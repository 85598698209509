// External Dependencies
import * as React from 'react'
import { Icon } from 'react-feather'

interface Props {
	text: string
	icon: Icon
	onClick: () => void
}

const NavbarDropdownItem: React.FC<Props> = (props: Props) => {
	const { text, icon, onClick } = props

	const FeatherIcon = icon
	const filteredProps: {[key: string]: any} = {
		...props,
		icon: undefined
	}
	return (
		<button className='dropdown-item d-flex align-items-center' onClick={onClick} {...filteredProps}>
			<FeatherIcon size={15} className='mr-2'/>
			<span>{text}</span>
		</button>
	)
}

export default NavbarDropdownItem
