import * as Yup from 'yup'

// Internal Dependencies
import { FormInput, FormInputType, ReactSelectItem } from '../../Shared/Form'

// Constants
import * as Constants from '../../../constants/index'

export interface QuickLinkFormState {
	title: string,
	link: string,
	image: File[]
}

export const QuickLinkFormInputs = (): FormInput[] => {
	return [{
		title: 'Title',
		property: 'title',
		type: FormInputType.TEXT,
		size: 12,
		placeholder: 'Enter title..',
		defaultValue: '',
		validation: Yup.string().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Link',
		property: 'link',
		type: FormInputType.TEXT,
		size: 12,
		placeholder: 'Enter link...',
		defaultValue: '',
		validation: Yup.string().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Image',
		property: 'image',
		type: FormInputType.FILE,
		size: 12,
		placeholder: 'Select image',
		file_accept: '.png,.jpg,.jpeg',
		defaultValue: '',
		validation: Yup.string().required(Constants.REQUIRED_FIELD),
	}]
}
