// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'

// Internal Dependencies

// Components
import FormModal from '../../Shared/Formik/FormModal'

// Form
import { QuickLinkFormInputs, QuickLinkFormState } from './form'

interface ComponentProps {
	submitting?: boolean
	saveHandler: (formState: QuickLinkFormState) => Promise<void>
	closeHandler: () => void
	quickLink?: core.Club.QuickLink
}

type Props = ComponentProps
type State = any

class QuickLinkForm extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Converts the state of the form into the 'HoursFormState'
	 * format, and sends it through the 'saveHandler' function on the props.
	 */
	handleSave = async (formState: QuickLinkFormState) => {
		await this.props.saveHandler(formState)
		this.props.closeHandler()
	}

	/**
	 * Calls the 'closeHandler' function on the props
	 */
	handleClose = async () => {
		this.props.closeHandler()
	}

	// ----------------------------------------------------------------------------------
	// Render
	// ----------------------------------------------------------------------------------

	render() {
		const resource = { ...this.props.quickLink }
		return (
			<FormModal
				modalTitle={'Add Quick Link'}
				formSpec={QuickLinkFormInputs()}
				formResource={resource}
				submitting={this.props.submitting}
				submitButtonHandler={this.handleSave}
				cancelButtonHandler={this.handleClose}
			/>
		)
	}
}

export default QuickLinkForm
