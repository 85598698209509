// External Dependencies
import * as core from 'club-hub-core'
import * as Yup from 'yup'

import { FormInputType, FormInput } from '../../Shared/Form'

export interface EditCoverImageState {
	image: core.SubModels.Image.Model | File[]
}

export const EditCoverImageModalInputs: FormInput[] = [
	{
		title: 'Image',
		property: 'image',
		type: FormInputType.FILE,
		file_accept: '.png,.jpg,.jpeg',
		size: 12,
		placeholder: 'Select a new image.',
		defaultValue: '',
		file_multipleFiles: false,
		validation: Yup.string().required('Required.')
	}
]
