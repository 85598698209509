// External Dependencies
import * as React from 'react'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { isEqual } from 'underscore'
import { RouteComponentProps } from 'react-router'
import { FormikProps } from 'formik'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies
import CopyToClipboard from '../../../helpers/copyToClipboad'

// Core
import * as core from 'club-hub-core'

// Actions
import { EventActions, AlertActions } from '../../../actions/index'

// State
import { RootReducerState } from '../../../reducers'
import { calendarsByIDSelector, calendarGroupsByIDSelector } from '../../../reducers/calendar'
import { eventsByIDSelector } from '../../../reducers/event'

// Components
import ModalComponent from '../../Shared/Modal'
import RecurrenceModal from '../../Modals/RecurrenceModal'
import { RecurrenceModalState } from '../../Modals/RecurrenceModal/form'
import DimmedLoader from '../../Shared/DimmedLoader'
import TypeCreationModal from '../../Shared/TypeCreationModal'

// Form
import { InputSelectionItem, ReactSelectItem, FormInput } from '../../Shared/Form'
import { ClubEventFormInputs, EventFormState } from './form'
import GenericFormComponent from '../../Shared/Formik/GenericForm'
import { BuildWrappedForm } from '../../Shared/Formik'
import NewLocationModal from '../../Modals/NewLocationModal'

// Helpers
import * as Constants from '../../../constants'
import { setStateAsync } from '../../../helpers/promise'
import { roundDateToNextHour, addMinutesToDate } from '../../../helpers/date'
import * as formHelper from '../../../helpers/form'
import { buildCustomRecurrenceString } from '../../../helpers/recurrence'
import { truncate } from 'fs'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	error: false,
	loading: true,
	group: null as core.Calendar.Group | null,
	calendar: null as core.Calendar.Model | null,
	creatingCustomRecurrence: false,
	creatingType: false,
	creatingLocation: false,
	deletingEvent: false,
	eventToUpdate: null as core.Event.Model | null,
	currentForm: null as EventFormState | null,
	recurring: null as string | null,
	recurringEnd: null as Date
}

type Props = RouteComponentProps & ConnectedState & ConnectedActions
type State = typeof initialState

class CreateEventForm extends React.Component<Props, State> {
	private isUpdateForm: boolean

	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
		this.isUpdateForm = this.props.location.pathname === Constants.UPDATE_EVENT_ROUTE
	}

	async componentDidMount() {
		if (this.isUpdateForm) {
			await this.setEventForFormWithQueryParams()
		}
		await this.setCalendarGroupWithQueryParams()
		await setStateAsync(this, { loading: false })
	}

	/**
	 * Gets the Event, that is being updated, from state using its ID that we get
	 * from the query parameters in the URL.
	 *
	 * The Event will have its information pre-filled into the form.
	 */
	setEventForFormWithQueryParams = async () => {
		// Parse the query string of the URL into an object
		const { location } = this.props
		const parsedQuery = queryString.parse(location.search)

		// Get the Event by its ID
		const eventID = parsedQuery.eventID
		const [err, event] = await to(this.props.fetchEvent(eventID) as any)
		if (err) {
			this.props.fireFlashMessage(`Our apologies, we couldn't find your Event. Please try again.`, Constants.FlashType.WARNING)
			return
		}

		// Update the state
		const eventToUpdate = this.props.eventState.currentEvent
		const recurring = eventToUpdate.recurring ? `${eventToUpdate.recurring}` : null
		const recurringEnd = eventToUpdate.recurringEnd ? new Date(eventToUpdate.recurringEnd) : null
		await setStateAsync(this, { eventToUpdate, recurring, recurringEnd })
	}

	/**
	 * Sets the calendar group for the component if one exists in the URL.
	 */
	setCalendarGroupWithQueryParams = async () => {
		const { calendarsByID, calGroupsByID, location } = this.props

		const groups = oc(this).props.loggedInClub.calendarGroups([])

		// Parse the query string of the URL into an object
		const parsedQuery = queryString.parse(location.search)

		// Check if a Group/Calendar has been provided in the query params
		const groupID = parsedQuery.calendarGroupID
		const calendarID = parsedQuery.calendarID

		// Both Provided
		if (groupID && calendarID) {
			const calendarGroup = calGroupsByID[groupID]
			const calendar = calendarsByID[calendarID]
			await setStateAsync(this, { group: calendarGroup, calendar: calendar })
			return
		}

		// Only Calendar Group ID Provided
		if (groupID && !calendarID) {
			const calendarGroup = calGroupsByID[groupID]
			await setStateAsync(this, { group: calendarGroup })
			return
		}

		// Only Calendar ID Provided
		if (!groupID && calendarID) {
			const calendar = calendarsByID[calendarID]
			const calendarGroup = calGroupsByID[`${oc(calendar).groupID()}`]
			await setStateAsync(this, { group: calendarGroup, calendar: calendar })
			return
		}
		// Default to the Club Calendar Group
		const clubGroup = groups.find((g) => g.type === core.Calendar.GroupType.Club)
		await setStateAsync(this, { group: clubGroup })
	}

	// ----------------------------------------------------------------------------------
	// Location Creation Modal Event Handlers
	// ----------------------------------------------------------------------------------

	handleLocationCreationModalSave = async (formikProps: FormikProps<EventFormState>, location: core.SubModels.Location.Model) => {
		const newLocationForForm: InputSelectionItem = (!isNullOrUndefined(location)) ?
			({ label: location.name, value: `${location._id}` }) : null

		// Update the form state to use the new Type
		formikProps.setFieldValue('location', newLocationForForm)
		this.handleCreationModalClose()
	}

	// ----------------------------------------------------------------------------------
	// Type Creation Modal Event Handlers
	// ----------------------------------------------------------------------------------

	handleTypeCreationModalSave = async (formikProps: FormikProps<EventFormState>, newType?: core.Club.ResourceType) => {
		const newTypeForForm: InputSelectionItem = (!isNullOrUndefined(newType)) ?
			({ label: newType.title, value: `${newType._id}` }) : null

		// Update the form state to use the new Type
		formikProps.setFieldValue('type', newTypeForForm)
		this.handleCreationModalClose()
	}

	// ----------------------------------------------------------------------------------
	// Recurrence Modal Event Handlers
	// ----------------------------------------------------------------------------------

	handleRecurrenceModalSave = async (form: RecurrenceModalState, formikProps: FormikProps<EventFormState>) => {
		const recurring = form.recurring.value
		const recurringEnd = form.recurringEnd
		await setStateAsync(this, { creatingCustomRecurrence: false, recurring, recurringEnd })
	}

	// ----------------------------------------------------------------------------------
	// Modal Handlers
	// ----------------------------------------------------------------------------------

	handleCreationModalClose = async () => {
		this.setState({ creatingCustomRecurrence: false, creatingType: false, creatingLocation: false })
	}

	// ----------------------------------------------------------------------------------
	// Deletion Modal Event Handlers
	// ----------------------------------------------------------------------------------

	handleDeletionModalClose = async () => {
		await setStateAsync(this, { deletingEvent: false })
	}

	handleDeleteEvent = async () => {
		await setStateAsync(this, { deletingEvent: true })
	}

	executeEventDeletion = async (): Promise<void> => {
		const { deleteEvent, fireFlashMessage, location, history } = this.props
		const { eventToUpdate } = this.state

		await setStateAsync(this, { loading: true })

		const [err] = await to(deleteEvent(`${eventToUpdate._id}`) as any)
		if (err) {
			fireFlashMessage(`Problem trying to delete Event. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}

		fireFlashMessage('Event successfully deleted.', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, error: false, currentForm: null })

		// Determine if there is a previous location to redirect to
		const locationState: { previousLocation: string } = oc(location).state()
		const redirectRoute = oc(locationState).previousLocation(Constants.EVENTS_ROUTE)
		history.replace(redirectRoute)
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Returns the user to the Events view when they cancel the form
	 */
	handleCancel = () => {
		this.props.history.goBack()
	}

	handleFormChange = async (field: string, item: InputSelectionItem) => {
		if (field === 'recurring') {
			await this.handleRecurrenceSelection(item)
		}
		if (field === 'type' && item.value === 'addNew') {
			this.setState({ creatingType: true })
		}
		if (field === 'location' && item.value === 'addNew') {
			this.setState({ creatingLocation: true })
		}
	}

	handleRecurrenceSelection = async (item: InputSelectionItem) => {
		if (item.value === 'custom') {
			await setStateAsync(this, { creatingCustomRecurrence: true })
			return
		}

		const recurring = item.value === 'none' ? null : item.value
		await setStateAsync(this, { creatingCustomRecurrence: false, recurring: recurring, recurringEnd: null })
	}

	/**
	 * Determines which handler to call (Create or Update) when the user submits the form
	 */
	handleSave = async (form: EventFormState, isDraft: boolean): Promise<void> => {
		form.isDraft = isDraft
		switch (this.props.location.pathname) {
			case Constants.CREATE_EVENT_ROUTE:
				return this.handleCreate(form)
			case Constants.UPDATE_EVENT_ROUTE:
				return this.handleUpdate(form)
			default:
				break
		}
	}

	/**
	 * Will create a new Event on the DB with the information in the form.
	 * Events will only be created if the validation conditions for the form are met.
	 */
	handleCreate = async (form: EventFormState): Promise<void> => {
		const { createEvent, fireFlashMessage, location, history } = this.props

		await setStateAsync(this, { loading: true, currentForm: form })

		const payload = this.buildEventPayload(form)
		const [err] = await to(createEvent(payload) as any)
		if (err) {
			fireFlashMessage(`Problem creating Event. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}

		fireFlashMessage('Event successfully created.', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, error: false, currentForm: null })
		history.goBack()
	}

	/**
	 * Will update an existing Event on the DB with the information in the form.
	 * Events will only be updated if the validation conditions for the form are met.
	 */
	handleUpdate = async (form: EventFormState): Promise<void> => {
		const { updateEvent, fireFlashMessage, history } = this.props
		const { eventToUpdate } = this.state

		if (!eventToUpdate) { return }
		const eventID = (`${eventToUpdate._id}`).split(Constants.EVENT_DATE_PREFIX)[0]

		await setStateAsync(this, { loading: true, currentForm: form })

		const payload = this.buildEventPayload(form)
		const [err] = await to(updateEvent(eventID, `${this.state.group._id}`, payload) as any)
		if (err) {
			fireFlashMessage(`Problem updating Event. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}

		fireFlashMessage('Event successfully updated.', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, error: false, currentForm: null })
		history.goBack()
	}

	handleCopyShortlinkToClipboard = (shortlink: string) => {
		const { fireFlashMessage, loggedInClub } = this.props
		const shortlinkURL = `${loggedInClub.baseURL}/event/shortlink/${shortlink}`
		CopyToClipboard(shortlinkURL)
		fireFlashMessage(`Copied link to event`, Constants.FlashType.SUCCESS)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	/**
	 * Builds the payload, that will be sent to the server, based on the form
	 */
	buildEventPayload = (form: EventFormState): FormData => {
		// Build the form data payload
		const formPayload = new FormData()

		// Create the Event payload and add it to the FormData
		const calendarIDs = oc(form).calendarIDs([]).map((input: ReactSelectItem) => input.value)
		const recurring = this.state.recurring ? Number(this.state.recurring) : null
		const recurringEnd = this.state.recurringEnd
		const event: any = {
			isDraft: form.isDraft,
			calendarIDs: calendarIDs,
			name: form.name,
			location: this.getLocation(form),
			description: oc(form).richContent.text(''),
			richContent: form.richContent,
			start: new Date(form.start).toISOString(),
			end: new Date(form.end).toISOString(),
			public: oc(form).public.value() === 'true',
			recurring: recurring,
			recurringEnd: recurringEnd,
			maxParticipants: oc(form).maxParticipants(),
			maxGuests: oc(form).maxGuests(),
			price: form.price,
			blockCalendar: oc(form).blockCalendar.value() === 'true',
			displayInFeed: oc(form).displayInFeed.value() === 'true',
			requiresRSVP: oc(form).requiresRSVP.value() === 'true',
			type: oc(form).type.value(),
			shortLink: form.shortLink
		}

		// Check for existing images
		const existingImages: core.SubModels.Image.Model[] = []
		if (form.images) {
			// tslint:disable-next-line
			for (let i = 0; i < form.images.length; i++) {
				const formImage: File | core.SubModels.Image.Model = form.images[i]
				if ((formImage as core.SubModels.Image.Model)._id) {
					existingImages.push(formImage as core.SubModels.Image.Model)
					continue
				}
				formPayload.append('image', formImage)
			}
		}

		// Set the existing images on the Event (defaults to [])
		event.images = existingImages
		formPayload.append('event', JSON.stringify(event))
		return formPayload
	}

	getLocation = (form: EventFormState) => {
		// Get the Location
		const locations = oc(this).props.loggedInClub.locations([])
		const selectedLocationID = oc(form).location.value('')
		let locationForForm = locations.find((location) => `${location._id}` === selectedLocationID)

		// If a location is not specified, we grab the default location for the calendar.
		if (isNullOrUndefined(locationForForm)) {
			locationForForm = this.state.calendar.location
		}
		return locationForForm
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildFormInputs = () => {
		const { loggedInClub, calendarState } = this.props
		const club = loggedInClub
		const locations = oc(club).locations([])
		const eventTypes = oc(club).resources.events.types([])
		const calendars = oc(calendarState).calendars([])
		const inputGroups = this.buildCalendarGroupsForForm()

		// Create the dynamic form inputs
		const customRecurrentLabel = this.getCustomRecurrenceLabel()
		const calendarInputs: ReactSelectItem[] = formHelper.calendarSelectInputs(calendars, inputGroups)
		const eventTypeInputs: any[] = formHelper.typeSelectInputs(eventTypes)
		const locationInputs: ReactSelectItem[] = formHelper.locationSelectInputs(locations)
		return ClubEventFormInputs(customRecurrentLabel, locationInputs, eventTypeInputs, calendarInputs, this.handleCopyShortlinkToClipboard)
	}

	buildCalendarGroupsForForm = () => {
		const groups = oc(this).props.calendarState.groups([])
		const clubGroup = groups.find((g: core.Calendar.Group) => g.type === core.Calendar.GroupType.Club)
		const inputGroups = [clubGroup]

		// If we are updating an event...capture all of the existing groups.
		if (this.state.eventToUpdate) {
			const calendarIDs = oc(this).state.eventToUpdate.calendarIDs([])
			const calendars = calendarIDs.map((calID: any) => this.props.calendarsByID[`${calID}`])
			const calGroups = calendars.map((cal: core.Calendar.Model) => this.props.calGroupsByID[`${cal.groupID}`])
			const groupsToAdd = calGroups.filter((calGroup: core.Calendar.Group) => calGroup.type !== core.Calendar.GroupType.Club)
			return inputGroups.concat(groupsToAdd)
		}

		// If we have a new event, check to see if we have other groups to add based on the group in state.
		const groupIsNotClubGroup = (oc(this).state.group.type() !== core.Calendar.GroupType.Club)
		if (this.state.group && groupIsNotClubGroup) {
			inputGroups.push(this.state.group)
		}
		return inputGroups
	}

	buildFormBody = (inputs: FormInput[], onChange: (field: string, value: any) => void, formikProps: FormikProps<EventFormState>) => {
		return BuildWrappedForm({ inputs, onChange }, formikProps)
	}

	buildForm = () => {
		if (!this.state.group) { return null }
		const formTitle = this.getFormTitle()
		const formInputs = this.buildFormInputs()

		const formResource = this.getFormResource(this.state.eventToUpdate)

		const updateButtonName = (this.state.eventToUpdate?.isDraft) ? 'Publish' : 'Update'
		const submitButtonName = (this.state.eventToUpdate) ? updateButtonName : 'Save'
		const secondaryButtonName = (this.state.eventToUpdate) ? 'Delete' : ''
		const updateDraftButtonName = (this.state.eventToUpdate?.isDraft) ? 'Update draft' : 'Move to drafts'
		const draftButtonName = (this.state.eventToUpdate) ? updateDraftButtonName : 'Save as draft'
		return (
			<GenericFormComponent
				title={formTitle}
				inputs={formInputs}
				enableReinitialize={false}
				formResource={formResource}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.handleCancel}
				submitButtonName={submitButtonName}
				submitButtonHandler={(form: EventFormState) => this.handleSave(form, false)}
				secondaryButtonName={secondaryButtonName}
				secondaryButtonHandler={this.handleDeleteEvent}
				dangerSecondary={true}
				draftButtonName={draftButtonName}
				draftButtonHandler={(form: EventFormState) => this.handleSave(form, true)}
				render={(formikProps) => (
					<>
						{this.buildFormBody(formInputs, this.handleFormChange, formikProps)}
						{this.buildDeletionModal(formikProps)}
						{this.buildRecurrenceModal(formikProps)}
						{this.buildTypeCreationModal(formikProps)}
						{this.buildLocationCreationModal(formikProps)}
					</>
				)}
			/>
		)
	}

	buildDeletionModal = (formikProps: FormikProps<EventFormState>) => {
		const { deletingEvent } = this.state
		if (!deletingEvent) { return null }

		return (
			<ModalComponent
				modalTitle={'Delete Event'}
				primaryMessage={'Are you sure you want to delete this Event?'}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={() => {
					formikProps.setSubmitting(false)
					return this.handleDeletionModalClose()
				}}
				submitButtonName={'Confirm'}
				submitButtonHandler={this.executeEventDeletion}
			/>
		)
	}

	buildRecurrenceModal = (formikProps: FormikProps<EventFormState>) => {
		const { creatingCustomRecurrence } = this.state
		if (!creatingCustomRecurrence) { return null }

		return (
			<RecurrenceModal
				recurring={oc(this).state.eventToUpdate.recurring()}
				recurringEnd={oc(this).state.eventToUpdate.recurringEnd()}
				submitButtonHandler={(state) => this.handleRecurrenceModalSave(state, formikProps)}
				cancelButtonHandler={() => this.handleCreationModalClose()}
			/>)
	}

	buildTypeCreationModal = (formikProps: FormikProps<EventFormState>) => {
		if (!this.state.creatingType) { return null }

		const existingTypesForModal = oc(this).props.loggedInClub.resources.events.types([])
		return (
			<TypeCreationModal
				typeName={'Events'}
				resourceType={'events'}
				existingTypes={existingTypesForModal}
				onClose={async () => {
					formikProps.setFieldValue('type', null)
					return this.handleCreationModalClose()
				}}
				onSave={(newType: core.Club.ResourceType) => {
					this.handleTypeCreationModalSave(formikProps, newType)
				}}
			/>
		)
	}

	buildLocationCreationModal = (formikProps: FormikProps<EventFormState>) => {
		if (!this.state.creatingLocation) { return null }

		return (
			<NewLocationModal
				modalTitle={'New Location'}
				cancelButtonHandler={async () => {
					formikProps.setFieldValue('location', null)
					return this.handleCreationModalClose()
				}}
				submitButtonHandler={(loc: core.SubModels.Location.Model) => {
					this.handleLocationCreationModalSave(formikProps, loc)
				}}
			/>
		)
	}

	render() {
		const component = (
			<div className='event-form-container row justify-content-center mx-auto'>
				<div className='col col-md-10 col-xl-8 d-flex justify-content-center'>
					{this.buildForm()}
				</div>
			</div>
		)
		return (<DimmedLoader component={component} isLoading={this.state.loading} />)
	}

	// -------------------------------------
	// Helpers
	// -------------------------------------

	getFormTitle = () => {
		const { group } = this.state
		if (group && group.type === core.Calendar.GroupType.Club) {
			return (this.isUpdateForm) ? 'Update Event' : 'New Event'
		}
		switch (group.type) {
			case core.Calendar.GroupType.Golf:
				return (this.isUpdateForm) ? 'Update Golf Event' : 'New Golf Event'
			case core.Calendar.GroupType.Dining:
				return (this.isUpdateForm) ? 'Update Dining Event' : 'New Dining Event'
		}
	}

	getFormResource = (eventToUpdate: core.Event.Model) => {
		if (eventToUpdate) {
			return this.formResourceForExistingEvent(eventToUpdate)
		}
		if (this.state.currentForm) {
			return this.state.currentForm  // If the request fails, use the form in state to rebuild the form inputs.
		}
		return this.formResourceForNewEvent()
	}

	formResourceForExistingEvent = (event: core.Event.Model) => {
		const recurringValue = this.getCustomRecurrenceValue()
		return {
			...event,
			recurring: recurringValue,
			location: oc(event).location._id(),
			public: `${oc(event).public(false)}`,
			maxParticipants: oc(event).maxParticipants(),
			displayInFeed: `${oc(event).displayInFeed(false)}`,
			requiresRSVP: `${oc(event).requiresRSVP(false)}`,
			blockCalendar: `${oc(event).blockCalendar(false)}`,
			images: oc(event).images([]),
		}
	}

	formResourceForNewEvent = () => {
		// Set default start / end times for new events.
		const start = roundDateToNextHour()
		const end = addMinutesToDate(start, 60)

		// Form Defaults
		let formResource = {
			start: start,
			end: end,
			public: 'false',
			richContent: {},
			recurring: 'None',
			calendarIDs: [] as string[]
		}
		// Check for start/end query parameters
		const queryParams = queryString.parse(this.props.location.search)
		if (queryParams.start || queryParams.end) {
			formResource = { ...formResource, start: queryParams.start, end: queryParams.end }
		}

		// Check for Calendar ID in query parameters
		if (queryParams.calendarID) {
			const calendar = this.state.calendar
			const calendarIDs = [`${calendar._id}`]
			formResource = { ...formResource, calendarIDs }
		}
		return formResource
	}

	getCustomRecurrenceLabel = () => {
		const recurring = oc(this).state.recurring()
		const recurringEnd = oc(this).state.recurringEnd()
		const existingCustomRecurrence = recurring && recurringEnd
		if (existingCustomRecurrence) {
			return buildCustomRecurrenceString(Number(recurring), recurringEnd)
		}
		return 'Custom...'
	}

	getCustomRecurrenceValue = () => {
		const recurring = oc(this).state.recurring()
		const recurringEnd = oc(this).state.recurringEnd()
		const existingCustomRecurrence = recurring && recurringEnd
		if (existingCustomRecurrence) {
			return 'custom'
		}
		if (recurring) {
			return recurring
		}
		return null
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	eventState: state.event,
	calendarState: state.calendar,
	loggedInClub: state.club.loggedInClub,
	calendarsByID: calendarsByIDSelector(state),
	calGroupsByID: calendarGroupsByIDSelector(state),
	eventsByID: eventsByIDSelector(state),
})

const mapDispatchToProps = {
	...EventActions,
	...AlertActions
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateEventForm)
