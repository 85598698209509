// External Dependencies
import * as Yup from 'yup'

// Internal Dependencies
import { FormInput, FormInputType, ReactSelectItem } from '../../Shared/Form'
import * as Constants from '../../../constants/index'

export interface UserGroupFormState {
	name: string,
	description: string,
	users: ReactSelectItem[],
}

export const UserGroupInputs = (members: ReactSelectItem[]): FormInput[] => [
	{
		title: 'Name',
		property: 'name',
		type: FormInputType.TEXT,
		size: 12,
		placeholder: 'Enter Name...',
		defaultValue: '',
		validation: Yup.string().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Members',
		property: 'users',
		type: FormInputType.MULTI_SELECT_TYPEAHEAD,
		size: 12,
		placeholder: 'Select Members...',
		selectItems: members,
		defaultValue: null,
		validation: Yup.array().of(
			Yup.object().shape({
				label: Yup.string(),
				value: Yup.string(),
			})
		).nullable().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Description',
		property: 'description',
		type: FormInputType.TEXT_AREA,
		size: 12,
		placeholder: 'Enter Description...',
		defaultValue: '',
	}
]

export const QueryUserGroupInputs = (): FormInput[] => [
	{
		title: 'Name',
		property: 'name',
		type: FormInputType.TEXT,
		size: 12,
		placeholder: 'Enter Name...',
		defaultValue: '',
		validation: Yup.string().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Description',
		property: 'description',
		type: FormInputType.TEXT_AREA,
		size: 12,
		placeholder: 'Enter Description...',
		defaultValue: '',
	}
]
