// External Dependencies.
import * as core from 'club-hub-core'
import * as Yup from 'yup'

// Internal Dependencies.
import { FormInput, FormInputType } from '../../Shared/Form'
import * as Constants from '../../../constants/index'

export interface EventUpdateState {
	[core.Event.UpdateType.All]: string
	[core.Event.UpdateType.Future]: string
	[core.Event.UpdateType.Single]: string
}

/**
 * Returns the form input fields used in the guest creation modal.
 */
export const EventUpdateInputs = (name: string): FormInput[] => {
	return [
		{
			title: `This ${name} Only`,
			property: core.Event.UpdateType.Single,
			type: FormInputType.CHECKBOX,
			size: 4,
			defaultValue: false,
		},
		{
			title: `All Future ${name}s`,
			property: core.Event.UpdateType.Future,
			type: FormInputType.CHECKBOX,
			size: 4,
			defaultValue: false,
		},
		{
			title: `All ${name}s`,
			property: core.Event.UpdateType.All,
			type: FormInputType.CHECKBOX,
			size: 4,
			defaultValue: false,
		}
	]
}
