// Action Types
import { ActionTypes, TypeKeys, EntryOverride } from '../actions/app'

const defaultState = {
	appEntryOverride: null as EntryOverride
}

export type AppState = typeof defaultState

function app(state = defaultState, action: ActionTypes): AppState {
	switch (action.type) {
		case TypeKeys.SET_APP_ENTRY_OVERRIDE:
			return {
				...state,
				appEntryOverride: action.entryOverride
			}
		case TypeKeys.CLEAR_PENDING_ENTRY_OVERRIDE:
			const copyState = { ...state }
			delete copyState.appEntryOverride
			return copyState
		default:
			return state
	}
}

export default app
