// External Dependencies
import * as React from 'react'
import { Icon } from 'react-feather'

// Interfaces / Types
export enum ButtonType {
	DEFAULT = 'default',
	GROUP = 'group'
}

export interface ButtonGroupItem {
	value: string
	text?: string
	icon?: Icon
	defaultSelected?: boolean
	selected?: boolean
	itemClass?: string
	itemType?: string
}

export interface HeaderButton {
	action: string
	type: ButtonType
	hidden?: boolean
	icon?: Icon
	text?: string
	class?: string
	groupButtons?: ButtonGroupItem[],
}

// Interfaces
interface ButtonGroupProps {
	buttons: HeaderButton[]
	buttonHandler?: any
	className?: string
}

const buildButtons = (props: ButtonGroupProps) => {
	const builtButtons = props.buttons.map((item: HeaderButton, index: number) => {
		if (item.hidden) { return null }
		return buildButton(item, props.buttonHandler, index)
	})

	return (
		<div className={`btn-list custom-btn-list ${props.className}`}>
			{builtButtons}
		</div>
	)
}

const buildButton = (button: HeaderButton, buttonHandler: any, index: number) => {
	switch (button.type) {
		case ButtonType.GROUP:
			return buildButtonGroup(button, buttonHandler, index)
		case ButtonType.DEFAULT:
		default:
			return buildDefaultButton(button, buttonHandler, index)
	}
}

const buildButtonGroup = (buttonGroup: HeaderButton, buttonHandler: any, index: number) => {
	const items = buttonGroup.groupButtons.map((button: ButtonGroupItem, buttonIndex: number) => {
		const text = button.text || null
		const iconClass = (text) ? 'mr-2' : ''
		const FeatherIcon = button.icon
		const icon = (button.icon) ? <FeatherIcon size={20} className={iconClass} /> : null

		let inputButton
		switch (button.itemType) {
			case 'button':
				inputButton = (
					<input
						className='selectgroup-input'
						type='radio'
						name='value'
						value={button.value}
						onClick={buttonHandler}
						defaultChecked={button.defaultSelected}
					/>
				)
				break
			default:
				inputButton = (
					<input
						className='selectgroup-input'
						type='radio'
						name='value'
						value={button.value}
						onChange={buttonHandler}
						defaultChecked={button.defaultSelected}
					/>
				)
				break
		}

		return (
			<label className={`buttongroup-item selectgroup-item ${button.itemClass}`} key={`buttongroup-item_${index}_${buttonIndex}`}>
				{inputButton}
				<span className={`selectgroup-button d-flex justify-content-center ${(icon) ? 'selectgroup-button-icon' : ''}`}>
					{icon}
					{text}
				</span>
			</label>
		)
	})

	return (
		<div className='buttongroup selectgroup' key={`buttongroup_${index}`}>
			{items}
		</div>
	)
}

const buildDefaultButton = (button: HeaderButton, buttonHandler: any, index: number) => {
	const text = button.text || null
	const iconClass = (text) ? `${button.icon} mr-2` : `${button.icon}`
	const FeatherIcon = button.icon
	const icon = (button.icon) ? <FeatherIcon size={18} className={iconClass} /> : null
	return (
		<button
			className={`btn ${button.class} d-flex justify-content-center align-items-center`}
			key={`headerButton_${index}`}
			onClick={buttonHandler}
			value={button.action}
		>
			{icon}
			{text}
		</button>
	)
}

const ButtonGroupComponent: React.FunctionComponent<ButtonGroupProps> = (props: ButtonGroupProps) => {
	return buildButtons(props)
}

export default ButtonGroupComponent
