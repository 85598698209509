// External Dependencies
import React from 'react'
import * as core from 'club-hub-core'
import { isNullOrUndefined } from 'util'
import to from 'await-to-js'

// Internal Dependencies
import { GlobalNotifSettingsColumns } from './table'

// Components
import TableComponent from '../../Shared/Table'
import Card from '../../Shared/Cards/Card'
import { NotifSettingToggle, OnToggleParams } from '../NotifSettingToggle'

// Helpers
import { setStateAsync } from '../../../helpers/promise'

export type NotifSettingKey = 'disableAll' | 'disablePush' | 'disableEmail' | 'disableText'

interface ComponentProps {
	heading: string
	settings?: core.NotificationPreference.Model
	onToggleNotif: (params: OnToggleParams) => Promise<core.Response.NotificationPreferenceResponse>
}

const initialState = {
	pendingAction: false
}

type Props = ComponentProps
type State = typeof initialState

export class GlobalNotifSettingsTable extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
	}

	dataForTable = () => {
		const { settings } = this.props

		const hasSetting = !isNullOrUndefined(settings)

		return [{
			_id: (hasSetting) ? settings._id : 'global-notif-settings',
			name: 'Global Settings',
			allNotifs: this.notificationToggle('disableAll'),
			pushNotifs: this.notificationToggle('disablePush'),
			emailNotifs: this.notificationToggle('disableEmail'),
			smsNotifs: this.notificationToggle('disableText'),
		}]
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	notificationToggle = (settingKey: NotifSettingKey) => {
		const { settings, onToggleNotif } = this.props
		const { pendingAction } = this.state

		const hasSetting = !isNullOrUndefined(settings)

		const notifPrefEnabled = (hasSetting) ? !settings[settingKey] : true
		const notifPrefID = (hasSetting) ? `${settings._id}` : undefined
		const notifPrefDisabled = (hasSetting) ? settingKey !== 'disableAll' && settings.disableAll : false

		return (
			<NotifSettingToggle
				id={`${notifPrefID || 'global-notif-settings'}__${settingKey}`}
				className={'globalNotifSettingsTable__toggle'}
				notifPrefID={notifPrefID}
				settingKey={settingKey}
				isEnabled={notifPrefEnabled}
				isDisabled={notifPrefDisabled || pendingAction}
				onToggleNotif={async (params) => {
					await setStateAsync(this, { pendingAction: true })
					const [err, res] = await to(onToggleNotif(params))
					await setStateAsync(this, { pendingAction: false })
					return res
				}}
			/>
		)
	}

	render() {
		const tableColumns = GlobalNotifSettingsColumns
		const rowItems = this.dataForTable()
		const content = (
			<TableComponent
				columns={tableColumns}
				rowItems={rowItems}
				showPaging={false}
				striped={false}
			/>
		)
		return (
			<Card
				title={'Global Notification Settings'}
				bodyClass={'globalNotifSettingsTable'}
				content={content}
			/>
		)
	}
}
