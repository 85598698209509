// External Dependencies
import * as Feather from 'react-feather'

// Internal Dependencies
import { TableColumn } from '../Shared/Table'
import { TableColumnType } from '../Shared/Table/Primitives'

export enum MenuItemTableActions {
	Edit = 'editMenuItem',
	Delete = 'deleteMenuItem',
}

export const MenuItemTableColumns: TableColumn[] = [
	{
		title: '',
		property: '',
		type: TableColumnType.CHECKBOX,
		action: '',
	},
	{
		title: 'Name',
		property: 'name',
		type: TableColumnType.TEXT,
		action: 'name',
	},
	{
		title: 'Price',
		property: 'price',
		type: TableColumnType.PRICE,
		action: 'price',
	},
	{
		title: 'Available',
		property: 'availableText',
		type: TableColumnType.TEXT,
		action: 'available',
	},
	{
		title: 'Last Updated',
		property: 'updatedAt',
		type: TableColumnType.DATE_TIME,
		action: 'name',
	},
	{
		title: '',
		property: '',
		type: TableColumnType.DROPDOWN,
		class: 'table-dropdown-menu',
		dropdownItems: [
			{
				action: MenuItemTableActions.Edit,
				valueProperty: '_id',
				displayText: 'Edit Menu Item',
				icon: Feather.Edit,
			},
			{
				action: MenuItemTableActions.Delete,
				valueProperty: '_id',
				displayText: 'Delete Menu Item',
				icon: Feather.Trash,
			},
		]
	}
]

export enum MenuSectionTableActions {
	Edit = 'editMenuSection',
	Delete = 'deleteMenuSection',
}

export const MenuSectionTableColumns: TableColumn[] = [
	{
		title: '',
		property: '',
		type: TableColumnType.CHECKBOX,
		action: '',
	},
	{
		title: 'Name',
		property: 'name',
		type: TableColumnType.TEXT,
		action: 'name',
	},
	{
		title: '',
		property: '',
		type: TableColumnType.DROPDOWN,
		class: 'table-dropdown-menu',
		dropdownItems: [
			{
				action: MenuSectionTableActions.Edit,
				valueProperty: '_id',
				displayText: 'Edit Menu Section',
				icon: Feather.Edit,
			},
			{
				action: MenuSectionTableActions.Delete,
				valueProperty: '_id',
				displayText: 'Delete Menu Section',
				icon: Feather.Trash,
			},
		]
	}
]
