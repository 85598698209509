// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { flatten } from 'underscore'
import { isNullOrUndefined, log } from 'util'
import { RouteComponentProps } from 'react-router'
import to from 'await-to-js'

// Actions
import { AlertActions } from '../../actions/index'
import { EventActions } from '../../actions/event'

// State
import { RootReducerState } from '../../reducers'
import { inCustomerViewSelector, usersByIDSelector } from '../../reducers/user'
import { eventsByIDSelector } from '../../reducers/event'

// Components
import DimmedLoader from '../Shared/DimmedLoader'
import ErrorComponent from '../Shared/ErrorComponent'
import UserComponent from '../User'
import BackHeader from '../Shared/BackHeader'

// Helpers
import * as Constants from '../../constants'
import { setStateAsync } from '../../helpers/promise'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	error: false,
	loading: false,
	pageIndex: 0
}

type Props = RouteComponentProps & ConnectedState & ConnectedActions
type State = typeof initialState

class RsvpComponent extends React.Component<Props, State> {
	private pageSize: number
	private eventID: string

	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
		this.pageSize = 20
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		this.setSelectedEvent()
		await setStateAsync(this, { loading: false })
	}

	setSelectedEvent = async () => {
		const { fetchEventIfNeeded } = this.props
		// Parse the query string of the URL into an object
		const parsedQuery = queryString.parse(this.props.location.search)
		this.eventID = parsedQuery.eventID
		const [err] = await to(fetchEventIfNeeded(this.eventID) as any)

		if (err) {
			this.props.fireFlashMessage(`Failed to fetch Events. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
		}

		await setStateAsync(this, { loading: false })
	}

	handleRefreshUsers = async () => {
		this.setSelectedEvent()
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildBackHeader = () => {
		const currentEvent = this.props.eventState.currentEvent
		const toLink = `${Constants.VIEW_EVENT_ROUTE}?eventID=${currentEvent._id}`
		return (
			<BackHeader to={toLink} backTitle={'Back to Event'} />
		)
	}

	buildUserComponent = () => {
		const selectedEvent = this.props.eventState.currentEvent
		const eventName = `${(selectedEvent) ? selectedEvent.name : 'Event'} - RSVPs`

		return (
			<div className={'rsvp-table-container'}>
				{this.buildBackHeader()}
				<UserComponent title={eventName} users={this.getUsersForEvent()} refreshUsers={this.handleRefreshUsers} />
			</div>

		)
	}

	render() {
		const { loggedInClub } = this.props
		if (this.state.error) { return <ErrorComponent club={loggedInClub} isAdmin={this.props.userState.isAdmin} /> }
		if (!this.props.eventState.currentEvent) { return null }

		return (
			<DimmedLoader
				component={this.buildUserComponent()}
				isLoading={this.state.loading}
			/>
		)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	getUsersForEvent = (): core.User.Model[] => {
		const reservations = this.props.eventState.currentEvent.reservations
		const reservedMembers: { [userID: string]: core.User.Model } = {}
		for (const reservation of reservations) {
			for (const participant of reservation.participants) {
				const user = this.props.usersByIDSelector[`${participant.userID}`]
				if (user) {
					reservedMembers[`${participant.userID}`] = user
				}
			}
		}
		return Object.values(reservedMembers)
	}
}

const mapStateToProps = (state: RootReducerState) => {
	return {
		eventState: state.event,
		loggedInClub: state.club.loggedInClub,
		userState: state.user,
		usersByIDSelector: usersByIDSelector(state),
		isCustomerView: inCustomerViewSelector(state),
		eventsByID: eventsByIDSelector(state),
	}
}

const mapDispatchToProps = {
	...AlertActions,
	...EventActions
}

export default connect(mapStateToProps, mapDispatchToProps)(RsvpComponent)
