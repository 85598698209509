// External Dependencies
import * as React from 'react'
import * as Sentry from '@sentry/browser'
import { RouteComponentProps } from 'react-router'
import { isEqual } from 'underscore'
import { connect } from 'react-redux'

// Internal Dependencies

// Components
import ErrorComponent from '../ErrorComponent'

// Helpers
import { setStateAsync } from '../../../helpers/promise'
import { UserActions } from '../../../actions'

const initialState = {
	isAdminUser: false,
	hasError: false
}

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

interface ErrorBoundaryProps {
	isAdminUser: boolean
}

type Props = RouteComponentProps & ErrorBoundaryProps & ConnectedState & ConnectedActions
type State = typeof initialState

class ErrorBoundary extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = { ...initialState, isAdminUser: props.isAdminUser }
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	static getDerivedStateFromError(error: Error) {
		return { hasError: true }
	}

	componentDidCatch(error: Error, info: React.ErrorInfo) {
		this.setState({ hasError: true })
		Sentry.addBreadcrumb({data: info})
		Sentry.captureException(error)
	}

	async componentDidUpdate(prevProps: Props) {
		// Clear the error if the User attempts to go to another location
		const differentLocation = !isEqual(prevProps.location, this.props.location)
		if (differentLocation) {
			await setStateAsync(this, { hasError: false })
		}
	}

	render() {
		if (this.state.hasError) {
			return <ErrorComponent isAdmin={this.props.isAdminUser} />
		}
		return this.props.children
	}
}

const mapStateToProps = (state: any) => ({
	userState: state.user,
})

const mapDispatchToProps = {
	...UserActions
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary)
