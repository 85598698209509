// External Dependencies
import * as React from 'react'

// Internal Dependencies
import { BaseTablePrimitiveProps } from '../index'

// Components
import CheckboxComponent from '../../../Checkbox'
import { BaseTableCell } from '../BaseTableCell'

type Props<T> = BaseTablePrimitiveProps<T> & { isChecked: boolean, handleRowCheckbox: (item: T) => any }

export const TableCheckbox: React.FC<Props<any>> = (props: Props<any>) => {
	// Destructure the props so that we don't pass unnecessary props
	const { isChecked, handleRowCheckbox, ...others } = props
	return (
		<BaseTableCell
			{...others}
			onClick={(e) => {
				// Prevent the parent table row from having its 'onClick' called
				e.nativeEvent.stopImmediatePropagation()
				e.stopPropagation()
			}}
		>
			<CheckboxComponent
				className={'table-checkbox'}
				isChecked={isChecked}
				onChange={() => handleRowCheckbox(props.item)}
			/>
		</BaseTableCell>
	)
}
