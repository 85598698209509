// External Dependencies
import * as core from 'club-hub-core'

// =======================================
// ROUTES
// =======================================
export const LOGIN_ROUTE = '/login'
export const REGISTER_ROUTE = '/register'
export const OAUTH_ROUTE = '/oauth/:provider'
export const FORGOT_PASSWORD_ROUTE = '/forgotpassword'
export const SET_PASSWORD_ROUTE = '/setpassword'

// HOME
export const HOME_ROUTE = '/home'

// Users
export const USER_ROUTE = '/user'
export const CREATE_USER_ROUTE = '/user/create'
export const UPDATE_USER_ROUTE = '/user/update'
export const VIEW_USER_ROUTE = '/user/view'
export const USER_PROFILE_ROUTE = '/user/me'
export const USER_STATEMENT_ROUTE = '/user/statement'

// User Groups
export const USER_GROUP_ROUTE = '/usergroup'
export const CREATE_USER_GROUP_ROUTE = '/usergroup/create'
export const UPDATE_USER_GROUP_ROUTE = '/usergroup/update'
export const VIEW_USER_GROUP_ROUTE = '/usergroup/view'

// Posts
export const POSTS_ROUTE = '/posts'
export const CREATE_POST_ROUTE = '/posts/create'
export const CREATE_POST_CONTENT_ROUTE = '/posts/create/content'
export const UPDATE_POST_ROUTE = '/posts/update'
export const UPDATE_POST_CONTENT_ROUTE = '/posts/update/content'
export const VIEW_POST_ROUTE = '/posts/view'

// Messages
export const MESSAGES_ROUTE = '/messages'
export const MESSAGES_DRAFT_ROUTE = '/messages/draft'
export const MESSAGES_TEMPLATE_ROUTE = '/messages/template'
export const VIEW_MESSAGE_USERS_ROUTE = '/messages/:message_id/users/:notif_status'
export const CREATE_MESSAGE_ROUTE = '/messages/create'
export const EDIT_MESSAGE_ROUTE = '/messages/edit/:delivery_type'
export const UPDATE_MESSAGE_ROUTE = '/messages/update/:delivery_type'
export const CREATE_PREVIEW_MESSAGE_ROUTE = '/messages/preview/:delivery_type'
export const UPDATE_PREVIEW_MESSAGE_ROUTE = '/messages/preview/:delivery_type'

// Calendars
export const CALENDAR_ROUTE = '/calendars'
export const CALENDAR_GROUPS_ROUTE = '/calendargroups'

// Events
export const EVENTS_ROUTE = '/events'
export const CREATE_EVENT_ROUTE = '/events/create'
export const UPDATE_EVENT_ROUTE = '/events/update'
export const VIEW_EVENT_ROUTE = '/events/view'
export const VIEW_EVENT_RSVPS_ROUTE = '/events/rsvps'
export const RSVP_TO_EVENT_ROUTE = '/events/rsvp'
export const VIEW_PUBLIC_EVENT_ROUTE = '/event/:event_shortlink'

// Admin Reservations
export const MY_RESERVATIONS_ROUTE = '/reservations/:type/viewing'

export const GOLF_RESERVATION_ROUTE = '/reservations/golf'
export const GOLF_RESERVATION_CALENDAR_ROUTE = '/reservations/golf/calendar'

export const DINING_RESERVATIONS_ROUTE = '/reservations/dining'
export const SCHEDULED_SERVICES_ROUTE = '/reservations/services'

export const GUEST_GOLFER_ROUTE = '/reservations/guest-golfer'
export const GUEST_GOLFER_CALENDAR_ROUTE = '/reservations/guest-golfer/calendar'

export const VIEW_SCHEDULED_SERVICE_ROUTE = '/reservations/services/view'
export const CREATE_SCHEDULED_SERVICE_ROUTE = '/reservations/services/create'
export const UPDATE_SCHEDULED_SERVICE_ROUTE = '/reservations/services/update'

export const VIEW_RESERVATION_DETAILS_ROUTE = '/reservations/:type/view'
export const RESERVATION_SETTINGS_ROUTE = '/reservations/:calendar_id/settings'

// Customer Reservations
export const CONFIRM_RESERVATION = '/reservations/confirm/:type/:action'
export const GOLF_RESERVATION = '/reservations/golf/:action'
export const DINING_RESERVATION = '/reservations/dining/:action'

// Service Providers
export const SERVICE_PROVIDERS_ROUTE = '/providers'
export const CREATE_SERVICE_PROVIDER_ROUTE = '/providers/create'
export const UPDATE_SERVICE_PROVIDER_ROUTE = '/providers/update'

// Vehicles
export const VEHICLES_ROUTE = '/vehicles'
export const CREATE_VEHICLE_ROUTE = '/vehicles/create'
export const UPDATE_VEHICLE_ROUTE = '/vehicles/update'

// Club Info
export const CLUB_INFO_ROUTE = '/info'

// Restaurants
export const RESTAURANT_ROUTE = '/restaurants'
export const RESTAURANT_CREATE_ROUTE = '/restaurants/create'
export const RESTAURANT_UPDATE_ROUTE = '/restaurants/update'
export const MENU_ROUTE = '/restaurants/:restaurant_id/menus'
export const MENU_CREATE_ROUTE = '/restaurants/:restaurant_id/menus/create'
export const MENU_UPDATE_ROUTE = '/restaurants/:restaurant_id/menus/update'
export const MENU_ITEMS_ROUTE = '/restaurants/:restaurant_id/menus/:menu_id/items'
export const MENU_ITEM_CREATE_ROUTE = '/restaurants/:restaurant_id/menus/:menu_id/items/create'
export const MENU_ITEM_UPDATE_ROUTE = '/restaurants/:restaurant_id/menus/:menu_id/items/update'

// Sections
export const SECTION_PAGES_ROUTE = '/sections/:section_id/pages'
export const CREATE_SECTION_PAGE_ROUTE = '/sections/:section_id/pages/create'
export const UPDATE_SECTION_PAGE_ROUTE = '/sections/:section_id/pages/update'
export const VIEW_SECTION_ROUTE = '/sections/view/:section_id'

// Settings
export const SETTINGS_ROUTE = '/settings'

// Forms
export const PUBLIC_FORMS = '/forms/:form_name'

// =======================================
// FLASH MESSAGES
// =======================================
export enum FlashType {
	PRIMARY = 'primary',
	SUCCESS = 'success',
	WARNING = 'warning',
	DANGER = 'danger'
}

// =======================================
// ERROR HANDLING
// =======================================
export const UNAUTHORIZED_ACCESS_NOTIFICATION = 'UNAUTHORIZED_ACCESS_NOTIFICATION'
export const INVALID_CREDENTIALS = 'INVALID_CREDENTIALS'
export const INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR'

// =======================================
// FORM VALIDATION
// =======================================
export const REQUIRED_FIELD = 'This field is required'

// =======================================
// Resources
// =======================================

// =======================================
// Events
// =======================================

export const EVENT_DATE_PREFIX = '_eventDate_'

// =======================================
// INTERCOM
// =======================================

export const INTERCOM_APP_KEY = 'cr5crjtw'

// =======================================
// COLORS
// =======================================

export enum SecondaryColors {
	BLUE = '#F2F6FC',
}

export const GROUP_COLORS = [core.Constants.Colors.BLUE, core.Constants.Colors.RED, core.Constants.Colors.GREEN, core.Constants.Colors.YELLOW, core.Constants.Colors.CYAN]

// =======================================
// DAYS
// =======================================

export enum DayOfWeek {
	Sunday = 0,
	Monday = 1,
	Tuesday = 2,
	Wednesday = 3,
	Thursday = 4,
	Friday = 5,
	Saturday = 6,
}

export enum Frequencies {
	Day = 0,
	Week = 1,
	Month = 2,
	Year = 3,
}

// =======================================
// Live Status
// =======================================

export enum LiveStatus {
	Live = 'live',
	Off = 'off',
}

// =======================================
// FALLBACK IMAGES
// =======================================

export const PLACEHOLDER_IMG = 'https://s3-us-west-2.amazonaws.com/clubhubs3/assets/placeholder.png'

// =======================================
// GIT INFORMATION
// =======================================

declare const VERSION: string
declare const COMMITHASH: string
declare const BRANCH: string

const _VERSION = VERSION
const _COMMITHASH = COMMITHASH
const _BRANCH = BRANCH

export { _VERSION as VERSION }
export { _COMMITHASH as COMMITHASH }
export { _BRANCH as BRANCH }
