// External Dependencies
import * as React from 'react'
import * as Sentry from '@sentry/browser'
import * as core from 'club-hub-core'
import * as RS from 'reactstrap'
import * as Feather from 'react-feather'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { flatten } from 'underscore'
import { isNullOrUndefined } from 'util'
import { RouteComponentProps } from 'react-router'
import { oc } from 'ts-optchain'
import { History } from 'history'
import to from 'await-to-js'

// Internal Dependencies

// Actions
import { EventActions, AlertActions, CustomerActions } from '../../actions/index'

// Components
import UserSelectModal from '../Modals/UserSelectModal'
import ModalComponent from '../Shared/Modal'
import BackHeader from '../Shared/BackHeader'
import RichContent from '../Shared/RichContent'
import AddToCalendarComponent from '../Shared/AddToCalendar'
import ImageCarousel from '../Shared/ImageCarousel'
import DimmedLoader from '../Shared/DimmedLoader'

// State
import { RootReducerState } from '../../reducers'
import { inCustomerViewSelector, bookableMembers } from '../../reducers/user'
import { eventsByIDSelector } from '../../reducers/event'
import { calendarsByIDSelector, calendarGroupsByIDSelector } from '../../reducers/calendar'

// Helpers
import * as Constants from '../../constants'
import * as eventHelper from '../../helpers/event'
import { setStateAsync } from '../../helpers/promise'
import { formatPrice } from '../../helpers/formatting'
import { thisExpression } from '@babel/types'

type ConnectedActions = typeof mapDispatchToProps
type ConnectedState = ReturnType<typeof mapStateToProps>

interface ComponentProps {
	event?: core.Event.Model
	handlePublicRsvp?: () => Promise<void>
	showRSVPButton?: boolean
}

const initialState = {
	event: null as core.Event.Model | null,
	existingRSVP: null as core.Event.Reservation | null,
	showCancellationModal: false,
	showingUserSelectModal: false,
	dropdownOpen: false,
	loading: true,
	showingRSVP: false
}

type Props = ComponentProps & ConnectedActions & ConnectedState & RouteComponentProps
type State = typeof initialState

class EventDetailComponent extends React.Component<Props, State> {
	private club: core.Club.Model

	constructor(props: Props) {
		super(props)
		this.club = props.loggedInClub
		this.state = { ...initialState, event: props.event }
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		if (this.props.event) {
			this.setState({ loading: false })
			return
		}

		// Get the Event via query parameters if one was not supplied via props
		await this.setEventWithQueryParameters()
	}

	setEventWithQueryParameters = async () => {
		// Parse the query string of the URL into an object
		Sentry.addBreadcrumb({
			data: {
				location: this.props.location,
				user: this.props.userState.loggedInUser
			}
		})
		const parsedQuery = queryString.parse(this.props.location.search)

		// Get the Event by its ID
		const eventID = parsedQuery.eventID
		if (!eventID) {
			this.setState({ loading: false })
			return
		}
		await this.props.fetchEvent(eventID, new Date())
		const eventToShow = this.props.eventState.currentEvent

		let existingRSVP: core.Event.Reservation
		if (this.props.userState.loggedInUser && this.props.isCustomerView) {
			existingRSVP = eventToShow.reservations.find((r) => {
				return `${r.owner}` === `${this.props.userState.loggedInUser._id}`
			})
		}
		// Update the state
		this.setState({ event: eventToShow, loading: false, existingRSVP })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	handleViewRsvps = () => {
		const queryParams = { eventID: `${this.state.event._id}` }
		const location = {
			pathname: Constants.VIEW_EVENT_RSVPS_ROUTE,
			search: queryString.stringify(queryParams)
		}
		this.props.history.push(location)
	}

	handleRsvp = async () => {
		const queryParams = { eventID: `${this.state.event._id}` }
		const location = {
			pathname: Constants.RSVP_TO_EVENT_ROUTE,
			search: queryString.stringify(queryParams)
		}
		this.props.history.push(location)
	}

	handleBulkRSVP = async () => {
		this.setState({ showingUserSelectModal: true })
	}

	handleCancelRsvp = async () => {
		this.setState({ showCancellationModal: true })
	}

	/**
	 * When editing an Event, navigate to the Event update
	 * form and pass the Event's ID via the query parameters
	 */
	handleEditEvent = () => {
		// Query params
		const eventID = this.state.event._id
		const calendarID = eventHelper.calendarIDForEvent(this.state.event)
		const queryParams = { eventID, calendarID }

		const location = {
			pathname: Constants.UPDATE_EVENT_ROUTE,
			search: queryString.stringify(queryParams),
			state: { ...this.props.location.state },
		}

		this.props.history.push(location)
	}

	/**
	 * Handles multiple users being selected for RSVPs.
	 */
	handleUsersSelected = async (userIDs: string[]) => {
		const [err] = await to(this.props.createEventRsvps(`${this.state.event._id}`, userIDs) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to create RSVPs for Event. ${err.message}`, Constants.FlashType.DANGER)
			return
		}

		const [fetchErr] = await to(this.props.fetchEvent(`${this.state.event._id}`, new Date()) as any)
		if (fetchErr) {
			this.props.fireFlashMessage(`Failed to create RSVPs for Event. ${err.message}`, Constants.FlashType.DANGER)
			return
		}

		this.props.fireFlashMessage(`Successfully created RSVPs.`, Constants.FlashType.SUCCESS)
		const eventToShow = this.props.eventState.currentEvent
		this.setState({ showingUserSelectModal: false, event: eventToShow })
	}

	/**
	 * Handles cancels to the user select.
	 */
	handleCancelUserSelect = async () => {
		this.setState({ showingUserSelectModal: false })
	}

	executeRsvpCancellation = async () => {
		const loggedInUser = this.props.userState.loggedInUser

		const reservations = this.state.event.reservations
		const reservationForUser = reservations.find((r) => r.owner === loggedInUser._id)
		if (!reservationForUser) {
			this.props.fireFlashMessage(`No Reservation found.`, Constants.FlashType.DANGER)
			return
		}

		const actualEventID = `${this.state.event._id}`.split(Constants.EVENT_DATE_PREFIX)[0]

		const [err] = await to(this.props.cancelEventRsvp(`${reservationForUser._id}`, actualEventID) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to cancel RSVP for Event. ${err.message}`, Constants.FlashType.DANGER)
			return
		}

		const [fetchErr] = await to(this.props.fetchEvents({ limit: 0, offset: 0 }) as any)
		if (fetchErr) {
			this.props.fireFlashMessage(`Failed to fetch updated Events. ${err.message}`, Constants.FlashType.DANGER)
			return
		}

		const updatedEvent = this.props.eventsByID[`${this.state.event._id}`]
		if (!updatedEvent) {
			this.props.fireFlashMessage(`Failed to fetch updated Event.`, Constants.FlashType.DANGER)
			return
		}

		this.props.fireFlashMessage(`Successfully cancelled RSVP.`, Constants.FlashType.SUCCESS)
		this.setState({ showCancellationModal: false, event: updatedEvent })
	}

	closeCancelRsvpModal = async () => {
		this.setState({ showCancellationModal: false })
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildBackHeader = () => {
		const userState = this.props.userState
		if (!userState || !userState.loggedInUser) {
			return
		}
		// Only use the edit handler if the user is an admin
		const editHandler = (!this.props.isCustomerView) ? this.handleEditEvent : null

		// Initial values for the BackHeader
		let backRoute: History.LocationDescriptor = Constants.EVENTS_ROUTE
		let backTitle: string = 'Events'

		// Check if we have 'previousLocation' state
		const locationState = oc(this).props.location.state({})
		const previousLocation = locationState.previousLocation
		if (!isNullOrUndefined(previousLocation)) {
			backRoute = previousLocation
			backTitle = (!isNullOrUndefined(locationState.previousLocationName)) ?
				locationState.previousLocationName : backTitle
		}

		return (
			<BackHeader
				to={backRoute}
				backTitle={backTitle}
				editHandler={editHandler}
			/>
		)
	}

	buildCancelRsvpModal = () => {
		if (!this.state.showCancellationModal) { return null }

		const title = 'Cancel RSVP'
		const primaryMessage = 'Cancel your RSVP for this Event?'
		const closeHandler = this.closeCancelRsvpModal
		const submitHandler = this.executeRsvpCancellation

		return (
			<ModalComponent
				modalTitle={title}
				primaryMessage={primaryMessage}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={closeHandler}
				submitButtonName={'Confirm'}
				submitButtonHandler={submitHandler}
			/>
		)
	}

	/**
	 * Build the card that display's the Event's photo
	 */
	buildEventPhotoCard = () => {
		const images = oc(this).state.event.images([])
		if (images.length === 0) { return null }
		return (
			<div className='event-photo-container'>
				<ImageCarousel
					images={images}
					club={this.props.loggedInClub}
				/>
			</div>
		)
	}

	/**
	 * Build the card that displays information about the Event,
	 * such as: Location, Time/Date, and RSVPs
	 */
	buildEventInfoCard = () => {
		return (
			<div className='event-detail-card-section event-detail-card-info-section'>
				<div className='row d-flex align-items-top'>
					<div className='col-12'>
						{this.buildRsvpSection()}
					</div>
					<div className='col-12'>
						{this.buildDateSection()}
					</div>
					<div className='col-12'>
						{this.buildLocationSection()}
					</div>
					<div className='col-12'>
						{this.buildEventPriceCard()}
					</div>
				</div>
			</div>
		)
	}

	/**
	 * Build the card that displays the Event's description
	 */
	buildEventDescriptionCard = () => {
		const event = this.state.event
		const content = oc(event).richContent.html()
		return (
			<div className='event-detail-card-section'>
				<p className='section-header-text text-muted'>DESCRIPTION</p>
				<RichContent content={content} />
			</div>
		)
	}

	/**
	 * Build the card that displays the Event's price
	 */
	buildEventPriceCard = () => {
		const event = this.state.event
		const eventCost = formatPrice(event.price)
		return (
			<div className='event-detail-text-section'>
				<p className='section-header-text text-muted'>PRICE</p>
				<div className='event-price-description'>{eventCost}</div>
			</div>
		)
	}

	/**
	 * Build the section of the information card that displays the Event location
	 */
	buildLocationSection = () => {
		const event = this.state.event
		const eventLocation = event.location
		const locationToDisplay = (eventLocation) ?
			(
				<div>
					{(eventLocation.name) ? (<p className='event-detail-text'>{eventLocation.name}</p>) : null}
					{(eventLocation.address1) ? (<p className='event-detail-text'>{eventLocation.address1}</p>) : null}
				</div>
			) :
			null

		let mapLinkToDisplay
		if (eventLocation) {
			const address1 = oc(eventLocation).address1('')
			const city = oc(eventLocation).city('')
			const state = oc(eventLocation).state('')
			const addressQuery = `${address1} ${city} ${state}`
			const mapLink = `https://maps.google.com/?q=${addressQuery}`
			mapLinkToDisplay = (<a href={mapLink} className='underscored-link'>View on map</a>)
		}

		return (
			<span className='event-detail-text-section d-flex flex-column justify-content-between h-100'>
				<div>
					<p className='section-header-text text-muted'>LOCATION</p>
					{locationToDisplay}
				</div>
				{mapLinkToDisplay}
			</span>
		)
	}

	/**
	 * Build the section of the information card that displays the Event date/time
	 */
	buildDateSection = () => {
		const event = this.state.event
		const startString = new Date(event.start).toLocaleDateString('en-US', { weekday: 'short', month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' })
		const endString = new Date(event.end).toLocaleDateString('en-US', { weekday: 'short', month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' })

		return (
			<span className='event-detail-text-section d-flex flex-column justify-content-between h-100'>
				<div>
					<p className='section-header-text text-muted'>DATE</p>
					<p className='event-detail-text'>{`${startString} - `}</p>
					<p className='event-detail-text'>{`${endString}`}</p>
				</div>
				<div>
					<AddToCalendarComponent event={event} />
				</div>
			</span>
		)
	}

	toggle = () => {
		this.setState({ dropdownOpen: !this.state.dropdownOpen })
	}

	buildAdminRSVPButtonGroup = () => {
		return (
			<RS.ButtonDropdown
				className={``}
				isOpen={this.state.dropdownOpen}
				toggle={this.toggle}
			>
				<RS.Button
					className={'top-bar-button'}
					key={'rsvp-button'}
					color='primary'
					onClick={this.handleRsvp}
				>
					RSVP
				</RS.Button>
				<RS.DropdownToggle color='primary' caret={true} />
				<RS.DropdownMenu>
					<RS.DropdownItem
						key={'bulk-rsvp-button'}
						onClick={this.handleBulkRSVP}
					>
						Bulk RSVP
					</RS.DropdownItem>
				</RS.DropdownMenu>
			</RS.ButtonDropdown>
		)
	}

	buildMemberRSVPButton = () => {
		// Check to see if the User has an RSVP
		const rsvpButtonText = this.state.existingRSVP && this.props.isCustomerView ? 'View RSVP' : 'RSVP'
		return (
			<button
				className='btn btn-primary btn-block'
				onClick={this.handleRsvp}
			>
				{rsvpButtonText}
			</button>
		)
	}

	buildPublicRSVPButton = () => {
		return (
			<button
				className='btn btn-primary btn-block'
				onClick={this.props.handlePublicRsvp}
			>
				{'RSVP'}
			</button>
		)
	}

	/**
	 * Build the section of the information card that displays the Event's RSVPs.
	 */
	buildRsvpSection = () => {
		const event = this.state.event
		const { userState } = this.props
		if (!event.requiresRSVP) { return }

		// Check to see that the User is logged in
		let button
		if (!userState || !userState.loggedInUser) {
			button = this.buildPublicRSVPButton()
		} else {
			button = this.props.isCustomerView ? this.buildMemberRSVPButton() : this.buildAdminRSVPButtonGroup()
		}

		let avatarList
		if (!userState || !userState.loggedInUser) {
			// Nothing
		} else {
			avatarList = this.buildAvatarList()
		}

		return (
			<span className='event-detail-text-section d-flex flex-column justify-content-end h-100'>
				{button}
				{avatarList}
			</span>
		)
	}

	/**
	 * Build a list of avatars, which represent users who have RSVP'd to the Event
	 */
	buildAvatarList = () => {
		const event = this.state.event
		if (!event.requiresRSVP) { return }

		// Determine the set of Members to use based on context
		const reservations = event.reservations
		const users = oc(this).props.userState.users([])

		// Get a list of all the Participants
		const eventParticipants = flatten(
			reservations.map((reservation) => {
				return reservation.participants.map((participant) => {
					return users.find((user: core.User.Model) => `${user._id}` === `${participant.userID}`)
				})
			}), true)

		const totalRSVPs = eventParticipants.length
		if (totalRSVPs === 0) {
			return null
		}

		return (
			<div className='d-flex align-items-center justify-content-between mt-3'>
				<button
					className='btn btn-link underscored-link d-flex justify-content-between align-items-center'
					onClick={this.handleViewRsvps}
				>
					{`View ${totalRSVPs} RSVPs`}
					<Feather.ChevronRight size={20} />
				</button>
			</div>
		)
	}

	buildUserSelectModal = () => {
		if (!this.state.showingUserSelectModal) { return }
		const users = oc(this).props.bookableMembers([])
		return (
			<UserSelectModal
				users={users}
				title='Select Users'
				cancelButtonHandler={this.handleCancelUserSelect}
				submitButtonHandler={this.handleUsersSelected}
			/>
		)
	}

	buildEventDetail = () => {
		const event = this.state.event
		if (!event) {
			return null
		}
		const { loading } = this.state
		if (loading) { return <DimmedLoader component={null} isLoading={true} /> }

		return (
			<div className='event-detail mx-auto'>
				{this.buildBackHeader()}

				<RS.Container className={'event-detail-container card'}>
					<RS.Row className={'event-detail-image-row'}>
						<RS.Col className={'event-detail-col'} md={12}>
							{this.buildEventPhotoCard()}
						</RS.Col>
					</RS.Row>
					<RS.Row className={'event-detail-title-row'}>
						<RS.Col className={'event-detail-title-col'} md={8}>
							<h3 className='event-detail-title'>{`${event.name}`}</h3>
							{this.buildEventDescriptionCard()}
						</RS.Col>

						<RS.Col className={'event-detail-title-col'} md={4}>
							{this.buildEventInfoCard()}
						</RS.Col>
					</RS.Row>
				</RS.Container>
				{this.buildCancelRsvpModal()}
				{this.buildUserSelectModal()}
			</div>
		)
	}

	render = () => {
		return this.buildEventDetail()
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	eventState: state.event,
	calendarState: state.calendar,
	userState: state.user,
	loggedInClub: state.club.loggedInClub,
	isCustomerView: inCustomerViewSelector(state),
	eventsByID: eventsByIDSelector(state),
	calendarsByID: calendarsByIDSelector(state),
	calendarGroupsByID: calendarGroupsByIDSelector(state),
	bookableMembers: bookableMembers(state)
})

const mapDispatchToProps = {
	...CustomerActions,
	...EventActions,
	...AlertActions
}

const enhance = compose<React.ComponentType<ComponentProps>>(
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(EventDetailComponent)
