// External Dependencies
import * as Yup from 'yup'

// Internal Dependencies
import { FormInputType, FormInput } from '../../../Shared/Form'

export interface SectionPageFormState {
	name: string
}

export const SectionPageFormInputs: FormInput[] = [
	{
		title: 'Name',
		property: 'name',
		type: FormInputType.TEXT,
		size: 12,
		placeholder: 'Enter a name...',
		defaultValue: '',
		validation: Yup.string().required('Field is required.'),
	}
]
