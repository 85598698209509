import * as core from 'club-hub-core'
import { oc } from 'ts-optchain'

import { PageHeaderSelectInput, PageHeaderInputType } from '../components/Shared/PageHeader'
import { ReactSelectItem } from '../components/Shared/Form'

/**
 * Builds an array of SelectInput objects for a resource type type.
 * @param resourceType The resource type which has select options.
 * @param club The club object which contains the type config.
 * @param filter The filter to apply.
 * @param changeHandler Change handler called when a type is selected.
 */
export const selectInputsForType = (resourceType: string, club: core.Club.Model, filter: string, changeHandler: any): PageHeaderSelectInput[] => {
	let types
	switch (resourceType) {
		case 'News':
			types = oc(club).resources.posts.types([])
			break
		case 'Events':
			types = oc(club).resources.events.types([])
			break
		default:
			break
	}

	const allTypes = { label: 'All', value: 'All' }
	const typesForSelect = types.map((type) => {
		return {
			label: type.title,
			value: `${type._id}`,
		}
	})
	const filterTypesForSelect = [allTypes].concat(typesForSelect)

	const selectedValue = (filter) ?
		filterTypesForSelect.find((item) => item.value === filter) :
		allTypes

	return [{
		inputType: PageHeaderInputType.Select,
		inputs: [allTypes].concat(typesForSelect),
		changeHandler: changeHandler,
		selected: selectedValue,
	}]
}

export const selectInputsForCalendars = (calendars: core.Calendar.Model[]): ReactSelectItem[] => {
	const typesForSelect = calendars.map((calendar: core.Calendar.Model) => {
		return {
			label: calendar.name,
			value: `${calendar._id}`,
		}
	})
	if (oc(calendars).length(0) > 1) {
		typesForSelect.push({ label: 'All', value: 'All' })
	}
	return typesForSelect
}
