// External Dependencies
import * as Yup from 'yup'

// Internal Dependencies
import { FormInputType, FormInput, InputSelectionItem } from '../../Shared/Form'
import { stateSelectionInputs } from '../../../helpers/form'
import * as Constants from '../../../constants/index'

export interface LocationFormState {
	name: string
	address1?: string
	address2?: string
	city?: string
	state?: InputSelectionItem,
	zip?: string
}

export const LocationModalInputs: FormInput[] = [
	{
		title: 'Name',
		property: 'name',
		type: FormInputType.TEXT,
		size: 12,
		placeholder: 'Enter a name...',
		defaultValue: '',
		validation: Yup.string().required(Constants.REQUIRED_FIELD)
	},
	{
		title: 'Address 1',
		property: 'address1',
		type: FormInputType.TEXT,
		size: 12,
		placeholder: 'Enter a address...',
		defaultValue: '',
		validation: Yup.string().required(Constants.REQUIRED_FIELD)
	},
	{
		title: 'Address 2',
		property: 'address2',
		type: FormInputType.TEXT,
		size: 12,
		placeholder: 'Enter a address...',
		defaultValue: '',
	},
	{
		title: 'City',
		property: 'city',
		type: FormInputType.TEXT,
		size: 6,
		placeholder: 'Enter city...',
		defaultValue: '',
		validation: Yup.string().required(Constants.REQUIRED_FIELD)
	},
	{
		title: 'State',
		property: 'state',
		type: FormInputType.SELECT,
		size: 3,
		placeholder: 'State',
		selectItems: stateSelectionInputs.map((input) => ({ label: input.value, value: input.value })),
		defaultValue: null,
		validation: Yup.mixed().required(Constants.REQUIRED_FIELD)
	},
	{
		title: 'Zip',
		property: 'zip',
		type: FormInputType.TEXT,
		size: 3,
		placeholder: 'Enter a zip...',
		defaultValue: '',
		validation: Yup.string().required(Constants.REQUIRED_FIELD)
	}
]
