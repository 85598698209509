// External Dependencies
import * as React from 'react'
import ReactPaginate from 'react-paginate'

interface Props {
	initialPage: number
	currentPage: number
	pageSize: number
	totalResults: number
	onPageChange: (data: any) => any
}

type State = any

export default class PaginationComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	handlePage = async (data: any) => {
		return this.props.onPageChange(data)
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	render() {
		const pageSize = this.props.pageSize || 1
		const totalResults = this.props.totalResults || 0
		const start = this.props.currentPage * pageSize
		const end = Math.min(start + pageSize, totalResults)

		return (
			<div className='pagination-container d-flex justify-content-between'>
				<div>
					{`Showing ${start + 1} to ${end} of ${totalResults} entries`}
				</div>
				<ReactPaginate
					initialPage={this.props.initialPage}
					forcePage={this.props.currentPage}
					previousLabel={'Previous'}
					nextLabel={'Next'}
					breakLabel={'...'}
					pageCount={Math.ceil(totalResults / pageSize)}
					marginPagesDisplayed={2}
					pageRangeDisplayed={5}
					onPageChange={this.handlePage}
					containerClassName={'pagination'}
					previousClassName={'page-item'}
					previousLinkClassName={'page-link'}
					nextClassName={'page-item'}
					nextLinkClassName={'page-link'}
					pageClassName={'page-item page-button'}
					pageLinkClassName={'page-link'}
					activeClassName={'active'}
					breakClassName={'pagination-break page-link-parent'}
				/>
			</div>
		)
	}
}
