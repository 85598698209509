// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import * as core from 'club-hub-core'
import { Field, FieldProps } from 'formik'

// Internal Dependencies

// Components
import { InputSelectionItem, FormInput, FormInputType } from '../../Shared/Form'
import { buildInputPrimitive } from '../../Shared/Formik'

interface ComponentProps {
	reservationSettings: core.Calendar.ReservationSetting[]
	value: InputSelectionItem
	onChange: (field: string, data: InputSelectionItem) => Promise<void>
	submitForm: () => void
	handleEdit: () => void
}

type Props = ComponentProps

/**
 * This section is where the user will select a date range, which will
 * determine the range of dates that will be affected by the settings in the form.
 *
 * In this section, the user can:
 * 	- Choose an existing range
 * 	- Create a new range
 * 	- Edit the currently selected range
 * 	- Save the changes that have been made to the settings
 */
export const DateRangeSection: React.FunctionComponent<Props> = (props: Props) => {
	const options = props.reservationSettings.map((setting) => {
		const label = (setting.isDefault) ?
			setting.name :
			`${new Date(setting.dateRangeStart).toLocaleDateString()} - ${new Date(setting.dateRangeEnd).toLocaleDateString()} : ${setting.name}`
		return {
			label: label,
			value: setting._id
		}
	})

	const selectOptions: any[] = [
		{ options: [...options] },
		{ label: '', options: [{ label: 'Add New...', value: 'addRange' }] }
	]

	const formInput: FormInput = {
		title: '',
		property: 'settingDateRange',
		type: FormInputType.GROUPED_SELECT,
		selectItems: selectOptions,
		defaultValue: props.value
	}

	return (
		<div className='reservationSettings-date-range'>
			<RS.Label for={'date-range'} className='form-label'>
				Select a date range to configure your options.
			</RS.Label>
			<div className='reservationSettings-date-range-row'>
				<div className='reservationSettings-date-range-select'>
					<Field name={formInput.property}>
					{(fieldProps: FieldProps<any>) => (
						buildInputPrimitive(formInput, fieldProps, { onChange: props.onChange } as any)
					)}
					</Field>
				</div>
			</div>
		</div>
	)
}
