// External Dependencies
import * as React from 'react'

// Form
import { PDFModalState, PDFModalInputs } from './form'

// Components
import FormModal from '../../Shared/Formik/FormModal'

interface ComponentProps {
	name: string
	link: string
	submitHandler: (form: PDFModalState) => void
	cancelHandler: () => void
}

const initialState = {
	name: '',
	link: '',
}

type Props = ComponentProps
type State = typeof initialState

/**
 * The `BlockModal` provides UI for blocking a calendar.
 */
class BlockModal extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)

		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Component Builders
	// ----------------------------------------------------------------------------------

	public render() {
		const formInputs = PDFModalInputs()
		const resource: any = { name: this.props.name, link: this.props.link }
		return (
			<FormModal
				modalTitle={'Insert PDF'}
				formSpec={formInputs}
				formResource={resource}
				submitButtonHandler={this.props.submitHandler}
				cancelButtonHandler={this.props.cancelHandler}
			/>
		)
	}
}
export default BlockModal
