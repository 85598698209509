// External Dependencies
import * as React from 'react'
import { isNullOrUndefined } from 'util'

// Internal Dependencies
import { BaseTablePrimitiveProps } from '../index'

type Props<T> = BaseTablePrimitiveProps<T> & { children: any, isDragOccurring?: boolean } & React.HTMLAttributes<any>

export class BaseTableCell<T> extends React.Component<Props<T>> {
	ref: HTMLElement

	getSnapshotBeforeUpdate(prevProps: Props<T>) {
		if (!this.ref) {
			return null
		}

		const isDragStarting: boolean =
			this.props.isDragOccurring && !prevProps.isDragOccurring

		if (!isDragStarting) {
			return null
		}

		const { width, height } = this.ref.getBoundingClientRect()

		const snapshot = { width, height }
		return snapshot
	}

	componentDidUpdate(prevProps: Props<T>, prevState: any, snapshot: any) {
		const ref = this.ref
		if (!ref) {
			return
		}

		if (snapshot) {
			if (ref.style.width === snapshot.width) {
				return
			}
			ref.style.width = `${snapshot.width}px`
			ref.style.height = `${snapshot.height}px`
			return
		}

		if (this.props.isDragOccurring) {
			return
		}

		// inline styles not applied
		if (isNullOrUndefined(ref.style.width)) {
			return
		}

		// no snapshot and drag is finished - clear the inline styles
		ref.style.removeProperty('height')
		ref.style.removeProperty('width')
	}

	setRef = (ref: HTMLElement) => {
		this.ref = ref
	}

	render() {
		// Destructure the props so that we don't pass unnecessary
		// props to the DOM node
		const {
			item,
			column,
			index,
			children,
			isDragOccurring,
			...others
		} = this.props

		return (
			<td key={`tableCell_${column.title}_${index}`} ref={this.setRef} {...others}>
				{children}
			</td>
		)
	}
}
