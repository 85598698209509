// External Dependencies.
import { oc } from 'ts-optchain'
import * as core from 'club-hub-core'

// Internal Dependencies
import { PLACEHOLDER_IMG } from '../constants'

export enum ImageSize {
	Large = 'lg',
	Medium = 'md',
	Small = 'sm',
	ExtraSmall = 'xs',
	Micro = 'micro'
}

// Check if the given imageURL fits our criteria for being a valid image
// TODO: Make this more robust, only allowing images from specific domains that are whitelisted
export const isValidImage = (imageURL: string) => {
	if (!imageURL) { return false }

	const validChecks = ['.png', '.jpeg', '.jpg', 'githubusercontent.com', 'images.unsplash.com']
	const lowercaseImageURL = imageURL.toLowerCase()
	for (const check of validChecks) {
		if (lowercaseImageURL.includes(check)) {
			return true
		}
	}
	return false
}

/**
 * Checks that the supplied imageURL is valid. If it's not valid
 * a fallback image URL will be returned. We look for the
 * fallback image on the club model at club.website.
 * If no image exists, use the Clubhub imageURL from constants.
 * @param imageURL
 * @param club
 */
export const imageForResource = (image: core.SubModels.Image.Model, club: core.Club.Model, size: ImageSize) => {
	// Get the URL for the relevant size of the image (lg, md, sm, xs, or micro)
	const original = oc(image).original()
	const sizedImageURL = oc(image)[size](original)

	// If the URL is valid, return it, otherwise return the appropriately sized placeholder
	// image for the Club. If that is undefined, return the default placeholder image.
	return (isValidImage(sizedImageURL)) ? sizedImageURL :
		oc(club).website.placeholderImage[size](PLACEHOLDER_IMG)
}
