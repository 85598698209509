// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as RS from 'reactstrap'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { FormikProps, FormikValues } from 'formik'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies

// Components
import DimmedLoader from '../DimmedLoader'

// Form
import { DinnerReservationFormState } from '../../Forms/DinnerReservation'
import { FormikComponent, BuildWrappedForm } from '../Formik'
import { FormInput } from '../../Shared/Form'

// Actions
import { EventActions, AlertActions } from '../../../actions/index'

// State
import { RootReducerState } from '../../../reducers'

// Helpers
import * as Constants from '../../../constants'
import { setStateAsync } from '../../../helpers/promise'
import { userForForm } from '../../../helpers/user'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

interface ComponentProps {
	inputs: FormInput[]
	eventCalendarID: string,
	onCancel: () => void,
	onSave: (formState?: DinnerReservationFormState) => void,
	onDelete: () => void,
	reservationID?: string,
	initialTime?: Date
	formTitle?: string
}

const initialState = {
	loading: true,
	eventTime: null as Date | null,
	showModal: true,
	showConfirmDelete: false,
	formResource: null as any,
}

type Props = ConnectedState & ConnectedActions & ComponentProps
type State = typeof initialState

class ReservationForm extends React.Component<Props, State> {
	private maxDiners: number
	private currentEvent: core.Event.AvailableEvent

	constructor(props: Props) {
		super(props)

		let state: State = { ...initialState }
		if (props.initialTime) {
			state = {
				...state,
				eventTime: props.initialTime,
				formResource: { time: props.initialTime }
			}
		}
		this.state = { ...state }
		this.maxDiners = 12
	}

	// ----------------------------------------------------------------------------------
	// Life cycle Methods
	// ----------------------------------------------------------------------------------

	componentDidMount = async () => {
		// Check if we are editing an existing Reservation
		if (this.props.reservationID) {
			await this.setExistingReservationState()
		}
		await setStateAsync(this, { loading: false })
	}

	componentDidUpdate = async (prevProps: Props) => {
		// Check if we are editing an existing Reservation
		if (this.props.reservationID && this.props.reservationID !== prevProps.reservationID) {
			await setStateAsync(this, { loading: true })
			await this.setExistingReservationState()
			await setStateAsync(this, { loading: false })
		}
	}

	// ----------------------------------------------------------------------------------
	// State Queries
	// ----------------------------------------------------------------------------------

	isInUpdateMode = (): boolean => {
		return !isNullOrUndefined(this.props.reservationID)
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	handleToggle = async () => {
		await setStateAsync(this, { showModal: !this.state.showModal })
	}

	handleFormChange = async (field: string, value: any) => {
		if (field === 'dateinput_time') {
			await setStateAsync(this, { eventTime: value })
		}
	}

	/**
	 * Called when the user is submitting a reservation.
	 */
	handleSave = async (formState: DinnerReservationFormState) => {
		return this.props.onSave(formState)
	}

	/**
	 * Called when the user is submitting an update to an existing reservation.
	 */
	handleUpdate = async (formState: DinnerReservationFormState) => {
		if (!formState.guests) {
			formState.guests = this.state.formResource.guests.value
		}

		return this.props.onSave(formState)
	}

	/**
	 * Called when the user checks in.
	 */
	handleCheckIn = async (checkedInState: boolean) => {
		await setStateAsync(this, { loading: true })
		const currentEventID = `${this.currentEvent.existingEvent._id}`

		// Build the payload for the Reservation, with
		// the updated participant list
		const existingReservation = this.getCurrentReservation()
		const updatedReservation: core.Event.Reservation = {
			...existingReservation,
			participants: this.buildUpdatedParticipantsList(existingReservation, checkedInState)
		}

		// Update the event.
		const [err] = await to(this.props.updateEventRsvp(this.props.reservationID, updatedReservation, currentEventID) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to update reservation. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false })
			return
		}

		this.props.fireFlashMessage('Successfully updated RSVP', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false })
	}

	/**
	 * Called when the user closes out of the modal
	 */
	handleCancel = async () => {
		this.props.onCancel()
	}

	/**
	 * Called when the user has clicked on the 'delete' button.
	 * On first call, it will toggle the delete confirmation
	 * text on the button, and on second click it will make
	 * the call to delete the tee time reservation
	 */
	handleDelete = async () => {
		if (this.state.showConfirmDelete) {
			await this.props.onDelete()
			return
		}
		await setStateAsync(this, { showConfirmDelete: true })
	}

	// ----------------------------------------------------------------------------------
	// State Changes
	// ----------------------------------------------------------------------------------

	/**
	 * If we are updating an existing tee time, we need to update
	 * the state of the form to reflect the existing tee time
	 */
	setExistingReservationState = async () => {
		// Find the Event and Reservation.
		const eventDataForCalendar = this.props.eventState.bookableEvents.find((data) => `${data.calendarID}` === this.props.eventCalendarID)
		const events = eventDataForCalendar.eventInfo
		let eventReservation: core.Event.Reservation
		this.currentEvent = events.find((event: core.Event.AvailableEvent) => {
			if (!event.existingEvent) { return false }
			eventReservation = event.existingEvent.reservations.find((r) => `${r._id}` === `${this.props.reservationID}`)
			return !isNullOrUndefined(eventReservation)
		})
		if (!this.currentEvent) { return }

		// Find the Member
		const members = oc(this).props.userState.users([])
		const reservationOwner = members.find((member) => member._id === eventReservation.owner)
		const reservationOwnerForForm = userForForm(reservationOwner)

		const numGuests = eventReservation.participants.length - 1
		const guestsForForm = {
			label: `${numGuests}`,
			value: `${numGuests}`,
		}

		const notesForForm = (eventReservation.meta) ? eventReservation.meta.notes : null

		const timeString = new Date(this.currentEvent.time).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' })
		const modalTitle = `${eventReservation.participants.length} Guests @ ${timeString}`
		const formResource = {
			time: new Date(this.currentEvent.time),
			member: reservationOwnerForForm,
			guests: guestsForForm,
			notes: notesForForm,
			modalTitle: modalTitle
		}

		await setStateAsync(this, { formResource: { ...formResource } })
	}

	// ----------------------------------------------------------------------------------
	// Form Buttons - TODO make a component for this
	// ----------------------------------------------------------------------------------

	// Builds the Save / Update + Delete buttons for the form; depending on context
	buildFormModalButtons = (formikProps: FormikProps<FormikValues>) => {
		const updatingRsvp = this.isInUpdateMode()
		return (
			<>
				<RS.Col xs={3} className='modal-button-col'>
					{this.cancelButton()}
				</RS.Col>
				<RS.Col xs={9} className='d-flex justify-content-end modal-button-col'>
					{(updatingRsvp) ? this.deleteButton() : null}
					{this.submitButton(updatingRsvp, formikProps)}
				</RS.Col>
			</>
		)
	}

	submitButton = (updating: boolean, formikProps: FormikProps<FormikValues>) => {
		const submitText = `${(updating) ? 'Update' : 'Create'}`
		const didSubmit = this.state.loading
		return (
			this.formButton(submitText, formikProps.submitForm, 'affirmative', didSubmit, false)
		)
	}

	cancelButton = () => {
		return (
			this.formButton('Cancel', this.handleCancel, `cancel reservationForm-cancel-btn`, false, false)
		)
	}

	checkInButton = () => {
		const res = this.getCurrentReservation()
		const participant = res.participants.find((p) => p.userID === res.owner)
		const checkInText = (participant.checkedIn) ? 'Cancel Check In' : 'Check In'
		const didCheckIn = this.state.loading
		return (
			this.formButton(checkInText, () => this.handleCheckIn(!participant.checkedIn), 'reservationForm-checkin-btn btn-block', didCheckIn, false)
		)
	}

	deleteButton = () => {
		// Build the delete button.
		const deleteText = `${(this.state.showConfirmDelete) ? 'Confirm Delete?' : 'Delete'}`
		return (
			this.formButton(deleteText, this.handleDelete, `reservationForm-delete-btn`, false, false)
		)
	}

	// Build a form Button.
	formButton = (text: string, onClick: any, color: string, loading: boolean, disabled: boolean) => {
		const btnClass = classNames({
			'btn': true,
			[color]: true,
			'btn-loading': loading,
		})
		return (
			<button
				type='button'
				className={btnClass}
				onClick={onClick}
				disabled={disabled}
			>
				{text}
			</button>
		)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	/**
	 * Creates an updated version of a Reservation's participant list, where the
	 * 'checkedIn' value of the Reservation's owner is set to 'checkedInState'.
	 * @param reservation The Reservation, whose participant list we are building.
	 * @param checkedInState The value that the Reservation's owner will have their 'checkedIn' field set to.
	 * @returns The updated list of participants.
	 */
	buildUpdatedParticipantsList = (reservation: core.Event.Reservation, checkedInState: boolean) => {
		const updatedParticipants = reservation.participants.map((p) => {
			const participantID = oc(p).userID()
			// We found the owner of the Reservation, update their 'checkedIn' state
			if (!isNullOrUndefined(participantID) && participantID === reservation.owner) {
				return { ...p, checkedIn: checkedInState }
			}
			return p
		})
		return updatedParticipants
	}

	buildReservation = (formState: DinnerReservationFormState): core.Event.Reservation => {
		// Build participant array.
		const participants: core.Event.Participant[] = Array.from({ length: Number(formState.guests.value) }, (v) => {
			return {
				userID: undefined,
				name: 'Guest',
				checkedIn: false,
				paid: false,
			}
		})

		const creatorParticipant: core.Event.Participant = {
			userID: formState.member.value as any,
			name: formState.member.label,
			checkedIn: false,
			paid: false,
		}
		participants.push(creatorParticipant)

		const reservation: core.Event.Reservation = {
			creator: this.props.userState.loggedInUser._id,
			owner: creatorParticipant.userID,
			participants: participants,
			meta: {
				notes: formState.notes,
			},
		}
		return reservation
	}

	// ----------------------------------------------------------------------------------
	// Form Builder
	// ----------------------------------------------------------------------------------

	// Builds the Modal that holds the form for this component
	buildFormModal = (formikProps: FormikProps<FormikValues>) => {
		let modalTitle

		if (this.props.formTitle) {
			modalTitle = this.props.formTitle
		} else if (this.state.formResource.modalTitle) {
			modalTitle = this.state.formResource.modalTitle
		} else if (this.props.initialTime) {
			const eventTime = new Date(this.state.eventTime) || new Date()
			const dateString = eventTime.toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: '2-digit', year: 'numeric' })
			const timeString = eventTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
			modalTitle = `${dateString} @${timeString}`
		} else {
			modalTitle = 'Reservation'
		}
		const updatingRsvp = this.isInUpdateMode()
		const formInputs = (updatingRsvp) ? [] : this.props.inputs
		return (
			<RS.Modal
				isOpen={this.state.showModal}
				toggle={this.handleToggle}
				onClosed={this.handleCancel}
				className='reservationForm-modal'
			>
				<RS.ModalHeader toggle={this.handleToggle}>
					{modalTitle}
				</RS.ModalHeader>
				<RS.ModalBody>
					{BuildWrappedForm({ inputs: formInputs, onChange: this.handleFormChange }, formikProps)}
					{(updatingRsvp) ? this.checkInButton() : null}
				</RS.ModalBody>
				<RS.ModalFooter>
					{this.buildFormModalButtons(formikProps)}
				</RS.ModalFooter>
			</RS.Modal>
		)
	}

	render() {
		if (this.state.loading) { return <DimmedLoader component={null} isLoading={true} /> }

		const isUpdateForm = this.isInUpdateMode()
		const submitHandler = (isUpdateForm) ? this.handleUpdate : this.handleSave
		const formInputs = (isUpdateForm) ? [] : this.props.inputs

		return (
			<FormikComponent
				inputs={formInputs}
				onSubmit={submitHandler}
				enableReinitialize={false}
				formResource={{ ...this.state.formResource }}
				render={this.buildFormModal}
			/>
		)
	}

	// --------------------------------------
	// Helpers
	// --------------------------------------

	getCurrentReservation = () => {
		const reservations = this.currentEvent.existingEvent.reservations
		const reservationID = this.props.reservationID
		return reservations.find((reservation: core.Event.Reservation) => `${reservation._id}` === reservationID)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	eventState: state.event,
	userState: state.user,
	calendarState: state.calendar,
})

const mapDispatchToProps = {
	...EventActions,
	...AlertActions,
}

const enhance = compose<React.ComponentType<ComponentProps>>(
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(ReservationForm)
