// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import * as Feather from 'react-feather'
import * as core from 'club-hub-core'
import classNames from 'classnames'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'

// Internal Dependencies

// Components
import AvatarComponent from '../../../Shared/Avatar'
import { ReservationActionButtons, ReservationCellAction } from '../../shared/ReservationActionButtons'

// Helpers
import * as Constants from '../../../../constants'
import { setStateAsync } from '../../../../helpers/promise'

export enum ReservationCellType {
	Open = 'open',
	Blocked = 'blocked',
	Unavailable = 'unavailable',
	Reserved = 'reserved'
}

interface ComponentProps {
	id: string
	type: ReservationCellType
	size?: number
	event: core.Event.AvailableEvent
	reservation?: core.Event.Reservation
	club: core.Club.Model
	user?: core.User.Model
	color?: string
	participant?: core.Event.Participant
	loggedInUser: core.User.Model
	lotteryDay?: boolean
	onActionClick?: (action: ReservationCellAction) => void
	onClick?: (action?: ReservationCellAction) => void
	onUnavailableClick?: () => Promise<void>
}

const initialState = {
	dropdownOpen: false
}

type Props = ComponentProps
type State = typeof initialState

class ReservationCellComponent extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props)

		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Handlers
	// ----------------------------------------------------------------------------------

	handleToggle = () => {
		this.setState({ dropdownOpen: !this.state.dropdownOpen })
	}

	handleNewTeeTimeClick = () => {
		this.handleToggle()
		this.props.onClick(ReservationCellAction.NEW_TEE_TIME)
	}

	handleNewBlockClick = () => {
		this.handleToggle()
		this.props.onClick(ReservationCellAction.NEW_BLOCK)
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildOpenCell = () => {
		const className = 'golf-reservation-col open d-flex align-items-center justify-content-center'
		const openClassName = classNames({
			'open-cell-content-container': true,
			'd-flex': true,
			'align-items-center': true,
			'justify-content-center': true
		})

		return (
			<RS.Col sm='12' md={''} id={this.props.id} className={className} onClick={this.handleNewTeeTimeClick}>
				<div className={openClassName}>
					<Feather.PlusCircle size={20} />
				</div>
			</RS.Col >
		)
	}

	buildUnavailableCell = (text?: string) => {
		const unavailableText = text || 'Tee Time Unavailable'
		const onClick = this.props.loggedInUser.admin ? this.props.onUnavailableClick : null
		const unavailableClassName = classNames({
			'golf-reservation-col': true,
			'd-flex': true,
			'no-drop': !this.props.loggedInUser.admin
		})
		return (
			<RS.Col sm='12' md={''} id={this.props.id} className={unavailableClassName} onClick={onClick}>
				<div className='unavailable-cell-content-container d-flex align-items-center justify-content-center'>
					<Feather.XCircle color={'#A0A6B1'} size={18} />
					<span className='text-muted'>{unavailableText}</span>
				</div>
			</RS.Col>
		)
	}

	buildReservedCell = () => {
		// Determine if we are building a cell for an anonymous Guest
		const isAnonGuest = (isNullOrUndefined(oc(this).props.user()) && isNullOrUndefined(oc(this).props.participant.userID()))
		const name = (isAnonGuest) ?
			'Member' : // Todo, get this name on the actual res, but I believe we will break mobile.
			`${oc(this).props.user.firstName('')[0]}. ${oc(this).props.user.lastName()}`
		const hasPaid = oc(this).props.participant.paid(false)
		const hasCheckedIn = oc(this).props.participant.checkedIn(false)
		const meta: core.Event.GolfReservationMeta = oc(this).props.reservation.meta()
		const cellButtons = (
			<ReservationActionButtons
				holes={oc(meta).holeCount()}
				hasPaid={hasPaid}
				hasCheckedIn={hasCheckedIn}
				includePayButton={true}
				onActionClick={this.props.onActionClick}
			/>
		)
		const size = this.props.size > 0 ? this.props.size : ''
		const types = oc(this).props.club.resources.members.types([])
		const type = types.find((t: core.Club.ResourceType) => `${t._id}` === `${oc(this).props.user.membershipType()}`)
		const style = { backgroundColor: '' }
		if (type) {
			style.backgroundColor = type.color
		}

		return (
			<RS.Col sm='12' md={size} className='golf-reservation-col' onClick={() => this.props.onClick()}>
				<div style={style} className='golf-reservation-content-container d-flex align-items-center justify-content-between'>
					<div className={'d-flex flex-row align-items-center'}>
						<AvatarComponent user={this.props.user} />
						<span>{name}</span>
					</div>
					{cellButtons}
				</div>
			</RS.Col>
		)
	}

	buildBlockCell = () => {
		const eventName = oc(this).props.event.existingEvent.name()
		const blockedText = `Blocked for Event ${(eventName) ? `- ${eventName}` : ''}`
		return this.buildUnavailableCell(blockedText)
	}

	render() {
		const isInPast = new Date(this.props.event.time).getTime() < new Date().getTime()
		switch (this.props.type) {
			case ReservationCellType.Open:
				return (isInPast && !this.props.loggedInUser.admin) ?
					this.buildUnavailableCell() :
					this.buildOpenCell()
			case ReservationCellType.Reserved:
				return this.buildReservedCell()
			case ReservationCellType.Blocked:
				return this.buildBlockCell()
			case ReservationCellType.Unavailable:
				return this.buildUnavailableCell()
		}
	}
}

export default ReservationCellComponent
