// External Dependencies
import * as Yup from 'yup'
import * as core from 'club-hub-core'

// Internal Dependencies
import { FormInput, FormInputType, ReactSelectItem, InputSelectionItem } from '../../Shared/Form'
import * as Constants from '../../../constants/index'

export interface MessageFormState {
	sender: InputSelectionItem,
	messageRecipients: ReactSelectItem[],
	deliveryDate: Date,
	title?: string,
	messageText?: string
	richContent?: core.SubModels.RichContent.Model
	subject?: string,
	status: string
}

export const IMMEDIATE_DELIVERY = 'immediate'
export const SCHEDULED_DELIVERY = 'schedule'

export const MessageFormInputs = (recipients?: ReactSelectItem[], senders?: ReactSelectItem[]): FormInput[] => {
	const inputs: FormInput[] = [
		{
			title: 'Subject',
			property: 'title',
			type: FormInputType.TEXT,
			size: 12,
			placeholder: 'Enter subject...',
			defaultValue: '',
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Body',
			property: 'richContent',
			type: FormInputType.MCE_EDITOR,
			defaultValue: '',
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		}
	]

	if (recipients) {
		inputs.unshift({
			title: 'To',
			property: 'messageRecipients',
			type: FormInputType.GROUPED_MULTI_SELECT_TYPEAHEAD,
			size: 12,
			placeholder: 'Who should receive the message?',
			selectItems: recipients,
			defaultValue: null,
			validation: Yup.array().of(
				Yup.object().shape({
					label: Yup.string(),
					value: Yup.string(),
				})
			).nullable().required(Constants.REQUIRED_FIELD),
		})
	}

	if (senders) {
		inputs.unshift({
			title: 'From',
			property: 'sender',
			type: FormInputType.SELECT,
			size: 12,
			placeholder: 'Who is sending the message?',
			selectItems: senders,
			defaultValue: null,
			validation: Yup.string().nullable().required(Constants.REQUIRED_FIELD),
		})
	}

	return inputs
}
