// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies

// Actions
import { AlertActions, ClubActions } from '../../../actions/index'

// State
import { RootReducerState } from '../../../reducers'

// Components
import TableComponent, { TableColumn } from '../../Shared/Table'
import FormModal from '../../Shared/Formik/FormModal'
import ModalComponent from '../../Shared/Modal'

// Table
import { ClubTypeTableActions, ClubTypeTableColumns } from './table'

// Form
import { EditTypeForm, EditTypeFormInputs } from './form'

// Helpers
import { setStateAsync } from '../../../helpers/promise'
import * as Constants from '../../../constants'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

interface ComponentProps {
	resourceType: keyof core.Club.Resources
	showingEditModal: boolean
	onEditModalClose: () => any
	tableColumns?: TableColumn[]
	tableMappingFunction?: (item: core.Club.ResourceType) => any
}

// State Key Constants
type ModalStateKey = typeof ShowingLocalEditModal | typeof ShowingDeletionModal
const ShowingLocalEditModal = 'showingLocalEditModal'
const ShowingDeletionModal = 'showingDeletionModal'

const initialState = {
	loading: false,
	error: false,
	pageIndex: 0,
	[ShowingLocalEditModal]: false,
	[ShowingDeletionModal]: false,
	typeToEdit: null as string | null,
	typeToDelete: null as string | null
}

type Props = ComponentProps & ConnectedActions & ConnectedState
type State = typeof initialState

class ClubTypesComponent extends React.Component<Props, State> {
	private pageSize: number

	constructor(props: Props) {
		super(props)
		this.pageSize = 20
		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	// ----------------------------------------------------------------------------------
	// Event Handlers - Modal Event Handlers
	// ----------------------------------------------------------------------------------

	executeTypeSave = async (title: string, color: string) => {
		const { resourceType, onEditModalClose, createClubType, updateClubType, fireFlashMessage } = this.props
		const { typeToEdit } = this.state

		await setStateAsync(this, { loading: true })

		const typePath = `resources.${resourceType}.types`
		const updatingResource = !isNullOrUndefined(typeToEdit)

		const resource: core.Club.ResourceType = (updatingResource) ?
			{ _id: typeToEdit as any, title, color } :
			{ title, color }

		const saveFunction = (updatingResource) ?
			updateClubType(typePath, resource) :
			createClubType(resource, typePath)

		const [err] = await to(saveFunction as any)
		if (err) {
			fireFlashMessage(`Problem trying to update Type. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true, typeToEdit: null })
			return
		}

		fireFlashMessage(`Successfully updated Type.`, Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, typeToEdit: null })

		// Close the modal locally (if the modal was triggered in this component)
		await this.handleCloseModal(ShowingLocalEditModal)

		// Close the modal through props (if the modal was triggered from a parent component)
		onEditModalClose()
	}

	executeTypeDeletion = async () => {
		const { resourceType, deleteClubType, fireFlashMessage } = this.props
		const { typeToDelete } = this.state

		await setStateAsync(this, { loading: true })

		const typePath = `resources.${resourceType}.types`
		const [deleteErr] = await to(deleteClubType(typeToDelete, typePath) as any)
		if (deleteErr) {
			fireFlashMessage(`Problem trying to delete Type. ${deleteErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true, typeToDelete: null })
			return
		}

		fireFlashMessage('Successfully deleted Type.', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, typeToDelete: null })
		await this.handleCloseModal(ShowingDeletionModal)
	}

	handleOpenModal = async (modalStateKey: ModalStateKey) => {
		await setStateAsync(this, (() => ({ [modalStateKey]: true })))
	}

	handleCloseModal = async (modalStateKey: ModalStateKey) => {
		await setStateAsync(this, (() => ({
			[modalStateKey]: false,
			typeToEdit: null,
			typeToDelete: null,
		})))
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Dropdown Actions
	// ----------------------------------------------------------------------------------

	handleTableDropdownAction = (e: [ClubTypeTableActions, string]) => {
		const [action, value] = e
		switch (action) {
			case ClubTypeTableActions.Edit:
				return this.handleEditType(value)
			case ClubTypeTableActions.Delete:
				return this.handleDeleteType(value)
			default:
				break
		}
	}

	handleEditType = async (typeID: string) => {
		await setStateAsync(this, { typeToEdit: typeID })
		await this.handleOpenModal(ShowingLocalEditModal)
	}

	handleDeleteType = async (typeID: string) => {
		await setStateAsync(this, { typeToDelete: typeID })
		await this.handleOpenModal(ShowingDeletionModal)
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Event Handlers
	// ----------------------------------------------------------------------------------

	/** Handle table pagination */
	handlePage = async (data: any) => {
		const { pageIndex } = this.state

		// Check if the new page is different that the current one
		if (data.selected !== pageIndex) {
			await setStateAsync(this, { pageIndex: data.selected })
		}
	}

	/** Handle a column header of the table being selected */
	handleTableHeaderAction = (e: any) => {
		// TODO: Add this functionality
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildTable = () => {
		const { resourceType, tableColumns, tableMappingFunction } = this.props
		const { pageIndex, loading } = this.state

		const clubTypes = oc(this).props.loggedInClub.resources[resourceType].types([])
		const total = clubTypes.length

		const startIdx = pageIndex * this.pageSize
		const endIdx = startIdx + this.pageSize
		const typesToShow = clubTypes.slice(startIdx, endIdx)

		// Determine which mapping function to use on the Resources
		const defaultMappingFunction = (value: core.Club.ResourceType) => {
			return {
				...value,
				colorBadge: { title: value.title, color: value.color },
			}
		}
		const mappingFunction = oc(tableMappingFunction)(defaultMappingFunction)
		const typesForTable = typesToShow.map(mappingFunction)

		// Determine which table columns will be used to build the Table
		const defaultTableColumns = ClubTypeTableColumns
		const columnsForTable = oc(tableColumns)(defaultTableColumns)

		return (
			<TableComponent<core.Club.ResourceType>
				columns={columnsForTable}
				rowItems={typesForTable}
				showPaging={true}
				currentPage={pageIndex}
				pageHandler={this.handlePage}
				totalResults={total}
				pageSize={this.pageSize}
				actionHandler={this.handleTableHeaderAction}
				dropdownHandler={this.handleTableDropdownAction}
				isLoading={loading}
			/>
		)
	}

	buildEditModal = () => {
		const { resourceType, showingEditModal, onEditModalClose } = this.props
		const { typeToEdit, showingLocalEditModal } = this.state

		if (!showingEditModal && !showingLocalEditModal) { return null }
		const clubTypes = oc(this).props.loggedInClub.resources[resourceType].types([])
		const existingType = clubTypes.find((clubType) => `${clubType._id}` === typeToEdit)
		const modalTitle = (existingType) ? 'Edit Type' : 'New Type'

		return (
			<FormModal<core.Club.ResourceType, EditTypeForm>
				modalTitle={modalTitle}
				formSpec={EditTypeFormInputs}
				formResource={existingType}
				submitButtonHandler={(values) => this.executeTypeSave(values.title, values.color.value)}
				cancelButtonHandler={async () => {
					await this.handleCloseModal(ShowingLocalEditModal)
					onEditModalClose()
				}}
			/>
		)
	}

	buildDeletionModal = () => {
		const { showingDeletionModal } = this.state

		if (!showingDeletionModal) { return null }
		return (
			<ModalComponent
				modalTitle={'Delete Type'}
				primaryMessage={'Are you sure you want to delete this Type?'}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={() => this.handleCloseModal(ShowingDeletionModal)}
				submitButtonName={'Confirm'}
				submitButtonHandler={this.executeTypeDeletion}
			/>
		)
	}

	render() {
		return (
			<>
				{this.buildTable()}
				{this.buildEditModal()}
				{this.buildDeletionModal()}
			</>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	loggedInClub: state.club.loggedInClub
})

const mapDispatchToProps = {
	...AlertActions,
	...ClubActions
}

const enhance = compose<React.ComponentType<ComponentProps>>(
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(ClubTypesComponent)
