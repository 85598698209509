// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'

interface ComponentProps {
	children?: JSX.Element
}

type Props = ComponentProps

export const InputFeedback: React.FunctionComponent<Props> = (props: Props) => {
	return (
		<RS.FormFeedback className='input-feedback'>
			{props.children}
		</RS.FormFeedback>
	)
}
