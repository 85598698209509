// External Dependencies
import * as core from 'club-hub-core'

const Operator = core.QueryFilter.ComparisonOperator

// Common Comparison InputSelectionItem's
export const IsEqual = { label: 'Is', value: Operator.eq }
export const IsNotEqual = { label: 'Is Not', value: Operator.ne }
export const IsBefore = { label: 'Is Before', value: Operator.lt }
export const IsAfter = { label: 'Is After', value: Operator.gt }
