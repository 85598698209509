// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'

// Form
import { DateRangeFormInputs, LotteryFormState } from './form'

// Components
import FormikModal from '../../../Shared/Formik/FormModal'

export interface LotteryFormResource {
	_id?: string,
	name: string,
	start: Date,
	end: Date,
}

interface ComponentProps {
	date?: Date
	reservation?: core.Event.Reservation
	nextHandler?: (state: LotteryFormState) => void
	viewRequestsHandler?: () => void
	cancelHandler?: () => void
}

type Props = ComponentProps
type State = any

class LotteryForm extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
	}

	// ----------------------------------------------------------------------------------
	// Component Builders
	// ----------------------------------------------------------------------------------

	render() {
		const formResource = {count: 1, date: new Date()}
		return (
			<FormikModal<any, LotteryFormState>
				className={'lottery-request-modal'}
				formSpec={DateRangeFormInputs}
				modalTitle={'Lottery Request'}
				submitButtonName={'Next'}
				submitButtonHandler={this.props.nextHandler}
				secondaryButtonName={'View Requests'}
				secondaryButtonHandler={this.props.viewRequestsHandler}
				cancelButtonHandler={this.props.cancelHandler}
				formResource={formResource}
			/>
		)
	}
}

export default LotteryForm
