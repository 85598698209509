// External Dependencies
import * as core from 'club-hub-core'
import { oc } from 'ts-optchain'

// Internal Dependencies
import { ClubResourceName } from '../reducers/club'
import * as Constants from '../constants'

export const publicationStatusBadge = (status: core.IShared.PublicationStatus) => {
	let statusColor
	switch (status) {
		case core.IShared.PublicationStatus.Published:
		case core.IShared.PublicationStatus.Delivered:
			statusColor = core.Constants.Colors.GREEN
			break
		case core.IShared.PublicationStatus.Draft:
			statusColor = core.Constants.Colors.ORANGE
			break
		case core.IShared.PublicationStatus.Pending:
		case core.IShared.PublicationStatus.Scheduled:
			statusColor = core.Constants.Colors.BLUE
			break
		case core.IShared.PublicationStatus.Template:
			statusColor = core.Constants.Colors.GRAY
			break
		default:
			return { title: 'N/A', color: core.Constants.Colors.GRAY }
	}

	return { title: status, color: statusColor }
}

export const clubResourceTypeBadge = (club: core.Club.Model, resourceType: ClubResourceName, typeID: string) => {
	const types = oc(club).resources[resourceType].types([])
	const type = types.find((t) => `${t._id}` === typeID)
	return (type) ? type : { title: 'N/A', color: core.Constants.Colors.GRAY }
}

export const calendarBadge = (calendar: core.Calendar.Model) => {
	return { title: oc(calendar).name('N/A'), color: oc(calendar).color(core.Constants.Colors.GRAY) }
}

export const groupBadge = (group: core.User.UserGroup) => {
	const title = group.queryFilter ? 'DYNAMIC' : 'EXPLICIT'
	const color = group.queryFilter ? core.Constants.Colors.BLUE : core.Constants.Colors.GRAY
	return { title, color }
}
