// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as RS from 'reactstrap'
import { oc } from 'ts-optchain'

// Internal Dependencies
import AsyncImage from '../../Shared/AsyncImage'
import { ImageSize } from '../../../helpers/image'

interface Props {
	event?: core.Event.Model
	calendar?: core.Calendar.Model
	post?: core.Post.Model
	image: core.SubModels.Image.Model
	club: core.Club.Model
	onClickEvent?: (event: core.Event.Model) => any
	onClickPost?: (event: core.Post.Model) => any
}

export default class HomeCard extends React.Component<Props, any> {
	constructor(props: Props) {
		super(props)
	}

	onClickHandler = () => {
		if (this.props.event) {
			this.props.onClickEvent(this.props.event)
		}
		if (this.props.post) {
			this.props.onClickPost(this.props.post)
		}
	}

	getTitle = () => {
		if (this.props.event) {
			return this.props.event.name
		}
		if (this.props.post) {
			return this.props.post.title
		}
	}
	render() {
		const title = this.getTitle()
		const content = (this.props.event) ?
			this.props.event.richContent :
			this.props.post.richContent

		let badge
		if (this.props.calendar) {
			const color = oc(this).props.calendar.color()
			const name = oc(this).props.calendar.name()
			badge = (
				<RS.Badge className='event-badge' style={{ backgroundColor: color }}>
					{name}
				</RS.Badge>
			)
		}
		const subtitle = oc(content).text('')
		return (
			<div className={'card home-card-container'} onClick={this.onClickHandler}>
				<AsyncImage
					className='home-card-image'
					image={this.props.image}
					club={this.props.club}
					size={ImageSize.Medium}
					placeholderClass={'home-card-image'}
					children={badge}
				/>
				<div className={'home-card-text-container'}>
					<h4>{title}</h4>
					<p>{subtitle}</p>
				</div>

			</div>
		)
	}
}
