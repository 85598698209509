// External Dependencies
import * as React from 'react'
import * as Feather from 'react-feather'
import * as core from 'club-hub-core'

interface Props {
	club: core.Club.Model
	temp: number
	description: string
	condition: string
}

export default class WeatherCard extends React.Component<Props, any> {
	constructor(props: Props) {
		super(props)
	}

	buildConditionIcon = () => {
		switch (this.props.condition) {
			case 'Thunderstorm':
				return Feather.CloudLightning
			case 'Drizzle':
				return Feather.CloudDrizzle
			case 'Rain':
				return Feather.CloudRain
			case 'Snow':
				return Feather.CloudSnow
			case 'Clear':
				return Feather.Sun
			case 'Clouds':
				return Feather.Cloud
			case 'Mist':
			case 'Smoke':
			case 'Haze':
			case 'Dust':
			case 'Fog':
			case 'Sand':
			case 'Ash':
			case 'Squall':
			case 'Tornado':
			default:
				return Feather.AlertCircle
		}
	}

	buildContent = () => {
		const temp = Math.round(this.props.temp)
		const ConditionIcon = this.buildConditionIcon()
		const city = this.props.club.locations[0].city
		const desc = this.props.description
		return (
			<div className='weather-card-content d-flex align-items-center'>
				<ConditionIcon size={40} />
				<div className={'weather-text-container'}>
					<h4>{`${temp}` + '\u00b0' + `F in ${city}`}</h4>
					<p>{desc}</p>
				</div>
			</div>
		)
	}
	render() {
		const options = { weekday: 'long', month: 'short', day: 'numeric', year: 'numeric' }
		const date = new Date().toLocaleDateString('en-US', options)
		return this.buildContent()
	}
}
