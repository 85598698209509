// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import { Select, Divider } from 'antd'
import * as Feather from 'react-feather'
import { oc } from 'ts-optchain'

// Internal Dependencies

// Helpers
import { setStateAsync } from '../../../helpers/promise'

export enum DropdownItemType {
	Default = 'default',
	Divider = 'divider',
}

export interface DropdownItem {
	type: DropdownItemType,
	icon?: Feather.Icon,
	text?: string,
	value?: string,
}

interface ComponentProps {
	header: string 	 // The dropdown default value.
	category: string // The category the selected item will be paired with.
	items: DropdownItem[]
	onChange: (item: DropdownItem) => any
	className?: string
	featherIcon?: Feather.Icon
	faIcon?: string
	alignRight?: boolean
}

const initialState = {
	dropdownToggle: false,
}

type Props = ComponentProps
type State = typeof initialState

export default class DropdownComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	toggle = () => {
		setStateAsync(this, { dropdownToggle: !this.state.dropdownToggle })
	}

	/**
	 * All user input is passed back to an onChange handler.
	 * This handler needs to accept an interface similar to a form input event.
	 * Build this object and pass it back to our onChange handler.
	 */
	itemSelected = (item: DropdownItem) => {
		this.props.onChange(item)
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildDropdown = (hasIcon?: boolean) => {
		const FeatherIcon = this.props.featherIcon
		const fontAwesomeIcon = (this.props.faIcon) ? <i className={this.props.faIcon} /> : null
		const icon = (this.props.featherIcon) ? (<FeatherIcon size={18} />) : fontAwesomeIcon

		const className = oc(this).props.className('')
		return (
			<RS.Dropdown
				className='dropdown-component'
				isOpen={this.state.dropdownToggle}
				toggle={this.toggle}
			>
				<RS.DropdownToggle
					caret={false}
					className={`${className} dropdown-toggle d-flex align-items-center justify-content-center`}
				>
					<div className={'dropdown-content d-flex justify-content-center align-items-center'}>
						{icon}
						{this.props.header}
					</div>
				</RS.DropdownToggle>
				<RS.DropdownMenu right={hasIcon}>
					{this.buildDropdownItems(this.props.items)}
				</RS.DropdownMenu>
			</RS.Dropdown>
		)
	}

	buildDropdownItems = (items: DropdownItem[]) => {
		return items.map((item: DropdownItem, idx: number) => {
			if (item.type === DropdownItemType.Divider) {
				return <Divider key={`dropdown-divider-${idx}`} style={{ margin: '4px 0' }} />
			}

			const onClick = () => this.itemSelected(item)
			const key = `dropdown-${item.text}-${idx}`
			const FeatherIcon = item.icon
			const icon = (item.icon) ? (<FeatherIcon className='dropdown-icon' size={18} />) : null
			return (
				<RS.DropdownItem key={key} value='item.text' onClick={onClick}>
					{icon}
					<span>{item.text}</span>
				</RS.DropdownItem>
			)
		})

	}

	buildDropdownWithIcon = () => {
		return this.buildDropdown(oc(this).props.alignRight(true))
	}

	render() {
		return (this.props.featherIcon || this.props.faIcon) ?
			this.buildDropdownWithIcon() :
			this.buildDropdown(this.props.alignRight)
	}
}
