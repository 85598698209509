// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import { connect } from 'react-redux'
import * as queryString from 'query-string'
import { Link, RouteComponentProps } from 'react-router-dom'
import { FormikProps, FormikValues, FormikActions } from 'formik'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies

// Images
// tslint:disable-next-line
const clubhubLogo = require('../../assets/images/clubhublogo.png')

// Constants
import * as Constants from '../../constants'

// Helpers
import { setStateAsync } from '../../helpers/promise'
import { getClubDomain } from '../../helpers/url'
import { imageForResource, ImageSize } from '../../helpers/image'

// Form
import { LoginFormInputs } from './form'
import { FormInput } from '../Shared/Form'

// Components
import ErrorComponent from '../Shared/ErrorComponent'
import DimmedLoader from '../Shared/DimmedLoader'
import { BuildWrappedForm, FormikComponent } from '../Shared/Formik'

// Actions and Action Interface Imports
import { UserActions, AlertActions, ClubActions } from '../../actions/index'

// Reducers and State
import { RootReducerState } from '../../reducers'

// -----------------------------------------
// Component Type Declarations
// -----------------------------------------

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	initialLoading: true,
	loading: false,
	error: false,
	submittingForm: false,
	clubDomain: null as string | null,
}

type Props = ConnectedActions & ConnectedState & RouteComponentProps
type State = typeof initialState

/**
 * Login View Component.
 * Available at /login
 * Contains email and password fields for member login.
 */
class LoginViewComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		// Get the club's domain off the URL
		const clubDomain = getClubDomain()
		this.state = { ...initialState, clubDomain }
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		// Parse Query to see if this is a redirect from another resource
		const parsedQuery = queryString.parse(this.props.location.search)
		const resourceType = parsedQuery.type
		const resourceID = parsedQuery.id

		const [err] = await to(this.props.fetchClubs() as any)
		if (err) {
			// tslint:disable-next-line
			console.error(`Failed to fetch Clubs with error: ${err}`)
			this.setState({ error: true, initialLoading: false })
		}
		this.setState({ initialLoading: false })
	}

	// ----------------------------------------------------------------------------------
	// Calls To Actions
	// ----------------------------------------------------------------------------------

	private submitLogin = async (values: FormikValues, actions: FormikActions<FormikValues>): Promise<void> => {
		this.setState({ loading: true })

		let clubName = (values.club) ? values.club.value : null
		if (!clubName) {
			const clubs = oc(this).props.clubState.clubs([])
			const club = clubs.find((c) => c.domain === this.state.clubDomain)
			clubName = oc(club).name('')
		}
		const email = values.email
		const password = values.password
		const clubDomain = this.state.clubDomain
		const [err] = await to(this.props.authenticateUser(email, password, clubName, clubDomain) as any)
		if (err) {
			// Determine which message to display
			let errMessage: string
			let unknownError: boolean
			switch (err.message) {
				case Constants.INVALID_CREDENTIALS:
					errMessage = 'Your email or password is incorrect. Please try again.'
					unknownError = false
					break
				default:
					errMessage = 'There was an error. Please contact info@meshstudio.io for assistance'
					unknownError = true
					break
			}
			this.props.fireFlashMessage(errMessage, Constants.FlashType.WARNING)
			this.setState({ loading: false, error: unknownError })
			return
		}

		return this.props.history.push('/home')
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	private buildForm = () => {
		const inputs: FormInput[] = LoginFormInputs()

		return (
			<FormikComponent
				inputs={inputs}
				enableReinitialize={false}
				onSubmit={this.submitLogin}
				render={(formikProps) => this.loginFormContent(formikProps, inputs)}
			/>
		)
	}

	private loginFormContent = (formikProps: FormikProps<FormikValues>, inputs: FormInput[]): JSX.Element => {
		const buttonLoadingClass = (this.state.loading) ? `btn-loading` : ``

		const wrappedFormProps = { inputs, submitOnEnter: true }
		return (
			<div>
				{BuildWrappedForm(wrappedFormProps, formikProps)}
				<Link to={Constants.FORGOT_PASSWORD_ROUTE} className='forgot-password'>Forgot your password?</Link>
				<button
					className={`login-btn btn btn-primary btn-block ${buttonLoadingClass}`}
					onClick={formikProps.submitForm}
					disabled={!formikProps.isValid}
				>
					Login
				</button>
			</div>
		)
	}

	buildLogo = () => {
		// If we don't have a domain, return the default
		if (!this.state.clubDomain) { return <img src={clubhubLogo} /> }

		// Find the Club by domain, and get its logo
		const clubState = this.props.clubState
		const clubs = oc(clubState).clubs([])
		const clubByDomain = clubs.find((club) => club.domain === this.state.clubDomain)
		if (clubByDomain) {
			return (<img src={imageForResource(clubByDomain.image, clubByDomain, ImageSize.Medium)} />)
		}

		// We didn't find the Club, return the default
		return <img src={clubhubLogo} />
	}

	public render() {
		const { loggedInClub } = this.props
		if (this.state.initialLoading) { return <DimmedLoader component={null} isLoading={true} /> }
		if (this.state.error) { return <ErrorComponent club={loggedInClub} isAdmin={this.props.userState.isAdmin} /> }

		return (
			<div className='login-container container-fluid'>
				<div className='inner-container container-fluid'>
					<RS.Row className='login-container-row'>
						<RS.Col xs={12} className='login-header-col'>
							{this.buildLogo()}
						</RS.Col>
					</RS.Row>

					<RS.Row className='login-container-row'>
						<RS.Col xs={12} className='login-col'>
							{this.buildForm()}
						</RS.Col>
					</RS.Row>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	clubState: state.club,
	userState: state.user,
	loggedInClub: state.club.loggedInClub
})

const mapDispatchToProps = {
	...AlertActions,
	...UserActions,
	...ClubActions
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginViewComponent)
