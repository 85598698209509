// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import { Omit } from 'react-router'
import { debounce } from 'underscore'
import { oc } from 'ts-optchain'

// Internal Dependencies
import { BaseInputPrimitiveProps } from '../index'
import Editor from '../../../Editor'

interface ComponentProps extends Omit<BaseInputPrimitiveProps, 'onChange'> {
	onChange: (property: string, value: core.SubModels.RichContent.Model) => void
	onBlur: (property: string, didBlur: boolean) => void
}

type Props = ComponentProps

export const EditorInput: React.FunctionComponent<Props> = (props: Props) => {
	const initialState = props.value as core.SubModels.RichContent.Model
	return (
		<Editor
			className={oc(props).item.class('')}
			richContent={initialState}
			formEditor={true}
			placeholder={'Enter content...'}
			onChange={debounce((content: core.SubModels.RichContent.Model) => onChange(props, content), 300)}
		/>
	)
}

const onChange = (props: Props, value: core.SubModels.RichContent.Model) => {
	// This will call 'setFieldValue'
	props.onChange(props.item.property, value)
}

const onBlur = (props: Props) => {
	// This will call 'setFieldTouched'
	props.onBlur(props.item.property, true)
}
