// External Dependencies
import * as React from 'react'
import * as Feather from 'react-feather'
import { oc } from 'ts-optchain'
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Table,
	Button
} from 'reactstrap'
import { setStateAsync } from '../../../../helpers/promise'

interface ComponentProps {
	isLoading: boolean
	type: string
	author: string
	recipients: string
	subject: string
	delivery: string
	testSend: boolean
	cancelButtonHandler: () => any
	submitButtonHandler: () => any
}

const initialState = {
	isOpen: true,
	newCalendarName: undefined as string | undefined,
	clickedSubmit: false,
}

type Props = ComponentProps
type State = typeof initialState

/**
 * The `MessageConfirmationModal` provides modal UI for confirming a message.
 */
class MessageConfirmationModal extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Handles submit button clicks.
	 */
	handleSubmitButton = async () => {
		await this.props.submitButtonHandler()
		this.handleToggle()
	}

	/**
	 * Handles cancel button clicks.
	 */
	handleToggle = async () => {
		await setStateAsync(this, {isOpen: !this.state.isOpen})
	}

	// ----------------------------------------------------------------------------------
	// Component Builds
	// ----------------------------------------------------------------------------------

	/**
	 * Builds the confirmation table which contains information about the message.
	 */
	buildConfirmationTable = () => {
		const type = oc(this).props.type('')
		const capitalizedType = type.charAt(0).toUpperCase() + type.toLocaleLowerCase().slice(1)
		return (
			<Table>
				<tbody>
					{this.buildConfirmationTableRow('To:', this.props.recipients)}
					{this.buildConfirmationTableRow('From:', this.props.author)}
					{this.buildConfirmationTableRow('Subject:', this.props.subject)}
					{this.buildConfirmationTableRow('Type:', capitalizedType)}
					{this.buildConfirmationTableRow('Delivery:', this.props.delivery)}
				</tbody>
			</Table>
		)
	}

	/**
	 * Builds a confirmation table row.
	 */
	buildConfirmationTableRow = (heading: string, detail: string) => {
		return (
			<tr>
				<th scope='row'>{heading}</th>
				<td>{detail}</td>
			</tr>
		)
	}

	public render() {
		const buttonLoading = (this.props.isLoading) ? 'btn-loading' : ''
		const normalSendDialog = 'You are about to send your message. Please confirm the following details before sending.'
		const testSendDialog = 'This is a test message, and will only be sent to you for review.'
		const message = this.props.testSend ? testSendDialog : normalSendDialog
		return (
			<Modal
				isOpen={this.state.isOpen}
				className={`message-confirmation-modal`}
				onClosed={this.props.cancelButtonHandler}
				toggle={this.handleToggle}
			>
				<ModalHeader className='modal-title'>{'Message Confirmation'}</ModalHeader>
				<ModalBody>
					<div className='d-flex align-items-center justify-content-center confirmation-icon-container'>
						<Feather.Send className='feather-icon' size={24} />
					</div>
					<h3 className='modal-message-title'>{'Almost There!'}</h3>
					<p className='modal-message'>{message}</p>
					<div className='table-container'>
						{this.buildConfirmationTable()}
					</div>
				</ModalBody>

				<ModalFooter>
					<Button color='secondary' onClick={this.handleToggle}>Cancel</Button>
					<Button color='primary' className={`${buttonLoading}`} onClick={this.handleSubmitButton}>Send</Button>
				</ModalFooter>
			</Modal>
		)
	}
}

export default MessageConfirmationModal
