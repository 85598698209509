// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import * as core from 'club-hub-core'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { FormikProps } from 'formik'
import { omit, debounce } from 'underscore'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies

// Actions
import { AlertActions, ClubActions } from '../../../actions'

// State
import { RootReducerState } from '../../../reducers'

// Components
import DimmedLoader from '../../Shared/DimmedLoader'

// Form
import { FormInput } from '../../Shared/Form'
import { ClubSettingsFormInputs, ClubSettingsFormState } from './form'
import { FormikComponent, BuildWrappedForm } from '../../Shared/Formik'

// Helpers
import * as Constants from '../../../constants'
import { setStateAsync } from '../../../helpers/promise'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

interface ComponentProps {
	club: core.Club.Model
}

const initialState = {
	error: false,
	loading: true,
	creatingLocation: false,
	currentForm: null as ClubSettingsFormState | null
}

type Props = ConnectedState & ConnectedActions & ComponentProps
type State = typeof initialState

export class ClubSettingsForm extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
	}

	async componentDidMount() {
		await setStateAsync(this, { loading: false })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Will update an existing Event on the DB with the information in the form.
	 * Events will only be updated if the validation conditions for the form are met.
	 */
	handleUpdateClub = async (form: ClubSettingsFormState): Promise<void> => {
		const { club, updateClub, fireFlashMessage } = this.props

		this.setState({ loading: true })
		const payload = this.buildClubUpdatePayload(form)
		const [err] = await to(updateClub(`${club._id}`, payload) as any)
		if (err) {
			fireFlashMessage(`Failed to update Club Settings. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}

		await setStateAsync(this, { loading: false })
		fireFlashMessage(`Successfully updated Club Settings.`, Constants.FlashType.SUCCESS)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	/**
	 * Builds the payload, that will be sent to the server, based on the form
	 */
	buildClubUpdatePayload = (form: ClubSettingsFormState): FormData => {
		const { club } = this.props

		const formPayload = new FormData()

		const clubSettings = this.buildClubSettings(form)

		const clubUpdates: Partial<core.Club.Model> = {
			name: form.name,
			domain: form.domain,
			primaryName: form.primaryName,
			secondaryName: form.secondaryName,

			// Only populate the 'clubSettings' key if we have an
			// object with at least one defined setting in it
			...(!isNullOrUndefined(clubSettings) ? { clubSettings } : {}),
			// Only include a 'website' key if the club has one to begin with
			...(!isNullOrUndefined(club.website) ? { website: { ...club.website } } : {})
		}

		// Check for existing logo
		if (Array.isArray(form.logo)) {
			// tslint:disable-next-line
			for (let i = 0; i < form.logo.length; i++) {
				const formImage: File | core.SubModels.Image.Model = form.logo[i]
				if ((formImage as core.SubModels.Image.Model)._id) {
					clubUpdates.image = formImage as core.SubModels.Image.Model
					break
				}
				formPayload.append('logo', formImage)
				break
			}
		} else if (!isNullOrUndefined(form.logo)) {
			// If form.image is not an array, then we know that
			// it is an existing image coming from the resource
			clubUpdates.image = form.logo
		}

		// Check for existing coverImage
		if (Array.isArray(form.coverImage)) {
			// tslint:disable-next-line
			for (let i = 0; i < form.coverImage.length; i++) {
				const formImage: File | core.SubModels.Image.Model = form.coverImage[i]
				if ((formImage as core.SubModels.Image.Model)._id) {
					clubUpdates.website = {
						...club.website,
						coverImage: formImage as core.SubModels.Image.Model
					}
					break
				}
				formPayload.append('coverImage', formImage)
				break
			}
		} else if (!isNullOrUndefined(form.coverImage)) {
			// If form.image is not an array, then we know that
			// it is an existing image coming from the resource
			clubUpdates.website = {
				...club.website,
				coverImage: form.coverImage
			}
		}

		formPayload.append('club', JSON.stringify(clubUpdates))

		return formPayload
	}

	/**
	 * Build the 'clubSettings' object for the 'buildClubUpdatePayload'
	 * function, based on the values in the form.
	 */
	buildClubSettings = (form: ClubSettingsFormState) => {
		// Return an object with only the defined values set in it
		const clubSetting: core.Club.ClubSettings = {
			customDomain: oc(form).customDomain(),
			iosAppURL: oc(form).iosAppURL(),
			androidAppURL: oc(form).androidAppURL(),
			eventSettings: {
				canInviteMembers: oc(form).canInviteMembers({} as any).value === 'true'
			}
		}
		return omit(clubSetting, isNullOrUndefined)
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildForm = () => {
		const { club } = this.props
		const formResource = this.getFormResource(club)
		const formInputs = ClubSettingsFormInputs([], [])

		return (
			<FormikComponent
				inputs={formInputs}
				enableReinitialize={false}
				formResource={formResource}
				onSubmit={this.handleUpdateClub}
				render={(formikProps: FormikProps<ClubSettingsFormState>) => (
					this.buildFormBody(formInputs, formikProps)
				)}
			/>
		)
	}

	buildFormBody = (inputs: FormInput[], formikProps: FormikProps<ClubSettingsFormState>) => {
		const saveButtonClass = classNames({
			'btn': true,
			'btn-primary': true,
			'clubSettingForm__save-btn': true,
		})
		return (
			<>
				{BuildWrappedForm({ inputs }, formikProps)}
				<button className={saveButtonClass} onClick={formikProps.submitForm}>Save</button>
			</>
		)
	}

	render() {
		const { loading } = this.state
		if (loading) { return (<DimmedLoader component={null} isLoading={loading} />) }
		return (
			<RS.Row className='clubSettingForm__container'>
				<RS.Col>
					{this.buildForm()}
				</RS.Col>
			</RS.Row>
		)
	}

	// -------------------------------------
	// Helpers
	// -------------------------------------

	getFormResource = (club: core.Club.Model): ClubSettingsFormState => {
		const canInviteMembers = oc(club).clubSettings.eventSettings.canInviteMembers() ?
			{ label: 'Yes', value: 'true' } : { label: 'No', value: 'false' }
		const formResource: ClubSettingsFormState = {
			name: club.name,
			domain: club.domain,
			type: { label: club.type, value: club.type },
			primaryName: club.primaryName,
			secondaryName: club.secondaryName,
			coverImage: oc(club).website.coverImage(),
			logo: club.image,
			customDomain: club.clubSettings.customDomain,
			iosAppURL: club.clubSettings.iosAppURL,
			androidAppURL: club.clubSettings.androidAppURL,
			canInviteMembers: canInviteMembers
		}
		return formResource
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	userState: state.user,
})

const mapDispatchToProps = {
	...AlertActions,
	...ClubActions,
}

const enhance = compose<React.ComponentType<ComponentProps>>(
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(ClubSettingsForm)
