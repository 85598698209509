// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as Feather from 'react-feather'
import { oc } from 'ts-optchain'
import { isNullOrUndefined } from 'util'

// Components
import TypeaheadComponent from '../../../../Shared/Typeahead'
import { InputSelectionItem } from '../../../../Shared/Form'

// Helpers
import { userForForm } from '../../../../../helpers/user'

interface ComponentProps {
	loggedInUser: core.User.Model
	club: core.Club.Model
	users: core.User.Model[]
	guests: InputSelectionItem[]
	reservedUserIDsMap: core.IShared.GeneralMap<string>
	rowIndex: number
	disabled: boolean
	displayXButton?: boolean
	handleMemberSelected: (data: InputSelectionItem, rowIndex: number) => void
	removeGuestInput?: (rowIndex: number) => void
}

type Props = ComponentProps

/**
 * Builds the select input for picking a guest.
 * @param rowIndex The index of the row that the input belongs to
 */
export const MemberSelectInput: React.FunctionComponent<Props> = (props: Props) => {
	const userID = props.loggedInUser._id
	const existingReservationInputs: InputSelectionItem[] = []
	const memberInputs: InputSelectionItem[] = []
	const guestInputs: InputSelectionItem[] = []

	// Build the member and guest inputs using the users from state.
	for (const user of props.users) {

		// If the member already has a RSVP to the event, don't add them to the member inputs.
		if (!isNullOrUndefined(props.reservedUserIDsMap[`${user._id}`])) {
			existingReservationInputs.push({ ...userForForm(user), group: 'RESERVED', disabled: true })
			continue
		}

		// If the user is already added as a guest, continue.
		const g = props.guests.find((guest: InputSelectionItem) => guest.value === `${user._id}`)
		if (!isNullOrUndefined(g)) {
			continue
		}

		// If the user doesn't have the member status of guest, add them to the member inputs.
		if (user.memberStatus !== core.User.MemberStatus.Guest) {
			memberInputs.push({ ...userForForm(user), group: 'MEMBERS' })
			continue
		}

		// If the user is a guest, add them to the guest inputs.
		if (user.memberStatus === core.User.MemberStatus.Guest) {
			guestInputs.push({ ...userForForm(user), group: 'GUESTS' })
			continue
		}
	}

	guestInputs.unshift({ label: 'Add Outside Guest', value: 'addGuest', group: 'GUESTS' })

	const rowIndex = props.rowIndex
	const selectedGuest = props.guests[rowIndex]

	const isAnonymousGuest = isNullOrUndefined(oc(selectedGuest).value())
	const selectedGuestForTypeahead = (isAnonymousGuest) ? undefined : [selectedGuest]

	// We need to provide a unique key so that removing the inputs works as intended (can't just use rowIndex)
	const guestValueForKey = oc(selectedGuest).value('anonymous')
	const inputKey = `guest_select_${guestValueForKey}_${rowIndex}`

	const xButton = props.displayXButton ? (
		<button className='btn btn-secondary remove-input-button' onClick={() => props.removeGuestInput(rowIndex)}>
			<Feather.X size={20} />
		</button>
	) : null

	let options = [...guestInputs, ...existingReservationInputs]
	const placeholder = 'Select Guest...'
	const isAdmin = oc(props).loggedInUser.admin()
	const canAddMembers = oc(props).club.clubSettings.eventSettings.canInviteMembers(true)
	if (canAddMembers) {
		options = options.concat(memberInputs)
	}
	return (
		<div className='member-select-container d-flex' key={inputKey}>
			<TypeaheadComponent
				id={`typeahead-${rowIndex}`}
				className={`react-select member-select-input flex-fill`}
				disabled={props.disabled}
				options={options}
				clearButton={true}
				onChange={(selected: InputSelectionItem[]) => {
					const selectedValue = oc(selected)[0]({ label: null, value: null })
					if (isNullOrUndefined(selectedValue)) { return }
					return props.handleMemberSelected(selectedValue, rowIndex)
				}}
				defaultSelected={selectedGuestForTypeahead}
				dataIsGrouped={true}
				isInvalid={true}
				isValid={true}
				emptyLabel={'No matches found'}
				placeholder={placeholder}
			/>
			{(rowIndex === 0) ? null : xButton}
		</div>
	)
}
