// External Dependencies
import * as React from 'react'
import * as Yup from 'yup'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter, matchPath, RouteComponentProps } from 'react-router'
import to from 'await-to-js'
import { oc } from 'ts-optchain'

// Internal Dependencies

// Actions
import { RootReducerState } from '../../reducers/index'
import { AlertActions } from '../../actions/index'

// Components
import ErrorComponent from '../Shared/ErrorComponent'
import DimmedLoader from '../Shared/DimmedLoader'

// Form
import GenericFormComponent from '../Shared/Formik/GenericForm'
import { FormInput } from '../Shared/Form'

// Helpers
import * as Constants from '../../constants'
import { setStateAsync } from '../../helpers/promise'
import { GET, POST } from '../../helpers/api'
import { getClubDomain } from '../../helpers/url'
import { RequestError } from '../../helpers/error'

// Interfaces
import { Form } from 'club-hub-core'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

interface ComponentProps {
	publicViewer?: boolean
}

const initialState = {
	loading: true,
	errorCode: null as number | null,
	formName: null as string | null,
	form: null as Form.Model | null,
	clubDomain: null as string | null
}

type Props = RouteComponentProps & ComponentProps & ConnectedActions & ConnectedState
type State = typeof initialState

class PublicFormComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		// Get the form name and club domain off of the URL.
		const match = matchPath<any>(this.props.location.pathname, { path: Constants.PUBLIC_FORMS })
		const clubDomain = getClubDomain()

		await setStateAsync(this, { formName: match.params.form_name, clubDomain })
		await this.getFormByName()
	}

	// ----------------------------------------------------------------------------------
	// Network Requests
	// ----------------------------------------------------------------------------------

	/**
	 * Fetches a form for a club.
	 * Uses React router params to build the request URL.
	 */
	getFormByName = async () => {
		const domain = oc(this).state.clubDomain() // Override for testing
		const route = `/forms/${domain}/${this.state.formName}`
		const [err, res] = await to<Form.Model, RequestError>(GET(route))
		if (err) {
			await setStateAsync(this, { errorCode: err.code, loading: false })
			return
		}
		await setStateAsync(this, { form: res, loading: false })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Returns the user to the login page.
	 */
	handleCancel = () => {
		this.props.history.push(Constants.LOGIN_ROUTE)
	}

	/**
	 * Submits the form to the server using route: /forms/submit.
	 */
	handleSubmit = async (form: Form.Model): Promise<void> => {
		await setStateAsync(this, { loading: true })

		// Build our POST payload and send request.
		const payload = this.buildFormPayload(form)
		const [err, res] = await to<any, RequestError>(POST(`/forms/submit`, payload))
		if (err) {
			await setStateAsync(this, { errorCode: err.code, loading: false })
			return
		}

		await setStateAsync(this, { form: null, loading: false })
		form = null
		this.props.fireFlashMessage(`You've successfully submitted your form`, Constants.FlashType.SUCCESS)
		this.props.history.push(Constants.LOGIN_ROUTE)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	buildFormPayload = (form: Form.Model) => {
		return {
			form: form,
			formID: this.state.form._id,
			clubDomain: oc(this).state.clubDomain('cedar')
		}
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildErrorComponent = () => {
		let errorProps

		if (this.state.errorCode === 500) {
			errorProps = {
				errorCode: '500'
			}
		}

		else {
			errorProps = {
				errorCode: '400',
				errorMessage: 'Form not found',
				detailsMessage: `Sorry, we were unable to find the Form`,
			}
		}

		const { loggedInClub } = this.props
		return (
			<ErrorComponent {...errorProps} club={loggedInClub} isAdmin={this.props.userState.isAdmin} />
		)
	}

	buildForm = () => {
		if (!this.state.form) { return null }

		// Set up the Yup validation here
		const inputsForForm = this.state.form.inputs.map((i) => {
			if (i.validation) {
				return { ...i, validation: Yup.mixed().required(Constants.REQUIRED_FIELD) }
			}
			return i
		}) as any as FormInput[]

		return (
			<GenericFormComponent
				title={this.state.form.displayName}
				inputs={inputsForForm}
				enableReinitialize={false}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.handleCancel}
				submitButtonName={'Submit'}
				submitButtonHandler={this.handleSubmit}
			/>
		)
	}

	render() {
		if (this.state.errorCode) { return this.buildErrorComponent() }
		if (this.state.loading) { return <DimmedLoader component={null} isLoading={true} /> }

		return (
			<div className='container public-form-component'>
				{this.buildForm()}
			</div>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	loggedInClub: state.club.loggedInClub,
	userState: state.user
})

const mapDispatchToProps = {
	...AlertActions,
}

const enhance = compose<React.ComponentType<ComponentProps>>(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(PublicFormComponent)
