
import * as React from 'react'
import * as core from 'club-hub-core'
import { Link } from 'react-router-dom'
import { flatten } from 'underscore'

import { InfoCardData } from '../../Shared/Cards/InfoCard'
import * as Constants from '../../../constants'

export const CollectionCard = (user: core.User.Model, club: core.Club.Model, canAdd: boolean, editVehicleHandler: any): InfoCardData => {
	const vehicles = user.meta.car.vehicles

	const buildCarInfo = (vehicle: core.SubModels.CarMeta.Vehicle) => {
		return [[
			{
				title: 'Vehicle',
				value: `${vehicle.make} ${vehicle.model}`,
				size: 6,
			},
			{
				title: 'Year',
				value: vehicle.year,
				size: 3,
			},
			{
				title: 'Color',
				value: vehicle.color,
				size: 3,
			},
			{
				title: 'Description',
				value: vehicle.description,
				size: 12,
				hideOnEmpty: true,
			}
		]]
	}

	const link = `${Constants.CREATE_VEHICLE_ROUTE}?userID=${user._id}`
	const titleString = `${user.firstName}'s Collection`
	const titleContent = (canAdd) ? (
		<div className='d-flex justify-content-between align-items-center w-100'>
			<div>{titleString}</div>
			<div>
				<Link to={link}>Add Vehicle</Link>
			</div>
		</div>
	) : titleString

	const content = flatten(vehicles.map(buildCarInfo), true)

	return {
		title: titleContent,
		bodyClass: 'car-collection-card',
		content: content,
		editHandler: editVehicleHandler
	}
}
