// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import * as core from 'club-hub-core'
import * as queryString from 'query-string'
import * as Feather from 'react-feather'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies
import TableComponent, { TableColumn } from '../Shared/Table'

// Tables
import { MessageTableColumns, TemplateTableColumns, DraftTableColumns, MessageActionType } from './table'

// Actions
import { MessageActions, AlertActions, PostActions, EventActions } from '../../actions/index'

// State
import { RootReducerState } from '../../reducers'

// Components
import TableHeader from '../Shared/TableHeader'
import { ButtonType, HeaderButton } from '../Shared/ButtonGroup'
import ErrorComponent from '../Shared/ErrorComponent'
import ModalComponent from '../Shared/Modal'
import { TabBarButtonInput } from '../Shared/TabBar'
import EmptyContent, { ContentType } from '../Shared/EmptyContent'

// Helpers
import * as Constants from '../../constants'

import { setStateAsync } from '../../helpers/promise'
import { publicationStatusBadge } from '../../helpers/badge'
import { RSA_NO_PADDING } from 'constants'

export enum MessageType {
	MESSAGE = 'Message',
	TEMPLATE = 'Template'
}

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	error: false,
	loading: true,
	messageStatus: core.IShared.PublicationStatus.Delivered as core.IShared.PublicationStatus,
	pageIndex: 0,
	messageToDelete: null as string | null,
	messageType: MessageType.MESSAGE as MessageType
}

type Props = RouteComponentProps & ConnectedState & ConnectedActions
type State = typeof initialState

/**
 * Message Component
 */
class MessageComponent extends React.Component<Props, State> {
	private pageSize: number

	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
		this.pageSize = 20
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		await this.setStateBasedOnPath()
		await this.fetchMessageForState()
		await setStateAsync(this, { loading: false })
	}

	async componentDidUpdate(prevProps: Props) {
		this.setStateBasedOnPath()
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	// ----------------------------------------------------------------------------------
	// Event Handlers - Route/State Reconciliation
	// ----------------------------------------------------------------------------------
	async setStateBasedOnPath() {
		const locationPath = this.props.history.location.pathname
		const matchPath = this.props.match.path
		const currentPath = this.props.match.isExact ? matchPath : locationPath
		let messageStatus: core.IShared.PublicationStatus
		switch (currentPath) {
			case Constants.MESSAGES_ROUTE:
				// If we're at the medium selection, clear the state
				messageStatus = core.IShared.PublicationStatus.Delivered
				break
			case Constants.MESSAGES_DRAFT_ROUTE:
				messageStatus = core.IShared.PublicationStatus.Draft
				break
			case Constants.MESSAGES_TEMPLATE_ROUTE:
				messageStatus = core.IShared.PublicationStatus.Template
				break
		}

		if (this.state.messageStatus !== messageStatus) {
			this.fetchMessageForState(messageStatus)
			this.setState({ messageStatus })
		}
	}

	routeForPublicationStatusState = (state: core.IShared.PublicationStatus) => {
		switch (state) {
			case core.IShared.PublicationStatus.Delivered:
				return Constants.MESSAGES_ROUTE
			case core.IShared.PublicationStatus.Draft:
				return Constants.MESSAGES_DRAFT_ROUTE
			case core.IShared.PublicationStatus.Template:
				return Constants.MESSAGES_TEMPLATE_ROUTE
			default:
				return Constants.MESSAGES_ROUTE
		}
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Dropdown Actions
	// ----------------------------------------------------------------------------------

	handleTableDropdownAction = async (e: any) => {
		const [action, messageID] = e
		switch (action) {
			case MessageActionType.EditMessage:
				this.handleEditMessage(messageID)
				break
			case MessageActionType.DeleteMessage:
				this.handleDeleteMessage(messageID)
				break
			case MessageActionType.SendTemplate:
				this.handleSendTemplate(messageID)
				break
			default:
				break
		}
	}

	handleEditMessage = async (messageID: string) => {
		const currentMessage = this.props.messageState.messages.find((msg) => `${msg._id}` === messageID)
		await this.props.setCurrentMessage(currentMessage)

		// Query parameters
		const queryParams = { messageID: messageID }
		const deliveryType = currentMessage.deliveryType.toLocaleLowerCase()
		const location = {
			pathname: Constants.UPDATE_MESSAGE_ROUTE.replace(':delivery_type', deliveryType),
			search: queryString.stringify(queryParams)
		}

		// If we have a subject, we need to update our state.
		if (oc(currentMessage).subjectID()) {
			const subjectID = `${currentMessage.subjectID}`
			const subjectType = oc(currentMessage).subjectType()
			if (subjectType === core.Message.SubjectType.Post) {
				await this.props.fetchPost(subjectID)
			} else if (subjectType === core.Message.SubjectType.Event) {
				await this.props.fetchEvent(subjectID)
			}
		}

		// Go to the route
		this.props.history.push(location)
	}

	handleDeleteMessage = async (messageID: string) => {
		await setStateAsync(this, { messageToDelete: messageID })
	}

	/**
	 * Creates a new message object with a copy of the rich content from the message template.
	 * This message object is set in message state as the 'current message'.
	 * Because this message object doesn't have an _id, the message form will treat it as a new message.
	 */
	handleSendTemplate = async (messageID: string) => {
		const template = this.props.messageState.messages.find((msg) => `${msg._id}` === messageID)
		const currentMessage: core.Message.Model = {
			author: this.props.userState.loggedInUser,
			clubID: this.props.loggedInClub._id,
			deliveryType: template.deliveryType,
			richContent: {
				...template.richContent,
				status: core.IShared.PublicationStatus.Draft
			},
			title: template.title,
			individualUserIDs: [],
			userGroupIDs: []
		}

		const path = Constants.UPDATE_MESSAGE_ROUTE.replace(':delivery_type', template.deliveryType.toLocaleLowerCase())
		await this.props.setCurrentMessage(currentMessage)
		this.props.history.push(path)
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Modal Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Make the API call to delete the Post, when the
	 * user confirms the deletion via the modal
	 */
	executeMessageDeletion = async () => {
		// Delete the Message and fetch the Messages
		const messageID = this.state.messageToDelete
		const currentPage = this.state.pageIndex
		const [deleteErr] = await to(this.props.deleteMessage(messageID) as any)
		if (deleteErr) {
			this.props.fireFlashMessage(`Failed to delete Message. ${deleteErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, messageToDelete: null })
		}

		const [fetchErr] = await to(this.fetchMessageForState())
		if (fetchErr) {
			this.props.fireFlashMessage(`Failed to fetch Messages. ${fetchErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, messageToDelete: null })
		}

		await setStateAsync(this, { loading: false, messageToDelete: null })
	}

	/**
	 * Hide the deletion modal when the user cancels it
	 */
	closeDeleteMessageModal = async () => {
		await setStateAsync(this, { messageToDelete: null })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Event Handlers
	// ----------------------------------------------------------------------------------

	handlePage = async (data: any) => {
		const { pageIndex } = this.state

		// Check if the new page is different that the current one
		if (data.selected !== pageIndex) {
			await setStateAsync(this, { pageIndex: data.selected, loading: true })
			await this.fetchMessageForState()
			await setStateAsync(this, { loading: false })
		}
	}

	/**
	 * Updates the message status in the route and component state.
	 */
	handleMessageState = async (state: core.IShared.PublicationStatus) => {
		const route = this.routeForPublicationStatusState(state)
		this.props.history.replace(route)
	}

	/**
	 * Fetches messages by status.
	 */
	fetchMessageForState = async (messageStatus: core.IShared.PublicationStatus = this.state.messageStatus) => {
		this.setState({ loading: true })
		this.props.clearMessages()
		const [err] = await to(this.props.fetchMessages(this.pageSize, this.state.pageIndex, [messageStatus]) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to fetch Messages. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}
		this.setState({ loading: false })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Header Event Handlers
	// ----------------------------------------------------------------------------------

	handleHeaderButtonAction = (e: any) => {
		const action = e.target.value
		switch (action) {
			case 'createMessage':
				this.handleCreateMessage()
				break
			default:
				break
		}
	}

	handleCreateMessage = async () => {
		await this.props.clearCurrentMessage()
		await this.props.setCurrentMessage({ richContent: { status: this.state.messageStatus } })
		this.props.history.push(Constants.CREATE_MESSAGE_ROUTE)
	}

	/**
	 * Handle a column header of the table being selected
	 */
	handleTableHeaderAction = (e: any) => {
		// TODO: Add this functionality
	}

	handleTableRowClicked = (row: core.Message.Model) => {
		switch (row.richContent.status) {
			case core.IShared.PublicationStatus.Draft:
			case core.IShared.PublicationStatus.Template:
				return this.handleEditMessage(`${row._id}`)
			default:
				throw new Error('Clicked row w no handler')
		}
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildDeliveryTypeIcon = (deliveryType: 'TEXT' | 'PUSH' | 'EMAIL') => {
		let icon
		switch (deliveryType) {
			case 'TEXT':
				icon = Feather.MessageSquare
				break
			case 'PUSH':
				icon = Feather.Bell
				break
			case 'EMAIL':
				icon = Feather.Mail
				break
			default:
				icon = Feather.AlertCircle
				break
		}

		const FeatherIcon = icon
		return (
			<div className='delivery-icon d-flex align-items-center justify-content-center'>
				<FeatherIcon size={16} />
			</div>
		)
	}

	buildNotifStatusLink = (msg: core.Message.Model, notifStatus: core.Notification.Status) => {
		const { VIEW_MESSAGE_USERS_ROUTE } = Constants

		const destination = VIEW_MESSAGE_USERS_ROUTE.replace(':message_id', `${msg._id}`).replace(':notif_status', notifStatus)

		let linkContent
		switch (notifStatus) {
			case core.Notification.Status.Sent:
				linkContent = (msg as any).sentCount
				break
			case core.Notification.Status.Delivered:
				linkContent = (msg as any).deliveredCount
				break
			case core.Notification.Status.Opened:
				linkContent = (msg as any).openedCount
				break
			default:
				return null
		}

		return (
			<Link to={destination}>{linkContent}</Link>
		)
	}

	buildMessageTable = () => {
		// Items for the Table
		const messageState = this.props.messageState
		const total = oc(messageState).messageCount(0)
		const messages = oc(messageState).messages([])
		const users = oc(this).props.userState.users([])

		const messagesForTable = messages.map((msg) => {
			const messageStatus = oc(msg).richContent.status()
			const messageRecipients: any[] = oc(msg).recipients([])
			const messageUsers = messageRecipients.map((recipientID: any) => {
				if (!recipientID) {
					return null
				}
				return users.find((user) => `${user._id}` === `${recipientID}`)
			}).filter((user: core.User.Model) => !isNullOrUndefined(user))
			const deliveryTypeIcon = this.buildDeliveryTypeIcon(msg.deliveryType as any)
			return {
				...msg,
				statusWithColor: publicationStatusBadge(messageStatus),
				messageRecipients: messageUsers,
				deliveryTypeIcon: deliveryTypeIcon,
				canEdit: (messageStatus === core.IShared.PublicationStatus.Draft) || (messageStatus === core.IShared.PublicationStatus.Template),
				sentCount: this.buildNotifStatusLink(msg, core.Notification.Status.Sent),
				deliveredCount: this.buildNotifStatusLink(msg, core.Notification.Status.Delivered),
				openedCount: this.buildNotifStatusLink(msg, core.Notification.Status.Opened),
			}
		})

		if (total === 0) {
			return (<EmptyContent type={ContentType.Messages} status={this.state.messageStatus} />)
		}

		// Optional Click Handler
		let clickHandler
		if (this.state.messageStatus === core.IShared.PublicationStatus.Draft || this.state.messageStatus === core.IShared.PublicationStatus.Template) {
			clickHandler = this.handleTableRowClicked
		}

		const columns = this.buildTableColumns()
		return (
			<TableComponent<core.Message.Model>
				columns={columns}
				rowItems={messagesForTable}
				showPaging={true}
				currentPage={this.state.pageIndex}
				pageHandler={this.handlePage}
				totalResults={total}
				pageSize={this.pageSize}
				actionHandler={this.handleTableHeaderAction}
				dropdownHandler={this.handleTableDropdownAction}
				isLoading={this.state.loading}
				onTableRowClick={clickHandler}
			/>
		)
	}

	buildTableColumns = (): TableColumn[] => {
		switch (this.state.messageStatus) {
			case core.IShared.PublicationStatus.Delivered:
				return MessageTableColumns()
			case core.IShared.PublicationStatus.Draft:
				return DraftTableColumns()
			case core.IShared.PublicationStatus.Template:
				return TemplateTableColumns()
			default:
				throw new Error('Invalid message status')
		}

	}

	buildHeaderButtons = (): HeaderButton[] => {
		const newMessageText = this.state.messageStatus === core.IShared.PublicationStatus.Template ? 'New Template' : 'New Message'
		return [
			{
				action: 'createMessage',
				type: ButtonType.DEFAULT,
				text: newMessageText,
				class: 'btn-primary',
			},
		]
	}

	buildPageHeader = () => {
		const buttons: TabBarButtonInput[] = [
			{ title: 'Delivered', onClick: () => this.handleMessageState(core.IShared.PublicationStatus.Delivered), active: this.isActiveTitle('Delivered') },
			{ title: 'Draft', onClick: () => this.handleMessageState(core.IShared.PublicationStatus.Draft), active: this.isActiveTitle('Draft') },
			{ title: 'Template', onClick: () => this.handleMessageState(core.IShared.PublicationStatus.Template), active: this.isActiveTitle('Template') },
		]

		return (
			<TableHeader
				fullScreen={true}
				tabInputs={buttons}
				pageTitle='Messages'
				buttons={this.buildHeaderButtons()}
				buttonHandler={this.handleHeaderButtonAction}
			/>
		)
	}

	isActiveTitle = (title: string): boolean => {
		return title.toLocaleLowerCase() === this.state.messageStatus.toLocaleLowerCase()
	}

	buildTableContent = () => {
		return (
			<div className='message-table-container-div'>
				{this.buildMessageTable()}
			</div>
		)
	}

	buildDeleteConfirmationModal = () => {
		if (!this.state.messageToDelete) { return null }
		return (
			<ModalComponent
				modalTitle={'Delete Message'}
				primaryMessage={'Are you sure you want to delete this Message?'}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.closeDeleteMessageModal}
				submitButtonName={'Confirm'}
				submitButtonHandler={this.executeMessageDeletion}
			/>
		)
	}

	render() {
		const { loggedInClub } = this.props
		if (this.state.error) { return <ErrorComponent club={loggedInClub} isAdmin={this.props.userState.isAdmin} /> }

		const content = this.buildTableContent()

		return (
			<RS.Row>
				<RS.Col className={'no-padding'}>
					<div className='message-container'>
						{this.buildPageHeader()}
						{content}
						{this.buildDeleteConfirmationModal()}
					</div>
				</RS.Col>
			</RS.Row>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	messageState: state.message,
	userState: state.user,
	loggedInClub: state.club.loggedInClub,
})

const mapDispatchToProps = {
	...MessageActions,
	...EventActions,
	...PostActions,
	...AlertActions
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageComponent)
