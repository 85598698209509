// External Dependencies
import { Icon } from 'react-feather'

// Interfaces / Types
export enum FormInputType {
	TEXT = 'text',
	COLOR = 'color',
	EMAIL = 'email',
	ALERT = 'alert',
	PASSWORD = 'password',
	URL = 'url',
	NUMBER = 'number',
	DATE = 'date',
	TIME = 'time',
	DATE_TIME = 'datetime',
	DATE_YEAR = 'dateyear',
	FILE = 'file',
	TEXT_AREA = 'textarea',
	SELECT = 'select',
	MULTI_SELECT = 'multiselect',
	GROUPED_SELECT = 'grouped_select',
	GROUPED_MULTI_SELECT = 'grouped_multi_select',
	PRICE = 'price',
	SELECT_GROUP = 'selectgroup',
	RADIO_GROUP = 'radiogroup',
	CHECKBOX = 'checkbox',
	CHECKBOX_GROUP = 'checkboxgroup',
	BUTTON_GROUP = 'buttongroup',
	BLANK = 'blank',
	MCE_EDITOR = 'mce_editor',
	CUSTOM = 'custom',
	PHONE = 'phone',
	SECTION = 'section',
	TYPEAHEAD = 'typeahead',
	GROUPED_TYPEAHEAD = 'grouped_typeahead',
	MULTI_SELECT_TYPEAHEAD = 'multi_select_typeahead',
	GROUPED_MULTI_SELECT_TYPEAHEAD = 'grouped_multi_select_typeahead',
	MEMBER_SELECT_INPUT = 'member_select_input',
	INPUT_GROUP = 'input_group',
}

export interface InputButtonItem {
	text: string
	value: any
	key?: string
	icon?: Icon
	color?: string
	index?: number
	class?: string
	onClick?: any
}

export interface InputSelectionItem {
	label: string
	value: any
	key?: string
	icon?: Icon
	group?: string
	isFixed?: boolean
	meta?: any
	disabled?: boolean
}

export interface ReactSelectItem {
	label: string
	value?: string | any
	// For grouped React Select component
	options?: InputSelectionItem[]
	type?: string
	group?: string
}

export enum SelectType {
	MEMBERS = 'members',
	GUESTS = 'guests',
	VEHICLES = 'vehicles',
	CALENDARS = 'calendars',
	ALL_USERS = 'all users'
}

export interface FormInput {
	title?: string,
	property: string,
	icon?: Icon
	meta?: boolean
	type: FormInputType,
	selectType?: SelectType,
	placeholder?: string,
	defaultValue?: any
	selectItems?: InputSelectionItem[] | ReactSelectItem[],
	buttonItems?: InputButtonItem[],
	inputItems?: FormInput[]
	size?: number,
	class?: string,
	validation?: any,
	validationFunc?: any,
	file_accept?: string,
	file_multipleFiles?: boolean,
	select_isClearable?: boolean,
	select_selectAll?: boolean,
	disabled?: boolean,
	copyToClipboardHandler?: (shortLink: string) => void,
	number_min?: number,
	number_max?: number,
	date_start_field?: string,
	date_end_field?: string,
	date_filter_func?: (date: Date) => boolean,
	date_min_time?: Date,
	date_max_time?: Date,
	date_interval?: number,
	custom_content?: any,
	customRenderFunc?: any
}
