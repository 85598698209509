// External Dependencies
import * as React from 'react'
import classNames from 'classnames'
import { isNullOrUndefined } from 'util'

interface DimmedLoaderProps {
	isLoading: boolean
	showComponent: boolean
	component: any
}

export default class DimmedLoader extends React.Component<DimmedLoaderProps> {
	static defaultProps: Pick<DimmedLoaderProps, 'showComponent'> = {
		showComponent: true,
	}

	constructor(props: DimmedLoaderProps) {
		super(props)
	}

	render() {
		if (!this.props.isLoading) {
			return this.props.component
		}

		const dimmerClasses = classNames({
			'dimmer': true,
			'active': true,
			'full-height-dimmer': true,
			'h-100': true // Determine if this is needed
		})

		const component = this.props.showComponent ? (
				<div className='dimmer-content'>
					{this.props.component}
				</div>
		) : null

		return (
			<div className={dimmerClasses}>
				<div className='loader' />
				{component}
			</div>
		)
	}
}
