import * as core from 'club-hub-core'

import { InfoCardData } from '../../Shared/Cards/InfoCard'

export const PersonalCard = (user: core.User.Model): InfoCardData => {
	const options = { month: 'long', day: 'numeric', year: 'numeric' }
	const birthday = new Date(user.birthday).toLocaleDateString('en-US', options)
	return {
		title: 'Personal',
		bodyClass: 'card-body',
		content: [[
			{
				title: 'Birthday',
				value: (user.birthday) ? birthday : '',
				size: 6,
			},
			{
				title: 'Profession',
				value: user.jobTitle,
				size: 6,
			}
		]]
	}
}
