// External Dependencies
import * as React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { FormikProps } from 'formik'

// Internal Dependencies

// UI
import ModalComponent from '../../Modal'

// Form
import { FormikComponent, BuildWrappedForm } from '../../Formik'
import { CalendarFormInputs, NewCalendarFormState } from './form'

// Actions
import { AlertActions } from '../../../../actions/index'

type ConnectedActions = typeof mapDispatchToProps

interface ComponentProps {
	cancelButtonHandler: () => any
	submitButtonHandler: (name: string, color: string) => any
}

type Props = ConnectedActions & ComponentProps

/**
 * The `EditCalendarModal` provides UI for creating a new calendar.
 */
class EditCalendarModal extends React.Component<Props> {
	constructor(props: Props) {
		super(props)
	}

	// ----------------------------------------------------------------------------------
	// Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Handles submit button clicks.
	 */
	handleSubmitButton = async (formState: NewCalendarFormState) => {
		this.props.submitButtonHandler(formState.name, formState.color.value)
	}

	/**
	 * Handles cancel button clicks.
	 */
	handleCancelButton = async () => {
		return this.props.cancelButtonHandler()
	}

	// ----------------------------------------------------------------------------------
	// Component Builds
	// ----------------------------------------------------------------------------------

	buildModal = (formikProps: FormikProps<any>) => {
		const wrappedFormProps = { inputs: CalendarFormInputs }
		return (
			<ModalComponent
				modalTitle={'New Calendar'}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.handleCancelButton}
				submitButtonName={'Save'}
				submitButtonHandler={formikProps.submitForm}
				buildInputs={() => BuildWrappedForm(wrappedFormProps, formikProps)}
			/>
		)
	}

	public render() {
		return (
			<FormikComponent
				inputs={CalendarFormInputs}
				enableReinitialize={false}
				onSubmit={this.handleSubmitButton}
				render={this.buildModal}
			/>
		)
	}
}

const mapDispatchToProps = {
	...AlertActions
}

const enhance = compose<React.ComponentType<ComponentProps>>(
	connect(undefined, mapDispatchToProps)
)

export default enhance(EditCalendarModal)
