// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import to from 'await-to-js'
import { oc } from 'ts-optchain'

// Internal Dependencies

// Actions
import { AlertActions, CalendarActions, EventActions } from '../../../actions/index'

// Reducers
import { RootReducerState } from '../../../reducers'
import { calendarsByIDSelector } from '../../../reducers/calendar'
import { bookableMembers, guestsSelector } from '../../../reducers/user'

// Form
import FormModal from '../../Shared/Formik/FormModal'
import { GuestGolferFormState, GuestGolferFormInputs } from './form'
import { FormInput, SelectType } from '../../Shared/Form'

// Helpers
import * as Constants from '../../../constants'
import { setStateAsync } from '../../../helpers/promise'
import { userForForm } from '../../../helpers/user'
import { guestFormInputs } from '../GuestModal/form'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

interface ComponentProps {
	calendar: core.Calendar.Model
	event?: core.Event.Model
	reservation?: core.Event.Reservation
	handleCancel?: () => void
	handleSave?: () => void
}

const initialState = {
	error: false,
	loading: true,
}

type Props = ComponentProps & ConnectedState & ConnectedActions
type State = typeof initialState

class GuestGolferModal extends React.Component<Props, State> {
	private isUpdateForm: boolean

	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		await setStateAsync(this, { loading: false })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Returns the user to the Service Providers view when they cancel the form
	 */
	handleCancel = () => {
		this.props.handleCancel()
	}

	handleSaveForm = async (form: GuestGolferFormState): Promise<void> => {
		if (this.props.reservation) {
			this.updateExistingRequest(form)
			return
		}
		this.createNewRequest(form)
	}
	/**
	 * Creates a new reservation.
	 */
	createNewRequest = async (form: GuestGolferFormState): Promise<void> => {
		await setStateAsync(this, { loading: true })

		const payload = this.buildReservationPayload(form)
		const calendarID = `${this.props.calendar._id}`

		const time = new Date(form.time)
		const date = new Date(form.date)
		date.setHours(time.getHours(), time.getMinutes())
		const [err] = await to(this.props.reserveBookableEvent(payload, calendarID, date.toISOString(), date.toISOString()) as any)
		if (err) {
			this.props.fireFlashMessage(`Problem creating requests. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}

		this.props.fireFlashMessage('Request successfully created.', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, error: false })
		this.props.handleSave()
	}

	/**
	 * Updates an existing reservation.
	 */
	updateExistingRequest = async (form: GuestGolferFormState): Promise<void> => {
		await setStateAsync(this, { loading: true })
		const payload = this.buildReservationPayload(form)
		const eventID = `${this.props.event._id}`

		const reservationID = `${this.props.reservation._id}`
		const [err] = await to(this.props.updateEventRsvp(reservationID, payload, eventID) as any)
		if (err) {
			this.props.fireFlashMessage(`Problem updating Request. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}

		this.props.fireFlashMessage('Request successfully updated.', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, error: false })
		this.props.handleSave()
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildForm = () => {
		const formTitle = (this.isUpdateForm) ? 'Update Guest Request' : 'New Guest Request'
		const formInputs = this.buildFormInputs()
		const formResource = this.buildFormResource()
		return (
			<FormModal
				submitting={this.state.loading}
				modalTitle={formTitle}
				formSpec={formInputs}
				formResource={formResource}
				cancelButtonHandler={this.handleCancel}
				submitButtonHandler={this.handleSaveForm}
			/>
		)
	}

	render() {
		return this.buildForm()
	}

	// --------------------------------------------------
	// Helpers
	// --------------------------------------------------

	buildFormInputs = () => {
		const inputs = GuestGolferFormInputs
		return inputs.map((input: FormInput) => {
			if (input.selectType) {
				input.selectItems = this.getItemsForSelectType(input.selectType)
			}
			return input
		})
	}

	buildFormResource = () => {
		const now = new Date()
		const meta = oc(this).props.reservation.meta({} as any) as core.Event.GuestReservationMeta
		return {
			member: oc(this).props.reservation.owner(),
			guests: oc(this).props.reservation.participants(),
			date: oc(this).props.event.start(now),
			time: oc(this).props.event.start(now),
			guestPaying: meta.guestPaying,
			holeCount: meta.holeCount,
			rentalClubCount: meta.rentalClubCount,
			golfCartCount: meta.golfCartCount
		}
	}

	/**
	 * Builds the payload, that will be sent to the server, based on the form
	 */
	buildReservationPayload = (form: GuestGolferFormState): core.Event.Reservation => {
		const participants = [{ userID: oc(form).guests.value(), name: 'Tester' }]
		return {
			creator: this.props.userState.loggedInUser,
			owner: form.member.value,
			participants,
			meta: {
				golfCartCount: form.golfCartCount,
				guestPaying: form.guestPaying,
				holeCount: form.holeCount,
				rentalClubCount: form.rentalClubCount,
				notes: ''
			}
		}
	}

	getItemsForSelectType = (selectType: SelectType) => {
		switch (selectType) {
			case SelectType.MEMBERS:
				return this.memberInputs()
			case SelectType.GUESTS:
				return this.guestInputs()
			case SelectType.ALL_USERS:
				return [...this.memberInputs(), ...this.guestInputs()]
			case SelectType.VEHICLES:
			default:
		}
	}

	memberInputs = () => {
		return this.props.bookableMembers.map((u: core.User.Model) => {
			return { ...userForForm(u), group: 'MEMBERS' }
		})
	}

	guestInputs = () => {
		const guestInputs = this.props.guests.map((u: core.User.Model) => {
			return { ...userForForm(u), group: 'GUESTS' }
		})
		guestInputs.push({ label: 'Add Outside Guest', value: 'addGuest', group: 'GUESTS' })
		return guestInputs
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	loggedInClub: state.club.loggedInClub,
	userState: state.user,
	calendarsByID: calendarsByIDSelector(state),
	bookableMembers: bookableMembers(state),
	guests: guestsSelector(state)
})

const mapDispatchToProps = {
	...CalendarActions,
	...AlertActions,
	...EventActions
}

export default connect(mapStateToProps, mapDispatchToProps)(GuestGolferModal)
