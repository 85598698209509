// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import classNames from 'classnames'
import Dropzone from 'react-dropzone'
import { connect } from 'react-redux'
import { uniq, omit, filter } from 'underscore'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'

// Internal Dependencies
import { BaseInputPrimitiveProps } from '../index'
import { setStateAsync } from '../../../../../helpers/promise'

// Actions
import { AlertActions } from '../../../../../actions/index'

type ConnectedActions = typeof mapDispatchToProps

interface ComponentProps extends Omit<BaseInputPrimitiveProps, 'onChange' | 'value'> {
	onChange: (property: string, data: React.ChangeEvent<any>) => void
	onBlur: (property: string, didBlur: boolean) => void
}

type Props = ComponentProps & ConnectedActions
type State = typeof initialState

const initialState = {
	files: [] as Array<File | core.SubModels.Image.Model>,
	filePreviews: {} as any as { [key: string]: string },
}

class FileInput extends React.Component<Props, State> {
	private allowMultipleFiles: boolean
	constructor(props: Props) {
		super(props)

		const { form, item } = props
		const existingImages: core.SubModels.Image.Model | core.SubModels.Image.Model[] = form.values[item.property] || []
		const existingFiles = (Array.isArray(existingImages)) ? existingImages : [existingImages]
		const filteredFiles = filter(existingFiles, (file: core.SubModels.Image.Model) => file.original !== undefined)

		// Set existing images on state so their thumbnail preview can be built.
		const existingFilePreviews: typeof initialState.filePreviews = {}
		for (const existingImage of filteredFiles) {
			const image = oc(existingImage).md(existingImage.original)
			if (image) {
				existingFilePreviews[`${existingImage._id}`] = image
			}
		}

		this.allowMultipleFiles = oc(item).file_multipleFiles(false)
		this.state = { ...initialState, files: filteredFiles, filePreviews: existingFilePreviews }
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * On change handler that updates form state.
	 */
	onFileChange = async (data: React.ChangeEvent<any>) => {
		const { item, onChange } = this.props

		// This will call 'setFieldValue'
		const files = data.currentTarget.files
		onChange(item.property, files)
	}

	/**
	 * Handler called by Dropzone when an image is added.
	 * Adds the selected image to state and calls the
	 * on change handler for Formik state updates.
	 */
	onDropHandler = async (acceptedFiles: File[]): Promise<void> => {
		const { files, filePreviews } = this.state

		// Update the File Previews map with newly accepted files
		let newPreviews = { ...filePreviews }

		for (const acceptedFile of acceptedFiles) {
			// If we are only allowing a single file for this input, then
			// fully replace the 'newPreviews' variable with the file
			// and break out of the loop
			if (!this.allowMultipleFiles) {
				newPreviews = { [acceptedFile.name]: URL.createObjectURL(acceptedFile) }
				break
			}
			newPreviews = { ...newPreviews, [acceptedFile.name]: URL.createObjectURL(acceptedFile) }
		}

		let newFiles: Array<File | core.SubModels.Image.Model> = []
		if (!this.allowMultipleFiles) {
			const newFile = oc(acceptedFiles)[0]()
			newFiles = (isNullOrUndefined(newFile)) ? [] : [newFile]
		} else {
			// Update the File list with a unique set of existing/newly accepted files
			newFiles = uniq([...acceptedFiles, ...files], false, (file) => {
				if ((file as core.SubModels.Image.Model)._id) { return `${(file as core.SubModels.Image.Model)._id}` }
				return (file as File).name
			})
		}

		// Update the local state
		await setStateAsync(this, { files: newFiles, filePreviews: newPreviews })

		// Put the list of files in an event and call the change handler
		const changeEvent = { currentTarget: { files: newFiles } }
		await this.onFileChange(changeEvent as React.ChangeEvent<any>)
	}

	/**
	 * Removes the thumbnail from component state.
	 */
	handleRemoveThumbnail = async (e: React.MouseEvent<HTMLButtonElement>, imageNameOrID: string) => {
		const { files, filePreviews } = this.state

		// Stop the Event from propagating further up
		e.stopPropagation()

		// Update the File list by removing any existing files with the provided name
		const updatedFiles = files.filter((file: File | core.SubModels.Image.Model) => {
			if ((file as core.SubModels.Image.Model)._id) { return `${(file as core.SubModels.Image.Model)._id}` !== imageNameOrID }
			return (file as File).name !== imageNameOrID
		})

		// Update the File Previews map by removing any existing previews with the provided name
		const updatedPreviews = omit(filePreviews, imageNameOrID)
		await setStateAsync(this, { files: updatedFiles, filePreviews: updatedPreviews })

		// Put the list of files in an event and call the change handler
		const changeEvent = {
			currentTarget: {
				files: updatedFiles
			}
		}

		await this.onFileChange(changeEvent as React.ChangeEvent<any>)
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildContent = (): JSX.Element => {
		const { item } = this.props
		const currentImages = this.state.files

		const instructionText = (this.allowMultipleFiles) ? 'Drop files here or click to upload.' : 'Drop single file here or click to upload'
		const showInstruction = currentImages.length === 0
		const dropzoneClass = classNames({
			'dropzone-container': true,
			'no-images': showInstruction
		})

		const acceptedFileTypes = oc(item).file_accept('.png,.jpg,.jpeg')
		return (
			<Dropzone onDrop={this.onDropHandler} multiple={this.allowMultipleFiles} accept={acceptedFileTypes}>
				{({getRootProps, getInputProps}) => {
				return (
				<div className={dropzoneClass} {...getRootProps()}>
					<div>
						<input {...getInputProps()} id={`fileinputlabel_${item.property}`} />
						{showInstruction && <div>{instructionText}</div>}
					</div>
					<div>
						{this.buildThumbnails()}
					</div>
				</div>
				)}}
			</Dropzone>
		)
	}

	buildThumbnails = (): JSX.Element[] => {
		const { files, filePreviews } = this.state
		return files.map((file: File | core.SubModels.Image.Model, index: number) => {
			const fileNameOrID = ((file as core.SubModels.Image.Model)._id) ? `${(file as core.SubModels.Image.Model)._id}` : (file as File).name
			const preview = filePreviews[fileNameOrID]
			return (
				<div key={`${fileNameOrID}_${index}`} className='thumbnail-container'>
					<div className='thumbnail-inner-container'>
						<img className='thumbnail' src={preview} />
						<button
							type='button'
							onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.handleRemoveThumbnail(e, fileNameOrID)}
						>
							x
						</button>
					</div>
				</div>
			)
		})
	}

	render(): JSX.Element {
		return this.buildContent()
	}
}

const mapDispatchToProps = {
	...AlertActions
}

export default connect(undefined, mapDispatchToProps)(FileInput)
