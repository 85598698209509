// External Dependencies
import * as Yup from 'yup'
import * as core from 'club-hub-core'
import { flatten } from 'underscore'

// Internal Dependencies
import { FormInput, FormInputType, InputSelectionItem, InputButtonItem } from '../../Shared/Form'
import * as Constants from '../../../constants/index'
import * as Feather from 'react-feather'

export interface GolfReservationFormState {
	date: Date,
	time: InputSelectionItem,
	count: number,
	golfers: InputSelectionItem[],
	carts: number
	holes: number,
	notes: string,
	comp: boolean
}

interface TeeTime {
	date: Date
	golferCount: number
}

export const SimpleGolfReservationInputs = (memberSelectionItems: InputSelectionItem[], timeSelectItems: InputSelectionItem[], teeTime: TeeTime): FormInput[] => {
	return [
		{
			title: 'Date',
			property: 'date',
			type: FormInputType.DATE,
			size: 6,
			placeholder: 'Select date',
			defaultValue: '',
			validation: Yup.date().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Time',
			property: 'time',
			type: FormInputType.SELECT,
			size: 6,
			placeholder: 'Time',
			selectItems: timeSelectItems,
			icon: Feather.Clock,
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Count',
			property: 'count',
			type: FormInputType.SELECT,
			size: 6,
			selectItems: buildRoundsSelect(),
			defaultValue: '',
		},
		{
			title: 'Golfer',
			property: `golfers[0]`,
			type: FormInputType.GROUPED_TYPEAHEAD,
			size: 6,
			defaultValue: '',
			selectItems: memberSelectionItems,
			placeholder: `Select Member...`,
		}
	]
}

export const ExpandedGolfReservationInputs = (memberSelectionItems: InputSelectionItem[], timeSelectItems: InputSelectionItem[], teeTimes: TeeTime[], addGolferButton: any): FormInput[] => {
	return [
		{
			title: 'Date',
			property: 'date',
			type: FormInputType.DATE,
			size: 6,
			placeholder: 'Select date',
			defaultValue: '',
			validation: Yup.date().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Time',
			property: 'time',
			type: FormInputType.SELECT,
			size: 6,
			placeholder: 'Time',
			selectItems: timeSelectItems,
			icon: Feather.Clock,
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		},
		...golfersInputsForTeeTimes(teeTimes, memberSelectionItems),
		{
			property: 'addGolfer',
			type: FormInputType.CUSTOM,
			size: 12,
			placeholder: 'Add Golfer',
			defaultValue: '',
			customRenderFunc: addGolferButton
		},
		{
			title: 'Options',
			property: 'options',
			type: FormInputType.SECTION,
		},
		{
			title: 'Carts',
			property: 'carts',
			type: FormInputType.BUTTON_GROUP,
			size: 3,
			placeholder: 'Carts',
			buttonItems: buildCartButtons(),
			class: 'golf-reservation-button'
		},
		{
			title: 'Holes',
			property: 'holes',
			type: FormInputType.BUTTON_GROUP,
			size: 3,
			placeholder: 'End...',
			buttonItems: buildHoleButtons(),
			class: 'golf-reservation-button'
		},
		{
			title: 'Notes',
			property: 'notes',
			type: FormInputType.TEXT_AREA,
			size: 12,
			placeholder: 'Notes...',
			defaultValue: '',
		},
	]
}

const golfersInputsForTeeTimes = (teeTimes: TeeTime[], memberSelectionItems: InputSelectionItem[]): FormInput[] => {
	const inputs = teeTimes.map((teeTime: TeeTime) => {
		const golferInputs: FormInput[] = []
		for (let i = 0; i < teeTime.golferCount; i++) {
			golferInputs.push({
				title: `Golfer ${i + 1}`,
				property: `golfers[${i}]`,
				type: FormInputType.GROUPED_TYPEAHEAD,
				size: 6,
				selectItems: memberSelectionItems,
				placeholder: `Select Member...`
			})
		}
		return [
			{
				title: 'Golfers',
				property: 'golfersection',
				type: FormInputType.SECTION,
			},
			...golferInputs
		]
	})
	return flatten(inputs)
}

const buildCartButtons = () => {
	return [
		{ text: `${0}`, value: 0 },
		{ text: `${1}`, value: 1 },
		{ text: `${2}`, value: 2 }
	]
}

const buildHoleButtons = () => {
	return [
		{ text: `${9}`, value: 9 },
		{ text: `${18}`, value: 18 }
	]
}

const buildRoundsSelect = () => {
	return [
		{ label: `${0}`, value: 0 },
		{ label: `${1}`, value: 1 },
		{ label: `${2}`, value: 2 },
		{ label: `${3}`, value: 3 },
		{ label: `${4}`, value: 4 },
		{ label: `${5}`, value: 5 },
		{ label: `${6}`, value: 6 },
	]
}
