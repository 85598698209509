// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import { Icon } from 'react-feather'

export interface ButtonProps {
	text: string
	icon?: Icon
	color: string
	value: any
	index?: number
	className?: string
	onClick: (e: any) => any
}

export const Button: React.FunctionComponent<ButtonProps> = (props: ButtonProps) => {
	const iconClass = (props.text) ? `${props.icon} mr-2` : `${props.icon}`
	const FeatherIcon = props.icon
	const icon = (props.icon) ? <FeatherIcon size={18} className={iconClass} /> : null

	const onClick = (e: any) => {
		// Build our own event here to accommodate for clicks on an icons.
		props.onClick({ target: { value: props.value } })
	}

	return (
		<RS.Button
			key={`button-${props.index}`}
			color={props.color}
			value={props.value}
			onClick={onClick}
			className={`d-flex align-items-center justify-content-center ${props.className}`}
		>
			{icon}
			{props.text}
		</RS.Button>
	)
}
