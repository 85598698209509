// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'

// Internal Dependencies

// Components
import FormModal from '../../Shared/Formik/FormModal'

// Form
import { HoursFormState, HourForm } from './form'

// Helpers
import { dayOfWeekSelectionInputs } from '../../../helpers/form'

interface ComponentProps {
	saveHandler: (formState: core.IShared.HoursOfOperation[]) => Promise<void>
	closeHandler: () => void
	existingHours?: HoursFormState
}

type Props = ComponentProps
type State = any

class HoursFormComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Converts the state of the form into the 'HoursFormState'
	 * format, and sends it through the 'saveHandler' function on the props.
	 */
	handleSave = async (formState: HoursFormState) => {
		const resultForServer = this.convertFormForServer(formState)
		await this.props.saveHandler(resultForServer)
		this.props.closeHandler()
	}

	/**
	 * Calls the 'closeHandler' function on the props
	 */
	handleClose = async () => {
		this.props.closeHandler()
	}

	// ----------------------------------------------------------------------------------
	// Component Builders
	// ----------------------------------------------------------------------------------

	render() {
		const { existingHours } = this.props

		return (
			<FormModal
				modalTitle={'Hours of Operation'}
				formResource={existingHours}
				formSpec={HourForm(dayOfWeekSelectionInputs)}
				submitButtonHandler={this.handleSave}
				cancelButtonHandler={this.handleClose}
			/>
		)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	/**
	 * Converts the given HoursFormState to conform to the core.Calendar.HoursOfOperation[]
	 * interface, which is used by the server.
	 */
	convertFormForServer = (formState: HoursFormState): core.IShared.HoursOfOperation[] => {
		const hoursFormForServer = {
			dayOfWeek: Number(formState.dayOfWeek.value),
			opens: formState.opens,
			closes: formState.closes,
			duration: formState.duration,
		}
		return [hoursFormForServer]
	}
}

export default HoursFormComponent
