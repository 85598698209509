// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { LocationDescriptorObject } from 'history'
import { RouteComponentProps } from 'react-router'
import { oc } from 'ts-optchain'
import { Formik } from 'formik'
import to from 'await-to-js'

// Internal Dependencies

// Actions
import { MessageActions, PostActions, EventActions, AlertActions } from '../../../actions/index'

// State
import { RootReducerState } from '../../../reducers'
import { adminsSelector, usersByIDSelector, } from '../../../reducers/user'
import { clubUserGroupsByIDSelector } from '../../../reducers/club'
import { removeStateForKey } from '../../../store/localStorage'
import { eventsByIDSelector } from '../../../reducers/event'

// Components
import DeliveryTypePicker from './DeliveryTypePicker'
import MessageCreationForm from './MessageCreationForm'
import DimmedLoader from '../../Shared/DimmedLoader'
import MessageConfirmationModal from './MessageConfirmationModal'
import ActionBar from '../../Shared/ActionBar'

// Helpers
import { setStateAsync } from '../../../helpers/promise'
import * as userHelper from '../../../helpers/user'
import * as Constants from '../../../constants'
import RichContent from '../../Shared/RichContent'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

enum FormStep {
	DeliveryType = 0,
	MessageForm = 1,
	MessagePreview = 2,
}

const initialState = {
	error: false,
	initialLoading: true,
	loading: false,
	confirming: false,
	testMessage: false,
	formStep: FormStep.DeliveryType,
	messagePayload: null as any,
	eventID: null as any
}

type Props = RouteComponentProps & ConnectedActions & ConnectedState
type State = typeof initialState

class MessageForm extends React.Component<Props, State> {
	private formikRef: Formik

	constructor(props: Props) {
		super(props)
		this.setCurrentMessageFromPath()
		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		// Check if there is a resource
		await setStateAsync(this, { initialLoading: false })
	}

	async componentDidUpdate(prevProps: Props) {
		this.updateFormStepWithProps()
	}

	componentWillUnmount() {
		// Clear our message state if we are bailing.
	}

	updateFormStepWithProps = () => {
		const locationPath = this.props.history.location.pathname
		const matchPath = this.props.match.path
		const currentPath = this.props.match.isExact ? matchPath : locationPath
		let formStep: FormStep
		switch (currentPath) {
			case Constants.CREATE_MESSAGE_ROUTE:
				// If we're at the medium selection, clear the state
				formStep = FormStep.DeliveryType
				break
			case Constants.EDIT_MESSAGE_ROUTE:
			case Constants.UPDATE_MESSAGE_ROUTE:
				formStep = FormStep.MessageForm
				break
			case Constants.UPDATE_PREVIEW_MESSAGE_ROUTE:
			case Constants.CREATE_PREVIEW_MESSAGE_ROUTE:
				formStep = FormStep.MessagePreview
				break
		}

		if (this.state.formStep !== formStep) {
			this.setState({ formStep })
		}
	}

	returnToMessageTable = (messageStatus: core.IShared.PublicationStatus) => {
		let pathname: string
		switch (messageStatus) {
			case core.IShared.PublicationStatus.Template:
				pathname = Constants.MESSAGES_TEMPLATE_ROUTE
				break
			case core.IShared.PublicationStatus.Draft:
				pathname = Constants.MESSAGES_DRAFT_ROUTE
				break
			default:
				pathname = Constants.MESSAGES_ROUTE
				break

		}

		const location = {
			pathname
		}
		this.props.history.push(location)
	}

	/**
	 * setCurrentMessageFromPath sets the currentMessage state based upon
	 * the path found in the URL. If the state matches the existing currentMessage,
	 * then we work from our in-mem message. If not, we clear the existing
	 * currentMessage and start anew.
	 *
	 * @memberof MessageForm
	 */
	setCurrentMessageFromPath = () => {
		// Extract the path / history info
		const { location } = this.props
		const parsedQuery = queryString.parse(location.search)
		const { Push, Email, Text } = core.Message.DeliveryType
		const { params } = this.props.match as any

		let typedDeliveryType: core.Message.DeliveryType
		const deliveryType = params.delivery_type as string
		if (deliveryType) {
			const upcaseDeliveryType = deliveryType.toUpperCase()
			switch (upcaseDeliveryType) {
				case core.Message.DeliveryType.Email:
					typedDeliveryType = core.Message.DeliveryType.Email
					break
				case core.Message.DeliveryType.Push:
					typedDeliveryType = core.Message.DeliveryType.Push
					break
				case core.Message.DeliveryType.Text:
					typedDeliveryType = core.Message.DeliveryType.Text
					break
			}

		}

		// Determine the Subject Type
		let subjectType: core.Message.SubjectType
		let subjectID: string
		const { Event, Post, Reservation } = core.Message.SubjectType
		if (parsedQuery.eventID) {
			subjectType = Event
			subjectID = parsedQuery.eventID
		} else if (parsedQuery.postID) {
			subjectType = Post
			subjectID = parsedQuery.postID
		} else if (parsedQuery.reservationID) {
			subjectType = Reservation
			subjectID = parsedQuery.reservationID
		}

		// Evaluate the `currentMessage` to see if the values line up
		// with the declared state in the path.
		const message = this.props.messageState.currentMessage
		const messageSubjectType = oc(message).subjectType()
		const messageSubjectID = oc(message).subjectID() as any as string
		const messageDeliveryType = oc(message).deliveryType()
		const isSameMessage = (
			messageDeliveryType === typedDeliveryType &&
			messageSubjectType === subjectType &&
			messageSubjectID === subjectID
		)

		// If its not the same message, we need to load a new message
		const coreSubjectID = subjectID as any as core.CoreModelID
		if (!isSameMessage) {
			this.props.setCurrentMessage({
				subjectType,
				deliveryType: typedDeliveryType,
				subjectID: coreSubjectID
			})
		}
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Delivery Type Picker
	// ----------------------------------------------------------------------------------

	handleDeliveryTypeClicked = async (type: core.Message.DeliveryType) => {
		let location: LocationDescriptorObject

		// Update the current message's delivery type
		await this.props.setCurrentMessage({
			...this.props.messageState.currentMessage,
			deliveryType: type
		})

		location = {
			pathname: Constants.EDIT_MESSAGE_ROUTE.replace(':delivery_type', type.toLowerCase()),
			search: this.props.location.search
		}

		this.props.history.push(location)
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Message Form
	// ----------------------------------------------------------------------------------

	/**
	 * Handles cancel events. Redirects user to the proper location based on the subject.
	 */
	handleCancel = async () => {
		// If we're previewing a message, step back to the appropriate
		// deliveryType edit stage
		if (this.state.formStep === FormStep.MessagePreview) {
			return this.props.history.goBack()
		}

		// Currently the only other place we have a cancel button is the
		// editor, so this is the base state. We never explicitly navigate
		// back to the base state, it's observed from props
		this.props.history.push(Constants.CREATE_MESSAGE_ROUTE)
		this.clearMessageState()
	}

	/**
	 * Handles clicks to saving a draft.
	 */
	handleSaveDraft = async (payload: Partial<core.Message.Model | any>): Promise<void> => {
		const messagePayload = {
			...payload,
			_id: oc(this).props.messageState.currentMessage._id(),
			richContent: {
				...payload.richContent,
				status: core.IShared.PublicationStatus.Draft,
			}
		}
		this.props.setCurrentMessage(messagePayload)
		await this.handleSaveMessage(messagePayload, 'Draft Saved')
	}

	/**
	 * Handles generating a new message from a template
	 */
	handleSendTemplate = async (template: Partial<core.Message.Model>) => {
		const currentMessage: core.Message.Model = {
			author: this.props.userState.loggedInUser,
			clubID: this.props.loggedInClub._id,
			deliveryType: template.deliveryType,
			richContent: {
				...template.richContent,
				status: core.IShared.PublicationStatus.Draft
			},
			title: template.title,
			individualUserIDs: [],
			userGroupIDs: []
		}

		const path = Constants.UPDATE_MESSAGE_ROUTE.replace(':delivery_type', template.deliveryType.toLocaleLowerCase())
		await this.props.setCurrentMessage(currentMessage)
		this.props.history.push(path)
	}

	/**
	 * Handles clicks to saving a template.
	 */
	handleSendTest = async (payload?: Partial<core.Message.Model>, redirect = true): Promise<void> => {
		payload = payload ? payload : this.props.messageState.currentMessage
		await this.props.setCurrentMessage(payload)
		await this.handleSaveMessage(this.props.messageState.currentMessage, null, false)
		await setStateAsync(this, { confirming: true, testMessage: true })
	}

	/**
	 * Handles clicks to saving a template.
	 */
	handleSaveTemplate = async (payload?: Partial<core.Message.Model>, redirect = true): Promise<void> => {
		payload = payload ? payload : this.props.messageState.currentMessage
		const messagePayload: core.Message.Model = {
			...payload,
			_id: oc(this).props.messageState.currentMessage._id(),
			richContent: {
				...payload.richContent,
				status: core.IShared.PublicationStatus.Template,
			}
		}
		await this.props.setCurrentMessage(messagePayload)
		await this.handleSaveMessage(this.props.messageState.currentMessage, 'Template Saved', redirect)
	}

	/**
	 * Handles updating a message.
	 */
	handleSaveMessage = async (message: core.Message.Model, successMessage: string, redirect = true) => {
		const { createMessage, updateMessage, fireFlashMessage } = this.props
		await setStateAsync(this, { loading: true })

		const saveFunction: any = message._id ?
			updateMessage(`${message._id}`, message) :
			createMessage(message)

		const [updateErr] = await to(saveFunction)
		if (updateErr) {
			fireFlashMessage(`Problem updating Message. ${updateErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}

		if (successMessage) {
			fireFlashMessage(successMessage, Constants.FlashType.SUCCESS)
		}
		if (redirect) {
			this.returnToMessageTable(message.richContent.status)
			this.clearMessageState()
		}
		await setStateAsync(this, { loading: false, error: false })
	}

	/**
	 * Handles sending a message.
	 */
	handleSendMessage = async () => {
		const { sendMessage, sendTestMessage, fireFlashMessage } = this.props
		await setStateAsync(this, { loading: true })

		const draftMessageID = `${oc(this).props.messageState.currentMessage._id()}`
		const [sendErr] = await to(sendMessage(draftMessageID) as any)
		if (sendErr) {
			await setStateAsync(this, { confirming: false, loading: false })
			fireFlashMessage(`Failed to send message. ${sendErr}`, Constants.FlashType.DANGER)
			return
		}
		this.setState({ loading: false })

		fireFlashMessage(`Successfully sent message.`, Constants.FlashType.SUCCESS)
		this.returnToMessageTable(core.IShared.PublicationStatus.Delivered)
		await this.clearMessageState()
	}

	/**
	 * Handles sending a message.
	 */
	handleSendTestMessage = async () => {
		const { sendTestMessage, fireFlashMessage } = this.props
		await setStateAsync(this, { loading: true })

		const draftMessageID = `${oc(this).props.messageState.currentMessage._id()}`
		const [sendErr] = await to(sendTestMessage(draftMessageID) as any)
		if (sendErr) {
			await setStateAsync(this, { confirming: false, loading: false })
			fireFlashMessage(`Failed to send message. ${sendErr}`, Constants.FlashType.DANGER)
			return
		}
		this.setState({ loading: false })

		fireFlashMessage(`Successfully sent message.`, Constants.FlashType.SUCCESS)
		await setStateAsync(this, { testMessage: false })
	}

	/**
	 * Handle clicks to preview a message.
	 */
	handlePreview = async (payload: Partial<core.Message.Model | any>): Promise<void> => {
		const { createMessage, updateMessage, fetchPreviewMessage, fireFlashMessage, history } = this.props
		await setStateAsync(this, { loading: true })

		const messageID = oc(this).props.messageState.currentMessage._id()
		const saveFunction = (messageID) ? updateMessage(`${messageID}`, payload) : createMessage(payload)
		const [err] = await to(saveFunction as any)
		if (err) {
			fireFlashMessage(`Failed to create message preview. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false })
			return
		}

		// Get the Message Preview
		const msg = oc(this).props.messageState.currentMessage()
		const currentMessageID = `${msg._id}`
		const [previewErr] = await to(fetchPreviewMessage(currentMessageID) as any)
		if (previewErr) {
			fireFlashMessage(`Failed to create message preview. ${previewErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false })
			return
		}

		const route = Constants.CREATE_PREVIEW_MESSAGE_ROUTE.replace(':delivery_type', oc(msg).deliveryType().toLocaleLowerCase())
		const currentSearch = this.props.history.location.search
		const location = { pathname: route, search: currentSearch }
		history.push(location)
		this.setState({ loading: false })
	}

	/**
	 * Handles confirming a message.
	 * If we are sending a push or text, we create it here.
	 */
	handleConfirmMessage = async (payload?: Partial<core.Message.Model | any>) => {
		const { createMessage, fireFlashMessage } = this.props
		// If we are sending a push or text, we don't preview the message so we create it here.
		if (payload && payload.deliveryType !== core.Message.DeliveryType.Email) {
			await setStateAsync(this, { loading: true })
			// Create the Message
			const [createErr] = await to(createMessage(payload) as any)
			if (createErr) {
				fireFlashMessage(`Failed to create message. ${createErr}`, Constants.FlashType.DANGER)
				await setStateAsync(this, { confirming: false, loading: false, testMessage: false })
				return
			}
			await setStateAsync(this, { loading: false })
		}
		setStateAsync(this, { confirming: true, messagePayload: payload, testMessage: false })
	}

	/**
	 * Handles cancelling out of the confirmation modal.
	 */
	handleConfirmationCancel = () => {
		setStateAsync(this, { confirming: false, testMessage: false })
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildContent = () => {
		// If we don't have a current message, we need to restart the process
		if (!this.props.messageState.currentMessage) {
			return (<DeliveryTypePicker onClick={this.handleDeliveryTypeClicked} />)
		}

		switch (this.state.formStep) {
			case FormStep.MessageForm:
				return this.buildForm()
			case FormStep.MessagePreview:
				return this.buildPreview()
			case FormStep.DeliveryType:
			default:
				return (<DeliveryTypePicker onClick={this.handleDeliveryTypeClicked} />)
		}
	}

	// ----------------------------------------------------------------------------------
	// STEP TWO - Message Form
	// ----------------------------------------------------------------------------------

	buildForm = () => {
		const message = this.props.messageState.currentMessage
		const status = oc(message).richContent.status(core.IShared.PublicationStatus.Draft)
		const subjectType = oc(this).props.messageState.currentMessage.subjectType()
		const messageFormRef = (ref: Formik) => {
			this.formikRef = ref
		}
		return (
			<MessageCreationForm
				handleCancel={this.handleCancel}
				handleSaveDraft={this.handleSaveDraft}
				handlePreview={this.handlePreview}
				handlePublish={this.handleConfirmMessage}
				handleSaveTemplate={this.handleSaveTemplate}
				handleSendTemplate={this.handleSendTemplate}
				subjectType={subjectType}
				status={status}
				formikRef={messageFormRef}
			/>
		)

	}

	// ----------------------------------------------------------------------------------
	// STEP THREE - Message Preview
	// ----------------------------------------------------------------------------------

	buildPreview = () => {
		const messagePreview = oc(this).props.messageState.currentMessage.richContent.publishedHtml()
		return (
			<div className='message-preview-container'>
				<div className='message-rich-content-container'>
					<RichContent content={messagePreview} />
				</div>
			</div>
		)
	}

	buildConfirmationModal = () => {
		if (!this.state.confirming) {
			return
		}
		const message = this.props.messageState.currentMessage || this.state.messagePayload
		if (!message) {
			return
		}

		let sendFunc = this.handleSendMessage
		if (this.state.testMessage) {
			sendFunc = this.handleSendTestMessage
		}

		const author = this.getMessageAuthor(message)
		const delivery = this.getMessageDeliveryDate(message)

		let recipient = this.getMessageRecipient(message)
		if (this.state.testMessage) {
			const user = this.props.userState.loggedInUser
			recipient = `${user.firstName} ${user.lastName}`
		}

		return (
			<MessageConfirmationModal
				isLoading={this.state.loading}
				type={message.deliveryType}
				author={author}
				recipients={recipient}
				subject={message.title}
				delivery={delivery}
				submitButtonHandler={sendFunc}
				cancelButtonHandler={this.handleConfirmationCancel}
				testSend={this.state.testMessage}
			/>
		)
	}

	render() {
		if (this.state.initialLoading) {
			return <DimmedLoader component={null} isLoading={true} />
		}
		const actionButtons = this.getActionBarButtons()
		const actionBar = (this.state.formStep === FormStep.MessagePreview) ? (
			<div className='col-12 action-bar-col'>
				<ActionBar buttonItems={actionButtons} onCancel={this.handleCancel} />
			</div>
		) : null

		return (
			<div className={`message-form-container justify-content-center`}>
				{actionBar}
				<div className='col col-md-10 col-xl-8 message-form-inner-container'>
					<DimmedLoader component={this.buildContent()} isLoading={this.state.loading} />
				</div>
				{this.buildConfirmationModal()}
			</div>
		)
	}

	getActionBarButtons = () => {
		return [
			{ title: 'Send', onClick: () => this.handleConfirmMessage() },
			{ title: 'Send Test', onClick: () => this.handleSendTest() },
			{ title: 'Save Draft', onClick: () => this.handleSaveDraftAction() },
			{ title: 'Save Template', onClick: () => this.handleSaveTemplate() },
		]
	}

	handleSaveDraftAction = async () => {
		const { fireFlashMessage } = this.props
		fireFlashMessage(`Draft saved.`, Constants.FlashType.SUCCESS)
		await this.clearMessageState()
		this.returnToMessageTable(core.IShared.PublicationStatus.Draft)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	clearMessageState = async () => {
		const { clearCurrentMessage, clearCurrentEvent, clearCurrentPost } = this.props
		const clearStatePromises = [clearCurrentMessage(), clearCurrentEvent(), clearCurrentPost()]
		removeStateForKey(`${Constants.CREATE_MESSAGE_ROUTE}__formState`)
		removeStateForKey(`${Constants.UPDATE_MESSAGE_ROUTE}__formState`)
		await Promise.all(clearStatePromises)
	}

	getMessageAuthor = (message: core.Message.Model) => {
		const adminUsers = this.props.admins
		const author: core.User.Model = userHelper.userWithID(adminUsers, message.author)
		if (!author) {
			return ''
		}
		return userHelper.fullName(author)
	}

	getMessageRecipient = (message: core.Message.Model) => {
		const individuals = oc(message).individualUserIDs([])
		const groups = oc(message).userGroupIDs([])
		const events = oc(message).eventIDs([])

		const strings: string[] = []
		if (individuals.length > 0) {
			const recipient: core.User.Model = this.props.usersByID[`${individuals[0]}`]
			const singleMemberString = userHelper.fullName(recipient)
			const multiMemberString = `${individuals.length} Members`
			const individualString = (individuals.length > 1) ? multiMemberString : singleMemberString
			strings.push(individualString)
		}

		if (groups.length > 0) {
			const group: core.User.UserGroup = this.props.userGroupsByID[`${groups[0]}`]
			const singleEventString = oc(group).name('')
			const multiEventString = `${groups.length} Groups`
			const groupString = (groups.length > 1) ? multiEventString : singleEventString
			strings.push(groupString)
		}

		if (events.length > 0) {
			const event: core.Event.Model = this.props.eventsByID[`${events[0]}`]
			const singleEventString = oc(event).name('')
			const multiEventString = `${events.length} Events`
			const eventString = (events.length > 1) ? multiEventString : singleEventString
			strings.push(eventString)
		}
		return strings.join(', ')
	}

	getMessageDeliveryDate = (message: core.Message.Model) => {
		const deliveryDate = new Date(message.deliveryDate)
		const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }
		const dateString: string = deliveryDate.toLocaleDateString('en-US', options)
		return deliveryDate > new Date() ? dateString : 'Immediate'
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	userState: state.user,
	postState: state.post,
	eventState: state.event,
	messageState: state.message,
	loggedInClub: state.club.loggedInClub,
	admins: adminsSelector(state),
	eventsByID: eventsByIDSelector(state),
	usersByID: usersByIDSelector(state),
	userGroupsByID: clubUserGroupsByIDSelector(state)
})

const mapDispatchToProps = {
	...MessageActions,
	...PostActions,
	...EventActions,
	...AlertActions,
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageForm)
