// External Dependencies
import * as Feather from 'react-feather'

// Internal Dependencies
import { TableColumn } from '../Shared/Table'
import { TableColumnType } from '../Shared/Table/Primitives'

export const MenuTableColumns: TableColumn[] = [
	{
		title: '',
		property: '',
		type: TableColumnType.CHECKBOX,
		action: '',
	},
	{
		title: 'Name',
		property: 'name',
		type: TableColumnType.TEXT,
		action: 'name',
	},
	{
		title: 'Items',
		property: 'items',
		type: TableColumnType.ARRAY_LENGTH,
		action: 'name',
	},
	{
		title: 'Last Updated',
		property: 'updatedAt',
		type: TableColumnType.DATE_TIME,
		action: 'name',
	},
	{
		title: '',
		property: '',
		type: TableColumnType.DROPDOWN,
		class: 'table-dropdown-menu',
		dropdownItems: [
			{
				action: 'viewMenuItems',
				valueProperty: '_id',
				displayText: 'View Menus',
				icon: Feather.Eye,
			},
			{
				action: 'editMenu',
				valueProperty: '_id',
				displayText: 'Edit Menu',
				icon: Feather.Edit,
			},
			{
				action: 'deleteMenu',
				valueProperty: '_id',
				displayText: 'Delete Menu',
				icon: Feather.Trash,
			},
		]
	}
]
