import * as React from 'react'
import * as RS from 'reactstrap'

import * as moment from 'moment'
import * as core from 'club-hub-core'
import { oc } from 'ts-optchain'

export interface TabBarButtonItem {
	title: string
	onClick: () => any
}

interface ComponentProps {
	buttonItems?: TabBarButtonItem[],
	onCancel: () => void
}

const initialState = {
	activeButton: null as string | null,
	dropdownOpen: null as any
}

type Props = ComponentProps
type State = typeof initialState

export class TabBarComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
	}

	toggle = async () => {
		this.setState({ dropdownOpen: !this.state.dropdownOpen })
	}

	buildBar = () => {
		const buttonItems = oc(this).props.buttonItems([])
		const buttonItem = buttonItems[0]
		const dropdownItems = buttonItems.slice(1).map((item: TabBarButtonItem, idx: number) => {
			return (<RS.DropdownItem key={`${item.title}_${idx}`} onClick={item.onClick}>{item.title}</RS.DropdownItem>)
		})
		return (
			<RS.Container className='top-bar-container no-gutters'>
				<RS.Row className='top-bar-row'>
					<RS.Col className='top-bar-back-col d-flex align-items-center'>
						<RS.Button
							className={'top-bar-button back'}
							onClick={this.props.onCancel}
						>
							{'Back'}
						</RS.Button>
					</RS.Col>
					<RS.Col className='top-bar-action-col d-flex align-items-center justify-content-end'>
						<RS.ButtonDropdown
							className={`top-bar-dropdown-button`}
							isOpen={this.state.dropdownOpen}
							toggle={this.toggle}
						>
							<RS.Button
								className={'top-bar-button'}
								key={buttonItem.title}
								color='primary'
								onClick={buttonItem.onClick}
							>
								{buttonItem.title}
							</RS.Button>
							<RS.DropdownToggle color='primary' caret={true} />
							<RS.DropdownMenu>
								{dropdownItems}
							</RS.DropdownMenu>
						</RS.ButtonDropdown>
					</RS.Col>
				</RS.Row>
			</RS.Container>
		)
	}
	render() {
		return this.buildBar()
	}
}

export default TabBarComponent
