// External Dependencies
import * as core from 'club-hub-core'
import { createSelector } from 'reselect'
import { indexBy } from 'underscore'

// Internal Dependencies
import { TypeKeys, ActionTypes } from '../actions/calendar'
import { RootReducerState } from './index'
import { mergeUpdatedModelObject } from '../helpers/reducer'

export type CalendarState = typeof defaultState

export const defaultState = {
	groups: null as core.Calendar.Group[] | null,
	calendars: null as core.Calendar.Model[] | null,
	currentCalendar: null as core.Calendar.Model | null,
	currentCalendarGroup: null as core.Calendar.Group | null,
}

function calendar(state = defaultState, action: ActionTypes): CalendarState {
	switch (action.type) {
		case TypeKeys.FETCHED_CALENDARS:
			return {
				...state,
				calendars: action.calendars,
			}
		case TypeKeys.CREATED_CALENDAR:
			return {
				...state,
				calendars: mergeUpdatedModelObject(action.calendar, state.calendars),
			}
		case TypeKeys.UPDATED_CALENDAR:
			return {
				...state,
				calendars: mergeUpdatedModelObject(action.calendar, state.calendars)
			}
		case TypeKeys.DELETED_CALENDAR:
			return {
				...state,
				calendars: state.calendars.filter((cal) => {
					return `${cal._id}` !== action.calendarID
				}),
			}
		case TypeKeys.FETCHED_CALENDAR_GROUPS:
			return {
				...state,
				groups: action.groups,
			}
		case TypeKeys.CREATED_CALENDAR_RESERVATION_SETTING:
		case TypeKeys.UPDATED_CALENDAR_RESERVATION_SETTING:
			return {
				...state,
				calendars: mergeUpdatedModelObject(action.calendar, state.calendars),
				currentCalendar: action.calendar
			}
		case TypeKeys.DELETED_CALENDAR_RESERVATION_SETTING:
			return {
				...state,
				calendars: mergeUpdatedModelObject(action.calendar, state.calendars),
				currentCalendar: null,
			}
		case TypeKeys.SET_CURRENT_CALENDAR:
			return {
				...state,
				currentCalendar: action.calendar,
				currentCalendarGroup: action.group,
				calendars: mergeUpdatedModelObject(action.calendar, state.calendars),
			}
		default:
			return state
	}
}

// Selectors
const calendars = (state: RootReducerState) => state.calendar.calendars
const calendarGroups = (state: RootReducerState) => state.calendar.groups
const loggedInClub = (state: RootReducerState) => state.club.loggedInClub

export const calendarsByIDSelector = createSelector([calendars], (calList) => indexBy(calList, '_id'))
export const calendarGroupsByIDSelector = createSelector([calendarGroups], (calGroupList) => indexBy(calGroupList, '_id'))
export const clubCalendarsForCurrentClub = createSelector([calendars, loggedInClub], (calendarz, club) => {
	const clubCalGroup = club.calendarGroups.find((calGroup: core.Calendar.Group) => {
		if (calGroup.type === 'CLUB') {
			return calGroup
		}
	})

	// Sometimes we call this while loading other parts of state
	if (!calendarz) {
		return []
	}

	return calendarz.filter((c: core.Calendar.Model) => c.groupID === clubCalGroup._id)
})

export default calendar
