// External Dependencies
import * as core from 'club-hub-core'
import { oc } from 'ts-optchain'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

// Internal Dependencies
import { InputSelectionItem } from '../components/Shared/Form'
import { formatPhoneNumber } from './formatting'

export const fullName = (user: core.User.Model) => {
	if (!user) { return '' }
	return `${user.firstName} ${user.lastName}`
}

export const userForForm = (user: core.User.Model): InputSelectionItem => {
	return { label: fullName(user), value: `${user._id}` }
}

export const memberInputForForm = (user: core.User.Model): any => {
	const participant = userForForm(user)
	return { participant: participant, needsCart: false, numHoles: 18 }
}

export const participantForForm = (participant: core.Event.Participant) => {
	if (!participant.userID) {
		return { label: 'Guest', value: undefined }
	}
	return { label: participant.name, value: `${participant.userID}` }
}

export const userGroupForForm = (group: core.User.UserGroup): InputSelectionItem => {
	return {
		label: group.name,
		value: `${group._id}`,
	}
}

export const getVehiclesForUser = (user: core.User.Model): core.SubModels.CarMeta.Vehicle[] => {
	if (user.meta && user.meta.car) {
		return user.meta.car.vehicles
	}
	return []
}

export const userWithID = (users: core.User.Model[], id: any): core.User.Model => {
	const filtered = users.find((user: core.User.Model) => {
		return `${user._id}` === `${id}`
	})
	return filtered
}

export const groupWithID = (club: core.Club.Model, id: any): core.User.UserGroup => {
	const filtered = club.userGroups.find((group: core.User.UserGroup) => {
		return `${group._id}` === `${id}`
	})
	return filtered
}

export const groupsForUser = (user: core.User.Model, club: core.Club.Model) => {
	return club.userGroups.filter((group: core.User.UserGroup) => {
		const castedUsers = group.users as any[]
		return castedUsers.find((u: any) => `${u}` === `${user._id}`)
	})
}

export const getUserSubtitle = (user: core.User.Model, club: core.Club.Model) => {
	const options = { month: '2-digit', day: '2-digit', year: '2-digit' }
	let joinedString = '--'
	if (user.joined) {
		const joinedDate = new Date(user.joined)
		joinedString = joinedDate.toLocaleDateString('us-EN', options)
	}
	return (club.type.toUpperCase() === core.Club.Type.Car.toUpperCase()) ?
		`Member Number: ${getMemberNumber(user, club)}` :
		`Joined: ${joinedString}`
}

export const getMemberNumber = (user: core.User.Model, club: core.Club.Model) => {
	const memberNumber = oc(user).memberNumber.number('--')
	const suffix = oc(user).memberNumber.suffix()
	return (club.type.toUpperCase() === core.Club.Type.Car.toUpperCase()) ? memberNumber : `${memberNumber}-${suffix}`
}

export const getMemberPrimaryPhoneNumber = (user: core.User.Model) => {
	const userPhoneNumbers = oc(user).phoneNumbers([])
	const userPrimaryPhoneNumber = oc(userPhoneNumbers.find((phoneNumber) => phoneNumber.primary)).number('')
	const formattedPhone = parsePhoneNumberFromString(userPrimaryPhoneNumber, 'US')
	return formattedPhone ? formattedPhone.formatNational() : userPrimaryPhoneNumber
}
