// External Dependencies
import * as core from 'club-hub-core'
import { indexBy, omit } from 'underscore'

// Internal Dependencies
import { ActionTypes, TypeKeys } from '../actions/queryFilter'
import { RootReducerState } from './index'

export type QueryFilterState = typeof defaultState

const defaultState = {
	filtersByID: {} as any as { [id: string]: core.QueryFilter.Model },
	filterIDs: [] as string[],
}

function queryFilterReducer(state = defaultState, action: ActionTypes) {
	switch (action.type) {
		case TypeKeys.FETCHED_FILTERS:
			return {
				...state,
				filtersByID: indexBy(action.filters, '_id'),
				filterIDs: action.filters.map((f) => `${f._id}`),
			}
		case TypeKeys.CREATED_FILTER:
			return {
				...state,
				filtersByID: {
					...state.filtersByID,
					[`${action.createdFilter._id}`]: action.createdFilter
				},
				filterIDs: [...state.filterIDs, `${action.createdFilter._id}`],
			}
		case TypeKeys.UPDATED_FILTER:
			return {
				...state,
				filtersByID: {
					...state.filtersByID,
					[`${action.updatedFilter._id}`]: action.updatedFilter
				},
				filterIDs: [...state.filterIDs, `${action.updatedFilter._id}`],
			}
		case TypeKeys.DELETED_FILTER:
			return {
				...state,
				filtersByID: omit(state.filtersByID, `${action.deletedFilter._id}`),
				filterIDs: state.filterIDs.filter((id) => id !== `${action.deletedFilter._id}`)
			}
		default:
			return state
	}
}

export const getFilterByID = (state: RootReducerState, props: { filterID: string }) => {
	return state.queryFilter.filtersByID[props.filterID]
}

export default queryFilterReducer
