// External Dependencies
import * as React from 'react'
import * as Feather from 'react-feather'
import { isNullOrUndefined } from 'util'

// Internal Dependencies
import { BaseTablePrimitiveProps } from '../index'
import { BaseTableCell } from '../BaseTableCell'

type Props<T> = BaseTablePrimitiveProps<T> & { dropdownHandler: (value: any[]) => any }

export const TableDropdown: React.FC<Props<any>> = (props: Props<any>) => {
	const dropdownContents = props.column.dropdownItems.map((dropdownItem, idx) => {
		const FeatherIcon = dropdownItem.icon
		const icon = (dropdownItem.icon) ? <FeatherIcon className='dropdown-icon' /> : null
		const dropdownValue = [dropdownItem.action, props.item[dropdownItem.valueProperty]]

		// Check if the dropdown item is to be shown
		if (!isNullOrUndefined(dropdownItem.isVisible)) {
			const itemIsVisible = props.item[dropdownItem.isVisible]
			if (!isNullOrUndefined(itemIsVisible) && !itemIsVisible) {
				return null
			}
		}

		// Check if the dropdown item can be disabled
		let isDisabled = false
		if (!isNullOrUndefined(dropdownItem.isEnabled)) {
			const itemIsEnabled = props.item[dropdownItem.isEnabled]
			isDisabled = !isNullOrUndefined(itemIsEnabled) && !itemIsEnabled
		}

		return (
			<button
				key={`dropdownItem_${idx}`}
				className='dropdown-item d-flex align-items-center'
				onClick={() => props.dropdownHandler(dropdownValue)}
				value={dropdownValue}
				disabled={isDisabled}
			>
				{icon}
				<span>{dropdownItem.displayText}</span>
			</button>
		)
	})

	const filteredDropdownContents = dropdownContents.filter((item) => !isNullOrUndefined(item))

	// Destructure the props so that we don't pass unnecessary props
	const { dropdownHandler, ...others } = props

	return (
		<BaseTableCell
			{...others}
			onClick={(e) => {
				// Prevent the parent table row from having its 'onClick' called
				e.nativeEvent.stopImmediatePropagation()
				e.stopPropagation()
			}}
		>
			<div className={`item-action dropdown ${props.index}`}>
				<button className={`icon item-action-button-${props.index}`} data-toggle='dropdown' data-boundary='window'>
					<Feather.MoreVertical size={20} />
				</button>
				<div className='dropdown-menu dropdown-menu-right'>
					{filteredDropdownContents}
				</div>
			</div>
		</BaseTableCell>
	)
}
