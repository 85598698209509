// External Dependencies
import * as React from 'react'
import { Omit } from 'react-router'
import { flatten } from 'underscore'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'

// Internal Dependencies
import { BaseInputPrimitiveProps } from '../index'
import { InputSelectionItem, FormInputType, FormInput, ReactSelectItem } from '../../../Form'
import TypeaheadComponent from '../../../Typeahead'

interface ComponentProps extends Omit<BaseInputPrimitiveProps, 'onChange'> {
	onChange: (property: string, data: InputSelectionItem | InputSelectionItem[]) => void
	onBlur: (property: string, didBlur: boolean) => void
}

type Props = ComponentProps

export const TypeaheadInput: React.FunctionComponent<Props> = (props: Props) => {
	const { item } = props

	const isGrouped =
		item.type === FormInputType.GROUPED_TYPEAHEAD ||
		item.type === FormInputType.GROUPED_MULTI_SELECT_TYPEAHEAD
	const isMulti =
		item.type === FormInputType.MULTI_SELECT_TYPEAHEAD ||
		item.type === FormInputType.GROUPED_MULTI_SELECT_TYPEAHEAD

	const items = oc(item).selectItems([])
	let defaultSelected
	if (Array.isArray(props.value)) {
		defaultSelected = props.value
	} else {
		defaultSelected = props.value ? [props.value] : []
	}
	return (
		<TypeaheadComponent
			id={item.property}
			key={`${new Date().getTime()}`} // Enable re-int.
			className={``}
			disabled={item.disabled}
			options={items}
			placeholder={item.placeholder}
			defaultSelected={defaultSelected}
			clearButton={true}
			onChange={(selected: InputSelectionItem[]) => {
				const selectedValue = (isMulti) ? selected : oc(selected)[0](null)
				onChange(props, selectedValue)
			}}
			multiple={isMulti}
			dataIsGrouped={isGrouped}
			onBlur={() => onBlur(props)}
		/>
	)
}

const onChange = (props: Props, data: InputSelectionItem | InputSelectionItem[]) => {
	// This will call 'setFieldValue'
	props.onChange(props.item.property, data)
}

const onBlur = (props: Props) => {
	// This will call 'setFieldTouched'
	props.onBlur(props.item.property, true)
}

/**
 * A helper function that takes in a 'value', and finds the appropriate
 * selectItem in the given 'input'
 */
export const GetTypeaheadSelectValueForForm = (input: FormInput, value: any) => {
	switch (input.type) {
		case FormInputType.TYPEAHEAD:
			return getValueForSingleTypeahead(input, value)
		case FormInputType.GROUPED_TYPEAHEAD:
			return getValueForGroupedSingleTypeahead(input, value)
		case FormInputType.MULTI_SELECT_TYPEAHEAD:
			return getValueForMultiSelectTypeahead(input, value)
		case FormInputType.GROUPED_MULTI_SELECT_TYPEAHEAD:
			return getValueForGroupedMultiSelectTypeahead(input, value)
		default:
			throw Error(`Invalid input type: ${input.type}`)
	}
}

/**
 * Given a value, finds the selectItem that has a matching value
 */
const getValueForSingleTypeahead = (input: FormInput, value: any) => {
	const selectItems = input.selectItems as InputSelectionItem[]
	return findTypeaheadItem(selectItems, value)
}

/**
 * Given a value, finds the selectItem that has a matching value
 */
const getValueForGroupedSingleTypeahead = (input: FormInput, value: any) => {
	const selectItems = input.selectItems as InputSelectionItem[]
	return findTypeaheadItem(selectItems, value)
}

/**
 * Given an array of values, finds all the selectItems with matching values
 */
const getValueForMultiSelectTypeahead = (input: FormInput, value: any[]) => {
	const valuesForSelect = oc(value)([]).map((valueItem: any) => {
		const selectItems = input.selectItems as InputSelectionItem[]
		return findTypeaheadItem(selectItems, valueItem)
	})
	return valuesForSelect
}

/**
 * Given an array of values, finds all the selectItems with matching values
 */
const getValueForGroupedMultiSelectTypeahead = (input: FormInput, value: any[]) => {
	const valuesForSelect = oc(value)([]).map((valueItem: any) => {
		const selectItems = input.selectItems as InputSelectionItem[]
		return findTypeaheadItem(selectItems, valueItem)
	})
	return valuesForSelect
}

/**
 * A common function used by the 'getValueFor...' functions
 * @param selectOptions A list of selection options to look for the target in
 * @param target The element that we are looking for in the selectOptions
 */
const findTypeaheadItem = (selectOptions: InputSelectionItem[], target: any): InputSelectionItem | undefined => {
	return selectOptions.find((item: InputSelectionItem | ReactSelectItem) => {
		// Check if the target is an InputSelectionItem, and match the 'value' fields
		const targetValue = oc(target as InputSelectionItem).value()
		if (!isNullOrUndefined(targetValue)) {
			return item.value === targetValue
		}
		return item.value === target
	})
}
