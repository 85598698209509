// External Dependencies
import * as Feather from 'react-feather'

// Internal Dependencies
import { TableColumn } from '../Shared/Table'
import { TableColumnType } from '../Shared/Table/Primitives'

export const RestaurantTableColumns: TableColumn[] = [
	{
		title: '',
		property: '',
		type: TableColumnType.CHECKBOX,
		action: '',
	},
	{
		title: 'Name',
		property: 'name',
		type: TableColumnType.TEXT,
		action: '',
	},
	{
		title: 'Menus',
		property: 'menus',
		type: TableColumnType.ARRAY_LENGTH,
		action: '',
	},
	{
		title: 'Items',
		property: 'menuItems',
		type: TableColumnType.ARRAY_LENGTH,
		action: '',
	},
	{
		title: '',
		property: '',
		type: TableColumnType.DROPDOWN,
		class: 'table-dropdown-menu',
		dropdownItems: [
			{
				action: 'viewMenus',
				valueProperty: '_id',
				displayText: 'View Menus',
				icon: Feather.Eye,
			},
			{
				action: 'editRestaurant',
				valueProperty: '_id',
				displayText: 'Edit Restaurant',
				icon: Feather.Edit,
			},
			{
				action: 'deleteRestaurant',
				valueProperty: '_id',
				displayText: 'Delete Restaurant',
				icon: Feather.Trash,
			},
		]
	}
]
