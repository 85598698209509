// External Dependencies
import * as React from 'react'
import { Omit } from 'react-router'
import { flatten } from 'underscore'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'

// Internal Dependencies
import { BaseInputPrimitiveProps } from '../index'
import { InputSelectionItem, FormInputType, FormInput, ReactSelectItem } from '../../../Form'
import SelectComponent from '../../../Select'

interface ComponentProps extends Omit<BaseInputPrimitiveProps, 'onChange' | 'onSelect'> {
	onChange: (property: string, data: InputSelectionItem | InputSelectionItem[]) => void
	onSelect?: (property: string, data: InputSelectionItem | InputSelectionItem[]) => void
	onBlur: (property: string, didBlur: boolean) => void
}

type Props = ComponentProps

export const SelectInput: React.FunctionComponent<Props> = (props: Props) => {
	const isMulti =
		props.item.type === FormInputType.MULTI_SELECT ||
		props.item.type === FormInputType.GROUPED_MULTI_SELECT
	return (
		<SelectComponent
			property={props.item.property}
			featherIcon={props.item.icon}
			className={`react-select`}
			options={props.item.selectItems}
			placeholder={props.item.placeholder}
			isMulti={isMulti}
			onChange={(data) => onChange(props, data)}
			onSelect={(data) => onSelect(props, data)}
			onBlur={() => onBlur(props)}
			isDisabled={props.item.disabled}
			value={props.value}
		/>
	)
}

const onChange = (props: Props, data: InputSelectionItem | InputSelectionItem[]) => {
	// This will call 'setFieldValue'
	props.onChange(props.item.property, data)
}

const onSelect = (props: Props, data: InputSelectionItem | InputSelectionItem[]): any => {
	if (!props.onSelect) { return null }
	props.onSelect(props.item.property, data)
}

const onBlur = (props: Props) => {
	// This will call 'setFieldTouched'
	props.onBlur(props.item.property, true)
}

/**
 * A helper function that takes in a 'value', and finds the appropriate
 * selectItem in the given 'input'
 */
export const GetSelectValueForForm = (input: FormInput, value: any) => {
	switch (input.type) {
		case FormInputType.SELECT:
			return getValueForSingleSelect(input, value)
		case FormInputType.GROUPED_SELECT:
			return getValueForGroupedSingleSelect(input, value)
		case FormInputType.MULTI_SELECT:
			return getValueForMultiSelect(input, value)
		case FormInputType.GROUPED_MULTI_SELECT:
			return getValueForGroupedMultiSelect(input, value)
		default:
			throw Error(`Invalid input type: ${input.type}`)
	}
}

/**
 * Given a value, finds the selectItem that has a matching value
 */
const getValueForSingleSelect = (input: FormInput, value: any) => {
	const selectItems = input.selectItems as InputSelectionItem[]
	return findSelectItem(selectItems, value)
}

/**
 * Given a value, finds the selectItem that has a matching value
 */
const getValueForGroupedSingleSelect = (input: FormInput, value: any) => {
	let valueForSelect
	for (const groupedItems of input.selectItems as ReactSelectItem[]) {
		const foundItem = findSelectItem(groupedItems.options, value)
		if (!isNullOrUndefined(foundItem)) {
			valueForSelect = foundItem
			break
		}
	}
	return valueForSelect
}

/**
 * Given an array of values, finds all the selectItems with matching values
 */
const getValueForMultiSelect = (input: FormInput, value: any[]) => {
	const valuesForSelect = oc(value)([]).map((valueItem: any) => {
		const selectItems = input.selectItems as InputSelectionItem[]
		return findSelectItem(selectItems, valueItem)
	})
	return valuesForSelect
}

/**
 * Given an array of values, finds all the selectItems with matching values
 */
const getValueForGroupedMultiSelect = (input: FormInput, value: any[]) => {
	const valuesForSelect = oc(value)([]).map((valueItem: any) => {
		const selectItems = input.selectItems as ReactSelectItem[]
		const options = flatten(selectItems.map((item) => item.options))
		return findSelectItem(options, valueItem)
	})
	return valuesForSelect
}

/**
 * A common function used by the 'getValueFor...' functions
 * @param selectOptions A list of selection options to look for the target in
 * @param target The element that we are looking for in the selectOptions
 */
const findSelectItem = (selectOptions: InputSelectionItem[], target: any): InputSelectionItem | undefined => {
	return selectOptions.find((item: InputSelectionItem | ReactSelectItem) => {
		// Check if the target is an InputSelectionItem, and match the 'value' fields
		const targetValue = oc(target as InputSelectionItem).value()
		if (!isNullOrUndefined(targetValue)) {
			return item.value === targetValue
		}
		return item.value === target
	})
}
