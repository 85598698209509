// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { pick, values } from 'underscore'
import { oc } from 'ts-optchain'
import to from 'await-to-js'
import { isNullOrUndefined } from 'util'

// Internal Dependencies

// Components
import { UserTableColumns } from '../User/table'
import AvatarComponent from '../Shared/Avatar'

// Constants
import * as Constants from '../../constants'

// Helpers
import { setStateAsync } from '../../helpers/promise'
import { fullName } from '../../helpers/user'
import { clubResourceTypeBadge } from '../../helpers/badge'
import { buildFuseSearch, FuseItem } from '../../helpers/fuse'

// Actions and Action Interface Imports
import { UserActions, GroupActions, AlertActions } from '../../actions/index'

// Reducer Interface Imports
import { RootReducerState } from '../../reducers'
import { usersByIDSelector } from '../../reducers/user'

// -----------------------------------------
// Component Type Declarations
// -----------------------------------------
import PageHeaderComponent, { PageHeaderSearchInput, PageHeaderInputType } from '../Shared/PageHeader'
import { ButtonType, HeaderButton } from '../Shared/ButtonGroup'
import TableComponent from '../Shared/Table'
import ErrorComponent from '../Shared/ErrorComponent'
import ModalComponent from '../Shared/Modal'
import BackHeader from '../Shared/BackHeader'
import { FuseOptions } from 'fuse.js'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	error: false,
	loading: false,
	searchTerm: null as string | null,
	pageIndex: 0,
	userToDelete: null as string | null,
	userGroup: null as core.User.UserGroup | null,
}

type Props = RouteComponentProps & ConnectedState & ConnectedActions
type State = typeof initialState

/**
 * User Component
 */
class UserGroupComponent extends React.Component<Props, State> {
	private pageSize: number
	private fuseMemberSearch: Fuse<FuseItem, FuseOptions<FuseItem>>

	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
		this.pageSize = 20
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		const { loggedInClub, usersByID, location } = this.props

		// Parse the query string of the URL into an object
		const parsedQuery = queryString.parse(location.search)
		const groupID = parsedQuery.groupID
		// Get the User Group from the ID in the URL
		const userGroup = oc(loggedInClub).userGroups([]).find((ug) => `${ug._id}` === `${groupID}`)
		const groupUserIDs = [...userGroup.users] as any as string[]

		const userGroupUsers = pick(usersByID, groupUserIDs)
		const userGroupForState = {
			...userGroup,
			users: values(userGroupUsers)
		}

		this.buildFuseMemberSearch(values(userGroupUsers))

		await setStateAsync(this, { loading: false, userGroup: userGroupForState })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	handleSearchChange = async (term: string) => {
		await setStateAsync(this, { searchTerm: term, pageIndex: 0 })
	}

	handleSearchEnter = async (event: any) => {
		if (event.key === 'Enter') {
			await setStateAsync(this, { pageIndex: 0 })
		}
	}

	handleSearchClear = async () => {
		await setStateAsync(this, { searchTerm: '', pageIndex: 0 })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Dropdown Actions
	// ----------------------------------------------------------------------------------

	/**
	 * Determines which action to take, based on which dropdown item
	 * the user selected in the table's dropdown menu
	 */
	handleTableDropdownAction = (e: any) => {
		const [action, value] = e
		switch (action) {
			// User Table Actions
			case 'viewUser':
				return this.handleViewUser(value)
			case 'messageUser':
				return this.handleMessageUser(value)
			case 'editUser':
				return this.handleEditUser(value)
			case 'removeUser':
				return this.handleDeleteUser(value)
			default:
				break
		}
	}

	handleViewUser = (userID: string) => {

		// Query params
		const queryParams = {
			userID: userID
		}

		const location = {
			pathname: Constants.VIEW_USER_ROUTE,
			search: queryString.stringify(queryParams)
		}

		this.props.history.push(location)
	}

	/**
	 * When creating a Message for a User, navigate to the Message creation
	 * form and pass information about the User via query parameters
	 */
	handleMessageUser = (value: string) => {
		// Get the User by their ID
		const selectedUser = this.props.usersByID[value]

		// Query parameters
		const queryParams = {
			recipients: JSON.stringify([{
				label: fullName(selectedUser),
				value: selectedUser._id,
				type: 'member'
			}]),
			deliveryType: ['TEXT', 'PUSH', 'EMAIL']
		}

		const location = {
			pathname: Constants.CREATE_MESSAGE_ROUTE,
			search: queryString.stringify(queryParams)
		}

		// Go to the route
		this.props.history.push(location)
	}

	/**
	 * When editing a User, navigate to the User update
	 * form and pass the User's ID via the query parameters
	 */
	handleEditUser = (userID: string) => {

		// Query params
		const queryParams = {
			userID: userID
		}

		const location = {
			pathname: Constants.UPDATE_USER_ROUTE,
			search: queryString.stringify(queryParams)
		}

		this.props.history.push(location)
	}

	/**
	 * When attempting to delete a User, display the
	 * User deletion confirmation modal
	 */
	handleDeleteUser = async (value: string) => {
		await setStateAsync(this, { userToDelete: value })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Modal Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Removes the selected user from the current user group.
	 * The user is filtered out of the group's users, then the group is updated.
	 */
	removeUserFromGroup = async () => {
		const club = this.props.loggedInClub
		const userID = this.state.userToDelete
		const users = oc(this).state.userGroup.users([]) as core.User.Model[]
		const filteredUsers = users.filter((user: core.User.Model) => `${user._id}` !== `${userID}`)

		// Copy the user group.
		const userGroup = {
			...this.state.userGroup,
			users: filteredUsers
		}

		// Update the group.
		const [err] = await to(this.props.updateUserGroup(`${club._id}`, `${userGroup._id}`, userGroup) as any)
		if (err) {
			this.props.fireFlashMessage(`Problem updating Member Group. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true, userToDelete: null })
			return
		}

		// Reset component state with the updated group.
		const updatedGroup = this.props.loggedInClub.userGroups.find((ug) => ug._id === this.state.userGroup._id)
		const groupUserIDs = [...updatedGroup.users] as any as string[]

		const userGroupUsers: core.User.Model[] = values(pick(this.props.usersByID, groupUserIDs))
		const userGroupForState = {
			...userGroup,
			users: [...userGroupUsers]
		}

		this.buildFuseMemberSearch(userGroupUsers)

		await setStateAsync(this, { userGroup: userGroupForState, loading: false, userToDelete: null })
	}

	/**
	 * Hide the deletion modal when the user cancels it
	 */
	closeDeleteUserModal = async () => {
		await setStateAsync(this, { userToDelete: null })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Handle table pagination
	 */
	handlePage = async (data: any) => {
		const { pageIndex } = this.state

		// Check if the new page is different that the current one
		if (data.selected !== pageIndex) {
			await setStateAsync(this, { pageIndex: data.selected })
		}
	}

	/**
	 * Handle a column header of the table being selected
	 */
	handleTableHeaderAction = (e: any) => {
		// TODO: Add Functionality
	}

	/**
	 * Handle a table row being clicked
	 */
	handleTableRowClicked = (item: core.User.Model) => {
		return this.handleViewUser(`${item._id}`)
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Header Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Determine which action to take, based on the header
	 * button that the user selected
	 */
	handleHeaderButtonAction = async (e: any) => {
		const action = e.target.value
		switch (action) {
			case 'editUserGroup':
				this.handleEditUserGroup()
				break
			default:
				break
		}
	}

	/**
	 * When editing a UserGroup, navigate to the UserGroup update
	 * form and pass the UserGroup's ID via the query parameters
	 */
	handleEditUserGroup = () => {

		// Query params
		const queryParams = {
			groupID: this.state.userGroup._id
		}

		const location = {
			pathname: Constants.UPDATE_USER_GROUP_ROUTE,
			search: queryString.stringify(queryParams)
		}

		this.props.history.push(location)
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildHeaderButtons = (): HeaderButton[] => {
		return [
			{
				action: 'editUserGroup',
				type: ButtonType.DEFAULT,
				text: 'Edit Group',
				class: 'btn-primary',
			},
		]
	}

	buildPageHeader = () => {

		const searchInputs: PageHeaderSearchInput[] = [{
			inputType: PageHeaderInputType.Search,
			changeHandler: this.handleSearchChange,
			enterHandler: this.handleSearchEnter,
			clearHandler: this.handleSearchClear
		}]

		return (
			<div className='page-header-with-back-btn'>
				<BackHeader
					to={Constants.USER_GROUP_ROUTE}
					backTitle={'User Groups'}
				/>
				<PageHeaderComponent
					pageTitle={this.state.userGroup.name}
					buttons={this.buildHeaderButtons()}
					buttonHandler={this.handleHeaderButtonAction}
					inputs={searchInputs}
				/>
			</div>
		)
	}

	buildDeleteConfirmationModal = () => {
		if (!this.state.userToDelete) { return null }

		const title = `Remove User`
		const primaryMessage = `Are you sure you want to remove this User from the group?`
		const closeHandler = this.closeDeleteUserModal
		const submitHandler = this.removeUserFromGroup

		return (
			<ModalComponent
				modalTitle={title}
				primaryMessage={primaryMessage}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={closeHandler}
				submitButtonName={'Confirm'}
				submitButtonHandler={submitHandler}
			/>
		)
	}

	buildUserTable = () => {
		const { loggedInClub } = this.props
		const { searchTerm, pageIndex, loading } = this.state

		const userSearchResults = oc(this.searchMembers(searchTerm))([])

		// Items for the Table
		const pageStart = pageIndex * this.pageSize
		const pageEnd = pageStart + this.pageSize
		const total = userSearchResults.length
		const users = userSearchResults.slice(pageStart, pageEnd).map((user: core.User.Model) => {
			return {
				...user,
				membershipTypeBadge: clubResourceTypeBadge(loggedInClub, 'members', user.membershipType as any),
				canCancelRSVP: false,
				avatar: <AvatarComponent user={user} />
			}
		})

		return (
			<TableComponent<core.User.Model>
				columns={UserTableColumns}
				rowItems={users}
				showPaging={true}
				currentPage={pageIndex}
				pageHandler={this.handlePage}
				totalResults={total}
				pageSize={this.pageSize}
				actionHandler={this.handleTableHeaderAction}
				dropdownHandler={this.handleTableDropdownAction}
				onTableRowClick={this.handleTableRowClicked}
				isLoading={loading}
			/>
		)
	}

	render() {
		const { userGroup, error } = this.state

		const { loggedInClub } = this.props
		if (error) { return <ErrorComponent club={loggedInClub} isAdmin={this.props.userState.isAdmin} /> }
		if (!userGroup) { return null }

		return (
			<div className='userGroupDetailComponent user-container'>
				{this.buildPageHeader()}

				{this.buildUserTable()}

				{this.buildDeleteConfirmationModal()}
			</div>
		)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	/**
	 * The Fuse search is built using an array of Fuse user objects.
	 * These objects contain a search string (full name and email).
	 * Fuse attempts to match the input search term to this search string.
	 * IDs of the matched Fuse users will be used to locate the users
	 * in component state.
	 * @param term Search term from user input.
	 * @return The Users that were found.
	 */
	searchMembers(term: string): core.User.Model[] {
		const { usersByID } = this.props
		const { userGroup } = this.state

		if (!this.fuseMemberSearch) { return }

		const users = userGroup.users as core.User.Model[]
		if (oc(term)('') === '') {
			return users
		}

		const fuseMatches: FuseItem[] = this.fuseMemberSearch.search(term) as FuseItem[]
		const searchResultUserIDs = fuseMatches.map((m) => m._id)

		const searchResultUsersByID = pick(usersByID, searchResultUserIDs)
		const searchResultUsers = values(searchResultUsersByID)
		return searchResultUsers
	}

	/**
	 * Sets up the Fuse member search. The users passed into this method
	 * will be filtered as the search term changes.
	 * Fuzzy search options: https://fusejs.io/
	 * @param users core.User.Model[]
	 */
	buildFuseMemberSearch = (users: core.User.Model[]): void => {
		// Build our fuse user objects.
		const usersForFuse: FuseItem[] = this.buildFuseUsers(users)
		this.fuseMemberSearch = buildFuseSearch(usersForFuse)
	}

	/**
	 * Returns an array of Fuse user objects.
	 * These objects contain the user's ID and a search string
	 * made up of the user's full name and email.
	 * @param allUsers Users on component state.
	 * @returns FuseUser[].
	 */
	buildFuseUsers = (allUsers: core.User.Model[]): FuseItem[] => {
		return allUsers.map((u: core.User.Model) => {
			if (isNullOrUndefined(u)) { return { _id: '', searchString: '' } }
			return {
				_id: `${u._id}`,
				searchString: `${fullName(u)} ${u.email}`
			}
		})
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	loggedInClub: state.club.loggedInClub,
	usersByID: usersByIDSelector(state),
	userState: state.user
})

const mapDispatchToProps = {
	...UserActions,
	...AlertActions,
	...GroupActions,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserGroupComponent)
