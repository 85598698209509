// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { FormikProps } from 'formik'

// Internal Dependencies

// Actions
import { CalendarActions, AlertActions } from '../../../actions/index'

// Form
import { DateRangeFormInputs, DateRangeFormState } from './form'

// Components
import ModalComponent from '../../Shared/Modal'
import { FormikComponent, BuildWrappedForm } from '../../Shared/Formik'

export interface DateRangeForm {
	_id?: string,
	name: string,
	start: Date,
	end: Date,
}

type ConnectedActions = typeof mapDispatchToProps

interface ComponentProps {
	selectedCalendar: core.Calendar.Model
	createHandler: (formState: DateRangeForm) => Promise<void>
	updateHandler: (formState: DateRangeForm) => Promise<void>
	closeHandler: () => void
	existingRangeConfig?: DateRangeForm
}

type Props = ComponentProps & ConnectedActions
type State = any

class DateRangeFormComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	handleSave = async (formState: DateRangeFormState) => {
		return (this.props.existingRangeConfig) ?
			this.handleUpdate(formState) :
			this.handleCreate(formState)
	}

	handleUpdate = async (formState: DateRangeFormState) => {
		await this.props.updateHandler(formState)
		this.props.closeHandler()
	}

	handleCreate = async (formState: DateRangeFormState) => {
		await this.props.createHandler(formState)
		this.props.closeHandler()
	}

	handleClose = async () => {
		this.props.closeHandler()
	}

	// ----------------------------------------------------------------------------------
	// Component Builders
	// ----------------------------------------------------------------------------------

	buildModal = (formikProps: FormikProps<any>) => {
		const formInputs = DateRangeFormInputs
		return (
			<ModalComponent
				modalTitle={'Settings Date Range'}
				className={'dateRangeForm-modal'}
				submitButtonName={'Save'}
				submitButtonHandler={formikProps.submitForm}
				buildInputs={() => BuildWrappedForm({inputs: formInputs}, formikProps)}
				cancelButtonHandler={this.handleClose}
			/>
		)
	}

	render() {
		return (
			<FormikComponent
				inputs={DateRangeFormInputs}
				enableReinitialize={false}
				formResource={this.props.existingRangeConfig}
				onSubmit={this.handleSave}
				render={this.buildModal}
			/>
		)
	}
}

const mapDispatchToProps = {
	...AlertActions,
	...CalendarActions
}

const enhance = compose<React.ComponentType<ComponentProps>>(
	connect(undefined, mapDispatchToProps)
)

export default enhance(DateRangeFormComponent)
