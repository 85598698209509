// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import { Icon } from 'react-feather'

import { BaseInputPrimitiveProps } from '../index'
type Props = BaseInputPrimitiveProps

export const CheckBox: React.FunctionComponent<Props> = (props: Props) => {
	return (
		<RS.Input
			key={`button-${props.item.property}`}
			type={'checkbox'}
			name={props.item.property}
			checked={props.value}
			readOnly={true}
		/>
	)
}
