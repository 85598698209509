// External Dependencies
import * as core from 'club-hub-core'
import { flatten, Dictionary, pick, values } from 'underscore'
import { isNullOrUndefined } from 'util'
import { usersByIDSelector } from '../reducers/user'
import { oc } from 'ts-optchain'

// Internal Dependencies

export const usersForEvent = (event: core.Event.Model, members: ReturnType<typeof usersByIDSelector>) => {
	const participants = event.reservations.map((reservation) => {
		return usersForReservation(reservation, members)
	})

	return flatten(participants, true)
}

export const usersForReservation = (reservation: core.Event.Reservation, members: ReturnType<typeof usersByIDSelector>) => {
	return reservation.participants.map((participant) => {
		return members[`${participant.userID}`]
	}).filter((member) => !isNullOrUndefined(member))
}

export const calendarIDForEvent = (event: core.Event.Model, calendar?: core.Calendar.Model) => {
	if (calendar) {
		const calIDs = oc(event).calendarIDs([])
		const foundID = calIDs.find((calID: any) => `${calID}` === `${calendar._id}`)
		if (foundID) {
			return foundID
		}
	}
	return oc(event).calendarIDs[0]({} as any)
}

/**
 * Gets the group for the events primary (first) calendar.
 */
export const calendarGroupForEvent = (event: core.Event.Model, calendars: Dictionary<core.Calendar.Model>, groups: Dictionary<core.Calendar.Group>) => {
	const calendarID = oc(event).calendarIDs[0](event.calendarID)
	const calendar = calendars[`${calendarID}`]
	return groups[`${calendar.groupID}`]
}

/**
 * Checks if an event belongs to a group via its calendars.
 */
export const eventBelongsToGroup = (event: core.Event.Model, calendarsByID: Dictionary<core.Calendar.Model>, groupID: any) => {
	const calendarIDs = oc(event).calendarIDs([]) as any
	const eventCalMap = pick(calendarsByID, calendarIDs)
	const eventCals = values(eventCalMap)
	const cal = eventCals.find((c: core.Calendar.Model) => `${groupID}` === `${c.groupID}`)
	return cal ? true : false
}
