// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as queryString from 'query-string'
import * as Feather from 'react-feather'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter, RouteComponentProps } from 'react-router'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies

// Actions
import { EventActions, AlertActions, UserActions } from '../../../actions/index'

// State
import { RootReducerState } from '../../../reducers'
import { inCustomerViewSelector } from '../../../reducers/user'

// Components
import { ReservationType } from '../ReservationList'
import Card from '../Cards/Card'
import ModalComponent from '../Modal'
import BackHeader from '../BackHeader'
import DimmedLoader from '../DimmedLoader'
import ErrorComponent from '../ErrorComponent'
import DetailRow, { DetailRowItem } from './DetailRow'
import DescriptionComponent from './DescriptionSection'
import UnderlineHeaderComponent from './UnderlineHeader'

// Helpers
import { setStateAsync } from '../../../helpers/promise'
import * as eventHelper from '../../../helpers/event'
import * as Constants from '../../../constants'
import { calendarsByIDSelector, calendarGroupsByIDSelector } from '../../../reducers/calendar'

enum FormAction {
	view = 'view',
	new = 'new',
	edit = 'edit'
}

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

interface ComponentProps {
	reservationType: ReservationType
	event: core.Event.Model
	match: any
}

const initialState = {
	loading: true,
	error: false,
	reservationType: null as ReservationType | null,
	event: null as core.Event.Model | null,
	reservation: null as core.Event.Reservation | null,
	reservationCreator: null as core.User.Model | null,
	eventToCancel: null as string | null,
	reservationToCancel: null as string | null,
}

type Props = ComponentProps & ConnectedActions & ConnectedState & RouteComponentProps
type State = typeof initialState

class ReservationDetailsComponent extends React.Component<Props, State> {
	private formAction: FormAction
	private goBackRoute: string

	// UI values based on reservation type.
	private pageTitle: string | JSX.Element
	private redirectRoute: string
	private cancelModalTitle: string
	private goBackTitle: string

	constructor(props: Props) {
		super(props)
		this.formAction = this.props.match.params.action
		this.state = { ...initialState, reservationType: props.reservationType, event: props.event }
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		const initializeComponentState = (!this.state.event) ?
			this.setEventWithQueryParameters() :
			this.props.getUser(`${this.props.event.creator}`)

		const [err] = await to(initializeComponentState as any)
		if (err) {
			this.props.fireFlashMessage(`Problem fetching your Reservation. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}

		this.setUIValuesBasedOnResType()
		await setStateAsync(this, { loading: false })
	}

	/**
	 * Sets the Event that is being displayed, given the Event ID and Reservation ID in the query string.
	 */
	setEventWithQueryParameters = async () => {
		await setStateAsync(this, { loading: true })

		// Set state values using the route query params.
		const parsedQuery = queryString.parse(this.props.location.search)
		const reservationID = parsedQuery.reservationID
		const eventID: string = parsedQuery.eventID
		let reservationType = (this.props.match.params as any).type

		// Fetch the event by it's ID. This will store the event at props.eventState.currentEvent.
		const [fetchErr] = await to(this.props.fetchEvent(eventID) as any)
		if (fetchErr) {
			throw fetchErr
		}

		const event: core.Event.Model = oc(this.props).eventState.currentEvent()
		if (!event) {
			throw new Error('Failed to find Event')
		}
		const reservation: core.Event.Reservation = event.reservations.find((r) => `${r._id}` === reservationID)
		if (!reservation) {
			throw new Error('Failed to find Reservation')
		}

		/**
		 * We need to remove the reservationType enum from the 'reservation list' component.
		 * We should instead use the calendar group to determine a reservation's type.
		 * This is a work around to account for the mismatch for the type in the URL vs the reservation type enum.
		 */
		reservationType = (reservationType === core.Calendar.GroupType.Service.toLocaleLowerCase()) ? ReservationType.SERVICE : reservationType

		/**
		 * Get the Owner of the Reservation. This will store the user at props.userState.currentUser.
		 */
		const reservationOwnerID = `${reservation.owner}`
		const [fetchOwnerErr] = await to(this.props.getUser(reservationOwnerID) as any)
		if (fetchOwnerErr) {
			throw fetchOwnerErr
		}

		// Update the state
		await setStateAsync(this, { event: event, reservation: reservation, reservationType: reservationType, loading: false })
	}

	/**
	 * Sets UI values on the class. These values are used to build headers, modal, redirect routes etc...
	 * These values are all different depending on the reservation type.
	 */
	setUIValuesBasedOnResType = () => {
		switch (this.state.reservationType) {
			case ReservationType.DINING:
				this.pageTitle = 'Reservation Details'
				this.cancelModalTitle = 'Dining reservation'
				this.goBackTitle = (this.props.isCustomerView) ? 'My Reservations' : 'Dining'
				this.redirectRoute = (this.props.isCustomerView) ?
					Constants.MY_RESERVATIONS_ROUTE.replace(':type', core.Calendar.GroupType.Dining.toLocaleLowerCase()) :
					Constants.DINING_RESERVATIONS_ROUTE
				break
			case ReservationType.GOLF:
				this.pageTitle = 'Tee Time Details'
				this.cancelModalTitle = 'Tee Time'
				this.goBackTitle = (this.props.isCustomerView) ? 'My Reservations' : 'Tee Times'
				this.redirectRoute = (this.props.isCustomerView) ?
					Constants.MY_RESERVATIONS_ROUTE.replace(':type', core.Calendar.GroupType.Golf.toLocaleLowerCase()) :
					Constants.GOLF_RESERVATION_ROUTE
				break
			case ReservationType.SERVICE:
				this.pageTitle = this.buildVehicleServiceHeader()
				this.cancelModalTitle = 'Service'
				this.goBackTitle = (this.props.isCustomerView) ? 'My Reservations' : 'Services'
				this.redirectRoute = (this.props.isCustomerView) ?
					Constants.MY_RESERVATIONS_ROUTE.replace(':type', core.Calendar.GroupType.Service.toLocaleLowerCase()) :
					Constants.SCHEDULED_SERVICES_ROUTE
				break
			default:
				this.pageTitle = 'Reservation Details'
				break
		}
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * When attempting to cancel an Event, display the
	 * Event cancellation confirmation modal
	 */
	handleCancelEvent = async () => {
		const event = this.state.event
		const reservation = this.state.reservation

		const actualEventID = `${event._id}`
		const reservationID = `${reservation._id}`
		return setStateAsync(this, { eventToCancel: actualEventID, reservationToCancel: reservationID })
	}

	/**
	 * Make the API call to cancel the Event, when the
	 * user confirms the cancellation via the modal
	 */
	executeEventDeletion = async () => {
		const reservationID = this.state.reservationToCancel
		const eventID = this.state.eventToCancel
		await setStateAsync(this, { loading: true })
		const [deleteErr] = await to(this.props.cancelEventRsvp(reservationID, eventID) as any)
		if (deleteErr) {
			this.props.fireFlashMessage(`Problem trying to cancel Reservation. ${deleteErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true, eventToCancel: null, reservationToCancel: null })
			return
		}

		this.props.fireFlashMessage('Successfully cancelled Reservation', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, eventToCancel: null, reservationToCancel: null })
		this.props.history.push(this.redirectRoute)
	}

	/**
	 * Hide the cancellation modal when the user closes it
	 */
	closeCancelEventModal = async () => {
		return setStateAsync(this, { eventToCancel: null, reservationToCancel: null })
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	backHeaderEditHandler = () => {
		const pathname = Constants.GOLF_RESERVATION.replace(':action', 'update')
		const queryParams = { time: this.state.event.start, eventID: `${this.state.event._id}` }
		const search = queryString.stringify(queryParams)
		const location = { search, pathname }
		this.props.history.push(location)
	}

	buildBackHeader = () => {
		const eventInPast = new Date(this.state.event.start).getTime() < new Date().getTime()
		const editableReservationType = this.state.reservationType === ReservationType.GOLF
		const isReservationOwner = `${this.props.userState.loggedInUser._id}` === `${this.state.reservation.owner}`
		const editHandler = (!eventInPast && editableReservationType && isReservationOwner) ?
			this.backHeaderEditHandler :
			null

		return (
			<BackHeader
				backTitle={this.goBackTitle}
				to={this.redirectRoute}
				editHandler={editHandler}
			/>
		)
	}

	buildReservationDetails = () => {
		const user = this.props.userState.currentUser
		if (!user) {
			this.props.fireFlashMessage('Sorry we had a problem finding your service request.', Constants.FlashType.DANGER)
			setStateAsync(this, { error: true })
			return
		}

		const eventInPast = new Date(this.state.event.start).getTime() < new Date().getTime()
		const canCancelReservation = !this.props.isCustomerView || `${this.props.userState.loggedInUser._id}` === `${this.state.reservation.owner}`

		let content
		switch (this.state.reservationType) {
			case ReservationType.DINING:
				content = this.buildDiningContent()
				break
			case ReservationType.GOLF:
				content = this.buildTeeTimeContent()
				break
			case ReservationType.SERVICE:
				content = this.buildVehicleServiceContent()
				break
			default:
				content = null
				break
		}

		const cancelButton = (
			<button className='btn btn-block cancel-reservation-btn' onClick={this.handleCancelEvent}>
				Cancel
			</button>
		)

		const contentWithCancellation = (
			<>
				{content}
				{(eventInPast || !canCancelReservation) ? null : cancelButton}
			</>
		)

		return (
			<Card
				title={this.pageTitle}
				headerClass={'d-flex'}
				bodyClass={'card-body'}
				content={contentWithCancellation}
			/>
		)
	}

	buildVehicleServiceHeader = () => {
		const titleString = 'Service Details'
		return (
			<div className='d-flex justify-content-between align-items-center w-100'>
				<div>{titleString}</div>
			</div>
		)
	}

	buildVehicleServiceContent = () => {
		// Get the Event information
		const event = this.state.event
		const reservation = this.state.reservation
		const eventStart = new Date(event.start).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })

		// Get the Vehicle information
		const user = this.props.userState.currentUser
		const userVehicles = oc(user).meta.car.vehicles([])
		const meta = oc(reservation).meta() as core.Event.CarReservationMeta
		const vehicleID = oc(meta).vehicleID()
		const vehicleInfo = userVehicles.find((vehicle) => `${vehicle._id}` === `${vehicleID}`)
		const vehicleName = `${vehicleInfo.year} ${vehicleInfo.make} ${vehicleInfo.model}`
		const vehicleStallNumbers = oc(user).meta.car.stallNumbers('-')
		const keySpots = oc(vehicleInfo).keySpots('-')

		// Get the Service Provider information
		const calendars = this.props.calendarState.calendars || []
		const calendarID = eventHelper.calendarIDForEvent(event)
		const serviceProvider = this.props.calendarsByID[`${calendarID}`]
		const serviceProviderName = oc(serviceProvider).name('')
		const serviceProviderWebsite = oc(serviceProvider).location.website('')

		// Hyper link the provider name if a website is included in the provider's location object.
		const providerName = (serviceProviderWebsite) ? <a href={serviceProviderWebsite}>{serviceProviderName}</a> : serviceProviderName

		const details: DetailRowItem[] = [
			{ icon: Feather.Calendar, label: 'Date', value: eventStart },
			{ icon: Feather.Bell, label: 'Provider', value: providerName },
			{ icon: Feather.Truck, label: 'Vehicle', value: vehicleName },
			{ icon: Feather.Grid, label: 'Stall Numbers', value: vehicleStallNumbers },
			{ icon: Feather.Key, label: 'Key Spot', value: keySpots },
		]

		// Get the Reservation Notes
		const notes = oc(meta).notes()

		return (
			<>
				{this.buildHeaderSection('Service', this.props.loggedInClub)}
				{this.buildDetailSection('DETAILS', details)}
				<div className='reservation-details-section'>
					<UnderlineHeaderComponent title={'NOTES'} />
					<DescriptionComponent description={(notes && notes.length) ? notes : 'No service notes.'} />
				</div>
			</>
		)
	}

	buildTeeTimeContent = () => {
		const start = new Date(this.state.event.start)
		const startDate = start.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })
		const startTime = start.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' })

		const details = [
			{ icon: Feather.Calendar, label: 'Date', value: startDate },
			{ icon: Feather.Clock, label: 'Time', value: startTime },
		]

		const reservation = this.state.reservation
		const filteredParticipants = reservation.participants.filter((participant: core.Event.Participant) => {
			return participant.userID
		})

		const golfers = filteredParticipants.map((participant: core.Event.Participant) => {
			return { icon: Feather.User, label: `${participant.name}` }
		})

		const guestCount = reservation.participants.length - filteredParticipants.length
		if (guestCount) {
			const label = (guestCount > 1) ? 'Members' : 'Member'
			golfers.push({ icon: Feather.Users, label: `${guestCount} other ${label}` })
		}

		return (
			<>
				{this.buildHeaderSection('Tee Time', this.props.loggedInClub)}
				{this.buildDetailSection('DETAILS', details)}
				{this.buildDetailSection('GOLFERS', golfers)}
			</>
		)
	}

	buildDiningContent = () => {
		const start = new Date(this.state.event.start)
		const startDate = start.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })
		const startTime = start.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' })

		const details = [
			{ icon: Feather.Calendar, label: 'Date', value: startDate },
			{ icon: Feather.Clock, label: 'Time', value: startTime },
		]

		const reservation = this.state.reservation
		const filteredParticipants = reservation.participants.filter((participant: core.Event.Participant) => {
			return participant.userID
		})

		const diners = filteredParticipants.map((participant: core.Event.Participant) => {
			return { icon: Feather.User, label: `${participant.name}` }
		})

		const guestCount = reservation.participants.length - filteredParticipants.length
		if (guestCount) {
			const label = (guestCount > 1) ? 'Members' : 'Member'
			diners.push({ icon: Feather.Users, label: `${guestCount} other ${label}` })
		}

		return (
			<>
				{this.buildHeaderSection('Dining', this.props.loggedInClub)}
				{this.buildDetailSection('DETAILS', details)}
				{this.buildDetailSection('DINERS', diners)}
			</>
		)
	}

	// ----------------------------------------------------------------------------------
	// Child Component Builders
	// ----------------------------------------------------------------------------------

	buildHeaderSection = (title: string, club: core.Club.Model) => {
		const description = `Your confirmed ${title} reservation information is below. If you have any questions, please contact ${club.name} at ${club.locations[0].phone}`
		return <DescriptionComponent title={title} description={description} />
	}

	buildDetailSection = (title: string, detailRows: DetailRowItem[]) => {
		const detailRowItems = detailRows.map((rowItem: DetailRowItem, index: number) => {
			return <DetailRow rowItem={rowItem} key={`detail_row_${index}`} />
		})

		return (
			<div className='reservation-details-section'>
				<UnderlineHeaderComponent title={title} />
				{detailRowItems}
			</div>
		)
	}

	// ----------------------------------------------------------------------------------
	// General Content Builders
	// ----------------------------------------------------------------------------------

	buildCancelConfirmationModal = () => {
		if (!this.state.eventToCancel || !this.state.reservationToCancel) { return null }
		return (
			<ModalComponent
				modalTitle={`Cancel ${this.cancelModalTitle}`}
				primaryMessage={`Are you sure you want to cancel this ${this.cancelModalTitle}?`}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.closeCancelEventModal}
				submitButtonName={'Confirm'}
				submitButtonHandler={this.executeEventDeletion}
			/>
		)
	}

	render() {
		const { loggedInClub } = this.props
		if (this.state.error) { return <ErrorComponent club={loggedInClub} isAdmin={this.props.userState.isAdmin} /> }
		if (this.state.loading) { return <DimmedLoader component={null} isLoading={true} /> }

		return (
			<div className='reservation-details-container col col-sm-12 col-md-6 mx-auto pt-6'>
				{this.buildBackHeader()}
				{this.buildReservationDetails()}
				{this.buildCancelConfirmationModal()}
			</div>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	userState: state.user,
	calendarState: state.calendar,
	eventState: state.event,
	loggedInClub: state.club.loggedInClub,
	isCustomerView: inCustomerViewSelector(state),
	calendarsByID: calendarsByIDSelector(state),
	calendarGroupsByID: calendarGroupsByIDSelector(state)
})

const mapDispatchToProps = {
	...UserActions,
	...EventActions,
	...AlertActions
}

const enhance = compose<React.ComponentType<ComponentProps>>(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(ReservationDetailsComponent)
