// External Dependencies
import * as core from 'club-hub-core'
import { Action, ActionCreator, Dispatch } from 'redux'
import { ThunkAction } from 'redux-thunk'
import to from 'await-to-js'
import * as Sentry from '@sentry/browser'

// Internal Dependencies
import { Identify } from '../helpers/analytics'
import rootReducer, { RootReducerState } from '../reducers'
import { clearState, saveStateForKey, SESSION_TOKEN_KEY } from '../store/localStorage'

// Actions
import { AdminActions } from './admin'
import { CustomerActions } from './customer'
import { ClubActions } from './club'

// Types / Interfaces
import { AuthInterface, usersByIDSelector } from '../reducers/user'

// API Helpers
import { POST, GET, POST_FORM, PUT_FORM, DELETE, PUT } from '../helpers/api'

// Helpers
import { GeneralMap } from '../helpers/interface'

// Actions
export enum TypeKeys {
	REGISTERING_USER = 'REGISTERING_USER',
	REGISTERED_USER = 'REGISTERED_USER',
	AUTHENTICATING_USER = 'AUTHENTICATING_USER',
	AUTHENTICATED_USER = 'AUTHENTICATED_USER',
	SIGN_OUT = 'SIGN_OUT',
	REQUESTED_NEW_PASSWORD = 'REQUESTED_NEW_PASSWORD',
	RESET_PASSWORD = 'RESET_PASSWORD',
	CHANGED_PASSWORD = 'CHANGED_PASSWORD',
	FETCHED_USERS = 'FETCHED_USERS',
	FETCHED_USER = 'FETCHED_USER',
	FETCHED_USER_RELATIONS = 'FETCHED_USER_RELATIONS',
	CREATED_USER = 'CREATED_USER',
	UPDATED_USER = 'UPDATED_USER',
	DELETED_USER = 'DELETED_USER',
	CREATED_USER_META = 'CREATED_USER_META',
	UPDATED_USER_META = 'UPDATED_USER_META',
	DELETED_USER_META = 'DELETED_USER_META',
	SET_CURRENT_USER = 'SET_CURRENT_USER',
	CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER',
	FETCHED_USERS_PAGINATED = 'FETCHED_USERS_PAGINATED',
	FETCHED_ALL_USERS = 'FETCHED_ALL_USERS',
	TOGGLED_CUSTOMER_VIEW = 'TOGGLED_CUSTOMER_VIEW',
	FETCHED_USERS_FOR_MESSAGE = 'FETCHED_USERS_FOR_MESSAGE',
	DELETED_USERS = 'DELETED_USERS',
	UPDATED_USERS = 'UPDATED_USERS',
}

export type ActionTypes =
	| RegisteredUserAction
	| AuthenticatedUserAction
	| SignOutUserAction
	| RequestedNewPasswordAction
	| ResetPasswordAction
	| ChangedPasswordAction
	| FetchedUsersActionPaginated
	| FetchedAllUsersAction
	| FetchedUserAction
	| CreatedUserAction
	| UpdatedUserAction
	| UpdatedUsersAction
	| DeletedUserAction
	| DeletedUsersAction
	| CreatedUserMetaAction
	| UpdatedUserMetaAction
	| DeletedUserMetaAction
	| SetCurrentUserAction
	| ClearCurrentUserAction
	| FetchedUserRelationsAction
	| ToggledCustomerViewAction
	| FetchedUsersForMessageAction

/**
 * RegisteredUserAction.
 */
export interface RegisteredUserAction extends Action {
	type: TypeKeys.REGISTERED_USER,
	users: any,
	receivedAt: number
}

/**
 * AuthenticatedUserAction.
 */
export interface AuthenticatedUserAction extends Action {
	type: TypeKeys.AUTHENTICATED_USER,
	user: core.User.Model,
	club: core.Club.Model,
	receivedAt: number
}

/**
 * SignOutUserAction.
 */
export interface SignOutUserAction extends Action {
	type: TypeKeys.SIGN_OUT,
}

/**
 * RequestedNewPasswordAction.
 */
export interface RequestedNewPasswordAction extends Action {
	type: TypeKeys.REQUESTED_NEW_PASSWORD,
}

/**
 * ResetPasswordAction.
 */
export interface ResetPasswordAction extends Action {
	type: TypeKeys.RESET_PASSWORD,
}

/**
 * ChangedPasswordAction
 */
export interface ChangedPasswordAction extends Action {
	type: TypeKeys.CHANGED_PASSWORD,
}

/**
 * FetchedUsersActionPaginated.
 */
export interface FetchedUsersActionPaginated extends Action {
	type: TypeKeys.FETCHED_USERS_PAGINATED,
	users: core.User.Model[],
	count: number,
	receivedAt: number
}

/**
 * FetchedAllUsersAction.
 */
export interface FetchedAllUsersAction extends Action {
	type: TypeKeys.FETCHED_ALL_USERS,
	users: core.User.Model[],
	receivedAt: number
}

/**
 * FetchedUserAction.
 */
export interface FetchedUserAction extends Action {
	type: TypeKeys.FETCHED_USER,
	user: core.User.Model,
}

/**
 * FetchedCurrentUserRelationsAction.
 */
export interface FetchedUserRelationsAction extends Action {
	type: TypeKeys.FETCHED_USER_RELATIONS,
	relations: core.Relation.Model[],
}

/**
 * CreatedUserAction.
 */
export interface CreatedUserAction extends Action {
	type: TypeKeys.CREATED_USER,
	user: core.User.Model,
	receivedAt: number
}

/**
 * UpdatedUserAction.
 */
export interface UpdatedUserAction extends Action {
	type: TypeKeys.UPDATED_USER,
	user: core.User.Model,
}

/**
 * UpdatedUsersAction.
 */
export interface UpdatedUsersAction extends Action {
	type: TypeKeys.UPDATED_USERS,
	users: core.User.Model[]
}

/**
 * DeletedUserAction.
 */
export interface DeletedUserAction extends Action {
	type: TypeKeys.DELETED_USER,
	userID: string
}

/**
 * DeletedUsersAction
 */
export interface DeletedUsersAction extends Action {
	type: TypeKeys.DELETED_USERS,
	userIDs: string[]
}

/**
 * CreatedUserMetaAction.
 */
export interface CreatedUserMetaAction extends Action {
	type: TypeKeys.CREATED_USER_META,
	user: core.User.Model,
}

/**
 * UpdatedUserMetaAction.
 */
export interface UpdatedUserMetaAction extends Action {
	type: TypeKeys.UPDATED_USER_META,
	user: core.User.Model,
}

/**
 * DeletedUserMetaAction.
 */
export interface DeletedUserMetaAction extends Action {
	type: TypeKeys.DELETED_USER_META,
	user: core.User.Model
}

/**
 * SetCurrentUserAction.
 */
export interface SetCurrentUserAction extends Action {
	type: TypeKeys.SET_CURRENT_USER,
	user: core.User.Model
}

/**
 * ClearCurrentUserAction.
 */
export interface ClearCurrentUserAction extends Action {
	type: TypeKeys.CLEAR_CURRENT_USER,
}

/**
 * ToggledCustomerViewAction
 */
export interface ToggledCustomerViewAction extends Action {
	type: TypeKeys.TOGGLED_CUSTOMER_VIEW
}

/**
 * FetchedUsersForMessageAction
 */
export interface FetchedUsersForMessageAction extends Action {
	type: TypeKeys.FETCHED_USERS_FOR_MESSAGE
	userIDs: string[]
}

// -----------------------------------------------------------------------------
// Registration
// -----------------------------------------------------------------------------

/**
 * Registers a new user via a call to the `/register` API.
 * @param email The email supplied in the registration form.
 * @param password The password supplied in the registration form.
 */
const registerUser = (email: string, password: string) => (dispatch: Dispatch<any>) => {
	const userPayload = { email: email, password: password }
	return POST('/register', userPayload).then((response: Response) => {
		dispatch(registeredUser(response))
	}).catch((err) => Promise.reject(err))
}

/**
 * Builds a `RegisteredUserAction` upon successful registration.
 * @param response The response from the register API call.
 * @return The `RegisteredUserAction` instance.
 */
const registeredUser = (response: any): RegisteredUserAction => {
	const action: RegisteredUserAction = {
		receivedAt: Date.now(),
		type: TypeKeys.REGISTERED_USER,
		users: response,
	}
	return action
}

// -----------------------------------------------------------------------------
// Login
// -----------------------------------------------------------------------------

/**
 * Logs in an existing user via a call to the `/login` API.
 * @param email The email supplied in the login form.
 * @param password The password supplied in the login form.
 */
const authenticateUser = (email: string, password: string, club: string, clubDomain: string) => async (dispatch: Dispatch<any>) => {
	const userPayload = { email: email, password: password, club: club, domain: clubDomain }
	const [err, res] = await to<AuthInterface>(POST('/login', userPayload))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to login with error: ${err}`)
		throw err
	}

	// Save our session token to local storage. We need this to deal with Safari BS.
	saveStateForKey(res.token, SESSION_TOKEN_KEY)

	// Only after setting the token, dispatch a successful auth
	dispatch(authenticatedUser(res.user, res.club))

	// Configure the Sentry scope for the current user
	Sentry.configureScope((scope) => {
		scope.setUser({
			id: `${res.user._id}`,
			email: res.user.email,
			club: res.club.name,
			...res.user
		})
	})

	// Track the login
	Identify(res.user, res.club)

	// Fetch state for the User
	if (res.user.admin) {
		await dispatch(AdminActions.fetchAdminState())
	} else {
		await dispatch(CustomerActions.fetchCustomerState())
	}
}

/**
 * Builds an `AuthenticatedUserAction` upon successful login.
 * @param user The user returned after login.
 * @return The `RegisteredUserAction` instance.
 */
const authenticatedUser = (user: core.User.Model, club: core.Club.Model): AuthenticatedUserAction => {
	const action: AuthenticatedUserAction = {
		club: club,
		receivedAt: Date.now(),
		type: TypeKeys.AUTHENTICATED_USER,
		user: user,
	}
	return action
}

// -----------------------------------------------------------------------------
// Logout
// -----------------------------------------------------------------------------

const signOut = () => async (dispatch: Dispatch<SignOutUserAction>) => {
	const [err] = await to(PUT('/logout', undefined))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to logout the user with error: ${err}`)
		throw err
	}
	// Logout the current user for Sentry
	dispatch(requestedSignOut())

	// Refresh our club list
	dispatch(ClubActions.fetchClubs as any)
}

const requestedSignOut = () => {
	const action: SignOutUserAction = {
		type: TypeKeys.SIGN_OUT
	}
	return action
}

// -----------------------------------------------------------------------------
// Forgot Password
// -----------------------------------------------------------------------------

const requestNewPassword = (email: string, club: string) => async (dispatch: Dispatch<RequestedNewPasswordAction>) => {
	const payload = { email, club }
	const [err] = await to(POST('/users/forgotpassword', payload))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to request new password with error: ${err}`)
		throw err
	}

	dispatch(requestedNewPassword())
}

const requestedNewPassword = (): RequestedNewPasswordAction => {
	const action: RequestedNewPasswordAction = {
		type: TypeKeys.REQUESTED_NEW_PASSWORD
	}
	return action
}

// -----------------------------------------------------------------------------
// Reset Password
// -----------------------------------------------------------------------------

const resetPassword = (token: string, password: string) => async (dispatch: Dispatch<ResetPasswordAction>) => {
	const payload = { token, password }
	const [err] = await to(PUT('/users/forgotpassword', payload))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to reset password with error: ${err}`)
		throw err
	}

	dispatch(finishedResettingPassword())
}

const finishedResettingPassword = (): ResetPasswordAction => {
	const action: ResetPasswordAction = {
		type: TypeKeys.RESET_PASSWORD
	}
	return action
}

// -----------------------------------------------------------------------------
// Changed Password
// -----------------------------------------------------------------------------

const changePassword = (oldPassword: string, newPassword: string) => async (dispatch: Dispatch<ChangedPasswordAction>) => {
	const payload = { oldPassword, newPassword }
	const [err] = await to(POST('/users/resetpassword', payload))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to change password with error: ${err}`)
		throw err
	}

	dispatch(changedPassword())
}

const changedPassword = (): ChangedPasswordAction => {
	const action: ChangedPasswordAction = {
		type: TypeKeys.CHANGED_PASSWORD
	}
	return action
}

// -----------------------------------------------------------------------------
// Get Users
// -----------------------------------------------------------------------------

interface GetUsersOptions {
	clubID: string
	pageSize: number
	page: number
	search?: string
	filter?: string
	pendingFilter?: core.QueryFilter.Model
}

/**
 * Fetches all users from a specific club.
 */
const getUsers = (options: GetUsersOptions) => async (dispatch: Dispatch<any>) => {
	const limit = options.pageSize
	const offset = options.page * limit
	const queryParams: any = {
		club_id: options.clubID,
		limit,
		offset,
	}
	if (options.search) {
		queryParams.search = options.search
	}
	if (options.pendingFilter) {
		queryParams.pendingFilter = JSON.stringify(options.pendingFilter)
	} else if (options.filter) {
		queryParams.filter = options.filter
	}

	const [err, res] = await to<GeneralMap<core.User.Model[] | number>>(GET(`/users`, queryParams))
	if (err) {
		throw err
	}

	await dispatch(fetchedUsers(res))
}

/**
 * Builds a `FetchedUsersAction` upon successful user fetch.
 */
export const fetchedUsers = (userRes: GeneralMap<core.User.Model[] | number>): FetchedUsersActionPaginated => {
	const action: FetchedUsersActionPaginated = {
		receivedAt: Date.now(),
		type: TypeKeys.FETCHED_USERS_PAGINATED,
		users: userRes.users as core.User.Model[],
		count: userRes.count as number,
	}
	return action
}

/**
 * Builds a `FetchedAllUsersAction` upon successful user fetch.
 */
export const fetchedAllUsers = (users: core.User.Model[]): FetchedAllUsersAction => {
	const action: FetchedAllUsersAction = {
		receivedAt: Date.now(),
		type: TypeKeys.FETCHED_ALL_USERS,
		users: users
	}
	return action
}

// -----------------------------------------------------------------------------
// Get User
// -----------------------------------------------------------------------------

const getUser = (userID: string, forceFetch: boolean = false) => async (dispatch: Dispatch<any>, getState: () => RootReducerState) => {
	if (!forceFetch) {
		const cachedUser = usersByIDSelector(getState())[userID]
		if (cachedUser) {
			return dispatch(fetchedUser(cachedUser))
		}
	}

	const [err, user] = await to<core.User.Model>(GET(`/users/${userID}`, {}))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to fetch User with error: ${err}`)
		throw err
	}

	await dispatch(fetchedUser(user))
}

const fetchedUser = (user: core.User.Model): FetchedUserAction => {
	const action: FetchedUserAction = {
		type: TypeKeys.FETCHED_USER,
		user: user,
	}

	return action
}

// -----------------------------------------------------------------------------
// Fetch User Relations
// -----------------------------------------------------------------------------

const fetchUserRelations = (userID: string) => async (dispatch: Dispatch<FetchedUserRelationsAction>) => {
	const [err, relations] = await to<core.Relation.Model[]>(GET(`/users/${userID}/relations`, {}))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to fetch User with error: ${err}`)
		throw err
	}

	await dispatch(fetchedUserRelations(relations))
}

const fetchedUserRelations = (relations: core.Relation.Model[]): FetchedUserRelationsAction => {
	const action: FetchedUserRelationsAction = {
		type: TypeKeys.FETCHED_USER_RELATIONS,
		relations: relations,
	}

	return action
}

// -----------------------------------------------------------------------------
// Create User
// -----------------------------------------------------------------------------

/*
 * Creates a new user.
 */
const createUser = (userPayload: FormData) => async (dispatch: Dispatch<CreatedUserAction>) => {
	const [userErr, userRes] = await to<core.User.Model>(POST_FORM('/admin/users', userPayload))
	if (userErr) {
		// tslint:disable-next-line
		console.error(`Failed to create User with error: ${userErr}`)
		throw userErr
	}
	await dispatch(createdUser(userRes))
}

/**
 * Builds a `CreatedUserAction` upon successful registration.
 */
const createdUser = (user: core.User.Model): CreatedUserAction => {
	const action: CreatedUserAction = {
		receivedAt: Date.now(),
		type: TypeKeys.CREATED_USER,
		user: user,
	}
	return action
}

// -----------------------------------------------------------------------------
// Update User
// -----------------------------------------------------------------------------

const updateUser = (userID: string, userPayload: FormData, isAdmin: boolean) => async (dispatch: Dispatch<UpdatedUserAction>) => {
	const [userErr, userRes] = await to<core.User.Model>(PUT_FORM(`/users/${userID}`, userPayload))
	if (userErr) {
		// tslint:disable-next-line
		console.error(`Failed to update User with error: ${userErr}`)
		throw userErr
	}

	await dispatch(updatedUser(userRes))
}

const updatedUser = (user: core.User.Model): UpdatedUserAction => {
	const action: UpdatedUserAction = {
		type: TypeKeys.UPDATED_USER,
		user: user,
	}
	return action
}

// -----------------------------------------------------------------------------
// Update Users
// -----------------------------------------------------------------------------

const updateUsers = (userIDs: string[], userPayload: { membershipType: string }) => async (dispatch: Dispatch<UpdatedUsersAction>) => {
	const [usersErr, usersRes] = await to<core.User.Model[]>(PUT(`/admin/users/update`, userPayload, { userIDs }))
	if (usersErr) {
		// tslint:disable-next-line
		console.error(`Failed to update Users with error: ${usersErr}`)
		throw usersErr
	}

	await dispatch(updatedUsers(usersRes))
}

const updatedUsers = (users: core.User.Model[]): UpdatedUsersAction => {
	const action: UpdatedUsersAction = {
		type: TypeKeys.UPDATED_USERS,
		users: users,
	}
	return action
}

// -----------------------------------------------------------------------------
// Delete User
// -----------------------------------------------------------------------------

/**
 * Deletes a User.
 */
const deleteUser = (userID: string) => async (dispatch: Dispatch<DeletedUserAction>) => {
	const [deleteErr] = await to(DELETE(`/admin/users/${userID}`, {}))
	if (deleteErr) {
		// tslint:disable-next-line
		console.error(`Failed to delete User with error: ${deleteErr}`)
		throw deleteErr
	}

	await dispatch(deletedUser(userID))
}

/**
 * Builds a `DeletedUserAction` upon successful deletion.
 */
const deletedUser = (userID: string): DeletedUserAction => {
	const action: DeletedUserAction = {
		type: TypeKeys.DELETED_USER,
		userID: userID,
	}
	return action
}

// -----------------------------------------------------------------------------
// Delete Users
// -----------------------------------------------------------------------------

/**
 * Deletes Users.
 */
const deleteUsers = (userIDs: string[]) => async (dispatch: Dispatch<DeletedUsersAction>) => {
	const [deleteErr] = await to(DELETE(`/admin/users`, { userIDs }))
	if (deleteErr) {
		// tslint:disable-next-line
		console.error(`Failed to delete Users with error: ${deleteErr}`)
		throw deleteErr
	}

	await dispatch(deletedUsers(userIDs))
}

/**
 * Builds a `DeletedUsersAction` upon successful deletion.
 */
const deletedUsers = (userIDs: string[]): DeletedUsersAction => {
	const action: DeletedUsersAction = {
		type: TypeKeys.DELETED_USERS,
		userIDs: userIDs,
	}
	return action
}

// -----------------------------------------------------------------------------
// Create User Meta
// -----------------------------------------------------------------------------

const createUserMeta = <T>(userID: string, metaField: string, payload: T, isAdmin: boolean) => async (dispatch: Dispatch<CreatedUserMetaAction>) => {
	const baseRoute = (isAdmin) ? '/admin' : ''
	const [createMetaErr, createMetaRes] = await to<core.User.Model>(POST_FORM(`${baseRoute}/users/${userID}/meta/${metaField}`, payload))
	if (createMetaErr) {
		// tslint:disable-next-line
		console.error(`Failed to create User meta with error: ${createMetaErr}`)
		throw createMetaErr
	}

	await dispatch(createdUserMeta(createMetaRes))
}

const createdUserMeta = (user: core.User.Model): CreatedUserMetaAction => {
	const action: CreatedUserMetaAction = {
		type: TypeKeys.CREATED_USER_META,
		user
	}
	return action
}

// -----------------------------------------------------------------------------
// Update User Meta
// -----------------------------------------------------------------------------

const updateUserMeta = <T>(userID: string, metaField: string, payload: T, isAdmin: boolean) => async (dispatch: Dispatch<UpdatedUserMetaAction>) => {
	const baseRoute = (isAdmin) ? '/admin' : ''
	const [updateMetaErr, updateMetaRes] = await to<core.User.Model>(PUT_FORM(`${baseRoute}/users/${userID}/meta/${metaField}`, payload))
	if (updateMetaErr) {
		// tslint:disable-next-line
		console.error(`Failed to update User meta with error: ${updateMetaErr}`)
		throw updateMetaErr
	}

	await dispatch(updatedUserMeta(updateMetaRes))
}

const updatedUserMeta = (user: core.User.Model): UpdatedUserMetaAction => {
	const action: UpdatedUserMetaAction = {
		type: TypeKeys.UPDATED_USER_META,
		user
	}
	return action
}

// -----------------------------------------------------------------------------
// Delete User Meta
// -----------------------------------------------------------------------------

const deleteUserMeta = (userID: string, metaField: string, metaID: string, isAdmin: boolean) => async (dispatch: Dispatch<DeletedUserMetaAction>) => {
	const baseRoute = (isAdmin) ? '/admin' : ''
	const [deleteMetaErr, deleteMetaRes] = await to<core.User.Model>(DELETE(`${baseRoute}/users/${userID}/meta/${metaField}/${metaID}`, {}))
	if (deleteMetaErr) {
		// tslint:disable-next-line
		console.error(`Failed to delete User meta with error: ${deleteMetaErr}`)
		throw deleteMetaErr
	}

	await dispatch(deletedUserMeta(deleteMetaRes))
}

const deletedUserMeta = (user: core.User.Model): DeletedUserMetaAction => {
	const action: DeletedUserMetaAction = {
		type: TypeKeys.DELETED_USER_META,
		user
	}
	return action
}

// -----------------------------------------------------------------------------
// Set Current User
// -----------------------------------------------------------------------------

const setCurrentUser = (user: core.User.Model) => async (dispatch: Dispatch<SetCurrentUserAction>) => {
	const action: SetCurrentUserAction = {
		type: TypeKeys.SET_CURRENT_USER,
		user: user,
	}
	await dispatch(action)
}

// -----------------------------------------------------------------------------
// Clear Current User
// -----------------------------------------------------------------------------

const clearCurrentUser = () => async (dispatch: Dispatch<ClearCurrentUserAction>) => {
	const action: ClearCurrentUserAction = {
		type: TypeKeys.CLEAR_CURRENT_USER,
	}
	await dispatch(action)
}

// -----------------------------------------------------------------------------
// Toggle Customer View
// -----------------------------------------------------------------------------

const toggleCustomerView = () => async (dispatch: Dispatch<ToggledCustomerViewAction>) => {
	const action: ToggledCustomerViewAction = {
		type: TypeKeys.TOGGLED_CUSTOMER_VIEW,
	}
	await dispatch(action)
}

// -----------------------------------------------------------------------------
// Fetch Users for Message
// -----------------------------------------------------------------------------

const fetchUsersForMessage = (messageID: string, status?: string) => async (dispatch: Dispatch<FetchedUsersForMessageAction>) => {
	const queryParams = {
		...((status) ? { status } : {})
	}
	const [err, res] = await to(GET(`/users/message/${messageID}`, queryParams))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to fetch Users for Message with error: ${err}`)
		throw err
	}

	dispatch(fetchedUsersForMessage(res))
	return res
}

const fetchedUsersForMessage = (userIDs: string[]): FetchedUsersForMessageAction => {
	const action: FetchedUsersForMessageAction = {
		type: TypeKeys.FETCHED_USERS_FOR_MESSAGE,
		userIDs,
	}
	return action
}

export const UserActions = {
	authenticateUser,
	authenticatedUser,
	createUser,
	updateUser,
	updateUsers,
	deleteUser,
	deleteUsers,
	getUsers,
	getUser,
	registerUser,
	signOut,
	createUserMeta,
	updateUserMeta,
	deleteUserMeta,
	requestNewPassword,
	resetPassword,
	changePassword,
	setCurrentUser,
	clearCurrentUser,
	fetchUserRelations,
	toggleCustomerView,
	fetchUsersForMessage
}
