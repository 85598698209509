// External Dependencies
import * as Feather from 'react-feather'

// Internal Dependencies
import { TableColumn } from '../Shared/Table'
import { TableColumnType } from '../Shared/Table/Primitives'

export enum ClubCalendarTableActions {
	View = 'viewClubCalendar',
	Edit = 'editClubCalendar',
	Delete = 'deleteClubCalendar'
}

export const ClubCalendarTableColumns: TableColumn[] = [
	{
		title: '',
		property: '',
		type: TableColumnType.CHECKBOX,
		action: '',
	},
	{
		title: 'Name',
		property: 'name',
		type: TableColumnType.TEXT,
		action: 'name',
	},
	{
		title: 'Color',
		property: 'colorBadge',
		type: TableColumnType.BADGE,
		class: 'text-uppercase',
		action: 'color',
	},
	{
		title: '',
		property: '',
		type: TableColumnType.DROPDOWN,
		class: 'table-dropdown-menu',
		dropdownItems: [
			{
				action: ClubCalendarTableActions.View,
				valueProperty: '_id',
				displayText: 'View Calendar',
				icon: Feather.Eye,
			},
			{
				action: ClubCalendarTableActions.Edit,
				valueProperty: '_id',
				displayText: 'Edit Calendar',
				icon: Feather.Edit,
			},
			{
				action: ClubCalendarTableActions.Delete,
				valueProperty: '_id',
				displayText: 'Delete Calendar',
				icon: Feather.Trash,
			},
		]
	}
]
