// External Dependencies
import * as Feather from 'react-feather'

// Internal Dependencies
import { TableColumn } from '../../Shared/Table'
import { TableColumnType } from '../../Shared/Table/Primitives'

export const ReservationSettingsHoursOfOperationColumns: TableColumn[] = [
	{
		title: 'Day',
		property: 'day',
		type: TableColumnType.TEXT,
	},
	{
		title: 'Open',
		property: 'open',
		type: TableColumnType.TIME,
	},
	{
		title: 'Close',
		property: 'close',
		type: TableColumnType.TIME,
	},
	{
		title: 'Booking Duration',
		property: 'duration',
		type: TableColumnType.TEXT,
	},
	{
		title: '',
		property: '',
		type: TableColumnType.DROPDOWN,
		class: 'table-dropdown-menu',
		dropdownItems: [
			{
				action: 'editHours',
				valueProperty: 'rowIdx',
				displayText: 'Edit',
				icon: Feather.Edit,
			},
			{
				action: 'deleteHours',
				valueProperty: 'rowIdx',
				displayText: 'Remove',
				icon: Feather.Trash,
			}
		],
	}
]
