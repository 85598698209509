// Internal Dependencies
import * as Yup from 'yup'
import { FormInput, ReactSelectItem, FormInputType, InputSelectionItem } from '../../Shared/Form'
import * as Constants from '../../../constants/index'

export interface HoursFormState {
	dayOfWeek: ReactSelectItem,
	opens: Date,
	closes: Date,
	duration: number,
}

export const HourFormBody: FormInput = {
	title: '',
	property: `inputDays`,
	type: null,
	defaultValue: [],
}

export const HourForm = (daysOfWeek: InputSelectionItem[]): FormInput[] => [
	{
		title: 'Day',
		property: 'dayOfWeek',
		type: FormInputType.SELECT,
		placeholder: 'Select Day',
		size: 6,
		selectItems: daysOfWeek,
		defaultValue: null,
		validation: Yup.string().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Booking Duration',
		property: 'duration',
		type: FormInputType.NUMBER,
		placeholder: 'Booking Duration',
		size: 6,
		defaultValue: '',
		validation: Yup.number().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Open',
		property: 'opens',
		type: FormInputType.TIME,
		placeholder: 'Select Open',
		size: 6,
		defaultValue: null,
		validation: Yup.date().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Close',
		property: 'closes',
		type: FormInputType.TIME,
		placeholder: 'Select Close',
		size: 6,
		defaultValue: null,
		validation: Yup.date().required(Constants.REQUIRED_FIELD),
	},
]
