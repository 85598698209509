// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { oc } from 'ts-optchain'

// Actions
import { AlertActions, ClubActions } from '../../../actions/index'

// State
import { RootReducerState } from '../../../reducers'

// Form
import { UsersSelectModalInputs, UserSelectModalState } from './form'

// Components
import FormModal from '../../Shared/Formik/FormModal'

// Helpers
import { userForForm } from '../../../helpers/user'
import { ReactSelectItem } from '../../Shared/Form'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

interface ComponentProps {
	title?: string
	description?: string
	users: core.User.Model[]
	cancelButtonHandler: () => any
	submitButtonHandler: (userIDs: string[]) => any
}

const initialState = {
	selectedUsers: null as core.User.Model[]
}

type Props = ConnectedActions & ComponentProps & ConnectedState
type State = typeof initialState

/**
 * The `UserSelectModal` provides UI for selecting multiple users.
 */
class UserSelectModal extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Handles submit button clicks.
	 */
	handleSubmitButton = async (formState: UserSelectModalState) => {
		const userItems = formState.users
		const userIDs = userItems.map((u: ReactSelectItem) => u.value)
		this.props.submitButtonHandler(userIDs)
	}

	// ----------------------------------------------------------------------------------
	// Component Builders
	// ----------------------------------------------------------------------------------

	public render() {
		const usersForForm = oc(this.props.userState).users([]).map(userForForm)
		const formInputs = UsersSelectModalInputs(usersForForm)
		const formResource = {}
		return (
			<FormModal
				modalTitle={'Select Users'}
				formSpec={formInputs}
				formResource={formResource}
				submitButtonHandler={this.handleSubmitButton}
				cancelButtonHandler={this.props.cancelButtonHandler}
			/>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	userState: state.user,
})

const mapDispatchToProps = {
	...AlertActions,
	...ClubActions
}

const enhance = compose<React.ComponentType<ComponentProps>>(
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(UserSelectModal)
