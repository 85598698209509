// External Dependencies
import * as Yup from 'yup'
import * as core from 'club-hub-core'

// Internal Dependencies
import { FormInput, FormInputType, InputSelectionItem } from '../../Shared/Form'

// Helpers
import * as Constants from '../../../constants/index'

export interface SecuritySettingsFormState {
	oldPassword?: string,
	newPassword?: string,
	confirmPassword?: string,
}

export const SecuritySettingsFormInputs = (): FormInput[] => [
	{
		title: 'Old Password',
		property: 'oldPassword',
		type: FormInputType.PASSWORD,
		size: 12,
		placeholder: 'Enter Old Password...',
		defaultValue: '',
		validation: Yup.string().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'New Password',
		property: 'newPassword',
		type: FormInputType.PASSWORD,
		size: 12,
		defaultValue: '',
		validation: Yup.string().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Confirm New Password',
		property: 'confirmPassword',
		type: FormInputType.PASSWORD,
		size: 12,
		defaultValue: '',
		validation: Yup.string()
			.required(Constants.REQUIRED_FIELD)
			.oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
	}
]
