// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import * as core from 'club-hub-core'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { DropResult } from 'react-beautiful-dnd'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies

// State
import { RootReducerState } from '../../reducers/index'

// Actions
import { AlertActions, RestaurantActions } from '../../actions/index'

// Components
import { HeaderButton, ButtonType } from '../Shared/ButtonGroup'
import ErrorComponent from '../Shared/ErrorComponent'
import TableComponent from '../Shared/Table'
import ModalComponent from '../Shared/Modal'
import DimmedLoader from '../Shared/DimmedLoader'
import TableHeader from '../Shared/TableHeader'
import FormModal from '../Shared/Formik/FormModal'
import { TabBarButtonInput } from '../Shared/TabBar'
import EmptyContent, { ContentType } from '../Shared/EmptyContent'

// Tables
import { MenuItemTableColumns, MenuSectionTableColumns, MenuItemTableActions, MenuSectionTableActions } from './table'

// Form
import { MenuSectionInputs, MenuSectionFormState } from './form'

// Helpers
import { setStateAsync } from '../../helpers/promise'
import { capitalizeString } from '../../helpers/formatting'
import { sortByOrderingIndex } from '../../helpers/array'
import * as Constants from '../../constants'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

enum ActiveTab {
	Items = 'items',
	Sections = 'sections'
}

enum HeaderButtonActions {
	CreateItem = 'createItem',
	CreateSection = 'createSection'
}

// State Key Constants
type ModalStateKey = typeof ShowingEditModal | typeof ShowingDeletionModal
const ShowingEditModal = 'showingEditModal'
const ShowingDeletionModal = 'showingDeletionModal'

interface ComponentRouteParams {
	menu_id: string,
	restaurant_id: string
}

const initialState = {
	restaurant: null as core.Restaurant.Model | null,
	menu: null as core.Restaurant.Menu | null,
	menuItems: null as core.Restaurant.MenuItem[] | null,
	[ShowingEditModal]: false,
	[ShowingDeletionModal]: false,
	menuItemToDelete: null as string | null,
	sectionToUpdate: null as string | null,
	sectionToDelete: null as string | null,
	error: false,
	initialLoading: true,
	loading: false,
	pageIndex: 0,
	tab: null as ActiveTab | null,
	sectionOrdering: null as string[] | null
}

type Props = RouteComponentProps<ComponentRouteParams> & ConnectedState & ConnectedActions
type State = typeof initialState

class MenuItemComponent extends React.Component<Props, State> {
	private pageSize: number

	constructor(props: Props) {
		super(props)
		this.pageSize = 20
		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	/**
	 * We already have the Restaurants in admin state.
	 * Set component state using one of these restaurants.
	 */
	async componentDidMount() {
		this.setComponentState()
		await setStateAsync(this, { initialLoading: false })
	}

	/**
	 * Locates the Restaurant and Menu in admin state using the route query params.
	 * Sets these resources on component state. This method is called after
	 * deletion of a menu item to update this component with the updated restaurant.
	 */
	async setComponentState() {
		// Grab the restaurantID off of the route.
		const queryParams = queryString.parse(this.props.location.search) as { tab: ActiveTab }
		const restaurantID = this.props.match.params.restaurant_id
		const menuID = this.props.match.params.menu_id
		const activeTab = oc(queryParams).tab(ActiveTab.Items)

		const restaurants = oc(this).props.restaurantState.restaurants([])
		const restaurant = restaurants.find((r) => `${r._id}` === restaurantID)
		const menu = restaurant.menus.find((m) => `${m._id}` === menuID)
		await setStateAsync(this, { menu, restaurant, tab: activeTab, sectionOrdering: null })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Dropdown Actions
	// ----------------------------------------------------------------------------------

	/**
	 * Determines which action to take, based on which dropdown item
	 * the user selected in the table's dropdown menu
	 */
	handleTableDropdownAction = (e: any) => {
		const [action, value] = e
		switch (action) {
			case MenuItemTableActions.Edit:
				return this.handleEditMenuItem(value)
			case MenuItemTableActions.Delete:
				return this.handleDeleteMenuItem(value)
			case MenuSectionTableActions.Edit:
				return this.handleEditMenuSection(value)
			case MenuSectionTableActions.Delete:
				return this.handleDeleteSectionItem(value)
			default:
				break
		}
	}

	handleEditMenuItem = (menuItemID: string) => {
		const route = Constants.MENU_ITEM_UPDATE_ROUTE
			.replace(':restaurant_id', `${this.state.restaurant._id}`)
			.replace(':menu_id', `${this.state.menu._id}`)

		// Query params
		const queryParams = { menuItemID }
		const location = {
			pathname: route,
			search: queryString.stringify(queryParams)
		}

		this.props.history.push(location)
	}

	handleDeleteMenuItem = async (menuItemID: string) => {
		await setStateAsync(this, { menuItemToDelete: menuItemID })
		await this.handleOpenModal(ShowingDeletionModal)
	}

	handleEditMenuSection = async (value: string) => {
		await setStateAsync(this, { sectionToUpdate: value })
		await this.handleOpenModal(ShowingEditModal)
	}

	handleDeleteSectionItem = async (value: string) => {
		await setStateAsync(this, { sectionToDelete: value })
		await this.handleOpenModal(ShowingDeletionModal)
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Modal Event Handlers
	// ----------------------------------------------------------------------------------

	handleOpenModal = async (modalStateKey: ModalStateKey) => {
		const newState: Partial<State> = {
			[modalStateKey]: true
		}
		await setStateAsync(this, (() => newState))
	}

	handleCloseModal = async (modalStateKey: ModalStateKey) => {
		const newState: Partial<State> = {
			[modalStateKey]: false,
			menuItemToDelete: null,
			sectionToUpdate: null,
		}
		await setStateAsync(this, (() => newState))
	}

	/**
	 * Make the API call to delete the Menu, when the
	 * user confirms the deletion via the modal
	 */
	executeMenuItemDeletion = async () => {
		await setStateAsync(this, { loading: true })
		const menuItemID = this.state.menuItemToDelete
		const restID = `${this.state.restaurant._id}`
		const [err] = await to(this.props.deleteMenuItem(restID, menuItemID) as any)
		if (err) {
			this.props.fireFlashMessage('Failed to Delete Item', Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false })
			return
		}
		this.props.fireFlashMessage('Successfully Deleted Item', Constants.FlashType.SUCCESS)
		await this.setComponentState()
		await this.handleCloseModal(ShowingDeletionModal)
		await setStateAsync(this, { loading: false })
	}

	handleSubmitSectionForm = async (values: MenuSectionFormState) => {
		const saveFunc = (isNullOrUndefined(values._id)) ?
			this.props.createMenuSection :
			this.props.updateMenuSection

		const restaurantID = this.state.restaurant._id
		const payload = (values as any) as core.Restaurant.MenuSection
		const [err] = await to(saveFunc(`${restaurantID}`, payload) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to save Section. ${err.message}`, Constants.FlashType.DANGER)
			return
		}
		await this.setComponentState()
		this.props.fireFlashMessage(`Successfully saved Section.`, Constants.FlashType.SUCCESS)
		await this.handleCloseModal(ShowingEditModal)
	}

	executeMenuSectionDeletion = async () => {
		await setStateAsync(this, { loading: true })
		const menuSectionID = this.state.sectionToDelete
		const restID = `${this.state.restaurant._id}`
		const [err] = await to(this.props.deleteMenuSection(restID, menuSectionID) as any)
		if (err) {
			this.props.fireFlashMessage('Failed to Delete Section', Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false })
			return
		}
		this.props.fireFlashMessage('Successfully Deleted Section', Constants.FlashType.SUCCESS)
		await this.setComponentState()
		await this.handleCloseModal(ShowingDeletionModal)
		await setStateAsync(this, { loading: false })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Handle a column header of the table being selected
	 */
	handleTableHeaderAction = (e: any) => {
		// TODO: Add this functionality
	}

	/**
	 * Handle a table row being clicked
	 */
	handleTableRowClicked = (menuItem: core.Restaurant.MenuItem) => {
		return this.handleEditMenuItem(menuItem._id as any)
	}

	handleSectionTableReorder = async (result: DropResult) => {
		// Update the 'sectionOrdering' state
		await this.updateSectionOrdering(result)

		// Save the ordering to the DB
		const updatedSections = oc(this).state.restaurant.menuSections([]).map((section) => {
			const sectionOrdering = oc(this).state.sectionOrdering([])
			const orderingIndex = sectionOrdering.findIndex((sectionID) => sectionID === `${section._id}`)
			return { ...section, orderingIndex }
		})

		const restaurantID = this.state.restaurant._id
		const payload = [...updatedSections]
		const [err] = await to(this.props.updateMenuSection(`${restaurantID}`, payload) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to save Sections. ${err.message}`, Constants.FlashType.DANGER)
			return
		}
		this.props.fireFlashMessage(`Successfully saved Sections.`, Constants.FlashType.SUCCESS)
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Header Event Handlers
	// ----------------------------------------------------------------------------------

	handleHeaderButtonAction = async (e: any) => {
		const action: HeaderButtonActions = e.target.value
		switch (action) {
			case HeaderButtonActions.CreateItem:
				return this.handleCreateMenuItem()
			case HeaderButtonActions.CreateSection:
				return this.handleOpenModal(ShowingEditModal)
			default:
				throw new Error(`Unrecognized table button action: ${action}`)
		}
	}

	/**
	 * Navigate to the Menu creation form
	 */
	handleCreateMenuItem = () => {
		const route = Constants.MENU_ITEM_CREATE_ROUTE
			.replace(':restaurant_id', `${this.state.restaurant._id}`)
			.replace(':menu_id', `${this.state.menu._id}`)

		this.props.history.push(route)
	}

	handleTab = async (tabTitle: string) => {
		// Update the query param in the current path with the message status.
		const queryParams = queryString.stringify({ tab: tabTitle })
		const updatedPath = `${this.props.location.pathname}?${queryParams}`
		this.props.history.replace(updatedPath)
		await this.setComponentState()
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	buildMenuItemsForMenu = (restaurantMenuItems: core.Restaurant.MenuItem[], menu: core.Restaurant.Menu): core.Restaurant.MenuItem[] => {
		return restaurantMenuItems.filter((menuItem: core.Restaurant.MenuItem) => {
			return `${menuItem.menuID}` === `${menu._id}`
		})
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildDeleteConfirmationModal = () => {
		if (!this.state.showingDeletionModal) { return null }

		if (!this.state.menuItemToDelete && !this.state.sectionToDelete) { return null }

		let modalTitle: string
		let primaryMessage: string
		let submitHandler: () => any
		if (this.state.menuItemToDelete) {
			modalTitle = 'Delete Menu Item'
			primaryMessage = 'Are you sure you want to delete this Menu Item?'
			submitHandler = this.executeMenuItemDeletion
		} else {
			modalTitle = 'Delete Menu Section'
			primaryMessage = 'Are you sure you want to delete this Menu Section?'
			submitHandler = this.executeMenuSectionDeletion
		}
		return (
			<ModalComponent
				modalTitle={modalTitle}
				primaryMessage={primaryMessage}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={() => this.handleCloseModal(ShowingDeletionModal)}
				submitButtonName={'Confirm'}
				submitButtonHandler={submitHandler}
			/>
		)
	}

	buildSectionFormModal = () => {
		if (!this.state.showingEditModal) { return null }

		const modalTitle = (this.state.sectionToUpdate) ? 'Update Section' : 'Create Section'

		const menuSections = oc(this).state.restaurant.menuSections([])

		const defaultOrderingIndex = menuSections.filter((section) => `${section.menuID}` === `${this.state.menu._id}`).length
		let formResource: core.Restaurant.MenuSection = { name: '', orderingIndex: defaultOrderingIndex, menuID: this.state.menu._id }
		if (this.state.sectionToUpdate) {
			const menuSection = menuSections.find((section) => `${section._id}` === this.state.sectionToUpdate)
			formResource = { ...menuSection }
		}
		return (
			<FormModal<core.Restaurant.MenuSection, MenuSectionFormState>
				modalTitle={modalTitle}
				formSpec={MenuSectionInputs()}
				formResource={formResource}
				cancelButtonHandler={() => this.handleCloseModal(ShowingEditModal)}
				submitButtonHandler={this.handleSubmitSectionForm}
			/>
		)
	}

	buildPageHeader = () => {
		const buttons: TabBarButtonInput[] = [
			{
				title: capitalizeString(ActiveTab.Items),
				onClick: () => this.handleTab(ActiveTab.Items),
				active: this.state.tab === ActiveTab.Items
			},
			{
				title: capitalizeString(ActiveTab.Sections),
				onClick: () => this.handleTab(ActiveTab.Sections),
				active: this.state.tab === ActiveTab.Sections
			},
		]

		return (
			<TableHeader
				fullScreen={true}
				tabInputs={buttons}
				pageTitle={`${this.state.menu.name}`}
				buttons={this.buildHeaderButtons()}
				buttonHandler={this.handleHeaderButtonAction}
			/>
		)
	}

	buildHeaderButtons = (): HeaderButton[] => {
		let buttonAction: HeaderButtonActions
		let buttonText: string
		switch (this.state.tab) {
			case ActiveTab.Sections:
				buttonAction = HeaderButtonActions.CreateSection
				buttonText = 'New Menu Section'
				break
			case ActiveTab.Items:
			default:
				buttonAction = HeaderButtonActions.CreateItem
				buttonText = 'New Menu Item'
				break
		}
		return [
			{
				action: buttonAction,
				type: ButtonType.DEFAULT,
				text: buttonText,
				class: 'btn-primary',
			},
		]
	}

	buildTableRowItemsForGroup = (items: core.Restaurant.MenuItem[], section: core.Restaurant.MenuSection) => {
		return items.filter((item) => `${item.sectionID}` === `${section._id}`).map((item) => {
			return {
				...item,
				availableText: (item.available) ? 'Yes' : 'No',
			}
		})
	}

	buildMenuTable = (items: core.Restaurant.MenuItem[]) => {
		const menuSections = [...oc(this.state.restaurant).menuSections([])]
		const sectionTables = menuSections.sort(sortByOrderingIndex).map((section: core.Restaurant.MenuSection) => {
			const tableRows = this.buildTableRowItemsForGroup(items, section)
			if (tableRows.length === 0) { return null }

			return (
				<div className='group-table-container' key={`${section.name}-container-key`}>
					<h4>{section.name}</h4>
					<TableComponent
						key={`${section.name}-table-key`}
						columns={MenuItemTableColumns}
						rowItems={tableRows}
						showPaging={false}
						actionHandler={this.handleTableHeaderAction}
						dropdownHandler={this.handleTableDropdownAction}
						onTableRowClick={this.handleTableRowClicked}
						isLoading={this.state.loading}
					/>
				</div>
			)
		})

		// Find items that do not have Section IDs that are in the Restaurant's Sections
		const menuSectionIDs = this.state.restaurant.menuSections.map((section) => section._id)
		const uncategorizedItems = items.filter((item) => {
			return isNullOrUndefined(menuSectionIDs.find((sectionID) => sectionID === item.sectionID))
		})

		// Check if we have uncategorized items
		if (uncategorizedItems.length > 0) {
			sectionTables.push(
				<div className='group-table-container' key={`uncategorized-container-key`}>
					<h4>{'Uncategorized Items'}</h4>
					<TableComponent
						key={`uncategorized-table-key`}
						columns={MenuItemTableColumns}
						rowItems={uncategorizedItems}
						showPaging={false}
						actionHandler={this.handleTableHeaderAction}
						dropdownHandler={this.handleTableDropdownAction}
						onTableRowClick={this.handleTableRowClicked}
						isLoading={this.state.loading}
					/>
				</div>
			)
		}

		// Check if Empty
		if (sectionTables.filter((content) => !isNullOrUndefined(content)).length === 0) {
			return (
				<EmptyContent type={ContentType.MenuItems} />
			)
		}

		return sectionTables
	}

	buildSectionTable = () => {
		const sectionsForTable = this.state.restaurant.menuSections.filter((section) => {
			return `${section.menuID}` === `${this.state.menu._id}`
		}).sort(sortByOrderingIndex)

		if (sectionsForTable.length === 0) {
			return (
				<EmptyContent type={ContentType.MenuSections} />
			)
		}

		// Do we need to reorder the list?
		let orderedSections = []
		if (this.state.sectionOrdering) {
			for (const sectionID of this.state.sectionOrdering) {
				const section = sectionsForTable.find((s) => `${s._id}` === sectionID)
				if (!isNullOrUndefined(section)) {
					orderedSections.push(section)
				}
			}
		} else {
			orderedSections = sectionsForTable
		}

		return (
			<div className='group-table-container'>
				<TableComponent
					columns={MenuSectionTableColumns}
					rowItems={orderedSections}
					showPaging={false}
					actionHandler={this.handleTableHeaderAction}
					dropdownHandler={this.handleTableDropdownAction}
					isLoading={this.state.loading}
					enableDragging={true}
					onDrop={this.handleSectionTableReorder}
				/>
			</div>
		)
	}

	buildContent = (menuItems: core.Restaurant.MenuItem[]) => {
		switch (this.state.tab) {
			case ActiveTab.Sections:
				return this.buildSectionTable()
			case ActiveTab.Items:
			default:
				return this.buildMenuTable(menuItems)
		}
	}

	render() {
		const club = oc(this).props.loggedInClub()
		if (this.state.initialLoading) { return <DimmedLoader component={null} isLoading={true} /> }
		if (this.state.error) { return <ErrorComponent club={club} isAdmin={this.props.userState.isAdmin} /> }

		const menuItems = this.buildMenuItemsForMenu(this.state.restaurant.menuItems, this.state.menu)
		return (
			<RS.Row className='menuItemsComponent justify-content-center menu-view-row'>
				<RS.Col lg={12} className={'no-padding'}>
					{this.buildPageHeader()}

					<div className='menu-content-container'>
						{this.buildContent(menuItems)}
					</div>

					{this.buildDeleteConfirmationModal()}
					{this.buildSectionFormModal()}
				</RS.Col>
			</RS.Row>
		)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	updateSectionOrdering = async (result: DropResult) => {
		const sectionsForTable = this.state.restaurant.menuSections.filter((section) => {
			return `${section.menuID}` === `${this.state.menu._id}`
		})

		// Create a list of the ordering
		const sectionIDs: string[] = (this.state.sectionOrdering) ?
			[...this.state.sectionOrdering] :
			sectionsForTable.sort(sortByOrderingIndex).map((s) => `${s._id}`)

		const { destination, source, draggableId } = result
		if (!destination) {
			await setStateAsync(this, { sectionOrdering: [...sectionIDs] })
			return
		}

		if (destination.droppableId === source.droppableId &&
			destination.index === source.index) {
			await setStateAsync(this, { sectionOrdering: [...sectionIDs] })
			return
		}

		sectionIDs.splice(source.index, 1)
		sectionIDs.splice(destination.index, 0, draggableId)
		await setStateAsync(this, { sectionOrdering: [...sectionIDs] })
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	loggedInClub: state.club.loggedInClub,
	userState: state.user,
	restaurantState: state.restaurant
})

const mapDispatchToProps = {
	...AlertActions,
	...RestaurantActions
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemComponent)
