// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import * as Feather from 'react-feather'
import classNames from 'classnames'
import * as Core from 'club-hub-core'

// @ts-ignore
import withSizes from 'react-sizes'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Link, RouteComponentProps } from 'react-router-dom'
import { flatten } from 'underscore'
import { oc } from 'ts-optchain'

// Internal Dependencies
import { NavLinkPressed } from '../../../helpers/analytics'

// State
import { RootReducerState } from '../../../reducers'
import { ClubNavigationSection, ClubNavigationItem } from '../../../reducers/club'
import { inCustomerViewSelector } from '../../../reducers/user'

// Helpers
import * as Constants from '../../../constants'

interface WithSizesProps {
	showingHamburgerMenu: boolean
}

type ConnectedState = ReturnType<typeof mapStateToProps>

type Props = RouteComponentProps & ConnectedState & WithSizesProps
type State = any

class SidebarComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
	}

	// ----------------------------------------------------------------------------------
	// Content Builders Handlers
	// ----------------------------------------------------------------------------------

	buildNavLinks = () => {
		const { loggedInClub, isCustomerView } = this.props

		const navigationConfig = loggedInClub.navigationConfig
		const navigationSections = (this.props.isCustomerView) ? navigationConfig.customer : navigationConfig.admin

		const sidebarItems = flatten(navigationSections.map((section: ClubNavigationSection, index: number) => {
			// Check for Website Section
			if (section.title === 'CLUB INFO') {
				return this.buildClubSectionLinks(section, index)
			}

			if (section.title === 'RESERVATIONS') {
				return this.buildReservationLinks(section, index)
			}

			return this.buildNavigationSectionLinks(section, index)
		}), true)

		const sidebarClasses = classNames({
			'bd-sidebar': true,
			'navbar-expand-lg': !isCustomerView,
			'col-lg-3': !isCustomerView,
			'col-xl-2': !isCustomerView,
			'd-lg-none': isCustomerView,
			'd-xl-none': isCustomerView,
			'collapse': true,
		})

		// Remove the trailing separator
		const sidebarContent = sidebarItems.slice(0, sidebarItems.length - 1)

		return (
			<RS.Col className={sidebarClasses} id='headerMenuCollapse'>
				<div className={'side-bar-container'}>
					<ul className='nav flex-column'>
						{sidebarContent}
					</ul>
				</div>
			</RS.Col>
		)
	}

	buildNavigationSectionLinks = (section: ClubNavigationSection, idx: number): any[] => {
		const sectionHeader = this.buildNavSection(section, idx)
		const sectionLinks = section.items.map(this.buildNavLink)
		const separator = (<div className='nav-separator' key={`nav-sep-${idx}`} />)
		return [sectionHeader, ...sectionLinks, separator]
	}

	buildReservationLinks = (section: ClubNavigationSection, idx: number): any[] => {
		// If we don't have any res items after filtering, we skip this section.
		if (oc(section).items.length(0) === 0) {
			return
		}
		return this.buildNavigationSectionLinks(section, idx)
	}

	buildClubSectionLinks = (section: ClubNavigationSection, idx: number) => {
		// Filter out any sections who don't have any pages.
		const clubSections = [...oc(this).props.sectionState.sections([])]
		const sortedClubSections = clubSections.sort((a, b) => oc(a).orderingIndex(0) - oc(b).orderingIndex(0)).filter((s) => s.pages.length > 0)
		const filteredClubSections = sortedClubSections.filter((s: Core.Section.Model) => (!s.public && s.status === Core.IShared.PublicationStatus.Published))

		return [
			this.buildNavSection(section, idx),
			...section.items.map(this.buildNavLink),
			...filteredClubSections.map((clubSection, sectionIdx) => {
				const path = Constants.VIEW_SECTION_ROUTE.replace(':section_id', `${clubSection._id}`)
				const navLink: ClubNavigationItem = {
					title: clubSection.name,
					featherIcon: 'Info',
					path: path
				}
				if (clubSection.icon) {
					delete navLink.featherIcon
					navLink.fontAwesomeIcon = clubSection.icon
				}
				return this.buildNavLink(navLink, sectionIdx)
			}),
			(<div className='nav-separator' key={`nav-sep-${idx}`} />)
		]
	}

	buildNavSection = (item: ClubNavigationSection, index: number) => {
		return (
			<div className='sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1' key={`nav-section-${index}`}>
				<span className='ml-2'>{item.title}</span>
			</div>
		)
	}

	buildIconBox = (iconIdentifier: string) => {
		return (
			<div className='icon-box mr-3 d-flex justify-content-center'>
				<i className={`fas fa-${iconIdentifier}`} />
			</div>
		)
	}

	buildFeatherIconBox = (featherIcon: string) => {
		const FeatherIcon = (Feather as any)[featherIcon]
		return (
			<div className='icon-box mr-3 d-flex justify-content-center'>
				<FeatherIcon />
			</div>
		)
	}

	buildNavLink = (navItem: ClubNavigationItem, index: number) => {
		// Check if we are building a disabled nav link
		const navDisabled = navItem.path === ''

		// The link will be active if the nav is not disabled and the path starts with the base path
		const currentPath: string = this.props.location.pathname
		const onPath = !navDisabled && (currentPath === navItem.path || currentPath.startsWith(`${navItem.path}/`))

		const icon = (navItem.featherIcon) ? this.buildFeatherIconBox(navItem.featherIcon) : this.buildIconBox(navItem.fontAwesomeIcon)

		const navItemClass = classNames({ active: onPath })

		const navLinkClass = classNames({
			'nav-link': true,
			'active': onPath,
			'disabled': navDisabled,
		})

		return (
			<RS.NavItem
				key={`${navItem.path}_${index}`}
				className={navItemClass}
				data-toggle={(this.props.showingHamburgerMenu) ? 'collapse' : ''}
				data-target={(this.props.showingHamburgerMenu) ? '#headerMenuCollapse' : ''}
				onClick={() => NavLinkPressed(navItem.title)}
			>
				<Link className={navLinkClass} to={navItem.path} data-cy={navItem.title}>
					{icon}
					{navItem.title}
				</Link>
			</RS.NavItem >
		)
	}

	render() {
		const { loggedInClub, userState } = this.props
		if (!userState || !userState.loggedInUser || !loggedInClub) { return null }

		return this.buildNavLinks()
	}

	// ----------------------------------------------
	// Validation Helpers
	// ----------------------------------------------

	validateReservationPrivilege = (resItemTile: string) => {
		const user = this.props.userState.loggedInUser
		if (user.admin) { return true }

		const calendarGroups = this.props.loggedInClub.calendarGroups
		if (resItemTile === 'Tee Times') {
			const golfGroup = calendarGroups.find((group: Core.Calendar.Group) => group.type === Core.Calendar.GroupType.Golf)
			const resSetting = this.getReservationSettingForCalendar(golfGroup)
			if (!resSetting) { return false }

			const privilege = resSetting.privileges.find((priv: Core.Calendar.Privilege) => priv.memberType === user.membershipType)
			return privilege ? true : false
		}
		if (resItemTile === 'Dining') {
			const diningGroup = calendarGroups.find((group: Core.Calendar.Group) => group.type === Core.Calendar.GroupType.Dining)
			const resSetting = this.getReservationSettingForGroup(diningGroup)
			if (!resSetting) { return false }

			const privilege = resSetting.privileges.find((priv: Core.Calendar.Privilege) => priv.memberType === user.membershipType)
			return privilege ? true : false
		}
		return true
	}

	getReservationSettingForGroup = (group: Core.Calendar.Group) => {
		const settings = oc(group).reservationSettings([])
		if (settings.length === 0) {
			return
		}
		if (settings.length === 1) {
			return settings[0]
		}
	}

	getReservationSettingForCalendar = (group: Core.Calendar.Group) => {
		const calendars = this.props.calendarState.calendars
		const calendar = calendars.find((cal: Core.Calendar.Model) => `${cal.groupID}` === `${group._id}`)
		const settings = oc(calendar).reservationSettings([])
		if (settings.length === 0) {
			return
		}
		if (settings.length === 1) {
			return settings[0]
		}
	}
}

const mapSizesToProps = ({ width }: any) => ({
	showingHamburgerMenu: width < 992,
})

const mapStateToProps = (state: RootReducerState) => ({
	userState: state.user,
	sectionState: state.section,
	calendarState: state.calendar,
	loggedInClub: state.club.loggedInClub,
	isCustomerView: inCustomerViewSelector(state),
})

const enhance = compose<React.ComponentType<{}>>(
	withSizes(mapSizesToProps),
	connect(mapStateToProps, undefined)
)

export default enhance(SidebarComponent)
