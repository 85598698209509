// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as queryString from 'query-string'
import * as moment from 'moment'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies
import Card from '../Shared/Cards/Card'
import BackHeader from '../Shared/BackHeader'

// Components
import * as Cards from './cards'
import ProfileCard from '../Shared/Cards/ProfileCard'
import InfoCard from '../Shared/Cards/InfoCard'
import AvatarComponent from '../Shared/Avatar'
import { GlobalNotifSettingsTable, NotifSettingKey } from './GlobalNotifSettingsTable'
import { GroupSettingsTable } from './GroupSettingsTable'
import DimmedLoader from '../Shared/DimmedLoader'
import { OnToggleParams } from './NotifSettingToggle'

// Actions
import { UserActions, AlertActions, GroupActions, NotificationActions, StatementActions } from '../../actions'

// State
import { RootReducerState } from '../../reducers'
import { inCustomerViewSelector, usersByIDSelector } from '../../reducers/user'

// Helpers
import { setStateAsync } from '../../helpers/promise'
import * as Constants from '../../constants'
import { fullName } from '../../helpers/user'
import { capitalizeString, formatPrice } from '../../helpers/formatting'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	loading: true,
	user: null as core.User.Model | null,
	userNotifSettings: null as core.Response.NotificationPreferenceResponse | null
}

type Props = ConnectedActions & ConnectedState & RouteComponentProps & React.HTMLProps<any>
type State = typeof initialState

class UserDetailComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		const { location } = this.props
		// Check if we are on the user/me route
		if (location.pathname === Constants.USER_PROFILE_ROUTE) {
			return this.setLoggedInUser()
		}

		this.setUserWithQueryParameters()
	}

	/**
	 * Checks to see if the query params in the route URL have changed.
	 * If they have we need to reset component state for the User
	 * that matches the userID in the query params.
	 * @param prevProps
	 * @returns void (sets component state).
	 */
	async componentDidUpdate(prevProps: Props) {
		const differentPath = prevProps.location.pathname !== this.props.location.pathname
		const differentQueryParams = prevProps.location.search !== this.props.location.search
		const onProfileRoute = this.props.location.pathname === Constants.USER_PROFILE_ROUTE
		if (differentPath && onProfileRoute) {
			this.setLoggedInUser()
			return
		}

		if (differentPath || differentQueryParams) {
			this.setUserWithQueryParameters()
		}
	}

	setLoggedInUser = async () => {
		const { userState } = this.props
		this.fetchUserInfo(userState.loggedInUser)
	}

	/**
	 * Sets the User that is being displayed, given the User ID in the query string
	 */
	setUserWithQueryParameters = async () => {
		// Parse the query string of the URL into an object
		const parsedQuery = queryString.parse(this.props.location.search)
		// Get the User by its ID
		const userID = parsedQuery.userID

		const userToShow = this.props.usersByID[userID]
		this.fetchUserInfo(userToShow)
		window.scrollTo(0, 0)
	}

	/**
	 * Fetches the User's relations and Notification Preferences.
	 * @param user The User to fetch info for.
	 * @returns void (sets state on component).
	 */
	fetchUserInfo = async (user: core.User.Model) => {
		if (!user) {
			this.props.fireFlashMessage(`Failed to fetch User information, please try again.`, Constants.FlashType.DANGER)
			return
		}

		// Fetch the Relations for the User
		const relationOp = this.props.fetchUserRelations(`${user._id}`)

		// Fetch the notification settings for the User
		const notifSettingOp = (!this.props.isCustomerView || this.isOwnProfile(user)) ?
			this.props.fetchNotifPreferences(`${user._id}`) as any :
			Promise.resolve()

		const userPromises = [relationOp, notifSettingOp]

		// Conditionally fetch statements
		if (this.props.userState.isAdmin || `${this.props.userState.loggedInUser._id}` === `${user._id}`) {
			// Fetch the Relations for the User
			const currentStatementOp = this.props.fetchCurrentStatement(`${user._id}`)
			userPromises.push(currentStatementOp)
		}

		// Fetch User information in parallel.
		const [err, asyncRes] = await to(Promise.all(userPromises))
		if (err) {
			this.props.fireFlashMessage(`Failed to fetch User information. ${err.message}`, Constants.FlashType.DANGER)
			return
		}

		const notifSettingRes = asyncRes[1] as any as core.Response.NotificationPreferenceResponse

		// Update the state
		setStateAsync(this, { user: user, userNotifSettings: notifSettingRes, loading: false })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	handleEditUser = () => {
		const queryParams = { userID: this.state.user._id }
		const location = {
			pathname: Constants.UPDATE_USER_ROUTE,
			search: queryString.stringify(queryParams)
		}

		this.props.history.push(location)
	}

	handleEditVehicle = (idx: number) => {
		const user = this.state.user
		const vehicle = user.meta.car.vehicles[idx]

		// Query Params
		const queryParams = { vehicleID: vehicle._id, userID: user._id }
		const search = queryString.stringify(queryParams)
		const location = { pathname: Constants.UPDATE_VEHICLE_ROUTE, search: search }
		this.props.history.push(location)
	}

	handleClickFamilyCard = (userID: string) => {
		// Query params
		const queryParams = {
			userID: userID
		}

		const location = {
			pathname: Constants.VIEW_USER_ROUTE,
			search: queryString.stringify(queryParams)
		}

		this.props.history.push(location)
	}

	handleToggleGlobalNotif = async (params: OnToggleParams) => {
		const { settingKey, notifID } = params
		return this.handleToggleNotif(settingKey, notifID)
	}

	handleToggleGroupNotif = async (params: OnToggleParams) => {
		const { settingKey, notifID, groupID } = params
		return this.handleToggleNotif(settingKey, notifID, groupID)
	}

	handleToggleNotif = async (settingKey: NotifSettingKey, notifID?: string, groupID?: string) => {
		const { fetchNotifPreferences, fireFlashMessage } = this.props
		const { user, userNotifSettings } = this.state

		const notifPref = (groupID) ?
			userNotifSettings.groupInfo.find(({ group }) => `${group._id}` === groupID).notificationPreference :
			userNotifSettings.global

		const isUpdating = notifID && notifPref

		const notifPreferencesAction = (isUpdating) ?
			this.updateNotifPreferences(notifPref, settingKey) :
			this.createNotifPreferences(settingKey, groupID)

		const [actionErr] = await to(notifPreferencesAction as any)
		if (actionErr) {
			fireFlashMessage(`Problem ${(isUpdating) ? 'updating' : 'creating'} User Group preferences. ${actionErr.message}`, Constants.FlashType.DANGER)
		}

		// Fetch the notification settings for the User
		const [notifErr, notifSettingRes] = await to<core.Response.NotificationPreferenceResponse>(fetchNotifPreferences(`${user._id}`) as any)
		if (notifErr) {
			this.props.fireFlashMessage(`Failed to fetch User Notification settings. ${notifErr.message}`, Constants.FlashType.DANGER)
		}

		// Update the state
		setStateAsync(this, { userNotifSettings: notifSettingRes })
		return notifSettingRes
	}

	// ----------------------------------------------------------------------------------
	// API Calls
	// ----------------------------------------------------------------------------------

	updateNotifPreferences = async (notifPref: core.NotificationPreference.Model, settingKey: NotifSettingKey) => {
		const { updateNotifPreference } = this.props

		let notifPrefToUpdate: core.NotificationPreference.Model
		switch (settingKey) {
			case 'disableAll':
				const newValue = !notifPref.disableAll
				notifPrefToUpdate = {
					...notifPref,
					disableAll: newValue,
					disablePush: newValue,
					disableEmail: newValue,
					disableText: newValue
				}
				break
			default:
				notifPrefToUpdate = {
					...notifPref,
					[settingKey]: !notifPref[settingKey]
				}
				break
		}

		// Make the call to update the preferences
		return updateNotifPreference(`${notifPref._id}`, notifPrefToUpdate)
	}

	createNotifPreferences = async (settingKey: NotifSettingKey, groupID?: string) => {
		const { createNotifPreference } = this.props
		const { user } = this.state

		const disablingAll = settingKey === 'disableAll'

		const notifPrefToCreate: core.NotificationPreference.Model | core.NotificationPreference.GroupModel = {
			userID: user._id,
			clubID: user.clubID,
			...((groupID) ? { userGroupID: groupID as any } : {}),
			disableAll: disablingAll,
			disablePush: disablingAll || settingKey === 'disablePush',
			disableEmail: disablingAll || settingKey === 'disableEmail',
			disableText: disablingAll || settingKey === 'disableText'
		}

		// Make the call to create the preferences
		return createNotifPreference(notifPrefToCreate)
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	/**
 * Builds the Family section of the view
 */
	buildStatementCard = () => {
		if (!oc(this).props.currentStatement._id()) {
			return null
		}
		const location = {
			pathname: Constants.USER_STATEMENT_ROUTE,
			search: queryString.stringify({ userID: this.state.user._id })
		}
		const navToStatements = this.props.history.push.bind(this, location)
		const title = (
			<React.Fragment>
				<span>{'Statements'}</span>
				<span className={'paid'}>Paid</span>
			</React.Fragment>
		)

		let cardContent: JSX.Element

		if (this.props.currentStatement) {
			const balanceTotal = formatPrice(this.props.currentStatement.balanceTotal)
			const arPeriod = moment(this.props.currentStatement.arPeriodDesc)
			const paymentDue = moment(this.props.currentStatement.paymentDue)

			cardContent = (
				<ul className='list-group card-list-group statement'>
					<p className='balance-num'>{balanceTotal}</p>
					<p className='balance-subtext'>{`${arPeriod.format('MMMM')} Balance`}</p>
					<p className='balance-due'>{`Due on ${paymentDue.format('MMMM Do')}`}</p>
					<button className='btn btn-primary btn-block' onClick={() => navToStatements()}>View Statement</button>
				</ul>
			)

			return (
				<div className='user-detail-family-section mb-5'>
					<Card
						title={title}
						bodyClass={'card-body'}
						content={cardContent}
					/>
				</div>
			)
		}
	}

	/**
	 * Builds the Family section of the view
	 */
	buildFamilyCard = () => {
		// Get User's Relations
		const relations: core.Relation.Model[] = oc(this).props.userState.currentRelations([])

		// Do not show Family Card if the User has no relations
		if (relations.length === 0) { return null }

		const familyMembers: Array<core.User.Model & { relationType: string }> = []
		for (const relation of relations) {
			// Get the ID and Type of the relative
			let relativeID
			let relativeType
			if (`${relation.firstID}` === `${this.state.user._id}`) {
				relativeID = relation.secondID
				relativeType = relation.secondType
			} else {
				relativeID = relation.firstID
				relativeType = relation.firstType
			}

			const familyMember = this.props.usersByID[`${relativeID}`]
			if (familyMember) {
				familyMembers.push({ ...familyMember, relationType: relativeType })
			}
		}

		// Sort the Family Members
		familyMembers.sort((a, b) => {
			const values = {
				[core.Relation.RelationType.Spouse]: 3,
				[core.Relation.RelationType.Parent]: 2,
				[core.Relation.RelationType.Sibling]: 1,
				[core.Relation.RelationType.Child]: 0,
			}
			const aValue = values[a.relationType as core.Relation.RelationType]
			const bValue = values[b.relationType as core.Relation.RelationType]
			return bValue - aValue
		})

		const familyMemberCards = familyMembers.map(this.buildFamilyMemberCard)
		const cardContent = (
			<ul className='list-group card-list-group'>
				{familyMemberCards}
			</ul>
		)
		const cardButton = (this.props.isCustomerView) ?
			null :
			(<button className='btn btn-primary btn-block'>Add Family Member</button>)
		return (
			<div className='user-detail-family-section mb-5'>
				<Card
					title={'Family Members'}
					bodyClass={'card-body'}
					content={cardContent}
				/>
				{/* Hiding this button for now -- don't have support for creating Relations yet */}
				{/* {cardButton} */}
			</div>
		)
	}

	/**
	 * Builds an individual Family Member item for the Family section
	 */
	buildFamilyMemberCard = (member: core.User.Model & { relationType: string }, index: number) => {
		const avatarClass = 'avatar-md d-block mr-2'
		const relationType = capitalizeString(member.relationType.toLocaleLowerCase())

		return (
			<li
				key={`family-member-${index}`}
				className='family-card-item list-group-item'
				onClick={() => this.handleClickFamilyCard(`${member._id}`)}
			>
				<div className='d-flex align-items-center'>
					<AvatarComponent className={avatarClass} user={member} />
					<div>
						<div>{fullName(member)}</div>
						<small className='d-block text-muted'>{relationType}</small>
					</div>
				</div>
			</li>
		)
	}

	buildCards = () => {
		const user = this.state.user

		const currentUser = this.props.userState.loggedInUser
		const canAddVehicles = currentUser._id === user._id
		const canAddContact = user.displaySettings.publicContact

		const canEdit = !this.props.isCustomerView || (`${user._id}` === `${currentUser._id}`)
		const editHandler = canEdit ? this.handleEditVehicle : null

		let infoCards
		if (!this.props.isCustomerView) {
			infoCards = Cards.AdminCards(user, this.props.loggedInClub, editHandler)
		} else {
			infoCards = (currentUser._id === user._id) ?
				Cards.CurrentMemberCards(user, this.props.loggedInClub, editHandler) :
				Cards.OtherMemberCards(user, this.props.loggedInClub, canAddVehicles, canAddContact, editHandler)
		}

		return infoCards.map((card, idx) => {
			return (<InfoCard key={`info-card-${idx}`} cardData={card} />)
		})
	}

	buildGlobalNotifSettingsTable = () => {
		const { userNotifSettings } = this.state
		if (isNullOrUndefined(userNotifSettings)) { return null }

		return (
			<GlobalNotifSettingsTable
				heading={'Global Notification Settings'}
				settings={userNotifSettings.global}
				onToggleNotif={this.handleToggleGlobalNotif}
			/>
		)
	}

	buildGroupSettingsTable = () => {
		const { userNotifSettings } = this.state
		if (isNullOrUndefined(userNotifSettings)) { return null }

		// The global settings will override any group settings
		// if the global setting is disabled
		const globalSettings = oc(userNotifSettings).global()

		return (
			<GroupSettingsTable
				heading={'Group Settings'}
				settings={userNotifSettings.groupInfo}
				onToggleNotif={this.handleToggleGroupNotif}
				globalSettings={globalSettings}
			/>
		)
	}

	buildAdminComponent = () => {
		const club = this.props.loggedInClub
		const locationQuery = queryString.parse(this.props.location.search) as core.IShared.GeneralMap<any>
		const searchQuery = oc(locationQuery).searchTerm()
		const goBackRoute = (searchQuery) ? `${Constants.USER_ROUTE}?searchTerm=${searchQuery}` : Constants.USER_ROUTE

		return (
			<div className='mt-4'>
				<BackHeader
					to={goBackRoute}
					backTitle={'Members'}
					editHandler={this.handleEditUser}
				/>
				<div className='row mt-4'>
					<div className='col-lg-4'>
						{/* Profile Image Card */}
						<ProfileCard user={this.state.user} club={club} ownProfile={true} />
						{this.buildStatementCard()}
						{this.buildFamilyCard()}
					</div>

					<div className='col-lg-8'>
						{this.buildCards()}
						{this.buildGlobalNotifSettingsTable()}
						{this.buildGroupSettingsTable()}
					</div>
				</div>
			</div>

		)
	}

	buildMemberComponent = () => {
		const club = this.props.loggedInClub
		const ownProfile = this.isOwnProfile()
		const editHandler = (ownProfile) ? this.handleEditUser : null
		return (
			<div className='row mt-4 justify-content-center'>
				<div className='col-lg-7'>
					<BackHeader
						to={Constants.USER_ROUTE}
						backTitle={'Members'}
						editHandler={editHandler}
					/>
					{/* Profile Image Card */}
					<ProfileCard user={this.state.user} club={club} ownProfile={ownProfile} />
					{this.buildStatementCard()}
					{this.buildCards()}
					{ownProfile && this.buildGlobalNotifSettingsTable()}
					{ownProfile && this.buildGroupSettingsTable()}
				</div>
			</div>
		)
	}

	buildContent = () => {
		return (this.props.isCustomerView) ? this.buildMemberComponent() : this.buildAdminComponent()
	}

	render() {
		if (this.state.loading) { return <DimmedLoader component={null} isLoading={true} /> }
		const user = this.state.user
		if (!user) {
			return null
		}

		const content = this.buildContent()
		return (
			<div className='user-detail-component'>
				{content}
			</div>
		)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	isOwnProfile = (u?: core.User.Model) => {
		const { loggedInUser } = this.props.userState
		const user = (u) ? u : this.state.user
		return `${user._id}` === `${loggedInUser._id}`
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	userState: state.user,
	loggedInClub: state.club.loggedInClub,
	currentStatement: state.statement.currentStatement,
	isCustomerView: inCustomerViewSelector(state),
	usersByID: usersByIDSelector(state)
})

const mapDispatchToProps = {
	...UserActions,
	...GroupActions,
	...AlertActions,
	...NotificationActions,
	...StatementActions
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailComponent)
