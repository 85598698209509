// External Dependencies
import * as core from 'club-hub-core'

// Internal Dependencies
import { FormInput, FormInputType, InputSelectionItem } from '../Form'

export interface QueryFilterFieldAttribute extends InputSelectionItem {
	operators: InputSelectionItem[],
	type: FormInputType,
	values?: InputSelectionItem[],
}

export interface QueryFilterFormState {
	queryField: {
		attribute: QueryFilterFieldAttribute
		comparison: InputSelectionItem
		value: string
	}
	pendingFilters: core.QueryFilter.Predicate[]
	activeFilters: core.QueryFilter.Predicate[]
}

export const FilterCreationInitialFormState: QueryFilterFormState = {
	queryField: {
		attribute: null,
		comparison: null,
		value: '',
	},
	pendingFilters: [],
	activeFilters: [],
}

export const FilterCreationForm = (fields: InputSelectionItem[], operators: InputSelectionItem[], attributeType: FormInputType, attributeValues: InputSelectionItem[]): FormInput[] => [
	{
		title: '',
		property: 'queryField.attribute',
		type: FormInputType.SELECT,
		placeholder: 'Field...',
		class: 'query-field-input',
		selectItems: fields,
		defaultValue: '',
	},
	{
		title: '',
		property: 'queryField.comparison',
		type: FormInputType.SELECT,
		class: 'query-operator-input',
		selectItems: operators,
		defaultValue: '',
	},
	{
		title: '',
		property: 'queryField.value',
		type: attributeType,
		class: 'query-criteria-input',
		selectItems: attributeValues,
		defaultValue: '',
	}
]
