// External Dependencies
import * as Yup from 'yup'

// Internal Dependencies
import { FormInput, FormInputType } from '../Shared/Form'
import * as Constants from '../../constants/index'

export interface MenuSectionFormState {
	name: string
	menuID: string
	orderingIndex: number
	_id?: string
}

export const MenuSectionInputs = (): FormInput[] => {
	return [
		{
			title: 'Name',
			property: 'name',
			type: FormInputType.TEXT,
			placeholder: 'Enter a name...',
			defaultValue: '',
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		},
		{
			title: '_id',
			property: '_id',
			type: FormInputType.BLANK,
			defaultValue: undefined,
		},
		{
			title: 'menuID',
			property: 'menuID',
			type: FormInputType.BLANK,
			defaultValue: undefined,
		},
		{
			title: 'orderingIndex',
			property: 'orderingIndex',
			type: FormInputType.BLANK,
			defaultValue: undefined,
		}
	]
}
