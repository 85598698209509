// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as queryString from 'query-string'
import * as RS from 'reactstrap'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { FormikProps } from 'formik'
import { isEqual, debounce } from 'underscore'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies

// Actions
import { PostActions, AlertActions } from '../../../actions/index'

// State
import { RootReducerState } from '../../../reducers'
import { adminsSelector } from '../../../reducers/user'
import { postsByIDSelector, currentPostSelector } from '../../../reducers/post'
import { saveStateForKey, removeStateForKey, loadStateForKey } from '../../../store/localStorage'

// Components
import TypeCreationModal from '../../Shared/TypeCreationModal'
import Editor from '../../Shared/Editor'
import DimmedLoader from '../../Shared/DimmedLoader'

// Form
import { PostFormInputs, PostFormState } from './form'
import GenericFormComponent from '../../Shared/Formik/GenericForm'
import { BuildWrappedForm } from '../../Shared/Formik'
import { InputSelectionItem, FormInput } from '../../Shared/Form'
import ActionBar from '../../Shared/ActionBar'

// Helpers
import * as Constants from '../../../constants'
import { setStateAsync } from '../../../helpers/promise'
import { userForForm } from '../../../helpers/user'
import * as formHelper from '../../../helpers/form'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	error: false,
	loading: true,
	creatingType: false,
	postToUpdate: null as core.Post.Model | null,
	postFormContent: null as PostFormState | null,
	richContent: null as core.SubModels.RichContent.Model | null,
	showingEditor: false,
}

type Props = RouteComponentProps & ConnectedActions & ConnectedState
type State = typeof initialState

class CreatePostForm extends React.Component<Props, State> {
	private isUpdateForm: boolean

	constructor(props: Props) {
		super(props)
		const currentPath: string = props.location.pathname
		const showingEditor = currentPath === `${Constants.CREATE_POST_ROUTE}/content` || currentPath === `${Constants.UPDATE_POST_ROUTE}/content`
		this.state = { ...initialState, showingEditor }
		this.isUpdateForm = currentPath.includes(Constants.UPDATE_POST_ROUTE)
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		if (this.isUpdateForm) {
			await this.setPostForFormWithQueryParams()
		}

		// Load state from local storage and set it
		await this.loadFormStateFromLocalStorage()
		await setStateAsync(this, { loading: false })
	}

	componentWillUnmount() {
		// Cancel any inflight debounced save calls:
		this.debouncedSave.cancel()
		this.debouncedSaveToLocalStorage.cancel()

		// Clear the Form State from Local Storage if we are moving to a different route
		const newRoute = this.props.history.location.pathname
		if (!newRoute.includes(Constants.UPDATE_POST_ROUTE) && !newRoute.includes(Constants.CREATE_POST_ROUTE)) {
			// Clear the state from local storage
			this.clearFormStateFromLocalStorage()
		}
	}

	setPostForFormWithQueryParams = async () => {
		// Parse the query string of the URL into an object
		const { location } = this.props
		const parsedQuery = queryString.parse(location.search)

		// Get the Post by its ID
		const postID = parsedQuery.postID
		const [err, post] = await to(this.props.fetchPost(postID) as any)
		if (err) {
			this.props.fireFlashMessage(`Our apologies, we couldn't find your Post. Please try again.`, Constants.FlashType.WARNING)
			return
		}
		const postToUpdate = oc(this).props.postState.currentPost({})
		await setStateAsync(this, { postToUpdate, richContent: postToUpdate.richContent })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Determines which handler to call (Create or Update) when the user submits the form
	 */
	handleSave = async (status: core.IShared.PublicationStatus): Promise<void> => {
		await setStateAsync(this, { loading: true })

		// Cancel any inflight debounced save calls:
		this.debouncedSave.cancel()
		this.debouncedSaveToLocalStorage.cancel()

		const { createPost, updatePost, fireFlashMessage } = this.props
		const { postFormContent, postToUpdate } = this.state

		// Build the payload
		const form: PostFormState = { ...postFormContent, status }
		const payload = this.buildPostPayload(form)

		// Determine the resource type
		const resourceType = (status === core.IShared.PublicationStatus.Draft) ? 'Draft' : 'News'

		// Save the Post
		const postToUpdateID = oc(postToUpdate)._id()
		const savePost = (this.isUpdateForm) ? updatePost(`${postToUpdateID}`, payload) : createPost(payload)
		const [err] = await to(savePost as any)
		if (err) {
			fireFlashMessage(`Problem saving ${resourceType}. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}

		fireFlashMessage(`Successfully saved ${resourceType}.`, Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, error: false })

		// Redirect to the Detail View
		this.redirectToDetailView()
	}

	/**
	 * Returns the user to the Posts view when they cancel the form
	 */
	handleCancel = async () => {
		const { location, history } = this.props
		const { postFormContent, richContent } = this.state

		await setStateAsync(this, { showingEditor: false })
		const baseRoute = this.getBaseRoute()

		// We are on the content page -- go back to the base form route
		if (location.pathname === `${baseRoute}/content`) {
			// Save the state to local storage, so it will survive a refresh
			await this.saveFormStateToLocalStorage(postFormContent, richContent)
			history.push(`${baseRoute}${location.search}`)
			return
		}
		// Go back to the Posts view
		history.goBack()
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Form
	// ----------------------------------------------------------------------------------

	handleNext = async (form: PostFormState): Promise<void> => {
		await setStateAsync(this, { postFormContent: form, showingEditor: true })

		const { location, history } = this.props
		const { richContent } = this.state

		// Save the state to local storage, so it will survive a refresh
		await this.saveFormStateToLocalStorage(form, richContent)

		const baseRoute = this.getBaseRoute()
		history.push(`${baseRoute}/content${location.search}`)
	}

	// ----------------------------------------------------------------------------------
	// Type Creation Modal Event Handlers
	// ----------------------------------------------------------------------------------

	handleTypeCreationModalSave = async (formikProps: FormikProps<PostFormState>, newType?: core.Club.ResourceType) => {
		const newTypeForForm: InputSelectionItem = (!isNullOrUndefined(newType)) ?
			({ label: newType.title, value: `${newType._id}` }) : null

		// Update the form state to use the new Type
		formikProps.setFieldValue('type', newTypeForForm)
		await this.handleCreationModalClose()
	}

	handleCreationModalClose = async () => {
		await setStateAsync(this, { creatingType: false })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Editor
	// ----------------------------------------------------------------------------------

	/**
	 * A debounced function, which will either create/update a Post that is being edited.
	 * This basically acts as an autosave mechanism, so that we can save a Post after
	 * the User types in some input into the editor
	 */
	debouncedSave = debounce(async () => {
		const { currentPost, createPost, updatePost, fireFlashMessage } = this.props
		const { postFormContent } = this.state

		// Build the payload
		const payload = this.buildPostPayload({ ...postFormContent, status: core.IShared.PublicationStatus.Draft })

		// Determine if we are updating an existing Post
		const currentPostID = oc(currentPost)._id()
		const savePost = (!isNullOrUndefined(currentPostID)) ? updatePost(`${currentPostID}`, payload) : createPost(payload)
		const [err] = await to(savePost as any)
		if (err) {
			fireFlashMessage(`Problem saving draft. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}

		fireFlashMessage(`Successfully saved draft.`, Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, error: false })
	}, 5000)

	/**
	 * A debounced function, which will save the current component form state to local storage,
	 * so that a user can safely refresh the page and not lose any of their edits.
	 */
	debouncedSaveToLocalStorage = debounce(() => {
		this.saveFormStateToLocalStorage(this.state.postFormContent, this.state.richContent)
	}, 1000)

	handleEditorChange = async (richContent: core.SubModels.RichContent.Model) => {
		await setStateAsync(this, { richContent })

		// Kick off the autosave functions (remote and local save)
		await this.debouncedSave()
		this.debouncedSaveToLocalStorage()
	}

	handleFormChange = async (field: string, value: InputSelectionItem) => {
		if (field === 'type' && value.value === 'addNew') {
			await setStateAsync(this, { creatingType: true })
		}
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildForm = () => {
		const { loggedInClub, admins } = this.props
		const { postToUpdate, postFormContent } = this.state

		const existingPost = (this.isUpdateForm && postToUpdate) ? postToUpdate : undefined
		const formTitle = (this.isUpdateForm) ? 'Update Post' : 'New Post'

		const authors: InputSelectionItem[] = admins.map(userForForm)
		const postTypes = oc(loggedInClub).resources.posts.types([])
		const locationTypes: any[] = formHelper.typeSelectInputs(postTypes)
		const formInputs = PostFormInputs(authors, locationTypes)

		// If we have a Post we are updating, set its Author
		let formResource: any
		const author = oc(existingPost).author({}) as core.User.Model
		if (existingPost) {
			formResource = {
				...existingPost,
				author: author._id, // Author prop is not populated, so we only have a id here.
				image: oc(existingPost).image()
			}
		}

		if (postFormContent) {
			formResource = { ...postFormContent }
			formResource.author = (formResource.author && formResource.author.value) ? formResource.author.value : formResource.author
			formResource.type = (formResource.type && formResource.type.value) ? formResource.type.value : formResource.type
		}

		return (
			<RS.Col md={8} className={'post-form-container no-padding'}>
				<GenericFormComponent
					title={formTitle}
					inputs={formInputs}
					formResource={formResource}
					enableReinitialize={false}
					cancelButtonName={'Cancel'}
					cancelButtonHandler={this.handleCancel}
					submitButtonName={'Next'}
					submitButtonHandler={this.handleNext}
					onChangeHandler={this.handleFormChange}
					render={(formikProps) => this.buildFormBody(formInputs, formikProps)}
				/>
			</RS.Col>
		)
	}

	buildFormBody = (formInputs: FormInput[], formikProps: FormikProps<PostFormState>) => {
		return (
			<>
				{BuildWrappedForm({ inputs: formInputs, onChange: this.handleFormChange }, formikProps)}
				{this.buildTypeCreationModal(formikProps)}
			</>
		)
	}

	buildTypeCreationModal = (formikProps: FormikProps<PostFormState>) => {
		const { creatingType } = this.state
		if (!creatingType) { return null }

		const existingTypesForModal = oc(this).props.loggedInClub.resources.posts.types([])
		return (
			<TypeCreationModal
				typeName={'Events'}
				resourceType={'posts'}
				existingTypes={existingTypesForModal}
				onClose={async () => {
					formikProps.setFieldValue('type', null)
					return this.handleCreationModalClose()
				}}
				onSave={(newType: core.Club.ResourceType) => this.handleTypeCreationModalSave(formikProps, newType)}
			/>
		)
	}

	buildEditor = () => {
		const { postToUpdate, richContent } = this.state
		const postToEdit = (this.isUpdateForm && postToUpdate) ? ({ ...postToUpdate }) : undefined

		// The initial rich content for the Editor will either be:
		// 1. The richContent field on an existing Post that is being edited
		// 2. The richContent field that exists on this component's state
		const initialRichContent = (!isNullOrUndefined(postToEdit)) ?
			oc(postToEdit).richContent({}) :
			oc(richContent)({})

		const actionButtons = this.getActionBarButtons()
		return (
			<RS.Col className={'richContent no-padding'}>
				<ActionBar buttonItems={actionButtons} onCancel={this.handleCancel} />
				<Editor
					className='mx-auto'
					fullScreen={true}
					richContent={initialRichContent}
					placeholder={'Enter content...'}
					onChange={this.handleEditorChange}
				/>
			</RS.Col>
		)
	}

	render() {
		const { loading, showingEditor } = this.state
		if (loading) { return <DimmedLoader component={null} isLoading={true} /> }
		const content = (showingEditor) ? this.buildEditor() : this.buildForm()
		return (
			<RS.Row className={'post-form-content-row d-flex justify-content-center'}>
				{content}
			</RS.Row>
		)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	/**
	 * Builds the payload, that will be sent to the server, based on the form
	 */
	buildPostPayload = (form: PostFormState): FormData => {
		const { richContent } = this.state

		const formPayload = new FormData()

		// Create the Post payload and add it to the FormData
		const post: Partial<core.Post.Model> = {
			author: form.author.value as any,
			title: form.title,
			richContent: {
				...richContent,
				status: form.status
			},
			publicationDate: oc(form).publicationDate(new Date()),
			type: oc(form).type.value() as any
		}

		// Check for existing image
		if (Array.isArray(form.image)) {
			// tslint:disable-next-line
			for (let i = 0; i < form.image.length; i++) {
				const formImage: File | core.SubModels.Image.Model = form.image[i]
				if ((formImage as core.SubModels.Image.Model)._id) {
					post.image = formImage as core.SubModels.Image.Model
					break
				}
				formPayload.append('image', formImage)
				break
			}
		} else if (!isNullOrUndefined(form.image)) {
			// If form.image is not an array, then we know that
			// it is an existing image coming from the resource
			post.image = form.image
		}

		formPayload.append('post', JSON.stringify(post))
		return formPayload
	}

	getActionBarButtons = () => {
		return [
			{ title: 'Publish', onClick: () => this.handleSave(core.IShared.PublicationStatus.Published) },
			{ title: 'Save Draft', onClick: () => this.handleSave(core.IShared.PublicationStatus.Draft) },
		]
	}

	redirectToDetailView = async () => {
		const { currentPost, history } = this.props

		const postID = currentPost._id
		const pathname = Constants.VIEW_POST_ROUTE
		const search = queryString.stringify({ postID })

		const postDetailLocation = { pathname, search }
		history.push(postDetailLocation)
	}

	getBaseRoute = () => {
		return (this.isUpdateForm) ? Constants.UPDATE_POST_ROUTE : Constants.CREATE_POST_ROUTE
	}

	saveFormStateToLocalStorage = (formState: PostFormState, editorContent: core.SubModels.RichContent.Model) => {
		const baseRoute = this.getBaseRoute()
		const stateToSave: Partial<State> = {
			postFormContent: formState,
			richContent: editorContent
		}
		saveStateForKey(stateToSave, baseRoute)
	}

	loadFormStateFromLocalStorage = async () => {
		const baseRoute = this.getBaseRoute()
		const formState = loadStateForKey(baseRoute)
		await setStateAsync(this, { ...formState })
	}

	clearFormStateFromLocalStorage = () => {
		const baseRoute = this.getBaseRoute()
		// Clear the Editor State
		removeStateForKey(baseRoute)
		// Clear the Form State
		removeStateForKey(`${baseRoute}__formState`)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	userState: state.user,
	postState: state.post,
	loggedInClub: state.club.loggedInClub,
	admins: adminsSelector(state),
	postsByID: postsByIDSelector(state),
	currentPost: currentPostSelector(state),
})

const mapDispatchToProps = {
	...PostActions,
	...AlertActions
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePostForm)
