// External Dependencies
import * as Yup from 'yup'

// Internal Dependencies
import { FormInput, FormInputType } from '../../Shared/Form'
import { dayOfWeekSelectionInputs } from '../../../helpers/form'
import * as Constants from '../../../constants/index'

export interface MenuFormState {
	name: string
	day: string
	open: string
	close: string
	sections?: string[]
	description?: string
}

export const MenuFormInputs = (): FormInput[] => [
	{
		title: 'Name',
		property: 'name',
		type: FormInputType.TEXT,
		size: 12,
		placeholder: 'Name...',
		defaultValue: '',
		validation: Yup.string().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Day',
		property: 'day',
		type: FormInputType.SELECT,
		size: 4,
		placeholder: 'Day...',
		selectItems: dayOfWeekSelectionInputs,
		defaultValue: null,
		validation: Yup.string().nullable().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Open',
		property: 'open',
		type: FormInputType.TIME,
		size: 4,
		placeholder: 'Open...',
		defaultValue: null,
		validation: Yup.date().nullable().default(null).required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Close',
		property: 'close',
		type: FormInputType.TIME,
		size: 4,
		placeholder: 'Close...',
		defaultValue: null,
		validation: Yup.date().nullable().default(null).required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Description',
		property: 'description',
		type: FormInputType.TEXT_AREA,
		size: 12,
		placeholder: 'Enter Description...',
		defaultValue: '',
	}
]
