// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import {oc} from 'ts-optchain'
import {sortBy, shuffle} from 'underscore'
import { isNullOrUndefined } from 'util'

// Internal Dependencies
import { BaseTablePrimitiveProps } from '../index'
import { BaseTableCell } from '../BaseTableCell'
import AvatarListComponent from '../../../AvatarList'

type Props<T> = BaseTablePrimitiveProps<T>

export const TableAvatarList: React.FC<Props<any>> = (props: Props<any>) => {
	const users = props.item[props.column.property] as core.User.Model[]
	const filteredUsers = shuffle(users.filter((user) => !isNullOrUndefined(user)))
	const orderedUsers = sortBy(filteredUsers, (user: core.User.Model) => {
		if (oc(user).image.sm()) {
			return -1
		}
		return 1
	})
	const visible = orderedUsers.slice(0, 3)
	return (
		<BaseTableCell {...props}>
			<AvatarListComponent users={visible} remaining={users.length - visible.length} />
		</BaseTableCell>
	)
}
