// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as RS from 'reactstrap'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { DropResult } from 'react-beautiful-dnd'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies

// Table
import { SectionTableItem, SectionTableColumns, SectionTableActions } from './table'

// Components
import { HeaderButton, ButtonType } from '../Shared/ButtonGroup'
import ModalComponent from '../Shared/Modal'
import TableComponent from '../Shared/Table'
import TableHeader from '../Shared/TableHeader'
import DimmedLoader from '../Shared/DimmedLoader'
import SectionFormModal from './SectionForm'
import { TabBarButtonInput } from '../Shared/TabBar'

// State
import { RootReducerState } from '../../reducers'

// Actions
import { SectionActions, AlertActions } from '../../actions/index'

// Helpers
import { setStateAsync } from '../../helpers/promise'
import { publicationStatusBadge } from '../../helpers/badge'
import { sortByOrderingIndex } from '../../helpers/array'
import * as Constants from '../../constants'
import { SectionFormState } from './SectionForm/form'
import { capitalizeString } from '../../helpers/formatting'
import { Rss } from 'react-feather'

enum ActiveTab {
	Private = 'private',
	Public = 'public',
}

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	loading: true,
	error: false,
	pageIndex: 0,
	showingNewSectionModal: false,
	sectionToRename: null as string | null,
	sectionToDelete: null as string | null,
	sectionOrdering: null as string[] | null,
	tab: null as ActiveTab | null,
}

type Props = ConnectedActions & ConnectedState & RouteComponentProps
type State = typeof initialState

class ClubSectionTableComponent extends React.Component<Props, State> {
	private pageSize: number

	constructor(props: Props) {
		super(props)
		this.pageSize = 20
		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		// Fetch sections on mount.
		await this.setComponentState()
	}

	async setComponentState() {
		await this.props.fetchSections(this.props.userState.isAdmin)

		// Parse the query string of the URL into an object
		const parsedQuery = queryString.parse(this.props.location.search)
		const sectionType = parsedQuery.type
		const tab = sectionType ? sectionType : ActiveTab.Private
		await setStateAsync(this, { loading: false, tab: tab })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Dropdown Actions
	// ----------------------------------------------------------------------------------

	/**
	 * Determines which action to take, based on which dropdown item
	 * the user selected in the table's dropdown menu
	 */
	handleTableDropdownAction = (e: any) => {
		const [action, value] = e
		switch (action) {
			case SectionTableActions.ViewSection:
				return this.handleViewSectionDetail(value)
			case SectionTableActions.RenameSection:
				return this.handleRenameSection(value)
			case SectionTableActions.RemoveSection:
				return this.handleDeleteSection(value)
			default:
				break
		}
	}

	/**
	 * When viewing a Section, navigate to the Pages table
	 */
	handleViewSection = (sectionID: string) => {
		const route = Constants.SECTION_PAGES_ROUTE.replace(':section_id', sectionID)
		this.props.history.push(route)
	}

	/**
	 * When viewing a Section Detail, navigate to the Section Detail route
	 */
	handleViewSectionDetail = (sectionID: string) => {
		const section = oc(this).props.sectionState.sections([]).find((s) => `${s._id}` === sectionID)
		const route = Constants.VIEW_SECTION_ROUTE.replace(':section_id', `${section._id}`)
		this.props.history.push(route)
	}

	/**
	 * When editing a Section
	 */
	handleRenameSection = async (sectionID: string) => {
		await setStateAsync(this, { sectionToRename: sectionID })
	}

	/**
	 * When attempting to delete a Section, display the
	 * Section deletion confirmation modal
	 */
	handleDeleteSection = async (sectionID: string) => {
		await setStateAsync(this, { sectionToDelete: sectionID })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Modal Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Handles clicks on the `Save` button in the New Section modal.
	 */
	handleCreateNewSection = async (formState: SectionFormState) => {
		// Create the Section
		const payload = this.buildSectionPayload(formState, false)
		const [err] = await to(this.props.createSection(payload) as any)
		if (err) {
			this.props.fireFlashMessage(`Problem creating Section. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}

		this.props.fireFlashMessage('Section successfully created.', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, error: false, showingNewSectionModal: false })
	}

	/**
	 * Handles clicks on the cancel button in the Section modal.
	 */
	handleCancelNewSectionModal = async () => {
		await setStateAsync(this, { showingNewSectionModal: false })
	}

	/**
	 * Handles clicks on the `Save` button in the New Section modal.
	 */
	handleUpdateSection = async (form: SectionFormState) => {
		// Create the Section
		const payload = this.buildSectionPayload(form, true)
		const [err] = await to(this.props.updateSection(this.state.sectionToRename, payload) as any)
		if (err) {
			this.props.fireFlashMessage(`Problem creating Section. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}

		this.props.fireFlashMessage('Section successfully updated.', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, error: false, sectionToRename: null })
	}

	/**
	 * Handles clicks on the cancel button in the Section modal.
	 */
	handleCancelUpdateSectionModal = async () => {
		await setStateAsync(this, { sectionToRename: null })
	}

	/**
	 * Make the API call to delete the Section, when the
	 * user confirms the deletion via the modal
	 */
	executeSectionDeletion = async () => {
		// Delete the Section and fetch the Sections
		const sectionID = this.state.sectionToDelete
		const [deleteErr] = await to(this.props.deleteSection(sectionID) as any)
		if (deleteErr) {
			this.props.fireFlashMessage(`Failed to delete Section. ${deleteErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, sectionToDelete: null })
			return
		}

		await setStateAsync(this, { loading: false, sectionToDelete: null })
	}

	/**
	 * Hide the deletion modal when the user cancels it
	 */
	closeDeleteSectionModal = async () => {
		await setStateAsync(this, { sectionToDelete: null })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Handle table pagination
	 */
	handlePage = async (data: any) => {
		const { pageIndex } = this.state

		// Check if the new page is different that the current one
		if (data.selected !== pageIndex) {
			await setStateAsync(this, { pageIndex: data.selected })
		}
	}

	/**
	 * Handle a column header of the table being selected
	 */
	handleTableHeaderAction = (e: any) => {
		// TODO: Add Functionality
	}

	/**
	 * Handle a table row being clicked
	 */
	handleTableRowClicked = (item: SectionTableItem) => {
		return this.handleViewSection(`${item._id}`)
	}

	handleTableReorder = async (result: DropResult) => {
		await this.updateSectionOrdering(result)

		// Save the ordering to the DB
		const updatedSections = oc(this).props.sectionState.sections([]).map((s) => {
			const sectionIDs = oc(this).state.sectionOrdering([])
			const sectionOrderIndex = sectionIDs.findIndex((sectionID) => sectionID === `${s._id}`)

			const orderingIndex = (sectionOrderIndex === -1) ?
				oc(this).props.sectionState.sectionCount(0) :
				sectionOrderIndex + (this.pageSize * this.state.pageIndex)
			return { ...s, orderingIndex }
		})

		const updatePromises: Array<Promise<void>> = []
		for (const updatedSection of updatedSections) {
			const sectionID = `${updatedSection._id}`
			const formPayload = new FormData()
			formPayload.append('section', JSON.stringify(updatedSection))
			const updateOp = this.props.updateSection(sectionID, formPayload) as any
			updatePromises.push(updateOp)
		}

		const [updateErr] = await to(Promise.all(updatePromises))
		if (updateErr) {
			this.props.fireFlashMessage(`Failed to update Sections. ${updateErr.message}`, Constants.FlashType.DANGER)
			return
		}

		this.props.fireFlashMessage(`Successfully updated Sections.`, Constants.FlashType.SUCCESS)
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Header Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Determine which action to take, based on the header
	 * button that the user selected
	 */
	handleHeaderButtonAction = (e: any) => {
		const action = e.target.value
		switch (action) {
			case 'createSection':
				this.handleCreateButton()
				break
			default:
				break
		}
	}

	handleCreateButton = async () => {
		await setStateAsync(this, { showingNewSectionModal: true })
	}

	handleTab = async (tabTitle: ActiveTab) => {
		await setStateAsync(this, { loading: true })
		// Update the query param in the current path with the message status.
		const queryParams = queryString.stringify({ type: tabTitle })
		const updatedPath = `${this.props.location.pathname}?${queryParams}`
		this.props.history.replace(updatedPath)

		this.setComponentState()
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	/**
	 * Builds a new Section payload.
	 */
	buildSectionPayload = (form: SectionFormState, isUpdating: boolean): FormData => {
		// Build the form data payload
		const formPayload = new FormData()
		const clubID = oc(this).props.loggedInClub._id()
		const creatorID = oc(this).props.userState.loggedInUser._id()

		const visibility = oc(form).visibility.value() === 'public'
		const payload: core.Section.Model = {
			clubID: clubID as any,
			name: form.name,
			icon: form.icon,
			description: form.description,
			public: visibility,
			status: isUpdating ? undefined : core.IShared.PublicationStatus.Published,
			pages: isUpdating ? undefined : [],
			orderingIndex: isUpdating ? undefined : 0,
			createdBy: creatorID as any,
			updatedBy: creatorID as any
		}

		// Check for existing image
		if (Array.isArray(form.image)) {
			// tslint:disable-next-line
			for (let i = 0; i < form.image.length; i++) {
				const formImage: File | core.SubModels.Image.Model = form.image[i]
				if ((formImage as core.SubModels.Image.Model)._id) {
					payload.image = formImage as core.SubModels.Image.Model
					break
				}
				formPayload.append('image', formImage)
				break
			}
		} else if (!isNullOrUndefined(form.image)) {
			// If form.image is not an array, then we know that
			// it is an existing image coming from the resource
			payload.image = form.image
		}

		formPayload.append('section', JSON.stringify(payload))

		return formPayload
	}

	updateSectionOrdering = async (result: DropResult) => {
		// Copy the Sections
		const sections = this.getAllSections()
		const sectionIDs = (this.state.sectionOrdering) ?
			[...this.state.sectionOrdering] :
			sections.sort(sortByOrderingIndex).map((s) => `${s._id}`)

		const { destination, source, draggableId } = result
		if (!destination) {
			await setStateAsync(this, { sectionOrdering: [...sectionIDs] })
			return
		}

		if (destination.droppableId === source.droppableId &&
			destination.index === source.index) {
			await setStateAsync(this, { sectionOrdering: [...sectionIDs] })
			return
		}

		sectionIDs.splice(source.index, 1)
		sectionIDs.splice(destination.index, 0, draggableId)
		await setStateAsync(this, { sectionOrdering: [...sectionIDs] })
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildHeaderButtons = (): HeaderButton[] => {
		return [
			{
				action: 'createSection',
				type: ButtonType.DEFAULT,
				text: 'New Section',
				class: 'btn-primary',
			},
		]
	}

	buildPageHeader = () => {
		const buttons: TabBarButtonInput[] = [
			{
				title: capitalizeString(ActiveTab.Private),
				onClick: () => this.handleTab(ActiveTab.Private),
				active: this.state.tab === ActiveTab.Private
			},
			{
				title: capitalizeString(ActiveTab.Public),
				onClick: () => this.handleTab(ActiveTab.Public),
				active: this.state.tab === ActiveTab.Public
			},
		]

		return (
			<TableHeader
				fullScreen={true}
				tabInputs={buttons}
				pageTitle={'Sections'}
				buttons={this.buildHeaderButtons()}
				buttonHandler={this.handleHeaderButtonAction}
			/>
		)
	}

	buildNewSectionModal = () => {
		if (!this.state.showingNewSectionModal) { return null }
		return (
			<SectionFormModal
				submitButtonHandler={this.handleCreateNewSection}
				cancelButtonHandler={this.handleCancelNewSectionModal}
			/>
		)
	}

	buildRenameSectionModal = () => {
		if (!this.state.sectionToRename) { return null }
		const sections = oc(this).props.sectionState.sections([])
		const sectionToUpdate = sections.find((s) => `${s._id}` === this.state.sectionToRename)
		const formResource = {
			...sectionToUpdate,
			image: oc(sectionToUpdate).image()
		}
		return (
			<SectionFormModal
				title={'Rename Section'}
				formResource={formResource}
				submitButtonHandler={this.handleUpdateSection}
				cancelButtonHandler={this.handleCancelUpdateSectionModal}
			/>
		)
	}

	buildDeleteConfirmationModal = () => {
		if (!this.state.sectionToDelete) { return null }
		return (
			<ModalComponent
				modalTitle={'Delete Section'}
				primaryMessage={'Are you sure you want to delete this Section?'}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.closeDeleteSectionModal}
				submitButtonName={'Confirm'}
				submitButtonHandler={this.executeSectionDeletion}
			/>
		)
	}

	buildSectionTable = () => {
		// Check if we have Posts
		const sections = this.getAllSections()
		const sectionCount = sections.length
		const users = oc(this).props.userState.users([])
		const sectionsForTable: SectionTableItem[] = sections.map((section) => {
			return {
				...section,
				statusWithColor: publicationStatusBadge(section.status),
				createdByUser: users.find((user) => `${user._id}` === `${section.createdBy}`)
			}
		}).sort(sortByOrderingIndex)

		// Do we need to reorder the list?
		let orderedSections = []
		if (this.state.sectionOrdering) {
			for (const sectionID of this.state.sectionOrdering) {
				const section = sectionsForTable.find((s) => `${s._id}` === sectionID)
				if (!isNullOrUndefined(section)) {
					orderedSections.push(section)
				}
			}
		} else {
			orderedSections = sectionsForTable
		}

		return (
			<TableComponent<SectionTableItem>
				columns={SectionTableColumns}
				rowItems={orderedSections}
				showPaging={true}
				currentPage={this.state.pageIndex}
				pageHandler={this.handlePage}
				totalResults={sectionCount}
				pageSize={this.pageSize}
				actionHandler={this.handleTableHeaderAction}
				dropdownHandler={this.handleTableDropdownAction}
				onTableRowClick={this.handleTableRowClicked}
				enableDragging={true}
				onDrop={this.handleTableReorder}
			/>
		)
	}

	render() {
		const component = (
			<RS.Row className='justify-content-center section-view-row'>
				<RS.Col className='no-padding'>
					{this.buildPageHeader()}

					<div className='section-content-container'>
						{this.buildSectionTable()}
					</div>

					{this.buildNewSectionModal()}
					{this.buildRenameSectionModal()}
					{this.buildDeleteConfirmationModal()}
				</RS.Col>
			</RS.Row>
		)
		return <DimmedLoader component={component} isLoading={this.state.loading} />
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	getAllSections = () => {
		const publicSections = (this.state.tab === ActiveTab.Public)
		let sections = oc(this).props.sectionState.sections([])
		if (this.props.userState.isAdmin) {
			sections = sections.filter((section: core.Section.Model) => section.public === publicSections)
		}
		return sections
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	sectionState: state.section,
	userState: state.user,
	loggedInClub: state.club.loggedInClub,
})

const mapDispatchToProps = {
	...SectionActions,
	...AlertActions
}

export default connect(mapStateToProps, mapDispatchToProps)(ClubSectionTableComponent)
