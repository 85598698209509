// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { isNullOrUndefined } from 'util'
import { Link, RouteComponentProps } from 'react-router-dom'
import { FormikProps, FormikValues, FormikActions } from 'formik'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies

// Images
// tslint:disable-next-line
const clubhubLogo = require('../../assets/images/clubhublogo.png')

// Actions
import { UserActions, AlertActions, ClubActions, InvitationActions } from '../../actions/index'

// State
import { RootReducerState } from '../../reducers'

// Form
import { FormInput } from '../Shared/Form'
import { NewPasswordFormInputs, ForgotPasswordFormInputs } from './form'

// Components
import ErrorComponent from '../Shared/ErrorComponent'
import DimmedLoader from '../Shared/DimmedLoader'
import { FormikComponent, BuildWrappedForm } from '../Shared/Formik'

// Helpers
import { setStateAsync } from '../../helpers/promise'
import { inputValidator } from '../../helpers/validation'
import { getClubDomain } from '../../helpers/url'
import { imageForResource, ImageSize } from '../../helpers/image'
import * as Constants from '../../constants'

type ConnectedActions = typeof mapDispatchToProps
type ConnectedState = ReturnType<typeof mapStateToProps>

const initialState = {
	clubDomain: null as string | null,
	token: null as string | null,
	inviteCode: null as string | null,
	requestedNewPassword: false,
	successfullyResetPassword: false,
	loading: true,
	error: false,
}

export enum ComponentStateKey {
	ForgotPassword = 'forgot-password',
	RequestedNewPassword = 'requested-new-password',
	SuccessfullyResetPassword = 'successfully-reset-password',
	CreatingNewPassword = 'creating-new-password',
	Onboarding = 'onboarding'
}

type Props = RouteComponentProps & ConnectedActions & ConnectedState
type State = typeof initialState

/**
 * This is a multi purpose component used for:
 * - Forgot password flow.
 * - Reset password flow.
 * - New user account completion (setting account password).
 * In the constructor, route parameters are used to determine which form to display.
 */
class ForgotPasswordComponent extends React.Component<Props, State> {
	private onboarding = false
	constructor(props: Props) {
		super(props)

		// Check for token
		const parsedQuery = queryString.parse(props.location.search)
		// Token is user for forgot password requests.
		const token = parsedQuery.token
		// Invite code is used for invites.
		const inviteCode = parsedQuery.inviteCode
		this.onboarding = props.location.pathname === Constants.SET_PASSWORD_ROUTE && inviteCode

		// Check for subdomain
		const clubDomain = getClubDomain()
		this.state = { ...initialState, token, clubDomain, inviteCode }

		// Check for incomplete URL params
		this.validateQueryParams()
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		// We should be accessing this component via the login screen, so we should already
		// have the list of Clubs. In the event there are no clubs in the state (we got to this
		// component directly), fetch them.
		if (oc(this).props.clubState.clubs([]).length === 0) {
			const [err] = await to(this.props.fetchClubs() as any)
			if (err) {
				// tslint:disable-next-line
				console.error(`Failed to fetch Clubs with error: ${err}`)
				await setStateAsync(this, { error: true, loading: false })
			}
		}

		await setStateAsync(this, { loading: false })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	private submitForm = (values: FormikValues, actions: FormikActions<FormikValues>) => {
		if (!this.state.token && this.state.requestedNewPassword) {
			this.props.history.push(Constants.LOGIN_ROUTE)
			return
		} else if (this.state.successfullyResetPassword) {
			this.props.history.push(Constants.LOGIN_ROUTE)
			return
		}
		const [isValid, invalidMessage] = this.validateForm(values)
		if (!isValid) {
			const message = (invalidMessage !== '') ? invalidMessage : 'The form is invalid'
			this.props.fireFlashMessage(message, Constants.FlashType.WARNING)
			actions.setSubmitting(false)
			return
		}

		if (this.onboarding) {
			return this.submitOnboarding(values)
		} else if (this.state.token) {
			return this.submitPasswordResetForm(values)
		} else {
			return this.submitPasswordRequestForm(values)
		}
	}

	private submitPasswordRequestForm = async (values: FormikValues) => {
		// Hit the route to get a token texted/emailed
		await setStateAsync(this, { loading: true })

		const clubDomain: string = (this.state.clubDomain) ?
			this.state.clubDomain :
			this.props.clubState.clubs.find((club) => club.name === oc<{ [key: string]: { value: any } }>(values).club.value()).domain

		const [err] = await to(this.props.requestNewPassword(values.email, clubDomain) as any)
		if (err) {
			this.props.fireFlashMessage(`${err.message}`, Constants.FlashType.WARNING)
			await setStateAsync(this, { loading: false })
			return
		}

		await setStateAsync(this, { requestedNewPassword: true, loading: false })
	}

	private submitPasswordResetForm = async (values: FormikValues) => {
		// Hit the reset route with token and password
		await setStateAsync(this, { loading: true })
		const [resetPasswordErr] = await to(this.props.resetPassword(this.state.token, values.newPassword) as any)
		if (resetPasswordErr) {
			this.props.fireFlashMessage(`Failed to reset password. ${resetPasswordErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false })
			return
		}

		await setStateAsync(this, { loading: false, successfullyResetPassword: true })
	}

	private submitOnboarding = async (values: FormikValues) => {
		await setStateAsync(this, { loading: true })

		// Get the invite token off of the URL
		const parsedQuery = queryString.parse(this.props.location.search)
		const [completeOnboardingErr] = await to(this.props.acceptInvitation(parsedQuery.inviteCode, values.newPassword) as any)
		if (completeOnboardingErr) {
			this.props.fireFlashMessage(`Failed to complete onboarding. ${completeOnboardingErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false })
			return
		}

		this.props.fireFlashMessage(`You've successfully setup your account! You can now login to the app.`, Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false })
		this.props.history.push(Constants.LOGIN_ROUTE)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	private validateForm = (values: FormikValues): [boolean, string] => {
		return (this.state.token || this.onboarding) ? this.validatePasswordResetForm(values) : [true, '']
	}

	private validatePasswordResetForm = (values: FormikValues): [boolean, string] => {
		const validInputs = inputValidator(values.newPassword) && inputValidator(values.newPasswordConfirm)
		if (!validInputs) {
			return [false, 'Must enter the new password in each field of the form']
		}

		const passwordsMatch = values.newPassword === values.newPasswordConfirm
		if (!passwordsMatch) {
			return [false, 'The passwords you entered do not match']
		}

		return [validInputs && passwordsMatch, '']
	}

	/**
	 * Validates that required URL params are included.
	 * If any ara missing, the user is redirected to the login component.
	 * EX: The set password route without a reset token query param.
	 */
	validateQueryParams = () => {
		const missingToken = (!this.state.token && !this.state.inviteCode)
		if (this.props.location.pathname === Constants.SET_PASSWORD_ROUTE && missingToken) {
			this.props.history.push(Constants.LOGIN_ROUTE)
		}
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildLogo = () => {
		// If we don't have a domain, return the default
		if (!this.state.clubDomain) { return <img src={clubhubLogo} /> }

		// Find the Club by domain, and get its logo
		const clubState = this.props.clubState
		const clubs = oc(clubState).clubs([])
		const clubByDomain = clubs.find((club) => club.domain === this.state.clubDomain)
		if (clubByDomain) {
			return (<img src={imageForResource(clubByDomain.image, clubByDomain, ImageSize.Medium)} />)
		}

		// We didn't find the Club, return the default
		return <img src={clubhubLogo} />
	}

	buildContent = (state: ComponentStateKey) => {
		const content = this.buildForm(state)

		return (
			<div className='forgot-password-container container-fluid'>
				<div className='inner-container container-fluid'>
					<RS.Row className='forgot-password-container-row'>
						<RS.Col xs={12} className='forgot-password-header-col'>
							{this.buildLogo()}
						</RS.Col>
					</RS.Row>

					<DimmedLoader component={content} isLoading={this.state.loading} />
				</div>
			</div>
		)
	}

	buildForm = (state: ComponentStateKey) => {
		const inputs = this.buildInputs(state)
		return (
			<RS.Row className='forgot-password-container-row'>
				<RS.Col xs={12} className='forgot-password-col'>
					<FormikComponent
						inputs={inputs}
						enableReinitialize={false}
						onSubmit={this.submitForm}
						render={(formikProps) => this.buildFormContent(formikProps, inputs, state)}
					/>
				</RS.Col>
			</RS.Row>
		)
	}

	buildFormContent = (formikProps: FormikProps<FormikValues>, inputs: FormInput[], state: ComponentStateKey) => {
		const formHeaderContent = this.buildFormHeader(state)
		const buttonLoadingClass = (this.state.loading) ? `btn-loading` : ``

		const wrappedFormProps = { inputs, submitOnEnter: true }
		const disabled = (inputs.length > 0) ? !formikProps.isValid : false
		return (
			<div>
				{formHeaderContent}
				{BuildWrappedForm(wrappedFormProps, formikProps)}
				<button
					className={`submit-btn btn btn-primary btn-block ${buttonLoadingClass}`}
					onClick={formikProps.submitForm}
					disabled={disabled}
				>
					{this.getButtonText(state)}
				</button>
			</div>
		)
	}

	buildInputs = (state: ComponentStateKey) => {
		switch (state) {
			case ComponentStateKey.RequestedNewPassword:
			case ComponentStateKey.SuccessfullyResetPassword:
				return []
			case ComponentStateKey.CreatingNewPassword:
				return NewPasswordFormInputs()
			case ComponentStateKey.Onboarding:
				return NewPasswordFormInputs()
			default:
				return ForgotPasswordFormInputs()
		}
	}

	buildFormHeader = (state: ComponentStateKey) => {
		switch (state) {
			case ComponentStateKey.RequestedNewPassword:
				return this.buildRequestedPassword()
			case ComponentStateKey.SuccessfullyResetPassword:
				this.props.fireFlashMessage('You successfully reset your password.', Constants.FlashType.SUCCESS)
				return this.buildSuccessfullyResetPassword()
			case ComponentStateKey.CreatingNewPassword:
				return this.buildNewPasswordFormHeader()
			case ComponentStateKey.Onboarding:
				return this.buildNewWelcomeHeader()
			case ComponentStateKey.ForgotPassword:
			default:
				return this.buildForgotPasswordFormHeader()
		}
	}

	getButtonText = (state: ComponentStateKey) => {
		switch (state) {
			case ComponentStateKey.RequestedNewPassword:
			case ComponentStateKey.SuccessfullyResetPassword:
				return 'Back to Login'
			case ComponentStateKey.CreatingNewPassword:
			case ComponentStateKey.Onboarding:
				return 'Submit'
			case ComponentStateKey.ForgotPassword:
			default:
				return 'Send Reset Email'
		}
	}

	buildForgotPasswordFormHeader = () => {
		const message = this.state.clubDomain ?
			'Enter your email address below and a password reset link will be sent to you.' :
			'Select a club, enter your email address and a password reset link will be sent to you. '
		return (
			<div className='form-header-container'>
				<h3>Forgot Password</h3>
				<p className='text-muted'>{message}</p>
			</div>
		)
	}

	buildRequestedPassword = () => {
		const title = 'Password Reset Email Sent'
		const subtitle = 'A password reset link has been sent to you. Look for it in your email or text messages.'
		return this.buildFormHeaderContent(title, subtitle)
	}

	buildSuccessfullyResetPassword = () => {
		const title = 'Password Reset Success'
		const subtitle = 'You successfully reset your password. Click the button below to login.'
		return this.buildFormHeaderContent(title, subtitle)
	}

	buildNewPasswordFormHeader = () => {
		const title = 'Set New Password'
		const subtitle = 'Enter a new password for your account.'
		return this.buildFormHeaderContent(title, subtitle)
	}

	buildNewWelcomeHeader = () => {
		let title = 'Welcome new member'
		if (this.state.clubDomain) {
			const clubForDomain = this.props.clubState.clubs.find((c) => c.domain === this.state.clubDomain)
			title = `Welcome to ${clubForDomain.name}`
		}
		const subtitle = 'Create a password to complete your account setup.'
		return this.buildFormHeaderContent(title, subtitle)
	}

	buildFormHeaderContent = (title: string, subtitle: string) => {
		return (
			<div className='form-header-container'>
				<h3>{title}</h3>
				<p className='text-muted'>{subtitle}</p>
			</div>
		)
	}

	render() {
		if (this.state.loading) { return <DimmedLoader component={null} isLoading={true} /> }
		if (this.state.error) { return <ErrorComponent club={null} isAdmin={false} /> }

		const componentState = this.getComponentState()
		return this.buildContent(componentState)
	}

	getComponentState = () => {
		if (this.state.successfullyResetPassword) {
			return ComponentStateKey.SuccessfullyResetPassword
		}

		if (!this.state.token && this.state.requestedNewPassword) {
			return ComponentStateKey.RequestedNewPassword
		}

		if (this.state.token) {
			return ComponentStateKey.CreatingNewPassword
		}

		if (this.onboarding) {
			return ComponentStateKey.Onboarding
		}

		return ComponentStateKey.ForgotPassword
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	clubState: state.club,
	userState: state.user,
	loggedInClub: state.club.loggedInClub
})

const mapDispatchToProps = {
	...ClubActions,
	...UserActions,
	...AlertActions,
	...InvitationActions
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordComponent)
