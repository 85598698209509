// External Dependencies
import Fuse, { FuseOptions } from 'fuse.js'

export interface FuseItem {
	_id: string
	searchString: string
}

export const buildFuseSearch = <T>(items: T[]): Fuse<T, FuseOptions<T>> => {
	// Set Fuse options.
	const fuseOptions: Fuse.FuseOptions<T> = {
		caseSensitive: false,
		shouldSort: true,
		threshold: 0.2,
		location: 0,
		distance: 200,
		maxPatternLength: 32,
		minMatchCharLength: 1,
		keys: ['searchString' as keyof T]
	}
	return new Fuse(items, fuseOptions)
}
