// External Dependencies
import * as React from 'react'
import { groupBy } from 'underscore'
import { Typeahead, Menu, MenuItem, Highlighter, TypeaheadResult, TypeaheadModel } from 'react-bootstrap-typeahead'
import { PropsFor } from '../../../helpers/interface'
import { oc } from 'ts-optchain'

interface ComponentProps {
	dataIsGrouped?: boolean
	className?: string
}

type Props = PropsFor<typeof Typeahead> & ComponentProps

class TypeaheadComponent extends React.Component<Props> {
	private typeahead: any

	constructor(props: Props) {
		super(props)
	}

	buildGroupedMenu = (results: Array<TypeaheadResult<TypeaheadModel> & { paginationOption: boolean }>, menuProps: any): React.ReactNode => {
		let idx = 0

		// Determine if the results contain a pagination option -- only take the relevant options
		const hasPagination = oc(results)[results.length - 1].paginationOption(false)
		const resultsToGroup = (hasPagination) ? results.slice(0, results.length - 1) : results

		// Group the options by their 'group' field
		const grouped = groupBy(resultsToGroup, (r: TypeaheadModel) => (r as any).group)
		const items = Object.keys(grouped).sort().map((group: string) => [
			!!idx && <Menu.Divider key={`${group}-divider`} />,
			<Menu.Header key={`${group}-header`}>{group}</Menu.Header>,
			...(grouped[group].map((item: TypeaheadModel) => {
				const result = (
					<MenuItem key={idx} option={item} position={idx}>
						<Highlighter search={menuProps.text || ''}>{(item as any).label}</Highlighter>
					</MenuItem>
				)
				idx++
				return result
			}))
		])

		const paginationItem = (
			<>
				<Menu.Divider key='pagination-item-divider' />
				<MenuItem
					className='rbt-menu-pagination-option'
					key={idx}
					option={results[results.length - 1]}
					position={idx}
				>
					{'Show more results...'}
				</MenuItem>
			</>
		)

		return (
			<Menu {...menuProps}>
				{items}
				{(hasPagination) ? paginationItem : null}
			</Menu>
		)
	}

	buildTypeaheadComponent = () => {
		const classProps = { className: this.props.className }
		const renderMenuProps = (this.props.dataIsGrouped) ? ({ renderMenu: this.buildGroupedMenu }) : undefined
		return (
			<Typeahead
				ref={(typeahead) => this.typeahead = typeahead}
				id={this.props.id}
				options={this.props.options}
				disabled={this.props.disabled}
				clearButton={this.props.clearButton}
				onChange={this.props.onChange}
				selected={this.props.selected}
				defaultSelected={this.props.defaultSelected}
				defaultInputValue={this.props.defaultInputValue}
				placeholder={this.props.placeholder}
				maxResults={this.props.maxResults}
				multiple={this.props.multiple}
				inputProps={{ autoComplete: 'new-password' }}
				{...classProps}
				{...renderMenuProps}
			/>
		)
	}

	render() {
		return this.buildTypeaheadComponent()
	}
}

export default TypeaheadComponent
