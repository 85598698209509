import * as core from 'club-hub-core'
import to from 'await-to-js'

// API Helpers
import { POST_FORM } from '../helpers/api'

/**
 * Uploads an image to the generic image bucket for a club.
 * Returns the URL where the image was uploaded.
 * @param imageData The image data to upload.
 */
const createImage = async (imageData: FormData): Promise<core.SubModels.Image.Model> => {
	const [createErr, res] = await to(POST_FORM('/admin/images', imageData))
	if (createErr) {
		// tslint:disable-next-line
		console.error(`Failed to upload image with error: ${createErr}`)
		throw createErr
	}
	return res
}

/**
 * Uploads a PDF image to the generic bucket for a club.
 * Returns the images that represent the PDF
 * @param imageData The image data to upload.
 */
const createPdfImage = async (imageData: FormData): Promise<{ pdfUrl: string, imageBuffer: Buffer, images: core.SubModels.Image.Model }> => {
	const [createErr, res] = await to(POST_FORM('/admin/images/pdf', imageData))
	if (createErr) {
		// tslint:disable-next-line
		console.error(`Failed to upload image with error: ${createErr}`)
		throw createErr
	}
	return res
}

export const ImageActions = {
	createImage,
	createPdfImage
}
