// External Dependencies
import * as React from 'react'
import * as Yup from 'yup'
import * as core from 'club-hub-core'
import { FormikProps, withFormik, WithFormikConfig } from 'formik'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { isNullOrUndefined } from 'util'
import to from 'await-to-js'
import { oc } from 'ts-optchain'

// Internal Dependencies
import { FormInput, FormInputType } from '../Form'

// Actions
import { QueryFilterActions, AlertActions, GroupActions } from '../../../actions/index'

// State
import { RootReducerState } from '../../../reducers'
import { getFilterByID } from '../../../reducers/queryFilter'

// Components
import FilterCreationSection from './FilterCreationSection'
import FilterSection from './FilterSection'
import FormModal from '../Formik/FormModal'
import ModalComponent from '../Modal'

// Form
import { QueryFilterFormState, FilterCreationInitialFormState, QueryFilterFieldAttribute } from './form'

// Helpers
import { setStateAsync } from '../../../helpers/promise'
import * as Constants from '../../../constants'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

interface ComponentProps {
	activeFilterID?: string
	pendingFilterPredicates?: core.QueryFilter.Predicate[]
	queryFields: QueryFilterFieldAttribute[]
	queryResource: string
	onSaveFilter: (filterID: string) => Promise<any>
	onClearFilter: () => Promise<any>
	onResetFilter: () => Promise<any>
	onUpdatePendingFilters: (queryFilter: core.QueryFilter.Model, pendingFilters: core.QueryFilter.Predicate[]) => Promise<any>
}

// State Key Constants
type ModalStateKey = typeof ShowingFilterNameModal | typeof ShowingFilterDeletionModal | typeof ShowingGroupCreationModal
const ShowingFilterNameModal = 'showingFilterNameModal'
const ShowingFilterDeletionModal = 'showingFilterDeletionModal'
const ShowingGroupCreationModal = 'showingGroupCreationModal'

const initialState = {
	editingActiveFilter: false,
	deletingActiveFilter: false,
	[ShowingFilterNameModal]: false,
	[ShowingFilterDeletionModal]: false,
	[ShowingGroupCreationModal]: false
}

type OuterProps = ComponentProps & ConnectedActions & ConnectedState
type Props = OuterProps & FormikProps<QueryFilterFormState>
type State = typeof initialState

class QueryFilterComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
	}

	async componentDidMount() {
		const { activeFilterID, pendingFilterPredicates } = this.props

		// Check if we are editing the active QueryFilter
		const hasActiveFilter = !isNullOrUndefined(activeFilterID)
		const pendingPredicates = oc(pendingFilterPredicates)([])
		if (hasActiveFilter && pendingPredicates.length > 0) {
			await setStateAsync(this, { editingActiveFilter: true })
		}
	}

	componentDidUpdate(prevProps: Props) {
		if (!prevProps.isSubmitting && this.props.isSubmitting) {
			this.handleFormSubmit()
			this.props.setSubmitting(false)
		}

		// Check if Pending Filters have changed
		const prevPending = oc(prevProps).values.pendingFilters([])
		const currentPending = oc(this).props.values.pendingFilters([])
		if (prevPending.length !== currentPending.length && currentPending.length > 0) {
			const payload = this.buildFilterPayload('pending', this.props.values)
			this.props.onUpdatePendingFilters(payload, [...this.props.values.pendingFilters])
		}
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	handleShowModal = async (modalStateKey: ModalStateKey) => {
		await setStateAsync(this, (() => ({ [modalStateKey]: true })))
	}

	handleCloseModal = async (modalStateKey: ModalStateKey) => {
		await setStateAsync(this, (() => ({
			[modalStateKey]: false,
			deletingActiveFilter: false,
		})))
	}

	/**
	 * Called when the User clicks the 'Create Group' button in the ActiveFilterSection.
	 */
	handleCreateGroupWithActiveFilter = async () => {
		await this.handleShowModal(ShowingGroupCreationModal)
	}

	/**
	 * Called when the User clicks the 'Edit Filters' button in the ActiveFilterSection.
	 * Updates the Formik state so that the active filters are pre-filled into the
	 * PendingFilterSection component.
	 */
	handleEditActiveFilter = async () => {
		// Move the Active Filters into the Pending Filters list
		const newPendingFilters = [...this.props.values.activeFilters]
		this.props.setValues({ ...this.props.values, pendingFilters: newPendingFilters })
		await setStateAsync(this, { editingActiveFilter: true })
	}

	/**
	 * Called when the User clicks the 'Delete Filters' button in the ActiveFilterSection.
	 */
	handleDeleteActiveFilter = async () => {
		await this.handleShowModal(ShowingFilterDeletionModal)
		await setStateAsync(this, { deletingActiveFilter: true })
	}

	/**
	 * Called when the User submits the form from the PendingFilterSection component.
	 * If we are creating a new Filter (i.e. not editing an active one), we need to show
	 * the User a model, where they can enter the name of the Filter. If we are editing
	 * a Filter, we can trigger the save function.
	 */
	handleFormSubmit = async () => {
		// If we are creating a new Filter, display the Filter Name modal
		if (!this.state.editingActiveFilter) {
			await this.handleShowModal(ShowingFilterNameModal)
			return
		}
		return this.executeSaveFilter(this.props.activeFilter.name)
	}

	/**
	 * Called when we are ready to save a Filter. Calls the appropriate API action, and
	 * displays a flash message on success/failure.
	 */
	executeSaveFilter = async (filterName: string) => {
		const payload = this.buildFilterPayload(filterName, this.props.values)
		const saveFunction = (this.state.editingActiveFilter) ?
			this.props.updateFilter(`${this.props.activeFilter._id}`, payload) :
			this.props.createFilter(payload)

		const [err, filterRes] = await to(saveFunction as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to save Filters. ${err.message}`, Constants.FlashType.DANGER)
			return
		}

		// Close the Modal
		await this.handleCloseModal(ShowingFilterNameModal)

		// Reset the state
		await setStateAsync(this, { editingActiveFilter: false })
		this.props.fireFlashMessage(`Successfully saved Filters.`, Constants.FlashType.SUCCESS)

		const filterID = `${filterRes._id}`
		await this.props.onSaveFilter(filterID)
	}

	/**
	 * Called when we are ready to delete a Filter. Calls the appropriate API action, and
	 * displays a flash message on success/failure.
	 */
	executeFilterDeletion = async () => {
		const filterID = oc(this).props.activeFilter._id()

		const [deleteErr] = await to(this.props.deleteFilter(`${filterID}`) as any)
		if (deleteErr) {
			this.props.fireFlashMessage(`Problem trying to delete Filter. ${deleteErr.message}`, Constants.FlashType.DANGER)
			return
		}

		await this.handleCloseModal(ShowingFilterDeletionModal)
		this.props.fireFlashMessage(`Successfully deleted Filter.`, Constants.FlashType.SUCCESS)
		await this.props.onClearFilter()
	}

	/**
	 * Called when we are ready to create a UserGroup based on a Filter. Calls the appropriate API action,
	 * and displays a flash message on success/failure.
	 */
	executeCreateGroup = async (name: string, description: string) => {
		// Create the payload that will be used to create a new User Group
		const queryFilter = oc(this).props.activeFilter._id()
		const payload: core.User.UserGroup = {
			name,
			description,
			queryFilter
		}

		const clubID = oc(this).props.loggedInClub._id()
		const [createErr] = await to(this.props.createUserGroup(`${clubID}`, payload) as any)
		if (createErr) {
			this.props.fireFlashMessage(`Problem trying to create Group for Filter. ${createErr.message}`, Constants.FlashType.DANGER)
			return
		}

		// Close the Modal
		await this.handleCloseModal(ShowingGroupCreationModal)
		this.props.fireFlashMessage(`Successfully created Group for Filter.`, Constants.FlashType.SUCCESS)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	buildFilterPayload = (filterName: string, formState: QueryFilterFormState) => {
		const predicates: core.QueryFilter.NestedPredicate = {
			type: core.QueryFilter.NestingType.and,
			predicates: formState.pendingFilters
		}

		const payload: core.QueryFilter.Model = {
			name: filterName,
			resource: this.props.queryResource,
			predicates: [predicates],
		} as any
		return payload
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	/**
	 * Builds a form modal, where the User can supply the name for the Filter
	 * they are going to save.
	 */
	buildFilterNameModal = () => {
		if (!this.state.showingFilterNameModal) { return null }
		const formSpec: FormInput[] = [
			{
				title: 'Name',
				property: 'name',
				type: FormInputType.TEXT,
				placeholder: 'Enter name...',
				defaultValue: oc(this).props.activeFilter.name(''),
				validation: Yup.string().required(Constants.REQUIRED_FIELD)
			}
		]
		return (
			<FormModal<{ name: string }, { name: string }>
				modalTitle={'Filter Name'}
				formSpec={formSpec}
				submitButtonHandler={({ name }) => this.executeSaveFilter(name)}
				cancelButtonHandler={() => this.handleCloseModal(ShowingFilterNameModal)}
			/>
		)
	}

	/**
	 * Builds a form modal, where the User can supply the name for the Filter
	 * they are going to save.
	 */
	buildGroupCreationModal = () => {
		if (!this.state.showingGroupCreationModal) { return null }
		const formSpec: FormInput[] = [
			{
				title: 'Name',
				property: 'name',
				type: FormInputType.TEXT,
				placeholder: 'Enter name...',
				defaultValue: oc(this).props.activeFilter.name(''),
				validation: Yup.string().required(Constants.REQUIRED_FIELD)
			},
			{
				title: 'Description',
				property: 'description',
				type: FormInputType.TEXT_AREA,
				placeholder: 'Enter description...',
				defaultValue: '',
			}
		]
		return (
			<FormModal<{ name: string, description: string, }, { name: string, description: string }>
				modalTitle={'Create User Group'}
				formSpec={formSpec}
				submitButtonHandler={({ name, description }) => this.executeCreateGroup(name, description)}
				cancelButtonHandler={() => this.handleCloseModal(ShowingGroupCreationModal)}
			/>
		)
	}

	/**
	 * Builds a modal, where the User can confirm that they want to delete the active Filter.
	 */
	buildFilterDeletionModal = () => {
		if (!this.state.showingFilterDeletionModal || !this.state.deletingActiveFilter) { return null }
		return (
			<ModalComponent
				modalTitle={'Delete Filter'}
				primaryMessage={'Are you sure you want to delete this Filter?'}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={() => this.handleCloseModal(ShowingFilterDeletionModal)}
				submitButtonName={'Confirm'}
				submitButtonHandler={this.executeFilterDeletion}
			/>
		)
	}

	buildContent = () => {
		const sections = (this.props.activeFilter && !this.state.editingActiveFilter) ?
			(
				<FilterSection
					showingActive={true}
					queryFields={this.props.queryFields}
					onCreateGroup={this.handleCreateGroupWithActiveFilter}
					onEdit={this.handleEditActiveFilter}
					onDelete={this.handleDeleteActiveFilter}
					onClear={this.props.onClearFilter}
				/>
			) :
			(
				<>
					<FilterCreationSection
						queryFields={this.props.queryFields}
						onCancel={this.props.onClearFilter}
					/>
					<FilterSection
						queryFields={this.props.queryFields}
						onClear={this.props.onResetFilter}
					/>
				</>
			)

		return (
			<>
				<div className='query-filter-container'>
					<div className='query-filter-outer-section'>
						{sections}
					</div>
				</div>
				{this.buildFilterNameModal()}
				{this.buildGroupCreationModal()}
				{this.buildFilterDeletionModal()}
			</>
		)
	}

	render() {
		return this.buildContent()
	}
}

const mapStateToProps = (state: RootReducerState, ownProps: ComponentProps) => ({
	queryFilterState: state.queryFilter,
	activeFilter: getFilterByID(state, { filterID: ownProps.activeFilterID }),
	loggedInClub: state.club.loggedInClub,
})

const mapDispatchToProps = {
	...QueryFilterActions,
	...AlertActions,
	...GroupActions,
}

const withFormikOptions: WithFormikConfig<OuterProps, QueryFilterFormState> = {
	handleSubmit: (values, formikBag) => {
		formikBag.setSubmitting(true)
	},
	mapPropsToValues: (props: OuterProps) => {
		const activeQueryFilter = props.activeFilter
		const predicates: core.QueryFilter.Predicate[] = (oc(activeQueryFilter).predicates[0] as any).predicates([])
		return {
			...FilterCreationInitialFormState,
			activeFilters: [...predicates],
			pendingFilters: [...props.pendingFilterPredicates]
		}
	},
	enableReinitialize: true,
}

const enhance = compose<React.ComponentType<ComponentProps>>(
	connect(mapStateToProps, mapDispatchToProps),
	withFormik<OuterProps, QueryFilterFormState>(withFormikOptions)
)

export default enhance(QueryFilterComponent)
