// External Dependencies
import * as core from 'club-hub-core'

/**
 * Returns the most specific set of Reservation settings based on the given start date.
 * The shorter the range is, the more specific.
 * @param reservationSettings A list of Reservation Settings
 * @param start The start date
 * @returns The most specific Reservation Setting
 */
export const getMostSpecificSetting = (reservationSettings: core.Calendar.ReservationSetting[], start: Date) => {
	let smallestRange
	let mostSpecificSetting
	for (const setting of reservationSettings) {
		// If we found the default setting, and we don't have
		// smallest range, then set the default to be the
		// most specific setting to this point
		if (setting.isDefault && !smallestRange) {
			mostSpecificSetting = setting
			continue
		}

		// Calculate the length of the current setting's date range
		const settingStart = new Date(setting.dateRangeStart)
		const settingEnd = new Date(setting.dateRangeEnd)
		const rangeLength = settingEnd.valueOf() - settingStart.valueOf()

		// Determine if the Reservation is in the current range
		const inRange = (start >= settingStart) && (start <= settingEnd)
		if (!inRange) { continue }

		// If we don't have a current smallest range set,
		// start with the current range
		if (!smallestRange) {
			smallestRange = rangeLength
			mostSpecificSetting = setting
			continue
		}

		// If we found a new smallest range, update the variables
		if (rangeLength < smallestRange) {
			smallestRange = rangeLength
			mostSpecificSetting = setting
		}
	}
	return mostSpecificSetting
}
