// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import classNames from 'classnames'
import { DragDropContext, Droppable, Draggable, DroppableProvided, DraggableProvided, DraggableStateSnapshot, DropResult, ResponderProvided } from 'react-beautiful-dnd'
import { Icon } from 'react-feather'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'

// Internal Dependencies

// Components
import PaginationComponent from '../Pagination'
import DimmedLoader from '../DimmedLoader'
import CheckboxComponent from '../Checkbox'

// Primitives
import { TableColumnType, TablePrimitiveMap, BaseTablePrimitiveProps } from './Primitives'

// Helpers
import { setStateAsync } from '../../../helpers/promise'

export interface DropdownItem {
	action: string
	valueProperty: string
	displayText: string
	icon?: Icon
	isEnabled?: string
	isVisible?: string
}

export interface TableColumn {
	title: string,
	property: string,
	type: TableColumnType,
	hideOnMobile?: boolean,
	headerClass?: string,
	class?: string,
	action?: string,
	dropdownItems?: DropdownItem[],
}

interface TableProps<T> {
	// Data
	columns: TableColumn[]
	rowItems: T[]

	// Pagination
	showPaging: boolean
	currentPage?: number
	totalResults?: number
	pageSize?: number
	pageHandler?: any

	// UI
	isLoading?: boolean
	striped?: boolean
	checkedRowMap?: { [key: string]: T }
	checkedAllRows?: boolean

	// Event Handlers
	actionHandler?: any
	dropdownHandler?: any
	onTableRowClick?: (item: T) => any
	onCheckboxChange?: (selectedMap: T) => any
	onSelectAll?: (selectingAll: boolean) => any

	// Drag and Drop
	enableDragging?: boolean
	onDrop?: (result: DropResult) => any
}

const initialState = {
	isDragging: false
}

type Props<T> = TableProps<T>
type State = typeof initialState

export default class TableComponent<T extends { _id?: any }> extends React.Component<Props<T>, State> {
	private tableRef: HTMLElement

	constructor(props: Props<T>) {
		super(props)
		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	handlePage = async (data: any) => {
		return this.props.pageHandler(data)
	}

	handleHeaderCheckbox = async () => {
		const { onSelectAll, checkedAllRows } = this.props
		if (onSelectAll) {
			onSelectAll(!checkedAllRows)
		}
	}

	handleRowCheckbox = async (item: T) => {
		const { onCheckboxChange } = this.props
		if (onCheckboxChange) {
			onCheckboxChange(item)
		}
	}

	handleRowClick = async (e: React.MouseEvent<HTMLTableRowElement>, item: T) => {
		return (this.props.onTableRowClick) ? this.props.onTableRowClick(item) : null
	}

	handleOnDragStart = async () => {
		await setStateAsync(this, { isDragging: true })
	}

	handleOnDragEnd = async (result: DropResult, provided: ResponderProvided) => {
		await setStateAsync(this, { isDragging: false })
		this.props.onDrop(result)
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildTableHeaders = () => {
		const { columns, actionHandler, checkedAllRows, checkedRowMap } = this.props

		return columns.map((header: TableColumn, idx: number) => {
			const headerClass = classNames({
				'hide-on-mobile': header.hideOnMobile,
				[header.headerClass]: !isNullOrUndefined(header.headerClass)
			})
			const headerAction = (header.action) ? header.action : null
			// Check if the column is a Checkbox
			if (header.type === TableColumnType.CHECKBOX) {
				if (isNullOrUndefined(checkedRowMap)) { return null }

				return (
					<th className={`checkbox-header ${headerClass}`} key={`header-checkbox_${idx}`}>
						<CheckboxComponent
							className={'table-checkbox'}
							isChecked={checkedAllRows}
							onChange={this.handleHeaderCheckbox}
						/>
					</th>
				)
			}

			return (
				<th className={headerClass} key={`table-header-${header.title}-${idx}`} defaultValue={headerAction} onClick={actionHandler}>
					{header.title}
				</th>
			)
		})
	}

	buildTableRows = () => {
		const { columns, rowItems } = this.props

		return rowItems.map((rowItem: T, index: number) => {
			return this.buildTableRow(rowItem, columns, index)
		})
	}

	buildTableRow = (item: T, columns: TableColumn[], index: number) => {
		const rowCells = columns.map((column) => this.buildTableCell(item, column, index))
		const rowClass = `custom-table-row ${(this.props.onTableRowClick) ? 'table-row-clickable' : ''}`
		const canDrag = oc(this).props.enableDragging(false)

		return (
			// TODO: We need to determine what the draggableId will be
			// as a general rule. For now, we will just assume that draggable
			// rows will be built on items that have a unique `_id` field
			<Draggable
				draggableId={item._id}
				index={index}
				key={item._id}
				isDragDisabled={!canDrag}
			>
			{(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
				<tr
					className={classNames(rowClass, { 'is-dragging': snapshot.isDragging })}
					key={`tableRow${index}`}
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					onClick={(e) => {
						// Check to see if we are dragging
						// https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/how-we-use-dom-events.md#event-prevention
						if (e.defaultPrevented) {
							return
						}
						this.handleRowClick(e, item)
					}}
				>
					{rowCells}
				</tr>
			)}
			</Draggable>
		)
	}

	buildTableCell = (item: T, column: TableColumn, index: number) => {
		const { dropdownHandler, checkedRowMap } = this.props
		const { isDragging } = this.state

		let className = (column.class) ? column.class : ''
		className = (column.hideOnMobile) ? `${className} hide-on-mobile` : className

		// Pick the Table Primitive based on the column type
		const TablePrimitive = TablePrimitiveMap[column.type]

		// Set up the props for the Table Primitive
		const basePrimitiveProps: BaseTablePrimitiveProps<T> = { item, column, className, index }

		// Add any additional props we might need
		let additionalProps: any = { isDragOccurring: isDragging }
		if (column.type === TableColumnType.DROPDOWN) {
			additionalProps = { ...additionalProps, dropdownHandler }
		}
		if (column.type === TableColumnType.CHECKBOX) {
			if (isNullOrUndefined(checkedRowMap)) { return null }

			const isChecked = !isNullOrUndefined(checkedRowMap[item._id])
			additionalProps = { ...additionalProps, isChecked, handleRowCheckbox: this.handleRowCheckbox }
		}

		// Return the Table Primitive with the props
		return (
			<TablePrimitive
				key={`tableCell-${column.property}-${column.type}-${index}`}
				{...basePrimitiveProps}
				{...additionalProps}
			/>
		)
	}

	buildPagination = () => {
		const { showPaging, currentPage, pageSize, totalResults } = this.props
		if (!showPaging) {
			return null
		}

		return (
			<PaginationComponent
				initialPage={currentPage}
				currentPage={currentPage}
				pageSize={pageSize}
				totalResults={totalResults}
				onPageChange={this.handlePage}
			/>
		)
	}

	render() {
		const striped = isNullOrUndefined(this.props.striped) ? true : this.props.striped
		const table = (
			<DragDropContext
				onBeforeDragStart={this.handleOnDragStart}
				onDragEnd={this.handleOnDragEnd}
			>
				<RS.Table striped={striped} className='table-content'>
					<thead>
						<tr>
							{this.buildTableHeaders()}
						</tr>
					</thead>
					<Droppable droppableId={'table'}>
					{(droppableProvided: DroppableProvided) => (
						<tbody
							ref={(ref: HTMLElement) => {
								this.tableRef = ref
								droppableProvided.innerRef(ref)
							}}
							{...droppableProvided.droppableProps}
						>
							{this.buildTableRows()}
							{droppableProvided.placeholder}
						</tbody>
					)}
					</Droppable>
				</RS.Table>
			</DragDropContext>
		)

		const pagination = this.props.isLoading ? null : this.buildPagination()

		return (
			<div className='table-component-container'>
				<div className='table-container'>
					<DimmedLoader
						showComponent={false}
						component={table}
						isLoading={this.props.isLoading}
					/>
				</div>
				{pagination}
			</div>
		)
	}
}
