// External Dependencies
import * as React from 'react'
import * as Feather from 'react-feather'
import * as RS from 'reactstrap'
import { oc } from 'ts-optchain'

// Internal Dependencies
import { setStateAsync } from '../../../helpers/promise'

interface ComponentProps {
	isOpen?: boolean
	modalTitle: string
	className?: string
	primaryMessage?: string
	subMessage?: string
	cancelButtonName?: string
	cancelButtonHandler?: any
	submitButtonName?: string
	submitButtonHandler?: any
	secondaryButtonName?: string
	secondaryButtonHandler?: any
	dangerSecondary?: boolean
	submitButtonValidation?: () => boolean
	buildInputs?: () => any // Provided function that will build UI form elements.
	submitting?: boolean
	nestedModal?: any
}

const initialState = {
	modal: true,
	submitting: false
}

type Props = ComponentProps
type State = typeof initialState

/**
 * Returns a modal with two buttons side by side.
 * Pass in the button names and corresponding handlers via the props.
 * If a sub message is passed in, it will display below the primary message.
 * @param props ModalComponentInterface
 */
class ModalComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		const state = { ...initialState }
		state.modal = oc(this).props.isOpen(true)
		this.state = state
	}

	componentDidUpdate = (prevProps: Props) => {
		if (prevProps.isOpen !== this.props.isOpen) {
			this.setState({ modal: oc(this).props.isOpen(true) })
		}
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	handleToggle = async () => {
		await setStateAsync(this, { modal: !this.state.modal })
	}

	handleSubmit = async () => {
		await setStateAsync(this, { submitting: true })
		await this.props.submitButtonHandler()
		await setStateAsync(this, { submitting: false })
	}

	handleCancel = async () => {
		this.props.cancelButtonHandler()
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildModalMessage = (message?: string) => {
		if (!message) { return null }

		return (
			<p className='modal-message'>
				{message}
			</p>
		)
	}

	buildCancelButton = () => {
		return (
			<button
				className='btn cancel'
				hidden={!this.props.cancelButtonName}
				onClick={this.handleCancel}
			>
				{this.props.cancelButtonName}
			</button>
		)
	}

	buildPrimaryButtons = () => {
		const buttonLoading = (this.state.submitting || this.props.submitting) ? 'btn-loading' : ''
		const color = this.props.dangerSecondary ? 'danger' : null
		return (
			<div>
				<RS.Button
					className={`${buttonLoading}`}
					color={color}
					hidden={!this.props.secondaryButtonName}
					onClick={this.props.secondaryButtonHandler}
				>
					{this.props.secondaryButtonName}
				</RS.Button>
				<RS.Button
					className={`submit-button ${buttonLoading}`}
					color={'primary'}
					disabled={(this.props.submitButtonValidation) ? !this.props.submitButtonValidation() : false}
					hidden={!this.props.submitButtonName}
					onClick={this.handleSubmit}
				>
					{this.props.submitButtonName}
				</RS.Button>
			</div >
		)
	}
	render() {
		const className = (this.props.className) ? this.props.className : ''
		return (
			<RS.Modal
				className={`modal-col ${className}`}
				isOpen={this.state.modal}
				toggle={this.handleCancel}
				onClosed={this.handleCancel}
			>
				<div className='custom-modal-title modal-header'>
					<h5 className='modal-title'>{this.props.modalTitle}</h5>
					<button className='close'>
						<Feather.XCircle size={20} onClick={this.handleCancel} />
					</button>
				</div>
				<RS.ModalBody>
					{this.buildModalMessage(this.props.primaryMessage)}
					{this.buildModalMessage(this.props.subMessage)}
					{(this.props.buildInputs) ? this.props.buildInputs() : null}
					{this.props.nestedModal}
				</RS.ModalBody>

				<RS.ModalFooter className='modal-button-container d-flex justify-content-between'>
					{this.buildCancelButton()}
					{this.buildPrimaryButtons()}
				</RS.ModalFooter>
			</RS.Modal>
		)
	}
}

export default ModalComponent
