// Internal Dependencies
import { TableColumn } from '../../Shared/Table'
import { TableColumnType } from '../../Shared/Table/Primitives'

export const GroupSettingsColumns: TableColumn[] = [
	{
		title: 'Name',
		property: 'name',
		type: TableColumnType.TEXT,
	},
	{
		title: 'All',
		property: 'allNotifs',
		type: TableColumnType.CUSTOM_JSX,
	},
	{
		title: 'Push',
		property: 'pushNotifs',
		type: TableColumnType.CUSTOM_JSX,
	},
	{
		title: 'Email',
		property: 'emailNotifs',
		type: TableColumnType.CUSTOM_JSX,
	},
	{
		title: 'Text',
		property: 'smsNotifs',
		type: TableColumnType.CUSTOM_JSX,
	}
]
