// External Dependencies
import * as React from 'react'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { Icon } from 'react-feather'
import { oc } from 'ts-optchain'

// Internal Dependencies
import { FormInputType } from '../Form'

interface ComponentProps extends ReactDatePickerProps {
	disabled?: boolean
	type: FormInputType.DATE | FormInputType.TIME | FormInputType.DATE_TIME
	featherIcon?: Icon
	faIcon?: string
}

type Props = ComponentProps

export default class DatePickerComponent extends React.Component<Props> {
	constructor(props: Props) {
		super(props)
	}

	buildDatePicker = () => {
		const defaultPickerProps = {
			popperModifiers: { flip: { enabled: false } },
			popperPlacement: 'bottom-end',
			isClearable: oc(this).props.isClearable(true),
		}

		let typeSpecificProps = {}
		switch (this.props.type) {
			case FormInputType.TIME:
				typeSpecificProps = {
					showTimeSelectOnly: true,
					showTimeSelect: true,
					timeIntervals: oc(this).props.timeIntervals(10),
					dateFormat: 'h:mm aa'
				}
				break
			case FormInputType.DATE_TIME:
				typeSpecificProps = {
					showTimeSelect: true,
					timeIntervals: oc(this).props.timeIntervals(30),
					dateFormat: 'MM/dd/yyyy h:mm aa',
					timeCaption: 'Time'
				}
				break
			default:
				break
		}
		return (
			<div className='datePicker-container'>
				<DatePicker
					{...this.props}
					{...defaultPickerProps}
					{...typeSpecificProps}
				/>
			</div>
		)
	}

	buildDatePickerWithIcon = () => {
		const FeatherIcon = this.props.featherIcon
		const fontAwesomeIcon = (this.props.faIcon) ? <i className={this.props.faIcon} /> : null
		const icon = (this.props.featherIcon) ? (<FeatherIcon size={18} />) : fontAwesomeIcon
		return (
			<div className='input-group no-wrap'>
				<div className='input-group-prepend'>
					<span className='input-group-text'>
						{icon}
					</span>
				</div>
				<div className='w-100'>
					{this.buildDatePicker()}
				</div>
			</div>
		)
	}

	render() {
		const content = (this.props.featherIcon || this.props.faIcon) ?
			this.buildDatePickerWithIcon() :
			this.buildDatePicker()

		return (
			<div className='datePickerComponent'>
				{content}
			</div>
		)
	}
}
