// External Dependencies
import * as React from 'react'

interface UnderlineHeaderProps {
	title: string
	className?: string
}

/**
 * Returns a header with blue underline. Used in reservation details and card component.
 * @param props PassedPropsInterface
 */
const UnderlineHeader: React.SFC<UnderlineHeaderProps> = (props: UnderlineHeaderProps) => {
	return (
		<div className={`${props.className || ''} underline-header-container`}>
			{props.title}
			<div className='section-underline'/>
		</div>
	)
}

export default UnderlineHeader
