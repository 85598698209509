// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import classNames from 'classnames'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Helpers
import { isValidImage } from '../../../helpers/image'
import { setStateAsync } from '../../../helpers/promise'

// Interfaces
import { LoadedImage, SrcSetData } from '../../Shared/ProgressiveImage'

interface ComponentProps {
	user: core.User.Model
}

type Props = ComponentProps & React.HTMLProps<any>

const initialState = {
	loading: true,
	img: null as string | null,
	imgErr: false
}

type State = typeof initialState

export default class AvatarComponent extends React.Component<Props, State> {
	// Keep track of mountedState
	private isMounted: boolean

	constructor(props: Props) {
		super(props)
		this.isMounted = false
		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		this.isMounted = true
		await this.fetchUserImage()
	}

	componentWillUnmount() {
		this.isMounted = false
	}

	async componentDidUpdate(prevProps: Props) {
		if (`${oc(prevProps).user._id()}` !== `${oc(this).props.user._id()}`) {
			await setStateAsync(this, { img: null, loading: true, imgErr: null })
			await this.fetchUserImage()
		}
	}

	fetchUserImage = async () => {
		const { user } = this.props
		const image = new Image()
		const [imgErr, loadedImage] = await to(this.loadImage(image, oc(user).image.sm()))
		if (imgErr) {
			if (this.isMounted) {
				await setStateAsync(this, { imgErr: true, loading: false })
			}
			return
		}

		if (this.isMounted) {
			await setStateAsync(this, { img: loadedImage.src, loading: false })
		}
	}

	/**
	 * Attempts to load an image by supplied src.
	 * The promise is rejected if the image fails to load.
	 * @returns Loaded Image.
	 */
	loadImage = (image: HTMLImageElement, src: string, srcSetData?: SrcSetData): Promise<LoadedImage> => {
		return new Promise((resolve, reject) => {
			image.src = src
			if (image.complete) {
				return resolve({ src, isCached: true })
			}
			image.onload = () => resolve({ src, isCached: false })
			image.onerror = (err) => reject(err)
			if (srcSetData) {
				image.srcset = srcSetData.srcSet
				image.sizes = srcSetData.sizes
			}
		})
	}

	buildUserAvatar = () => {
		const { user, className } = this.props

		// If we don't have a User, return a placeholder avatar
		const placeholderAvatarClass = classNames(className, 'avatar avatar-placeholder')
		if (isNullOrUndefined(user)) {
			return <span className={placeholderAvatarClass} />
		}

		// If we have a valid User image, return an avatar with the image
		const avatarURL = oc(user).image.sm()
		const valid = isValidImage(avatarURL)

		// If we know the image exists return it.
		if (valid && this.state.img && !this.state.imgErr) {
			const imageAvatarClass = classNames(className, 'avatar')
			return <span className={imageAvatarClass} style={{ backgroundImage: `url(${this.state.img})` }} />
		}

		// If we do not have a valid User image, return a placeholder with their initials
		const hasInitials = user.firstName && user.lastName
		const initials = (hasInitials) ? `${user.firstName[0]}${user.lastName[0]}` : ''
		const initialAvatarClass = classNames(className, 'avatar', { 'avatar-placeholder': !hasInitials })
		return <span className={initialAvatarClass}>{initials}</span>
	}

	render() {
		if (this.state.loading) {
			const { user, className } = this.props
			const placeholderAvatarClass = classNames(className, 'avatar avatar-placeholder')
			return <span className={placeholderAvatarClass} />
		}
		return this.buildUserAvatar()
	}
}
