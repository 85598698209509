// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as RS from 'reactstrap'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'
import scrollIntoView from 'scroll-into-view-if-needed'
import to from 'await-to-js'

// Components
import DiningReservationCellComponent, { DiningReservationCellProps } from './DiningReservationCell'
import PageHeaderComponent from '../../Shared/PageHeader'
import { InputSelectionItem } from '../../Shared/Form'
import { DropdownItem } from '../../Shared/Dropdown'
import DimmedLoader from '../../Shared/DimmedLoader'
import ReservationQueue from '../../Shared/ReservationQueue'
import { HeaderButton, ButtonType } from '../../Shared/ButtonGroup'
import { ConfirmReservationComponent } from '../../ConfirmReservation/OldReservation'

// Forms
import { DinnerReservationFormInputs, DinnerReservationFormState } from '../../Forms/DinnerReservation'
import CustomerDiningForm from '../../ConfirmReservation/DiningForm'
import ReservationForm from '../../Shared/ReservationForm'

// Actions
import { EventActions, AlertActions, CalendarActions } from '../../../actions/index'

// State / Reducers
import { RootReducerState } from '../../../reducers'
import { inCustomerViewSelector } from '../../../reducers/user'

// Page Header Inputs
import * as inputs from './headerInputs'

// Helpers
import * as Constants from '../../../constants'
import { setStateAsync } from '../../../helpers/promise'
import { calendarIDForEvent } from '../../../helpers/event'
import { beginningOfDay, sortDates } from '../../../helpers/date'
import { fullName, userForForm } from '../../../helpers/user'
import { QueryOptions } from '../../../helpers/interface'
import { getMostSpecificSetting } from '../../../helpers/reservation'

// Interfaces
import { ReservationCellAction } from '../shared/ReservationActionButtons'

interface EventWithReservation {
	_id: string
	start: string
	end: string
	reservation: core.Event.Reservation
	availableEvent: core.Event.AvailableEvent
}

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	error: false,
	initialLoad: true,
	loading: false,
	selectedDate: new Date().toISOString() as string | null,
	selectedCalendarID: null as string | null,
	selectedCalendarGroupID: null as string | null,
	showingBookingModal: false,
	bookingModalDate: null as Date | null,
	bookingModalReservationID: null as string | null,
	submittingForm: false,
	selectedEventID: null as string | null
}

type Props = ConnectedState & ConnectedActions & RouteComponentProps
type State = typeof initialState

// Exporting the context so that we can have typings support
// in external files that take in 'this' as a parameter from this component
export type DiningReservationComponentContext = DiningReservationComponent

class DiningReservationComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		// Get the first Calendar Group with a type 'DINING'
		const calendarGroup = this.getCalendarGroup()
		const calendarGroupID = calendarGroup._id

		// Fetch Cals if we are a customer.
		if (this.props.isCustomerView) {
			await this.fetchCalendarsForGroup(`${calendarGroupID}`)
		}

		// Get the Calendars for the Group
		const calendars = oc(this).props.calendarState.calendars([])
		const calendarIDs = calendars.filter((c) => c.groupID === calendarGroupID).map((c) => `${c._id}`)
		const defaultCalendarID = oc(calendarIDs)[0]()

		// Check if there was a date specified in the query params
		const parsedQuery = queryString.parse(this.props.location.search)
		const defaultDate = oc(parsedQuery as { date: string }).date(this.state.selectedDate)
		const start = beginningOfDay(defaultDate).toISOString()
		await this.fetchBookableEvents(start, defaultCalendarID)

		const newState = {
			initialLoad: false,
			selectedCalendarGroupID: `${calendarGroupID}`,
			selectedCalendarID: defaultCalendarID,
			selectedDate: start,
		}
		await setStateAsync(this, newState)

		// Scroll the current time into view
		const currentTimeCell = document.getElementsByClassName('current-time-reservation-cell')[0]
		if (!isNullOrUndefined(currentTimeCell)) {
			scrollIntoView(currentTimeCell, { behavior: 'smooth', scrollMode: 'if-needed', inline: 'start' })
		}
	}

	// ----------------------------------------------------------------------------------
	// Content Fetch
	// ----------------------------------------------------------------------------------

	fetchCalendarsForGroup = async (groupID: string) => {
		const queryParams: QueryOptions = { offset: 0, limit: 0, groupID: groupID }
		const [fetchCalendarsErr] = await to(this.props.fetchCalendars(queryParams) as any)
		if (fetchCalendarsErr) {
			this.props.fireFlashMessage(`Problem fetching Dining Room info. ${fetchCalendarsErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
		}
	}

	fetchBookableEvents = async (start: string, calendarID: string) => {
		const [err] = await to(this.props.fetchBookableEvents(start, [calendarID], !this.props.isCustomerView) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to fetch Events. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { initialLoad: false, error: true })
			return
		}
	}
	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	handleDateChanged = async (changedDate: Date) => {
		const newDate = changedDate.toISOString()
		await setStateAsync(this, { loading: true, selectedDate: newDate })

		// Fetch the Events
		const start = beginningOfDay(newDate).toISOString()
		const calendarIDs = [this.state.selectedCalendarID]
		const [err] = await to(this.props.fetchBookableEvents(start, calendarIDs, !this.props.isCustomerView) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to fetch Events. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}

		await setStateAsync(this, { loading: false })
	}

	/**
	 * Navigates the user to the Confirm Reservation component.
	 */
	showReservationConfirmationFromForm = async (formState: DinnerReservationFormState) => {
		const duration = oc(formState).duration.value()
		const date = oc(formState).time(this.state.bookingModalDate)
		const guestCount = Number(formState.guests.value)
		this.showReservationConfirmation(duration, date, guestCount)
	}

	saveReservation = async (formState: DinnerReservationFormState) => {
		const { reserveBookableEvent, fetchBookableEvents, fireFlashMessage, isCustomerView } = this.props
		const { selectedCalendarID, bookingModalDate } = this.state

		const defaultDuration = 89
		const duration = oc(formState).duration.value(`${defaultDuration}`)

		const calID = selectedCalendarID
		const startTime = bookingModalDate.toISOString()
		const endTimeDate = new Date(startTime)
		endTimeDate.setMinutes(endTimeDate.getMinutes() + Number(duration), 0, 0)
		const endTime = endTimeDate.toISOString()

		await setStateAsync(this, { loading: true })

		// Build the Reservation payload
		const reservation = this.buildReservationPayload(formState)

		const [requestError] = await to(reserveBookableEvent(reservation, calID, startTime, endTime) as any)
		if (requestError) {
			fireFlashMessage(`Failed to save reservation. ${requestError.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false })
			return
		}

		const [fetchErr] = await to(fetchBookableEvents(startTime, [calID], !isCustomerView) as any)
		if (fetchErr) {
			fireFlashMessage(`Failed to fetch Events. ${fetchErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false })
			throw fetchErr
		}

		await setStateAsync(this, { loading: false })
		await this.handleBookingModalClosed()
	}

	showReservationConfirmation = async (duration: string, date: Date, guestCount: number) => {
		const calGroup = this.getCalendarGroup()
		const calendar = this.getCalendar(this.state.selectedCalendarID)
		const routeAction = (this.state.bookingModalReservationID) ? 'update' : 'new'
		const route = Constants.CONFIRM_RESERVATION.replace(':type', 'golf').replace(':action', routeAction)

		const routeState: Partial<ConfirmReservationComponent['state']> = {
			bookingDate: date,
			duration: (duration) ? Number(duration) : undefined,
			calendarGroup: calGroup,
			calendarID: `${calendar._id}`,
			reservationID: this.state.bookingModalReservationID,
			eventID: this.state.selectedEventID,
			guestCount: guestCount
		}
		return this.props.history.push(route, routeState)
	}

	// ----------------------------------------------------------------------------------
	// Menu Action Handlers
	// ----------------------------------------------------------------------------------

	handleActionSelected = async (item: DropdownItem) => {
		switch (item.value) {
			case inputs.MenuActions.NewEvent:
				return this.handleNewDiningEvent()
			case inputs.MenuActions.ViewCalendar:
				return this.handleViewCalendar()
			case inputs.MenuActions.ViewSettings:
				return this.handleViewSettings()
			case inputs.MenuActions.NewReservation:
				return this.handleNewReservation()
		}
	}

	/**
	 * Display New Dining Event Form.
	 */
	handleNewDiningEvent = async () => {
		const queryParams = { calendarID: this.state.selectedCalendarID }
		const search = queryString.stringify(queryParams)
		const location = {
			pathname: Constants.CREATE_EVENT_ROUTE,
			search: search,
			state: {
				previousLocationName: 'Dining',
				previousLocation: { ...this.props.location }
			}
		}
		this.props.history.push(location)
	}

	/**
	 * Display Dining Big Calendar.
	 */
	handleViewCalendar = async () => {
		const queryParams = { groupID: this.state.selectedCalendarGroupID }
		const search = queryString.stringify(queryParams)
		const location = { pathname: Constants.CALENDAR_ROUTE, search: search }
		this.props.history.push(location)
	}

	/**
	 * Display Dining Settings.
	 */
	handleViewSettings = async () => {
		const selectedCalendarID = this.state.selectedCalendarID
		const route = Constants.RESERVATION_SETTINGS_ROUTE.replace(':calendar_id', selectedCalendarID)
		this.props.history.push(route)
	}

	/**
	 * Display the new reservation form.
	 */
	handleNewReservation = async () => {
		await setStateAsync(this, { showingBookingModal: true })
	}

	// ----------------------------------------------------------------------------------
	// Calendar Selection Action Handler
	// ----------------------------------------------------------------------------------

	/**
	 * Handles changes to the calendar selector.
	 */
	handleCalendarChanged = async (data: InputSelectionItem) => {
		await setStateAsync(this, { loading: true, selectedCalendarID: data.value })

		// Fetch the Events
		const start = beginningOfDay(this.state.selectedDate).toISOString()
		await this.fetchBookableEvents(start, this.state.selectedCalendarID)
		await setStateAsync(this, { loading: false })
	}

	// ----------------------------------------------------------------------------------
	// Reservation Table Action Handlers
	// ----------------------------------------------------------------------------------
	handleNewReservationCancel = async () => {
		const location = { pathname: `${Constants.DINING_RESERVATIONS_ROUTE}/viewing` }
		this.props.history.push(location)
	}

	handleNewReservationClicked = async (bookingTime: Date) => {
		await setStateAsync(this, { showingBookingModal: true, bookingModalDate: bookingTime })
	}

	handleExistingReservationClick = async (res: core.Event.Reservation, event: core.Event.AvailableEvent) => {
		const calendarID = calendarIDForEvent(event.existingEvent)
		const existing = event.existingEvent
		await setStateAsync(this, {
			bookingModalDate: event.existingEvent.start,
			selectedCalendarID: `${calendarID}`,
			bookingModalReservationID: `${res._id}`,
			selectedEventID: `${event.existingEvent._id}`
		})
		const start = new Date(existing.start)
		const end = new Date(existing.end)
		const difference = end.getTime() - start.getTime() // This will give difference in milliseconds
		const duration = Math.round(difference / 60000) // Difference in minutes.
		this.showReservationConfirmation(`${duration}`, event.existingEvent.start, res.participants.length)
	}

	handleBookingModalClosed = async () => {
		await setStateAsync(this, { showingBookingModal: false, bookingModalDate: null, bookingModalReservationID: null, selectedEventID: null })
	}

	handleBookingModalDelete = async () => {
		await setStateAsync(this, { loading: true })

		const [cancelErr] = await to(this.props.cancelEventRsvp(this.state.bookingModalReservationID, this.state.selectedEventID) as any)
		if (cancelErr) {
			this.props.fireFlashMessage(`Failed to cancel Reservation for Event. ${cancelErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}

		// Fetch the Events
		const calendarIDs = [this.state.selectedCalendarID]
		const start = beginningOfDay(this.state.selectedDate).toISOString()
		const [fetchErr] = await to(this.props.fetchBookableEvents(start, calendarIDs, !this.props.isCustomerView) as any)
		if (fetchErr) {
			this.props.fireFlashMessage(`Failed to fetch Events. ${fetchErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}

		this.props.fireFlashMessage(`Successfully cancelled Reservation.`, Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, showingBookingModal: false, bookingModalReservationID: null })
	}

	handleCheckInAction = async (action: ReservationCellAction, eventWithRes: EventWithReservation) => {
		await setStateAsync(this, { loading: true })
		const event = eventWithRes.availableEvent.existingEvent
		const res = eventWithRes.reservation
		const currentEventID = `${event._id}`

		// Build the payload for the Reservation, with the updated participant list
		const updatedReservation: core.Event.Reservation = {
			...res,
			participants: this.buildUpdatedParticipantsList(res)
		}

		// Update the event.
		const [err] = await to(this.props.updateEventRsvp(`${res._id}`, updatedReservation, currentEventID) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to update reservation. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false })
			return
		}

		this.props.fireFlashMessage('Successfully updated RSVP', Constants.FlashType.SUCCESS)

		// Fetch the Events
		const calendarIDs = [this.state.selectedCalendarID]
		const start = beginningOfDay(this.state.selectedDate).toISOString()
		const [fetchErr] = await to(this.props.fetchBookableEvents(start, calendarIDs, !this.props.isCustomerView) as any)
		if (fetchErr) {
			this.props.fireFlashMessage(`Failed to fetch Events. ${fetchErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}
		await setStateAsync(this, { loading: false, submittingForm: false })
	}

	// ----------------------------------------------------------------------------------
	// Live Select Action Handlers
	// ----------------------------------------------------------------------------------

	handleLiveToggle = async (event: any) => {
		switch (event.value as Constants.LiveStatus) {
			case Constants.LiveStatus.Live:
				await this.updateCalendarLiveStatus(true)
				this.props.fireFlashMessage(`Dining Calendar is now Live`, Constants.FlashType.SUCCESS)
				break
			case Constants.LiveStatus.Off:
				this.updateCalendarLiveStatus(false)
				this.props.fireFlashMessage(`Dining Calendar is now Off`, Constants.FlashType.WARNING)
				break
		}
	}

	updateCalendarLiveStatus = async (live: boolean) => {
		await setStateAsync(this, { loading: true })
		const calendarID = this.state.selectedCalendarID
		const [err] = await to(this.props.updateCalendar(calendarID, { live: live }) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to fetch Events. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}
		await setStateAsync(this, { loading: false })
	}

	// ----------------------------------------------------------------------------------
	// Header Button Action Handlers
	// ----------------------------------------------------------------------------------

	handleHeaderButtonAction = (e: any) => {
		const action = e.target.value
		switch (action) {
			case 'scrollToCurrent':
				const currentTimeCell = document.getElementsByClassName('current-time-reservation-cell')[0]
				if (!isNullOrUndefined(currentTimeCell)) {
					scrollIntoView(currentTimeCell, { behavior: 'smooth', scrollMode: 'if-needed', inline: 'start' })
				}
				break
		}
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildPageHeader = () => {
		// For Calendar Group Select:
		const dateInputs = inputs.DateSelectInputs(this)
		const dropdownInputs = inputs.MenuSelectInputs(this)

		const calendar = this.getCalendar(this.state.selectedCalendarID)
		const liveSelectInputs = inputs.LiveSelectInputs(calendar.live, this.handleLiveToggle)

		const group = this.getCalendarGroup()
		const calendars = this.getCalendarsForGroup(`${group._id}`)
		const selectInputs = inputs.CalendarSelectInputs(calendars, calendar, this.handleCalendarChanged)

		const inputsForHeader = [
			liveSelectInputs,
			dateInputs,
			selectInputs,
			dropdownInputs
		]

		const buttonsForHeader: HeaderButton[] = [
			{
				text: 'Now',
				action: 'scrollToCurrent',
				type: ButtonType.DEFAULT,
				class: 'btn btn-secondary'
			}
		]

		return (
			<PageHeaderComponent
				pageTitle={calendar.name}
				inputs={inputsForHeader}
				buttons={buttonsForHeader}
				buttonHandler={this.handleHeaderButtonAction}
			/>
		)
	}

	buildContent = () => {
		const reservationQueue = this.buildReservationQueue()
		const table = this.buildBookingTable()

		return (
			<RS.Row className='dining-reservation-row'>
				<RS.Col xs={12} lg={4} xl={3}>
					{reservationQueue}
				</RS.Col>

				<RS.Col xs={12} lg={8} xl={9}>
					<div className='dining-reservation-table-container'>
						<DimmedLoader component={table} isLoading={this.state.loading} />
					</div>
				</RS.Col>
			</RS.Row>
		)
	}

	buildReservationQueue = () => {
		const eventDataForCalendar = oc(this).props.eventState.bookableEvents([])

		const upcomingEvents: { [key: string]: core.Event.AvailableEvent[] } = {}
		for (const eventData of eventDataForCalendar) {
			for (const eventInfo of eventData.eventInfo) {
				// If there is no existing event, continue
				if (isNullOrUndefined(eventInfo.existingEvent)) { continue }

				// If the existing event has already started, continue
				const startTime = new Date(eventInfo.existingEvent.start)
				if (startTime.valueOf() < new Date().valueOf()) { continue }

				// If the event is in the future, add the event to our queue map.
				const time = new Date(eventInfo.time)
				if (isNullOrUndefined(upcomingEvents[time.toISOString()])) {
					upcomingEvents[time.toISOString()] = [eventInfo]
				} else {
					upcomingEvents[time.toISOString()].push({ ...eventInfo })
				}
			}
		}

		// Setup our onClick handler.
		const onClick = (res: core.Event.Reservation, event: core.Event.AvailableEvent) => {
			this.handleExistingReservationClick(res, event)
		}
		return (
			<ReservationQueue upcomingEventsByTime={upcomingEvents} onClick={onClick} />
		)
	}

	buildBookingTable = () => {
		const data = oc(this).props.eventState.bookableEvents[0]()

		return (
			<RS.Table className='dining-reservation-table' bordered={true} >
				<thead>
					<tr>
						{this.buildTableHeaders()}
					</tr>
				</thead>
				<tbody>
					{this.buildAvailabilityRow()}
					{this.buildCalendarRows(data)}
				</tbody>
			</RS.Table>
		)
	}

	buildTableHeaders = () => {
		const allTimes = oc(this).props.eventState.bookableEvents[0].allTimes([])
		let foundCurrentTime = false
		const columns = allTimes.map((bookingTime, idx) => {
			const options = { hour: 'numeric', minute: 'numeric' }
			const label = new Date(bookingTime).toLocaleTimeString('en-US', options)
			const time = label.slice(0, label.length - 2)
			const period = label.slice(label.length - 2)

			const timeInPast = new Date(bookingTime).getTime() < new Date().getTime()
			let tableHeaderClass = ''
			if (!timeInPast && !foundCurrentTime) {
				foundCurrentTime = true
				tableHeaderClass = 'reservation-header-current-time'
			}

			return (
				<th key={`tableHeader_${label}_${idx}`} className={`${tableHeaderClass}`}>
					<div className='dining-reservation-header'>
						<div className='time-label'>{time}</div>
						<div className='period-label'>{period}</div>
					</div>
				</th>
			)
		})
		return [...columns]
	}

	buildAvailabilityRow = () => {
		const allTimes = oc(this).props.eventState.bookableEvents[0].allTimes([])
		let foundCurrentTime = false
		const columns = allTimes.map((bookingTime, idx) => {
			// Determine how many spots are available for this time
			const eventInfo = oc(this).props.eventState.bookableEvents[0].eventInfo([])
			const bookableEventAtTime = eventInfo.find((bookableEvent) => bookableEvent.time === bookingTime)
			const takenSpots = bookableEventAtTime.totalSpots - bookableEventAtTime.openSpots
			const availableSpots = `${takenSpots}/${bookableEventAtTime.totalSpots}`

			const timeInPast = new Date(bookingTime).getTime() < new Date().getTime()
			let availabilityCellClass = ''
			if (!timeInPast && !foundCurrentTime) {
				foundCurrentTime = true
				availabilityCellClass = 'reservation-availability-current-time'
			}

			return (
				<td key={`availability-cell-${idx}`} className={`${availabilityCellClass}`}>
					<div className='available-spots-label'>{availableSpots}</div>
				</td>
			)
		})

		return (
			<tr className='availability-row'>
				{columns}
			</tr>
		)
	}

	/**
	 * Creates a list of rows for the DiningReservation table
	 * @param eventData The EventData for the Calendar
	 */
	buildCalendarRows = (eventData: core.Event.EventDataForCalendar) => {
		// Get the Reservations
		const bookableEventsWithReservation = this.getBookableEventsWithReservation(eventData)

		// Sort the Reservations by Start Time
		const sortedReservations = bookableEventsWithReservation.sort((a, b) => sortDates(a, b, 'start'))

		const reservationRows = []
		for (let rowIndex = 0; rowIndex < sortedReservations.length; rowIndex++) {
			// Add a spacer row to the table, so that the rows have spaces between them
			reservationRows.push(this.spacerRow(rowIndex))
			reservationRows.push(this.buildReservationRow(eventData, sortedReservations[rowIndex], rowIndex))
		}

		// Add an extra row at the bottom for new bookings.
		const lastRowIndex = sortedReservations.length + 1
		reservationRows.push(this.spacerRow(lastRowIndex))
		reservationRows.push(this.buildReservationRow(eventData, null, lastRowIndex, true))

		return reservationRows
	}

	/**
	 * Creates a row in the table, with a ReservationCell that corresponds to the Reservation that we pass in
	 * @param eventData The EventData for the Calendar
	 * @param eventWithReservation The Reservation that we will be building a cell for in this row
	 * @param rowIndex The index of the row that we are building
	 */
	buildReservationRow = (eventData: core.Event.EventDataForCalendar, eventWithReservation: EventWithReservation, rowIndex: number, isLast?: boolean) => {
		// Determine the recurrence period using adjacent times
		const period = new Date(eventData.allTimes[1]).valueOf() - new Date(eventData.allTimes[0]).valueOf()

		// Iterate over all the BookableEvents and render cells to build the table
		let foundCurrentCell = false
		const reservationCells: JSX.Element[] = []
		let skipCount = 0
		const bookableEvents = oc(eventData).eventInfo([])
		for (let cellIndex = 0; cellIndex < bookableEvents.length; cellIndex++) {
			const bookableEvent = bookableEvents[cellIndex]
			const cellKey = `reservation-cell_row${rowIndex}_cell${cellIndex}`
			const eventTime = new Date(bookableEvent.time)
			const existingEventID = oc(bookableEvent).existingEvent._id()

			let reservationCellProps: DiningReservationCellProps

			// Determine if we need to skip some cells because of an Event
			// that spans multiple columns
			if (skipCount > 0) {
				skipCount -= 1
				continue
			}

			// Determine if the Event is blocking
			const eventIsBlocking = oc(bookableEvent).existingEvent.blockCalendar(false)
			if (eventIsBlocking) {
				const blockingEvent = bookableEvent.existingEvent
				reservationCellProps = this.buildBlockedCellProps(cellKey, blockingEvent, period)
			}

			// We have a Reservation with the Event
			else if (eventWithReservation && `${existingEventID}` === `${eventWithReservation._id}`) {
				reservationCellProps = this.buildReservationCellProps(eventWithReservation, cellKey, eventTime, period, `${eventData.calendarID}`)
			}

			// Build an Empty Cell
			else {
				reservationCellProps = this.buildEmptyCellProps(cellKey, eventTime, eventWithReservation)
			}

			// Check if the Event is in the past
			const eventInPast = eventTime.getTime() < new Date().getTime()
			if (!foundCurrentCell && !eventInPast) {
				foundCurrentCell = true
				reservationCellProps = {
					...reservationCellProps,
					isCurrent: true,
				}
			}

			// If the columnSpan is greater than 1, we need to skip
			// the next few iterations of the loop to account for that
			skipCount = reservationCellProps.columnSpan - 1
			let checkedIn = false
			if (eventWithReservation) {
				const res = eventWithReservation.reservation
				const participant = res.participants.find((p: core.Event.Participant) => `${p.userID}` === `${res.owner}`)
				checkedIn = participant.checkedIn
			}

			const cellToRender = (
				<DiningReservationCellComponent
					{...reservationCellProps}
					key={reservationCellProps.id}
					isDisabled={eventInPast}
					isAdmin={!this.props.isCustomerView}
					isCheckedIn={checkedIn}
					onActionClick={(action: ReservationCellAction) => this.handleCheckInAction(action, eventWithReservation)}
				/>
			)
			reservationCells.push(cellToRender)
		}

		return (
			<tr className={'reservation-row mt-2 align-items-center'} key={`reservation-row-${rowIndex}`}>
				{reservationCells}
			</tr>
		)
	}

	/**
	 * Builds the form inputs for a Dinner Reservation form.
	 */
	buildDinnerFormInputs = (calendar?: core.Calendar.Model) => {
		// Get the specific set of Reservation Settings for today
		const reservationSettings = oc(calendar).reservationSettings([])
		const currentDay = beginningOfDay(new Date().toISOString())
		const reservationSetting = getMostSpecificSetting(reservationSettings, currentDay)
		const maxSpots = oc(reservationSetting).maxGuestsAdmin(12)
		const guestItems: InputSelectionItem[] = []
		for (let i = 1; i <= maxSpots; i++) {
			guestItems.push({ label: `${i}`, value: `${i}` })
		}

		const DurationOptions = [
			{ label: '0.5 Hours', value: '29' },
			{ label: '1.0 Hours', value: '59' },
			{ label: '1.5 Hours', value: '89' },
			{ label: '2.0 Hours', value: '119' },
			{ label: '2.5 Hours', value: '149' },
		]

		const members = oc(this).props.userState.users([])
		const memberItems = members.map(userForForm)
		const durationItems = DurationOptions
		return DinnerReservationFormInputs(memberItems, guestItems, durationItems)
	}

	buildBookingModal = () => {
		if (!this.state.showingBookingModal) { return null }

		const calendar = this.getCalendar(this.state.selectedCalendarID)
		const formTitle = (this.state.bookingModalReservationID) ? null : 'New Dining Reservation'
		return (
			<ReservationForm
				inputs={this.buildDinnerFormInputs(calendar)}
				initialTime={this.state.bookingModalDate}
				eventCalendarID={this.state.selectedCalendarID}
				reservationID={this.state.bookingModalReservationID}
				onCancel={this.handleBookingModalClosed}
				onSave={this.saveReservation}
				onDelete={this.handleBookingModalDelete}
				formTitle={formTitle}
			/>
		)
	}

	render() {
		if (this.state.initialLoad) { return <DimmedLoader component={null} isLoading={true} /> }
		if (!this.props.eventState) { return null }

		if (this.props.isCustomerView) {
			return <CustomerDiningForm {...this.props} onCancel={this.handleNewReservationCancel} />
		}

		return (
			<div className='dining-reservation-container'>
				{this.buildPageHeader()}
				{this.buildContent()}
				{this.buildBookingModal()}
			</div>
		)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	buildReservationPayload = (formState: DinnerReservationFormState): core.Event.Reservation => {
		const { userState } = this.props

		const owner: core.Event.Participant = {
			userID: oc(formState).member.value() as any,
			name: oc(formState).member.label(),
			checkedIn: false,
			paid: false
		}

		// Create the guests based on the size of the party
		const guests: core.Event.Participant[] = []
		for (let i = 1; i < Number(formState.guests.value); i++) {
			const newParticipant: core.Event.Participant = {
				userID: null,
				name: 'Guest',
				checkedIn: false,
				paid: false
			}
			guests.push(newParticipant)
		}

		// Create the reservation for the payload
		const reservation: core.Event.Reservation = {
			creator: userState.loggedInUser._id,
			owner: owner.userID,
			participants: [owner, ...guests],
		}

		return reservation
	}

	/**
	 * Returns a spacer row for a table, allowing us to have gaps between table rows
	 */
	spacerRow = (rowIndex: number) => (
		<tr className='spacer-row' key={`spacer-row_${rowIndex}`}>
			<td />
		</tr>
	)

	/**
	 * Takes the EventDataForCalendar and returns an array of EventWithReservation objects. This effectively
	 * flattens the nested Reservation field of the 'existingEvent' field of a 'BookableEvent', so that
	 * we can easily access the _id, start, and end times of the Event that a Reservation is linked to.
	 */
	getBookableEventsWithReservation = (eventData: core.Event.EventDataForCalendar): EventWithReservation[] => {
		let bookableEventsWithReservation: EventWithReservation[] = []
		for (const bookableEvent of eventData.eventInfo) {
			const hasReservations = oc(bookableEvent).existingEvent.reservations.length(0) !== 0
			if (hasReservations) {
				for (const reservation of bookableEvent.existingEvent.reservations) {
					const existingBookableEvent = {
						_id: bookableEvent.existingEvent._id,
						start: bookableEvent.existingEvent.start,
						end: bookableEvent.existingEvent.end,
						reservation: { ...reservation },
						availableEvent: bookableEvent
					}
					bookableEventsWithReservation = [].concat(bookableEventsWithReservation, existingBookableEvent)
				}
			}
		}
		return bookableEventsWithReservation
	}

	/**
	 * Returns the props to build a reserved ReservationCell
	 */
	buildReservationCellProps = (eventWithReservation: EventWithReservation, cellKey: string, eventTime: Date, period: number, calendarID: string): DiningReservationCellProps => {
		const clubMembers = oc(this).props.userState.users([])

		// We found the existing Event that is linked to our 'eventWithReservation'
		const availableEvent = eventWithReservation.availableEvent
		const reservation = eventWithReservation.reservation

		// Determine the name of member who is linked to the Reservation
		const reservationOwner = clubMembers.find((m) => m._id === reservation.owner)
		const reservationName = fullName(reservationOwner)

		// Determine the number of spots taken with this Reservation
		const takenSpots = reservation.participants.length

		// Determine the duration of the Reservation
		const rawDuration = new Date(eventWithReservation.end).valueOf() - new Date(eventWithReservation.start).valueOf()
		const columnSpan = Math.ceil(rawDuration / period)

		return {
			id: `${cellKey}_reserved`,
			isAdmin: !this.props.isCustomerView,
			spots: takenSpots,
			name: reservationName,
			columnSpan: columnSpan,
			onClick: () => this.handleExistingReservationClick(reservation, availableEvent)
		}
	}

	/**
	 * Returns the props to build an empty ReservationCell
	 */
	buildEmptyCellProps = (cellKey: string, eventTime: Date, eventWithReservation?: EventWithReservation): DiningReservationCellProps => {
		const emptyCellOnClickHandler = (isNullOrUndefined(eventWithReservation)) ?
			() => this.handleNewReservationClicked(eventTime) :
			null

		return {
			id: `${cellKey}_open`,
			isAdmin: !this.props.isCustomerView,
			isOpen: true,
			columnSpan: 1,
			onClick: emptyCellOnClickHandler
		}
	}

	buildBlockedCellProps = (cellKey: string, blockingEvent: core.Event.Model, period: number): DiningReservationCellProps => {
		const rawDuration = new Date(blockingEvent.end).valueOf() - new Date(blockingEvent.start).valueOf()
		const columnSpan = Math.ceil(rawDuration / period)

		return {
			id: `${cellKey}_reserved`,
			spots: 0,
			name: `Blocked by: ${blockingEvent.name}`,
			columnSpan: columnSpan,
			isBlocked: true,
			onClick: async () => {
				this.props.fireFlashMessage(`Event Blocked for: ${blockingEvent.name}`, Constants.FlashType.WARNING)
			},
		}
	}

	getCalendarGroup = () => {
		const calendarGroups = oc(this).props.calendarState.groups([])
		return calendarGroups.find((cg) => cg.type === core.Calendar.GroupType.Dining)
	}

	getCalendarsForGroup = (groupID: string) => {
		const calendars = oc(this).props.calendarState.calendars([])
		return calendars.filter((cal) => `${cal.groupID}` === groupID)
	}

	getCalendar = (id: string) => {
		const group = this.getCalendarGroup()
		const calendarsForGroup = this.getCalendarsForGroup(`${group._id}`)
		return calendarsForGroup.find((cal) => `${cal._id}` === id)
	}

	/**
	 * Creates an updated version of a Reservation's participant list, where the
	 * 'checkedIn' value of the Reservation's owner is set to 'checkedInState'
	 * @param reservation The Reservation, whose participant list we are building
	 * @param checkedInState The value that the Reservation's owner will have their 'checkedIn' field set to
	 * @returns The updated list of participants
	 */
	buildUpdatedParticipantsList = (reservation: core.Event.Reservation) => {
		const updatedParticipants = reservation.participants.map((p) => {
			const state = !p.checkedIn
			const participantID = oc(p).userID()
			// We found the owner of the Reservation, update their 'checkedIn' state
			if (!isNullOrUndefined(participantID) && participantID === reservation.owner) {
				return { ...p, checkedIn: state }
			}
			return p
		})
		return updatedParticipants
	}

}

const mapStateToProps = (state: RootReducerState) => ({
	eventState: state.event,
	userState: state.user,
	calendarState: state.calendar,
	isCustomerView: inCustomerViewSelector(state),
})

const mapDispatchToProps = {
	...EventActions,
	...AlertActions,
	...CalendarActions
}

export default connect(mapStateToProps, mapDispatchToProps)(DiningReservationComponent)
