// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import to from 'await-to-js'
import { oc } from 'ts-optchain'

// Internal Dependencies

// State
import { RootReducerState } from '../../reducers/index'

// Actions
import { AlertActions, RestaurantActions } from '../../actions/index'

// Components
import PageHeaderComponent from '../Shared/PageHeader'
import { HeaderButton, ButtonType } from '../Shared/ButtonGroup'
import ErrorComponent from '../Shared/ErrorComponent'
import TableComponent from '../Shared/Table'
import ModalComponent from '../Shared/Modal'
import DimmedLoader from '../Shared/DimmedLoader'

// Tables
import { RestaurantTableColumns } from './table'

// Helpers
import * as Constants from '../../constants'
import { setStateAsync } from '../../helpers/promise'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	showingTable: true,
	restaurantToDelete: null as string | null,
	error: false,
	initialLoading: false,
	loading: false,
	pageIndex: 0
}

type Props = RouteComponentProps & ConnectedState & ConnectedActions
type State = typeof initialState

class RestaurantComponent extends React.Component<Props, State> {
	private pageSize: number
	private isCustomerView: boolean

	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
		this.pageSize = 20
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		// TODO add fetch restaurants here.
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Dropdown Actions
	// ----------------------------------------------------------------------------------

	/**
	 * Determines which action to take, based on which dropdown item
	 * the user selected in the table's dropdown menu
	 */
	handleTableDropdownAction = (e: any) => {
		const [action, value] = e
		switch (action) {
			case 'viewMenus':
				return this.handleViewRestaurant(value)
			case 'editRestaurant':
				return this.handleEditRestaurant(value)
			case 'deleteRestaurant':
				return this.handleDeleteRestaurant(value)
			default:
				break
		}
	}

	handleViewRestaurant = (restaurantID: string) => {
		const route = Constants.MENU_ROUTE.replace(':restaurant_id', restaurantID)
		this.props.history.push(route)
	}

	handleEditRestaurant = (restaurantID: string) => {
		// Query params
		const queryParams = { restaurantID }
		const location = {
			pathname: Constants.RESTAURANT_UPDATE_ROUTE,
			search: queryString.stringify(queryParams)
		}

		this.props.history.push(location)
	}

	handleDeleteRestaurant = async (restaurantID: string) => {
		return setStateAsync(this, { restaurantToDelete: restaurantID })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Modal Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Make the API call to delete the Restaurant, when the
	 * user confirms the deletion via the modal
	 */
	executeRestaurantDeletion = async () => {
		const restaurantID = this.state.restaurantToDelete
		await setStateAsync(this, { loading: true })

		const currentPage = this.state.pageIndex
		const [deleteErr] = await to(this.props.deleteRestaurant(restaurantID) as any)
		if (deleteErr) {
			this.props.fireFlashMessage(`Problem trying to delete Restaurant. ${deleteErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true, restaurantToDelete: null })
			return
		}

		this.props.fireFlashMessage('Successfully deleted Restaurant', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, restaurantToDelete: null })

	}

	/**
	 * Hide the deletion modal when the user cancels it
	 */
	closeDeleteRestaurantModal = async () => {
		return setStateAsync(this, { restaurantToDelete: null })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Handle table pagination
	 */
	handlePage = async (data: any) => {
		const { pageIndex } = this.state

		// Check if the new page is different that the current one
		if (data.selected !== pageIndex) {
			await setStateAsync(this, { pageIndex: data.selected })
		}
	}

	/**
	 * Handle a column header of the table being selected
	 */
	handleTableHeaderAction = (e: any) => {
		// TODO: Add this functionality
	}

	/**
	 * Handle a table row being clicked
	 */
	handleTableRowClicked = (restaurant: core.Restaurant.Model) => {
		return this.handleViewRestaurant(restaurant._id as any)
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Header Event Handlers
	// ----------------------------------------------------------------------------------

	handleHeaderButtonAction = async (e: any) => {
		const action = e.target.value
		switch (action) {
			case 'createRestaurant':
				return this.handleCreateRestaurant()
			default:
				throw new Error(`Unrecognized table button action: ${action}`)
				break
		}
	}

	/**
	 * Navigate to the Restaurant creation form
	 */
	handleCreateRestaurant = () => {
		this.props.history.push(Constants.RESTAURANT_CREATE_ROUTE)
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildDeleteConfirmationModal = () => {
		if (!this.state.restaurantToDelete) { return null }
		return (
			<ModalComponent
				modalTitle={'Delete Restaurant'}
				primaryMessage={'Are you sure you want to delete this Restaurant?'}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.closeDeleteRestaurantModal}
				submitButtonName={'Confirm'}
				submitButtonHandler={this.executeRestaurantDeletion}
			/>
		)
	}

	buildPageHeader = () => {
		return (
			<PageHeaderComponent
				pageTitle={'Restaurants'}
				buttons={this.buildHeaderButtons()}
				buttonHandler={this.handleHeaderButtonAction}
			/>
		)
	}

	buildHeaderButtons = (): HeaderButton[] => {
		return [
			{
				action: 'createRestaurant',
				type: ButtonType.DEFAULT,
				text: 'New Restaurant',
				class: 'btn-primary',
			},
		]
	}

	buildTableRowItems = () => {
		const restaurants: core.Restaurant.Model[] = oc(this).props.restaurantState.restaurants([])
		return restaurants.map((r) => {
			return { ...r }
		})
	}

	buildRestaurantTable = () => {
		const tableRows = this.buildTableRowItems()
		const total = tableRows.length

		const startIdx = this.state.pageIndex * this.pageSize
		const endIdx = startIdx + this.pageSize
		const itemsForPage = tableRows.slice(startIdx, endIdx)

		return (
			<TableComponent<any>
				columns={RestaurantTableColumns}
				rowItems={itemsForPage}
				showPaging={true}
				currentPage={this.state.pageIndex}
				pageHandler={this.handlePage}
				totalResults={total}
				pageSize={this.pageSize}
				actionHandler={this.handleTableHeaderAction}
				dropdownHandler={this.handleTableDropdownAction}
				onTableRowClick={this.handleTableRowClicked}
				isLoading={this.state.loading}
			/>
		)
	}

	render() {
		const { loggedInClub } = this.props
		if (this.state.initialLoading) { return <DimmedLoader component={null} isLoading={true} /> }
		if (this.state.error) { return <ErrorComponent club={loggedInClub} isAdmin={this.props.userState.isAdmin} /> }

		return (
			<div className='row justify-content-center'>
				<div className={'col-lg-12'}>
					{this.buildPageHeader()}

					<div className='restaurant-content-container mx-auto'>
						{this.buildRestaurantTable()}
					</div>

					{this.buildDeleteConfirmationModal()}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	loggedInClub: state.club.loggedInClub,
	userState: state.user,
	restaurantState: state.restaurant,
})

const mapDispatchToProps = {
	...AlertActions,
	...RestaurantActions
}

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantComponent)
