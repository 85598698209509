// External Dependencies
import * as React from 'react'
import * as Feather from 'react-feather'
import moment from 'moment'
import { Omit } from 'react-router'
import { isNullOrUndefined } from 'util'

// Internal Dependencies
import { BaseInputPrimitiveProps } from '../index'
import DatePickerComponent from '../../../DatePicker'

import { FormInputType } from '../../../Form'

// Helpers
import { beginningOfDay, addMinutesToDate } from '../../../../../helpers/date'
import { Constants } from 'club-hub-core'

interface ComponentProps extends Omit<BaseInputPrimitiveProps, 'onChange'> {
	onChange: (property: string, date: Date) => void
	onBlur: (property: string, didBlur: boolean) => void
}

type Props = ComponentProps

export const DateInput: React.FunctionComponent<Props> = (props: Props) => {
	let selected = props.value ? new Date(props.value) : null
	let isClearable = true

	// If the input has a 'date_start_field' on it, than this input cannot have a value
	// lower than the field it is linked to
	if (props.item.date_start_field) {
		const formValue = props.form.values[props.item.date_start_field]
		const startDate = (formValue) ? new Date(formValue) : null

		isClearable = isNullOrUndefined(startDate)

		// If the input has a lower value than its linked field, update it to match
		if (startDate && (!selected || (selected && selected.valueOf() < startDate.valueOf()))) {
			selected = addMinutesToDate(startDate, 60)
			onChange(props, selected)
		}
	}

	// Add support for a Date filtering function
	let filterDatesFunc = (date: Date) => filterDates(props, date)
	if (props.item.date_filter_func) {
		filterDatesFunc = ((date: Date) => {
			return props.item.date_filter_func(date) && filterDates(props, date)
		})
	}

	// Add support for min/max time
	let minTime
	let maxTime
	if (props.item.date_min_time && props.item.date_max_time) {
		minTime = props.item.date_min_time
		maxTime = props.item.date_max_time
	}
	const timeOnly = props.item.type === FormInputType.TIME
	const icon = timeOnly ? Feather.Clock : Feather.Calendar
	const showYear = props.item.type === FormInputType.DATE_YEAR
	return (
		<DatePickerComponent
			id={props.item.property}
			disabled={props.item.disabled}
			type={props.item.type as any}
			className={`form-control`}
			featherIcon={icon}
			selected={selected}
			placeholderText={props.item.placeholder}
			onChange={(date: Date) => onChange(props, date)}
			onChangeRaw={(event) => {
				const value = event.target.value
				const dateValue = new Date(value)
				const momentValue = moment(dateValue)
				const isValidDate = momentValue.isValid()
				if (!isValidDate) {
					event.stopPropagation()
					event.nativeEvent.stopPropagation()
				}
			}}
			showMonthDropdown={showYear}
			showYearDropdown={showYear}
			onBlur={() => onBlur(props)}
			filterDate={filterDatesFunc}
			minTime={minTime}
			maxTime={maxTime}
			timeIntervals={props.item.date_interval}
			isClearable={!props.item.disabled && isClearable}
		/>
	)
}

const filterDates = (props: Props, date: Date) => {
	// Filter out all the dates that come before the linked field's value, if it is set
	if (props.item.date_start_field) {
		const formValue = props.form.values[props.item.date_start_field]
		const startDate = (formValue) ? new Date(formValue) : null

		if (startDate) {
			// To do a proper comparison between dates, we need to set the
			// two dates to the beginning of their respective days. This
			// will prevent the current start date from being filtered out
			// of the end date input
			const dateToFilter = beginningOfDay(date.toISOString())
			const startDateToFilter = beginningOfDay(startDate.toISOString())
			return dateToFilter.valueOf() >= startDateToFilter.valueOf()
		}
	}
	return true
}

const onChange = (props: Props, date: Date) => {
	if (!isNullOrUndefined(date)) {
		date.setSeconds(0, 0)
	}
	// This will call 'setFieldValue'
	props.onChange(props.item.property, date)
}

const onBlur = (props: Props) => {
	// This will call 'setFieldTouched'
	props.onBlur(props.item.property, true)
}
