// External Dependencies
import * as React from 'react'

// Internal Dependencies
import { BaseTablePrimitiveProps } from '../index'
import { BaseTableCell } from '../BaseTableCell'

type Props<T> = BaseTablePrimitiveProps<T>

export const TablePrice: React.FC<Props<any>> = (props: Props<any>) => {
	const rawValue = props.item[props.column.property]
	const cellContents = `$${((rawValue as number)).toFixed(2)}`
	return (
		<BaseTableCell {...props}>
			{cellContents}
		</BaseTableCell>
	)
}
