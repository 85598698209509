import * as React from 'react'
import { Route, withRouter, RouteProps } from 'react-router-dom'
import { Page } from '../helpers/analytics'
import * as Intercom from 'react-intercom'

const routeComponent = ({ component, ...rest }: any) => {
	const path = rest.path as any
	(Intercom as any).IntercomAPI('trackEvent', path)

	const componentForRoute = (props: RouteProps) => {
		return React.createElement(component, props)
	}
	return <Route {...rest} render={componentForRoute} />
}

export const RouteComponent = withRouter(routeComponent)
