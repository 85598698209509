// External Dependencies
import { Action, Dispatch } from 'redux'
import { LocationDescriptorObject } from 'history'
import * as queryString from 'query-string'

// Internal Dependencies
import * as Constants from '../constants'

/**
 * Data Types
 */
export enum AllowedEntryResourceType {
	Event = 'event',
	Post = 'post'
}

export interface EntryOverride {
	resourceType: AllowedEntryResourceType,
	resourceID: string
}

/**
 * Action Types
 */

export type ActionTypes =
	| SetEntryOverride
	| ClearPendingEntryOverride

export interface SetEntryOverride extends Action {
	type: TypeKeys.SET_APP_ENTRY_OVERRIDE,
	entryOverride: EntryOverride
}

export interface ClearPendingEntryOverride extends Action {
	type: TypeKeys.CLEAR_PENDING_ENTRY_OVERRIDE,
}

/**
 * Action
 */

export enum TypeKeys {
	SET_APP_ENTRY_OVERRIDE = 'SET_APP_ENTRY_OVERRIDE',
	CLEAR_PENDING_ENTRY_OVERRIDE = 'CLEAR_PENDING_ENTRY_OVERRIDE'
}

// -----------------------------------------------------------------------------
// Set Application Entry Override
// Note: This will store information about a resource that should be presented
// to the user once they have successfully authenticated.
// -----------------------------------------------------------------------------
const setAppEntryOverride = (resourceType: AllowedEntryResourceType, resourceID: string) => (dispatch: Dispatch<any>) => {
	const override: SetEntryOverride = {
		type: TypeKeys.SET_APP_ENTRY_OVERRIDE,
		entryOverride: {
			resourceID,
			resourceType
		}
	}
	dispatch(override)
}

// -----------------------------------------------------------------------------
// Clear Entry Override
// -----------------------------------------------------------------------------
const clearAppEntryOverride = () => (dispatch: Dispatch<any>) => {
	dispatch({
		type: TypeKeys.CLEAR_PENDING_ENTRY_OVERRIDE,
	})
}

// -----------------------------------------------------------------------------
// URL Helpers
// -----------------------------------------------------------------------------

export const urlForEntryOverride = (override: EntryOverride): LocationDescriptorObject => {
	switch (override.resourceType) {
		case AllowedEntryResourceType.Event:
			const queryParams = { eventID: `${override.resourceID}` }
			return {
				pathname: Constants.VIEW_EVENT_ROUTE,
				search: queryString.stringify(queryParams)
			}
		default:
			throw new Error(`Invalid url entry override type: ${override.resourceType}`)
	}
}

export const AppActions = {
	setAppEntryOverride,
	clearAppEntryOverride,
}
