// External Dependencies
import { Dispatch, Action } from 'redux'
import to from 'await-to-js'

// Internal Dependencies

// API Helpers
import { POST } from '../helpers/api'

// Actions
export enum TypeKeys {
	ACCEPT_INVITATION = 'ACCEPT_INVITATION',
	SEND_INVITATION = 'SEND_INVITATION'
}

export type ActionTypes =
	| AcceptInvitationAction
	| SendInvitationAction

/**
 * Accepts an invitation.
 */
export interface AcceptInvitationAction extends Action {
	type: TypeKeys.ACCEPT_INVITATION
}

export interface SendInvitationAction extends Action {
	type: TypeKeys.SEND_INVITATION
}

/**
 * Accepts an invitation.
 */
const acceptInvitation = (inviteCode: string, password: string) => async (dispatch: Dispatch<any>) => {
	const [err, invitationRes] = await to(POST(`/invitations/accept`, { inviteCode, password }))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to accept Invitation with error: ${err}`)
		throw err
	}
}

/**
 * Sends an invitation.
 */
const sendInvitation = (inviteeID: string) => async (dispatch: Dispatch<SendInvitationAction>) => {
	const [err, invitationRes] = await to(POST(`/admin/invitations`, { userID: inviteeID }))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to accept Invitation with error: ${err}`)
		throw err
	}
}

/**
 * Sends an invitation.
 */
const sendInvitationToGroup = (groupID: string) => async (dispatch: Dispatch<SendInvitationAction>) => {
	const [err, invitationRes] = await to(POST(`/admin/invitations`, { groupID: groupID }))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to accept Invitation with error: ${err}`)
		throw err
	}
}

export const InvitationActions = {
	acceptInvitation,
	sendInvitation,
	sendInvitationToGroup
}
