import * as core from 'club-hub-core'
import { oc } from 'ts-optchain'

import { PageHeaderSelectInput, PageHeaderInputType } from '../components/Shared/PageHeader'

/**
 * Filters calendars for a specific calendar group.
 */
export const calendarsForGroup = (group: core.Calendar.Group, calendars: core.Calendar.Model[]) => {
	if (!group) {
		return []
	}
	return calendars.filter((c) => c.groupID === group._id)
}

/**
 * Builds an array of SelectInput objects for a list of calendars
 * @param resourceType The resource type which has select options.
 * @param club The club object which contains the type config.
 * @param filter The filter to apply.
 * @param changeHandler Change handler called when a type is selected.
 */
export const selectInputsForCalendar = (calendars: core.Calendar.Model[], filter: string, changeHandler: any): PageHeaderSelectInput[] => {
	const allTypes = { label: 'All', value: 'All' }
	const typesForSelect = calendars.map((cal) => {
		return {
			label: cal.name,
			value: `${cal._id}`,
		}
	})
	const filterTypesForSelect = [allTypes].concat(typesForSelect)

	const selectedValue = (filter) ?
		filterTypesForSelect.find((item) => item.value === filter) :
		allTypes

	return [{
		inputType: PageHeaderInputType.Select,
		inputs: [allTypes].concat(typesForSelect),
		changeHandler: changeHandler,
		selected: selectedValue,
	}]
}

/**
 * Fetches the most relevant array of reservation settings.
 */
export const getReservationSettings = (group: core.Calendar.Group, calendar: core.Calendar.Model) => {
	const groupSettings = oc(group).reservationSettings([])
	const calendarSettings = oc(calendar).reservationSettings([])
	return calendarSettings.length ? calendarSettings : groupSettings
}
