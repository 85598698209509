// External Dependencies
import * as core from 'club-hub-core'
import { createSelector } from 'reselect'
import { indexBy, union, uniq } from 'underscore'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'

// Internal Dependencies
import { ActionTypes, TypeKeys as UserActionTypeKeys } from '../actions/user'
import { mergeUpdatedModelObject, mergeUpdatedModelObjects, removeObject, removeObjects } from '../helpers/reducer'
import { RootReducerState } from './index'

// Style Helper
import { updateGlobalStyle } from '../helpers/style'

export interface AuthInterface {
	club: core.Club.Model
	token: string
	user: core.User.Model
}

export interface UsersByID {
	[id: string]: core.User.Model
}

/**
 * Stores all user data.
 * @param currentUser - Used when we need to store a single user while navigating between component.
 * @param currentRelations - Stores relations for a single user (family members).
 * @param loggedInUser - The currently logged in user.
 * @param users - Stores users of all users types.
 * @param userCount - Number of total users.
 * @param isAdmin - If this currently logged in user is an admin or not.
 * @param toggledCustomerView - If the user has toggled the customer view.
 */
export const defaultState = {
	currentUser: null as core.User.Model | null,
	currentRelations: [] as core.Relation.Model[],
	loggedInUser: null as core.User.Model | null,
	users: [] as core.User.Model[],
	userCount: 0,
	isAdmin: false,
	isMeshAdmin: false,
	toggledCustomerView: false,
}

export type UserState = typeof defaultState

const userReducer = (state: UserState = defaultState, action: ActionTypes): UserState => {
	switch (action.type) {
		case UserActionTypeKeys.AUTHENTICATED_USER:
			updateGlobalStyle(action.club)
			return {
				...state,
				loggedInUser: action.user,
				isAdmin: action.user.admin,
				isMeshAdmin: isMeshAdmin(action.user.email)
			}
		case UserActionTypeKeys.FETCHED_USERS_PAGINATED:
			return {
				...state,
				users: action.users,
			}
		case UserActionTypeKeys.FETCHED_ALL_USERS:
			return {
				...state,
				users: action.users,

			}
		case UserActionTypeKeys.FETCHED_USER:
			return {
				...state,
				currentUser: action.user,
			}
		case UserActionTypeKeys.FETCHED_USER_RELATIONS:
			return {
				...state,
				currentRelations: action.relations,
			}
		case UserActionTypeKeys.CREATED_USER:
			return {
				...state,
				users: [...state.users, action.user],
				userCount: state.userCount + 1,
			}
		case UserActionTypeKeys.UPDATED_USER:
			return {
				...state,
				users: mergeUpdatedModelObject(action.user, state.users),
				userCount: state.userCount
			}
		case UserActionTypeKeys.UPDATED_USERS:
			return {
				...state,
				users: mergeUpdatedModelObjects(action.users, state.users),
				userCount: state.userCount
			}
		case UserActionTypeKeys.DELETED_USER:
			return {
				...state,
				users: removeObject(action.userID, state.users),
				userCount: state.userCount
			}
		case UserActionTypeKeys.DELETED_USERS:
			return {
				...state,
				users: removeObjects(action.userIDs, state.users),
				userCount: state.userCount
			}
		case UserActionTypeKeys.CREATED_USER_META:
		case UserActionTypeKeys.UPDATED_USER_META:
		case UserActionTypeKeys.DELETED_USER_META:
			return {
				...state,
				users: mergeUpdatedModelObject(action.user, state.users),
			}
		case UserActionTypeKeys.SIGN_OUT:
			return {
				...defaultState
			}
		case UserActionTypeKeys.SET_CURRENT_USER:
			return {
				...state,
				currentUser: action.user
			}
		case UserActionTypeKeys.CLEAR_CURRENT_USER:
			return {
				...state,
				currentUser: null
			}
		case UserActionTypeKeys.TOGGLED_CUSTOMER_VIEW:
			return {
				...state,
				toggledCustomerView: !state.toggledCustomerView
			}
		default:
			return state
	}
}

/**
 * Helper for determining if the logged in user is a member of our team. Poor man's
 * super-user detection for the time being.
 */

const isMeshAdmin = (email: string): boolean => {
	const domain = email.replace(/.*@/, '')
	return domain === 'meshstudio.io' || domain === 'tryclubhub.com'
}

/**
 * The following data structures are created using resources from the user reducer.
 * They are updated when resources in the user reducer change.
 */

// Selectors
const users = (state: RootReducerState) => state.user.users
const loggedInUser = (state: RootReducerState) => state.user.loggedInUser
const toggledCustomerView = (state: RootReducerState) => state.user.toggledCustomerView

/**
 * Array of guest users (users with a member status of guest).
 * @returns Core.Model.User[]
 */
export const guestsSelector = createSelector([users], (userList) => {
	return userList.filter((u) => u.memberStatus === core.User.MemberStatus.Guest)
})

/**
 * Members who can book reservations.
 */
export const bookableMembers = createSelector([users], (userList) => {
	return userList.filter((u) => u.displaySettings)
})

/**
 * Array of admin users.
 * @returns Core.Model.User[]
 */
export const adminsSelector = createSelector([users], (userList) => {
	return userList.filter((u) => u.admin === true)
})

/**
 * Array of member users (users that don't have a member status of guest).
 * @returns Core.Model.User[]
 */
export const membersSelector = createSelector([users], (userList) => {
	return userList.filter((u) => u.memberStatus !== core.User.MemberStatus.Guest)
})

/**
 * Map of users keyed by their user_id.
 * @returns { [id: string]: core.User.Model }
 */
export const usersByIDSelector = createSelector([users], (userList) => indexBy(userList, '_id'))
export const userIsAdminSelector = createSelector([loggedInUser], (user) => oc(user).admin(false) === true)
export const userClubIDSelector = createSelector([loggedInUser], (user) => {
	if (isNullOrUndefined(user)) {
		return null
	}
	return `${user.clubID}`
})
export const inCustomerViewSelector = createSelector([loggedInUser, toggledCustomerView], (user, customerViewToggled) => {
	const userIsAdmin = oc(user).admin(false)
	return userIsAdmin === false || userIsAdmin === true && customerViewToggled
})

export default userReducer
