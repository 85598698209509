// Internal Dependencies
import { TableColumn } from '../index'

// Primitives
import { TableText } from './Text'
import { TableDate } from './Date'
import { TablePrice } from './Price'
import { TableBadge } from './Badge'
import { TableImage } from './Image'
import { TableProfileImage } from './ProfileImage'
import { TableAvatarList } from './AvatarList'
import { TableDropdown } from './Dropdown'
import { TableCheckbox } from './Checkbox'
import { TableAvatarAndName } from './AvatarAndName'
import { TableCustomJSX } from './CustomJSX'
import { TableArrayLength } from './ArrayLength'
import { TableBlank } from './Blank'

// Interfaces / Types
export enum TableColumnType {
	BLANK = 'BLANK',
	TEXT = 'TEXT',
	DATE_TIME = 'DATE_TIME',
	DATE = 'DATE',
	TIME = 'TIME',
	PRICE = 'PRICE',
	BADGE = 'BADGE',
	IMAGE = 'IMAGE',
	PROFILE_IMAGE = 'PROFILE_IMAGE',
	ARRAY_LENGTH = 'ARRAY_LENGTH',
	DROPDOWN = 'DROPDOWN',
	AVATAR_LIST = 'AVATAR_LIST',
	CHECKBOX = 'CHECKBOX',
	AVATAR_AND_NAME = 'AVATAR_AND_NAME',
	CUSTOM_JSX = 'CUSTOM_JSX'
}

export interface BaseTablePrimitiveProps<T> {
	item: T
	column: TableColumn
	className: string
	index: number
}

export const TablePrimitiveMap: { [key: string]: React.FC<BaseTablePrimitiveProps<any> & any> } = {
	[TableColumnType.TEXT]: TableText,
	[TableColumnType.DATE_TIME]: TableDate,
	[TableColumnType.DATE]: TableDate,
	[TableColumnType.TIME]: TableDate,
	[TableColumnType.PRICE]: TablePrice,
	[TableColumnType.BADGE]: TableBadge,
	[TableColumnType.IMAGE]: TableImage,
	[TableColumnType.PROFILE_IMAGE]: TableProfileImage,
	[TableColumnType.ARRAY_LENGTH]: TableArrayLength,
	[TableColumnType.DROPDOWN]: TableDropdown,
	[TableColumnType.AVATAR_LIST]: TableAvatarList,
	[TableColumnType.CHECKBOX]: TableCheckbox,
	[TableColumnType.AVATAR_AND_NAME]: TableAvatarAndName,
	[TableColumnType.CUSTOM_JSX]: TableCustomJSX,
	[TableColumnType.BLANK]: TableBlank
}
