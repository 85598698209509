// External Dependencies
import * as React from 'react'

// Internal Dependencies

// Helpers
import { setStateAsync } from '../../../helpers/promise'

export interface TabBarButtonInput {
	title: string
	onClick: () => any
	active?: boolean
}

interface ComponentProps {
	buttons: TabBarButtonInput[]
}

const initialState = {
	activeButton: null as string | null
}

type Props = ComponentProps
type State = typeof initialState

export class TabBarComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
	}

	componentDidMount = async () => {
		if (!this.props.buttons || !this.props.buttons.length) { return }
		let activeButtonKey: string = `button_${this.props.buttons[0].title}_0`
		// Overwrite the default active key if a tab input has the active key set.
		this.props.buttons.forEach((b, index) => {
			if (b.active) {
				activeButtonKey = `button_${b.title}_${index}`
			}
		})
		await setStateAsync(this, { activeButton: activeButtonKey })
	}

	handleButtonClick = async (key: string, button: TabBarButtonInput) => {
		await setStateAsync(this, { activeButton: key })
		return button.onClick()
	}

	buildTabBar = () => {
		const buttons = this.props.buttons.map((button, index) => {
			const buttonKey = `button_${button.title}_${index}`
			const isActive = (button.active !== undefined) ? button.active : buttonKey === this.state.activeButton
			const buttonClass = (isActive) ? 'active' : ''

			return (
				<button
					key={buttonKey}
					onClick={() => this.handleButtonClick(buttonKey, button)}
					className={`tab-bar-btn btn-link no-glow ${buttonClass}`}
				>
					{button.title}
				</button>
			)
		})

		return (
			<div className='tab-bar'>
				{buttons}
			</div>
		)
	}

	render() {
		return this.buildTabBar()
	}
}
