// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'

interface ComponentProps {
	isChecked: boolean
	className: string
	onChange: () => any
}

type Props = ComponentProps
type State = any

export default class CheckboxComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
	}

	buildCheckboxComponent = () => {
		return (
			<div className={this.props.className}>
				<RS.Input
					type='checkbox'
					checked={this.props.isChecked}
					// tslint:disable-next-line
					onChange={this.props.onChange}
				/>
			</div>
		)
	}

	render() {
		return this.buildCheckboxComponent()
	}
}
