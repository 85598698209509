// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as RS from 'reactstrap'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { isEqual } from 'underscore'
import { oc } from 'ts-optchain'

// Internal Dependencies
import { NavLinkPressed } from '../../helpers/analytics'

// Actions
import { SectionActions, AlertActions } from '../../actions/index'

// State
import { RootReducerState } from '../../reducers'
import { inCustomerViewSelector } from '../../reducers/user'

// Components
import DimmedLoader from '../Shared/DimmedLoader'

// Helpers
import { setStateAsync } from '../../helpers/promise'
import * as Constants from '../../constants'

export interface WikiSection {
	key: string
	title: string
	component: any
}

interface ComponentProps {
	title: string
	sections: WikiSection[]
}

const initialState = {
	loading: false,
	activeSectionKey: null as string
}

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

type Props = ComponentProps & ConnectedActions & ConnectedState
type State = typeof initialState

class WikiComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)

		const activeSectionKey = this.props.sections[0].key
		this.state = { ...initialState, activeSectionKey }
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Method
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		await setStateAsync(this, { loading: false })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	handlePageChange = async (key: string) => {
		// Track nav
		NavLinkPressed(key)

		// Set State
		await setStateAsync(this, { activeSectionKey: key })
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildPagesNav = () => {
		const activeSectionKey = oc(this).state.activeSectionKey()
		const pages = this.props.sections.map((section: WikiSection, idx) => {
			return (
				<RS.NavItem
					key={`${section.key}_${idx}`}
					active={activeSectionKey && `${section.key}` === `${activeSectionKey}`}
					onClick={() => this.handlePageChange(section.key)}
				>
					{section.title}
				</RS.NavItem>
			)
		})

		return (
			<div className='wiki-nav-panel'>
				<h6 className='wiki-nav-panel-header text-uppercase'>{this.props.title}</h6>
				<RS.Nav vertical={true}>
					{pages}
				</RS.Nav>
			</div>
		)
	}

	buildPageBody = () => {
		const activeSectionKey = oc(this).state.activeSectionKey()
		const section = this.sectionForKey(activeSectionKey)
		return (
			<div className='wiki-page-body'>
				<h1>{section.title}</h1>
				<hr />
				{section.component}
			</div>
		)
	}

	buildContent = () => {
		return (
			<div className='card'>
				<RS.Row noGutters={true}>
					<RS.Col sm={2} className='wiki-nav-container'>
						{this.buildPagesNav()}
					</RS.Col>
					<RS.Col sm={10} className='wiki-page-container'>
						{this.buildPageBody()}
					</RS.Col>
				</RS.Row>
			</div>
		)
	}

	render() {
		if (this.state.loading) { return <DimmedLoader component={null} isLoading={true} /> }

		return (
			<div className='wiki-container'>
				{this.buildContent()}
			</div>
		)
	}

	sectionForKey = (key: string) => {
		return this.props.sections.find((section: WikiSection) => section.key === key)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	sectionState: state.section,
	userState: state.user,
	isCustomerView: inCustomerViewSelector(state),
})

const mapDispatchToProps = {
	...SectionActions,
	...AlertActions
}

export default connect(mapStateToProps, mapDispatchToProps)(WikiComponent)
