// External Dependencies
import * as React from 'react'

// Internal Dependencies
import { BaseTablePrimitiveProps } from '../index'

// Helpers
import { ImageSize, isValidImage } from '../../../../../helpers/image'
import { BaseTableCell } from '../BaseTableCell'

type Props<T> = BaseTablePrimitiveProps<T>

export const TableProfileImage: React.FC<Props<any>> = (props: Props<any>) => {
	// Check if the item has an image
	const hasImage = props.item.hasOwnProperty(props.column.property)

	const imageURL: string = props.item[props.column.property][ImageSize.Medium]
	// Guarding against seeded URLs that don't point to actual images
	const validImage = isValidImage(imageURL)
	const avatar = (hasImage && validImage) ?
		(<span className='avatar' style={{ backgroundImage: `url(${imageURL})` }}/>) :
		(<span className='avatar avatar-placeholder' />)

	return (
		<BaseTableCell {...props}>
			<div className='text-center'>
				{avatar}
			</div>
		</BaseTableCell>
	)
}
