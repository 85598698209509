// External Dependencies
import * as Yup from 'yup'

// Internal Dependencies
import { FormInput, FormInputType, ReactSelectItem } from '../../Shared/Form'
import * as Constants from '../../../constants/index'

export interface VehicleFormState {
	member: ReactSelectItem,
	make: string,
	model: string,
	year: string,
	color: string,
	vehicleNumber: string,
	description: string,
	vin: string,
	keySpots: string,
	bay: number,
	image?: File[]
}

export const AdminVehicleFormInputs = (members: ReactSelectItem[]): FormInput[] => {
	const inputs: FormInput[] = [
		{
			title: 'Member',
			property: 'member',
			type: FormInputType.SELECT,
			size: 6,
			placeholder: 'Select Member...',
			selectItems: members,
			defaultValue: null,
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Year',
			property: 'year',
			type: FormInputType.NUMBER,
			size: 6,
			placeholder: 'Enter Year...',
			defaultValue: '',
		},
		{
			title: 'Make',
			property: 'make',
			type: FormInputType.TEXT,
			size: 6,
			placeholder: 'Enter Make...',
			defaultValue: '',
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Model',
			property: 'model',
			type: FormInputType.TEXT,
			size: 6,
			placeholder: 'Enter Model...',
			defaultValue: '',
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Color',
			property: 'color',
			type: FormInputType.TEXT,
			size: 6,
			placeholder: 'Enter Color...',
			defaultValue: '',
		},
		{
			title: 'VIN',
			property: 'vin',
			type: FormInputType.TEXT,
			size: 6,
			placeholder: 'Enter VIN...',
			defaultValue: '',
		},
		{
			title: 'Bay',
			property: 'bay',
			type: FormInputType.NUMBER,
			size: 6,
			placeholder: 'Enter Bay...',
			defaultValue: '',
		},
		{
			title: 'Vehicle image',
			property: 'image',
			type: FormInputType.FILE,
			size: 12,
			defaultValue: null,
			file_accept: '.png,.jpg,.jpeg',
			file_multipleFiles: false,
		},
		{
			title: 'Description',
			property: 'description',
			type: FormInputType.TEXT_AREA,
			size: 12,
			placeholder: 'Enter Description...',
			defaultValue: '',
		}
	]
	return inputs
}

export const CustomerVehicleFormInputs = (): FormInput[] => {
	const inputs: FormInput[] = [
		{
			title: 'Year',
			property: 'year',
			type: FormInputType.TEXT,
			size: 6,
			placeholder: 'Enter Year...',
			defaultValue: '',
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Make',
			property: 'make',
			type: FormInputType.TEXT,
			size: 6,
			placeholder: 'Enter Make...',
			defaultValue: '',
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Model',
			property: 'model',
			type: FormInputType.TEXT,
			size: 6,
			placeholder: 'Enter Model...',
			defaultValue: '',
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Color',
			property: 'color',
			type: FormInputType.TEXT,
			size: 6,
			placeholder: 'Enter Color...',
			defaultValue: '',
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'VIN',
			property: 'vin',
			type: FormInputType.TEXT,
			size: 6,
			placeholder: 'Enter VIN...',
			defaultValue: '',
		},
		{
			title: 'Vehicle image',
			property: 'image',
			type: FormInputType.FILE,
			size: 12,
			defaultValue: null,
			file_accept: '.png,.jpg,.jpeg',
			file_multipleFiles: false,
		},
		{
			title: 'Description',
			property: 'description',
			type: FormInputType.TEXT_AREA,
			size: 12,
			placeholder: 'Enter Description...',
			defaultValue: '',
		}
	]
	return inputs
}
