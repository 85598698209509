// External Dependencies
import * as React from 'react'
import {oc} from 'ts-optchain'

// Shared
import { ReservationActionButtons, ReservationCellAction } from '../../shared/ReservationActionButtons'

export interface DiningReservationCellProps {
	id: string
	isAdmin?: boolean
	columnSpan: number
	isOpen?: boolean
	isBlocked?: boolean
	isDisabled?: boolean
	isCurrent?: boolean
	name?: string
	spots?: number
	isCheckedIn?: boolean
	hasPaid?: boolean
	onClick?: () => Promise<void>
	onActionClick?: (action: ReservationCellAction) => Promise<void>
}

type Props = DiningReservationCellProps

export const DiningReservationCellComponent: React.FunctionComponent<Props> = (props: Props) => {
	if (props.isBlocked) {
		return buildBlockedCell(props)
	}
	return (props.isOpen) ? buildOpenCell(props) : buildReservedCell(props)
}

const buildOpenCell = (props: Props) => {
	const disabledClass = (props.isDisabled) ? 'disabled-reservation-cell' : ''
	const currentTimeClass = (props.isCurrent) ? 'current-time-reservation-cell' : ''
	return (
		<td
			id={props.id}
			className={`reservation-cell open-reservation-cell ${disabledClass} ${currentTimeClass}`}
			colSpan={props.columnSpan}
			onClick={(props.isDisabled) ? null : props.onClick}
		>
			<span/>
		</td>
	)
}

const buildReservedCell = (props: Props) => {
	const disabledClass = (props.isDisabled) ? 'disabled-reservation-cell' : ''
	const currentTimeClass = (props.isCurrent) ? 'current-time-reservation-cell' : ''
	const cellButtons = (props.isAdmin) ?
		(
		<ReservationActionButtons
			hasPaid={props.hasPaid}
			hasCheckedIn={props.isCheckedIn}
			includePayButton={false}
			onActionClick={props.onActionClick}
		/>
		) :
		null

	return (
		<td
			id={props.id}
			className={`reservation-cell closed-reservation-cell ${disabledClass} ${currentTimeClass}`}
			colSpan={props.columnSpan}
			onClick={(props.isDisabled) ? null : props.onClick}
		>
			<div className='reservation-cell-container'>
				<div className='reservation-details'>
					<div className='reservation-spots'>{props.spots}</div>
					<div className='reservation-name'>{props.name}</div>
				</div>
				{cellButtons}
			</div>
		</td>
	)
}

const buildBlockedCell = (props: Props) => {
	const currentTimeClass = (props.isCurrent) ? 'current-time-reservation-cell' : ''
	return (
		<td
			id={props.id}
			className={`reservation-cell closed-reservation-cell disabled-reservation-cell ${currentTimeClass}`}
			colSpan={props.columnSpan}
			onClick={props.onClick}
		>
			<div className='reservation-cell-container'>
				<div className='d-flex mx-auto'>{props.name}</div>
			</div>
		</td>
	)
}

export default DiningReservationCellComponent
