// External Dependencies
import * as core from 'club-hub-core'
import { Dispatch, Action } from 'redux'
import to from 'await-to-js'
import { oc } from 'ts-optchain'

// Internal Dependencies

// API Helpers
import { POST, PUT, DELETE } from '../helpers/api'

// Actions
export enum TypeKeys {
	CREATED_USER_GROUP = 'CREATED_USER_GROUP',
	UPDATED_USER_GROUP = 'UPDATED_USER_GROUP',
	DELETED_USER_GROUP = 'DELETED_USER_GROUP',
}

export type ActionTypes =
	| CreatedUserGroupAction
	| UpdatedUserGroupAction
	| DeletedUserGroupAction

export interface CreatedUserGroupAction extends Action {
	type: TypeKeys.CREATED_USER_GROUP,
	club: core.Club.Model
}

export interface UpdatedUserGroupAction extends Action {
	type: TypeKeys.UPDATED_USER_GROUP,
	club: core.Club.Model,
	group: core.User.UserGroup
}

export interface DeletedUserGroupAction extends Action {
	type: TypeKeys.DELETED_USER_GROUP,
	club: core.Club.Model
}

// -----------------------------------------------------------------------------
// Create User Group
// -----------------------------------------------------------------------------

const createUserGroup = (clubID: string, payload: any) => async (dispatch: Dispatch<CreatedUserGroupAction>) => {
	const [groupErr, club] = await to<core.Club.Model>(POST(`/admin/clubs/${clubID}/groups`, payload))
	if (groupErr) {
		// tslint:disable-next-line
		console.error(`Failed to create User Group with error: ${groupErr}`)
		throw groupErr
	}

	dispatch(createdUserGroup(club))
}

const createdUserGroup = (club: core.Club.Model): CreatedUserGroupAction => {
	const action: CreatedUserGroupAction = {
		type: TypeKeys.CREATED_USER_GROUP,
		club
	}
	return action
}

// -----------------------------------------------------------------------------
// Update User Group
// -----------------------------------------------------------------------------

const updateUserGroup = (clubID: string, groupID: string, payload: any) => async (dispatch: Dispatch<UpdatedUserGroupAction>) => {
	const [groupErr, club] = await to<core.Club.Model>(PUT(`/admin/clubs/${clubID}/groups/${groupID}`, payload))
	if (groupErr) {
		// tslint:disable-next-line
		console.error(`Failed to update User Group with error: ${groupErr}`)
		throw groupErr
	}

	const updatedGroup = oc(club).userGroups([]).find((ug) => `${ug._id}` === groupID)
	dispatch(updatedUserGroup(club, updatedGroup))
}

const updatedUserGroup = (club: core.Club.Model, group: core.User.UserGroup): UpdatedUserGroupAction => {
	const action: UpdatedUserGroupAction = {
		type: TypeKeys.UPDATED_USER_GROUP,
		club,
		group
	}
	return action
}

// -----------------------------------------------------------------------------
// Deleted User Group
// -----------------------------------------------------------------------------

const deleteUserGroup = (clubID: string, groupID: string) => async (dispatch: Dispatch<DeletedUserGroupAction>) => {
	const [deleteErr, club] = await to<core.Club.Model>(DELETE(`/admin/clubs/${clubID}/groups/${groupID}`, {}))
	if (deleteErr) {
		// tslint:disable-next-line
		console.error(`Failed to delete User Group with error: ${deleteErr}`)
		throw deleteErr
	}

	dispatch(deletedUserGroup(club))
}

const deletedUserGroup = (club: core.Club.Model): DeletedUserGroupAction => {
	const action: DeletedUserGroupAction = {
		type: TypeKeys.DELETED_USER_GROUP,
		club
	}
	return action
}

export const GroupActions = {
	createUserGroup,
	updateUserGroup,
	deleteUserGroup,
}
