// External Dependencies
import * as Feather from 'react-feather'
import * as core from 'club-hub-core'
import { isNullOrUndefined } from 'util'

// Internal Dependencies

// Components
import { DiningReservationComponentContext } from './index'

// Page Header
import {
	PageHeaderSelectInput,
	PageHeaderInputType,
	PageHeaderDateInput,
	PageHeaderDropdownInput
} from '../../Shared/PageHeader'
import { DropdownItemType } from '../../Shared/Dropdown'
import { FormInputType } from '../../Shared/Form'

// Helpers
import * as Constants from '../../../constants'
import { isDateValid } from '../../../helpers/date'

export enum MenuActions {
	NewEvent = 'newDiningEvent',
	ViewCalendar = 'viewCalendar',
	ViewSettings = 'viewSettings',
	NewReservation = 'newReservation',
}

export const LiveSelectInputs = (live: boolean, onChange: any): PageHeaderSelectInput => {
	// For Status Select:
	const statusesForSelect = [
		{
			label: 'Live',
			value: Constants.LiveStatus.Live,
			color: core.Constants.Colors.GREEN,
			type: 'tee-time-status',
			leadingText: 'Status:',
		},
		{
			label: 'Off',
			value:  Constants.LiveStatus.Off,
			color: core.Constants.Colors.RED,
			type: 'tee-time-status',
			leadingText: 'Status:',
		},
	]
	return {
		inputType: PageHeaderInputType.Select,
		className: 'diningReservationHeader-status-input',
		icon: Feather.Power,
		inputs: statusesForSelect,
		changeHandler: onChange,
		selected: live ? statusesForSelect[0] : statusesForSelect[1]
	}
}

export const CalendarSelectInputs = (calendars: core.Calendar.Model[], selected: core.Calendar.Model, onChange: any): PageHeaderSelectInput => {
	const calendarsForSelect = calendars.map((cal) => {
		return { label: cal.name, value: `${cal._id}` }
	})
	const selectedCalendar = calendarsForSelect.find((v) => v.value === `${selected._id}`)

	return {
		inputType: PageHeaderInputType.Select,
		className: 'diningReservationHeader-calendar-group-input',
		icon: Feather.Menu,
		inputs: calendarsForSelect,
		changeHandler: onChange,
		selected: selectedCalendar
	}

}

export const DateSelectInputs = (context: DiningReservationComponentContext): PageHeaderDateInput => {
	const valueExists = !isNullOrUndefined(context.state.selectedDate)
	const selectedDate = new Date(context.state.selectedDate)
	const selectedDateValue = (valueExists && isDateValid(selectedDate)) ? selectedDate : null

	return {
		inputType: PageHeaderInputType.Date,
		className: 'diningReservationHeader-datepicker',
		type: FormInputType.DATE,
		selected: selectedDateValue,
		changeHandler: context.handleDateChanged,
		isClearable: false,
		icon: Feather.Calendar,
	}

}

export const MenuSelectInputs = (context: DiningReservationComponentContext): PageHeaderDropdownInput => {
	return {
		inputType: PageHeaderInputType.Dropdown,
		className: 'diningReservationHeader-dropdown',
		header: 'Menu',
		category: 'Menu',
		items: [
			{
				type: DropdownItemType.Default,
				icon: Feather.Calendar,
				text: 'New Dining Event',
				value: MenuActions.NewEvent,
			},
			{
				type: DropdownItemType.Default,
				icon: Feather.Eye,
				text: 'View Calendar',
				value: MenuActions.ViewCalendar,
			},
			{
				type: DropdownItemType.Default,
				icon: Feather.Settings,
				text: 'Settings',
				value: MenuActions.ViewSettings,
			},
			{ type: DropdownItemType.Divider },
			{
				type: DropdownItemType.Default,
				icon: Feather.PlusCircle,
				text: 'New Reservation',
				value: MenuActions.NewReservation,
			},
		],
		onChange: context.handleActionSelected,
		featherIcon: Feather.MoreVertical,
	}
}
