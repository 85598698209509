// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as RS from 'reactstrap'
import * as Feather from 'react-feather'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies

// Form
import { InputSelectionItem, FormInput } from '../../Shared/Form'

// Components
import GuestModal from '../GuestModal'
import FormModal from '../../Shared/Formik/FormModal'
import ModalComponent from '../../Shared/Modal'

// Actions
import { EventActions, AlertActions, CustomerActions } from '../../../actions/index'

// State
import { RootReducerState } from '../../../reducers'
import { inCustomerViewSelector, bookableMembers, guestsSelector, usersByIDSelector } from '../../../reducers/user'

// Helpers
import * as Constants from '../../../constants'
import { fullName, userForForm } from '../../../helpers/user'
import { SimpleGolfReservationInputs, ExpandedGolfReservationInputs, GolfReservationFormState } from './form'
import * as dateHelper from '../../../helpers/date'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

interface ComponentProps {
	eventTime: Date | string
	bookableEvent: core.Event.AvailableEvent
	reservation?: core.Event.Reservation,
	eventCalendarID: string,
	setting: core.Calendar.ReservationSetting
	onCancel: () => void,
	onSave: () => Promise<void>,
}

const initialState = {
	showModal: true,
	showingGuestModal: false,
	showingDeletionModal: false,
	duration: 0,
	hours: null as core.IShared.HoursOfOperation,
	loading: false,
	error: false,
	golferCount: 4,
	guestIndex: 0,
	expanded: false,
	owner: null as string,

	// Form Info
	date: null as Date | null,
	time: null as Date | null,
	count: 1, // Number of reservations.
	golfers: [] as string[],
	carts: 0,
	holes: 18,
	notes: null as string | null,
	comp: false as boolean
}

type Props = ConnectedState & ConnectedActions & ComponentProps
type State = typeof initialState

class CreateGolfReservationForm extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)

		const state: State = { ...initialState }

		if (props.reservation) {
			const reservation = props.reservation
			const golfers = this.getGolferInputsForReservation(reservation)
			state.golfers = golfers
			state.golferCount = golfers.length
			state.notes = oc(reservation).notes(oc(reservation).meta.notes(''))
			state.expanded = true
		}

		const date = new Date(props.eventTime)
		const hours = this.getHoursOfOperation(date)
		const duration = oc(hours).duration(this.props.setting.bookingDuration)
		this.state = { ...state, date, hours, duration }
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Toggles the modal to display or not
	 */
	handleToggle = async () => {
		this.setState({ showModal: !this.state.showModal })
	}

	handleToggleExpanded = () => {
		this.setState({ expanded: !this.state.expanded })
	}

	toggleDeletionModal = () => {
		this.setState({ showingDeletionModal: !this.state.showingDeletionModal })
	}

	/**
	 * Toggles the guest modal to display or not.
	 */
	toggleGuestModal = async () => {
		this.setState({ showingGuestModal: !this.state.showingGuestModal })
	}

	/**
	 * Called when the user closes out of the modal
	 */
	handleCancel = async () => {
		this.props.onCancel()
	}

	handleChange = (field: string, value: any) => {
		if (field === 'count') {
			this.setState({ count: value.value })
		}

		if (field === 'time') {
			const date = new Date(this.state.date)
			const updated = dateHelper.setTimeForDate(date, value)
			this.setState({ time: updated })
		}

		if (field.includes('golfers')) {
			const item = value as InputSelectionItem
			if (oc(item).value('') === 'addGuest') {
				this.toggleGuestModal()
			} else if (this.state.golfers.length === 0) {
				this.setState({ golfers: [item.value] })
			}
		}
	}

	handleChangeGolferCount = (change: number) => {
		this.setState({ golferCount: this.state.golferCount + change })
	}

	// ----------------------------------------------------------------------------------
	// Form Button Handlers
	// ----------------------------------------------------------------------------------

	handleSubmit = async (formState: GolfReservationFormState) => {
		if (oc(formState).golfers([]).length === 0) {
			return this.props.fireFlashMessage('Please select at least one golfer.', Constants.FlashType.WARNING)
		}

		this.setState({ loading: true })

		let err
		let result
		if (this.props.reservation) {
			[err, result] = await to(this.updateReservation(formState))
		} else {
			[err, result] = await to(this.createReservations(formState))
		}

		if (err) { return }

		await this.props.onSave()
		this.handleToggle()
		this.setState({ loading: false })
	}

	// ----------------------------------------------------------------------------------
	// Reservation Network Requests
	// ----------------------------------------------------------------------------------

	createReservations = async (formState: GolfReservationFormState) => {
		const time = oc(formState).time.value()

		const promises = []
		for (let i = 0; i < this.state.count; i++) {
			const bookingTime = time + (i * this.state.duration * 60 * 1000)
			const updatedState = { ...formState }
			updatedState.time.value = bookingTime
			promises.push(this.createReservation(updatedState))
		}
		return Promise.all(promises)
	}
	/**
	 * Called when the user is submitting a new tee time
	 */
	createReservation = async (formState: GolfReservationFormState) => {
		this.setState({ loading: true })

		const payload = this.buildReservationPayload(formState)
		const eventCalendarID = this.props.eventCalendarID

		const time = oc(formState).time.value()
		const timeDate = new Date(time)
		formState.date.setHours(timeDate.getHours(), timeDate.getMinutes()) // Update our date & time.

		const [err] = await to(this.props.reserveBookableEvent(payload, eventCalendarID, formState.date.toISOString()) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to reserve Event. ${err.message}`, Constants.FlashType.DANGER)
			this.setState({ loading: false })
			throw err
		}
		this.props.fireFlashMessage(`Tee Time Created`, Constants.FlashType.SUCCESS)
	}

	/**
	 * Called when the user is submitting an update to an existing tee time
	 */
	updateReservation = async (formState: GolfReservationFormState) => {
		this.setState({ loading: true })

		// Find the Event
		const reservationID = `${this.props.reservation._id}`
		const payload = this.buildReservationPayload(formState)
		const currentEvent = this.props.bookableEvent
		const existingEventID = oc(currentEvent).existingEvent._id()
		const [err] = await to(this.props.updateEventRsvp(reservationID, payload, `${existingEventID}`) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to reserve Event. ${err.message}`, Constants.FlashType.DANGER)
			this.setState({ loading: false })
			throw err
		}

		this.props.fireFlashMessage(`Tee Time Updated`, Constants.FlashType.SUCCESS)
	}

	/**
	 * Called when the user has confirmed that they would like to
	 * delete an existing tee time reservation
	 */
	deleteReservation = async () => {
		this.setState({ loading: true })

		// Find the Event with the Reservation ID
		const currentEvent = this.props.bookableEvent
		const existingEvent = currentEvent.existingEvent
		const reservationID = `${this.props.reservation._id}`
		const [err] = await to(this.props.cancelEventRsvp(reservationID, `${existingEvent._id}`) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to cancel Tee Time. ${err.message}`, Constants.FlashType.DANGER)
			this.setState({ loading: false })
			return
		}
		await this.props.onSave()
		this.handleToggle()
		this.props.fireFlashMessage(`Tee Time Canceled`, Constants.FlashType.SUCCESS)
	}

	/**
	 * Creates a new guest user in the DB.
	 * This guest user will be added to the input that 'Add Guest' was selected from.
	 * @returns void.
	 */
	handleSaveGuest = async (guest: core.User.Model) => {
		const guestData = { label: fullName(guest), value: `${guest._id}` }
		this.setState({ showingGuestModal: false })
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildModal = () => {
		const formInputs = this.getFormInputs()
		const formResource = this.buildFormResource()
		const formTitle = this.props.reservation ? 'Update Tee Time' : 'New Tee Time'
		const dangerSecondary = this.props.reservation ? true : false
		let secondaryName: string
		let secondaryHandler: any
		if (this.props.reservation) {
			secondaryName = 'Cancel Tee Time'
			secondaryHandler = this.toggleDeletionModal
		} else {
			secondaryName = this.state.expanded ? 'Less Options' : 'More Options'
			secondaryHandler = this.handleToggleExpanded
		}
		return (
			<FormModal
				className={'golf-reservation-modal form-component-container'}
				onChange={this.handleChange}
				enableReinitialize={true}
				modalTitle={formTitle}
				formSpec={formInputs}
				formResource={formResource}
				secondaryButtonName={secondaryName}
				secondaryButtonHandler={secondaryHandler}
				dangerSecondary={dangerSecondary}
				submitButtonHandler={this.handleSubmit}
				cancelButtonHandler={this.handleCancel}
				submitting={this.state.loading}
			/>
		)
	}

	buildGuestModal = () => {
		if (!this.state.showingGuestModal) { return null }

		return (
			<GuestModal
				club={this.props.loggedInClub}
				handleNewGuest={this.handleSaveGuest}
				handleToggle={this.toggleGuestModal}
			/>
		)
	}

	buildDeletionModal = () => {
		if (!this.state.showingDeletionModal) { return null }

		const title = 'Cancel Tee Time'
		const message = 'Are you sure you would like to cancel this Tee Time?'
		return (
			<ModalComponent
				modalTitle={title}
				primaryMessage={message}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.toggleDeletionModal}
				submitButtonName={'Confirm'}
				submitButtonHandler={this.deleteReservation}
			/>
		)
	}

	render() {
		// Wait until we have all the props we need
		if (!this.state.date) { return null }

		if (this.state.showingDeletionModal) {
			return this.buildDeletionModal()
		}
		if (this.state.showingGuestModal) {
			return this.buildGuestModal()
		}
		return this.buildModal()
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	buildAddGolferButton = (input: FormInput) => {
		return (
			<div className={'d-flex justify-content-end'}>
				<RS.ButtonGroup>
					<RS.Button color={'primary'} outline={true} onClick={() => this.handleChangeGolferCount(1)}>
						<Feather.PlusCircle size={20} />
					</RS.Button>
					<RS.Button color={'primary'} outline={true} onClick={() => this.handleChangeGolferCount(-1)}>
						<Feather.MinusCircle size={20} />
					</RS.Button>
				</RS.ButtonGroup>
			</div>
		)
	}

	buildFormResource = () => {
		const meta: core.Event.GolfReservationMeta = oc(this).props.reservation.meta()
		return {
			date: oc(this).state.date(),
			time: oc(this).state.date().getTime(),
			count: oc(this).state.count(),
			carts: oc(meta).golfCartCount(0),
			holes: oc(meta).holeCount(18),
			notes: oc(meta).notes(),
			owner: oc(this).state.owner(),
			golfers: oc(this).state.golfers(),
		}
	}

	getFormInputs = () => {
		const teeTimes = this.getTeeTimes()
		const selectItems = this.buildUserInputSelectItems()
		const timeSelectInputs = this.getTimeSelectInputs()
		return this.state.expanded ?
			ExpandedGolfReservationInputs(selectItems, timeSelectInputs, teeTimes, this.buildAddGolferButton) :
			SimpleGolfReservationInputs(selectItems, timeSelectInputs, teeTimes[0])
	}

	getTeeTimes = () => {
		return [{ date: this.state.date, golferCount: this.state.golferCount }]
	}

	getGolferInputsForReservation = (reservation: core.Event.Reservation) => {
		const participants = oc(reservation).participants([])
		const existingParticipants = participants.map((participant) => {
			const nullParticipant = isNullOrUndefined(participant.userID)
			return nullParticipant ? null : `${participant.userID}`
		})
		return existingParticipants
	}

	buildUserInputSelectItems = () => {
		const members = oc(this).props.bookableMembers([])
		const memberSelectItems = members.map((u: core.User.Model) => ({ ...userForForm(u), group: 'MEMBERS' }))
		const guests = oc(this).props.guests([])
		const guestSelectItems = guests.map((u: core.User.Model) => ({ ...userForForm(u), group: 'GUEST' }))
		const addGuestSelectItem: InputSelectionItem = { label: 'Add Outside Guest', value: 'addGuest', group: 'ADD GUEST' }
		return [addGuestSelectItem, ...memberSelectItems, ...guestSelectItems]
	}

	getTimeSelectInputs = () => {
		const hours = oc(this).state.hours()
		if (!hours) { return null }

		const opens = hours.opens ? new Date(hours.opens) : null
		const closes = hours.closes ? new Date(hours.closes) : null

		const startTime = new Date(this.state.date)
		startTime.setHours(opens.getHours())
		startTime.setMinutes(opens.getMinutes())

		const endTime = new Date(this.state.date)
		endTime.setHours(closes.getHours())
		endTime.setMinutes(closes.getMinutes())

		return dateHelper.inputSelectionItemsForRange(startTime, endTime, this.state.duration)
	}

	getHoursOfOperation = (date: Date) => {
		const dateForHours = date ? new Date(date) : new Date()
		const hours = oc(this).props.setting.hours([])
		return hours.find((hour: core.IShared.HoursOfOperation) => hour.dayOfWeek === dateForHours.getDay())
	}

	buildReservationPayload = (formState: GolfReservationFormState) => {
		const golfers = oc(formState).golfers([])
		const participants = []
		for (let i = 0; i < this.state.golferCount; i++) {
			const golfer = oc(golfers)[i]()
			if (golfer) {
				participants.push({ userID: golfer.value as any, name: golfer.label })
			} else {
				participants.push({ userID: null as any, name: 'Member' })
			}
		}

		// Create the reservation for the payload
		const reservation: core.Event.Reservation = {
			creator: this.props.userState.loggedInUser._id,
			owner: participants[0].userID,
			participants: participants,
			notes: formState.notes,
			comp: formState.comp,
			meta: {
				notes: formState.notes,
				golfCartCount: oc(formState).carts(0),
				holeCount: oc(formState).holes(18)
			}
		}
		return reservation
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	eventState: state.event,
	userState: state.user,
	calendarState: state.calendar,
	loggedInClub: state.club.loggedInClub,
	isCustomerView: inCustomerViewSelector(state),
	bookableMembers: bookableMembers(state),
	guests: guestsSelector(state),
	usersByID: usersByIDSelector(state)
})

const mapDispatchToProps = {
	...EventActions,
	...AlertActions,
	...CustomerActions,
}

const enhance = compose<React.ComponentType<ComponentProps>>(
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(CreateGolfReservationForm)
