// External Dependencies
import * as Yup from 'yup'

// Internal Dependencies
import { FormInput, FormInputType, InputSelectionItem } from '../../Shared/Form'

// Helpers
import { endOfDay, beginningOfDay, roundDateToNextHour } from '../../../helpers/date'
import * as Constants from '../../../constants/index'

export interface DiningSelectFormState {
	start?: Date
	guests?: InputSelectionItem
	calendarID?: InputSelectionItem
}

export const diningSelectForm = (calendarInputs: InputSelectionItem[], guestInputs: InputSelectionItem[]): FormInput[] => [
	{
		title: 'Restaurant',
		property: 'calendarID',
		type: FormInputType.SELECT,
		size: 12,
		placeholder: 'Select Restaurant...',
		selectItems: calendarInputs,
		validation: Yup.string().required(Constants.REQUIRED_FIELD),
		defaultValue: null,
	},
	{
		title: 'Date',
		property: 'start',
		type: FormInputType.DATE_TIME,
		size: 12,
		placeholder: 'Select Date...',
		defaultValue: roundDateToNextHour(new Date()),
		validation: Yup.date().nullable().required(Constants.REQUIRED_FIELD),
		date_filter_func: (date: Date) => date.getTime() >= beginningOfDay(new Date().toISOString()).getTime(),
		date_min_time: new Date(),
		date_max_time: endOfDay(new Date().toISOString()),
	},
	{
		title: 'Party Size',
		property: 'guests',
		type: FormInputType.SELECT,
		defaultValue: { label: `${4}`, value: `${4}` },
		size: 12,
		placeholder: 'Select number of guests in your party...',
		validation: Yup.string().required(Constants.REQUIRED_FIELD),
		selectItems: guestInputs,
	}
]
