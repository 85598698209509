// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'

interface ComponentProps {
	content: string
	class?: string
}

type Props = ComponentProps
type State = any

export default class RichContent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
	}

	render() {
		return (<div className={`rich-content-container ${this.props.class}`} dangerouslySetInnerHTML={{__html: this.props.content}} />)
	}
}
