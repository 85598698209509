// External Dependencies
import * as Yup from 'yup'

// Internal Dependencies
import { FormInput, FormInputType, InputSelectionItem } from '../../../Shared/Form'
import { stateSelectionInputs } from '../../../../helpers/form'
import * as core from 'club-hub-core'

// Utils
import * as Constants from '../../../../constants'

export const AdminUserFormInputs = (membershipTypes: InputSelectionItem[], club: core.Club.Model): FormInput[] => {
	const inputs: FormInput[] = [
		{
			title: 'First Name',
			property: 'firstName',
			type: FormInputType.TEXT,
			size: 4,
			placeholder: 'First',
			defaultValue: '',
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Middle',
			property: 'middleName',
			type: FormInputType.TEXT,
			size: 2,
			placeholder: 'Middle',
			defaultValue: '',
		},
		{
			title: 'Last Name',
			property: 'lastName',
			type: FormInputType.TEXT,
			size: 6,
			placeholder: 'Last',
			defaultValue: '',
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Profile Image',
			property: 'image',
			type: FormInputType.FILE,
			size: 12,
			file_accept: '.png,.jpg,.jpeg',
			file_multipleFiles: false,
			defaultValue: null,
		},
		{
			title: 'Personal Info',
			property: 'personalInfo',
			type: FormInputType.SECTION,
		},
		{
			title: 'Birthday',
			property: 'birthday',
			type: FormInputType.DATE,
			size: 6,
			placeholder: 'Select Date of Birth...',
			defaultValue: null,
		},
		{
			title: 'Profession',
			property: 'jobTitle',
			type: FormInputType.TEXT,
			size: 6,
			placeholder: 'Profession',
			defaultValue: '',
		},
		{
			title: 'Contact Info',
			property: '',
			type: FormInputType.SECTION,
		},
		{
			title: 'Email Address',
			property: 'email',
			type: FormInputType.EMAIL,
			size: 6,
			placeholder: 'Email',
			defaultValue: '',
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Phone Number',
			property: 'phone',
			type: FormInputType.TEXT,
			size: 6,
			placeholder: '(xxx) xxx-xxxx',
			defaultValue: '',
		},
		{
			title: 'Mailing Address',
			property: 'address',
			type: FormInputType.TEXT,
			size: 6,
			placeholder: 'Street Address',
			defaultValue: '',
		},
		{
			title: 'City',
			property: 'city',
			type: FormInputType.TEXT,
			size: 6,
			placeholder: 'City',
			defaultValue: '',
		},
		{
			title: 'State',
			property: 'state',
			type: FormInputType.SELECT,
			size: 6,
			placeholder: 'Select State...',
			selectItems: stateSelectionInputs,
			defaultValue: null,
		},
		{
			title: 'Zip',
			property: 'zip',
			type: FormInputType.TEXT,
			size: 6,
			placeholder: 'Zip Code',
			defaultValue: '',
		},
		{
			title: '',
			property: '',
			type: FormInputType.BLANK,
			size: 6,
		},
		{
			title: 'Club Info',
			property: '',
			type: FormInputType.SECTION,
		},
		{
			title: 'Member Number',
			property: 'number',
			type: FormInputType.TEXT,
			size: 6,
			placeholder: 'Member Number',
			defaultValue: '',
		},
		{
			title: 'Membership',
			property: 'membershipType',
			type: FormInputType.GROUPED_SELECT,
			size: 6,
			placeholder: 'Select Membership...',
			selectItems: membershipTypes,
			defaultValue: null,
			validation: Yup.string().nullable().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Status',
			property: 'memberStatus',
			type: FormInputType.SELECT,
			size: 6,
			placeholder: 'Select Status...',
			selectItems: [
				{ label: 'Active', value: core.User.MemberStatus.Active },
				{ label: 'Prospect', value: core.User.MemberStatus.Prospect },
				{ label: 'Lapsed', value: core.User.MemberStatus.Lapsed },
			],
			defaultValue: null,
		},
		{
			title: 'Date Joined',
			property: 'joined',
			type: FormInputType.DATE,
			size: 6,
			placeholder: 'Select Date Joined...',
			defaultValue: null,
		},
		{
			title: 'Admin',
			property: 'admin',
			type: FormInputType.SELECT,
			size: 6,
			placeholder: 'Is Admin...',
			selectItems: [{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }],
			defaultValue: { label: 'No', value: 'false' },
		},
		{
			title: 'Agent Access Code',
			property: 'agentAccessCode',
			type: FormInputType.TEXT,
			placeholder: 'Enter Agent Access Code...',
			size: 6,
			defaultValue: '',
		},
		{
			title: 'Stall Numbers',
			property: 'stallNumbers',
			type: FormInputType.TEXT,
			placeholder: 'Enter Stall Numbers...',
			size: 6,
			defaultValue: '',
		},
		{
			title: 'Privacy Settings',
			property: '',
			type: FormInputType.SECTION,
		},
		{
			title: 'Public Profile',
			property: 'publicProfile',
			type: FormInputType.SELECT,
			size: 6,
			selectItems: [
				{ label: 'Public', value: 'true' },
				{ label: 'Private', value: 'false' }
			],
			defaultValue: null,
		},
		{
			title: 'Public Contact Info',
			property: 'publicContact',
			type: FormInputType.SELECT,
			size: 6,
			selectItems: [
				{ label: 'Public', value: 'true' },
				{ label: 'Private', value: 'false' }
			],
			defaultValue: null,
		},
	]

	return inputs
}

export const MemberUserFormInputs = (club: core.Club.Model): FormInput[] => {
	const inputs: FormInput[] = [
		{
			title: 'First Name',
			property: 'firstName',
			type: FormInputType.TEXT,
			size: 4,
			placeholder: 'First',
			defaultValue: '',
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Middle',
			property: 'middleName',
			type: FormInputType.TEXT,
			size: 2,
			placeholder: 'Middle',
			defaultValue: '',
		},
		{
			title: 'Last Name',
			property: 'lastName',
			type: FormInputType.TEXT,
			size: 6,
			placeholder: 'Last',
			defaultValue: '',
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Email Address',
			property: 'email',
			type: FormInputType.EMAIL,
			size: 6,
			placeholder: 'Email',
			defaultValue: '',
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Phone Number',
			property: 'phone',
			type: FormInputType.TEXT,
			size: 6,
			placeholder: '(xxx) xxx-xxxx',
			defaultValue: '',
		},
		{
			title: 'Birthday',
			property: 'birthday',
			type: FormInputType.DATE,
			size: 6,
			placeholder: 'Select Date of Birth...',
			defaultValue: null,
			validation: Yup.date().nullable().default(null).required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Profession',
			property: 'jobTitle',
			type: FormInputType.TEXT,
			size: 6,
			placeholder: 'Profession',
			defaultValue: '',
		},
		{
			title: 'Image',
			property: 'image',
			type: FormInputType.FILE,
			size: 6,
			file_accept: '.png,.jpg,.jpeg',
			file_multipleFiles: false,
			defaultValue: null,
		},
		{
			title: 'Mailing Address',
			property: 'address',
			type: FormInputType.TEXT,
			size: 6,
			placeholder: 'Street Address',
			defaultValue: '',
		},
		{
			title: 'City',
			property: 'city',
			type: FormInputType.TEXT,
			size: 6,
			placeholder: 'City',
			defaultValue: '',
		},
		{
			title: 'State',
			property: 'state',
			type: FormInputType.SELECT,
			size: 3,
			placeholder: 'Select State...',
			selectItems: stateSelectionInputs,
			defaultValue: null,
		},
		{
			title: 'Zip',
			property: 'zip',
			type: FormInputType.TEXT,
			size: 3,
			placeholder: 'Zip Code',
			defaultValue: '',
		},
		{
			title: 'Public Profile',
			property: 'publicProfile',
			type: FormInputType.SELECT,
			size: 6,
			selectItems: [
				{ label: 'Public', value: 'true' },
				{ label: 'Private', value: 'false' }
			],
			defaultValue: null,
		},
		{
			title: 'Public Contact Info',
			property: 'publicContact',
			type: FormInputType.SELECT,
			size: 6,
			selectItems: [
				{ label: 'Public', value: 'true' },
				{ label: 'Private', value: 'false' }
			],
			defaultValue: null,
		},
		{
			title: 'Display Vehicles',
			property: 'displaySettings',
			type: FormInputType.SELECT,
			size: 6,
			selectItems: [
				{ label: 'Public', value: 'true' },
				{ label: 'Private', value: 'false' }
			],
			defaultValue: null,
		}
	]
	return inputs
}
