// External Dependencies
import * as Yup from 'yup'

// Internal Dependencies
import { FormInput, FormInputType, InputSelectionItem, SelectType } from '../../Shared/Form'
import { stateSelectionInputs } from '../../../helpers/form'
import * as Constants from '../../../constants/index'

export interface GuestGolferFormState {
	member: InputSelectionItem
	guests: InputSelectionItem
	date: Date
	time: Date
	guestPaying?: boolean
	holeCount?: number
	rentalClubCount?: number
	golfCartCount?: number
}

export const GuestGolferFormInputs: FormInput[] = [
	{
		title: 'Member',
		property: 'member',
		type: FormInputType.GROUPED_TYPEAHEAD,
		selectType: SelectType.MEMBERS,
		size: 12,
		placeholder: 'Select Member...',
		defaultValue: '',
		validation: Yup.string().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Guest',
		property: 'guest',
		type: FormInputType.GROUPED_TYPEAHEAD,
		selectType: SelectType.GUESTS,
		size: 12,
		placeholder: 'Select Guest...',
		defaultValue: '',
	},
	{
		title: 'Date',
		property: 'date',
		type: FormInputType.DATE,
		size: 6,
		placeholder: 'Date...',
		defaultValue: '',
		validation: Yup.string().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Time',
		property: 'time',
		type: FormInputType.TIME,
		size: 6,
		placeholder: 'Time...',
		defaultValue: '',
		validation: Yup.string().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Guest Paying',
		property: 'guestPaying',
		type: FormInputType.BUTTON_GROUP,
		size: 6,
		placeholder: 'End...',
		defaultValue: false,
		buttonItems: [
			{ text: 'Yes', value: true },
			{ text: 'No', value: false },
		],
		class: 'golf-reservation-button'
	},
	{
		title: 'Holes',
		property: 'holeCount',
		type: FormInputType.BUTTON_GROUP,
		size: 6,
		placeholder: 'Holes...',
		defaultValue: 18,
		buttonItems: [
			{ text: `${9}`, value: 9 },
			{ text: `${18}`, value: 18 }
		],
		class: 'golf-reservation-button'
	},
	{
		title: 'Rental Clubs',
		property: 'rentalClubCount',
		type: FormInputType.BUTTON_GROUP,
		size: 6,
		placeholder: 'End...',
		defaultValue: 0,
		buttonItems: [
			{ text: `${0}`, value: 0 },
			{ text: `${1}`, value: 1 },
			{ text: `${2}`, value: 2 }
		],
		class: 'golf-reservation-button'
	},
	{
		title: 'Carts',
		property: 'golfCartCount',
		type: FormInputType.BUTTON_GROUP,
		size: 6,
		placeholder: 'End...',
		defaultValue: 0,
		buttonItems: [
			{ text: `${0}`, value: 0 },
			{ text: `${1}`, value: 1 },
			{ text: `${2}`, value: 2 }
		],
		class: 'golf-reservation-button'
	},
]
