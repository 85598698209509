// External Dependencies
import * as core from 'club-hub-core'
import { Dispatch, Action } from 'redux'
import to from 'await-to-js'

// Internal Dependencies

// API Helpers
import { POST, GET, PUT, DELETE, POST_FORM, PUT_FORM } from '../helpers/api'

// Helpers
import { GeneralMap } from '../helpers/interface'

export enum TypeKeys {
	FETCHED_SECTIONS = 'FETCHED_SECTIONS',
	CREATED_SECTION = 'CREATED_SECTION',
	UPDATED_SECTION = 'UPDATED_SECTION',
	DELETED_SECTION = 'DELETED_SECTION',
	CREATED_SECTION_PAGE = 'CREATED_SECTION_PAGE',
	UPDATED_SECTION_PAGE = 'UPDATED_SECTION_PAGE',
	DELETED_SECTION_PAGE = 'DELETED_SECTION_PAGE',
}

export type ActionTypes =
	| FetchedSectionsAction
	| CreatedSectionAction
	| UpdatedSectionAction
	| DeletedSectionAction
	| CreatedSectionPageAction
	| UpdatedSectionPageAction
	| DeletedSectionPageAction

export interface FetchedSectionsAction extends Action {
	type: TypeKeys.FETCHED_SECTIONS,
	sections: core.Section.Model[],
	count: number,
}

export interface CreatedSectionAction extends Action {
	type: TypeKeys.CREATED_SECTION,
	createdSection: core.Section.Model
}

export interface UpdatedSectionAction extends Action {
	type: TypeKeys.UPDATED_SECTION,
	updatedSection: core.Section.Model
}

export interface DeletedSectionAction extends Action {
	type: TypeKeys.DELETED_SECTION,
	deletedSection: core.Section.Model
}

export interface CreatedSectionPageAction extends Action {
	type: TypeKeys.CREATED_SECTION_PAGE,
	createdSection: core.Section.Model
}

export interface UpdatedSectionPageAction extends Action {
	type: TypeKeys.UPDATED_SECTION_PAGE,
	updatedSection: core.Section.Model
}

export interface DeletedSectionPageAction extends Action {
	type: TypeKeys.DELETED_SECTION_PAGE,
	deletedSection: core.Section.Model
}

// -----------------------------------------------------------------------------
// Fetch Sections
// -----------------------------------------------------------------------------

const fetchSections = (isAdmin?: boolean) => async (dispatch: Dispatch<FetchedSectionsAction>) => {
	const path = (isAdmin) ? '/admin/sections' : '/sections'
	const [err, res] = await to<GeneralMap<core.Section.Model[] | number>>(GET(path, {}))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to fetch Sections with error: ${err}`)
		throw err
	}

	await dispatch(fetchedSections(res))
}

export const fetchedSections = (sectionsRes: GeneralMap<core.Section.Model[] | number>): FetchedSectionsAction => {
	const action: FetchedSectionsAction = {
		type: TypeKeys.FETCHED_SECTIONS,
		sections: sectionsRes.sections as core.Section.Model[],
		count: sectionsRes.count as number,
	}
	return action
}

// -----------------------------------------------------------------------------
// Create Section
// -----------------------------------------------------------------------------

const createSection = (sectionPayload: FormData) => async (dispatch: Dispatch<CreatedSectionAction>) => {
	const [err, res] = await to<core.Section.Model>(POST_FORM('/admin/sections', sectionPayload))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to create Section with error: ${err}`)
		throw err
	}

	await dispatch(createdSection(res))

	// Fetch the new Sections
	await dispatch(SectionActions.fetchSections(true) as any)
}

const createdSection = (sectionRes: core.Section.Model): CreatedSectionAction => {
	const action: CreatedSectionAction = {
		type: TypeKeys.CREATED_SECTION,
		createdSection: sectionRes,
	}
	return action
}

// -----------------------------------------------------------------------------
// Update Section
// -----------------------------------------------------------------------------

const updateSection = (sectionID: string, sectionPayload: FormData) => async (dispatch: Dispatch<UpdatedSectionAction>) => {
	const [err, res] = await to<core.Section.Model>(PUT_FORM(`/admin/sections/${sectionID}`, sectionPayload))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to update Section with error: ${err}`)
		throw err
	}

	await dispatch(updatedSection(res))

	// Fetch the new Sections
	await dispatch(SectionActions.fetchSections(true) as any)
}

const updatedSection = (sectionRes: core.Section.Model): UpdatedSectionAction => {
	const action: UpdatedSectionAction = {
		type: TypeKeys.UPDATED_SECTION,
		updatedSection: sectionRes,
	}
	return action
}

// -----------------------------------------------------------------------------
// Delete Section
// -----------------------------------------------------------------------------

const deleteSection = (sectionID: string) => async (dispatch: Dispatch<DeletedSectionAction>) => {
	const [err, res] = await to<core.Section.Model>(DELETE(`/admin/sections/${sectionID}`, {}))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to delete Section with error: ${err}`)
		throw err
	}

	await dispatch(deletedSection(res))

	// Fetch the new Sections
	await dispatch(SectionActions.fetchSections(true) as any)
}

const deletedSection = (sectionRes: core.Section.Model): DeletedSectionAction => {
	const action: DeletedSectionAction = {
		type: TypeKeys.DELETED_SECTION,
		deletedSection: sectionRes,
	}
	return action
}

// -----------------------------------------------------------------------------
// Create Section Page
// -----------------------------------------------------------------------------

const createSectionPage = (sectionID: string, payload: core.Section.Page) => async (dispatch: Dispatch<CreatedSectionPageAction>) => {
	const [err, res] = await to<core.Section.Model>(POST(`/admin/sections/${sectionID}/sub/pages`, payload))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to create Page with error: ${err}`)
		throw err
	}

	await dispatch(createdSectionPage(res))

	// Fetch the new Sections
	await dispatch(SectionActions.fetchSections(true) as any)
}

const createdSectionPage = (sectionRes: core.Section.Model): CreatedSectionPageAction => {
	const action: CreatedSectionPageAction = {
		type: TypeKeys.CREATED_SECTION_PAGE,
		createdSection: sectionRes,
	}
	return action
}

// -----------------------------------------------------------------------------
// Update Section Page
// -----------------------------------------------------------------------------

const updateSectionPage = (sectionID: string, payload: core.Section.Page | core.Section.Page[]) => async (dispatch: Dispatch<UpdatedSectionPageAction>) => {
	const [err, res] = await to<core.Section.Model>(PUT(`/admin/sections/${sectionID}/sub/pages`, payload))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to update Page with error: ${err}`)
		throw err
	}

	await dispatch(updatedSectionPage(res))

	// Fetch the new Sections
	await dispatch(SectionActions.fetchSections(true) as any)
}

const updatedSectionPage = (sectionRes: core.Section.Model): UpdatedSectionPageAction => {
	const action: UpdatedSectionPageAction = {
		type: TypeKeys.UPDATED_SECTION_PAGE,
		updatedSection: sectionRes,
	}
	return action
}

// -----------------------------------------------------------------------------
// Delete Section Page
// -----------------------------------------------------------------------------

const deleteSectionPage = (sectionID: string, pageID: string) => async (dispatch: Dispatch<DeletedSectionPageAction>) => {
	const [err, res] = await to<core.Section.Model>(DELETE(`/admin/sections/${sectionID}/sub/pages/${pageID}`, {}))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to delete Page with error: ${err}`)
		throw err
	}

	await dispatch(deletedSectionPage(res))

	// Fetch the new Sections
	await dispatch(SectionActions.fetchSections(true) as any)
}

const deletedSectionPage = (sectionRes: core.Section.Model): DeletedSectionPageAction => {
	const action: DeletedSectionPageAction = {
		type: TypeKeys.DELETED_SECTION_PAGE,
		deletedSection: sectionRes,
	}
	return action
}

export const SectionActions = {
	fetchSections,
	createSection,
	updateSection,
	deleteSection,
	createSectionPage,
	updateSectionPage,
	deleteSectionPage
}
