// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { debounce } from 'underscore'
import { oc } from 'ts-optchain'

// Internal Dependencies

// Components
import SearchComponent from '../Search'
import DimmedLoader from '../DimmedLoader'

// State / Reducers
import { RootReducerState } from '../../../reducers'

// Helpers
import { timeAndPeriod } from '../../../helpers/date'
import { fullName } from '../../../helpers/user'
import { setStateAsync } from '../../../helpers/promise'

type ConnectedState = ReturnType<typeof mapStateToProps>

interface ComponentProps {
	upcomingEventsByTime: { [key: string]: core.Event.AvailableEvent[] }
	onClick: (res: core.Event.Reservation, event: core.Event.AvailableEvent) => void
}

const initialState = {
	error: false,
	loading: false,
	queueSearchTerm: '',
}

type Props = ComponentProps & ConnectedState
type State = typeof initialState

class ReservationQueueComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {...initialState}
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	handleQueueSearchChange = async (term: string) => {
		await setStateAsync(this, { queueSearchTerm: term })
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	/**
	 * Responsible for building the Reservation Queue
	 */
	buildReservationQueue = () => {
		const { upcomingEventsByTime } = this.props
		const sections = Object.keys(upcomingEventsByTime).map((eventTime: string, idx: number) => {
			return this.buildQueueSection(eventTime, [...upcomingEventsByTime[eventTime]], idx)
		})

		return (
			<div className='reservation-queue'>
				<div className='reservation-queue-header'>
					<h5>Upcoming Reservations</h5>
					<SearchComponent
						searchChangeHandler={debounce(this.handleQueueSearchChange, 200)}
						searchClearHandler={() => this.handleQueueSearchChange('')}
					/>
				</div>
				{sections}
			</div>
		)
	}

	/**
	 * Responsible for building a section of the Reservation Queue. A section
	 * is a block of reservations that are grouped by a starting time.
	 */
	buildQueueSection = (eventTime: string, events: core.Event.AvailableEvent[], sectionIdx: number) => {
		const formattedTime = timeAndPeriod(new Date(eventTime))
		const reservationRows = []
		for (let eventIdx = 0; eventIdx < events.length; eventIdx++) {
			const event = events[eventIdx]

			// Skip over blocked Events
			if (event.status === core.Event.AvailableEventStatus.Blocked) { continue }
			for (let reservationIdx = 0; reservationIdx < event.existingEvent.reservations.length; reservationIdx++) {
				const reservation = event.existingEvent.reservations[reservationIdx]
				reservationRows.push(this.buildQueueRow(reservation, event, sectionIdx, eventIdx, reservationIdx))
			}
		}

		// To account for the possibility of Blocking Events being included
		// we need to check to see if there are any Reservation Rows to build.
		// We don't want to display times that are reserved for blocking events.
		if (reservationRows.length === 0) {
			return null
		}

		const key = `reservation-queue-section-${sectionIdx}`
		return (
			<div key={key} className='reservation-queue-section'>
				<p className='reservation-queue-section-header'>
					{formattedTime}
				</p>
				{reservationRows}
			</div>
		)
	}

	/**
	 * Responsible for building a row for a section of the Reservation Queue.
	 */
	buildQueueRow = (reservation: core.Event.Reservation, event: core.Event.AvailableEvent, sectionIdx: number, eventIdx: number, reservationIdx: number) => {
		const users: core.User.Model[] = oc(this).props.userState.users([])
		const reservationOwner: core.User.Model = users.find((user) => user._id === reservation.owner)
		const ownerParticipant: core.Event.Participant = reservation.participants.find((participant) => participant.userID === reservationOwner._id)
		const creatorName: string = fullName(reservationOwner)
		const numParticipants: number = reservation.participants.length

		if (!creatorName.toLowerCase().includes(this.state.queueSearchTerm.toLowerCase())) { return null }

		const dot = ownerParticipant.checkedIn ? <div className='green-dot'/> : <div className='gray-dot'/>
		const key = `reservation-queue-section-${sectionIdx}-event-${eventIdx}-row-${reservationIdx}`
		return (
			<div key={key} className='reservation-queue-section-row' onClick={() => this.props.onClick(reservation, event)}>
				<div className='d-flex align-items-center'>
					{dot}
					<div className='reservation-queue-creator'>
						<div className='reservation-queue-creator-name'>{creatorName}</div>
						<div className='reservation-queue-reservation-type'>Mobile Reservation</div>
					</div>
				</div>
				<div className='reservation-queue-row-participants'>{numParticipants}</div>
			</div>
		)
	}

	render() {
		if (this.state.loading) { return <DimmedLoader component={null} isLoading={true} /> }
		return this.buildReservationQueue()
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	userState: state.user,
})

const enhance = compose<React.ComponentType<ComponentProps>>(
	connect(mapStateToProps, undefined)
)

export default enhance(ReservationQueueComponent)
