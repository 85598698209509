// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import { connect } from 'react-redux'
import { compose } from 'redux'

// Actions
import { AlertActions, ClubActions } from '../../actions'

// State
import { RootReducerState } from '../../reducers'
import { inCustomerViewSelector } from '../../reducers/user'
import Wiki, { WikiSection } from '../Wiki'

// Components
import ClubSettingsForm from './ClubSettingsForm'
import BrandSettingsForm from './BrandSettingsForm'
import SecuritySettingsForm from './SecuritySettingsForm'
import QuickLinkSection from './QuickLinkSection'
import DimmedLoader from '../Shared/DimmedLoader'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	loading: false,
}

type Props = ConnectedState & ConnectedActions
type State = typeof initialState

export class SettingsComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	render() {
		if (this.state.loading) { return <DimmedLoader component={null} isLoading={true} /> }

		const isCustomerView = this.props.isCustomerView
		const sections = isCustomerView ? this.buildMemberSettings() : this.buildAdminSettings()
		return (
			<div className='clubSettings__container'>
				<Wiki title={'Settings'} sections={sections} />
			</div>
		)
	}

	buildAdminSettings = (): WikiSection[] => {
		const { loggedInClub } = this.props
		return [
			{
				key: 'club',
				title: 'Club',
				component: (<ClubSettingsForm club={loggedInClub} />)
			},
			{
				key: 'brand',
				title: 'Brand',
				component: (<BrandSettingsForm club={loggedInClub} />)
			},
			{
				key: 'password',
				title: 'Password',
				component: (<SecuritySettingsForm club={loggedInClub} />)
			},
			{
				key: 'quicklinks',
				title: 'Quick Links',
				component: (<QuickLinkSection club={loggedInClub} />)
			}
		]
	}

	buildMemberSettings = (): WikiSection[] => {
		const { loggedInClub } = this.props
		return [
			{
				key: 'password',
				title: 'Password',
				component: (<SecuritySettingsForm club={loggedInClub} />)
			},
		]
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	calendarState: state.calendar,
	userState: state.user,
	loggedInClub: state.club.loggedInClub,
	isCustomerView: inCustomerViewSelector(state)
})

const mapDispatchToProps = {
	...AlertActions,
	...ClubActions
}

const enhance = compose<React.ComponentType>(
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(SettingsComponent)
