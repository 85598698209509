// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import * as core from 'club-hub-core'
import { oc } from 'ts-optchain'

// Internal Dependencies
import { BaseTablePrimitiveProps } from '../index'
import { BaseTableCell } from '../BaseTableCell'

type Props<T> = BaseTablePrimitiveProps<T>

export const TableBadge: React.FC<Props<any>> = (props: Props<any>) => {
	const rawValue = props.item[props.column.property] as { color: any, title: any }
	const color = oc(rawValue).color(core.Constants.Colors.GRAY)
	const title = oc(rawValue).title('').toUpperCase()
	return (
		<BaseTableCell {...props}>
			<RS.Badge style={{ backgroundColor: color }}>
				{title}
			</RS.Badge>
		</BaseTableCell>
	)
}
