// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'

// Internal Dependencies
import { BaseTablePrimitiveProps } from '../index'

// Components
import { BaseTableCell } from '../BaseTableCell'
import AvatarComponent from '../../../Avatar'

// Helpers
import { fullName } from '../../../../../helpers/user'

type Props<T> = BaseTablePrimitiveProps<T>

export const TableAvatarAndName: React.FC<Props<any>> = (props: Props<any>) => {
	const user: core.User.Model = props.item[props.column.property]
	return (
		<BaseTableCell {...props}>
			<div className='d-flex align-items-center'>
				<AvatarComponent user={user} />
				<div className='user-avatar-name'>
					{fullName(user)}
				</div>
			</div>
		</BaseTableCell>
	)
}
