// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as queryString from 'query-string'
import * as Feather from 'react-feather'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies

// State
import { RootReducerState } from '../../reducers/index'
import { inCustomerViewSelector } from '../../reducers/user'

// Actions
import { PostActions, AlertActions, MessageActions } from '../../actions/index'

// Components
import { HeaderButton, ButtonType } from '../Shared/ButtonGroup'
import ErrorComponent from '../Shared/ErrorComponent'
import TableComponent from '../Shared/Table'
import ModalComponent from '../Shared/Modal'
import DimmedLoader from '../Shared/DimmedLoader'
import { InputSelectionItem } from '../Shared/Form'
import EmptyContent, { ContentType } from '../Shared/EmptyContent'
import { TabBarButtonInput } from '../Shared/TabBar'
import TableHeader from '../Shared/TableHeader'
import RichPostCard from './RichPostCard'
import ClubTypes from '../Shared/ClubTypes'

// Helpers
import * as Constants from '../../constants'
import { PostTableColumns, PostTableItem } from './table'
import { setStateAsync } from '../../helpers/promise'
import { isValidImage } from '../../helpers/image'
import { selectInputsForType } from '../../helpers/types'
import { publicationStatusBadge, clubResourceTypeBadge } from '../../helpers/badge'

const PostComponentTabs = {
	...core.IShared.PublicationStatus,
	Types: 'types',
}

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

// State Key Constants
type ModalStateKey = typeof ShowingTypeCreationModal | typeof ShowingPostDeletionModal
const ShowingTypeCreationModal = 'showingTypeCreationModal'
const ShowingPostDeletionModal = 'showingPostDeletionModal'

const initialState = {
	showingTable: true,
	postToDelete: null as string | null,
	error: false,
	loading: false,
	pageIndex: 0,
	filter: null as string | null,
	postStatus: core.IShared.PublicationStatus.Published as core.IShared.PublicationStatus,
	viewingTab: null as string | null,
	[ShowingPostDeletionModal]: false,
	[ShowingTypeCreationModal]: false
}

type Props = RouteComponentProps & ConnectedState & ConnectedActions
type State = typeof initialState

class PostComponent extends React.Component<Props, State> {
	private pageSize: number
	private club: core.Club.Model

	constructor(props: Props) {
		super(props)

		const { loggedInClub } = props

		this.club = loggedInClub
		this.pageSize = 20
		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		await this.fetchPosts()
	}

	// ----------------------------------------------------------------------------------
	// Network Requests
	// ----------------------------------------------------------------------------------

	fetchPosts = async () => {
		await setStateAsync(this, { loading: true })
		const queryOpts = this.buildQueryOpts()
		const [err] = await to(this.props.fetchPosts(queryOpts) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to fetch News. ${err.message}`, Constants.FlashType.DANGER)
			return setStateAsync(this, { loading: false, error: true })
		}
		await setStateAsync(this, { loading: false })
	}

	/**
	 * Builds the query options used to fetch posts.
	 * This first looks at pagination values on component state.
	 * By default only Posts with a status of Published are fetched.
	 * This can be updated if the user is an admin.
	 */
	buildQueryOpts = () => {
		const defaultQueryOpts: core.IShared.QueryOptions = {
			limit: this.pageSize,
			offset: this.pageSize * this.state.pageIndex,
			sortField: 'publicationDate',
			sortValue: -1
		}

		if (this.state.filter && this.state.filter !== 'All') {
			defaultQueryOpts.type = this.state.filter
		}

		if (!this.props.isCustomerView) {
			defaultQueryOpts.status = this.state.postStatus
		} else {
			defaultQueryOpts.status = core.IShared.PublicationStatus.Published
		}

		return defaultQueryOpts
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Dropdown Actions
	// ----------------------------------------------------------------------------------

	/**
	 * Determines which action to take, based on which dropdown item
	 * the user selected in the table's dropdown menu
	 */
	handleTableDropdownAction = (e: any) => {
		const [action, value] = e
		switch (action) {
			case 'viewPost':
				return this.handleViewPost(value)
			case 'messagePost':
				return this.handleMessagePost(value)
			case 'editPost':
				return this.handleEditPost(value)
			case 'removePost':
				return this.handleDeletePost(value)
			default:
				break
		}
	}

	handleViewPost = (postID: string) => {
		// Query parameters
		const queryParams = { postID: postID }
		const location = {
			pathname: Constants.VIEW_POST_ROUTE,
			search: queryString.stringify(queryParams)
		}
		this.props.history.push(location)
	}

	/**
	 * When creating a Message about a Post, navigate to the Message creation
	 * form and pass information about the Post via query parameters
	 */
	handleMessagePost = async (value: string) => {
		// Query parameters
		const queryParams = { postID: value }

		const location = {
			pathname: Constants.CREATE_MESSAGE_ROUTE,
			search: queryString.stringify(queryParams)
		}

		const currentPost = this.props.postState.posts.find((p) => `${p._id}` === value)
		await this.props.setCurrentPost(currentPost)
		await this.props.clearCurrentMessage()
		this.props.history.push(location)
	}

	/**
	 * When editing a Post, navigate to the Post update
	 * form and pass the Post's ID via the query parameters
	 */
	handleEditPost = (postID: string) => {
		// Query parameters
		const queryParams = { postID: postID }

		const location = {
			pathname: Constants.UPDATE_POST_ROUTE,
			search: queryString.stringify(queryParams)
		}
		this.props.history.push(location)
	}

	/**
	 * When attempting to delete a Post, display the
	 * Post deletion confirmation modal
	 */
	handleDeletePost = (value: string) => {
		return this.setState({ postToDelete: value })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Modal Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Make the API call to delete the Post, when the
	 * user confirms the deletion via the modal
	 */
	executePostDeletion = async () => {
		// Delete the Post and fetch the Posts
		const postID = this.state.postToDelete
		const fetchPostsQueryParams = this.buildQueryOpts()
		const [deleteErr] = await to(this.props.deletePost(postID, fetchPostsQueryParams) as any)
		if (deleteErr) {
			this.props.fireFlashMessage(`Failed to delete Post. ${deleteErr.message}`, Constants.FlashType.DANGER)
			return this.setState({ loading: false, postToDelete: null })
		}

		this.setState({ loading: false, postToDelete: null })
	}

	openModal = async (modalStateKey: ModalStateKey) => {
		await setStateAsync(this, (() => ({ [modalStateKey]: true })))
	}

	closeModal = async (modalStateKey: ModalStateKey) => {
		const newState: Partial<State> = {
			[modalStateKey]: false,
			postToDelete: null
		}
		await setStateAsync(this, (() => newState))
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Handle table pagination
	 */
	handlePage = async (data: any) => {
		const { pageIndex } = this.state

		// Check if the new page is different that the current one
		if (data.selected !== pageIndex) {
			await setStateAsync(this, { pageIndex: data.selected, loading: true })
			await this.fetchPosts()
			await setStateAsync(this, { loading: false })
		}
	}

	/**
	 * Handle a column header of the table being selected
	 */
	handleTableHeaderAction = (e: any) => {
		// TODO: Add Functionality
	}

	/**
	 * Handle a table row being clicked
	 */
	handleTableRowClicked = (item: PostTableItem) => {
		return this.handleViewPost(`${item._id}`)
	}

	handleFilterChange = async (postType: InputSelectionItem) => {
		await setStateAsync(this, { filter: postType.value, loading: true })
		await this.fetchPosts()
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Header Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Determine which action to take, based on the header
	 * button that the user selected
	 */
	handleHeaderButtonAction = (e: any) => {
		const action = e.target.value
		switch (action) {
			case 'showRichContent':
				this.setState({ showingTable: false })
				break
			case 'showTableContent':
				this.setState({ showingTable: true })
				break
			case 'createPost':
				this.handleCreateButton()
				break
			case 'createType':
				this.openModal(ShowingTypeCreationModal)
				break
			default:
				break
		}
	}

	/**
	 * Navigate to the Post creation form
	 */
	handleCreateButton = async () => {
		const { clearCurrentPost, history } = this.props

		// Clear the current Post in Redux, since we are creating a new one
		await clearCurrentPost()
		history.push(Constants.CREATE_POST_ROUTE)
	}

	handleTabChange = async (tab: string) => {
		await setStateAsync(this, { viewingTab: tab })
	}

	handleStatusChange = async (status: core.IShared.PublicationStatus) => {
		await setStateAsync(this, { postStatus: status, viewingTab: status, loading: true })
		await this.fetchPosts()
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildHeaderButtons = (): HeaderButton[] => {
		const { isCustomerView } = this.props
		const { viewingTab } = this.state

		if (isCustomerView) { return null }

		if (viewingTab === PostComponentTabs.Types) {
			return [
				{
					action: 'createType',
					type: ButtonType.DEFAULT,
					text: 'New Type',
					class: 'btn-primary'
				}
			]
		}

		return [
			{
				action: '',
				type: ButtonType.GROUP,
				class: 'post-content-btn',
				groupButtons: [
					{
						value: 'showTableContent',
						icon: Feather.Menu,
						defaultSelected: true,
						itemClass: 'post-content-btn',
					},
					{
						value: 'showRichContent',
						icon: Feather.Grid,
						itemClass: 'post-content-btn',
					}
				]
			},
			{
				action: 'createPost',
				type: ButtonType.DEFAULT,
				text: 'New Post',
				class: 'btn-primary',
			},
		]
	}

	buildTabInputs = (): TabBarButtonInput[] => {
		if (this.props.isCustomerView) { return null }
		return [
			{ title: 'Published', onClick: () => this.handleStatusChange(PostComponentTabs.Published) },
			{ title: 'Scheduled', onClick: () => this.handleStatusChange(PostComponentTabs.Scheduled) },
			{ title: 'Draft', onClick: () => this.handleStatusChange(PostComponentTabs.Draft) },
			{ title: 'Types', onClick: () => this.handleTabChange(PostComponentTabs.Types) }
		]
	}

	buildPageHeader = () => {
		const { isCustomerView, loggedInClub } = this.props
		const { filter, showingTable, viewingTab } = this.state

		const title = 'News'

		// Build filter inputs.
		const selectInputs = (viewingTab === PostComponentTabs.Types) ?
			[] :
			selectInputsForType(title, loggedInClub, filter, this.handleFilterChange)
		const tabInputs = this.buildTabInputs()
		const showFullScreen = isCustomerView || !showingTable

		return (
			<TableHeader
				fullScreen={!showFullScreen}
				tabInputs={tabInputs}
				pageTitle={title}
				inputs={selectInputs}
				buttons={this.buildHeaderButtons()}
				buttonHandler={this.handleHeaderButtonAction}
			/>
		)
	}

	buildDeleteConfirmationModal = () => {
		if (!this.state.postToDelete) { return null }
		return (
			<ModalComponent
				modalTitle={'Delete Post'}
				primaryMessage={'Are you sure you want to delete this Post?'}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={() => this.closeModal(ShowingPostDeletionModal)}
				submitButtonName={'Confirm'}
				submitButtonHandler={this.executePostDeletion}
			/>
		)
	}

	buildPostTable = () => {
		const { loggedInClub, postState } = this.props
		const { viewingTab, showingTypeCreationModal, postStatus } = this.state

		if (viewingTab === PostComponentTabs.Types) {
			return (
				<div className='post-table-container'>
					<ClubTypes
						resourceType={'posts'}
						showingEditModal={showingTypeCreationModal}
						onEditModalClose={() => this.closeModal(ShowingTypeCreationModal)}
					/>
				</div>
			)
		}

		// Check if we have Posts
		const posts: core.Post.Model[] = oc(postState).posts([])
		const total = this.props.postState.count

		const postsForTable: PostTableItem[] = posts.map((post) => {
			const type = post.type ? `${post.type}` : ''
			return {
				...post,
				statusWithColor: publicationStatusBadge(postStatus),
				typeWithColor: clubResourceTypeBadge(loggedInClub, 'posts', type)
			}
		})

		if (total === 0) {
			return (<EmptyContent type={ContentType.Posts} status={this.state.postStatus} />)
		}

		return (
			<div className='post-table-container'>
				<TableComponent
					columns={PostTableColumns}
					rowItems={postsForTable}
					showPaging={true}
					currentPage={this.state.pageIndex}
					pageHandler={this.handlePage}
					totalResults={total}
					pageSize={this.pageSize}
					actionHandler={this.handleTableHeaderAction}
					dropdownHandler={this.handleTableDropdownAction}
					onTableRowClick={this.handleTableRowClicked}
					isLoading={this.state.loading}
				/>
			</div>
		)
	}

	buildRichContent = () => {
		const posts: core.Post.Model[] = this.props.postState.posts || []
		if (posts.length === 0) {
			return (<EmptyContent type={ContentType.Posts} status={this.state.postStatus} />)
		}
		const postContent = posts.map(this.buildRichContentItem)

		return (
			<DimmedLoader component={postContent} isLoading={this.state.loading} />
		)
	}

	buildRichContentItem = (post: core.Post.Model, index: number) => {
		return (
			<RichPostCard
				key={`richPost_${index}`}
				post={post}
				club={this.club}
				postAvatar={this.buildPostAvatar(post)}
				onClick={() => this.handleViewPost(`${post._id}`)}
			/>
		)
	}

	buildPostAvatar = (post: core.Post.Model) => {
		const hasImage = post.author && (post.author as core.User.Model).image
		const avatarURL = oc(post.author as core.User.Model).image.sm()
		return (hasImage && isValidImage(avatarURL)) ?
			(<span className='avatar mr-2' style={{ backgroundImage: `url(${avatarURL})` }} />) :
			(<span className='avatar avatar-placeholder mr-2' />)
	}

	render() {
		const { loggedInClub } = this.props
		if (this.state.error) { return <ErrorComponent club={loggedInClub} isAdmin={this.props.userState.isAdmin} /> }

		// Determine which content we are showing
		const content = (this.state.showingTable && !this.props.isCustomerView) ?
			this.buildPostTable() :
			this.buildRichContent()
		const className = (this.props.isCustomerView || !this.state.showingTable) ? `col-lg-8` : `col-lg-12`
		return (
			<div className='row justify-content-center post-view-row'>
				<div className={`${className} no-padding`}>
					{this.buildPageHeader()}

					< div className='post-content-container-div' >
						{content}
					</div >

					{this.buildDeleteConfirmationModal()}
				</div >
			</div >
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	postState: state.post,
	loggedInClub: state.club.loggedInClub,
	isCustomerView: inCustomerViewSelector(state),
	userState: state.user
})

const mapDispatchToProps = {
	...PostActions,
	...MessageActions,
	...AlertActions,
}

export default connect(mapStateToProps, mapDispatchToProps)(PostComponent)
