// External Dependencies
import * as core from 'club-hub-core'
import { Action, ActionCreator, Dispatch } from 'redux'
import { ThunkAction } from 'redux-thunk'
import to from 'await-to-js'

// Internal Dependencies
import rootReducer, { RootReducerState } from '../reducers'
import { postsByIDSelector } from '../reducers/post'

// API Helpers
import { GET, DELETE, POST_FORM, PUT_FORM } from '../helpers/api'

// Helpers
import { GeneralMap } from '../helpers/interface'

// Actions
export enum TypeKeys {
	FETCHED_POSTS = 'FETCHED_POSTS',
	FETCHED_POST = 'FETCHED_POST',
	DELETED_POST = 'DELETED_POST',
	CREATED_POST = 'CREATED_POST',
	UPDATED_POST = 'UPDATED_POST',
	SET_CURRENT_POST = 'SET_CURRENT_POST',
	CLEAR_CURRENT_POST = 'CLEAR_CURRENT_POST',
}

export type ActionTypes =
	| FetchedPostsAction
	| FetchedPostAction
	| DeletedPostAction
	| CreatedPostAction
	| UpdatedPostAction
	| SetCurrentPostAction
	| ClearCurrentPostAction

export interface FetchedPostsAction extends Action {
	type: TypeKeys.FETCHED_POSTS,
	posts: core.Post.Model[],
	count: number,
}

export interface FetchedPostAction extends Action {
	type: TypeKeys.FETCHED_POST,
	post: core.Post.Model
}

export interface DeletedPostAction extends Action {
	type: TypeKeys.DELETED_POST,
	postID: string,
}

export interface CreatedPostAction extends Action {
	type: TypeKeys.CREATED_POST,
	post: core.Post.Model
}

export interface UpdatedPostAction extends Action {
	type: TypeKeys.UPDATED_POST,
	post: core.Post.Model
}

export interface SetCurrentPostAction extends Action {
	type: TypeKeys.SET_CURRENT_POST,
	post: core.Post.Model
}

export interface ClearCurrentPostAction extends Action {
	type: TypeKeys.CLEAR_CURRENT_POST,
}

// -----------------------------------------------------------------------------
// Fetch Posts
// -----------------------------------------------------------------------------

const fetchPosts = (queryParams: core.IShared.QueryOptions) => async (dispatch: Dispatch<FetchedPostsAction>) => {
	const [err, postRes] = await to(GET('/posts', queryParams))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to fetch posts with error: ${err}`)
		throw err
	}
	return dispatch(fetchedPosts(postRes))
}

const fetchedPosts = (postRes: GeneralMap<core.Post.Model[] | number>): FetchedPostsAction => {
	const action: FetchedPostsAction = {
		type: TypeKeys.FETCHED_POSTS,
		posts: postRes.posts as core.Post.Model[],
		count: postRes.count as number,
	}
	return action
}

// -----------------------------------------------------------------------------
// Fetch Posts
// -----------------------------------------------------------------------------

const fetchPost = (postID: string, forceFetch: boolean = false) => async (dispatch: Dispatch<FetchedPostAction>, getState: () => RootReducerState) => {
	if (!forceFetch) {
		const post = postsByIDSelector(getState())[postID]
		if (post) {
			return dispatch(fetchedPost(post))
		}
	}
	const [err, postRes] = await to(GET(`/posts/${postID}`, {}))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to fetch post with error: ${err}`)
		throw err
	}
	return dispatch(fetchedPost(postRes))
}

const fetchedPost = (postRes: core.Post.Model): FetchedPostAction => {
	const action: FetchedPostAction = {
		type: TypeKeys.FETCHED_POST,
		post: postRes,
	}
	return action
}

// -----------------------------------------------------------------------------
// Delete Post
// -----------------------------------------------------------------------------

const deletePost = (postID: string, fetchPostQueryParams?: core.IShared.QueryOptions) => async (dispatch: Dispatch<DeletedPostAction>) => {
	const [deleteErr] = await to<core.Post.Model>(DELETE(`/admin/posts/${postID}`, {}))
	if (deleteErr) {
		// tslint:disable-next-line
		console.error(`Failed to delete post with error: ${deleteErr}`)
		throw deleteErr
	}

	dispatch(deletedPost(postID))

	const fetchQueryParams: core.IShared.QueryOptions = {
		...defaultFetchPostsQuery(),
		...fetchPostQueryParams
	}

	const [fetchError] = await to(dispatch(fetchPosts(fetchQueryParams) as any))
	if (fetchError) {
		// tslint:disable-next-line
		console.error(`Failed to fetch posts with error: ${fetchError}`)
		throw fetchError
	}
}

const deletedPost = (postID: string): DeletedPostAction => {
	const action: DeletedPostAction = {
		type: TypeKeys.DELETED_POST,
		postID: postID
	}
	return action
}

// -----------------------------------------------------------------------------
// Create Post
// -----------------------------------------------------------------------------

const createPost = (postPayload: FormData, fetchPostQueryParams?: core.IShared.QueryOptions) => async (dispatch: Dispatch<CreatedPostAction>) => {
	const [postErr, postRes] = await to<core.Post.Model>(POST_FORM('/admin/posts', postPayload))
	if (postErr) {
		// tslint:disable-next-line
		console.error(`Failed to create post with error: ${postErr}`)
		throw postErr
	}

	dispatch(createdPost(postRes))

	const fetchQueryParams: core.IShared.QueryOptions = {
		...defaultFetchPostsQuery(),
		...fetchPostQueryParams
	}

	const [fetchError] = await to(dispatch(fetchPosts(fetchQueryParams) as any))
	if (fetchError) {
		// tslint:disable-next-line
		console.error(`Failed to fetch posts with error: ${fetchError}`)
		throw fetchError
	}
}

const createdPost = (post: core.Post.Model): CreatedPostAction => {
	const action: CreatedPostAction = {
		type: TypeKeys.CREATED_POST,
		post: post
	}
	return action
}

// -----------------------------------------------------------------------------
// Update Post
// -----------------------------------------------------------------------------

const updatePost = (postID: string, postPayload: FormData) => async (dispatch: Dispatch<UpdatedPostAction>) => {
	const [postErr, postRes] = await to<core.Post.Model>(PUT_FORM(`/admin/posts/${postID}`, postPayload))
	if (postErr) {
		// tslint:disable-next-line
		console.error(`Failed to update Post with error: ${postErr}`)
		throw postErr
	}

	return dispatch(updatedPost(postRes))
}

const updatedPost = (post: core.Post.Model): UpdatedPostAction => {
	const action: UpdatedPostAction = {
		type: TypeKeys.UPDATED_POST,
		post: post
	}
	return action
}

// -----------------------------------------------------------------------------
// Set Current Event
// -----------------------------------------------------------------------------

const setCurrentPost = (post: core.Post.Model) => async (dispatch: Dispatch<SetCurrentPostAction>) => {
	const action: SetCurrentPostAction = {
		type: TypeKeys.SET_CURRENT_POST,
		post: post
	}
	await dispatch(action)
}

// -----------------------------------------------------------------------------
// Clear Current Event
// -----------------------------------------------------------------------------

const clearCurrentPost = () => async (dispatch: Dispatch<ClearCurrentPostAction>) => {
	const action: ClearCurrentPostAction = {
		type: TypeKeys.CLEAR_CURRENT_POST,
	}
	await dispatch(action)
}

// -----------------------------------------------------------------------------
// Helpers
// -----------------------------------------------------------------------------

const defaultFetchPostsQuery = (): core.IShared.QueryOptions => ({
	limit: 20,
	offset: 0,
})

export const PostActions = {
	fetchPosts,
	fetchPost,
	deletePost,
	createPost,
	updatePost,
	setCurrentPost,
	clearCurrentPost
}
