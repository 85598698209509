// External Dependencies
import * as Yup from 'yup'

// Internal Dependencies
import { FormInput, FormInputType, InputSelectionItem } from '../../Shared/Form'

export interface EditTypeForm {
	title: string
	color: InputSelectionItem
}

export const EditTypeFormInputs: FormInput[] = [
	{
		title: 'Name',
		property: 'title',
		type: FormInputType.TEXT,
		size: 8,
		placeholder: 'Enter a name...',
		defaultValue: '',
		validation: Yup.string().required('Required.')
	},
	{
		title: 'Color',
		property: 'color',
		type: FormInputType.COLOR,
		size: 4,
		placeholder: 'Select...',
		defaultValue: '',
		validation: Yup.string().required('Required.')
	}
]
