// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'

// Internal Dependencies

// Table
import { ReservationSettingsPrivilegesColumns } from './table'
import TableComponent from '../../Shared/Table'

interface ComponentProps {
	membershipTypes: core.Club.ResourceType[]
	dataForTable: core.Calendar.Privilege[]
	onTableDropdown: (e: any) => Promise<void>
	onAddPrivilege: () => Promise<void>
}

type Props = ComponentProps

/**
 * This section contains a table that will hold user-defined privilege information
 * for members of the club. This information can include how many reservations members
 * can make over a period of time, how many guests they can invite, or restrict these
 * capabilities depending on their membership type
 *
 * Clicking the 'Add Privilege' button will display a modal where the user
 * can add additional privilege restrictions to the table.
 */
export const PrivilegeSection: React.FunctionComponent<Props> = (props: Props) => {
	const tableColumns = ReservationSettingsPrivilegesColumns

	const dataForTable = props.dataForTable.map((row, idx) => {
		return {
			_id: row.memberType,
			rowIdx: idx,
			membershipType: props.membershipTypes.find((m) => m._id === row.memberType),
			window: (row.bookingWindow) ? `${row.bookingWindow} Days` : `--`,
			bookings: `${row.maxBookings.length} Privileges`,
			guests: `${row.maxGuests.length} Privileges`,
		}
	})

	return (
		<div className='reservationSettings-privileges-section'>
			<h3 className='reservationSettings-section-heading'>Privileges</h3>
			<hr />
			<p>Configure the member privileges.</p>

			<TableComponent
				columns={tableColumns}
				rowItems={dataForTable}
				showPaging={false}
				dropdownHandler={props.onTableDropdown}
			/>

			<button
				className='reservationSettings-right-aligned-button btn btn-primary'
				onClick={props.onAddPrivilege}
			>
				Edit Privileges
			</button>
		</div>
	)
}
