// External Dependencies
import * as Yup from 'yup'
import * as core from 'club-hub-core'

// Internal Dependencies
import { FormInput, FormInputType, InputSelectionItem } from '../../Shared/Form'

// Helpers
import * as Constants from '../../../constants/index'

export interface BrandSettingsFormState {
	primaryColor?: string,
	secondaryColor?: string,
	font?: string,
}

export const BrandSettingsFormInputs = (): FormInput[] => [
	{
		title: 'Primary Color',
		property: 'primaryColor',
		type: FormInputType.COLOR,
		size: 6,
		placeholder: 'Enter Primary Color...',
		defaultValue: '',
		validation: Yup.string().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Secondary Color',
		property: 'secondaryColor',
		type: FormInputType.COLOR,
		size: 6,
		placeholder: 'Enter Secondary Color...',
		defaultValue: '',
		validation: Yup.string().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Font',
		property: 'font',
		type: FormInputType.TEXT,
		size: 6,
		placeholder: 'Enter font...',
		defaultValue: '',
	}
]
