// External Dependencies
import { oc } from 'ts-optchain'

interface Orderable {
	orderingIndex: number
}

export const sortByOrderingIndex = <T>(a: T & Orderable, b: T & Orderable): number => {
	return oc(a).orderingIndex(0) - oc(b).orderingIndex(0)
}
