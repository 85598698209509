// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import { Icon } from 'react-feather'
import { oc } from 'ts-optchain'

import { BaseInputPrimitiveProps } from '../index'
import { InputButtonItem } from '../../../Form'

type Props = BaseInputPrimitiveProps

export const ButtonGroup: React.FunctionComponent<Props> = (props: Props) => {

	const items = oc(props).item.buttonItems([])
	const buttons = items.map((input: InputButtonItem, index: number) => {
		const iconClass = (input.text) ? `${input.icon} mr-2` : `${input.icon}`
		const FeatherIcon = input.icon
		const icon = (input.icon) ? <FeatherIcon size={18} className={iconClass} /> : null

		const onClick = (e: any) => {
			// Build our own event here to accommodate for clicks on an icons.
			props.onChange({ target: { value: input.value, name: props.item.property } })
		}
		return (
			<RS.Button
				key={`button-${index}`}
				color={input.color}
				value={input.value}
				onClick={onClick}
				active={props.value === input.value}
				className={`d-flex align-items-center justify-content-center ${props.item.class}`}
			>
				{icon}
				{input.text}
			</RS.Button>
		)
	})

	return (
		<RS.ButtonGroup>
			{buttons}
		</RS.ButtonGroup>
	)
}
