// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as RS from 'reactstrap'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { RouteComponentProps, matchPath } from 'react-router'
import { isNullOrUndefined } from 'util'
import { debounce, indexBy, Dictionary } from 'underscore'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies

// Actions
import { SectionActions, AlertActions } from '../../../actions/index'

// State
import { RootReducerState } from '../../../reducers'

// Components
import Editor from '../../Shared/Editor'
import DimmedLoader from '../../Shared/DimmedLoader'
import ActionBar from '../../Shared/ActionBar'

// Helpers
import { setStateAsync } from '../../../helpers/promise'
import { saveStateForKey, loadStateForKey, removeStateForKey } from '../../../store/localStorage'
import * as Constants from '../../../constants'

type ConnectedActions = typeof mapDispatchToProps
type ConnectedState = ReturnType<typeof mapStateToProps>

const initialState = {
	loading: true,
	sectionID: null as string | null,
	pageID: null as string | null,
	pageName: null as string | null,
	richContent: null as core.SubModels.RichContent.Model | null
}

type Props = RouteComponentProps & ConnectedActions & ConnectedState
type State = typeof initialState

class SectionPageForm extends React.Component<Props, State> {
	private isUpdateForm: boolean

	constructor(props: Props) {
		super(props)

		// Determine if we are updating
		const updateMatch = matchPath(props.location.pathname, { path: Constants.UPDATE_SECTION_PAGE_ROUTE })
		this.isUpdateForm = !isNullOrUndefined(updateMatch)

		const queryParams = queryString.parse(props.location.search)

		// Set the Section ID
		const sectionID = (props.match.params as any).section_id

		// Set the Page ID
		const pageID = queryParams.pageID

		// Set the Page Name
		const pageName = queryParams.pageName

		// Set the Page Content
		const sections = oc(props).sectionState.sections([])
		const pageSection = sections.find((s) => s._id === sectionID)

		const pages = oc(pageSection).pages([])
		const pageByID = pages.find((p) => p._id === pageID)

		this.state = {
			...initialState,
			sectionID: sectionID,
			pageName: pageName,
			pageID: pageID,
			richContent: oc(pageByID).content({})
		}
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		// Load state from local storage and set it
		await this.loadFormStateFromLocalStorage()
		await setStateAsync(this, { loading: false })
	}

	componentWillUnmount() {
		const newRoute = this.props.history.location.pathname
		const matchCreate = matchPath(newRoute, { path: Constants.CREATE_SECTION_PAGE_ROUTE })
		const matchUpdate = matchPath(newRoute, { path: Constants.UPDATE_SECTION_PAGE_ROUTE })

		// Cancel any inflight debounced save calls:
		this.debouncedSave.cancel()
		this.debouncedSaveToLocalStorage.cancel()

		// Clear the Form State from Local Storage if we are moving to a different route
		if (!matchCreate && !matchUpdate) {
			// Clear the state from local storage
			this.clearFormStateFromLocalStorage()
		}
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	handleCancel = async () => {
		const { history } = this.props
		const { sectionID } = this.state

		// Redirect to the Section Pages table
		const sectionPagesRoute = Constants.SECTION_PAGES_ROUTE.replace(':section_id', sectionID)
		history.push(sectionPagesRoute)
	}

	handleSave = async (status: core.IShared.PublicationStatus): Promise<void> => {
		const { createSectionPage, updateSectionPage, fireFlashMessage, history } = this.props
		const { sectionID, pageID, richContent } = this.state

		// Cancel any inflight debounced save calls:
		this.debouncedSave.cancel()
		this.debouncedSaveToLocalStorage.cancel()

		await setStateAsync(this, { loading: true, richContent: richContent })

		// Build the payload
		const payload = this.buildPagePayload(status)

		// Save the Page
		const savePage = (this.isUpdateForm || pageID) ? updateSectionPage : createSectionPage
		const [err] = await to(savePage(sectionID, payload) as any)
		if (err) {
			const savingAction = (this.isUpdateForm) ? 'updating' : 'creating'
			fireFlashMessage(`Problem ${savingAction} Page. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false })
			return
		}

		const savedAction = (this.isUpdateForm) ? 'updated' : 'created'
		fireFlashMessage(`Successfully ${savedAction} Page.`, Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false })

		// Redirect to the Section Pages table
		const sectionPagesRoute = Constants.SECTION_PAGES_ROUTE.replace(':section_id', sectionID)
		history.push(sectionPagesRoute)
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Editor
	// ----------------------------------------------------------------------------------

	/**
	 * A debounced function, which will either create/update a Page that is being edited.
	 * This basically acts as an autosave mechanism, so that we can save a Page after
	 * the User types in some input into the editor
	 */
	debouncedSave = debounce(async () => {
		const { createSectionPage, updateSectionPage, fireFlashMessage } = this.props
		const { sectionID, pageID } = this.state

		// Determine if we are creating or update a Section Page
		const updatingPage = !isNullOrUndefined(pageID)

		// If we are creating a new Page, we need to keep track of the PageIDs that currently exist
		const existingPageIDMap = indexBy(this.getPagesForSection(), '_id')

		// Build the payload
		const payload = {
			...this.buildPagePayload(core.IShared.PublicationStatus.Draft),
			...((updatingPage) ? { _id: pageID as any } : {})
		}

		const savePage = (updatingPage) ? updateSectionPage : createSectionPage
		const [err] = await to(savePage(sectionID, payload) as any)
		if (err) {
			fireFlashMessage(`Problem saving draft. ${err.message}`, Constants.FlashType.DANGER)
			return
		}

		fireFlashMessage(`Successfully saved draft.`, Constants.FlashType.SUCCESS)

		// If we created a new Page, we need to set the PageID on state
		if (!updatingPage) {
			await this.setNewPageID(existingPageIDMap)
		}
	}, 5000)

	/**
	 * A debounced function, which will save the current component form state to local storage,
	 * so that a user can safely refresh the page and not lose any of their edits.
	 */
	debouncedSaveToLocalStorage = debounce(() => {
		const { pageName, richContent, pageID } = this.state
		this.saveFormStateToLocalStorage(pageName, richContent, pageID)
	}, 1000)

	handleEditorChange = async (content: core.SubModels.RichContent.Model) => {
		await setStateAsync(this, { richContent: content })

		// Kick off the autosave functions (remote and local save)
		await this.debouncedSave()
		this.debouncedSaveToLocalStorage()
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	buildPagePayload = (status: core.IShared.PublicationStatus) => {
		const { richContent, sectionID, pageName, pageID } = this.state

		const richContentForPage: core.SubModels.RichContent.Model = {
			text: richContent.text,
			html: richContent.html,
			status: status,
		}

		const creatorID = this.props.userState.loggedInUser._id

		const page: core.Section.Page = {
			sectionID: sectionID as any,
			name: pageName,
			content: richContentForPage,
			orderingIndex: 0,
			createdBy: creatorID,
			updatedBy: creatorID,
		}

		if (this.isUpdateForm || pageID) {
			page._id = pageID as any
		}
		return page
	}

	/**
	 * Given a map of existing Pages, keyed by ID, updates this component's
	 * state to have a 'pageID' equal to the ID of the newly created Page
	 */
	setNewPageID = async (existingPageIDMap: Dictionary<core.Section.Page>) => {
		const newPages = this.getPagesForSection()
		for (const newPage of newPages) {
			if (isNullOrUndefined(existingPageIDMap[`${newPage._id}`])) {
				await setStateAsync(this, { pageID: `${newPage._id}` })
				break
			}
		}
	}

	/**
	 * Helper that returns a list of Pages for the current Section
	 */
	getPagesForSection = () => {
		const { sectionID } = this.state
		const pageSection = oc(this).props.sectionState.sections([]).find((s) => `${s._id}` === sectionID)
		const pages = oc(pageSection).pages([])
		return pages
	}

	getBaseRoute = () => {
		const rawRoute = (this.isUpdateForm) ?
			Constants.UPDATE_SECTION_PAGE_ROUTE :
			Constants.CREATE_SECTION_PAGE_ROUTE

		return rawRoute.replace(':section_id', this.state.sectionID)
	}

	saveFormStateToLocalStorage = (pageName: string, richContent: core.SubModels.RichContent.Model, pageID?: string) => {
		const baseRoute = this.getBaseRoute()
		saveStateForKey({ pageName, richContent, pageID }, baseRoute)
	}

	loadFormStateFromLocalStorage = async () => {
		const baseRoute = this.getBaseRoute()
		const formState = loadStateForKey(baseRoute)
		await setStateAsync(this, { ...formState })
	}

	clearFormStateFromLocalStorage = () => {
		const baseRoute = this.getBaseRoute()
		removeStateForKey(baseRoute)
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	getActionBarButtons = () => {
		return [
			{ title: 'Publish', onClick: () => this.handleSave(core.IShared.PublicationStatus.Published) },
			{ title: 'Save Draft', onClick: () => this.handleSave(core.IShared.PublicationStatus.Draft) },
		]
	}

	render() {
		if (this.state.loading) { return <DimmedLoader component={null} isLoading={true} /> }
		const actionButtons = this.getActionBarButtons()
		return (
			<RS.Row className={'page-form-content-row justify-content-center'}>
				<RS.Col className={'editor-row no-padding'}>
					<ActionBar buttonItems={actionButtons} onCancel={this.handleCancel} />
					<Editor
						className='mx-auto'
						fullScreen={true}
						richContent={this.state.richContent}
						placeholder={'Enter content...'}
						onChange={this.handleEditorChange}
					/>
				</RS.Col>
			</RS.Row>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	sectionState: state.section,
	userState: state.user,
})

const mapDispatchToProps = {
	...SectionActions,
	...AlertActions,
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionPageForm)
