// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'

// Internal Dependencies

// Table
import { ReservationSettingsHoursOfOperationColumns } from './table'
import TableComponent from '../../Shared/Table'

// Helpers
import { dayOfWeekSelectionInputs } from '../../../helpers/form'

interface ComponentProps {
	settingHours: core.IShared.HoursOfOperation[]
	onTableDropdown: (e: any) => Promise<void>
	onAddHours: () => Promise<void>
}

type Props = ComponentProps

/**
 * This section contains a table that will hold the user-defined
 * ranges of time, which determine when reservations can be made on certain
 * days of the week within the range of dates that have been selected.
 *
 * Clicking the 'Add Hours' button will display a modal where the user
 * can add additional time restrictions to the table.
 */
export const HoursSection: React.FunctionComponent<Props> = (props: Props) => {
	const tableColumns = ReservationSettingsHoursOfOperationColumns

	const dataForTable = []
	if (props.settingHours) {
		const hoursForm = props.settingHours
		for (let i = 0; i < hoursForm.length; i++) {
			const _id = `${hoursForm[i].dayOfWeek}`
			const rowIdx = i
			const day = dayOfWeekSelectionInputs[Number(hoursForm[i].dayOfWeek)].label
			const open = new Date(hoursForm[i].opens)
			const close = new Date(hoursForm[i].closes)
			const duration = hoursForm[i].duration

			dataForTable.push({_id, rowIdx, day, open, close, duration })
		}
	}

	return (
		<div className='reservationSettings-hours-section'>
			<h3 className='reservationSettings-section-heading'>Hours of Operation</h3>
			<hr/>
			<p>Configure the base calendar hours of operation.</p>

			<TableComponent
				columns={tableColumns}
				rowItems={dataForTable}
				showPaging={false}
				dropdownHandler={props.onTableDropdown}
			/>

			<button
				className='reservationSettings-right-aligned-button btn btn-primary'
				onClick={props.onAddHours}
			>
				Add Hours
			</button>
		</div>
	)
}
