// External Dependencies
import * as React from 'react'
import * as Feather from 'react-feather'
import * as RS from 'reactstrap'

// Internal Dependencies

// Components
import DatePickerComponent from '../DatePicker'
import { FormInputType } from '../Form'
import ButtonGroupComponent, { ButtonType, HeaderButton } from '../ButtonGroup'
import { RSA_NO_PADDING } from 'constants'
import { colorSelectionInputs } from '../../../helpers/form'

// Interfaces
interface DateBarProps {
	currentDate: string
	buttonHandler?: (selectedDate: string) => void
}

type Props = DateBarProps
type State = any

export default class DateBarComponent extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props)
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	handleDateButtonAction = async (e: any) => {
		const currentDate = new Date(this.props.currentDate)
		let newDate
		const action = e.target.value
		switch (action) {
			case 'prevWeek':
				currentDate.setDate(currentDate.getDate() - 1)
				newDate = currentDate.toISOString()
				break
			case 'nextWeek':
				currentDate.setDate(currentDate.getDate() + 1)
				newDate = currentDate.toISOString()
				break
			default:
				newDate = action
				break
		}

		this.props.buttonHandler(newDate)
	}

	changeDate = (change: number) => {
		const currentDate = new Date(this.props.currentDate)
		currentDate.setDate(currentDate.getDate() + change)
		const newDate = currentDate.toISOString()
		this.props.buttonHandler(newDate)
	}

	handleTodayClick = () => {
		this.props.buttonHandler(new Date().toISOString())
	}

	handleChange = (date: Date) => {
		this.props.buttonHandler(date.toISOString())
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildDateControlElements = () => {
		return (
			<div className='date-control-elements-container d-flex align-items-center'>
				<RS.Button onClick={() => this.changeDate(-1)}>
					<Feather.ChevronLeft size={24} />
				</RS.Button>

				{this.buildDatePicker()}

				<RS.Button onClick={() => this.changeDate(1)}>
					<Feather.ChevronRight size={24} />
				</RS.Button>

				<RS.Button className={'today-button'} onClick={() => this.handleTodayClick()}>
					{'Today'}
				</RS.Button>
			</div>
		)
	}

	buildDatePicker = () => {
		const date = new Date(this.props.currentDate)
		return (
			<div className={'date-picker-container'}>
				<DatePickerComponent
					selected={date}
					type={FormInputType.DATE}
					className={`form-control`}
					onChange={this.handleChange}
					isClearable={false}
					featherIcon={Feather.Calendar}
				/>
			</div>
		)
	}

	render() {

		return (
			<div className={'date-bar-component d-flex justify-content-between align-items-center'}>
				{this.buildDateControlElements()}
			</div>
		)
	}
}
