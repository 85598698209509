// External Dependencies
import * as React from 'react'
import { parsePhoneNumber } from 'libphonenumber-js'

/**
 * Returns a formatted a phone number in international format (+1 prefix).
 */
export const formatPhoneNumber = (phoneNumber: any): string => {
	if (!phoneNumber) {
		return ''
	}
	const stringNumber = `${phoneNumber}`
	return parsePhoneNumber(stringNumber, 'US').formatInternational()
}

/**
 * Returns a formatted number value or a string
 * if the passed in value can't be converted to a number.
 * @param price.
 */
export const formatPrice = (price: string | number | undefined) => {
	if (!price || (`${price}`).length === 0) {
		return 'Free'
	}
	return (isNaN(Number(price))) ? price : `$${(Number(price)).toFixed(2)}`
}

/**
 * Returns an anchor tag that will open the default email provider for the user's browser.
 * @param email
 */
export const buildEmailLink = (email: string): JSX.Element => {
	return <a href={`mailto:${email}`}>{email}</a>
}

export const capitalizeString = (stringToCapitalize: string): string => {
	const downCased = stringToCapitalize.toLocaleLowerCase()
	return `${downCased.charAt(0).toLocaleUpperCase()}${downCased.slice(1)}`
}
