// External Dependencies
import * as core from 'club-hub-core'

// Internal Dependencies
import { ActionTypes as StatementActionTypes, TypeKeys as StatementActionTypeKeys } from '../actions/statement'
import { mergeUpdatedModelObject } from '../helpers/reducer'

export type StatmentState = typeof defaultState
type ActionTypes = StatementActionTypes

const defaultState = {
	statements: [] as core.Statement.Model[],
	currentStatement: null as core.Statement.Model
}

function statementReducer(state = defaultState, action: ActionTypes): StatmentState {
	switch (action.type) {
		case StatementActionTypeKeys.FETCHED_MOST_RECENT_STATEMENT:
			return {
				...state,
				currentStatement: action.currentStatement,
				statements: mergeUpdatedModelObject(action.currentStatement, state.statements),
			}

		case StatementActionTypeKeys.FETCHED_STATEMENTS:
			return {
				...state,
				statements: mergeUpdatedModelObject(state.currentStatement, action.statements),
				currentStatement: action.statements[action.statements.length - 1]
			}

		case StatementActionTypeKeys.SET_CURRENT_STATEMENT:
			return {
				...state,
				currentStatement: action.currentStatement,
			}

		case StatementActionTypeKeys.CLEAR_CURRENT_STATEMENT:
			return {
				...state,
				currentStatement: null
			}
		default:
			return state
	}
}

export default statementReducer
