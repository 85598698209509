// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as Feather from 'react-feather'
import * as RS from 'reactstrap'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies

// Components
import ReservationCellComponent from './ReservationCell'
import { ReservationCellAction } from '../shared/ReservationActionButtons'
import TableHeader from '../../Shared/TableHeader'
import DateBarComponent from '../../Shared/DateBar'
import GolfReservation from '../../Modals/GolfReservation'
import DimmedLoader from '../../Shared/DimmedLoader'
import ModalComponent from '../../Shared/Modal'
import { DropdownItem } from '../../Shared/Dropdown'
import LotteryForm from './LotteryForm'
import BlockModal from '../../Modals/BlockModal'
import EmptyContent, { ContentType } from '../../Shared/EmptyContent'
import TeeTimeMenu from './TeeTimeMenu'

// Actions
import { EventActions, AlertActions, CalendarActions, LotteryActions } from '../../../actions/index'

// State / Reducers
import { RootReducerState } from '../../../reducers'
import { inCustomerViewSelector, usersByIDSelector } from '../../../reducers/user'

// Page Header Inputs
import {
	ReservationSettingsHeaderDateInputs,
	ReservationSettingsDropdownInputs,
	buildHeaderButtons,
	TeeSheetActions
} from './headerInputs'

// Helpers
import * as Constants from '../../../constants'
import { setStateAsync } from '../../../helpers/promise'
import * as dateHelper from '../../../helpers/date'
import { QueryOptions } from '../../../helpers/interface'
import { getMostSpecificSetting } from '../../../helpers/reservation'
import { getAllTeeSheetEvents, getPendingLotteryEvents } from '../../../helpers/teeSheet'
import TeeSheetHelper, { TeeSheetHelperProps, CellInfo } from './teeSheetHelper'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	error: false,
	initialLoad: true,
	loading: false,
	selectedCalendarID: null as string | null,
	selectedCalendar: null as core.Calendar.Model,
	selectedGroupID: null as string | null,
	selectedEvent: null as core.Event.AvailableEvent,
	selectedReservation: null as core.Event.Reservation,
	selectedDate: new Date().toISOString() as string | null,
	showingBookingModal: false,
	showingBlockModal: false,
	showingDeletionModal: false,
	bookingModalDate: null as Date | null,
	bookingModalReservationID: null as string | null,
	toggleIndex: null as string,
	showLottery: false,
	recurring: null as number | null,
	recurringEnd: null as Date | null,
	dropdownOpen: ''
}

type Props = ConnectedState & ConnectedActions & RouteComponentProps
type State = typeof initialState

const BaseParticipants = 4

class ReservationComponent extends React.Component<Props, State> {
	private calendarGroup: core.Calendar.Group

	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		const parsedQuery = queryString.parse(this.props.location.search)
		const date = parsedQuery.selectedDate
		if (isNullOrUndefined(date)) {
			const today = dateHelper.beginningOfDay(new Date().toISOString())
			this.updatePathForDate(today)
		}

		// Get the first Calendar Group with a name of 'Golf'
		const calendarGroups = oc(this).props.calendarState.groups([])
		const calendarGroup = calendarGroups.find((group) => group.type === core.Calendar.GroupType.Golf)
		const golfCalendarGroupID = calendarGroup._id

		if (this.props.isCustomerView) {
			const queryParams: QueryOptions = { offset: 0, limit: 0, groupID: golfCalendarGroupID }
			const [fetchCalendarsErr] = await to(this.props.fetchCalendars(queryParams) as any)
			if (fetchCalendarsErr) {
				this.props.fireFlashMessage(`Problem fetching Tee Times.${fetchCalendarsErr.message}`, Constants.FlashType.DANGER)
				await setStateAsync(this, { loading: false, error: true })
				return
			}
		}

		// Get the first Calendar that is a part of the Calendar Group
		const calendars = oc(this).props.calendarState.calendars([])
		const defaultCalendar = calendars.find((calendar) => calendar.groupID === golfCalendarGroupID)
		const defaultCalendarID = `${defaultCalendar._id}`
		const newState = {
			initialLoad: false,
			selectedCalendar: defaultCalendar,
			selectedCalendarID: defaultCalendarID,
			selectedGroupID: `${calendarGroup._id}`
		}
		this.setState(newState)

		// KC - Turning this off for now. Its pretty annoying.
		// // Scroll the current time into view
		// const currentTimeRow = document.getElementsByClassName('reservation-row-current-time')[0]
		// if (!isNullOrUndefined(currentTimeRow)) {
		// 	scrollIntoView(currentTimeRow, { behavior: 'smooth', scrollMode: 'if-needed', inline: 'start' })
		// }
	}

	async componentDidUpdate(oldProps: Props) {
		const parsedQuery = queryString.parse(this.props.location.search)
		const date = parsedQuery.selectedDate
		if (isNullOrUndefined(date)) { return }

		let selectedDate = new Date(Number(date)).toISOString()
		selectedDate = dateHelper.beginningOfDay(selectedDate).toISOString()

		if (`${selectedDate}` === this.state.selectedDate) { return }
		await setStateAsync(this, { selectedDate, loading: true })
		await this.refreshEvents()
		this.setState({ loading: false })
	}

	updatePathForDate = (date: Date) => {
		const pathname = Constants.GOLF_RESERVATION_ROUTE
		const search = queryString.stringify({ selectedDate: date.getTime() })
		this.props.history.push({ pathname, search })
	}

	refreshEvents = async () => {
		if (!this.state.selectedCalendarID) { return }

		const [err] = await to(this.props.fetchBookableEvents(this.state.selectedDate, [this.state.selectedCalendarID], !this.props.isCustomerView) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to fetch Events.${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
		}
	}

	// ----------------------------------------------------------------------------------
	// Menu Action Handlers
	// ----------------------------------------------------------------------------------

	handleButtonSelected = async (e: { target: { value: TeeSheetActions } }) => {
		const action = e.target.value
		switch (action) {
			case TeeSheetActions.NewTeeSheetEvent:
				return this.handleNewGolfEvent()
			case TeeSheetActions.NewBlock:
				return this.handleNewBlock()
			case TeeSheetActions.ViewCalendar:
				return this.handleViewCalendar()
			case TeeSheetActions.ViewSettings:
				return this.handleViewSettings()
			case TeeSheetActions.NewTeeTime:
				return this.handleNewReservation()
			case TeeSheetActions.ViewLotteryRequests:
				return this.handleViewLotteryRequests()
		}
	}

	handleActionSelected = async (item: DropdownItem) => {
		switch (item.value) {
			case TeeSheetActions.NewTeeSheetEvent:
				return this.handleNewGolfEvent()
			case TeeSheetActions.ViewCalendar:
				return this.handleViewCalendar()
			case TeeSheetActions.ViewSettings:
				return this.handleViewSettings()
			case TeeSheetActions.NewTeeTime:
				return this.handleNewReservation()
			case TeeSheetActions.ViewLotteryRequests:
				return this.handleViewLotteryRequests()
		}
	}

	handleReservationActionSelected = async (action: ReservationCellAction, info: CellInfo) => {
		const updatedParticipants = info.reservation.participants.map((participant) => {
			// Check that we have found the Participant to update
			if (`${participant._id}` === `${info.participant._id}`) {
				switch (action) {
					case ReservationCellAction.CHECK_IN:
						return { ...participant, checkedIn: !participant.checkedIn }
					case ReservationCellAction.PAID:
						return { ...participant, paid: !participant.paid }
					default:
						return participant
				}
			}
			return participant
		})

		const eventID = `${oc(info).event._id()}`
		const resID = `${info.reservation._id}`
		const resToUpdate: core.Event.Reservation = {
			...info.reservation,
			participants: updatedParticipants
		}
		const [updateErr] = await to(this.props.updateEventRsvp(resID, resToUpdate, eventID) as any)
		if (updateErr) {
			this.props.fireFlashMessage(`Failed to update Reservation with error: ${updateErr}`, Constants.FlashType.DANGER)
			return
		}

		// Fetch the Events
		await this.refreshEvents()
		this.props.fireFlashMessage('Successfully updated Reservation', Constants.FlashType.SUCCESS)
	}

	handleNewGolfEvent = async () => {
		const queryParams = { calendarID: this.state.selectedCalendarID, block: true }
		const search = queryString.stringify(queryParams)
		const location = {
			pathname: Constants.CREATE_EVENT_ROUTE,
			search: search,
			state: {
				previousLocationName: 'Tee Times',
				previousLocation: { ...this.props.location }
			}
		}
		this.props.history.push(location)
	}

	handleViewCalendar = async () => {
		const groupID = this.state.selectedGroupID
		const calendarID = this.state.selectedCalendarID
		const search = queryString.stringify({ groupID, calendarID })
		const location = { pathname: Constants.GOLF_RESERVATION_CALENDAR_ROUTE, search: search }
		this.props.history.push(location)
	}

	handleViewSettings = async () => {
		const golfCalendarID = this.state.selectedCalendarID
		const route = Constants.RESERVATION_SETTINGS_ROUTE.replace(':calendar_id', golfCalendarID)
		this.props.history.push(route)
	}

	handleViewLotteryRequests = async () => {
		this.setState({ showLottery: !this.state.showLottery })
	}

	// ----------------------------------------------------------------------------------
	// Booking Modal Handlers
	// ----------------------------------------------------------------------------------

	handleNewReservation = async () => {
		this.setState({ showingBookingModal: true })
	}

	// ----------------------------------------------------------------------------------
	// Block Modal Handlers
	// ----------------------------------------------------------------------------------

	handleNewBlock = async (bookableEvent?: core.Event.AvailableEvent) => {
		this.setState({ showingBlockModal: true, selectedEvent: bookableEvent })
	}

	handleBlockModalClose = async () => {
		this.setState({ showingBlockModal: false, recurring: null, recurringEnd: null })
	}

	/**
	 * Handles the selection of a calendar event.
	 */
	displayEvent = (eventID: string) => {
		const queryParams = { eventID: eventID }
		const location = {
			pathname: Constants.VIEW_EVENT_ROUTE,
			search: queryString.stringify(queryParams),
			state: {
				previousLocationName: 'Tee Sheet',
				previousLocation: { ...this.props.location },
			}
		}
		this.props.history.push(location)
	}

	/**
	 * If a customer selects a tee time they own, navigate them to the reservation detail screen.
	 */
	handleCustomerSelectedReservation = (bookableEvent: core.Event.AvailableEvent, reservation: core.Event.Reservation) => {
		const userID = oc(this).props.userState.loggedInUser._id()
		const belongsToReservation = oc(reservation).participants([]).find((p: core.Event.Participant) => {
			const participantID = oc(p).userID()
			if (isNullOrUndefined(participantID)) { return false }
			return `${p.userID}` === `${userID}`
		})

		// The Member doesn't belong the Reservation -- bail
		if (isNullOrUndefined(belongsToReservation)) { return null }

		// Redirect to the Detail view if the Event has already occurred.
		// Otherwise redirect to the confirmation view, so that changes can be made.
		const bookingInPast = new Date(bookableEvent.time).getTime() < new Date().getTime()
		return (bookingInPast) ?
			this.displayEventDetail(`${reservation._id}`, `${bookableEvent.existingEvent._id}`) :
			this.displayEditReservation(bookableEvent, reservation)
	}

	// ----------------------------------------------------------------------------------
	// Date Change Handlers
	// ----------------------------------------------------------------------------------

	handleDateChanged = async (newDate: string) => {
		this.updatePathForDate(new Date(newDate))
	}

	// ----------------------------------------------------------------------------------
	// Tee Time Click Handlers
	// ----------------------------------------------------------------------------------

	handleExistingTeeTimeClicked = async (bookableEvent: core.Event.AvailableEvent, reservation: core.Event.Reservation) => {
		if (this.props.isCustomerView) {
			return this.handleCustomerSelectedReservation(bookableEvent, reservation)
		}
		const updatedState = { showingBookingModal: true, selectedEvent: bookableEvent, selectedReservation: reservation }
		await setStateAsync(this, updatedState)
	}

	handleUnavailableCellClicked = async (bookableEvent: core.Event.AvailableEvent, reservation: core.Event.Reservation) => {
		if (bookableEvent.existingEvent.blockCalendar) {
			return this.handleNewBlock(bookableEvent)
		}
		this.displayEvent(`${bookableEvent.existingEvent._id}`)
	}

	displayAdminModal = async (bookingDate: Date, reservationID: string) => {
		await setStateAsync(this, {
			showingBookingModal: true,
			bookingModalDate: bookingDate,
			bookingModalReservationID: reservationID,
		})
	}

	// ----------------------------------------------------------------------------------
	// Lottery Handlers
	// ----------------------------------------------------------------------------------

	updateLotteryStatus = async (lottery: core.Lottery.Model, status: core.Lottery.Status) => {
		await setStateAsync(this, { loading: true })
		lottery.status = status
		const [err, updated] = await to(this.props.updateLottery(lottery, this.state.selectedCalendarID) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to fetch Events.${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}
		this.props.fireFlashMessage('Successfully updated lottery request.', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false })
	}

	/**
	 * Navigates the user to the Reservation Detail component where they can view the selected reservation.
	 */
	displayEventDetail = (reservationID: string, eventID: string) => {
		const queryParams = { eventID: eventID, reservationID: reservationID }
		const location = {
			pathname: Constants.VIEW_RESERVATION_DETAILS_ROUTE.replace(':type', 'golf').replace(':action', 'view'),
			search: queryString.stringify(queryParams)
		}
		this.props.history.push(location)
	}

	handleNewTeeTimeClicked = async (bookableEvent: core.Event.AvailableEvent) => {
		if (this.props.isCustomerView) {
			return this.displayConfirmReservation(bookableEvent)
		}
		await setStateAsync(this, { showingBookingModal: true, selectedEvent: bookableEvent })
	}

	/**
 	* Navigates the user to the fully pre-filled ConfirmReservation component where they can make changes to the reservation.
 	*/
	displayEditReservation = (bookableEvent: core.Event.AvailableEvent, reservation: core.Event.Reservation) => {
		const pathname = Constants.GOLF_RESERVATION.replace(':action', 'update')

		const queryParams = { time: bookableEvent.time, reservationID: `${reservation._id}` }
		const search = queryString.stringify(queryParams)
		const location = { search, pathname }
		this.props.history.push(location)
	}

	/**
	 * Navigates the user the reservation confirmation component.
	 * All reservation information is passed into this component via route state.
	 */
	displayConfirmReservation = async (bookableEvent: core.Event.AvailableEvent, lottery?: boolean) => {
		const route = Constants.GOLF_RESERVATION.replace(':action', 'new')
		const pathname = lottery ? `${route}?lottery=true` : route

		const queryParams = { time: bookableEvent.time }
		const search = queryString.stringify(queryParams)
		const location = { search, pathname }
		this.props.history.push(location)
	}

	handleBookingModalClosed = async () => {
		await setStateAsync(this, {
			showingBookingModal: false,
			showingBlockModal: false,
			bookingModalDate: null,
			bookingModalReservationID: null,
			selectedEvent: null,
			selectedReservation: null,
		})
	}

	handleBookingModalSaved = async () => {
		this.setState({ loading: true })
		await this.refreshEvents()

		await setStateAsync(this, {
			loading: false,
			bookingModalDate: null,
			bookingModalReservationID: null,
			selectedEvent: null,
			selectedReservation: null,
			showingBlockModal: false,
			showingBookingModal: false
		})
	}

	// ----------------------------------------------------------------------------------
	// Live Select Action Handlers
	// ----------------------------------------------------------------------------------

	handleLiveToggle = async (event: any) => {
		switch (event.value as Constants.LiveStatus) {
			case Constants.LiveStatus.Live:
				await this.updateCalendarLiveStatus(true)
				this.props.fireFlashMessage(`Tee Time bookings are now Live`, Constants.FlashType.SUCCESS)
				break
			case Constants.LiveStatus.Off:
				this.updateCalendarLiveStatus(false)
				this.props.fireFlashMessage(`Tee Time bookings are now Off`, Constants.FlashType.WARNING)
				break
		}
	}

	updateCalendarLiveStatus = async (live: boolean) => {
		await setStateAsync(this, { loading: true })
		const calendarID = this.state.selectedCalendarID
		const [err] = await to(this.props.updateCalendar(calendarID, { live: live }) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed update Tee Time calendar.${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}
		await setStateAsync(this, { loading: false })
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildPageHeader = (eventData: core.Event.EventDataForCalendar) => {
		const dropdownInputs = ReservationSettingsDropdownInputs(this, oc(eventData).lotteryDay())
		const headerButtons = buildHeaderButtons()

		const inputs = this.props.isCustomerView ? [] : [...dropdownInputs]
		const buttons = this.props.isCustomerView ? null : headerButtons
		const title = oc(this).state.selectedCalendar.name()
		const dateBar = (<DateBarComponent buttonHandler={this.handleDateChanged} currentDate={this.state.selectedDate} />)
		return (
			<div className='page-header-container sticky-top'>
				<TableHeader
					pageTitle={title}
					fullScreen={true}
					inputs={inputs}
					buttons={buttons}
					buttonHandler={this.handleButtonSelected}
					childComponent={dateBar}
				/>
			</div>
		)
	}

	/**
	 * Creates a list of rows for the Reservation table
	 * @param bookableEvents a list of bookable Events
	 */
	buildReservationRows = (bookableEvents: core.Event.AvailableEvent[], lotteryDay: boolean) => {
		const reservationRows: JSX.Element[] = [this.buildDateInfoRow()]
		let foundCurrentTime = false
		const currentTime = new Date()
		const eventsByHour = this.groupEventsByHour(bookableEvents)

		Object.keys(eventsByHour).forEach((hourKey: string, idx: number) => {
			const events = eventsByHour[hourKey]
			const hourRow = this.buildHourRow(hourKey, idx)
			reservationRows.push(hourRow)
			for (let rowIndex = 0; rowIndex < events.length; rowIndex++) {
				const event = events[rowIndex]
				const eventInPast = new Date(event.time).getTime() < currentTime.getTime()
				const isCurrent = !foundCurrentTime && !eventInPast
				if (isCurrent) {
					foundCurrentTime = true
				}
				const row = this.buildReservationRow(event, `${rowIndex} +${idx}`, isCurrent, lotteryDay)
				reservationRows.push(row)
			}
		})
		return reservationRows
	}

	groupEventsByHour = (bookableEvents: core.Event.AvailableEvent[]): { [key: string]: core.Event.AvailableEvent[] } => {
		const eventMap: { [key: string]: any } = {}
		bookableEvents.forEach((event: core.Event.AvailableEvent) => {
			const hour = new Date(event.time).getHours()
			let hourArray = eventMap[`${hour}`]
			if (hourArray) {
				hourArray.push(event)
			} else {
				hourArray = [event]
			}
			eventMap[`${hour}`] = hourArray
		})
		return eventMap
	}

	buildTimeCol = (bookableEvent: core.Event.AvailableEvent, time: string) => {
		const colClass = 'reservation-time-col d-flex align-items-center'
		return (
			<RS.Col sm='12' md='1'>
				<RS.Row className='reservation-time-row'>
					<RS.Col className={colClass}>
						<p className={'time-label'}>{time}</p>
					</RS.Col>
				</RS.Row>
			</RS.Col>
		)
	}

	buildReservationCol = (bookableEvent: core.Event.AvailableEvent, rowIndex: string, lotteryDay?: boolean) => {
		const reservationCells = this.buildReservationCells(bookableEvent, rowIndex, lotteryDay)
		return (
			<RS.Col>
				<RS.Row className={'reservation-detail-row'} key={`reservation - row - ${rowIndex}`}>
					{reservationCells}
				</RS.Row>
			</RS.Col>
		)
	}

	handleDropdownToggle = (key?: string) => {
		if (key === this.state.dropdownOpen) {
			this.setState({ dropdownOpen: '' })
		} else {
			this.setState({ dropdownOpen: key })
		}
	}

	buildActionButtonCol = (event: core.Event.AvailableEvent, rowIndex: string) => {
		const className = 'tee-sheet-action-col d-flex align-items-center justify-content-center '
		return (
			<RS.Col md={1} className={className} key={`action-col-${rowIndex}`}>
				<TeeTimeMenu event={event} handleActionClick={this.handleUnreservedCellClick} />
			</RS.Col>
		)
	}

	buildDateInfoRow = () => {
		const date = new Date(this.state.selectedDate)
		const weekday = dateHelper.dayString(date)
		const day = dateHelper.dateString(date)

		const setting = this.getReservationSetting(new Date(this.state.selectedDate))
		const hours = setting.hours.find((hour: core.IShared.HoursOfOperation) => hour.dayOfWeek === date.getDay())
		if (!hours) { return }

		const open = dateHelper.timeString(hours.opens)
		const close = dateHelper.timeString(hours.closes)
		const hourLabel = `Hours: ${open} - ${close}`

		return (
			<RS.Row key={`date-info-row`} className={`date-info-row`}>
				<RS.Col xs={5} className={'date-info-col d-flex align-items-center'}>
					<h4 className={'weekday-label'}>{weekday}</h4>
					<h4 className={'date-label'}>{day}</h4>
				</RS.Col>
				<RS.Col xs={7} className={'date-info-col d-flex align-items-center justify-content-end'}>
					<h4 className={'date-label'}>{hourLabel}</h4>
				</RS.Col>
			</RS.Row >
		)
	}

	buildHourRow = (hourKey: string, index: number) => {
		const date = new Date()
		date.setHours(Number(hourKey), 0, 0)
		const time = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
		return (
			<RS.Row key={`reservation-hour-row-${index}`} className={`reservation-hour-row`}>
				<RS.Col className={'reservation-hour-col d-flex align-items-center'}>
					<p>{time}</p>
					<div className={'hour-line'} />
				</RS.Col>
			</RS.Row >
		)
	}

	/**
	 * Creates an individual row for the Reservation table
	 * @param bookableEvent a bookable Event
	 * @param rowIndex the index of the row that is being built
	 */
	buildReservationRow = (bookableEvent: core.Event.AvailableEvent, rowIndex: string, isCurrent?: boolean, lotteryDay?: boolean) => {
		const time = new Date(bookableEvent.time)
		const timeLabel = dateHelper.timeString(time)
		const isCurrentClass = (isCurrent) ? 'current' : ''
		const isInPastClass = dateHelper.dateIsInPast(time) ? 'past' : ''

		const lottery = this.getLottery(bookableEvent) as core.Lottery.Model
		const lotteryCol = this.state.showLottery ? this.buildLotteryActionCol(rowIndex, lottery) : null
		return (
			<RS.Row key={`reservation-row-${rowIndex}`} className={`reservation-row ${isCurrentClass} ${isInPastClass}`}>
				{this.buildTimeCol(bookableEvent, timeLabel)}
				{this.buildReservationCol(bookableEvent, rowIndex, isCurrent)}
				{this.buildActionButtonCol(bookableEvent, rowIndex)}
				{lotteryCol}
			</RS.Row >
		)
	}

	/**
	 * Create the Reservation cells for a row in the Reservation table
	 * @param bookableEvent a bookable Event
	 * @param rowIndex the index of the row that is being built
	 */
	buildReservationCells = (bookableEvent: core.Event.AvailableEvent, rowIndex: string, lotteryDay: boolean) => {
		const helperProps: TeeSheetHelperProps = {
			bookableEvent: bookableEvent,
			index: rowIndex,
			isCustomerView: this.props.isCustomerView,
			memberID: `${this.props.userState.loggedInUser._id}`,
			usersByID: this.props.usersByID,
			lotteryDay: lotteryDay
		}
		const helper = new TeeSheetHelper(helperProps)
		const cellInfo = helper.getCellInfo()
		return cellInfo.map((info: CellInfo) => {
			if (info.user || info.participant) {
				const size = (cellInfo.length >= BaseParticipants) ? 0 : 3
				return this.buildReservedCell(bookableEvent, info, size)
			}
			return this.buildUnreservedCell(bookableEvent, info)
		})
	}

	// ----------------------------------------------------------------------------------
	// Lottery UI Builders
	// ----------------------------------------------------------------------------------

	getLottery = (bookableEvent: core.Event.AvailableEvent) => {
		const event = bookableEvent.existingEvent
		if (!event) { return }

		const reservations = oc(event).reservations([])
		if (!reservations.length) { return }
		return reservations[0].lottery
	}

	handleToggle = async (index: string) => {
		const toggleIndex = (index === this.state.toggleIndex) ? null : index
		setStateAsync(this, { toggleIndex: toggleIndex })
	}

	buildLotteryActionCol = (rowIndex: string, lottery: core.Lottery.Model) => {
		return (
			<RS.Col xs='2'>
				<RS.Row className='reservation-time-row'>
					<RS.Col className='reservation-time-col d-flex align-items-center justify-content-center'>
						{this.buildLotteryActionButton(rowIndex, lottery as core.Lottery.Model)}
					</RS.Col>
				</RS.Row>
			</RS.Col>
		)
	}

	buildLotteryActionButton = (index: string, lottery: core.Lottery.Model) => {
		const isOpen = this.state.toggleIndex === index
		return (
			<RS.ButtonDropdown isOpen={isOpen} toggle={() => this.handleToggle(index)}>
				<RS.Button id='caret' color='primary'>{'Action'}</RS.Button>
				<RS.DropdownToggle caret={true} color='primary' />
				<RS.DropdownMenu>
					<RS.DropdownItem onClick={() => this.updateLotteryStatus(lottery, core.Lottery.Status.Approved)}>Approve</RS.DropdownItem>
					<RS.DropdownItem onClick={() => this.updateLotteryStatus(lottery, core.Lottery.Status.Moved)}>Move</RS.DropdownItem>
					<RS.DropdownItem divider={true} />
					<RS.DropdownItem onClick={() => this.updateLotteryStatus(lottery, core.Lottery.Status.Rejected)}>Reject</RS.DropdownItem>
				</RS.DropdownMenu>
			</RS.ButtonDropdown>
		)
	}

	// ----------------------------------------------------------------------------------
	// Modal Builders
	// ----------------------------------------------------------------------------------

	buildBookingModal = (eventData: core.Event.EventDataForCalendar) => {
		if (!this.state.showingBookingModal) { return null }
		if (eventData.lotteryDay && this.props.isCustomerView) { return null }

		const setting = this.getReservationSetting(new Date(this.state.selectedDate))
		const eventTime = this.state.selectedEvent ? this.state.selectedEvent.time : this.state.selectedDate
		return (
			<GolfReservation
				eventTime={eventTime}
				setting={setting}
				bookableEvent={this.state.selectedEvent}
				reservation={this.state.selectedReservation}
				eventCalendarID={this.state.selectedCalendarID}
				onCancel={this.handleBookingModalClosed}
				onSave={this.handleBookingModalSaved}
			/>
		)
	}

	buildBlockModal = () => {
		if (!this.state.showingBlockModal) { return null }
		if (this.props.isCustomerView) { return null }

		const exitingEvent = oc(this).state.selectedEvent.existingEvent()
		const setting = this.getReservationSetting(new Date(this.state.selectedDate))
		const eventTime = this.state.selectedEvent ? this.state.selectedEvent.time : this.state.selectedDate
		return (
			<BlockModal
				start={eventTime}
				setting={setting}
				event={exitingEvent}
				recurring={this.state.recurring}
				recurringEnd={this.state.recurringEnd}
				handleToggle={this.handleBlockModalClose}
				refreshEvents={this.refreshEvents}
			/>
		)
	}

	buildLotteryForm = (eventData: core.Event.EventDataForCalendar) => {
		if (!this.state.showingBookingModal) { return null }
		if (!eventData.lotteryDay || !this.props.isCustomerView) { return null }

		return (
			<LotteryForm
				nextHandler={() => this.displayConfirmReservation(this.state.selectedEvent, true)}
				cancelHandler={this.handleBookingModalClosed}
			/>
		)
	}

	// ----------------------------------------------------------------------------------
	// Render
	// ----------------------------------------------------------------------------------

	render() {
		if (!this.state.selectedDate) { return (<div />) }

		const loading = (this.state.initialLoad || this.state.loading)
		if (loading) { return (<DimmedLoader component={null} isLoading={loading} />) }

		const eventDataForCalendar: core.Event.EventDataForCalendar[] = oc(this).props.eventState.bookableEvents([])
		const eventData = eventDataForCalendar[0]
		const bookableEvents = this.getEventsToDisplay(eventData)

		const teeSheetRows = this.buildReservationRows(bookableEvents, oc(eventData).lotteryDay(false))
		const emptyContent = (<EmptyContent type={ContentType.TeeSheetUnavailable} isInPast={true} />)
		const available = this.teeSheetAvailable(new Date(this.state.selectedDate))
		const content = available ? teeSheetRows : emptyContent
		const teeSheet = (
			<div className='reservation-content-container'>
				{content}
			</div>
		)
		return (
			<RS.Row className='tee-sheet-container'>
				<RS.Col className={'no-padding'}>
					{this.buildPageHeader(eventData)}
					{teeSheet}
					{this.buildBookingModal(eventData)}
					{this.buildBlockModal()}
					{this.buildLotteryForm(eventData)}
				</RS.Col>
			</RS.Row>
		)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	getEventsToDisplay = (eventData: core.Event.EventDataForCalendar) => {
		if (!this.state.showLottery) {
			return getAllTeeSheetEvents(eventData)
		}
		return getPendingLotteryEvents(eventData)
	}

	getGolfCalendar = () => {
		const group = this.getCalendarGroup()
		const calendars = oc(this).props.calendarState.calendars([])
		return calendars.find((cal: core.Calendar.Model) => `${cal.groupID}` === `${group._id}`)
	}

	getCalendarGroup = () => {
		const calendarGroups = oc(this).props.calendarState.groups([])
		return calendarGroups.find((cg) => cg.type === core.Calendar.GroupType.Golf)
	}

	getCalendarsForGroup = (groupID: string) => {
		const calendars = oc(this).props.calendarState.calendars([])
		return calendars.filter((cal) => `${cal.groupID}` === groupID)
	}

	getCalendar = (id: string) => {
		const group = this.getCalendarGroup()
		const calendarsForGroup = this.getCalendarsForGroup(`${group._id}`)
		return calendarsForGroup.find((cal) => `${cal._id}` === id)
	}

	getReservationSetting = (date: Date) => {
		const calendar = this.getGolfCalendar()
		const reservationSettings = oc(calendar).reservationSettings([])
		return getMostSpecificSetting(reservationSettings, date)
	}

	teeSheetAvailable = (date: Date) => {
		const setting = this.getReservationSetting(date)
		const hour = oc(setting).hours([]).find((h: core.IShared.HoursOfOperation) => h.dayOfWeek === date.getDay())
		return hour ? true : false
	}

	// ----------------------------------------------------------------------------------
	// Cell Info Helpers
	// ----------------------------------------------------------------------------------

	handleUnreservedCellClick = (action: ReservationCellAction, event: core.Event.AvailableEvent) => {
		if (action === ReservationCellAction.NEW_TEE_TIME) {
			this.handleNewTeeTimeClicked(event)
		}
		if (action === ReservationCellAction.NEW_BLOCK) {
			this.handleNewBlock(event)
		}
	}

	buildUnreservedCell = (bookableEvent: core.Event.AvailableEvent, info: CellInfo) => {
		const id = `reservation-cell-row${info.index}-reservation${info.resIndex}-cell${info.cellIndex}-open`
		const loggedInUser = oc(this).props.userState.loggedInUser()
		return (
			<ReservationCellComponent
				id={id}
				key={id}
				type={info.type}
				lotteryDay={info.lotteryDay}
				club={this.props.clubState.loggedInClub}
				event={bookableEvent}
				loggedInUser={loggedInUser}
				onClick={(action) => this.handleUnreservedCellClick(action, bookableEvent)}
				onUnavailableClick={() => this.handleUnavailableCellClicked(bookableEvent, info.reservation)}
			/>
		)
	}

	buildReservedCell = (bookableEvent: core.Event.AvailableEvent, info: CellInfo, size: number) => {
		const id = `reservation-cell-row-${info.index}-reservation${info.resIndex}-cell${info.cellIndex}`
		const loggedInUser = oc(this).props.userState.loggedInUser()
		return (
			<ReservationCellComponent
				id={id}
				key={id}
				size={size}
				type={info.type}
				reservation={info.reservation}
				event={bookableEvent}
				club={this.props.clubState.loggedInClub}
				user={info.user}
				color={info.color}
				participant={info.participant}
				loggedInUser={loggedInUser}
				onActionClick={(action) => this.handleReservationActionSelected(action, info)}
				onClick={() => this.handleExistingTeeTimeClicked(bookableEvent, info.reservation)}
			/>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	eventState: state.event,
	clubState: state.club,
	userState: state.user,
	calendarState: state.calendar,
	isCustomerView: inCustomerViewSelector(state),
	usersByID: usersByIDSelector(state)
})

const mapDispatchToProps = {
	...EventActions,
	...AlertActions,
	...CalendarActions,
	...LotteryActions,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReservationComponent)
