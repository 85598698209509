// External Dependencies
import * as React from 'react'
import { Icon } from 'react-feather'
import { oc } from 'ts-optchain'
import * as RS from 'reactstrap'

// Internal Dependencies
import { InputSelectionItem, FormInputType, ReactSelectItem } from '../Form'

// Components
import ButtonGroupComponent, { HeaderButton } from '../ButtonGroup'
import SearchComponent from '../Search'
import SelectComponent from '../Select'
import DatePickerComponent from '../DatePicker'
import DropdownComponent from '../Dropdown'
import { TabBarButtonInput, TabBarComponent } from '../TabBar'
import { Button, ButtonProps } from '../Formik/InputPrimitives/Button'

// Helpers
import { PropsFor } from '../../../helpers/interface'

// Interfaces
export enum PageHeaderInputType {
	Search = 'search',
	Select = 'select',
	Date = 'date',
	Dropdown = 'dropdown',
	Button = 'button',
	ButtonGroup = 'buttongroup',
	None = 'none'
}

interface PageHeaderInput {
	inputType: PageHeaderInputType
	placeholder?: string
	className?: string
	icon?: Icon
	faIcon?: string
}

export interface PageHeaderButtonGroupInput extends PageHeaderInput {
	buttons: ButtonProps[]
}

export interface PageHeaderButtonInput extends PageHeaderInput {
	button: ButtonProps
}

export interface PageHeaderSearchInput extends PageHeaderInput {
	changeHandler: (term: string) => any
	enterHandler: (event: any) => any
	clearHandler: () => any
	defaultValue?: string
}

export interface PageHeaderSelectInput extends PageHeaderInput {
	selected?: InputSelectionItem
	placeholder?: string
	inputs: InputSelectionItem[] | ReactSelectItem[]
	changeHandler: (data: InputSelectionItem) => any
}

export interface PageHeaderDateInput extends PageHeaderInput {
	type: FormInputType.DATE | FormInputType.TIME | FormInputType.DATE_TIME
	selected: Date | null
	changeHandler: (date: Date) => any
	isClearable?: boolean
}

export type PageHeaderDropdownInput = PageHeaderInput & PropsFor<typeof DropdownComponent>

interface PageHeaderProps {
	pageTitle: string
	inputs?: Array<PageHeaderSearchInput | PageHeaderSelectInput | PageHeaderDateInput | PageHeaderDropdownInput | ReactSelectItem | HeaderButton[]>
	tabInputs?: TabBarButtonInput[]
	buttons?: HeaderButton[]
	buttonHandler?: any
	childComponent?: any
	fullScreen?: boolean
	noBottomMargin?: boolean
}

/**
 * Return a Page header component, ex: 'User Dashboard' with set spacing.
 * @param props
 */
const PageHeaderComponent: React.FunctionComponent<PageHeaderProps> = (props: PageHeaderProps) => {

	const buildItems = () => {
		if (!props.inputs) { return null }
		return props.inputs.map((input: any, index: number) => {
			const item = buildItem(input, index)
			if (!item) { return null }
			return (
				<div className={'table-header-item-container'} key={`table-header-item-container-${index}`}>
					{item}
				</div>
			)
		})
	}

	const buildItem = (input: any, index: number) => {
		switch (input.inputType) {
			case PageHeaderInputType.Button:
				return buildButtonInput(input, index)
			case PageHeaderInputType.Date:
				return buildDateInput(input, index)
			case PageHeaderInputType.Search:
				return buildSearchInput(input, index)
			case PageHeaderInputType.Select:
				return buildSelectInput(input, index)
			case PageHeaderInputType.Dropdown:
				return buildDropdownInput(input, index)
			case PageHeaderInputType.ButtonGroup:
				return buildButtonGroupInput(input, index)
			case PageHeaderInputType.None:
				return null
			default:
				return null
		}
	}

	const buildSearchInput = (input: PageHeaderSearchInput, index: number) => {
		const className = oc(input).className('')
		return (
			<div className={`${className}`} key={`search_input_${index}`}>
				<SearchComponent
					searchChangeHandler={input.changeHandler}
					searchClearHandler={input.clearHandler}
					searchEnterHandler={input.enterHandler}
					defaultValue={input.defaultValue}
				/>
			</div>
		)
	}

	const buildSelectInput = (input: PageHeaderSelectInput, index: number) => {
		const className = oc(input).className('')
		return (
			<div className={`${className} select-input`} key={`select_input_${index}`} >
				<SelectComponent
					placeholder={input.placeholder}
					options={input.inputs}
					onChange={input.changeHandler}
					value={input.selected}
					featherIcon={input.icon}
					faIcon={input.faIcon}
				/>
			</div>
		)
	}

	const buildDateInput = (input: PageHeaderDateInput, index: number) => {
		const className = oc(input).className('')
		return (
			<div className={`${className}`} key={`date_input_${index}`}>
				<DatePickerComponent
					type={input.type}
					className={`form-control`}
					selected={input.selected}
					onChange={input.changeHandler}
					isClearable={input.isClearable}
					featherIcon={input.icon}
					faIcon={input.faIcon}
				/>
			</div>
		)
	}

	const buildDropdownInput = (dropdown: PageHeaderDropdownInput, index: number) => {
		const className = oc(dropdown).className('')
		return (
			<div className={`${className}`} key={`dropdown_input_${index}`}>
				<DropdownComponent
					header={dropdown.header}
					category={dropdown.category}
					items={dropdown.items}
					onChange={dropdown.onChange}
					className={`form-control`}
					featherIcon={dropdown.featherIcon}
					faIcon={dropdown.faIcon}
				/>
			</div>
		)
	}

	const buildButtonInput = (input: PageHeaderButtonInput, index: number) => {
		const button = input.button
		return (<Button {...button} onClick={props.buttonHandler} className={'single-button'} />)
	}

	const buildButtonGroupInput = (input: PageHeaderButtonGroupInput, index: number) => {
		const buttons = input.buttons.map((button: ButtonProps, idx: number) => {
			return (<Button key={`button-${idx}`} {...button} onClick={props.buttonHandler} />)
		})
		return (
			<RS.ButtonGroup>
				{buttons}
			</RS.ButtonGroup>
		)
	}

	const buildButtons = () => {
		if (!props.buttons) { return null }

		return (
			<ButtonGroupComponent
				buttons={props.buttons}
				buttonHandler={props.buttonHandler}
				className={'page-header-btns ml-2'}
			/>
		)
	}

	const buildTabBar = () => {
		if (!props.tabInputs) { return null }
		return (
			<RS.Row className={'table-tabs-row'}>
				<RS.Col sm={12}>
					<div className='table-header-tab-row'>
						<TabBarComponent buttons={props.tabInputs} />
					</div>
				</RS.Col>
			</RS.Row>
		)
	}

	const fullScreen = props.fullScreen ? 'full-screen' : ''
	const marginBottom = props.noBottomMargin ? '' : 'margin-bottom'
	const paddingBottom = props.tabInputs ? '' : 'padding-bottom '
	const children = (
		<>
			{props.childComponent}
			{buildItems()}
			{buildButtons()}
		</>
	)

	const containerClass = `table-header-container fill ${fullScreen} ${paddingBottom} ${marginBottom}`
	return (
		< RS.Container className={containerClass}>
			<RS.Row className={'table-header-row d-flex align-items-center'}>
				<RS.Col sm={12} md={3}>
					<h1 className='page-title'>{props.pageTitle}</h1>
				</RS.Col>
				<RS.Col sm={12} lg={9} className={'d-flex align-items-center input-fields flex-wrap'}>
					{children}
				</RS.Col>
			</RS.Row>
			{buildTabBar()}
		</RS.Container >
	)
}

export default PageHeaderComponent
