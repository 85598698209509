// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'

interface ComponentProps {
	children: JSX.Element
	className?: string
	error?: Error
}

type Props = ComponentProps

export const Label: React.FunctionComponent<Props> = (props: Props) => {
	return (
		<RS.Label className='form-label' {...props}>
			{props.children}
		</RS.Label>
	)
}
