// External Dependencies
import * as Yup from 'yup'

// Internal Dependencies
import { FormInput, FormInputType, InputSelectionItem } from '../../Shared/Form'
import * as Constants from '../../../constants/index'

export interface DinnerReservationFormState {
	time: Date,
	member: InputSelectionItem,
	guests: InputSelectionItem,
	duration: InputSelectionItem
	notes: string
}

export const DinnerReservationFormInputs = (memberItems: any, guestItems: any, durationItems: any): FormInput[] => [
	{
		title: 'Date & Time',
		property: 'time',
		type: FormInputType.DATE_TIME,
		size: 12,
		placeholder: '',
		defaultValue: null,
		validation: Yup.date().nullable().default(null).required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Party Size',
		property: 'guests',
		type: FormInputType.SELECT,
		size: 12,
		placeholder: 'Select party size...',
		selectItems: guestItems,
		defaultValue: null,
		validation: Yup.string().nullable().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Duration',
		property: 'duration',
		type: FormInputType.SELECT,
		size: 12,
		placeholder: 'Select duration...',
		selectItems: durationItems,
		defaultValue: null,
		validation: Yup.string().nullable().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Member',
		property: 'member',
		type: FormInputType.TYPEAHEAD,
		size: 12,
		placeholder: 'Select member...',
		selectItems: memberItems,
		defaultValue: null,
		validation: Yup.string().nullable().required(Constants.REQUIRED_FIELD),
	}
]
