// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as RS from 'reactstrap'
import * as queryString from 'query-string'
import * as Feather from 'react-feather'
import Fuse from 'fuse.js'
import { connect } from 'react-redux'
import { flatten } from 'underscore'
import { isNullOrUndefined } from 'util'
import { RouteComponentProps } from 'react-router'
import to from 'await-to-js'
import { oc } from 'ts-optchain'

// Internal Dependencies

// State
import { RootReducerState } from '../../reducers/index'
import { membersSelector, usersByIDSelector } from '../../reducers/user'

// Actions
import { AlertActions, UserActions } from '../../actions/index'

// Components
import PageHeaderComponent, { PageHeaderSelectInput, PageHeaderInputType, PageHeaderSearchInput } from '../Shared/PageHeader'
import TableHeader from '../Shared/TableHeader'
import { HeaderButton, ButtonType } from '../Shared/ButtonGroup'
import ErrorComponent from '../Shared/ErrorComponent'
import TableComponent from '../Shared/Table'
import ModalComponent from '../Shared/Modal'
import { InputSelectionItem } from '../Shared/Form'

// Tables
import { VehicleTableColumns, VehicleTableRow } from './table'

// Helpers
import * as Constants from '../../constants'
import { setStateAsync } from '../../helpers/promise'
import { userForForm } from '../../helpers/user'
import { buildFuseSearch, FuseItem } from '../../helpers/fuse'
import SearchHelper from '../../helpers/search'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	vehicles: [] as core.SubModels.CarMeta.Vehicle[],
	selectedUserID: 'All' as string,
	vehicleToDelete: null as string | null,
	error: false,
	loading: true,
	pageIndex: 0,
	searchTerm: null as string
}

type Props = RouteComponentProps & ConnectedState & ConnectedActions
type State = typeof initialState

class VehicleComponent extends React.Component<Props, State> {
	private pageSize: number
	private searchHelper: SearchHelper

	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
		this.pageSize = 20
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		const vehicles = this.getVehiclesForState()
		await setStateAsync(this, { vehicles: vehicles, loading: false })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Dropdown Actions
	// ----------------------------------------------------------------------------------

	/**
	 * Determines which action to take, based on which dropdown item
	 * the user selected in the table's dropdown menu
	 */
	handleTableDropdownAction = (e: any) => {
		const [action, value] = e
		switch (action) {
			case 'editVehicle':
				return this.handleEditVehicle(value)
			case 'deleteVehicle':
				return this.handleDeleteVehicle(value)
			default:
				break
		}
	}

	/**
	 * When editing a Vehicle, navigate to the Vehicle update
	 * form and pass the Vehicle's ID via the query parameters
	 */
	handleEditVehicle = (vehicleID: string) => {
		const { history } = this.props
		const { vehicles } = this.state

		// Find the Vehicle with the ID
		const vehicleToEdit = vehicles.find((vehicle) => `${vehicle._id}` === vehicleID)
		if (!vehicleToEdit) {
			return
		}

		// Query Params
		const queryParams = {
			vehicleID: vehicleToEdit._id,
			userID: vehicleToEdit.userID,
		}

		const location = {
			pathname: Constants.UPDATE_VEHICLE_ROUTE,
			search: queryString.stringify(queryParams)
		}

		history.push(location)
	}

	/**
	 * When attempting to delete a Vehicle, display the
	 * Vehicle deletion confirmation modal
	 */
	handleDeleteVehicle = async (vehicleID: string) => {
		const { vehicles } = this.state

		// Find the Vehicle with the ID
		const vehicleToDelete = vehicles.find((vehicle) => `${vehicle._id}` === vehicleID)
		if (!vehicleToDelete) {
			return
		}

		return setStateAsync(this, { vehicleToDelete: `${vehicleToDelete._id}` })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Modal Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Make the API call to delete the Vehicle, when the
	 * user confirms the deletion via the modal
	 */
	executeVehicleDeletion = async () => {
		const { deleteUserMeta, fireFlashMessage } = this.props
		const { vehicleToDelete, vehicles } = this.state

		await setStateAsync(this, { loading: true })

		// Find the Vehicle with the ID
		const existingVehicleToDelete = vehicles.find((v) => `${v._id}` === vehicleToDelete)
		if (!existingVehicleToDelete) {
			return
		}

		const userID = `${existingVehicleToDelete.userID}`
		const metaField = 'meta.car.vehicles'
		const [deleteErr] = await to(deleteUserMeta(userID, metaField, vehicleToDelete, true) as any)
		if (deleteErr) {
			fireFlashMessage(`Problem trying to delete Vehicle. ${deleteErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true, vehicleToDelete: null })
			return
		}

		// Set the state with the new vehicles
		const newVehicleState = this.getVehiclesForState()
		await setStateAsync(this, { loading: false, vehicles: newVehicleState, vehicleToDelete: null })

		fireFlashMessage('Successfully deleted Vehicle', Constants.FlashType.SUCCESS)
	}

	/**
	 * Hide the deletion modal when the user closes it
	 */
	closeDeleteVehicleModal = async () => {
		return setStateAsync(this, { vehicleToDelete: null })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Handle table pagination
	 */
	handlePage = async (data: any) => {
		const { pageIndex } = this.state

		// Check if the new page is different that the current one
		if (data.selected !== pageIndex) {
			await setStateAsync(this, { pageIndex: data.selected })
		}
	}

	/**
	 * Handle a column header of the table being selected
	 */
	handleTableHeaderAction = (e: any) => {
		// TODO: Add this functionality
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Header Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Determine which action to take, based on the header
	 * button that the user selected
	 */
	handleHeaderButtonAction = async (e: any) => {
		const action = e.target.value
		switch (action) {
			case 'createVehicle':
				return this.handleCreateVehicle()
			default:
				break
		}
	}

	/**
	 * Navigate to the Service creation form
	 */
	handleCreateVehicle = () => {
		this.props.history.push(Constants.CREATE_VEHICLE_ROUTE)
	}

	handleUserChange = async (data: InputSelectionItem) => {
		await setStateAsync(this, { selectedUserID: data.value, pageIndex: 0 })
	}

	// ----------------------------------------------------------------------------------
	// Search Handlers
	// ----------------------------------------------------------------------------------

	handleSearchChange = async (term: string) => {
		await setStateAsync(this, { searchTerm: term, pageIndex: 0 })
	}

	handleSearchEnter = async (event: any) => {
		return
	}

	handleSearchClear = async () => {
		this.props.history.replace(this.props.history.location.pathname)
		await setStateAsync(this, { searchTerm: '', pageIndex: 0 })
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildDeleteConfirmationModal = () => {
		if (!this.state.vehicleToDelete) { return null }
		return (
			<ModalComponent
				modalTitle={'Delete Vehicle'}
				primaryMessage={'Are you sure you want to delete this Vehicle?'}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.closeDeleteVehicleModal}
				submitButtonName={'Confirm'}
				submitButtonHandler={this.executeVehicleDeletion}
			/>
		)
	}

	buildHeaderButtons = (): HeaderButton[] => {
		return [
			{
				action: 'createVehicle',
				type: ButtonType.DEFAULT,
				text: 'New Vehicle',
				class: 'btn-primary',
			},
		]
	}

	buildPageHeader = () => {
		const searchInputs: PageHeaderSearchInput[] = [{
			inputType: PageHeaderInputType.Search,
			changeHandler: this.handleSearchChange,
			enterHandler: this.handleSearchEnter,
			clearHandler: this.handleSearchClear,
			defaultValue: this.state.searchTerm
		}]
		const selectInputs: PageHeaderSelectInput[] = [
			this.buildUserSelect(),
		]

		return (
			<TableHeader
				fullScreen={true}
				pageTitle='Vehicles'
				inputs={[...searchInputs, ...selectInputs]}
				buttons={this.buildHeaderButtons()}
				buttonHandler={this.handleHeaderButtonAction}
			/>
		)
	}

	buildUserSelect = (): PageHeaderSelectInput => {
		const { members, usersByID } = this.props
		const { selectedUserID } = this.state

		// Define the options for Select Component
		const membersForSelect = members.map(userForForm)
		const defaultSelection = { label: 'All', value: 'All' }
		const allUsersForSelect = [defaultSelection, ...membersForSelect]

		// Determine what the value of the Select Component will be
		const selectedMember = usersByID[selectedUserID]
		const selectedValue = (selectedMember) ? userForForm(selectedMember) : defaultSelection

		return {
			inputType: PageHeaderInputType.Select,
			className: 'vehicleHeader-user-input',
			icon: Feather.User,
			selected: selectedValue,
			inputs: allUsersForSelect,
			changeHandler: this.handleUserChange
		}
	}

	/**
	 * Returns a list of the Users to show, with the search term
	 * acting as a filter
	 */
	getVehiclesToShow = (vehiclesToSearch: core.SubModels.CarMeta.Vehicle[]): { vehiclesToShow: core.SubModels.CarMeta.Vehicle[], total: number } => {
		const { searchTerm, pageIndex } = this.state
		const searchHelper = new SearchHelper(vehiclesToSearch, this.formatVehicleForSearch)
		const vehicles = searchHelper.search(searchTerm)
		const start = pageIndex * this.pageSize
		const end = start + this.pageSize
		const vehiclesToShow = vehicles.slice(start, end)
		return { vehiclesToShow, total: vehicles.length }
	}

	formatVehicleForSearch = (vehicle: VehicleTableRow) => {
		const searchString = `${oc(vehicle).year('')} ${oc(vehicle).make('')} ${oc(vehicle).model('')} ${oc(vehicle).owner.firstName('')} ${oc(vehicle).owner.lastName('')} }`
		return { _id: `${vehicle._id}`, searchString: searchString }
	}

	buildVehicleTable = () => {
		const { usersByID } = this.props
		const { vehicles, selectedUserID, pageIndex, loading, searchTerm } = this.state

		// If there is a selected User, find all the Events that they have reservations for
		const selectedVehicles = this.getVehiclesForMember(vehicles, selectedUserID)
		const vehiclesWithOwner = selectedVehicles.map((vehicle: core.SubModels.CarMeta.Vehicle) => {
			return { ...vehicle, owner: usersByID[`${vehicle.userID}`] }
		})
		const { vehiclesToShow, total } = this.getVehiclesToShow(vehiclesWithOwner)

		return (
			<TableComponent<VehicleTableRow>
				columns={VehicleTableColumns}
				rowItems={vehiclesToShow as VehicleTableRow[]}
				showPaging={true}
				currentPage={pageIndex}
				pageHandler={this.handlePage}
				totalResults={total}
				pageSize={this.pageSize}
				actionHandler={this.handleTableHeaderAction}
				dropdownHandler={this.handleTableDropdownAction}
				isLoading={loading}
			/>
		)
	}

	getVehiclesForMember = (vehicles: core.SubModels.CarMeta.Vehicle[], userID: string) => {
		const allVehicles = (userID === 'All')
		if (allVehicles) { return vehicles }
		return vehicles.filter((vehicle) => `${vehicle.userID}` === userID)
	}

	render() {
		const { loggedInClub } = this.props
		if (this.state.error) { return <ErrorComponent club={loggedInClub} isAdmin={this.props.userState.isAdmin} /> }

		const content = this.buildVehicleTable()

		return (
			<RS.Row className={'vehicle-container'}>
				<RS.Col className={'no-padding'}>
					{this.buildPageHeader()}

					<div className='vehicle-table-container'>
						{content}
					</div>

					{this.buildDeleteConfirmationModal()}
				</RS.Col>
			</RS.Row>
		)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	getVehiclesForState() {
		const memberUsers = oc(this).props.members([])
		if (!memberUsers) {
			return []
		}

		// Get the Vehicles
		const vehicles = flatten(memberUsers.map((member) => {
			if (member.meta && member.meta.car) {
				return member.meta.car.vehicles
			}
			return null
		}), true).filter((cars) => !isNullOrUndefined(cars))

		return vehicles
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	members: membersSelector(state),
	usersByID: usersByIDSelector(state),
	loggedInClub: state.club.loggedInClub,
	userState: state.user
})

const mapDispatchToProps = {
	...UserActions,
	...AlertActions
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleComponent)
