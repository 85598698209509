// External Dependencies
import * as React from 'react'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies

// Core
import * as core from 'club-hub-core'

// Actions
import { AlertActions, RestaurantActions } from '../../../actions/index'

// State
import { RootReducerState } from '../../../reducers'

// Components
import DimmedLoader from '../../Shared/DimmedLoader'

// Form
import GenericFormComponent from '../../Shared/Formik/GenericForm'
import { RestaurantFormInputs, RestaurantFormState } from './form'

// Helpers
import * as Constants from '../../../constants'
import { setStateAsync } from '../../../helpers/promise'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	error: false,
	loading: true,
	restaurantToUpdate: null as core.Restaurant.Model | null
}

type Props = RouteComponentProps & ConnectedState & ConnectedActions
type State = typeof initialState

class RestaurantForm extends React.Component<Props, State> {
	private isUpdateForm: boolean

	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
		this.isUpdateForm = this.props.location.pathname === Constants.RESTAURANT_UPDATE_ROUTE
	}

	async componentDidMount() {
		if (this.isUpdateForm) {
			await this.setRestaurantFromQueryParams()
		}

		await setStateAsync(this, { loading: false })
	}

	/**
	 * Gets the Restaurant that is being updated from state using its ID that we get
	 * from the query parameters in the URL.
	 *
	 * The Restaurant will have its information pre-filled into the form.
	 */
	setRestaurantFromQueryParams = async () => {
		// Parse the query string of the URL into an object
		const parsedQuery = queryString.parse(this.props.location.search)

		// Get the Restaurant by its ID
		const restaurants = oc(this).props.restaurantState.restaurants([])
		const restaurantID = parsedQuery.restaurantID
		const restaurantToEdit = restaurants.find((restaurant) => restaurant._id === restaurantID)

		// Update the state
		await setStateAsync(this, { restaurantToUpdate: restaurantToEdit })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Returns the user to the Restaurants view when they cancel the form.
	 */
	handleCancel = () => {
		this.props.history.push(Constants.RESTAURANT_ROUTE)
	}

	/**
	 * Determines which handler to call (Create or Update) when the user submits the form.
	 */
	handleSave = async (form: RestaurantFormState): Promise<void> => {
		switch (this.props.location.pathname) {
			case Constants.RESTAURANT_CREATE_ROUTE:
				return this.handleCreate(form)
			case Constants.RESTAURANT_UPDATE_ROUTE:
				return this.handleUpdate(form)
			default:
				break
		}
	}

	handleCreate = async (form: RestaurantFormState): Promise<void> => {
		await setStateAsync(this, { loading: true })

		const payload = this.buildRestaurantPayload(form)
		const [err]  = await to(this.props.createRestaurant(payload) as any)
		if (err) {
			this.props.fireFlashMessage(`Problem creating Restaurant. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}

		this.props.fireFlashMessage('Restaurant successfully created.', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, error: false })

		this.props.history.push(Constants.RESTAURANT_ROUTE)
	}

	handleUpdate = async (form: RestaurantFormState): Promise<void> => {
		await setStateAsync(this, { loading: true })

		const payload = this.buildRestaurantPayload(form)
		const [err]  = await to(this.props.updateRestaurant(`${this.state.restaurantToUpdate._id}`, payload) as any)
		if (err) {
			this.props.fireFlashMessage(`Problem updating Restaurant. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}

		this.props.fireFlashMessage('Restaurant successfully updated.', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, error: false })

		this.props.history.push(Constants.RESTAURANT_ROUTE)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	/**
	 * Builds the payload, that will be sent to the server, based on the form.
	 * Must be sent as multi part form data so that the server can process any images.
	 */
	buildRestaurantPayload = (form: RestaurantFormState): FormData => {
		// Build the form data payload
		const formPayload = new FormData()

		const restaurant: core.Restaurant.Model = {
			name: form.name,
			clubID: this.props.loggedInClub._id,
			description: form.description,
			location: {name: 'Clubhouse'}
		}

		// Check for existing image
		if (Array.isArray(form.image)) {
			// tslint:disable-next-line
			for (let i = 0; i < form.image.length; i++) {
				const formImage: File | core.SubModels.Image.Model = form.image[i]
				if ((formImage as core.SubModels.Image.Model)._id) {
					restaurant.image = formImage as core.SubModels.Image.Model
					break
				}
				formPayload.append('image', formImage)
				break
			}
		} else if (!isNullOrUndefined(form.image)) {
			// If form.image is not an array, then we know that
			// it is an existing image coming from the resource
			restaurant.image = form.image
		}

		formPayload.append('restaurant', JSON.stringify(restaurant))

		return formPayload
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildFormInputs = () => {
		// TODO add build form inputs logic (locations) after we decide how we want this displayed.
	}

	buildForm = () => {
		const restaurantToUpdate = (this.isUpdateForm && this.state.restaurantToUpdate) ? this.state.restaurantToUpdate : undefined
		const formTitle = (this.isUpdateForm) ? 'Update Restaurant' : 'New Restaurant'
		const submitButtonName = (this.isUpdateForm) ? 'Update' : 'Save'

		// If we are updating a restaurant, pass in a map with restaurant values so the form can be prefilled.
		let formResource: any
		if (this.isUpdateForm) {
			formResource = {
				...restaurantToUpdate,
				image: oc(restaurantToUpdate).image()
			}
		}

		return (
			<GenericFormComponent
				title={formTitle}
				inputs={RestaurantFormInputs()}
				enableReinitialize={false}
				formResource={formResource}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.handleCancel}
				submitButtonName={submitButtonName}
				submitButtonHandler={this.handleSave}
			/>
		)
	}

	render() {
		if (this.state.loading) { return <DimmedLoader component={null} isLoading={true} /> }
		return (
			<div className='restaurant-form-container row justify-content-center mx-auto'>
				<div className='col col-md-8 col-xl-6'>
					{this.buildForm()}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	restaurantState: state.restaurant,
	loggedInClub: state.club.loggedInClub,
})

const mapDispatchToProps = {
	...AlertActions,
	...RestaurantActions
}

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantForm)
