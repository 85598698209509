// External Dependencies
import * as Feather from 'react-feather'

// Internal Dependencies
import { TableColumn } from '../Shared/Table'
import { TableColumnType } from '../Shared/Table/Primitives'

export enum EventLocationTableActions {
	Edit = 'editEventLocation',
	Delete = 'deleteEventLocation'
}

export const EventLocationTableColumns: TableColumn[] = [
	{
		title: '',
		property: '',
		type: TableColumnType.CHECKBOX,
		action: '',
	},
	{
		title: 'Name',
		property: 'name',
		type: TableColumnType.TEXT,
		action: 'name',
	},
	{
		title: 'Address 1',
		property: 'address1',
		type: TableColumnType.TEXT,
		action: 'address1',
	},
	{
		title: 'Address 2',
		property: 'address2',
		type: TableColumnType.TEXT,
		action: 'address2',
	},
	{
		title: 'City',
		property: 'city',
		type: TableColumnType.TEXT,
		action: 'city',
	},
	{
		title: 'State',
		property: 'state',
		type: TableColumnType.TEXT,
		action: 'state',
	},
	{
		title: 'Zip Code',
		property: 'zip',
		type: TableColumnType.TEXT,
		action: 'zip',
	},
	{
		title: '',
		property: '',
		type: TableColumnType.DROPDOWN,
		class: 'table-dropdown-menu',
		dropdownItems: [
			{
				action: EventLocationTableActions.Edit,
				valueProperty: '_id',
				displayText: 'Edit Location',
				icon: Feather.Edit,
			},
			{
				action: EventLocationTableActions.Delete,
				valueProperty: '_id',
				displayText: 'Delete Location',
				icon: Feather.Trash,
			},
		]
	}
]
