// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import { oc } from 'ts-optchain'

// Internal Dependencies

// Components
import ProgressiveImage, { LoadedImage, SrcSetData } from '../ProgressiveImage'

// Helpers
import { ImageSize, imageForResource } from '../../../helpers/image'

interface ComponentProps {
	image: core.SubModels.Image.Model
	size: ImageSize
	club: core.Club.Model
	placeholderClass?: string
}

type Props = ComponentProps & React.HTMLAttributes<any>
type State = any

class AsyncImage extends React.Component<Props, State> {
	private imageURL: string
	private minimizedURL: string
	constructor(props: Props) {
		super(props)
		this.imageURL = imageForResource(props.image, props.club, props.size)
		this.minimizedURL = imageForResource(props.image, props.club, ImageSize.Micro)
	}

	buildImage = (placeholder: LoadedImage, fullImage: LoadedImage, initialLoading: boolean, loading: boolean, srcSetData: SrcSetData) => {
		if (!placeholder && !fullImage) {
			const emptyImageClass = `progressive-image-empty ${oc(this).props.placeholderClass('')}`
			return (
				<div className={emptyImageClass} />

			)
		}

		let placeholderElement = null
		if (placeholder) {
			const placeholderClass = (placeholder.isCached) ? 'tester progressive-image-cached blurred' : 'tester progressive-image blurred loaded'
			placeholderElement = (oc(placeholder).src()) ? (
				<div className={`${this.props.className} ${placeholderClass}`} style={{ backgroundImage: `url(${placeholder.src})` }} />
			) : null
		}

		let fullImageElement = null
		if (fullImage) {
			const fullImageClass = (fullImage.isCached) ? 'tester ' : 'tester  progressive-image loaded'
			fullImageElement = (oc(fullImage).src()) ? (
				<div className={`${this.props.className} ${fullImageClass}`} style={{ backgroundImage: `url(${fullImage.src})` }}>
					{this.props.children}
				</ div>
			) : null
		}

		return (
			<div className='progressive-image-container'>
				{placeholderElement}
				{fullImageElement}
			</div>
		)
	}

	render() {
		return (
			<ProgressiveImage src={this.imageURL} placeholder={this.minimizedURL}>
				{this.buildImage}
			</ProgressiveImage>
		)
	}
}

export default AsyncImage
