// External Dependencies
import * as React from 'react'
import * as Core from 'club-hub-core'
import { Link } from 'react-router-dom'
import { oc } from 'ts-optchain'
import * as Sentry from '@sentry/browser'

interface ErrorInterface {
	club?: Core.Club.Model
	isAdmin: boolean
	errorCode?: string
	errorMessage?: string
	detailsMessage?: string
	buttonText?: string
	buttonPath?: string
	buttonOnClick?: () => void
}

/**
 * Error component to be displayed in case of API failures.
 * @param props ErrorInterface
 */
const ErrorComponent: React.SFC<ErrorInterface> = (props: ErrorInterface = { isAdmin: false }) => {
	const errorCode = (props.errorCode) ?
		(
			<div className='display-1 text-muted mb-5'>
				{props.errorCode}
			</div>
		) :
		null

	const getErrorMessage = () => {
		if (props.isAdmin) {
			return (
				<div className='error-component-dialog'>
					<p className='message-header'>
						It looks like you hit an error, please refresh and try again.
						</p>
					<p className='message'>
						We've been alerted that you ran into some trouble, but would appreciate it if you could provide some more details
							<a
								href='mailto:info@warehousepc.com'
								onClick={(e) => {
									const eventID = Sentry.lastEventId()
									if (eventID) {
										e.preventDefault()
										Sentry.showReportDialog({eventID})
									}
								}}
								className='text-link'
							>
							here
							</a>
						to help us fix the problem as soon as possible.
						</p>

				</div>
			)
		} else {
			if (props.club) {
				const locations = oc(props).club.locations([])
				const primaryLocation = locations.find((location: Core.SubModels.Location.Model) => location.primary)
				return (
					<p className='message'>
						{`Please refresh and try again. If the issue persists, please contact the club staff at ${oc(primaryLocation).phone('')} .`}
					</p>
				)
			}
			return (props.detailsMessage) ?
			(<p className='message'>{props.detailsMessage}</p>) :
			(<p className='message'>{`Please refresh and try again. If the issue persists, please contact the club staff.`}</p>)
		}
	}

	const message = (props.errorMessage) ? props.errorMessage : 'Our apologies, something went wrong.'
	const button = (props.buttonText && props.buttonPath) ? (
		<Link to={props.buttonPath}>
			<button onClick={props.buttonOnClick} className='btn btn-primary error-btn'>{props.buttonText}</button>
		</Link>
	) : null

	return (
		<div className='error-component'>
			<div className='page-content'>
				<div className='text-center'>
					{errorCode}
					<h1 className='h2 mb-3'>{message}</h1>
					{getErrorMessage()}
					{button}
				</div>
			</div>
		</div>
	)
}

export default ErrorComponent
