// External Dependencies
import * as core from 'club-hub-core'
import { createSelector } from 'reselect'
import { indexBy } from 'underscore'

// Internal Dependencies
import { ActionTypes, TypeKeys } from '../actions/message'
import { RootReducerState } from './index'
import { mergeUpdatedModelObject } from '../helpers/reducer'

export type MessageState = typeof defaultState

const defaultState = {
	currentMessage: null as core.Message.Model | null,
	messages: [] as core.Message.Model[],
	messageCount: 0 as number,
}

function message(state = defaultState, action: ActionTypes) {
	switch (action.type) {
		case TypeKeys.FETCHED_MESSAGES:
			return {
				...state,
				messages: action.messages,
				messageCount: action.count
			}
		case TypeKeys.CREATED_MESSAGE:
			return {
				...state,
				currentMessage: action.message,
				messages: mergeUpdatedModelObject(action.message, state.messages),
				messageCount: state.messageCount + 1
			}
		case TypeKeys.UPDATED_MESSAGE:
			return {
				...state,
				currentMessage: action.message,
				messages: mergeUpdatedModelObject(action.message, state.messages),
			}
		case TypeKeys.SENT_MESSAGE:
			return {
				...state,
				currentMessage: action.message,
				messages: mergeUpdatedModelObject(action.message, state.messages),
			}
		case TypeKeys.PREVIEW_MESSAGE:
			return {
				...state,
				currentMessage: {
					...state.currentMessage,
					richContent: {
						...state.currentMessage.richContent,
						publishedHtml: action.preview
					}
				},
			}
		case TypeKeys.SET_CURRENT_MESSAGE:
			return {
				...state,
				currentMessage: action.message,
				messages: mergeUpdatedModelObject(action.message, state.messages),
			}
		case TypeKeys.CLEAR_CURRENT_MESSAGE:
			return {
				...state,
				currentMessage: null,
			}
		case TypeKeys.CLEAR_MESSAGES:
			return {
				...state,
				messages: [],
			}
		default:
			return state
	}
}

// Selectors
const messages = (state: RootReducerState) => state.message.messages

/**
 * Map of Messages, keyed by their _id.
 * @returns { [id: string]: core.Message.Model }
 */
export const messagesByIDSelector = createSelector([messages], (messageList) => indexBy(messageList, '_id'))

export default message
