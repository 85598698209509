// External Dependencies
import * as React from 'react'
import { Icon } from 'react-feather'

export interface DetailRowItem {
	icon?: Icon
	label?: string
	value?: string | JSX.Element
	link?: string
}

interface DetailRowProps {
	rowItem: DetailRowItem
	className?: string
}

/**
 * Returns a detail row used in Reservation Details / part of card body content.
 * @param props PassedPropsInterface
 */
const DetailRowComponent: React.FunctionComponent<DetailRowProps> = (props: DetailRowProps) => {
	const FeatherIcon = props.rowItem.icon
	return (
		<div className={`${props.className || ''} reservation-details-row d-flex justify-content-between align-items-center`}>
			<div className='d-flex align-items-center'>
				<FeatherIcon size={20} />
				<div className='details-row-label'>{props.rowItem.label}</div>
			</div>
			<div>
				<div>{props.rowItem.value}</div>
			</div>
		</div>
	)
}

export default DetailRowComponent
