// External Dependencies
import * as React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { RouteComponentProps } from 'react-router'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies

// Core
import * as core from 'club-hub-core'

// Actions
import { AlertActions, CustomerActions, EventActions } from '../../../actions/index'

// State
import { RootReducerState } from '../../../reducers'

// Components
import DimmedLoader from '../../Shared/DimmedLoader'
import TimeSelectRow from './TimeSelectRow'
import { ConfirmReservationComponent } from '../OldReservation'

// Form
import GenericFormComponent from '../../Shared/Formik/GenericForm'
import { diningSelectForm, DiningSelectFormState } from './form'
import { InputSelectionItem } from '../../Shared/Form'

// Helpers
import * as Constants from '../../../constants'
import { setStateAsync } from '../../../helpers/promise'

interface ComponentProps {
	onCancel: () => void
}

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	showTimeSelectionModal: false,
	form: {} as any as DiningSelectFormState,
	error: false,
	loading: false
}

type Props = ComponentProps & RouteComponentProps & ConnectedState & ConnectedActions
type State = typeof initialState

class CustomerDiningForm extends React.Component<Props, State> {
	private club: core.Club.Model
	private diningCalGroup: core.Calendar.Group
	private diningCalendars: core.Calendar.Model[]

	constructor(props: Props) {
		super(props)

		const { loggedInClub, calendars } = props

		this.club = loggedInClub
		const calendarGroups = oc(this.club).calendarGroups([])
		this.diningCalGroup = calendarGroups.find((calGroup: core.Calendar.Group) => calGroup.type === core.Calendar.GroupType.Dining)
		this.diningCalendars = calendars.filter((cal: core.Calendar.Model) => cal.groupID === this.diningCalGroup._id)

		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Form Handlers
	// ----------------------------------------------------------------------------------

	handleGetBookableEvents = async (form: DiningSelectFormState) => {
		await setStateAsync(this, { loading: true })
		await this.fetchBookableEvents(form)
		await setStateAsync(this, { loading: false, showTimeSelectionModal: true, form: form })
	}

	/**
	 * Navigates the user to the customer reservation confirmation component.
	 */
	handleSubmitTime = async (bookingTime: Date) => {
		const route = Constants.CONFIRM_RESERVATION.replace(':type', 'dining').replace(':action', 'new')
		const routeState: Partial<ConfirmReservationComponent['state']> = {
			bookingDate: bookingTime,
			calendarGroup: this.diningCalGroup,
			calendarID: this.state.form.calendarID.value,
			guestCount: Number(this.state.form.guests.value),
			maxGuests: Number(this.state.form.guests.value),
		}
		this.props.history.push(route, routeState)
	}

	handleFormChange = async (field: string, input: InputSelectionItem) => {
		if (field === 'calendarID') {
			const updatedForm = { ...this.state.form }
			updatedForm[field] = input
			await setStateAsync(this, { form: updatedForm })
		}
	}

	// ----------------------------------------------------------------------------------
	// Network Requests
	// ----------------------------------------------------------------------------------

	/**
	 * Fetches the bookable events for the selected section (calendar) and start time.
	 * @returns void - sets bookable events on event state.
	 */
	fetchBookableEvents = async (form: DiningSelectFormState) => {
		// Build query options.
		const startTime = form.start.toISOString()
		const calendarID = form.calendarID.value

		// Fetch bookable events for the calendar.
		const [fetchErr] = await to(this.props.fetchBookableEvents(startTime, [calendarID], false) as any)
		if (fetchErr) {
			this.props.fireFlashMessage(`Failed to fetch Services. ${fetchErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false })
			return
		}
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	handleToggle = async () => {
		await setStateAsync(this, { showTimeSelectionModal: false })
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	/**
	 * Renders either the dining selection form or the time selection modal.
	 * This is determined by the component state value 'showTimeSelectionModal'.
	 */
	buildContent = () => {
		if (this.state.showTimeSelectionModal) {
			return this.buildTimeSelectForm()
		}
		return this.buildDiningSelectForm()
	}

	/**
	 * Renders the first form where the user can select time, section and number of guests.
	 * @returns Generic Form Component.
	 */
	buildDiningSelectForm = () => {
		// Build calendar inputs.
		const calendarsInputs = this.diningCalendars.map((calendar: core.Calendar.Model) => {
			return { label: calendar.name, value: `${calendar._id}` }
		})

		// Return form.
		return (
			<GenericFormComponent
				title={'New Reservation'}
				inputs={diningSelectForm(calendarsInputs, this.buildGuestInputs())}
				enableReinitialize={false}
				submitButtonName={'Search'}
				submitButtonHandler={this.handleGetBookableEvents}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.props.onCancel}
				onChangeHandler={this.handleFormChange}
			/>
		)
	}

	/**
	 * Returns an array of input select items used to build the guest input dropdown.
	 * The length of the array is determined by the selected calendar's max guests value in calendar settings.
	 * This will default to 10.
	 */
	buildGuestInputs = (): InputSelectionItem[] => {
		// Determine the number of Guests that a Member can include in a Reservation
		const calendar = this.diningCalendars.find((c) => {
			return !isNullOrUndefined(c._id) && `${c._id}` === `${oc(this).state.form.calendarID.value()}`
		})
		const inputLength = oc(calendar).reservationSettings[0].maxGuestsMember(10)

		const inputOptions: InputSelectionItem[] = []
		for (let i = 1; i <= inputLength; i++) {
			inputOptions.push({ label: `${i}`, value: `${i}` })
		}
		return inputOptions
	}

	/**
	 * Returns a list of available booking times.
	 * On select, the user will be taken to the reservation confirmation component.
	 */
	buildTimeSelectForm = () => {
		const bookableEvents: core.Event.EventDataForCalendar[] = oc(this).props.bookableEvents([])
		// We only fetched bookable events for one calendar so we can use the first index.
		// Filter out any events whose start time is before the one selected in the dining form.
		const filteredEvents = bookableEvents[0].eventInfo.filter((event: core.Event.AvailableEvent) => {
			return new Date(event.time).toISOString() >= new Date(this.state.form.start).toISOString()
		})

		// If there are no events slots available, display flash and return.
		if (filteredEvents.length === 0) {
			this.props.fireFlashMessage(`We're sorry. There are no openings available at the selected time.`, Constants.FlashType.DANGER)
			setStateAsync(this, { showTimeSelectionModal: false })
			return null
		}

		const guestCount = this.state.form.guests.value
		const startTime = this.state.form.start.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' })
		const formTitle = `${guestCount} Guests @ ${startTime}`

		// Return form.
		return (
			<GenericFormComponent
				title={formTitle}
				inputs={[]}
				render={() => this.buildModalBody(filteredEvents)}
				enableReinitialize={false}
				cancelButtonHandler={this.props.onCancel}
				cancelButtonName={'Cancel'}
			/>
		)
	}

	/**
	 * Builds the available booking time rows that will be rendered in the modal.
	 */
	buildModalBody = (availableEvents: core.Event.AvailableEvent[]) => {
		return availableEvents.map((availableEvent: core.Event.AvailableEvent, index: number) => {
			if (index > 4) { return null }

			let slotAvailable = true
			// If there are not enough slots, slot is unavailable.
			if (!(availableEvent.openSpots >= Number(this.state.form.guests.value))) {
				slotAvailable = false
			}
			// If the event is blocked, slot is unavailable.
			if (availableEvent.status === core.Event.AvailableEventStatus.Blocked) {
				slotAvailable = false
			}

			return (
				<TimeSelectRow
					key={`timeRow_${availableEvent.time}`}
					timeString={availableEvent.time}
					available={slotAvailable}
					submitHandler={this.handleSubmitTime}
				/>
			)
		})
	}

	render() {
		if (this.state.loading) { return <DimmedLoader component={null} isLoading={true} /> }
		return (
			<div className='customer-dining-container col-sm-4 mx-auto'>
				{this.buildContent()}
			</div>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	bookableEvents: oc(state).event.bookableEvents([]),
	calendars: oc(state).calendar.calendars([]),
	loggedInClub: state.club.loggedInClub,
})

const mapDispatchToProps = {
	...AlertActions,
	...CustomerActions,
	...EventActions
}

const enhance = compose<React.ComponentType<ComponentProps>>(
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(CustomerDiningForm)
