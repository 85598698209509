// External Dependencies
import * as core from 'club-hub-core'
import ReactGA, { InitializeOptions } from 'react-ga'
// Internal Dependencies
import { fullName } from './user'

// Modify the global window for analytics
declare global {
	interface Window { analytics: SegmentAnalytics.AnalyticsJS }
}

const SEGMENT_WRITE_KEY = 'aVftfRWtx9BFgHDv5b50aLLpJfxYWZkN'
const GOOGLE_ANALYTICS_KEY = 'UA-138128149-1'

/**
 * Load initializes the tracking class
 */
export const Load = () => {
	if (process.env.ENV === 'production') {
		ReactGA.initialize(GOOGLE_ANALYTICS_KEY)
		window.analytics.load(SEGMENT_WRITE_KEY)
	} else {
		// Init GA Tracking in debug
		const gaTrackingOpts: InitializeOptions = {
			testMode: true
		}
		ReactGA.initialize(GOOGLE_ANALYTICS_KEY, gaTrackingOpts)
	}
}

/**
 * Identify is for tracking a session for a user
 * @param user The user
 * @param club The club
 */
export const Identify = (user: core.User.Model, club: core.Club.Model) => {
	const userName = fullName(user)
	const userId = `${user._id}`

	// Reinit GA Tracking
	const gaTrackingOpts: InitializeOptions = {
		gaOptions: {
			name: userName,
			userId
		},
		testMode: true
	}

	if (process.env.ENV === 'production') {
		gaTrackingOpts.testMode = false
		const traits = {
			name: userName,
			club: club.name,
			email: user.email
		}
		window.analytics.identify(userId, traits)
	}

	// Always init GA
	ReactGA.initialize(GOOGLE_ANALYTICS_KEY, gaTrackingOpts)
}

/**
 * Page is used to track a page view
 * @param name Name of the page
 */
export const Page = (name: string) => {
	if (process.env.ENV === 'production') {
		window.analytics.page(name)
		ReactGA.pageview(name)
	}
}

/**
 * Track is a generic call to track an event. We should strive
 * to make higher level tracking calls that are specific to an action
 * and export those. See #NavLinkPressed or #FormSubmission for examples
 * @param category Category of the event
 * @param category Name of the event
 * @param properties (optional) Properties to track
 */
const Track = (category: string, action: string, properties?: any) => {
	if (process.env.ENV === 'production') {
		const analyticsJSTitle = `${category} - ${action}`
		window.analytics.track(analyticsJSTitle, properties)
		ReactGA.event({
			category,
			action
		})
	}
}

/**
 * NavLink Tracking
 * @param linkName Name of the link
 */
export const NavLinkPressed = (linkName: string) => {
	Track('NavPressed', linkName)
}

/**
 * Form Submission Tracking
 * @param formName Name of the form
 * @param payload The submitted response
 */
export const FormSubmission = (formName: string, payload: object) => {
	Track('FormSubmission', formName, payload)
}
