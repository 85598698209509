import Axios from 'axios'

const baseURL = 'https://api.openweathermap.org/data/2.5'
const axios = Axios.create({ url: baseURL })

export interface WeatherResponse {
	main: Main
	weather: Weather[]
}

export interface Main {
	temp: number
}

export interface Weather {
	id: number
	main: string
	description: string
	icon: string
}

/**
 * Fetches the weather for the provided lat and lon.
 */
export const getWeather = async (lat: string, lon: string): Promise<WeatherResponse> => {
	const query = { lat: lat, lon: lon, APPID: '59d982483558e622578070269654b2ee', units: 'imperial' }
	const res = await axios.get(`${baseURL}/weather`, { params: query })
	return res.data as Promise<WeatherResponse>
}
