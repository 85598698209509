// External Dependencies
import * as React from 'react'
import * as Feather from 'react-feather'
import classNames from 'classnames'
import { isNullOrUndefined } from 'util'

interface ComponentProps {
	text: string
	onClickClose?: () => any
}

type Props = ComponentProps

export const FilterTag: React.FC<Props> = (props: Props) => {
	const hasCloseButton = !isNullOrUndefined(props.onClickClose)
	const closeButton = (hasCloseButton) ?
		(
			<Feather.XCircle
				className='query-filter-tag-x'
				size={16}
				onClick={props.onClickClose}
			/>
		) :
		null
	return (
		<div className={classNames('query-filter-tag', { 'has-close-button': hasCloseButton })}>
			<div className='query-filter-tag-text'>
				{props.text}
			</div>
			{closeButton}
		</div>
	)
}
