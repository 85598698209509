// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import * as core from 'club-hub-core'
import classNames from 'classnames'
// @ts-ignore
import withSizes from 'react-sizes'
import { oc } from 'ts-optchain'

// Internal Dependencies

// Components
import AsyncBackgroundImage from '../../Shared/AsyncBackgroundImage'
import AsyncImage from '../../Shared/AsyncImage'

// Helpers
import { ImageSize } from '../../../helpers/image'
import { formatPrice } from '../../../helpers/formatting'

interface ComponentProps {
	club: core.Club.Model
	event: core.Event.Model
	calendar: core.Calendar.Model
	showRSVPBadge: boolean
	onClick: () => any
}

interface WithSizesProps {
	isMobile: boolean
}

type Props = ComponentProps & WithSizesProps

class RichEventCard extends React.Component<Props> {
	constructor(props: Props) {
		super(props)
	}

	buildCardImage = ({ club, event, isMobile }: Props) => {
		const imageProps = {
			className: (isMobile) ? 'card-img-top' : 'rich-event-aside card-aside-column',
			image: oc(event).images[0]({}),
			club: club,
			size: (isMobile) ? ImageSize.Medium : ImageSize.Large
		}

		return (isMobile) ?
			(
				<div className='rich-event-card-mobile-image'>
					<AsyncImage {...imageProps} />
				</div>
			) :
			(
				<AsyncBackgroundImage {...imageProps} />
			)
	}

	buildCardBody = ({ event, calendar }: Props) => {
		const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
		const date = new Date(event.start).toLocaleString('us-EN', options)
		const formattedPrice = formatPrice(event.price)

		return (
			<div className='card-body d-flex flex-column'>
				<div className='text-muted event-time'>{date}</div>
				<div className='event-name'>{oc(event).name('')}</div>
				<div className='event-description'>{oc(event).richContent.text('')}</div>
				<div className='d-flex align-items-center justify-content-between'>
					<div className='badge-container'>
						{this.buildCalendarBadge(calendar)}
						{this.buildRSVPBadge()}
						{this.buildPublicBadge(event)}
					</div>
					<div className='event-price'>{formattedPrice}</div>
				</div>
			</div>
		)
	}

	buildCalendarBadge = (calendar: core.Calendar.Model) => {
		const color = oc(calendar).color()
		const title = oc(calendar).name('')
		return this.buildBadge(color, title)
	}

	buildRSVPBadge = () => {
		if (!this.props.showRSVPBadge) { return null }
		const color = core.Constants.Colors.GREEN
		const title = 'RSVP'
		return this.buildBadge(color, title)
	}

	buildPublicBadge = (event: core.Event.Model) => {
		if (!event.public) { return null }
		const color = oc(this).props.club.clubSettings.primaryColor('')
		const title = 'PUBLIC'
		return this.buildBadge(color, title)
	}

	buildBadge = (color: string, title: string) => {
		return (
			<RS.Badge className='event-badge' style={{ backgroundColor: color }}>
				{title.toUpperCase()}
			</RS.Badge>
		)
	}

	buildCard = ({ isMobile, onClick }: Props) => {
		const cardClass = classNames({
			'card': true,
			'rich-event-card': true,
			'card-aside': !isMobile,
			'rich-content-item': !isMobile
		})

		return (
			<div className={cardClass} onClick={onClick}>
				{/** Image */}
				{this.buildCardImage(this.props)}

				{/** Content */}
				{this.buildCardBody(this.props)}
			</div>
		)
	}

	render() {
		return this.buildCard(this.props)
	}
}

const mapSizesToProps = (sizes: any) => ({
	isMobile: withSizes.isMobile(sizes)
})

export default withSizes(mapSizesToProps)(RichEventCard) as React.ComponentClass<ComponentProps>
