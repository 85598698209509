import * as core from 'club-hub-core'
import { oc } from 'ts-optchain'

/**
 * Returns all Events for the Tee Sheet...filtering out all Lottery reservations whose status is not approved
 */
export const getAllTeeSheetEvents = (eventData: core.Event.EventDataForCalendar) => {
	return oc(eventData).eventInfo([])
}

/**
 * Returns all events with Pending lottery reservations.
 */
export const getPendingLotteryEvents = (eventData: core.Event.EventDataForCalendar) => {
	const bookableEvents: core.Event.AvailableEvent[] = oc(eventData).eventInfo([])
	const eventsWithReservations = bookableEvents.filter((event: core.Event.AvailableEvent) => event.existingEvent)
	return eventsWithReservations.filter((event: core.Event.AvailableEvent) => {
		const reservations = oc(event).existingEvent.reservations([])
		return reservations.find((res: core.Event.Reservation) => {
			const lottery = res.lottery as core.Lottery.Model
			return oc(lottery).status() === core.Lottery.Status.Pending
		})
	})
}
