// External Dependencies
import React from 'react'
import * as core from 'club-hub-core'
import { isNullOrUndefined } from 'util'
import to from 'await-to-js'

// Internal Dependencies
import { GroupSettingsColumns } from './table'

// Components
import TableComponent from '../../Shared/Table'
import Card from '../../Shared/Cards/Card'
import { NotifSettingKey } from '../GlobalNotifSettingsTable'
import { NotifSettingToggle, OnToggleParams } from '../NotifSettingToggle'

// Helpers
import { setStateAsync } from '../../../helpers/promise'

interface ComponentProps {
	heading: string
	settings: core.Response.UserGroupInfo[]
	onToggleNotif: (params: OnToggleParams) => Promise<core.Response.NotificationPreferenceResponse>
	globalSettings?: core.NotificationPreference.Model
}

const initialState = {
	pendingAction: false
}

type Props = ComponentProps
type State = typeof initialState

export class GroupSettingsTable extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
	}

	dataForTable = () => {
		const { settings, globalSettings } = this.props

		return settings.map((setting: core.Response.UserGroupInfo) => {
			return {
				_id: setting.group._id,
				name: setting.group.name,
				allNotifs: this.notificationToggle(setting, 'disableAll'),
				pushNotifs: this.notificationToggle(setting, 'disablePush'),
				emailNotifs: this.notificationToggle(setting, 'disableEmail'),
				smsNotifs: this.notificationToggle(setting, 'disableText'),
			}
		})
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	notificationToggle = (setting: core.Response.UserGroupInfo, settingKey: NotifSettingKey) => {
		const { globalSettings, onToggleNotif } = this.props
		const { pendingAction } = this.state
		const { group, notificationPreference } = setting

		// Determine Global Setting
		const hasGlobalSettings = !isNullOrUndefined(globalSettings)
		const settingIsGloballyEnabled = (hasGlobalSettings) ? !globalSettings[settingKey] : true
		const settingIsGloballyDisabled = (hasGlobalSettings) ? (globalSettings.disableAll || !settingIsGloballyEnabled) : false

		// Determine Group Setting
		const hasNotifPref = !isNullOrUndefined(notificationPreference)
		const settingIsGroupEnabled = (hasNotifPref) ? !notificationPreference[settingKey] : true
		const settingIsGroupDisabled = (hasNotifPref) ? settingKey !== 'disableAll' && notificationPreference.disableAll : false

		const notifPrefEnabled = settingIsGloballyEnabled && settingIsGroupEnabled
		const notifPrefID = (hasNotifPref) ? `${notificationPreference._id}` : undefined
		const notifPrefDisabled = settingIsGloballyDisabled || settingIsGroupDisabled

		return (
			<NotifSettingToggle
				id={`${group._id}__${settingKey}`}
				className={'groupSettingsTable__toggle'}
				groupID={`${group._id}`}
				notifPrefID={notifPrefID}
				settingKey={settingKey}
				isEnabled={notifPrefEnabled}
				isDisabled={notifPrefDisabled || pendingAction}
				onToggleNotif={async (params) => {
					await setStateAsync(this, { pendingAction: true })
					const [err, res] = await to(onToggleNotif(params))
					await setStateAsync(this, { pendingAction: false })
					return res
				}}
			/>
		)
	}

	render() {
		const tableColumns = GroupSettingsColumns
		const rowItems = this.dataForTable()

		const content = (
			<TableComponent
				columns={tableColumns}
				rowItems={rowItems}
				showPaging={false}
				striped={false}
			/>
		)
		return (
			<Card
				title={'Group Notification Settings'}
				bodyClass={'groupSettingsTable'}
				content={content}
			/>
		)
	}
}
