// External Dependencies
import * as React from 'react'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies

// Core
import * as core from 'club-hub-core'

// Actions
import { AlertActions, RestaurantActions } from '../../../actions/index'

// State
import { RootReducerState } from '../../../reducers'

// Components
import DimmedLoader from '../../Shared/DimmedLoader'

// Form
import GenericFormComponent from '../../Shared/Formik/GenericForm'
import { MenuFormInputs, MenuFormState } from './form'

// Helpers
import * as Constants from '../../../constants'
import { setStateAsync } from '../../../helpers/promise'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	error: false,
	loading: true,
	restaurant: null as core.Restaurant.Model | null,
	menu: null as core.Restaurant.Menu | null
}

type Props = RouteComponentProps & ConnectedState & ConnectedActions
type State = typeof initialState

class MenuForm extends React.Component<Props, State> {
	private isUpdateForm: boolean

	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
		this.isUpdateForm = this.props.location.pathname === Constants.MENU_UPDATE_ROUTE
	}

	async componentDidMount() {
		await this.setMenuFromQueryParams()
		await setStateAsync(this, { loading: false })
	}

	/**
	 * Gets the Menu that is being updated from state using its ID that we get
	 * from the query parameters in the URL.
	 *
	 * The Menu will have its information pre-filled into the form.
	 */
	setMenuFromQueryParams = async () => {
		// Parse the query string of the URL into an object
		const parsedQuery = queryString.parse(this.props.location.search)

		// Get the Menu by its ID
		const restaurants = oc(this).props.restaurantState.restaurants([])
		const restaurantID = (this.props.match.params as any).restaurant_id
		const menuID = parsedQuery.menuID

		// Set the resources in state.
		const restaurant = restaurants.find((r) => `${r._id}` === restaurantID)
		const menu = restaurant.menus.find((m) => `${m._id}` === menuID)

		// Update the state
		await setStateAsync(this, { restaurant: restaurant, menu: menu })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Returns the user to the Menu view when they cancel the form.
	 */
	handleCancel = () => {
		// TODO set this in state.
		const route = this.props.location.pathname.split('/')
		route.pop()
		this.props.history.push(route.join('/'))
	}

	/**
	 * Determines which handler to call (Create or Update) when the user submits the form.
	 */
	handleSave = async (form: MenuFormState): Promise<void> => {
		await ((this.state.menu) ? this.handleUpdate(form) : this.handleCreate(form))

		const restaurantID = `${this.state.restaurant._id}`
		const route = Constants.MENU_ROUTE.replace(':restaurant_id', restaurantID)
		this.props.history.push(route)

	}

	handleCreate = async (form: MenuFormState): Promise<void> => {
		await setStateAsync(this, {loading: true})

		const payload = this.buildMenuPayload(form)
		const restID = `${this.state.restaurant._id}`
		const [err, created] = await to(this.props.createMenu(restID, payload) as any)
		if (err) {
			this.props.fireFlashMessage('Failed to Create Menu.', Constants.FlashType.DANGER)
			await setStateAsync(this, {loading: false})
			return
		}
		this.props.fireFlashMessage('Menu Created!', Constants.FlashType.SUCCESS)
		await setStateAsync(this, {loading: false})
	}

	handleUpdate = async (form: MenuFormState): Promise<void> => {
		await setStateAsync(this, {loading: true})

		const payload = this.buildMenuPayload(form)
		payload._id = this.state.menu._id
		const restID = `${this.state.restaurant._id}`
		const [err, updated] = await to(this.props.updateMenu(restID, payload) as any)
		if (err) {
			this.props.fireFlashMessage('Failed to Update Menu.', Constants.FlashType.DANGER)
			await setStateAsync(this, {loading: false})
			return
		}
		this.props.fireFlashMessage('Menu Updated!', Constants.FlashType.SUCCESS)
		await setStateAsync(this, {loading: false})
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	/**
	 * Builds the payload, that will be sent to the server, based on the form.
	 * Must be sent as multi part form data so that the server can process any images.
	 */
	buildMenuPayload = (form: MenuFormState): core.Restaurant.Menu => {
		const menu: any = {
			name: form.name,
			description: form.description,
			hours: [{
				day: form.day,
				open: form.open,
				close: form.close
			}]
		}
		return menu
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildFormInputs = () => {
		// TODO add build form inputs logic (locations) after we decide how we want this displayed.
	}

	buildForm = () => {
		const formResource = oc(this).state.menu()
		const formTitle = (this.isUpdateForm) ? 'Update Menu' : 'New Menu'
		const submitButtonName = (this.isUpdateForm) ? 'Update' : 'Save'

		return (
			<GenericFormComponent
				title={formTitle}
				inputs={MenuFormInputs()}
				enableReinitialize={false}
				formResource={formResource}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.handleCancel}
				submitButtonName={submitButtonName}
				submitButtonHandler={this.handleSave}
			/>
		)
	}

	render() {
		if (this.state.loading) { return <DimmedLoader component={null} isLoading={true} /> }
		return (
			<div className='menu-form-container row justify-content-center mx-auto'>
				<div className='col col-md-8 col-xl-6'>
					{this.buildForm()}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	restaurantState: state.restaurant
})

const mapDispatchToProps = {
	...AlertActions,
	...RestaurantActions
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuForm)
