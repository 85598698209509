// External Dependencies
import { Dispatch, Action } from 'redux'

// Internal Dependencies
import * as Constants from '../constants'

// Actions
export enum TypeKeys {
	FIRE_FLASH_MESSAGE = 'FIRE_FLASH_MESSAGE',
	CLEAR_FLASH_MESSAGE = 'CLEAR_FLASH_MESSAGE'
}

export type ActionTypes =
	| FireFlashMessageAction
	| ClearFlashMessageAction

export interface FireFlashMessageAction extends Action {
	type: TypeKeys.FIRE_FLASH_MESSAGE,
	message: string,
	alertType: string,
	receivedAt: number
}

export interface ClearFlashMessageAction extends Action {
	type: TypeKeys.CLEAR_FLASH_MESSAGE
}

// -----------------------------------------------------------------------------
// Fire Flash Message
// -----------------------------------------------------------------------------

/**
 * Fires a flash message. Clears flash message state after 3 seconds.
 */
const fireFlashMessage = (message: string, flashType?: Constants.FlashType) => (dispatch: Dispatch<any>) => {
	dispatch(firedFlashMessage(message, flashType))
	setTimeout(() => {
		dispatch(clearFlashMessage())
	}, 3000)
}

/**
 * Builds a `FireFlashMessageAction'.
 * @param message The message to display.
 * @param flashColor The color of the flash message: 'success' | 'warning' | 'danger'
 */
const firedFlashMessage = (message: string, flashType?: Constants.FlashType): FireFlashMessageAction => {
	if (message.includes(Constants.UNAUTHORIZED_ACCESS_NOTIFICATION)) {
		flashType = Constants.FlashType.WARNING
		message = 'Your session expired. Please login again.'
	}
	const action: FireFlashMessageAction = {
		type: TypeKeys.FIRE_FLASH_MESSAGE,
		message: message,
		alertType: flashType,
		receivedAt: Date.now()
	}
	return action
}

/**
 *  Clears the Flash Message from the Redux Store
 */
const clearFlashMessage = (): any => {
	const action: ClearFlashMessageAction = {
		type: TypeKeys.CLEAR_FLASH_MESSAGE,
	}
	return action
}

export const AlertActions = {
	fireFlashMessage,
	clearFlashMessage
}
