// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import classNames from 'classnames'
// @ts-ignore
import withSizes from 'react-sizes'

// Internal Dependencies

// Components
import AsyncBackgroundImage from '../../Shared/AsyncBackgroundImage'
import AsyncImage from '../../Shared/AsyncImage'

// Helpers
import { ImageSize } from '../../../helpers/image'
import { fullName } from '../../../helpers/user'

interface ComponentProps {
	post: core.Post.Model
	club: core.Club.Model
	postAvatar: JSX.Element
	onClick: () => any
}

interface WithSizesProps {
	isMobile: boolean
}

type Props = ComponentProps & WithSizesProps

class RichEventCard extends React.Component<Props> {
	constructor(props: Props) {
		super(props)
	}

	buildCardImage = ({ post, club, isMobile }: Props) => {
		const imageProps = {
			className: (isMobile) ? 'card-img-top' : 'rich-post-aside card-aside-column-right card-aside-column',
			image: post.image,
			club: club,
			size: ImageSize.Medium
		}

		return (isMobile) ?
			(
				<div className='rich-post-card-mobile-image'>
					<AsyncImage {...imageProps} />
				</div>
			) :
			(
				<AsyncBackgroundImage {...imageProps} />
			)
	}

	buildCardBody = ({ post, postAvatar }: Props) => {
		const authorName = fullName(post.author as core.User.Model)
		const options = { year: 'numeric', month: 'short', day: 'numeric' }
		const postDate = new Date(post.publicationDate).toLocaleDateString('us-en', options)

		return (
			<div className='card-body d-flex flex-column'>
				<div className='post-title'>{post.title}</div>
				<div className='post-description'>{post.richContent.text}</div>
				<div className='d-flex align-items-center mt-auto'>
					{postAvatar}
					<div>
						<div className='post-author-name'>{authorName}</div>
						<small className='d-block text-muted post-date'>{postDate}</small>
					</div>
				</div>
			</div>
		)
	}

	buildCard = ({ isMobile, onClick }: Props) => {
		const cardClass = classNames({
			'card': true,
			'rich-post-card': true,
			'card-aside': !isMobile,
			'rich-content-item': !isMobile
		})

		return (
			<div className={cardClass} onClick={onClick}>
				{/** Image (for mobile) */}
				{(isMobile) ? this.buildCardImage(this.props) : null}

				{/** Content */}
				{this.buildCardBody(this.props)}

				{/** Image (for non-mobile) */}
				{(!isMobile) ? this.buildCardImage(this.props) : null}
			</div>
		)
	}

	render() {
		return this.buildCard(this.props)
	}
}

const mapSizesToProps = (sizes: any) => ({
	isMobile: withSizes.isMobile(sizes)
})

export default withSizes(mapSizesToProps)(RichEventCard) as React.ComponentClass<ComponentProps>
