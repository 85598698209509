// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import * as queryString from 'query-string'
import * as Feather from 'react-feather'
import * as core from 'club-hub-core'
import update from 'immutability-helper'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { indexBy } from 'underscore'
import { withRouter, RouteComponentProps } from 'react-router'
import { oc } from 'ts-optchain'
import to from 'await-to-js'
import { isNullOrUndefined } from 'util'

// Actions
import { AlertActions, CustomerActions, EventActions, LotteryActions } from '../../../actions/index'

// State
import { RootReducerState } from '../../../reducers'
import { inCustomerViewSelector, bookableMembers } from '../../../reducers/user'
import { eventsByIDSelector } from '../../../reducers/event'

// Components
import Card from '../../Shared/Cards/Card'
import AsyncImage from '../../Shared/AsyncImage'
import ButtonRow from '../../Shared/ButtonRow'
import BackHeader from '../../Shared/BackHeader'
import UnderlineHeader from '../../Shared/ReservationDetails/UnderlineHeader'
import DimmedLoader from '../../Shared/DimmedLoader'
import ErrorComponent from '../../Shared/ErrorComponent'
import FormModal from '../../Shared/Formik/FormModal'
import GolferInputComponent from '../../Shared/GolferInput'
import { ReservationType } from '../../Shared/ReservationList'
import { SelectInput } from '../../Shared/Formik/InputPrimitives/SelectInput'
import { InputSelectionItem, FormInput, FormInputType } from '../../Shared/Form'
import { TextAreaInput } from '../../Shared/Formik/InputPrimitives/TextAreaInput'
import GuestModal from '../../Modals/GuestModal'

// Child Components
import DetailRow, { DetailRowItem } from '../../Shared/ReservationDetails/DetailRow'
import MemberInputComponent from '../../Shared/GuestInputs'

// Helpers
import * as Constants from '../../../constants'
import { setStateAsync } from '../../../helpers/promise'
import { fullName, memberInputForForm } from '../../../helpers/user'
import { ImageSize } from '../../../helpers/image'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

interface GolferInput {
	participant?: InputSelectionItem,
	needsCart: boolean,
	numHoles: number,
}

const emptyInput = () => {
	return { label: null as any, value: null as any }
}

interface ComponentProps {
	bookingDate: Date
	event: core.Event.Model
	reservation?: core.Event.Reservation
	calendar: core.Calendar.Model
	calendarGroup: core.Calendar.Group
	eventName: string
	backName: string
	type: ReservationType
	cardTitle: string
	submitButtonName: string
	guestCount?: number // The initial number of guest spots that are displayed in the form.
	maxGuests: number // Used to determine the max number of people that can be added to the form.
	handleCreateLottery?: (res: core.Event.Reservation) => void
}

const initialState = {
	usersByID: null as { [key: string]: core.User.Model },
	guestIndex: 0,
	guestModal: false,
	participants: [] as GolferInput[],
	reservedUserIDs: null as { [key: string]: string },
	showConfirmDelete: false,
	notes: '',
	error: false,
	loading: true,
	duration: null as number | null,
	lottery: false
}

type Props = ComponentProps & ConnectedState & RouteComponentProps & ConnectedActions
type State = typeof initialState

const MEMBER_PLACEHOLDER = 'Member'

export class BaseReservationComponent extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props)

		// Lets figure out if we need to do this.
		const participants = Array.from({ length: this.props.guestCount || 0 }, (v) => {
			return { participant: emptyInput(), needsCart: false, numHoles: 18 }
		})

		// Add the event's existing reserved users to a hash.
		const reservedUserIDs = this.getExistingResUserIDs()

		// Index the bookable members.
		const usersByID = indexBy(props.bookableMembers, '_id')

		// Set initial state.
		this.state = { ...initialState, usersByID, participants, reservedUserIDs }
	}

	/**
	 * If there is a reservationID in state (passed in from route state),
	 * then we know we are updating an existing reservation.
	 * Fetch the event and reservation, then set state values.
	 */
	async componentDidMount() {
		if (this.props.reservation) {
			const reservation = this.props.reservation
			const participants = this.getGuestInputsForReservation(reservation)
			const notes = oc(reservation).notes(oc(reservation).meta.notes(''))
			await setStateAsync(this, { participants, notes })
		} else if (this.props.isCustomerView) {
			// If the user is a Member, initialize the 'guests' array to include the
			const member = this.props.userState.loggedInUser
			const input = memberInputForForm(member)
			const participants = [input]
			await setStateAsync(this, { participants })
		}
		await setStateAsync(this, { loading: false })
	}

	// ----------------------------------------------------------------------------------
	// Form Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Handles form changes.
	 */
	handleFormChange = async (e: any) => {
		// Get the field/value from the event
		const field: keyof State = e.target.name
		const value = e.target.value
		await setStateAsync(this, () => ({ [field]: value }))
	}

	/**
	 * Handles selections in a Member select.
	 */
	handleMemberSelected = async (data: InputSelectionItem, index: number) => {
		if (!data.value && !data.label) {
			return this.removeGuestInput(index)
		}

		if (data.value === 'addGuest') {
			await setStateAsync(this, { guestModal: true })
			return
		}
		this.handleAddGuest(data)
	}

	// ----------------------------------------------------------------------------------
	// Add / Remove Guests
	// ----------------------------------------------------------------------------------

	handleAddGuest = async (data?: InputSelectionItem) => {
		if (this.state.participants.length >= this.props.maxGuests) {
			return
		}
		const user = this.state.usersByID[data.value]
		const participants = [...this.state.participants]
		const participant = { value: `${user._id}`, label: `${user.firstName} ${user.lastName}` }
		participants.push({ participant, needsCart: false, numHoles: 18 })
		await setStateAsync(this, { participants })
	}

	removeGuestInput = async (rowIndex: number): Promise<void> => {
		if (rowIndex === 0) { return null }
		const participants = [...this.state.participants]
		participants.splice(rowIndex, 1)
		await setStateAsync(this, { participants })
	}

	/**
 	* Toggles the guest modal to display or not.
 	*/
	closeGuestModal = async () => {
		await setStateAsync(this, { guestModal: !this.state.guestModal })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Returns user to the previous component.
	 */
	handleCancel = () => {
		this.props.history.goBack()
	}

	handleDelete = async () => {
		if (this.state.showConfirmDelete) {
			await this.removeRSVP()
			return
		}
		await setStateAsync(this, { showConfirmDelete: true })
	}

	// ----------------------------------------------------------------------------------
	// Network Requests
	// ----------------------------------------------------------------------------------

	/**
	 * Creates a reservation for an event.
	 * This will be done using the create RSVP or
	 * reserve bookable event API. Both take in a reservation object.
	 * @returns void (Triggers a route change).
	 */
	handleSubmit = async (): Promise<void> => {
		await setStateAsync(this, { loading: true })

		// Build the Reservation payload
		const reservation = this.buildReservationPayload()

		// If we have a lottery request, create it.
		if (this.state.lottery) {
			await this.props.handleCreateLottery(reservation)
			this.setState({ loading: false })
			this.handleRouteChange()
			return
		}

		// Check to see if we are updating an existing reservation.
		if (this.props.reservation) {
			this.updateRSVP(reservation)
		} else {
			// Choose which Reservation function to use based on the Calendar Group type
			const calendarGroupType = this.props.calendarGroup.type
			const createRsvpFunction = (calendarGroupType === core.Calendar.GroupType.Club) ?
				this.createRSVP :
				this.reserveBookableEvent

			const [requestError] = await to(createRsvpFunction(reservation))
			if (requestError) {
				this.props.fireFlashMessage(`Failed to create reservation. ${requestError.message}`, Constants.FlashType.DANGER)
				await setStateAsync(this, { loading: false })
				return
			}
		}
		this.handleRouteChange()
		await setStateAsync(this, { loading: false })
	}

	/**
	 * Sends the reservation payload to the create RSVP API.
	 * @returns void (Triggers a route change).
	 */
	createRSVP = async (reservation: core.Event.Reservation) => {
		const [err] = await to(this.props.createEventRsvp(reservation, `${this.props.event._id}`) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to create RSVP for Event.${err.message}`, Constants.FlashType.DANGER)
			throw err
		}
		this.props.fireFlashMessage(`Successfully created RSVP.`, Constants.FlashType.SUCCESS)
	}

	/**
	 * Sends the reservation payload to the update RSVP API.
	 * @returns void (Triggers a route change).
	 */
	updateRSVP = async (reservation: core.Event.Reservation) => {
		const resID = `${this.props.reservation._id}`
		const [err] = await to(this.props.updateEventRsvp(resID, reservation, `${this.props.event._id}`) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to update RSVP for Event.${err.message}`, Constants.FlashType.DANGER)
			throw err
		}
		this.props.fireFlashMessage(`Successfully updated RSVP.`, Constants.FlashType.SUCCESS)
	}

	/**
	 * Removes the reservation.
	 * @returns void (Triggers a route change).
	 */
	removeRSVP = async () => {
		await setStateAsync(this, { loading: true })

		const resID = `${this.props.reservation._id}`
		const [err] = await to(this.props.cancelEventRsvp(resID, `${this.props.event._id}`, 0, 0) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to cancel Reservation for Event.${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}

		this.handleRouteChange()
		this.props.fireFlashMessage(`Successfully cancelled Reservation.`, Constants.FlashType.SUCCESS)
	}

	/**
	 * Sends the reservation payload to the reserve bookable event API.
	 * @returns void (Triggers a route change).
	 */
	reserveBookableEvent = async (reservation: core.Event.Reservation) => {
		const calendarID = `${this.props.calendar._id}`
		const startTime = new Date(this.props.bookingDate).toISOString()
		const endTime = this.determineEndTime(this.props.bookingDate)

		const [reserveErr] = await to(this.props.reserveBookableEvent(reservation, calendarID, startTime, endTime) as any)
		if (reserveErr) {
			this.props.fireFlashMessage(`Failed to reserve Tee Time.`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false })
			throw reserveErr
		}

		const [fetchErr] = await to(this.props.fetchBookableEvents(startTime, [calendarID], !this.props.isCustomerView) as any)
		if (fetchErr) {
			this.props.fireFlashMessage(`Failed to fetch Events.`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false })
			throw fetchErr
		}

		this.props.fireFlashMessage(`You've successfully confirmed your reservation`, Constants.FlashType.SUCCESS)
	}

	/**
	 * Handles adding a new guest to the UI after it was created.
	 * @returns void.
	 */
	handleNewGuest = async (guest: core.User.Model): Promise<void> => {
		const guestData = { label: fullName(guest), value: `${guest._id}` }
		await this.handleMemberSelected(guestData, this.state.guestIndex)
		await setStateAsync(this, { loading: false, error: false, guestModal: false })
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	/**
	 * Builds the reservation card which includes a form.
	 * The logic for the form is held in the form component that matches the reservation type.
	 */
	buildContent = () => {
		const prefix = this.props.reservation ? 'Update' : 'New'
		return (
			<Card
				title={`${prefix} ${this.props.cardTitle}`}
				headerClass={'d-flex'}
				bodyClass={'card-body'}
				hideHeader={true}
				content={this.buildCardBody()}
				footer={this.buildButtonRow()}
			/>
		)
	}

	buildCardBody = () => {
		return (
			<>
				{this.buildImageComponent()}
				{this.buildAlertComponent()}
				{this.buildInfoComponent()}
				<>
					{this.buildGuestInputs()}
					{this.buildTextArea()}
				</>
			</>
		)
	}

	buildImageComponent = () => {
		const { loggedInClub } = this.props
		const image = oc(this).props.event.images[0]({})

		return (
			<div className='event-photo-container'>
				<AsyncImage
					image={image}
					size={ImageSize.Large}
					club={loggedInClub}
					className={'card-img-top'}
					placeholderClass={'card-img-top-placeholder'}
				/>
			</div>
		)
	}

	buildAlertComponent = () => {
		const club = oc(this).props.loggedInClub()
		const desc = `Please review your reservation information and confirm below. If you have any questions, please contact ${oc(club).name('')} at ${oc(club).locations[0].phone('')}`
		return (<RS.Alert color={'primary'}>{desc}</RS.Alert>)
	}

	buildInfoComponent = () => {
		const dateOpts = { month: 'long', day: 'numeric', year: 'numeric' }
		const dateString = new Date(this.props.bookingDate).toLocaleDateString('en-US', dateOpts)

		const timeOpts = { hour: 'numeric', minute: 'numeric' }
		const startTimeString = new Date(this.props.bookingDate).toLocaleTimeString('en-US', timeOpts)

		const dateTimeDetailRows: DetailRowItem[] = [
			{ icon: Feather.MapPin, label: 'Event', value: this.props.eventName },
			{ icon: Feather.Calendar, label: 'Time & Date', value: `${dateString}, ${startTimeString}` }
		]
		return (
			<div className='card-details'>
				<RS.Label className='form-label'>{'Details'}</RS.Label>
				{dateTimeDetailRows.map((i) => <DetailRow rowItem={i} key={i.label} />)}
			</div>
		)
	}

	/**
	 * Builds the Guest selection inputs section of the form.
	 */
	buildGuestInputs = () => {
		if (this.props.calendarGroup.type === core.Calendar.GroupType.Golf) {
			return this.buildGolferInputs()
		}
		return this.buildMemberInputs()
	}

	buildGolferInputs = () => {
		const users = oc(this).props.bookableMembers([])
		return (
			<GolferInputComponent
				club={this.props.loggedInClub}
				golfers={this.state.participants}
				eventTime={this.props.bookingDate}
				user={this.props.userState.loggedInUser}
				users={users}
				reservedUsers={oc(this).props.reservation.participants([])}
				handleAddGuest={(index: number) => this.handleAddGuest}
				handleMemberSelected={this.handleMemberSelected}
				updateGolferState={this.updateGolferState}
			/>
		)
	}

	buildMemberInputs = () => {
		const users = oc(this).props.bookableMembers([])
		const guests = this.state.participants.map((g: GolferInput) => g.participant)
		return (
			<MemberInputComponent
				title={'People'}
				club={this.props.loggedInClub}
				users={users}
				maxGuests={this.props.maxGuests}
				guestCount={guests.length + 1}
				guests={guests}
				loggedInUser={this.props.userState.loggedInUser}
				isCustomerView={this.props.isCustomerView}
				handleFormChange={this.handleFormChange}
				handleMemberSelected={this.handleMemberSelected}
				removeGuestInput={this.removeGuestInput}
				reservedUserIDsMap={this.state.reservedUserIDs}
			/>
		)
	}

	buildTextArea = () => {
		return (
			<>
				<RS.Label className='form-label'>
					<span>{'Notes'}</span>
				</RS.Label>
				<TextAreaInput
					item={{
						title: 'Notes',
						property: 'notes',
						type: FormInputType.TEXT_AREA,
						placeholder: 'Reservation notes...'
					}}
					value={this.state.notes}
					onChange={this.handleFormChange}
				/>
			</>
		)
	}

	buildButtonRow = () => {
		const { participants, showConfirmDelete } = this.state
		const disableButton = isNullOrUndefined(oc(participants)[0].participant.label())

		let deleteText = null
		let removeButtonHandler = null
		if (this.props.reservation) {
			deleteText = `${(showConfirmDelete) ? 'Confirm Cancellation?' : 'Cancel Reservation'}`
			removeButtonHandler = this.handleDelete
		}

		return (
			<ButtonRow
				disableButton={disableButton}
				submitButtonName={this.props.submitButtonName}
				submitButtonHandler={this.handleSubmit}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.handleCancel}
				removeButtonName={deleteText}
				removeButtonHandler={removeButtonHandler}
			/>
		)
	}

	buildGolfOptionInputs = () => {
		const golfOptionInputs: FormInput[] = [
			{
				title: 'Holes',
				property: 'holeCount',
				type: FormInputType.GROUPED_SELECT,
				selectItems: [{ label: '9', value: '9' }, { label: '18', value: '18' }],
				defaultValue: null,
			},
			{
				title: 'Carts',
				property: 'transport',
				type: FormInputType.GROUPED_SELECT,
				selectItems: [{ label: '1', value: '1' }, { label: '2', value: '2' }, { label: '3', value: '3' }],
				defaultValue: null,
			}
		]

		const selectInputs: JSX.Element[] = golfOptionInputs.map((input: FormInput) => {
			return (
				<div className='selectgroup' key={input.title}>
					<RS.Label className='form-label'>
						<span>{input.title}</span>
					</RS.Label>
					<SelectInput
						item={input}
						onChange={(property, data) => this.handleFormChange({ target: { name: property, value: data } })}
						value={(this.state as any)[input.property]}
						onBlur={() => null}
					/>
				</div>
			)
		})

		return (
			<>
				<UnderlineHeader title={'OPTIONS'} />
				<RS.FormGroup className='hole-select-container d-flex'>
					{selectInputs}
				</RS.FormGroup>
			</>
		)
	}

	buildGuestModal = () => {
		if (!this.state.guestModal) { return }

		return (
			<GuestModal
				club={this.props.loggedInClub}
				handleNewGuest={this.handleNewGuest}
				handleToggle={this.closeGuestModal}
			/>
		)
	}

	// ----------------------------------------------------------------------------------
	// General Content Builders
	// ----------------------------------------------------------------------------------

	render() {
		const { loggedInClub } = this.props
		if (this.state.loading) { return <DimmedLoader component={null} isLoading={true} /> }
		if (this.state.error) { return <ErrorComponent club={loggedInClub} isAdmin={this.props.userState.isAdmin} /> }
		return (
			<RS.Col sm={12} md={6} className='customer-reservation-container'>
				<BackHeader backFunc={this.handleCancel} backTitle={this.props.backName} />
				{this.buildContent()}
				{this.buildGuestModal()}
			</RS.Col>
		)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	/**
	 * Sends Members back to the My Reservation's view, and sends Admins back using the 'backRoute'.
	 * NOTE: If the user isn't an admin, and the reservation was made on the club calendar (Event RSVP),
	 * navigate to the Event detail component.
	 */
	handleRouteChange = () => {
		if (this.props.isCustomerView && this.props.calendarGroup.type !== core.Calendar.GroupType.Club) {
			const route = Constants.MY_RESERVATIONS_ROUTE.replace(':type', this.props.calendarGroup.type)
			return this.props.history.push(route)
		}
		this.props.history.goBack()
	}

	/**
	 * Adds all users who have a RSVP to the current event to a hash.
	 * This is used when building the member select inputs to prevent
	 * the same user from having a duplicate RSVP.
	 */
	getExistingResUserIDs = (): { [key: string]: string } => {
		if (isNullOrUndefined(this.props.event)) { return }

		const reservedUserIDsMap: { [key: string]: string } = {}
		this.props.event.reservations.forEach((res: core.Event.Reservation) =>
			res.participants.forEach((p: core.Event.Participant) =>
				reservedUserIDsMap[`${p.userID}`] = `${p.userID}`
			))
		return reservedUserIDsMap
	}

	/**
	 * Builds the reservation payload used to create the golf event.
	 * For guest users (without emails / userIDs), the participant
	 * will have a userID of undefined and name of 'Guest'.
	 */
	buildReservationPayload = (): core.Event.Reservation => {
		const participants: core.Event.Participant[] = []
		for (const participant of this.state.participants) {
			const newParticipant: core.Event.Participant = {
				userID: participant.participant.value as any,
				name: participant.participant.label || MEMBER_PLACEHOLDER,
				checkedIn: false,
				paid: false,
			}
			participants.push(newParticipant)
		}

		// Create the reservation for the payload
		const reservation: core.Event.Reservation = {
			creator: this.props.userState.loggedInUser._id,
			owner: participants[0].userID,
			participants: participants,
			notes: this.state.notes
		}

		// Attach golf Reservation metadata.
		if (this.props.calendarGroup.type.toLocaleLowerCase() === core.Calendar.GroupType.Golf.toLocaleLowerCase()) {
			reservation.meta = { notes: this.state.notes }
		}
		return reservation
	}

	/**
	 * For the dining calendar group we need to determine an end time.
	 * If 'duration' isn't supplied on component state, default to 90 minutes.
	 * In the future we could probably grab this value off of the calendar settings - SG.
	 */
	determineEndTime = (bookingTime: Date): string => {
		if (this.props.calendarGroup.type !== core.Calendar.GroupType.Dining) {
			return undefined
		}

		const defaultDuration = 89 // Eighty nine minutes.
		const duration = oc(this).state.duration(defaultDuration)

		// Determine the start/end
		const startTime = new Date(bookingTime).toISOString()
		const temp = new Date(startTime)
		temp.setMinutes(temp.getMinutes() + duration, 0, 0)
		return temp.toISOString()
	}

	// Update the golfer array with John's fun way.
	// https://medium.com/pro-react/a-brief-talk-about-immutability-and-react-s-helpers-70919ab8ae7c#7d62
	updateGolferState = async (index: number, property: string, value: any) => {
		const participants: GolferInput[] = this.state.participants.concat()
		const updatedParticipants = update(participants, {
			[index]: { [property]: { $set: value } }
		})
		await setStateAsync(this, { participants: updatedParticipants })
	}

	getGuestInputsForReservation = (reservation: core.Event.Reservation) => {
		const participants = oc(reservation).participants([])
		return participants.map((participant) => {
			const label = oc(participant).name()
			const value = label === MEMBER_PLACEHOLDER ? null : `${oc(participant).userID()}`
			return { participant: { label, value }, needsCart: oc(participant).golfCart(false), numHoles: oc(participant).holeCount(18) }
		})
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	userState: state.user,
	calendarState: state.calendar,
	eventState: state.event,
	isCustomerView: inCustomerViewSelector(state),
	bookableMembers: bookableMembers(state),
	eventsByID: eventsByIDSelector(state),
	loggedInClub: state.club.loggedInClub,
})

const mapDispatchToProps = {
	...AlertActions,
	...CustomerActions,
	...EventActions,
	...LotteryActions
}

const enhance = compose<React.ComponentType<{}>>(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(BaseReservationComponent)
