// External Dependencies
import * as core from 'club-hub-core'

// Internal Dependencies
import { ActionTypes, TypeKeys } from '../actions/section'
import { TypeKeys as CustomerTypeKeys, ActionTypes as CustomerActionTypes } from '../actions/customer'

export type SectionState = typeof defaultState

const defaultState = {
	sections: [] as core.Section.Model[],
	sectionCount: 0,
}

function sectionReducer(state = defaultState, action: ActionTypes | CustomerActionTypes) {
	switch (action.type) {
		case CustomerTypeKeys.FETCHED_CUSTOMER_STATE:
			return {
				...state,
				sections: action.sections,
			}
		case TypeKeys.FETCHED_SECTIONS:
			return {
				...state,
				sections: action.sections,
				sectionCount: action.count,
			}
		default:
			return state
	}
}

export default sectionReducer
