// External Dependencies
import * as Yup from 'yup'
import * as core from 'club-hub-core'
import { oc } from 'ts-optchain'

import { buildCustomRecurrenceString } from '../../../helpers/recurrence'

// Internal Dependencies
import { FormInput, FormInputType, InputSelectionItem, ReactSelectItem } from '../../Shared/Form'
import * as Constants from '../../../constants/index'

export interface EventFormState {
	isDraft?: boolean,
	name: string,
	location: InputSelectionItem,
	start: Date,
	type?: InputSelectionItem,
	end: Date,
	calendarIDs: InputSelectionItem[],
	richContent: core.SubModels.RichContent.Model,
	price?: string,
	images?: File[],
	shortLink?: string,
	public: InputSelectionItem,
	recurring: InputSelectionItem,
	recurringEnd: Date,
	maxParticipants: number
	maxGuests: number
	blockCalendar?: InputSelectionItem
	displayInFeed?: InputSelectionItem
	requiresRSVP?: InputSelectionItem
}

export const ClubEventFormInputs = (
	customLabel: string,
	locations: ReactSelectItem[],
	eventTypes: InputSelectionItem[],
	calendars: ReactSelectItem[],
	copyToClipboardHandler: (shortLink: string) => void,
): FormInput[] => {
	const recurringSelectItems: InputSelectionItem[] = [
		{ label: 'None', value: 'none' },
		{ label: 'Daily', value: `${core.Event.RecurringFrequency.DAILY}` },
		{ label: 'Weekly', value: `${core.Event.RecurringFrequency.WEEKLY}` },
		{ label: 'Monthly', value: `${core.Event.RecurringFrequency.MONTHLY}` },
		{ label: 'Annually', value: `${core.Event.RecurringFrequency.YEARLY}` },
		{ label: customLabel, value: 'custom' }
	]
	return [
		{
			title: 'Name',
			property: 'name',
			type: FormInputType.TEXT,
			size: 12,
			placeholder: 'Name',
			defaultValue: '',
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Start',
			property: 'start',
			type: FormInputType.DATE_TIME,
			size: 6,
			placeholder: 'Select Date...',
			date_end_field: 'end',
			defaultValue: null,
			validation: Yup.date().nullable().default(null).required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'End',
			property: 'end',
			type: FormInputType.DATE_TIME,
			size: 6,
			placeholder: 'Select Date...',
			date_start_field: 'start',
			defaultValue: null,
			validation: Yup.date().nullable().default(null).required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Calendars',
			property: 'calendarIDs',
			type: FormInputType.GROUPED_MULTI_SELECT,
			size: 6,
			placeholder: 'Select Calendar...',
			selectItems: calendars,
			defaultValue: null,
			validation: Yup.string().nullable().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Location',
			property: 'location',
			type: FormInputType.SELECT,
			size: 6,
			placeholder: 'Select Location...',
			selectItems: locations,
			defaultValue: null,
			validation: Yup.string().nullable().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Images',
			property: 'images',
			type: FormInputType.FILE,
			size: 12,
			defaultValue: null,
			file_accept: '.png,.jpg,.jpeg',
			file_multipleFiles: true,
		},
		{
			title: 'Type',
			property: 'type',
			type: FormInputType.SELECT,
			size: 6,
			placeholder: 'Select Type...',
			selectItems: eventTypes,
			defaultValue: null,
		},
		{
			title: 'Price',
			property: 'price',
			type: FormInputType.PRICE,
			placeholder: '',
			size: 6,
			defaultValue: '',
		},
		{
			title: 'Recurring',
			property: 'recurring',
			type: FormInputType.SELECT,
			size: 6,
			selectItems: recurringSelectItems,
			defaultValue: { label: 'None', value: 'none' },
		},
		{
			title: 'Display In Feed',
			property: 'displayInFeed',
			type: FormInputType.SELECT,
			size: 3,
			selectItems: [{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }],
			defaultValue: { label: 'Yes', value: 'true' },
		},
		{
			title: 'Block Calendar',
			property: 'blockCalendar',
			type: FormInputType.SELECT,
			size: 3,
			selectItems: [
				{ label: 'Yes', value: 'true' },
				{ label: 'No', value: 'false' }
			],
			defaultValue: { label: 'Yes', value: 'true' },
		},
		{
			title: 'Requires RSVP',
			property: 'requiresRSVP',
			type: FormInputType.SELECT,
			size: 3,
			selectItems: [{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }],
			defaultValue: { label: 'Yes', value: 'true' },
		},
		{
			title: 'Max Participants',
			property: 'maxParticipants',
			type: FormInputType.NUMBER,
			placeholder: 'Max',
			size: 3,
			defaultValue: 50,
		},
		{
			title: 'Max Guests',
			property: 'maxGuests',
			type: FormInputType.NUMBER,
			placeholder: 'Max',
			size: 3,
			defaultValue: 4,
		},
		{
			title: 'Public',
			property: 'public',
			type: FormInputType.SELECT,
			size: 3,
			selectItems: [{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }],
			defaultValue: { label: 'No', value: 'false' },
		},
		{
			title: 'Short Link',
			property: 'shortLink',
			type: FormInputType.URL,
			placeholder: 'Short Link',
			size: 12,
			defaultValue: '',
			copyToClipboardHandler
		},
		{
			title: 'Description',
			property: 'richContent',
			type: FormInputType.MCE_EDITOR,
			class: 'event-description-editor',
			placeholder: 'Description',
			defaultValue: [],
		}
	]
}
