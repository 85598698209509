/**
 * Accepts a parameter a returns a boolean indicating if the param has any value.
 * Used for checking fields in a components local state (validation action payloads).
 */
export const inputValidator = (value: string): boolean => {
	if (value === null || value === '' || value === undefined) {
		return false
	}

	if (Array.isArray(value)) {
		return value.length > 0
	}

	return true
}

/**
 * Accepts an array of keys to check for on the supplied object.
 */
export const interfaceValidator = (keys: string[], objInterface: any): boolean => {
	for (const key of keys) {
		if (!inputValidator(objInterface[key])) {
			return false
		}
	}
	return true
}
