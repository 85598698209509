// External Dependencies
import * as React from 'react'
import * as Feather from 'react-feather'

// @ts-ignore
import chroma from 'chroma-js'
import { contains, filter, find } from 'underscore'
import { Select, Empty } from 'antd'
const { Option, OptGroup } = Select

// SCSS Colors
// @ts-ignore
import colors from '../../../assets/__base.scss'

import { InputSelectionItem, ReactSelectItem } from '../Form'

interface GroupOption {
	label: string
	options: ReactSelectItem[]
}

interface AntSelectInput {
	key: string
	label: string
}

interface SelectProps {
	groupOptions?: GroupOption[],
	options?: ReactSelectItem[],
	onChange: (data: InputSelectionItem | InputSelectionItem[]) => any
	onSelect?: (data: InputSelectionItem | InputSelectionItem[]) => any
	onBlur?: () => void
	className?: string
	placeholder?: string
	property?: string
	controlStyles?: any
	value?: InputSelectionItem | InputSelectionItem[]
	isMulti?: boolean
	selectAll?: boolean
	isClearable?: boolean
	isDisabled?: boolean
	featherIcon?: any
	faIcon?: string
	openMenuOnClick?: boolean
}

export default class SelectComponent extends React.Component<SelectProps> {
	private flatOptions: InputSelectionItem[]
	constructor(props: SelectProps) {
		super(props)
		this.setFlatOptions()
	}

	componentDidUpdate = () => {
		this.setFlatOptions()
	}

	setFlatOptions = () => {
		if (this.props.options) {
			let flatOptions: InputSelectionItem[] = []
			for (const option of this.props.options) {
				if (option.options) {
					flatOptions = flatOptions.concat(option.options)
				} else {
					flatOptions.push(option as InputSelectionItem)
				}
			}
			this.flatOptions = flatOptions
		}
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------
	onChange = (val: AntSelectInput | AntSelectInput[]) => {
		const { onChange } = this.props
		this.onAction(val, onChange)
	}

	onSelect = (val: AntSelectInput | AntSelectInput[]) => {
		const { onSelect } = this.props
		if (!onSelect) { return }

		this.onAction(val, onSelect)
	}

	onAction = (val: AntSelectInput | AntSelectInput[], action: any) => {
		if (Array.isArray(val)) {
			const selectedValues = (val as AntSelectInput[]).map((obj: AntSelectInput) => {
				return obj.key
			})

			const filteredVals = filter(this.flatOptions, (opt: ReactSelectItem) => {
				if (opt) {
					return contains(selectedValues, opt.value)
				}
				return false
			})
			return action(filteredVals)
		} else {
			const found = find(this.flatOptions, (opt: ReactSelectItem) => {
				return opt.value === (val as AntSelectInput).key
			})
			return action(found)
		}
	}

	// ----------------------------------------------------------------------------------
	// Custom Select Components
	// ----------------------------------------------------------------------------------

	customSelectOption = (reactSelectProps: any) => {
		const { onMouseMove, onMouseOver, ...newInnerProps } = reactSelectProps.innerProps as any
		const newReactSelectProps = {
			...reactSelectProps,
			innerProps: newInnerProps
		} as any
		return (
			{ ...newReactSelectProps }
		)
	}

	buildSelectComponent = () => {
		// Map out the selectedItems
		let selectedNodes
		if (this.props.value instanceof Array) {
			selectedNodes = this.props.value.map((instanceItem: InputSelectionItem) => {
				return { key: instanceItem.value, label: instanceItem.label }
			})
		} else if (this.props.value) {
			selectedNodes = { key: this.props.value.value, label: this.props.value.label }
		}

		// Handy mapping func
		const optionFormatterFunc = (value: InputSelectionItem) => {
			const FeatherIcon = value.icon
			const icon = value.icon ? (<FeatherIcon size={20} />) : null
			return (
				<Option key={`${value.value}`} value={value.value}>
					{icon}
					{value.label}
				</Option>
			)
		}

		const groupOptionFormatterFunc = (value: ReactSelectItem) => {
			if (value.options) {
				const key = `${value.value}`
				const optz = []
				for (const inputOpt of value.options) {
					optz.push(optionFormatterFunc(inputOpt))
				}

				return (
					<OptGroup key={key} label={value.label}>
						{optz}
					</OptGroup>
				)
			}
			const inputItem: InputSelectionItem = value as InputSelectionItem
			return optionFormatterFunc(inputItem)
		}

		// This is a mangled mess due to a bad set of interfaces used by the callers. Need to refactor
		const runningOptions = []
		for (const option of this.props.options) {
			if (option.options && option.options.length > 0) {
				if (option.label) {
					runningOptions.push(groupOptionFormatterFunc(option))
				} else {
					option.options.forEach((opt: InputSelectionItem) => runningOptions.push(optionFormatterFunc(opt)))
				}
			} else if (option.label && option.value) {
				const inputItem: InputSelectionItem = option as InputSelectionItem
				runningOptions.push(optionFormatterFunc(inputItem))
			}
		}

		const notFoundContent = this.buildEmptyContent()

		// Note: WindowedSelect takes the same props as ReactSelect, but adds 'windowThreshold'
		const mode = this.props.isMulti ? 'multiple' : null
		return (
			<Select
				labelInValue={true}
				id={this.props.property}
				mode={mode}
				className={this.props.className}
				placeholder={this.props.placeholder}
				optionFilterProp='children'
				onChange={this.onChange}
				onSelect={this.onSelect}
				value={selectedNodes}
				onBlur={this.props.onBlur}
				showSearch={true}
				notFoundContent={notFoundContent}
			>
				{runningOptions}
			</Select>
		)
	}

	buildEmptyContent = () => {
		return (
			<Empty
				image='https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original'
				imageStyle={{
					height: 60,
				}}
				description={
					<span>No More Options</span>
				}
			/>
		)
	}

	buildSelectWithIcon = () => {
		const FeatherIcon = this.props.featherIcon
		const fontAwesomeIcon = (this.props.faIcon) ? <i className={this.props.faIcon} /> : null
		const icon = (this.props.featherIcon) ? (<FeatherIcon size={18} />) : fontAwesomeIcon
		return (
			<div className='select-with-icon input-group no-wrap'>
				<div className='input-group-prepend'>
					<span className='input-group-text'>
						{icon}
					</span>
				</div>
				<div className='w-100'>
					{this.buildSelectComponent()}
				</div>
			</div>
		)
	}

	render() {
		return (this.props.featherIcon || this.props.faIcon) ?
			this.buildSelectWithIcon() :
			this.buildSelectComponent()
	}
}
