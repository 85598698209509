// External Dependencies
import * as React from 'react'
import { SketchPicker, ColorResult } from 'react-color'

// Internal Dependencies
import { BaseInputPrimitiveProps } from '../index'
import { setStateAsync } from '../../../../../helpers/promise'

interface Props extends Omit<BaseInputPrimitiveProps, 'onChange'> {
	onChange: (property: string, color: string) => void
}

const initialState = {
	displayColorPicker: false,
}

type State = typeof initialState

export default class ColorInput extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)

		this.state = { ...initialState }
	}

	handleClick = () => {
		setStateAsync(this, { displayColorPicker: true })
	}

	handleClose = () => {
		setStateAsync(this, { displayColorPicker: false })
	}

	handleChange = (color: ColorResult) => {
		this.props.onChange(this.props.item.property, color.hex)
	}

	buildInput = () => {
		const colorStyle = { backgroundColor: `${this.props.value}` }
		return (
			<div className={'color-input-container'} onClick={this.handleClick}>
				<div className={'color-input'} style={colorStyle} />
			</div>
		)
	}

	buildPopover = () => {
		if (!this.state.displayColorPicker) { return null }
		return (
			<div className={'popover-container'}>
				<div className={'cover-container'} onClick={this.handleClose} />
				<SketchPicker color={this.props.value} onChange={this.handleChange} />
			</div>
		)
	}
	render() {
		return (
			<div>
				{this.buildInput()}
				{this.buildPopover()}
			</div >
		)
	}
}
