// External Dependencies
import * as Yup from 'yup'

// Internal Dependencies
import { FormInput, FormInputType, InputSelectionItem } from '../../Shared/Form'
import { stateSelectionInputs } from '../../../helpers/form'
import * as Constants from '../../../constants/index'

export interface ServiceProviderFormState {
	name: string
	contactName: string
	phone: string
	email: string
	street: string
	city: string
	state: InputSelectionItem
	zip: string
	website?: string
}

export const ServiceProviderFormInputs: FormInput[] = [
	{
		title: 'Name',
		property: 'name',
		type: FormInputType.TEXT,
		size: 6,
		placeholder: 'Name',
		defaultValue: '',
		validation: Yup.string().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Contact Name',
		property: 'contactName',
		type: FormInputType.TEXT,
		size: 6,
		placeholder: 'Contact Name',
		defaultValue: '',
		validation: Yup.string().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Phone Number',
		property: FormInputType.PHONE,
		type: FormInputType.TEXT,
		size: 6,
		placeholder: '(xxx) xxx-xxxx',
		defaultValue: '',
		validation: Yup.string().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Email',
		property: 'email',
		type: FormInputType.TEXT,
		size: 6,
		placeholder: 'Email',
		defaultValue: '',
		validation: Yup.string().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Website',
		property: 'website',
		type: FormInputType.TEXT,
		size: 6,
		placeholder: 'Website',
		defaultValue: '',
	},
	{
		title: 'Address',
		property: 'street',
		type: FormInputType.TEXT,
		size: 6,
		placeholder: 'Street Address',
		defaultValue: '',
		validation: Yup.string().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'City',
		property: 'city',
		type: FormInputType.TEXT,
		size: 4,
		placeholder: 'City',
		defaultValue: '',
		validation: Yup.string().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'State',
		property: 'state',
		type: FormInputType.SELECT,
		size: 4,
		placeholder: 'Select State...',
		selectItems: stateSelectionInputs,
		defaultValue: null,
		validation: Yup.string().nullable().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Zip',
		property: 'zip',
		type: FormInputType.TEXT,
		size: 4,
		placeholder: 'Zip Code',
		defaultValue: '',
		validation: Yup.string().required(Constants.REQUIRED_FIELD),
	},
]
