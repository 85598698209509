// External Dependencies
import { combineReducers, AnyAction } from 'redux'
import * as Sentry from '@sentry/browser'

// Internal Dependencies
import { TypeKeys as UserTypeKeys } from '../actions/user'
import app, { AppState } from './app'
import user, { UserState } from './user'
import message, { MessageState } from './message'
import alert, { AlertState } from './alert'
import restaurant, { RestaurantState } from './restaurant'
import post, { PostState } from './post'
import event, { EventState } from './event'
import club, { ClubState } from './club'
import statement, { StatmentState } from './statement'
import calendar, { CalendarState } from './calendar'
import section, { SectionState } from './section'
import queryFilter, { QueryFilterState } from './queryFilter'

// Local Persistence
import { clearState as ClearLocalStorage } from '../store/localStorage'

export interface RootReducerState {
	app: AppState,
	restaurant: RestaurantState,
	message: MessageState,
	alert: AlertState,
	user: UserState,
	post: PostState,
	event: EventState,
	club: ClubState,
	calendar: CalendarState,
	section: SectionState,
	statement: StatmentState,
	queryFilter: QueryFilterState,
}

const rootReducer = (state: RootReducerState, action: AnyAction) => {
	// Check if the User is signing out - clear the store if so
	if (action.type === UserTypeKeys.SIGN_OUT) {
		ClearLocalStorage()
		Sentry.configureScope((scope) => { scope.setUser({}) })
		state = undefined
		window.location.href = '/'
	}

	return appReducer(state, action)
}

const appReducer = combineReducers<RootReducerState>({
	app,
	restaurant,
	message,
	alert,
	user,
	post,
	event,
	club,
	calendar,
	section,
	statement,
	queryFilter,
})

export default rootReducer
