// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import to from 'await-to-js'

// Internal Dependencies

// Actions
import { AlertActions, CalendarActions } from '../../../actions/index'

// State
import { RootReducerState } from '../../../reducers'
import { calendarsByIDSelector } from '../../../reducers/calendar'

// Components
import DimmedLoader from '../../Shared/DimmedLoader'

// Form
import GenericFormComponent from '../../Shared/Formik/GenericForm'
import { ServiceProviderFormInputs, ServiceProviderFormState } from './form'

// Helpers
import * as Constants from '../../../constants'
import { setStateAsync } from '../../../helpers/promise'
import { formatPhoneNumber } from '../../../helpers/formatting'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	error: false,
	loading: true,
	serviceProviderToUpdate: null as core.Calendar.Model | null
}

type Props = RouteComponentProps & ConnectedState & ConnectedActions
type State = typeof initialState

class ServiceProviderForm extends React.Component<Props, State> {
	private isUpdateForm: boolean

	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
		this.isUpdateForm = this.props.location.pathname === Constants.UPDATE_SERVICE_PROVIDER_ROUTE
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		if (this.isUpdateForm) {
			await this.setServiceProviderForFormWithQueryParams()
		}
		await setStateAsync(this, { loading: false })
	}

	/**
	 * Gets the Service Provider, that is being updated, from state using its ID that we get
	 * from the query parameters in the URL.
	 *
	 * The Service Provider will have its information pre-filled into the form.
	 */
	setServiceProviderForFormWithQueryParams = async () => {
		// Parse the query string of the URL into an object
		const parsedQuery = queryString.parse(this.props.location.search)

		// Get the Service Provider by its ID
		const serviceProviderID = parsedQuery.serviceProviderID
		const serviceProviderToEdit = this.props.calendarsByID[serviceProviderID]

		// Update the state
		await setStateAsync(this, { serviceProviderToUpdate: serviceProviderToEdit })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Returns the user to the Service Providers view when they cancel the form
	 */
	handleCancel = () => {
		this.props.history.push(Constants.SERVICE_PROVIDERS_ROUTE)
	}

	/**
	 * Determines which handler to call (Create or Update) when the user submits the form
	 */
	handleSave = async (form: ServiceProviderFormState): Promise<void> => {
		switch (this.props.location.pathname) {
			case Constants.CREATE_SERVICE_PROVIDER_ROUTE:
				return this.handleCreate(form)
			case Constants.UPDATE_SERVICE_PROVIDER_ROUTE:
				return this.handleUpdate(form)
			default:
				break
		}
	}

	/**
	 * Will create a new ServiceProvider Calendar on the DB with the information in the form.
	 * Service Providers will only be created if the validation conditions for the form are met.
	 */
	handleCreate = async (form: ServiceProviderFormState): Promise<void> => {
		await setStateAsync(this, { loading: true })

		const payload = this.buildServiceProviderPayload(form)
		const [err] = await to(this.props.createCalendar(payload) as any)
		if (err) {
			this.props.fireFlashMessage(`Problem creating Service Provider. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}

		this.props.fireFlashMessage('Successfully created Service Provider', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, error: false })
		this.props.history.push(Constants.SERVICE_PROVIDERS_ROUTE)
	}

	/**
	 * Will update an existing ServiceProvider Calendar on the DB with the information in the form.
	 * Service Providers will only be updated if the validation conditions for the form are met.
	 */
	handleUpdate = async (form: ServiceProviderFormState): Promise<void> => {
		if (!this.state.serviceProviderToUpdate) { return }
		const calendarID = `${this.state.serviceProviderToUpdate._id}`

		await setStateAsync(this, { loading: true })

		const payload = this.buildServiceProviderPayload(form)
		const [err] = await to(this.props.updateCalendar(calendarID, payload) as any)
		if (err) {
			this.props.fireFlashMessage(`Problem updating Service Provider. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true, serviceProviderToUpdate: null })
			return
		}

		this.props.fireFlashMessage('Successfully updated Service Provider', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, error: false, serviceProviderToUpdate: null })
		this.props.history.push(Constants.SERVICE_PROVIDERS_ROUTE)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	/**
	 * Builds the payload, that will be sent to the server, based on the form
	 */
	buildServiceProviderPayload = (form: ServiceProviderFormState): core.Calendar.Model => {
		const loggedInClub = this.props.loggedInClub
		const serviceCalendarGroup = loggedInClub.calendarGroups.find((cg) => cg.type === core.Calendar.GroupType.Service)

		// Create the Service Provider payload and add it to the FormData
		const payload: core.Calendar.Model = {
			name: form.name,
			clubID: loggedInClub._id,
			groupID: serviceCalendarGroup._id,
			maxParticipants: null,
			location: {
				address1: form.street,
				city: form.city,
				state: form.state.value,
				zip: form.zip,
				contactName: form.contactName,
				phone: formatPhoneNumber(form.phone),
				email: form.email,
				website: form.website
			}
		}

		return payload
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildForm = () => {
		const serviceProviderToUpdate = (this.isUpdateForm && this.state.serviceProviderToUpdate) ?
			this.state.serviceProviderToUpdate : undefined
		const formTitle = (this.isUpdateForm) ? 'Update Service Provider' : 'New Service Provider'
		const formInputs = ServiceProviderFormInputs
		const submitButtonName = (this.isUpdateForm) ? 'Update' : 'Save'

		const formResource: ServiceProviderFormState = (serviceProviderToUpdate) ? {
			name: serviceProviderToUpdate.name,
			contactName: serviceProviderToUpdate.location.contactName,
			phone: serviceProviderToUpdate.location.phone,
			email: serviceProviderToUpdate.location.email,
			street: serviceProviderToUpdate.location.address1,
			city: serviceProviderToUpdate.location.city,
			state: serviceProviderToUpdate.location.state as any,
			zip: serviceProviderToUpdate.location.zip,
			website: serviceProviderToUpdate.location.website
		} : undefined

		return (
			<GenericFormComponent
				title={formTitle}
				inputs={formInputs}
				formResource={formResource}
				enableReinitialize={false}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.handleCancel}
				submitButtonName={submitButtonName}
				submitButtonHandler={this.handleSave}
			/>
		)
	}

	render() {
		if (this.state.loading) { return <DimmedLoader isLoading={true} component={null} /> }

		return (
			<div className='row justify-content-center mt-4 mx-auto'>
				<div className='col col-md-10 col-xl-8 d-flex justify-content-center'>
					{this.buildForm()}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	loggedInClub: state.club.loggedInClub,
	calendarsByID: calendarsByIDSelector(state),
})

const mapDispatchToProps = {
	...CalendarActions,
	...AlertActions
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceProviderForm)
