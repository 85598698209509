// External Dependencies
import * as Feather from 'react-feather'

// Internal Dependencies
import { TableColumn } from '../../Shared/Table'
import { TableColumnType } from '../../Shared/Table/Primitives'

export const ReservationSettingsPrivilegesColumns: TableColumn[] = [
	{
		title: 'Membership',
		property: 'membershipType',
		type: TableColumnType.BADGE,
	},
	{
		title: 'Window',
		property: 'window',
		type: TableColumnType.TEXT,
	},
	{
		title: 'Bookings',
		property: 'bookings',
		type: TableColumnType.TEXT,
	},
	{
		title: 'Guests',
		property: 'guests',
		type: TableColumnType.TEXT,
	},
	{
		title: '',
		property: '',
		type: TableColumnType.DROPDOWN,
		class: 'table-dropdown-menu',
		dropdownItems: [
			{
				action: 'editPrivilege',
				valueProperty: 'rowIdx',
				displayText: 'Edit',
				icon: Feather.Edit,
			},
			{
				action: 'deletePrivilege',
				valueProperty: 'rowIdx',
				displayText: 'Remove',
				icon: Feather.Trash,
			}
		],
	}
]
