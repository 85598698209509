// External Dependencies
import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'

// Internal Dependencies

// Actions and Action Interface Imports
import { OauthActions, AlertActions } from '../../actions/index'

import * as Constants from '../../constants'

// Components
import ErrorComponent from '../Shared/ErrorComponent'
import DimmedLoader from '../Shared/DimmedLoader'

type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	loading: true,
	error: false
}

type Props = RouteComponentProps & ConnectedActions
type State = typeof initialState

/**
 * User Component
 */
class OauthComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
	}

	public decodedQuery: any = null

	// ----------------------------------------------------------------------------------
	// Life-cycle Methods
	// ----------------------------------------------------------------------------------

	componentDidMount(): void {
		if ((this.props.match.params as any).provider === 'google') {
			const token = this.props.location.search.split('=')[1]
			return (this.props.submitGoogleOauth(token) as any).then((res: any) => {
				this.props.fireFlashMessage(`You've successfully synced data on your Google Calendar.`, Constants.FlashType.SUCCESS)
				return this.props.history.push(Constants.USER_ROUTE)
			}).catch((err: Error) => { throw err })
		}
	}

	render() {
		if (this.state.loading) { return <DimmedLoader component={null} isLoading={true} /> }
		if (this.state.error) { return <ErrorComponent isAdmin={false} /> }

		return (
			<div />
		)
	}
}

const mapDispatchToProps = {
	...OauthActions,
	...AlertActions
}

export default connect(undefined, mapDispatchToProps)(OauthComponent)
