// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as Feather from 'react-feather'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { connect as FormikConnect, FormikContext, FieldArrayRenderProps, FieldArray } from 'formik'
import { oc } from 'ts-optchain'

// Internal Dependencies

// Actions
import { QueryFilterActions } from '../../../../actions/index'

// State
import { RootReducerState } from '../../../../reducers'

// Components
import { FilterTag } from '../FilterTag'
import { QueryFilterFormState } from '../form'
import { FormInputType, InputSelectionItem } from '../../Form'
import DropdownComponent, { DropdownItemType, DropdownItem } from '../../Dropdown'

interface FormikConnectedProps {
	formik: FormikContext<QueryFilterFormState>
}

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

interface ComponentProps {
	queryFields: Array<InputSelectionItem & { type: FormInputType }>
	showingActive?: boolean
	onCreateGroup?: () => any
	onEdit?: () => any
	onDelete?: () => any
	onClear?: () => any
}

type Props = ComponentProps & FormikConnectedProps & ConnectedActions & ConnectedState
type State = any

class FilterSection extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	handleDropdown = (item: DropdownItem) => {
		switch (item.value) {
			case 'createGroup':
				return this.props.onCreateGroup()
			case 'edit':
				return this.props.onEdit()
			case 'clear':
				return this.props.onClear()
			case 'delete':
				return this.props.onDelete()
		}
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildFilters = (fieldArrayProps: FieldArrayRenderProps) => {
		const formValues: QueryFilterFormState = fieldArrayProps.form.values
		const filtersToRender = (this.props.showingActive) ? formValues.activeFilters : formValues.pendingFilters
		return filtersToRender.map((filter: core.QueryFilter.Predicate, idx: number) => {
			return this.buildFilter(fieldArrayProps, filter, idx)
		})
	}

	buildFilter = (fieldArrayProps: FieldArrayRenderProps, filter: core.QueryFilter.Predicate, idx: number) => {
		const attribute = this.formatFilterAttribute(filter)
		const comparison = this.formatFilterComparison(filter)
		const value = this.formatFilterValue(filter)
		const tagText = `${attribute} ${comparison} ${value}`
		const onClickClose = (this.props.showingActive) ? undefined : () => {
			const pendingFilters = fieldArrayProps.form.values.pendingFilters
			if (pendingFilters && pendingFilters.length === 1) {
				this.props.onClear()
			}
			fieldArrayProps.remove(idx)
		}

		return (
			<FilterTag key={`filter-tag=${idx}`} text={tagText} onClickClose={onClickClose} />
		)
	}

	buildDropdown = () => {
		const dropdownClass = classNames({
			'form-control': true,
			'query-filter-active-dropdown': true
		})

		return (
			<DropdownComponent
				alignRight={true}
				header={'Menu'}
				featherIcon={Feather.Menu}
				category={''}
				items={[
					{ type: DropdownItemType.Default, icon: Feather.Users, text: 'Create Group', value: 'createGroup' },
					{ type: DropdownItemType.Default, icon: Feather.Edit, text: 'Edit', value: 'edit' },
					{ type: DropdownItemType.Default, icon: Feather.X, text: 'Clear', value: 'clear' },
					{ type: DropdownItemType.Default, icon: Feather.Trash2, text: 'Delete', value: 'delete' },
				]}
				onChange={this.handleDropdown}
				className={dropdownClass}
			/>
		)
	}

	buildButton = () => {
		const buttonClass = classNames({
			'btn': true,
			'btn-primary': true,
			'query-filter-active-edit-button': this.props.showingActive,
			'query-filter-pending-save-button': !this.props.showingActive,
		})

		const onClick = (this.props.showingActive) ?
			this.props.onEdit :
			this.props.formik.submitForm

		const buttonText = (this.props.showingActive) ?
			'Edit Filters' :
			'Save Filters'

		return (
			<button className={buttonClass} onClick={onClick}>
				{buttonText}
			</button>
		)
	}

	render() {
		const { showingActive } = this.props
		const { values } = this.props.formik
		if (!this.props.showingActive && values.pendingFilters.length === 0) { return null }
		if (this.props.showingActive && values.activeFilters.length === 0) { return null }

		const sectionClass = classNames({
			'query-filter-section': true,
			'query-filter-active-section': showingActive,
			'query-filter-pending-section': !showingActive
		})

		const sectionTagsClass = classNames({
			'query-filter-active-tags': showingActive,
			'query-filter-pending-tags': !showingActive
		})

		const fieldArrayName = (showingActive) ? 'activeFilters' : 'pendingFilters'

		return (
			<div className={sectionClass}>
				<div className={sectionTagsClass}>
					<FieldArray name={fieldArrayName} render={this.buildFilters} />
				</div>
				{(showingActive) ? this.buildDropdown() : this.buildButton()}
			</div>
		)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	formatFilterAttribute = (filter: core.QueryFilter.Predicate) => {
		const filterAttributeValue = filter.attribute
		const attributeOptionsWithValue = this.props.queryFields.find((field) => field.value === filterAttributeValue)
		return attributeOptionsWithValue.label
	}

	formatFilterComparison = (filter: core.QueryFilter.Predicate) => {
		return core.QueryFilter.ComparisonOperatorLabel[filter.comparison]
	}

	formatFilterValue = (filter: core.QueryFilter.Predicate) => {
		switch (filter.type) {
			case FormInputType.DATE:
				return new Date(filter.value).toLocaleDateString('en-US')
			case FormInputType.SELECT:
				return (filter.value as any as InputSelectionItem).label
			default:
				return filter.value
		}
	}
}

const mapStateToProps = (state: RootReducerState) => {
	return {
		queryFilterState: state.queryFilter
	}
}

const mapDispatchToProps = {
	...QueryFilterActions
}

export default FormikConnect<ComponentProps>(connect<ConnectedState, ConnectedActions, Props>(mapStateToProps, mapDispatchToProps)(FilterSection))
