// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import * as Core from 'club-hub-core'
import { oc } from 'ts-optchain'

import { ImageSize } from '../../../helpers/image'
import AsyncImage from '../../Shared/AsyncImage'

interface ComponentProps {
	images: Core.SubModels.Image.Model[]
	club: Core.Club.Model
}

const initialState = {
	activeIndex: 0
}

type Props = ComponentProps
type State = typeof initialState

/**
 * Displays a lovely carousel of images.
 */
class ImageCarousel extends React.Component<Props, State> {

	private animating: boolean

	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
	}

	onExiting = () => {
		this.animating = true
	}

	onExited = () => {
		this.animating = false
	}

	/**
	 * Flips the active index to the next index.
	 */
	next = () => {
		if (this.animating) { return }

		const images = oc(this).props.images([])
		const lastIndex = (this.state.activeIndex === images.length - 1)
		const nextIndex = lastIndex ? 0 : this.state.activeIndex + 1
		this.setState({ activeIndex: nextIndex })
	}

	/**
	 * Flips the active index to the previous index.
	 */
	previous = () => {
		if (this.animating) { return }

		const images = oc(this).props.images([])
		const firstIndex = (this.state.activeIndex === 0)
		const nextIndex = firstIndex ? images.length - 1 : this.state.activeIndex - 1
		this.setState({ activeIndex: nextIndex })
	}

	getImageSlides = () => {
		const images = oc(this).props.images([])
		return images.map((image: Core.SubModels.Image.Model) => {
			return (
				<RS.CarouselItem key={`${image._id}`}>
					<AsyncImage
						image={image}
						size={ImageSize.Large}
						club={this.props.club}
						className={'card-img-top'}
						placeholderClass={'card-img-top-placeholder'}
					/>
				</RS.CarouselItem>
			)
		})
	}

	getCarouselControls = () => {
		const images = oc(this).props.images([])
		if (images.length <= 1) { return null }
		return (
			<>
				<RS.CarouselControl direction='prev' directionText='Previous' onClickHandler={this.previous} />
				<RS.CarouselControl direction='next' directionText='Next' onClickHandler={this.next} />
			</>
		)
	}

	render() {
		const slides = this.getImageSlides()
		const controls = this.getCarouselControls()
		return (
			<div className='image-carousel-container'>
				<RS.Carousel
					className={'image-carousel'}
					activeIndex={this.state.activeIndex}
					next={this.next}
					previous={this.previous}
				>
					{slides}
					{controls}
				</RS.Carousel>
			</div>
		)
	}
}

export default ImageCarousel
