// External Dependencies
import * as Feather from 'react-feather'

// Internal Dependencies
import { TableColumn } from '../Shared/Table'
import { TableColumnType } from '../Shared/Table/Primitives'

export enum UserTableActions {
	ViewUser = 'viewUser',
	MessageUser = 'messageUser',
	EditUser = 'editUser',
	RemoveUser = 'removeUser',
	ResetPassword = 'resetPassword',
	SendInvite = 'sendInvite',
	CancelRSVP = 'cancelRSVP'
}

export const UserTableColumns: TableColumn[] = [
	{
		title: '',
		property: '',
		type: TableColumnType.CHECKBOX,
		action: '',
	},
	{
		title: '',
		property: 'avatar',
		type: TableColumnType.CUSTOM_JSX,
		class: 'table-avatar-td',
	},
	{
		title: 'First',
		property: 'firstName',
		type: TableColumnType.TEXT,
		action: 'name',
	},
	{
		title: 'Last',
		property: 'lastName',
		type: TableColumnType.TEXT,
		action: 'name',
	},
	{
		title: 'Number',
		property: 'fullMemberNumber',
		type: TableColumnType.TEXT,
		action: 'memberNumber',
	},
	{
		title: 'Email',
		property: 'email',
		type: TableColumnType.TEXT,
		action: 'email',
	},
	{
		title: 'Phone',
		property: 'primaryPhoneNumber',
		type: TableColumnType.TEXT,
		action: 'phone',
	},
	{
		title: 'Type',
		property: 'membershipTypeBadge',
		type: TableColumnType.BADGE,
		action: 'membershipType',
	},
	{
		title: '',
		property: '',
		type: TableColumnType.DROPDOWN,
		class: 'table-dropdown-menu',
		dropdownItems: [{
			action: UserTableActions.ViewUser,
			valueProperty: '_id',
			displayText: 'View User',
			icon: Feather.Eye,
		},
		{
			action: UserTableActions.MessageUser,
			valueProperty: '_id',
			displayText: 'Message User',
			icon: Feather.MessageSquare,
		},
		{
			action: UserTableActions.EditUser,
			valueProperty: '_id',
			displayText: 'Edit User',
			icon: Feather.Edit,
			isVisible: 'canEdit'
		},
		{
			action: UserTableActions.RemoveUser,
			valueProperty: '_id',
			displayText: 'Remove User',
			icon: Feather.Trash,
			isVisible: 'canDelete'
		},
		{
			action: UserTableActions.ResetPassword,
			valueProperty: '_id',
			displayText: 'Reset Password',
			icon: Feather.Lock,
			isVisible: 'canResetPassword'
		},
		{
			action: UserTableActions.SendInvite,
			valueProperty: '_id',
			displayText: 'Send Invite',
			icon: Feather.MessageCircle,
			isVisible: 'canInvite'
		},
		{
			action: UserTableActions.CancelRSVP,
			valueProperty: '_id',
			displayText: 'Cancel RSVP',
			icon: Feather.XCircle,
			isVisible: 'canCancelRSVP'
		}]
	}
]
