// External Dependencies
import * as Feather from 'react-feather'

// Internal Dependencies
import { TableColumn, DropdownItem } from '../Shared/Table'
import { TableColumnType } from '../Shared/Table/Primitives'
import { MessageType } from './'

export enum MessageActionType {
	EditMessage = 'editMessage',
	DeleteMessage = 'deleteMessage',
	SendTemplate = 'sendTemplate'
}

// ----------------------------------------------------------------------------------
// Message Table
// ----------------------------------------------------------------------------------

export const MessageTableColumns = (): TableColumn[] => {
	return [
		{
			title: 'Type',
			property: 'deliveryTypeIcon',
			type: TableColumnType.TEXT,
			action: 'deliveryType',
			class: 'delivery-icon-cell'
		},
		{
			title: 'Subject',
			property: 'title',
			type: TableColumnType.TEXT,
			class: 'message-title-cell',
			action: 'title',
		},
		{
			title: 'Status',
			property: 'statusWithColor',
			type: TableColumnType.BADGE,
			action: 'status',
		},
		{
			title: 'Date',
			property: 'deliveryDate',
			type: TableColumnType.DATE_TIME,
			action: 'deliveryDate',
		},
		{
			title: 'Recipients',
			property: 'messageRecipients',
			type: TableColumnType.AVATAR_LIST,
			action: 'messageRecipients',
		},
		{
			title: 'Sent',
			property: 'sentCount',
			type: TableColumnType.CUSTOM_JSX,
			action: 'sentCount',
		},
		{
			title: 'Delivered',
			property: 'deliveredCount',
			type: TableColumnType.CUSTOM_JSX,
			action: 'deliveredCount',
		},
		{
			title: 'Opened',
			property: 'openedCount',
			type: TableColumnType.CUSTOM_JSX,
			action: 'openedCount',
		},
		{
			title: '',
			property: '',
			type: TableColumnType.DROPDOWN,
			class: 'table-dropdown-menu',
			dropdownItems: messageDropdownItems()
		}
	]
}

const messageDropdownItems = (): DropdownItem[] => {
	const items = [
		{
			action: MessageActionType.DeleteMessage,
			valueProperty: '_id',
			displayText: `Delete`,
			icon: Feather.Trash,
			isVisible: 'canDelete',
		},
	]
	return items
}

// ----------------------------------------------------------------------------------
// Draft Table
// ----------------------------------------------------------------------------------

export const DraftTableColumns = (): TableColumn[] => {
	return [
		{
			title: 'Type',
			property: 'deliveryTypeIcon',
			type: TableColumnType.TEXT,
			action: 'deliveryType',
			class: 'delivery-icon-cell'
		},
		{
			title: 'Subject',
			property: 'title',
			type: TableColumnType.TEXT,
			class: 'message-title-cell',
			action: 'title',
		},
		{
			title: 'Date',
			property: 'deliveryDate',
			type: TableColumnType.DATE_TIME,
			action: 'deliveryDate',
		},
		{
			title: '',
			property: '',
			type: TableColumnType.DROPDOWN,
			class: 'table-dropdown-menu',
			dropdownItems: draftDropdownItems()
		}
	]
}

const draftDropdownItems = (): DropdownItem[] => {
	const items = [
		{
			action: MessageActionType.EditMessage,
			valueProperty: '_id',
			displayText: `Edit`,
			icon: Feather.Edit,
			isVisible: 'canEdit',
		},
		{
			action: MessageActionType.DeleteMessage,
			valueProperty: '_id',
			displayText: `Delete`,
			icon: Feather.Trash,
			isVisible: 'canDelete',
		},
	]
	return items
}

// ----------------------------------------------------------------------------------
// Template Table
// ----------------------------------------------------------------------------------

export const TemplateTableColumns = (): TableColumn[] => {
	return [
		{
			title: 'Type',
			property: 'deliveryTypeIcon',
			type: TableColumnType.TEXT,
			action: 'deliveryType',
			class: 'delivery-icon-cell'
		},
		{
			title: 'Name',
			property: 'title',
			type: TableColumnType.TEXT,
			class: 'message-title-cell',
			action: 'title',
		},
		{
			title: 'Date',
			property: 'deliveryDate',
			type: TableColumnType.DATE_TIME,
			action: 'deliveryDate',
		},
		{
			title: '',
			property: '',
			type: TableColumnType.DROPDOWN,
			class: 'table-dropdown-menu',
			dropdownItems: templateDropdownItems()
		}
	]
}

const templateDropdownItems = (): DropdownItem[] => {
	const items = [
		{
			action: MessageActionType.SendTemplate,
			valueProperty: '_id',
			displayText: `Use`,
			icon: Feather.Send,
			isVisible: 'sendTemplate',
		},
		{
			action: MessageActionType.EditMessage,
			valueProperty: '_id',
			displayText: `Edit`,
			icon: Feather.Edit,
			isVisible: 'canEdit',
		},
		{
			action: MessageActionType.DeleteMessage,
			valueProperty: '_id',
			displayText: `Delete`,
			icon: Feather.Trash,
			isVisible: 'canDelete',
		},
	]
	return items
}
