// External Dependencies
import * as core from 'club-hub-core'
import { Dispatch, Action } from 'redux'
import to from 'await-to-js'

// Internal Dependencies

// Actions
import { UserActions, fetchedAllUsers } from './user'
import { fetchedCalendarGroups, fetchedCalendars } from './calendar'
import { fetchedRestaurants } from './restaurant'
import { fetchedSections } from './section'

// API Helpers
import { GET } from '../helpers/api'

// Actions
export enum TypeKeys {
	FETCHED_ADMIN_STATE = 'FETCHED_ADMIN_STATE'
}

export type ActionTypes =
	| FetchedAdminStateAction

export interface FetchedAdminStateAction extends Action {
	type: TypeKeys.FETCHED_ADMIN_STATE,
	user: core.User.Model,
	club: core.Club.Model,
	calendars: core.Calendar.Model[],
	restaurants: core.Restaurant.Model[],
	sections: core.Section.Model[]
}

// -----------------------------------------------------------------------------
// Fetch Admin State
// -----------------------------------------------------------------------------

const fetchAdminState = () => async (dispatch: Dispatch<Action>) => {
	const [err, res] = await to(GET('/admin/admins/me'))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to fetch Admin State with error: ${err}`)
		throw err
	}

	// Dispatch the relevant pieces of the response
	// to their respective reducers
	dispatch(UserActions.authenticatedUser(res.user, res.club))
	dispatch(fetchedAllUsers(res.members))
	dispatch(fetchedCalendarGroups(res.club.calendarGroups))
	dispatch(fetchedCalendars({ calendars: res.calendars, count: res.calendars.length }))
	dispatch(fetchedRestaurants({ restaurants: res.restaurants, count: res.restaurants.length }))
	dispatch(fetchedSections({ sections: res.sections, count: res.sections.length }))
}

export const AdminActions = {
	fetchAdminState
}
