// External Dependencies
import * as core from 'club-hub-core'
import * as Feather from 'react-feather'

// Internal Dependencies
import { TableColumn } from '../../Shared/Table'
import { TableColumnType } from '../../Shared/Table/Primitives'

export interface SectionPageTableItem extends core.Section.Page {
	statusWithColor: { title: string, color: string },
	createdByUser: core.User.Model,
}

export enum SectionPageTableActions {
	ViewPage = 'viewSectionPage',
	RenamePage = 'renameSectionPage',
	EditPage = 'editSectionPage',
	RemovePage = 'removeSectionPage'
}

export const SectionPageTableColumns: TableColumn[] = [
	{
		title: '',
		property: '',
		type: TableColumnType.CHECKBOX,
		action: '',
	},
	{
		title: 'Name',
		property: 'name',
		type: TableColumnType.TEXT,
		action: 'name'
	},
	{
		title: 'Status',
		property: 'statusWithColor',
		type: TableColumnType.BADGE,
		action: 'status',
	},
	{
		title: 'Created By',
		property: 'createdByUser',
		type: TableColumnType.AVATAR_AND_NAME,
		action: 'createdBy',
	},
	{
		title: 'Last Updated',
		property: 'updatedAt',
		type: TableColumnType.DATE_TIME,
		action: 'lastUpdated'
	},
	{
		title: '',
		property: '',
		type: TableColumnType.DROPDOWN,
		class: 'table-dropdown-menu',
		dropdownItems: [
			{
				action: SectionPageTableActions.ViewPage,
				valueProperty: '_id',
				displayText: 'View',
				icon: Feather.Eye
			},
			{
				action: SectionPageTableActions.RenamePage,
				valueProperty: '_id',
				displayText: 'Rename Page',
				icon: Feather.Edit2,
			},
			{
				action: SectionPageTableActions.EditPage,
				valueProperty: '_id',
				displayText: 'Edit Page',
				icon: Feather.Edit
			},
			{
				action: SectionPageTableActions.RemovePage,
				valueProperty: '_id',
				displayText: 'Remove Page',
				icon: Feather.Trash
			},
		]
	}
]
