// External Dependencies
import * as Yup from 'yup'

// Internal Dependencies
import { FormInput, FormInputType, ReactSelectItem, InputSelectionItem } from '../../Shared/Form'
import * as Constants from '../../../constants/index'

export interface ServiceFormState {
	member: ReactSelectItem
	vehicle: ReactSelectItem
	date: Date
	provider: ReactSelectItem
	notes: string
	type: InputSelectionItem,
}

const stringValidator = Yup.string().required(Constants.REQUIRED_FIELD)

export const AdminServiceFormInputs = (members: ReactSelectItem[], vehicles: ReactSelectItem[], providers: ReactSelectItem[], serviceTypes: ReactSelectItem[]): FormInput[] => [
	{
		title: 'Member',
		property: 'member',
		type: FormInputType.SELECT,
		size: 6,
		placeholder: 'Select Member...',
		selectItems: members,
		select_isClearable: true,
		defaultValue: '',
		validation: stringValidator
	},
	{
		title: 'Vehicle',
		property: 'vehicle',
		type: FormInputType.SELECT,
		size: 6,
		placeholder: 'Select Vehicle...',
		selectItems: vehicles,
		select_isClearable: true,
		defaultValue: '',
		validation: stringValidator
	},
	{
		title: 'Date',
		property: 'date',
		type: FormInputType.DATE,
		size: 6,
		placeholder: 'Select Date...',
		defaultValue: '',
		validation: stringValidator
	},
	{
		title: 'Provider',
		property: 'provider',
		type: FormInputType.SELECT,
		size: 6,
		placeholder: 'Select Provider...',
		selectItems: providers,
		defaultValue: '',
	},
	{
		title: 'Type',
		property: 'type',
		type: FormInputType.GROUPED_SELECT,
		size: 6,
		placeholder: 'Select Type...',
		selectItems: serviceTypes,
		defaultValue: null
	},
	{
		title: 'Notes',
		property: 'notes',
		type: FormInputType.TEXT_AREA,
		placeholder: 'Enter Notes...',
		defaultValue: ''
	}
]

export const CustomerServiceFormInputs = (vehicles: ReactSelectItem[], providers: ReactSelectItem[], serviceTypes: ReactSelectItem[]): FormInput[] => [
	{
		title: 'Date',
		property: 'date',
		type: FormInputType.DATE,
		size: 6,
		placeholder: 'Select Date...',
		defaultValue: '',
		validation: stringValidator
	},
	{
		title: 'Vehicle',
		property: 'vehicle',
		type: FormInputType.SELECT,
		size: 6,
		placeholder: 'Select Vehicle...',
		selectItems: vehicles,
		defaultValue: '',
		validation: stringValidator
	},
	{
		title: 'Provider',
		property: 'provider',
		type: FormInputType.SELECT,
		size: 6,
		placeholder: 'Select Provider...',
		selectItems: providers,
		defaultValue: '',
		validation: stringValidator
	},
	{
		title: 'Type',
		property: 'type',
		type: FormInputType.SELECT,
		size: 6,
		placeholder: 'Select Type...',
		selectItems: serviceTypes,
		defaultValue: null
	},
	{
		title: 'Notes',
		property: 'notes',
		type: FormInputType.TEXT_AREA,
		placeholder: 'Enter Notes...',
		defaultValue: ''
	}
]
