// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as RS from 'reactstrap'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { DropResult } from 'react-beautiful-dnd'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies

// Table
import { SectionPageTableItem, SectionPageTableColumns, SectionPageTableActions } from './table'

// Components
import { HeaderButton, ButtonType } from '../../Shared/ButtonGroup'
import PageHeaderComponent from '../../Shared/PageHeader'
import ModalComponent from '../../Shared/Modal'
import TableComponent from '../../Shared/Table'
import DimmedLoader from '../../Shared/DimmedLoader'
import PageFormModal from './PageForm'
import BackHeader from '../../Shared/BackHeader'

// Actions
import { SectionActions, AlertActions } from '../../../actions/index'

// State
import { RootReducerState } from '../../../reducers'

// Helpers
import { setStateAsync } from '../../../helpers/promise'
import { publicationStatusBadge } from '../../../helpers/badge'
import { sortByOrderingIndex } from '../../../helpers/array'
import * as Constants from '../../../constants'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	loading: true,
	error: false,
	showingNewPageModal: false,
	pageToRename: null as string | null,
	pageToUpdate: null as string | null,
	pageToDelete: null as string | null,
	section: null as core.Section.Model | null,
	pageIndex: 0,
	pageOrdering: null as string[] | null,
	sectionTab: null as string
}

type Props = ConnectedActions & ConnectedState & RouteComponentProps
type State = typeof initialState

class ClubSectionPageTableComponent extends React.Component<Props, State> {
	private pageSize: number

	constructor(props: Props) {
		super(props)
		this.pageSize = 20

		// Get the Section
		const sectionID = (props.match.params as any).section_id
		const sections = oc(props).sectionState.sections([])
		const section = sections.find((s) => s._id === sectionID)

		this.state = { ...initialState, section }
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		await setStateAsync(this, { loading: false })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Dropdown Actions
	// ----------------------------------------------------------------------------------

	/**
	 * Determines which action to take, based on which dropdown item
	 * the user selected in the table's dropdown menu
	 */
	handleTableDropdownAction = (e: any) => {
		const [action, value] = e
		switch (action) {
			case SectionPageTableActions.ViewPage:
				return this.handleViewSectionPage(value)
			case SectionPageTableActions.RenamePage:
				return this.handleRenameSectionPage(value)
			case SectionPageTableActions.EditPage:
				return this.handleEditSectionPage(value)
			case SectionPageTableActions.RemovePage:
				return this.handleDeleteSectionPage(value)
			default:
				break
		}
	}

	/**
	 * When viewing a Section Page, navigate to the Pages table
	 */
	handleViewSectionPage = (pageID: string) => {
		const section = oc(this).props.sectionState.sections([]).find((s) => s._id === this.state.section._id)
		const page = oc(section).pages([]).find((p) => `${p._id}` === pageID)
		const routeParams = { page: page.name }
		const routeLocation = {
			pathname: Constants.VIEW_SECTION_ROUTE.replace(':section_id', `${section._id}`),
			search: queryString.stringify(routeParams)
		}
		this.props.history.push(routeLocation)
	}

	/**
	 * When renaming a Section Page
	 */
	handleRenameSectionPage = async (pageID: string) => {
		await setStateAsync(this, { pageToRename: pageID })
	}

	/**
	 * When editing a Section Page
	 */
	handleEditSectionPage = async (pageID: string) => {
		await setStateAsync(this, { pageToUpdate: pageID })
		const page = oc(this).state.section.pages([]).find((sectionPage) => `${sectionPage._id}` === pageID)
		const sectionID = `${this.state.section._id}`
		const pageFormRoute = Constants.UPDATE_SECTION_PAGE_ROUTE.replace(':section_id', sectionID)
		const pageFormParams = {
			pageName: page.name,
			pageID: pageID
		}
		const pageFormLocation = {
			pathname: pageFormRoute,
			search: queryString.stringify(pageFormParams)
		}
		this.props.history.push(pageFormLocation)
	}

	/**
	 * When attempting to delete a Section Page, display the
	 * Section Page deletion confirmation modal
	 */
	handleDeleteSectionPage = async (pageID: string) => {
		await setStateAsync(this, { pageToDelete: pageID })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Modal Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Handles clicks on the `Save` button in the New Section modal.
	 */
	handleCreateNewSectionPage = async (pageName: string) => {
		await setStateAsync(this, { loading: true, showingNewPageModal: false })

		const sectionID = `${this.state.section._id}`
		const pageFormRoute = Constants.CREATE_SECTION_PAGE_ROUTE.replace(':section_id', sectionID)
		const pageFormParams = {
			pageName: pageName,
		}
		const pageFormLocation = {
			pathname: pageFormRoute,
			search: queryString.stringify(pageFormParams)
		}
		this.props.history.push(pageFormLocation)
	}

	/**
	 * Handles clicks on the cancel button in the Section Page modal.
	 */
	handleCancelNewSectionPageModal = async () => {
		await setStateAsync(this, { showingNewPageModal: false })
	}

	/**
	 * Handles clicks on the `Save` button in the New Section Page modal.
	 */
	handleUpdateSectionPage = async (pageName: string) => {
		await setStateAsync(this, { loading: true })

		const sectionID = `${this.state.section._id}`
		const updatePayload = this.buildUpdatePagePayload(this.state.pageToRename, pageName)
		const [updateErr] = await to(this.props.updateSectionPage(sectionID, updatePayload) as any)
		if (updateErr) {
			this.props.fireFlashMessage(`Failed to rename Page with error: ${updateErr}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, pageToRename: null })
			return
		}

		await this.updateSectionState()
		this.props.fireFlashMessage(`Successfully renamed Page`, Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, pageToRename: null })
	}

	/**
	 * Handles clicks on the cancel button in the Section Page modal.
	 */
	handleCancelUpdateSectionPageModal = async () => {
		await setStateAsync(this, { pageToUpdate: null })
	}

	/**
	 * Make the API call to delete the Section Page, when the
	 * user confirms the deletion via the modal
	 */
	executeSectionDeletion = async () => {
		const sectionID = this.state.section._id as any
		const pageID = this.state.pageToDelete
		const [deleteErr] = await to(this.props.deleteSectionPage(sectionID, pageID) as any)
		if (deleteErr) {
			this.props.fireFlashMessage(`Failed to delete Page. ${deleteErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, pageToDelete: null })
			return
		}

		await this.updateSectionState()
		await setStateAsync(this, { loading: false, pageToDelete: null })
	}

	/**
	 * Hide the deletion modal when the user cancels it
	 */
	closeDeleteSectionPageModal = async () => {
		await setStateAsync(this, { pageToDelete: null })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Handle table pagination
	 */
	handlePage = async (data: any) => {
		const { pageIndex } = this.state

		// Check if the new page is different that the current one
		if (data.selected !== pageIndex) {
			await setStateAsync(this, { pageIndex: data.selected })
		}
	}

	/**
	 * Handle a column header of the table being selected
	 */
	handleTableHeaderAction = (e: any) => {
		// TODO: Add Functionality
	}

	/**
	 * Handle a table row being clicked
	 */
	handleTableRowClicked = (item: SectionPageTableItem) => {
		return this.handleViewSectionPage(`${item._id}`)
	}

	handleTableReorder = async (result: DropResult) => {
		await this.updatePageOrdering(result)

		// Save the ordering to the DB
		const updatedPages = oc(this).state.section.pages([]).map((p) => {
			const pageIDs = oc(this).state.pageOrdering([])
			const pageOrderIndex = pageIDs.findIndex((pageID) => pageID === `${p._id}`)
			const orderingIndex = (pageOrderIndex === -1) ?
				oc(this).state.section.pages([]).length :
				pageOrderIndex + (this.pageSize * this.state.pageIndex)
			return { ...p, orderingIndex }
		})

		const sectionID = `${this.state.section._id}`
		const [updateErr] = await to(this.props.updateSectionPage(sectionID, updatedPages) as any)
		if (updateErr) {
			this.props.fireFlashMessage(`Failed to update Pages. ${updateErr.message}`, Constants.FlashType.DANGER)
			return
		}

		await this.updateSectionState()
		this.props.fireFlashMessage(`Successfully updated Pages.`, Constants.FlashType.SUCCESS)
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Header Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Determine which action to take, based on the header
	 * button that the user selected
	 */
	handleHeaderButtonAction = (e: any) => {
		const action = e.target.value
		switch (action) {
			case 'createPage':
				this.handleCreateButton()
				break
			default:
				break
		}
	}

	handleCreateButton = async () => {
		await setStateAsync(this, { showingNewPageModal: true })
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	updateSectionState = async () => {
		const refreshedSections = oc(this).props.sectionState.sections([])
		const refreshedSection = refreshedSections.find((s) => s._id === this.state.section._id)
		await setStateAsync(this, { section: refreshedSection })
	}

	/**
	 * Builds a Section Page payload.
	 */
	buildUpdatePagePayload = (pageID: string, name: string): core.Section.Page => {
		const section = oc(this).state.section()
		const pages = oc(section).pages([])
		const pageToUpdate = pages.find((p) => `${p._id}` === pageID)
		const creatorID = oc(this).props.userState.loggedInUser._id()
		const payload: core.Section.Page = {
			...pageToUpdate,
			name: name,
			createdBy: creatorID as any,
			updatedBy: creatorID as any
		}
		return payload
	}

	updatePageOrdering = async (result: DropResult) => {
		const pages = [...oc(this).state.section.pages([])]

		const pagesIDs = (this.state.pageOrdering) ?
			[...this.state.pageOrdering] :
			pages.sort(sortByOrderingIndex).map((p) => `${p._id}`)

		const { destination, source, draggableId } = result
		if (!destination) {
			await setStateAsync(this, { pageOrdering: [...pagesIDs] })
			return
		}

		if (destination.droppableId === source.droppableId &&
			destination.index === source.index) {
			await setStateAsync(this, { pageOrdering: [...pagesIDs] })
			return
		}

		pagesIDs.splice(source.index, 1)
		pagesIDs.splice(destination.index, 0, draggableId)
		await setStateAsync(this, { pageOrdering: [...pagesIDs] })
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildHeaderButtons = (): HeaderButton[] => {
		return [
			{
				action: 'createPage',
				type: ButtonType.DEFAULT,
				text: 'New Page',
				class: 'btn-primary',
			},
		]
	}

	buildPageHeader = () => {
		const title = `${this.state.section.name} - Pages`
		// Checks if the current Section is public / private to determine the 'type' query param for the go back route.
		const sectionTypeQueryParam = (oc(this).state.section.public(false)) ? 'public' : 'private'
		const goBackRoute = `${Constants.CLUB_INFO_ROUTE}?type=${sectionTypeQueryParam}`
		return (
			<div className='page-header-with-back-btn'>
				<BackHeader to={goBackRoute} backTitle={'Sections'} />
				<PageHeaderComponent
					pageTitle={title}
					buttons={this.buildHeaderButtons()}
					buttonHandler={this.handleHeaderButtonAction}
				/>
			</div>
		)
	}

	buildNewSectionModal = () => {
		if (!this.state.showingNewPageModal) { return null }
		return (
			<PageFormModal
				submitButtonHandler={this.handleCreateNewSectionPage}
				cancelButtonHandler={this.handleCancelNewSectionPageModal}
			/>
		)
	}

	buildRenamePageModal = () => {
		if (!this.state.pageToRename) { return null }
		const pages = oc(this).state.section.pages([])
		const pageToRename = pages.find((p) => `${p._id}` === this.state.pageToRename)
		const formResource = { ...pageToRename }
		return (
			<PageFormModal
				title={'Rename Page'}
				formResource={formResource}
				submitButtonHandler={this.handleUpdateSectionPage}
				cancelButtonHandler={this.handleCancelUpdateSectionPageModal}
			/>
		)
	}

	buildDeleteConfirmationModal = () => {
		if (!this.state.pageToDelete) { return null }
		return (
			<ModalComponent
				modalTitle={'Delete Page'}
				primaryMessage={'Are you sure you want to delete this Page?'}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.closeDeleteSectionPageModal}
				submitButtonName={'Confirm'}
				submitButtonHandler={this.executeSectionDeletion}
			/>
		)
	}

	buildSectionPageTable = () => {
		const users = oc(this).props.userState.users([])

		const pages = oc(this).state.section.pages([])
		const pageCount = pages.length

		const pagesForTable: SectionPageTableItem[] = pages.map((page) => {
			const status = oc(page).content.status()
			return {
				...page,
				statusWithColor: publicationStatusBadge(status),
				createdByUser: users.find((user) => `${user._id}` === `${page.createdBy}`)
			}
		}).sort(sortByOrderingIndex)

		// Do we need to reorder the list?
		let orderedPages = []
		if (this.state.pageOrdering) {
			for (const pageID of this.state.pageOrdering) {
				const page = pagesForTable.find((p) => `${p._id}` === pageID)
				if (!isNullOrUndefined(page)) {
					orderedPages.push(page)
				}
			}
		} else {
			orderedPages = pagesForTable
		}

		return (
			<TableComponent<SectionPageTableItem>
				columns={SectionPageTableColumns}
				rowItems={orderedPages}
				showPaging={true}
				currentPage={this.state.pageIndex}
				pageHandler={this.handlePage}
				totalResults={pageCount}
				pageSize={this.pageSize}
				actionHandler={this.handleTableHeaderAction}
				dropdownHandler={this.handleTableDropdownAction}
				onTableRowClick={this.handleTableRowClicked}
				isLoading={this.state.loading}
				enableDragging={true}
				onDrop={this.handleTableReorder}
			/>
		)
	}

	render() {
		if (this.state.loading) { return <DimmedLoader component={null} isLoading={true} /> }

		return (
			<RS.Row className='sectionPageComponent justify-content-center section-view-row'>
				<RS.Col>
					{this.buildPageHeader()}

					<div className='section-page-content-container'>
						{this.buildSectionPageTable()}
					</div>

					{this.buildNewSectionModal()}
					{this.buildRenamePageModal()}
					{this.buildDeleteConfirmationModal()}
				</RS.Col>
			</RS.Row>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	sectionState: state.section,
	userState: state.user,
})

const mapDispatchToProps = {
	...SectionActions,
	...AlertActions
}

export default connect(mapStateToProps, mapDispatchToProps)(ClubSectionPageTableComponent)
