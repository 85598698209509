// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies

// Actions
import { UserActions, AlertActions } from '../../actions'

// State
import { RootReducerState } from '../../reducers'
import { usersByIDSelector } from '../../reducers/user'
import { messagesByIDSelector } from '../../reducers/message'

// Components
import UserComponent from '../User'
import DimmedLoader from '../Shared/DimmedLoader'

// Helpers
import * as Constants from '../../constants'
import { setStateAsync } from '../../helpers/promise'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps
interface RouteParams { message_id: string, notif_status: core.Notification.Status }

const initialState = {
	error: false,
	loading: true,
	messageID: null as string | null,
	status: null as core.Notification.Status | null,
	userIDs: [] as string[],
}

type Props = RouteComponentProps<RouteParams> & ConnectedActions & ConnectedState
type State = typeof initialState

export class MessageUsersComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)

		// Get the state from the Router Props
		const { message_id, notif_status } = props.match.params

		this.state = {
			...initialState,
			messageID: message_id,
			status: notif_status
		}
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		const { fetchUsersForMessage, fireFlashMessage } = this.props
		const { messageID, status } = this.state

		const [err, userIDs] = await to<string[]>(fetchUsersForMessage(messageID, status) as any)
		if (err) {
			fireFlashMessage(`Failed to fetch Users for Message.`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}
		await setStateAsync(this, { loading: false, userIDs })
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildUserComponent = () => {
		const { messagesByID } = this.props
		const { messageID, status } = this.state

		const messageTitle = oc(messagesByID)[messageID].title('Message')
		const title = `${messageTitle} - Users (${status})`
		const users = this.getUsers()

		return <UserComponent title={title} users={users} />
	}

	render() {
		const { loading } = this.state

		return (
			<DimmedLoader
				component={this.buildUserComponent()}
				isLoading={loading}
			/>
		)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	getUsers = (): core.User.Model[] => {
		const { usersByID } = this.props
		const { userIDs } = this.state

		return userIDs.map((userID: string) => usersByID[userID])
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	usersByID: usersByIDSelector(state),
	messagesByID: messagesByIDSelector(state),
})

const mapDispatchToProps = {
	...UserActions,
	...AlertActions
}

const enhance = compose<React.ComponentType>(
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(MessageUsersComponent)
