// External Dependencies
import * as Yup from 'yup'

// Internal Dependencies
import { FormInput, FormInputType } from '../../../Shared/Form'
import * as Constants from '../../../../constants/index'

export interface LotteryFormState {
	date: Date,
	count: number
}

export const DateRangeFormInputs: FormInput[] = [
	{
		title: '',
		property: 'alert',
		type: FormInputType.ALERT,
		size: 12,
		placeholder: 'Please confirm the details below to create your lottery request.',
	},
	{
		title: 'Date & Time',
		property: 'date',
		type: FormInputType.DATE_TIME,
		size: 6,
		placeholder: 'Enter Name...',
		defaultValue: '',
		validation: Yup.string().required(Constants.REQUIRED_FIELD)
	},
	{
		title: 'Consecutive Tee Times',
		property: 'count',
		type: FormInputType.NUMBER,
		size: 6,
		placeholder: 'Select Start...',
		defaultValue: null,
		validation: Yup.date().nullable().required(Constants.REQUIRED_FIELD)
	}
]
