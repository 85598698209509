/**
 * Function copies a piece of text to the client's clipboard
 * @param text
 */
export default function (text: string) {
	const textField = document.createElement('textarea')
	textField.innerText = text
	document.body.appendChild(textField)
	textField.select()
	document.execCommand('copy')
	textField.remove()
}
