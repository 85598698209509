// External Dependencies
import * as core from 'club-hub-core'
import * as Feather from 'react-feather'

// Internal Dependencies
import { TableColumn } from '../Shared/Table'
import { TableColumnType } from '../Shared/Table/Primitives'

export interface PostTableItem extends core.Post.Model {
	statusWithColor: { title: string, color: string }
}

export const PostTableColumns: TableColumn[] = [
	{
		title: '',
		property: '',
		type: TableColumnType.CHECKBOX,
		action: '',
	},
	{
		title: 'Title',
		property: 'title',
		type: TableColumnType.TEXT,
		action: 'title',
		class: 'post-title-cell'
	},
	{
		title: 'Status',
		property: 'statusWithColor',
		type: TableColumnType.BADGE,
		action: 'status',
	},
	{
		title: 'Publication Date',
		property: 'publicationDate',
		type: TableColumnType.DATE_TIME,
		class: 'message-title-cell',
		action: 'date',
	},
	{
		title: 'Type',
		property: 'typeWithColor',
		type: TableColumnType.BADGE,
		action: 'type',
		hideOnMobile: false,
	},
	{
		title: 'Author',
		property: 'author',
		type: TableColumnType.AVATAR_AND_NAME,
		action: 'author',
		hideOnMobile: true,
	},
	{
		title: '',
		property: '',
		type: TableColumnType.DROPDOWN,
		class: 'table-dropdown-menu',
		dropdownItems: [
			{
				action: 'viewPost',
				valueProperty: '_id',
				displayText: 'View',
				icon: Feather.Eye,
			},
			{
				action: 'messagePost',
				valueProperty: '_id',
				displayText: 'Message',
				icon: Feather.MessageSquare,
			},
			{
				action: 'editPost',
				valueProperty: '_id',
				displayText: 'Edit Post',
				icon: Feather.Edit
			},
			{
				action: 'removePost',
				valueProperty: '_id',
				displayText: 'Remove Post',
				icon: Feather.Trash
			}
		]
	}
]
