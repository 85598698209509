// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { FormikProps, FormikActions, FormikValues } from 'formik'
import { isNullOrUndefined } from 'util'
import { to } from 'await-to-js'
import { oc } from 'ts-optchain'

// Internal Dependencies

// Actions
import { AlertActions, ClubActions } from '../../../actions/index'

// State
import { RootReducerState } from '../../../reducers'

// UI
import ModalComponent from '../../Shared/Modal'

// Form
import { LocationModalInputs, LocationFormState } from './form'
import { FormikComponent, BuildWrappedForm } from '../../Shared/Formik'

// Helpers
import * as Constants from '../../../constants'
import { setStateAsync } from '../../../helpers/promise'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

interface ComponentProps {
	modalTitle?: string
	locationToEdit?: core.SubModels.Location.Model
	cancelButtonHandler: () => any
	submitButtonHandler: (location: core.SubModels.Location.Model) => any
}

const initialState = {
	creatingAddress: false,
	existingName: null as string | null
}

type Props = ConnectedActions & ComponentProps & ConnectedState
type State = typeof initialState

/**
 * The `NewLocationModal` provides UI for creating a new location.
 */
class NewLocationModal extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			...initialState,
			creatingAddress: !isNullOrUndefined(props.locationToEdit)
		}
	}

	// ----------------------------------------------------------------------------------
	// Handlers
	// ----------------------------------------------------------------------------------

	handleAddAddress = async (formikProps: FormikProps<LocationFormState>) => {
		// We store whatever the value of 'name' was on the form here, so that
		// the field will not be cleared when the form re-initializes with the address fields
		await setStateAsync(this, { creatingAddress: true, existingName: formikProps.values.name })
	}

	/**
	 * Handles submit button clicks.
	 */
	handleSubmitButton = async (formState: LocationFormState, actions: FormikActions<FormikValues>) => {
		// Keep track of existing Locations
		const existingLocations = oc(this).props.loggedInClub.locations([])
		const existingLocationIDs = existingLocations.map((loc) => loc._id)

		// Determine if we are using the default address
		const primaryLocation = existingLocations.find((loc) => loc.primary)
		const locationPayload: core.SubModels.Location.Model = (this.state.creatingAddress) ?
			({
				...formState,
				state: formState.state.value,
			}) :
			({
				name: formState.name,
				address1: primaryLocation.address1,
				address2: primaryLocation.address2,
				city: primaryLocation.city,
				state: primaryLocation.state,
				zip: primaryLocation.zip
			})

		const saveFunction = (!isNullOrUndefined(this.props.locationToEdit)) ?
			this.props.updateClubLocation({ ...locationPayload, _id: this.props.locationToEdit._id }) :
			this.props.createClubLocation(locationPayload)

		const [err] = await to(saveFunction as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to save Location. ${err.message}`, Constants.FlashType.DANGER)
			actions.setSubmitting(false)
			return
		}

		// Check to see if any new locations were created
		const locations = oc(this).props.loggedInClub.locations([])
		const newLocation = locations.find((loc) => isNullOrUndefined(existingLocationIDs.find((existingID) => existingID === loc._id)))

		actions.setSubmitting(false)
		this.props.fireFlashMessage(`Location saved.`, Constants.FlashType.SUCCESS)
		this.props.submitButtonHandler(newLocation)
	}

	// ----------------------------------------------------------------------------------
	// Component Builders
	// ----------------------------------------------------------------------------------

	buildAddAddressButton = (formikProps: FormikProps<LocationFormState>) => {
		if (this.state.creatingAddress) { return null }
		return (
			<button
				className='btn btn-primary btn-block'
				onClick={() => this.handleAddAddress(formikProps)}
			>
				Add Address
			</button>
		)
	}

	buildModalContent = (formikProps: FormikProps<LocationFormState>) => {
		const formInputs = (!this.state.creatingAddress) ?
			[LocationModalInputs[0]] :
			LocationModalInputs
		const wrappedFormProps = { inputs: formInputs }
		return (
			<>
				{BuildWrappedForm(wrappedFormProps, formikProps)}
				{this.buildAddAddressButton(formikProps)}
			</>
		)
	}

	buildModal = (formikProps: FormikProps<LocationFormState>) => {
		const modalTitle = oc(this).props.modalTitle('New Location')
		return (
			<ModalComponent
				modalTitle={modalTitle}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.props.cancelButtonHandler}
				submitButtonName={'Save'}
				submitButtonHandler={formikProps.submitForm}
				buildInputs={() => this.buildModalContent(formikProps)}
				submitting={formikProps.isSubmitting}
			/>
		)
	}

	public render() {
		// If the User is not creating an address with the Location,
		// then the only input we care about is the 'name' field
		const formInputs = (!this.state.creatingAddress) ?
			[LocationModalInputs[0]] :
			LocationModalInputs

		// We are allowing this form to re-initialize, because the form state
		// and validation schema will change if the User decides to add an address.
		const formResource = (this.props.locationToEdit) ?
			this.props.locationToEdit :
			{ name: this.state.existingName }
		return (
			<FormikComponent
				inputs={formInputs}
				formResource={formResource}
				enableReinitialize={true}
				onSubmit={this.handleSubmitButton}
				render={this.buildModal}
			/>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	loggedInClub: state.club.loggedInClub
})

const mapDispatchToProps = {
	...AlertActions,
	...ClubActions
}

const enhance = compose<React.ComponentType<ComponentProps>>(
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(NewLocationModal)
