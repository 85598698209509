// External Dependencies
import React from 'react'
import * as RS from 'reactstrap'
import * as core from 'club-hub-core'
import to from 'await-to-js'

// Internal Dependencies
import { NotifSettingKey } from '../GlobalNotifSettingsTable'

// Helpers
import { setStateAsync } from '../../../helpers/promise'

export interface OnToggleParams {
	settingKey: NotifSettingKey,
	groupID?: string,
	notifID?: string
}

interface ComponentProps {
	id: string
	className: string
	notifPrefID: string
	settingKey: NotifSettingKey
	isEnabled: boolean
	isDisabled: boolean
	onToggleNotif: (params: OnToggleParams) => Promise<core.Response.NotificationPreferenceResponse>
	groupID?: string
}

const initialState = {
	isChecked: true,
	pendingToggle: false,
}

type Props = ComponentProps
type State = typeof initialState

export class NotifSettingToggle extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = { ...initialState, isChecked: props.isEnabled }
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	componentDidUpdate = async (prevProps: Props, prevState: State) => {
		if (prevProps.isDisabled !== this.props.isDisabled) {
			await setStateAsync(this, { isChecked: this.props.isEnabled })
		}

		if (prevProps.isEnabled !== this.props.isEnabled) {
			await setStateAsync(this, { isChecked: this.props.isEnabled })
		}
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	handleToggleChange = async () => {
		const { groupID, settingKey, notifPrefID, onToggleNotif } = this.props
		const { isChecked, pendingToggle } = this.state

		// If there is a pending toggle action in-flight, we return out of here
		if (pendingToggle) { return }

		// Indicate that there is a pending toggle action taking place
		await setStateAsync(this, { pendingToggle: true })

		// Update the local state value of the toggle switch
		const newToggleState: boolean = !isChecked
		await setStateAsync(this, { isChecked: newToggleState })

		// Call the onToggleNotif handler
		const [err] = await to(onToggleNotif({groupID, settingKey, notifID: notifPrefID}))
		if (err) {
			// If we hit an error in the onToggleNotif handler
			// we will revert the toggle state change
			await setStateAsync(this, { isChecked: !newToggleState })
		}

		// The action is finished, so update the state
		await setStateAsync(this, { pendingToggle: false })
	}

	render() {
		const { id, className, isDisabled } = this.props
		const { isChecked, pendingToggle } = this.state
		return (
			<RS.CustomInput
				id={id}
				type={'switch'}
				className={className}
				checked={isChecked}
				disabled={isDisabled || pendingToggle}
				onChange={() => this.handleToggleChange()}
			/>
		)
	}
}
