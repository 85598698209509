// External Dependencies
import * as React from 'react'
import * as Feather from 'react-feather'

interface TimeRowProps {
	timeString: Date
	available: boolean
	submitHandler: (time: Date) => Promise<void>
}

/**
 * Returns a row used in the time selection modal.
 * @param props PassedPropsInterface
 */
const TimeRow: React.FC<TimeRowProps> = (props: TimeRowProps) => {

	const options = { hour: 'numeric', minute: 'numeric' }
	const timeString = new Date(props.timeString).toLocaleTimeString('en-US', options)
	const cellText = props.available ? timeString : 'Unavailable'
	const availabilityClass = props.available ? 'available' : 'unavailable'
	const onClick = props.available ? () => props.submitHandler(props.timeString) : null

	return (
		<div className={`time-select-row ${availabilityClass}`} onClick={onClick}>
			<div className='date-time-container'>
				<div className='time-icon'>
					<Feather.Clock size={20}/>
				</div>

				<div className='date-string'>
					{cellText}
				</div>
			</div>
		</div>
	)
}

export default TimeRow
