// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { to } from 'await-to-js'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'

// Internal Dependencies

// Components
import FormModal from '../Formik/FormModal'

// Actions
import { ClubActions, AlertActions } from '../../../actions/index'

// State
import { RootReducerState } from '../../../reducers'
import { ClubResourceName } from '../../../reducers/club'

// Helpers
import { setStateAsync } from '../../../helpers/promise'
import * as Constants from '../../../constants'

// Form
import { EditTypeForm, EditTypeFormInputs } from './form'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

interface ComponentProps {
	typeName: string
	resourceType: ClubResourceName
	existingTypes: core.Club.ResourceType[]
	onSave: (type?: core.Club.ResourceType) => any
	onClose: any
}

const initialState = {
	inputText: '',
	editingID: '',
	addingType: false,
	loading: false,
	typeToEdit: '' as any
}

type Props = ConnectedActions & ConnectedState & ComponentProps
type State = typeof initialState

class TypeCreationModal extends React.Component<Props, State> {

	public formContent: core.Club.ResourceType[]
	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
		this.formContent = props.existingTypes
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	// ----------------------------------------------------------------------------------
	// Event Handlers - Modal Event Handlers
	// ----------------------------------------------------------------------------------

	executeTypeSave = async (title: string, color: string) => {
		const { resourceType, createClubType, updateClubType, fireFlashMessage, } = this.props
		const { typeToEdit } = this.state

		await setStateAsync(this, { loading: true })

		const typePath = `resources.${resourceType}.types`
		let type: core.Club.ResourceType = { title, color }
		type = typeToEdit ? { ...type, _id: typeToEdit } : type
		const saveFunction = (typeToEdit) ? updateClubType(typePath, type) : createClubType(type, typePath)
		const [err, res] = await to(saveFunction as any)
		if (err) {
			fireFlashMessage(`Problem trying to update Type. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, typeToEdit: null })
			return
		}

		const types = oc(this).props.loggedInClub.resources[this.props.resourceType].types([])
		const newType = types.find((t) => (t.color === type.color) && (t.title === t.title))

		fireFlashMessage(`Successfully updated Type.`, Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, typeToEdit: null })

		this.props.onSave(newType)
	}

	handleCancel = async () => {
		this.props.onClose()
	}

	buildEditModal = () => {
		const { resourceType } = this.props
		const { typeToEdit } = this.state

		const clubTypes = oc(this).props.loggedInClub.resources[resourceType].types([])
		const existingType = clubTypes.find((clubType) => `${clubType._id}` === typeToEdit)
		const modalTitle = (existingType) ? 'Edit Type' : 'New Type'

		return (
			<FormModal<core.Club.ResourceType, EditTypeForm>
				modalTitle={modalTitle}
				formSpec={EditTypeFormInputs}
				formResource={existingType}
				submitButtonHandler={(values) => {
					this.executeTypeSave(values.title, values.color)
				}}
				cancelButtonHandler={this.props.onClose}
			/>
		)
	}

	render() {
		return this.buildEditModal()
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	loggedInClub: state.club.loggedInClub
})

const mapDispatchToProps = {
	...ClubActions,
	...AlertActions
}

const enhance = compose<React.ComponentType<ComponentProps>>(
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(TypeCreationModal)
