// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import * as Feather from 'react-feather'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'

// Internal Dependencies

// Helpers
import { setStateAsync } from '../../../helpers/promise'

interface ComponentProps {
	searchChangeHandler?: (term: string) => any
	searchEnterHandler?: (event: any) => any
	searchClearHandler?: () => any
	defaultValue?: string
}

const initialState = {
	searchTerm: ''
}

type Props = ComponentProps
type State = typeof initialState

class SearchComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = { ...initialState, searchTerm: oc(this).props.defaultValue('') }
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	handleClear = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		await setStateAsync(this, { searchTerm: '' })

		if (!this.props.searchClearHandler) { return }
		return this.props.searchClearHandler()
	}

	handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const term = event.target.value
		await setStateAsync(this, { searchTerm: term })

		if (!this.props.searchChangeHandler) { return }
		return this.props.searchChangeHandler(term)
	}

	handleEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (!this.props.searchEnterHandler) { return }

		return this.props.searchEnterHandler(event)
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildSearchInput = () => {
		const searchTermSet = !isNullOrUndefined(this.state.searchTerm) && this.state.searchTerm !== ''
		const clearFunc = (searchTermSet) ? this.handleClear : null
		const icon = (searchTermSet) ?
			(
				<Feather.X size={15} />
			) :
			(
				<Feather.Search size={15} />
			)

		return (
			<div className='search-input input-icon'>
				<RS.Input
					placeholder={'Search...'}
					type={'search'}
					onChange={this.handleChange}
					onKeyUp={this.handleEnter}
					value={this.state.searchTerm}
				/>
				<div
					className={`input-icon-addon ${(searchTermSet) ? 'clear-icon' : ''}`}
					onClick={clearFunc}
				>
					<i>{icon}</i>
				</div>
			</div>
		)
	}

	render() {
		return (
			<div>
				{this.buildSearchInput()}
			</div>
		)
	}
}

export default SearchComponent
