// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'

// Internal Dependencies
import AvatarComponent from '../Avatar'

interface ComponentProps {
	users: core.User.Model[]
	remaining?: number
}

type Props = ComponentProps

export default class AvatarListComponent extends React.Component<Props> {
	constructor(props: Props) {
		super(props)
	}

	buildUserAvatars = () => {
		const users = this.props.users
		const remaining = this.props.remaining || 0

		const avatars = users.map(this.buildUserAvatar)

		const remainingAvatar = (remaining > 0) ?
			(<span className='avatar'>{`+${remaining}`}</span>) :
			null

		return (
			<div className='avatar-list avatar-list-stacked'>
				{avatars}
				{remainingAvatar}
			</div>
		)
	}

	buildUserAvatar = (user: core.User.Model, index: number) => {
		return (
			<AvatarComponent key={`avatar-list_${index}`} user={user} />
		)
	}

	render() {
		return this.buildUserAvatars()
	}
}
