// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import { oc } from 'ts-optchain'

// Internal Dependencies

// Components
import ProgressiveImage, { LoadedImage, SrcSetData } from '../ProgressiveImage'

// Helpers
import { ImageSize, imageForResource } from '../../../helpers/image'

interface ComponentProps {
	image: core.SubModels.Image.Model
	size: ImageSize
	club: core.Club.Model
	placeholderClass?: string
}

type Props = ComponentProps & React.HTMLAttributes<any>
type State = any

class AsyncBackgroundImage extends React.Component<Props, State> {
	private imageURL: string
	private minimizedURL: string

	constructor(props: Props) {
		super(props)
		this.imageURL = imageForResource(props.image, props.club, props.size)
		this.minimizedURL = imageForResource(props.image, props.club, ImageSize.Micro)
	}

	// ----------------------------------------------------------------------------------
	// Helper Methods
	// ----------------------------------------------------------------------------------

	buildImage = (placeholder: LoadedImage, fullImage: LoadedImage, initialLoading: boolean, loading: boolean, srcSetData: SrcSetData) => {
		let backgroundImageURL = ''
		let backgroundImageClass = ''
		if (!placeholder && !fullImage) {
			backgroundImageClass = `bg-progressive-image-empty`
		}

		if (placeholder) {
			backgroundImageClass = (placeholder.isCached) ?
				'bg-progressive-image-cached bg-progressive-image-blurred' :
				'bg-progressive-image bg-progressive-image-blurred bg-progressive-image-loaded'
			backgroundImageURL = oc(placeholder).src()
		}

		if (fullImage) {
			backgroundImageClass = (fullImage.isCached) ? '' : 'bg-progressive-image bg-progressive-image-loaded'
			backgroundImageURL = oc(fullImage).src()
		}

		const style = { backgroundImage: `url(${backgroundImageURL})` }

		return (
			<div style={style} className={`${this.props.className} ${backgroundImageClass}`}>
				{this.props.children}
			</div>
		)
	}

	render() {
		return (
			<ProgressiveImage
				src={this.imageURL}
				placeholder={this.minimizedURL}
			>
				{this.buildImage}
			</ProgressiveImage>
		)
	}
}

export default AsyncBackgroundImage
