import * as React from 'react'
import * as RS from 'reactstrap'
import * as Feather from 'react-feather'
import * as core from 'club-hub-core'
import classNames from 'classnames'
import { oc } from 'ts-optchain'

export enum ReservationCellAction {
	CHECK_IN = 'checkedIn',
	PAID = 'paid',
	NEW_TEE_TIME = 'new-tee-time',
	NEW_BLOCK = 'new-block'
}

export interface Props {
	onActionClick: (action: ReservationCellAction) => void
	hasPaid: boolean
	hasCheckedIn: boolean
	cart?: boolean
	holes?: number
	includePayButton: boolean
}

const reservationButtonClass = 'reservation-button'

export const ReservationActionButtons: React.FunctionComponent<Props> = (props: Props) => {

	const handleActionClick = (e: React.MouseEvent<HTMLButtonElement>, action: ReservationCellAction) => {
		// Prevent the parent element from having its 'onClick' called
		e.nativeEvent.stopImmediatePropagation()
		e.stopPropagation()
		props.onActionClick(action)
	}

	const cartComponent = () => {
		if (!props.cart) { return null }
		return (
			<div className={'tee-sheet-component d-flex justify-content-center align-items-center'}>
				<i className='fas fa-car-side' />
			</div>
		)
	}

	const holesComponent = () => {
		return (
			<div className={'tee-sheet-component d-flex justify-content-center align-items-center'}>
				<p>{`${oc(props).holes(18)}`}</p>
			</div>
		)
	}

	const checkInButton = (
		<RS.Button
			className={classNames(reservationButtonClass, { filled: props.hasCheckedIn })}
			onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
				handleActionClick(e, ReservationCellAction.CHECK_IN)
			}}
			outline={true}
		>
			<Feather.CheckCircle size={14} />
		</RS.Button>
	)

	const paidButton = (
		<RS.Button
			className={classNames(reservationButtonClass, { filled: props.hasPaid })}
			onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
				handleActionClick(e, ReservationCellAction.PAID)
			}}
			outline={true}
		>
			<Feather.DollarSign size={14} />
		</RS.Button>
	)

	const buttons = [checkInButton]
	if (props.includePayButton) {
		buttons.push(paidButton)
	}

	return (
		<div className='reservation-button-container d-flex align-items-center'>
			{cartComponent()}
			{holesComponent()}
		</div >
	)
}
