// External Dependencies
import * as Feather from 'react-feather'

// Internal Dependencies
import { TableColumn } from '../Shared/Table'
import { TableColumnType } from '../Shared/Table/Primitives'

import { PageHeaderSearchInput, PageHeaderInputType } from '../Shared/TableHeader'

export enum EventTableActions {
	ViewEvent = 'viewEvent',
	MessageEvent = 'messageEvent',
	MessageRSVPs = 'messageRSVPs',
	EditEvent = 'editEvent',
	DeleteEvent = 'deleteEvent'
}

export const EventTableColumns: TableColumn[] = [
	{
		title: '',
		property: '',
		type: TableColumnType.CHECKBOX,
		action: '',
	},
	{
		title: 'Title',
		property: 'name',
		type: TableColumnType.TEXT,
		action: 'name',
	},
	{
		title: 'Calendar',
		property: 'calendar',
		type: TableColumnType.BADGE,
		class: 'text-uppercase',
		action: '',
	},
	{
		title: 'Date',
		property: 'start',
		type: TableColumnType.DATE,
		action: 'dateStart',
	},
	{
		title: 'Time',
		property: 'start',
		type: TableColumnType.TIME,
		action: 'timeStart',
	},
	{
		title: 'Type',
		property: 'type',
		type: TableColumnType.BADGE,
		class: 'text-uppercase',
		action: 'type',
	},
	{
		title: 'RSVPS',
		property: 'rsvps',
		type: TableColumnType.AVATAR_LIST,
		action: 'rsvps',
	},
	{
		title: '',
		property: '',
		type: TableColumnType.DROPDOWN,
		class: 'table-dropdown-menu',
		dropdownItems: [
			{
				action: EventTableActions.ViewEvent,
				valueProperty: '_id',
				displayText: 'View',
				icon: Feather.Eye,
			},
			{
				action: EventTableActions.MessageEvent,
				valueProperty: '_id',
				displayText: 'Message',
				icon: Feather.MessageSquare,
			},
			{
				action: EventTableActions.MessageRSVPs,
				valueProperty: '_id',
				displayText: 'Message RSVPs',
				icon: Feather.MessageSquare,
			},
			{
				action: EventTableActions.EditEvent,
				valueProperty: '_id',
				displayText: 'Edit Event',
				icon: Feather.Edit,
			},
			{
				action: EventTableActions.DeleteEvent,
				valueProperty: '_id',
				displayText: 'Delete Event',
				icon: Feather.Trash,
			},
		]
	}
]
