// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import * as Yup from 'yup'
import { isNullOrUndefined } from 'util'
import {
	Formik,
	Form,
	Field,
	FormikProps,
	FormikValues,
	FieldProps,
	FormikActions,
	ErrorMessage
} from 'formik'

// Internal Dependencies

// Components
import ErrorScroll from './errorFocus'

// Form
import { FormInput, FormInputType } from '../Form'

// Input Primitives
import { InputPrimitiveMap, InputUsesSelect, InputUsesDate, InputUsesTypeahead } from './InputPrimitives'
import { Label } from './InputPrimitives/Label'
import { InputFeedback } from './InputPrimitives/InputFeedback'
import { GetSelectValueForForm } from './InputPrimitives/SelectInput'
import { GetTypeaheadSelectValueForForm } from './InputPrimitives/TypeaheadInput'

interface WrappedFormProps {
	inputs: FormInput[]
	onChange?: (field: string, value: any, formikProps: FormikProps<FormikValues>) => void
	onSelect?: (field: string, value: any, formikProps: FormikProps<FormikValues>) => void
	submitOnEnter?: boolean
}

interface ComponentProps extends WrappedFormProps {
	onSubmit: (values: FormikValues, actions: FormikActions<FormikValues>) => void
	enableReinitialize: boolean
	formResource?: any
	formikRef?: React.LegacyRef<Formik>
	render?: (props: FormikProps<FormikValues>) => React.ReactNode
}

type Props = ComponentProps

export const FormikComponent: React.FunctionComponent<Props> = (props: Props) => {

	// Determine what to render, depending on what was passed in
	const renderProps = (props.render) ?
		props.render :
		(formikProps: FormikProps<FormikValues>) => BuildWrappedForm(props, formikProps)

	return (
		<Formik
			initialValues={buildInitialValues(props)}
			enableReinitialize={props.enableReinitialize}
			validationSchema={buildValidationSchema(props)}
			onSubmit={props.onSubmit}
			validateOnChange={true}
			ref={props.formikRef}
			render={(formikProps: FormikProps<FormikValues>) => {
				return (
					<>
						{renderProps(formikProps)}
						<ErrorScroll />
					</>
				)
			}}
		/>
	)
}

/**
 * Wraps the inputs in a Formik Form. Use if you need other content in the form.
 */
export const BuildWrappedForm = (props: WrappedFormProps, formikProps: FormikProps<FormikValues>) => {
	const formInputs = props.inputs.map((input: FormInput, idx: number) => buildFormInput(input, props, idx))

	const handleOnKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
		if (event.keyCode === 13 && props.submitOnEnter) {
			formikProps.submitForm()
		}
	}

	const handleOnChange = (event: React.ChangeEvent<any>) => {
		// Formik doesn't support file inputs, with handleChange.
		// We are setting the value for file inputs with 'setFieldValue'
		// and we want to avoid it getting overwritten by what comes through
		// this function, so we will skip over any events that are linked to files.
		//
		// More information can be found:
		// https://github.com/jaredpalmer/formik/issues/45
		// https://github.com/jaredpalmer/formik/issues/247
		if (event.target.type === FormInputType.FILE) {
			return
		}

		// Check for onChange event from Typeahead -- skip it, since
		// we are not interested in tracking the text that is typed into the
		// input element.
		if ((event.target.className as string).includes('rbt-input-main')) {
			return
		}

		if (props.onChange) {
			props.onChange(event.target.name, event.target.value, formikProps)
		}
		formikProps.handleChange(event)
	}

	return (
		<>
			<Form onKeyDown={handleOnKeyDown} onChange={handleOnChange}>
				<RS.Row>
					{formInputs}
				</RS.Row>
			</Form>
			{/* Uncomment line below to debug props */}
			{/* {displayFormikState(formikProps)} */}
		</>
	)
}

/**
 * Builds the set of initial values for the form. This
 * is accomplished either by looking at the values on an
 * existing 'formResource' that is passed in, or via the
 * 'defaultValue' field of each input
 */
const buildInitialValues = (props: Props): FormikValues => {
	let values: { [key: string]: any } = {}

	if (props.formResource) {
		// Loop over the inputs and take the values from
		// the form resource
		const formResource = { ...props.formResource }
		for (const input of props.inputs) {
			let property = input.property
			let initialValue = formResource[property]

			// Support for formik nested values https://jaredpalmer.com/formik/docs/guides/arrays
			if (property.includes('[') && property.includes(']')) {
				// Parse the array index from the input property.
				const index = property.substring(property.lastIndexOf('[') + 1, property.lastIndexOf(']'))
				// Parse the form resource property from the input property.
				const strippedProperty = property.substr(0, property.indexOf('['))
				// Get the value for the stripped property.
				const strippedValue = formResource[strippedProperty]
				if (strippedValue && strippedValue.length > Number(index)) {
					property = strippedProperty
					initialValue = strippedValue[Number(index)]
				}
			}

			// Determine if we need to convert the value for the input
			const usingSelect = InputUsesSelect(input)
			if (usingSelect) {
				initialValue = GetSelectValueForForm(input, initialValue)
			}

			const usingTypeahead = InputUsesTypeahead(input)
			if (usingTypeahead) {
				initialValue = GetTypeaheadSelectValueForForm(input, initialValue)
			}

			// Configure the correct initial value for a nested property.
			if (input.property.includes('[') && input.property.includes(']')) {
				const existingValues = values[property]
				if (existingValues && Array.isArray(existingValues)) {
					existingValues.push(initialValue)
					initialValue = existingValues
				} else {
					initialValue = [initialValue]
				}
			}

			// If the value is undefined, use the input's default value.
			if (isNullOrUndefined(initialValue)) {
				initialValue = input.defaultValue
			}
			values = { ...values, [property]: initialValue }
		}
	} else {
		// Loop over the inputs and take the default values
		for (const input of props.inputs) {
			values = { ...values, [input.property]: input.defaultValue }
		}
	}
	return values
}

const buildValidationSchema = (props: Props): any => {
	let schemaShape = {}
	for (const input of props.inputs) {
		if (!input.validation) { continue }
		schemaShape = { ...schemaShape, [input.property]: input.validation }
	}
	return Yup.object().shape({ ...schemaShape })
}

/**
 * Given an input, determine which type of input primitive to build
 * and wrap it in a Formik Field.
 */
const buildFormInput = (input: FormInput, wrappedProps: WrappedFormProps, idx: number): any => {
	// Do not build an input for BLANK inputs
	if (input.type === FormInputType.BLANK) { return }

	// Check if a Section is being built
	if (input.type === FormInputType.SECTION) {
		return (
			<RS.Col key={`section-${idx}`} className={`section-header ${input.class}`} md={input.size || 12}>
				<p className={'form-section-title'}>{input.title}</p>
				<hr />
			</RS.Col>
		)
	}

	if (input.type === FormInputType.INPUT_GROUP) {
		return input.inputItems.map((i: FormInput, index: number) => buildFormInput(i, wrappedProps, idx * index))
	}

	return (
		<RS.Col md={input.size || 12} key={`formInput-${idx}`} id={`${idx}`}>
			<RS.FormGroup>
				{buildLabeledInputPrimitive(input, wrappedProps)}
			</RS.FormGroup>
		</RS.Col>
	)
}

/**
 * Builds the Input Primitive with an accompanying Label and ErrorMessage
 */
export const buildLabeledInputPrimitive = (input: FormInput, wrappedProps?: WrappedFormProps) => {
	// Check if a Section is being built
	const label = input.title ? (
		<Label>
			<span>{input.title}</span>
		</Label>
	) : null

	return (
		<>
			{label}
			<Field
				name={input.property}
				validation={input.validationFunc}
			>
				{(fieldProps: FieldProps) => (
					buildInputPrimitive(input, fieldProps, { ...wrappedProps })
				)}
			</Field>
			<ErrorMessage component={InputFeedback} name={input.property} />
		</>
	)
}

/**
 * Builds the Input Primitive with an accompanying ErrorMessage
 */
export const buildUnlabeledInputPrimitive = (input: FormInput, wrappedProps?: WrappedFormProps) => {
	return (
		<>
			<Field name={input.property}>
				{(fieldProps: FieldProps) => (
					buildInputPrimitive(input, fieldProps, { ...wrappedProps })
				)}
			</Field>
			<ErrorMessage component={InputFeedback} name={input.property} />
		</>
	)
}

/**
 * Determine which Primitive to use, depending on the 'type' value of FormInput.
 * Pass in the props from Formik that it will need to work in the form.
 */
export const buildInputPrimitive = (input: FormInput, fieldProps: FieldProps, wrappedProps: WrappedFormProps): any => {

	// Get the Primitive using its type
	const InputPrimitive = InputPrimitiveMap[input.type]

	// Formik Props
	const formProps = { ...fieldProps.form }
	let additionalProps = { ...fieldProps.field, form: fieldProps.form }

	// Determine if we are using a custom onChange and onBlur
	const usingCustomHandlers =
		InputUsesSelect(input) ||
		InputUsesDate(input) ||
		InputUsesTypeahead(input) ||
		input.type === FormInputType.COLOR ||
		input.type === FormInputType.MCE_EDITOR ||
		input.type === FormInputType.FILE

	if (usingCustomHandlers) {
		// We will need to override onChange and onBlur for custom components
		// to use the 'setFieldValue' and 'setFieldTouched' functions
		additionalProps = {
			...additionalProps,
			onChange: ((field: string, value: any) => {
				if (wrappedProps.onChange) {
					wrappedProps.onChange(field, value, formProps)
				}

				return formProps.setFieldValue(field, value)
			}) as any,
			onBlur: formProps.setFieldTouched as any
		}
	}
	return (
		<InputPrimitive
			item={input}
			onSelect={wrappedProps.onSelect}
			{...additionalProps}
		/>
	)
}

/**
 * Displays the props for the Formik component -- use for debugging forms
 */
const displayFormikState = (formikProps: FormikProps<FormikValues>) => {
	const preStyle = {
		background: '#f6f8fa',
		fontSize: '.65rem',
		padding: '.5rem',
	}
	return (
		<div style={{ margin: '1rem 0' }}>
			<h3 style={{ fontFamily: 'monospace' }} />
			<pre style={preStyle}>
				<strong>props</strong> = {JSON.stringify(formikProps, null, 2)}
			</pre>
		</div>
	)
}
