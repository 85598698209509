// External Dependencies
import * as Sentry from '@sentry/browser'
import * as URI from 'urijs'
import * as BrowserDetect from 'detect-browser'
import * as URL from 'url'
import { oc } from 'ts-optchain'

// Constants
import { UNAUTHORIZED_ACCESS_NOTIFICATION, INTERNAL_SERVER_ERROR, INVALID_CREDENTIALS } from '../constants'

// Storage
import { loadStateForKey, SESSION_TOKEN_KEY } from '../store/localStorage'

// Helpers
import { RequestError } from './error'

// OS/Platform Info
const detectedUA = BrowserDetect.detect() as BrowserDetect.BrowserInfo
const platform = oc(detectedUA).name('' as any).toLocaleLowerCase()
const version = oc(detectedUA).version('' as any).toLocaleLowerCase()
let os = oc(detectedUA).os('' as any).toLocaleLowerCase()

// Forcing a minor correction here to keep mobile/web tracking consistent
if (os === 'android os') {
	os = 'android'
}

const API_VERSION = 'v1'
let HOST = 'http://localhost:8080'
if (process.env.ENV === 'production') {
	HOST = 'https://api.warehousepc.com'
} else if (process.env.ENV === 'staging') {
	HOST = 'https://api-staging.warehousepc.com'
}

if (process.env.HOST_OVERRIDE === 'production') {
	HOST = 'https://api.warehousepc.com'
} else if (process.env.HOST_OVERRIDE === 'staging') {
	HOST = 'https://api-staging.warehousepc.com'
}

// =======================================
// INTERFACE METHODS
// =======================================

/**
 * PUTS a resource for the provided path and params.
 * @param path The path for the resource.
 * @param path An optional set of parameters to include in the request.
 */
export function GET(path: string, params?: any) {
	const url = URLWithPath(path, params)
	const options = baseRequest('GET', url)
	return performFetch(url, options)
}

/**
 * GETS a resource for the provided path and params.
 * @param path The path for the resource.
 * @param path An optional set of parameters to include in the request.
 */
export function PUT(path: string, body: any, params?: any) {
	const url = URLWithPath(path, params)
	const options = baseRequest('PUT', url, body)
	return performFetch(url, options)
}

/**
 * POSTS a resource for the provided path and params.
 * @param path The path for the resource.
 * @param path An optional set of parameters to include in the request.
 */
export function POST(path: string, body: any) {
	const url = URLWithPath(path)
	const options = baseRequest('POST', url, body)
	return performFetch(url, options)
}

/**
 * PUT_FORM is used when uploading files via FormData.
 */
export function PUT_FORM(path: string, body: any, query?: any) {
	const url = URLWithPath(path, query)
	const options = {
		headers: defaultHeaders(),
		body: body,
		cors: true,
		credentials: 'include' as RequestCredentials,
		method: 'PUT',

	}
	return performFetch(url, options)
}

/**
 * POST_FORM is used when uploading files via FormData.
 */
export function POST_FORM(path: string, body: any) {
	const url = URLWithPath(path)
	const options = {
		headers: defaultHeaders(),
		body: body,
		cors: true,
		credentials: 'include' as RequestCredentials,
		method: 'POST',
	}
	return performFetch(url, options)
}

/**
 * DELETES a resource for the provided path and params.
 * @param path The path for the resource.
 * @param path An optional set of parameters to include in the request.
 */
export function DELETE(path: string, params: any) {
	const url = URLWithPath(path, params)
	const options = baseRequest('DELETE', url)
	return performFetch(url, options)
}

/**
 * Performs the Fetch for the given request
 * @param  {[type]} request Base Request
 * @return {[type]}         Result Promise
 */
function performFetch(url: string, options: RequestInit) {
	return fetch(url, options).then(async (response: Response) => {
		if (!response.ok) {
			const parsedURL = URL.parse(url)
			const pathComponents = parsedURL.pathname.split('/')
			const lastPathComponent = pathComponents[pathComponents.length - 1]
			if (response.status === 401) {
				if (lastPathComponent === 'login') {
					throw new RequestError(INVALID_CREDENTIALS, response.status)
				}
				throw new RequestError(UNAUTHORIZED_ACCESS_NOTIFICATION, response.status)
			}

			if (responseContainsJSON(response)) {
				const resJSON = await response.json()
				const errorMessage = buildErrorMessage(resJSON)
				throw new RequestError(errorMessage, response.status)
			}
			const statusText = (response.statusText === 'Internal Server Error') ? INTERNAL_SERVER_ERROR : response.statusText
			throw new RequestError(statusText, 500)
		}

		if (response.status === 204) {
			return null
		} else if (response.headers.get('x-redirect')) {
			return { redirect: response.headers.get('x-redirect') }
		} else if (responseContainsJSON(response)) {
			return response.json()
		} else {
			return {}
		}
	}).catch((error: Error) => {
		// tslint:disable-next-line
		console.error(`
		Request failed.
		URL: ${url}
		Options: ${JSON.stringify(options)}`)

		if (error.message !== UNAUTHORIZED_ACCESS_NOTIFICATION) {
			// Send the error back to Sentry
			Sentry.captureMessage(error.message, Sentry.Severity.Error)
		}
		throw error
	})
}

function responseContainsJSON(response: Response) {
	const val = response.headers.get('content-type')
	const containsJSON = (val.indexOf('application/json') >= 0)
	return val && containsJSON
}

/**
 * Creates the base request object for the operation
 * @param  {String} method HTTP Method
 * @param  {String} URL    URL For Req
 * @param  {Object} Body   Body for the request
 * @return {Request}       Formatted Request
 */
function baseRequest(method: string, url: string, body?: any): RequestInit {
	const headersForRequest = defaultHeadersWithJSON()
	const init = {
		body: JSON.stringify(body),
		cors: true,
		credentials: 'include' as RequestCredentials,
		headers: headersForRequest,
		method: method,
	}
	return init
}

/**
 * defaultHeaders supplies the default headers for an api request
 * @return {Headers} Default Headers
 */
function defaultHeaders() {
	const header = new Headers()
	header.set('X-Web-Source', 'meshboilerplate')
	header.set('X-Platform', platform)
	header.set('X-OS', os)
	header.set('X-Version', version)
	const token = loadStateForKey(SESSION_TOKEN_KEY)
	if (token) {
		header.set('Authorization', `Bearer ${token}`)
	}
	return header
}

/**
 * defaultHeaders supplies the default headers for an api request
 * @return {Headers} Default Headers
 */
function defaultHeadersWithJSON() {
	const header = defaultHeaders()
	header.set('Accept', 'application/json')
	header.set('Content-Type', 'application/json')
	return header
}

/**
 * URI For the requested path
 * @param  {String} path Path for the API
 * @return {String}        URI for the path
 */
function URLWithPath(path: string, queryParams: { [key: string]: string } = {}) {
	const uri = new URI(HOST)
	uri.pathname(`${API_VERSION}${path}`)

	// Append Query Params
	for (const key in queryParams) {
		if (queryParams.hasOwnProperty(key)) {
			uri.setSearch(key, queryParams[key])
		}
	}
	return `${uri}`
}

/**
 * Builds a pretty error message using the formatted error objects
 * coming from the server. This can be an array of errors or an error message.
 * We can continue to build this out as we make error handling more consistent on the server.
 * This can also be used to return useful error messages to users who break club specific rules.
 * SG - 3/20/19.
 *
 * @param err
 */
function buildErrorMessage(err: any) {
	// Single Error
	if (err.error) {
		return err.error
	}

	// Multiple Errors
	if (err.errors && err.errors.length) {
		let errorMessage = ''
		for (const individualError of err.errors) {
			errorMessage += `${individualError.message}\n`
		}
		return errorMessage
	}

	// We received an error that doesn't match our spec --
	// just display a generic error here
	return 'Error from Server'
}
