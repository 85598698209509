// External Dependencies
import * as core from 'club-hub-core'
import { Dispatch, Action } from 'redux'
import to from 'await-to-js'

// Internal Dependencies

// API Helpers
import { POST, GET, PUT, DELETE } from '../helpers/api'

export enum TypeKeys {
	FETCHED_FILTERS = 'FETCHED_FILTERS',
	CREATED_FILTER = 'CREATED_FILTER',
	UPDATED_FILTER = 'UPDATED_FILTER',
	DELETED_FILTER = 'DELETED_FILTER',
}

export type ActionTypes =
	| FetchedFiltersAction
	| CreatedFilterAction
	| UpdatedFilterAction
	| DeletedFilterAction

export interface FetchedFiltersAction extends Action {
	type: TypeKeys.FETCHED_FILTERS,
	filters: core.QueryFilter.Model[],
}

export interface CreatedFilterAction extends Action {
	type: TypeKeys.CREATED_FILTER,
	createdFilter: core.QueryFilter.Model
}

export interface UpdatedFilterAction extends Action {
	type: TypeKeys.UPDATED_FILTER,
	updatedFilter: core.QueryFilter.Model
}

export interface DeletedFilterAction extends Action {
	type: TypeKeys.DELETED_FILTER,
	deletedFilter: core.QueryFilter.Model
}

// -----------------------------------------------------------------------------
// Fetch Filters
// -----------------------------------------------------------------------------

const fetchFilters = () => async (dispatch: Dispatch<FetchedFiltersAction>) => {
	const [err, res] = await to<core.QueryFilter.Model[]>(GET('/admin/filters', {}))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to fetch Filters with error: ${err}`)
		throw err
	}

	await dispatch(fetchedFilters(res))
}

const fetchedFilters = (filtersRes: core.QueryFilter.Model[]): FetchedFiltersAction => {
	const action: FetchedFiltersAction = {
		type: TypeKeys.FETCHED_FILTERS,
		filters: filtersRes,
	}
	return action
}

// -----------------------------------------------------------------------------
// Create Filter
// -----------------------------------------------------------------------------

const createFilter = (payload: core.QueryFilter.Model) => async (dispatch: Dispatch<CreatedFilterAction>) => {
	const [err, res] = await to<core.QueryFilter.Model>(POST('/admin/filters', payload))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to create Filter with error: ${err}`)
		throw err
	}

	await dispatch(createdFilter(res))
	return res
}

const createdFilter = (filterRes: core.QueryFilter.Model): CreatedFilterAction => {
	const action: CreatedFilterAction = {
		type: TypeKeys.CREATED_FILTER,
		createdFilter: filterRes,
	}
	return action
}

// -----------------------------------------------------------------------------
// Update Filter
// -----------------------------------------------------------------------------

const updateFilter = (filterID: string, payload: core.QueryFilter.Model) => async (dispatch: Dispatch<UpdatedFilterAction>) => {
	const [err, res] = await to<core.QueryFilter.Model>(PUT(`/admin/filters/${filterID}`, payload))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to update Filter with error: ${err}`)
		throw err
	}

	await dispatch(updatedFilter(res))
	return res
}

const updatedFilter = (filterRes: core.QueryFilter.Model): UpdatedFilterAction => {
	const action: UpdatedFilterAction = {
		type: TypeKeys.UPDATED_FILTER,
		updatedFilter: filterRes,
	}
	return action
}

// -----------------------------------------------------------------------------
// Delete Filter
// -----------------------------------------------------------------------------

const deleteFilter = (filterID: string) => async (dispatch: Dispatch<DeletedFilterAction>) => {
	const [err, res] = await to<core.QueryFilter.Model>(DELETE(`/admin/filters/${filterID}`, {}))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to delete Filter with error: ${err}`)
		throw err
	}

	await dispatch(deletedFilter(res))
}

const deletedFilter = (filterRes: core.QueryFilter.Model): DeletedFilterAction => {
	const action: DeletedFilterAction = {
		type: TypeKeys.DELETED_FILTER,
		deletedFilter: filterRes,
	}
	return action
}

export const QueryFilterActions = {
	fetchFilters,
	createFilter,
	updateFilter,
	deleteFilter,
}
