// External Dependencies
import * as core from 'club-hub-core'
import * as Feather from 'react-feather'

// Internal Dependencies
import { TableColumn } from '../Shared/Table'
import { TableColumnType } from '../Shared/Table/Primitives'

export interface ServiceProviderTableItem extends core.Calendar.Model {
	streetAddress: string
	city: string
	state: string
	zip: string
	email: string
	phone: string
	contactName: string
}

export const ServiceProviderTableColumns: TableColumn[] = [
	{
		title: '',
		property: '',
		type: TableColumnType.CHECKBOX,
		action: '',
	},
	{
		title: 'Name',
		property: 'name',
		type: TableColumnType.TEXT,
		action: 'name',
	},
	{
		title: 'Email',
		property: 'email',
		type: TableColumnType.TEXT,
		action: 'email'
	},
	{
		title: 'Phone',
		property: 'phone',
		type: TableColumnType.TEXT,
		action: 'phone'
	},
	{
		title: 'Contact Name',
		property: 'contactName',
		type: TableColumnType.TEXT,
		action: 'contactName'
	},
	{
		title: 'City',
		property: 'city',
		type: TableColumnType.TEXT,
		action: 'city'
	},
	{
		title: '',
		property: '',
		type: TableColumnType.DROPDOWN,
		class: 'table-dropdown-menu',
		dropdownItems: [
			{
				action: 'editServiceProvider',
				valueProperty: '_id',
				displayText: 'Edit Service Provider',
				icon: Feather.Edit,
			},
			{
				action: 'removeServiceProvider',
				valueProperty: '_id',
				displayText: 'Remove Service Provider',
				icon: Feather.Trash,
			}
		]
	}
]
