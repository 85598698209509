// External Dependencies
import * as Yup from 'yup'

// Internal Dependencies
import { FormInputType, FormInput, ReactSelectItem } from '../../Shared/Form'
import * as Constants from '../../../constants/index'

export interface UserSelectModalState {
	users: ReactSelectItem[]
}

export const UsersSelectModalInputs = (usersForForm: ReactSelectItem[]): FormInput[] => {
	return [
		{
			title: 'Users',
			property: 'users',
			type: FormInputType.MULTI_SELECT_TYPEAHEAD,
			size: 12,
			placeholder: 'Select users...',
			defaultValue: '',
			selectItems: usersForForm,
			validation: Yup.string().required(Constants.REQUIRED_FIELD)
		}
	]
}
