// External Dependencies
import { FormikProps } from 'formik'

// Internal Dependencies
import { FormInputType, FormInput } from '../../Form'

// Input Primitives
import { CustomInput } from './CustomInput'
import { TextInput } from './TextInput'
import { CheckBox } from './CheckBox'
import { SelectInput } from './SelectInput'
import { TextAreaInput } from './TextAreaInput'
import { NumberInput } from './NumberInput'
import { PriceInput } from './PriceInput'
import FileInput from './FileInput'
import { SelectGroupInput } from './SelectGroupInput'
import { DateInput } from './DateInput'
import { EditorInput } from './EditorInput'
import { RadioGroupInput } from './RadioGroupInput'
import { TypeaheadInput } from './TypeaheadInput'
import { AlertInput } from './AlertInput'
import ColorInput from './ColorInput'
import { MemberSelectInput } from './MemberSelectInput'
import { ButtonGroup } from './ButtonGroup'

export interface BaseInputPrimitiveProps {
	id?: string
	item: FormInput
	type?: string
	value: any
	onChange: (e: any) => any
	onSelect?: (e: any) => any
	form?: FormikProps<any>
}

export const InputPrimitiveMap: any = {
	[FormInputType.CUSTOM]: CustomInput,
	[FormInputType.TEXT]: TextInput,
	[FormInputType.CHECKBOX]: CheckBox,
	[FormInputType.COLOR]: ColorInput,
	[FormInputType.BUTTON_GROUP]: ButtonGroup,
	[FormInputType.EMAIL]: TextInput,
	[FormInputType.URL]: TextInput,
	[FormInputType.ALERT]: AlertInput,
	[FormInputType.NUMBER]: NumberInput,
	[FormInputType.PRICE]: PriceInput,
	[FormInputType.TEXT_AREA]: TextAreaInput,
	[FormInputType.PASSWORD]: TextInput,
	[FormInputType.SELECT_GROUP]: SelectGroupInput,
	[FormInputType.FILE]: FileInput,
	[FormInputType.SELECT]: SelectInput,
	[FormInputType.GROUPED_SELECT]: SelectInput,
	[FormInputType.MULTI_SELECT]: SelectInput,
	[FormInputType.GROUPED_MULTI_SELECT]: SelectInput,
	[FormInputType.DATE_YEAR]: DateInput,
	[FormInputType.DATE]: DateInput,
	[FormInputType.DATE_TIME]: DateInput,
	[FormInputType.TIME]: DateInput,
	[FormInputType.MCE_EDITOR]: EditorInput,
	[FormInputType.RADIO_GROUP]: RadioGroupInput,
	[FormInputType.TYPEAHEAD]: TypeaheadInput,
	[FormInputType.GROUPED_TYPEAHEAD]: TypeaheadInput,
	[FormInputType.MULTI_SELECT_TYPEAHEAD]: TypeaheadInput,
	[FormInputType.GROUPED_MULTI_SELECT_TYPEAHEAD]: TypeaheadInput,
	[FormInputType.MEMBER_SELECT_INPUT]: MemberSelectInput,
}

/**
 * Helper function to determine if the given 'input'
 * is a Select-related input
 */
export const InputUsesSelect = (input: FormInput) => {
	return input.type === FormInputType.SELECT ||
		input.type === FormInputType.GROUPED_SELECT ||
		input.type === FormInputType.MULTI_SELECT ||
		input.type === FormInputType.GROUPED_MULTI_SELECT
}

/**
 * Helper function to determine if the given 'input'
 * is a Date-related input
 */
export const InputUsesDate = (input: FormInput) => {
	return input.type === FormInputType.TIME ||
		input.type === FormInputType.DATE ||
		input.type === FormInputType.DATE_TIME ||
		input.type === FormInputType.DATE_YEAR
}

/**
 * Helper function to determine if the given 'input'
 * is a Typeahead-related input
 */
export const InputUsesTypeahead = (input: FormInput) => {
	return input.type === FormInputType.TYPEAHEAD ||
		input.type === FormInputType.GROUPED_TYPEAHEAD ||
		input.type === FormInputType.MULTI_SELECT_TYPEAHEAD ||
		input.type === FormInputType.GROUPED_MULTI_SELECT_TYPEAHEAD
}
