import * as React from 'react'
import * as RS from 'reactstrap'
import * as Feather from 'react-feather'
import * as core from 'club-hub-core'
import { ReservationCellAction } from '../../shared/ReservationActionButtons'

interface ComponentProps {
	event: core.Event.AvailableEvent
	handleActionClick: (action: any, event: core.Event.AvailableEvent) => void
}

const initialState = {
	dropdownOpen: false
}

type Props = ComponentProps
type State = typeof initialState

export default class TeeTimeMenu extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props)

		this.state = { ...initialState }
	}

	handleToggle = () => {
		this.setState({ dropdownOpen: !this.state.dropdownOpen })
	}

	buildDropdownItem = (title: string, icon: Feather.Icon, action: ReservationCellAction) => {
		const FeatherIcon = icon
		return (
			<RS.DropdownItem
				className={'d-flex tee-time-menu-item align-items-center'}
				onClick={() => this.props.handleActionClick(action, this.props.event)}
			>
				<FeatherIcon className={'dropdown-icon'} size={20} />
				{title}
			</RS.DropdownItem>
		)
	}

	render() {
		return (
			<RS.Dropdown className={'tee-time-dropdown'} isOpen={this.state.dropdownOpen} toggle={this.handleToggle}>
				<RS.DropdownToggle className={'tee-time-menu-dropdown-toggle'} onClick={this.handleToggle}>
					<Feather.MoreVertical size={20} />
				</RS.DropdownToggle>
				<RS.DropdownMenu className={'tee-time-dropdown-menu dropdown-menu-arrow'} right={true}>
					{this.buildDropdownItem('New Tee Time', Feather.Clock, ReservationCellAction.NEW_TEE_TIME)}
					<RS.DropdownItem divider={true} />
					{this.buildDropdownItem('Block Time', Feather.XCircle, ReservationCellAction.NEW_BLOCK)}
				</RS.DropdownMenu>
			</RS.Dropdown>
		)
	}
}
