import Color from 'color'
import * as core from 'club-hub-core'

// @ts-ignore
import style from '../assets/__base.scss'

/**
 * Configures the global styles for our app based on club.
 * @param club The club for which to configure.
 */
export const updateGlobalStyle = (club: core.Club.Model) => {
	const existingValue = document.body.style.getPropertyValue('--primary-color')

	// Configure our properties for the site.
	const primaryColor = club.clubSettings.primaryColor
	const secondaryColor = club.clubSettings.secondaryColor
	const lightPrimary = Color(primaryColor).lighten(0.1).hex()

	document.body.style.setProperty('--primary-color', primaryColor)
	document.body.style.setProperty('--primary-color-light', lightPrimary)
	document.body.style.setProperty('--secondary-color', secondaryColor)

	const font = club.clubSettings.font
	document.body.style.setProperty('--font', font)

	const bump = (font === 'Arapey') ? 2 : 0
	setFontSize('--larger-font', style.LargestFont, bump)
	setFontSize('--large-font', style.LargeFont, bump)
	setFontSize('--regular-font', style.RegularFont, bump)
	setFontSize('--medium-font', style.MediumFont, bump)
	setFontSize('--small-font', style.SmallFont, bump)
	setFontSize('--smaller-font', style.SmallerFont, bump)
	setFontSize('--smallest-font', style.SmallestFont, bump)
}

const setFontSize = (fontVar: string, base: string, bump: number) => {
	const baseSizeString = base.replace('px', '')
	const baseSize = Number(baseSizeString)
	const bumped = baseSize + bump
	document.body.style.setProperty(fontVar, `${bumped}px`)
}
