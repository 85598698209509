// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as RS from 'reactstrap'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { isEqual } from 'underscore'
import { oc } from 'ts-optchain'

// Internal Dependencies
import { NavLinkPressed } from '../../helpers/analytics'

// Actions
import { SectionActions, AlertActions } from '../../actions/index'

// State
import { RootReducerState } from '../../reducers'
import { inCustomerViewSelector } from '../../reducers/user'

// Components
import DimmedLoader from '../Shared/DimmedLoader'
import RichContent from '../Shared/RichContent'
import BackHeader from '../Shared/BackHeader'
import AsyncBackgroundImage from '../Shared/AsyncBackgroundImage'

// Helpers
import { setStateAsync } from '../../helpers/promise'
import { sortByOrderingIndex } from '../../helpers/array'
import * as Constants from '../../constants'
import { ImageSize } from '../../helpers/image'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	initialLoading: true,
	section: null as core.Section.Model | null,
	activePage: null as core.Section.Page | null
}

type Props = ConnectedActions & ConnectedState & RouteComponentProps
type State = typeof initialState

class SectionDetailComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)

		const sectionID = (props.match.params as any).section_id
		const section = props.sectionState.sections.find((s) => `${s._id}` === sectionID)
		const pages = [...oc(section).pages([])]
		const sortedPages = pages.sort(sortByOrderingIndex)

		// Determine the default page
		const queryParams = queryString.parse(props.location.search)
		const pageName = queryParams.page

		const defaultPage = (pageName) ?
			pages.find((p) => p.name === pageName) :
			oc(sortedPages)[0]()
		this.state = { ...initialState, section, activePage: defaultPage }
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Method
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		await setStateAsync(this, { initialLoading: false })
	}

	async componentDidUpdate(prevProps: Props) {
		const samePath = prevProps.match.path === this.props.match.path
		const differentSection = !isEqual(prevProps.match.params, this.props.match.params)
		if (samePath && differentSection) {
			const sectionID = (this.props.match.params as any).section_id
			const section = oc(this).props.sectionState.sections([]).find((s) => `${s._id}` === sectionID)
			const pages = [...oc(section).pages([])]
			const sortedPages = pages.sort(sortByOrderingIndex)
			const defaultPage = oc(sortedPages)[0]()
			await setStateAsync(this, { section: section, activePage: defaultPage })
		}
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	handlePageChange = async (page: core.Section.Page) => {
		// Track nav
		NavLinkPressed(page.name)

		// Set State
		await setStateAsync(this, { activePage: page })

		const locationParams = {
			page: page.name
		}
		const location = {
			pathname: this.props.match.url,
			search: queryString.stringify(locationParams)
		}
		this.props.history.replace(location)
	}

	handleEditClicked = async () => {
		const page = this.state.activePage
		const sectionID = `${this.state.section._id}`
		const pageFormRoute = Constants.UPDATE_SECTION_PAGE_ROUTE.replace(':section_id', sectionID)
		const pageFormParams = { pageName: page.name, pageID: page._id }
		const pageFormLocation = {
			pathname: pageFormRoute,
			search: queryString.stringify(pageFormParams)
		}
		this.props.history.push(pageFormLocation)
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildContent = () => {
		const className = this.props.isCustomerView ? 'customer-view' : ''
		return (
			<RS.Row noGutters={true}>
				<RS.Col sm={12} className='section-detail-page-header-container'>
					{this.buildPageHeroImage()}
				</RS.Col>
				<RS.Col sm={12} className='section-detail-page-content-container d-flex justify-content-center'>
					<div className={`card section-detail-card ${className}`}>
						<RS.Row noGutters={true}>
							<RS.Col sm={3} md={3} className='section-detail-nav-container no-padding'>
								{this.buildPagesNav()}
							</RS.Col>
							<RS.Col sm={9} md={9} className='section-detail-page-body-container'>
								{this.buildPageBody()}
							</RS.Col>
						</RS.Row>
					</div>
				</RS.Col>
			</RS.Row>
		)
	}

	buildPageHeroImage = () => {
		const image = oc(this).state.section.image()
		const name = oc(this).state.section.name()
		if (!image || !this.props.isCustomerView) { return }

		return (
			<AsyncBackgroundImage
				className='card section-image-container'
				image={this.state.section.image}
				size={ImageSize.Large}
				club={this.props.loggedInClub}
			>
				<div className={'section-image-title-container d-flex justify-content-center align-items-center'}>
					<h1>{name}</h1>
				</div>
			</AsyncBackgroundImage>
		)
	}

	buildPageHeader = () => {
		if (this.props.isCustomerView) { return null }

		const backRoute = Constants.SECTION_PAGES_ROUTE.replace(':section_id', `${oc(this).state.section._id()}`)
		return (
			<BackHeader to={backRoute} backTitle='Section Pages' editHandler={this.handleEditClicked} />
		)
	}

	buildPagesNav = () => {
		const activePage = oc(this).state.activePage()
		const sortedPages = [...oc(this).state.section.pages([])].sort(sortByOrderingIndex)
		let filteredPages = sortedPages
		if (this.props.isCustomerView) {
			filteredPages = sortedPages.filter((p: core.Section.Page) => p.content.status === core.IShared.PublicationStatus.Published)
		}
		const pages = filteredPages.map((page, idx) => {
			return (
				<RS.NavItem
					key={`${page._id}_${idx}`}
					active={activePage && `${page._id}` === `${activePage._id}`}
					onClick={() => this.handlePageChange(page)}
				>
					{page.name}
				</RS.NavItem>
			)
		})

		return (
			<div className='section-detail-page-nav'>
				<h4 className='section-detail-page-nav-header text-uppercase'>{this.state.section.name}</h4>
				<RS.Nav vertical={true}>
					{pages}
				</RS.Nav>
			</div>
		)
	}

	buildPageBody = () => {
		const title = oc(this.state.activePage).name('')
		const content = oc(this.state.activePage).content.html('')
		return (
			<div className='section-detail-page-body'>
				<div className={'section-detail-title-container'}>
					<h1>{title}</h1>
				</div>
				<hr />
				<RichContent content={content} />
			</div>
		)
	}

	render() {
		if (this.state.initialLoading) { return <DimmedLoader component={null} isLoading={true} /> }
		const className = this.props.isCustomerView ? 'customer-view' : ''
		return (
			<div className={`section-detail-container ${className}`}>
				{this.buildPageHeader()}
				{this.buildContent()}
			</div>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	sectionState: state.section,
	userState: state.user,
	isCustomerView: inCustomerViewSelector(state),
	loggedInClub: state.club.loggedInClub
})

const mapDispatchToProps = {
	...SectionActions,
	...AlertActions
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionDetailComponent)
