// External Dependencies
import * as Yup from 'yup'

// Internal Dependencies
import { FormInput, FormInputType } from '../../Shared/Form'
import * as Constants from '../../../constants/index'

export interface DateRangeFormState {
	name: string,
	start: Date,
	end: Date,
}

export const DateRangeFormInputs: FormInput[] = [
	{
		title: 'Name',
		property: 'name',
		type: FormInputType.TEXT,
		size: 12,
		placeholder: 'Enter Name...',
		defaultValue: '',
		validation: Yup.string().required(Constants.REQUIRED_FIELD)
	},
	{
		title: 'Start',
		property: 'start',
		type: FormInputType.DATE,
		size: 12,
		placeholder: 'Select Start...',
		defaultValue: null,
		validation: Yup.date().nullable().required(Constants.REQUIRED_FIELD)
	},
	{
		title: 'End',
		property: 'end',
		type: FormInputType.DATE,
		size: 12,
		placeholder: 'Select End...',
		defaultValue: null,
		validation: Yup.date().nullable().required(Constants.REQUIRED_FIELD)
	}
]
