// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies

// Actions
import { AlertActions, ClubActions } from '../../actions/index'

// State
import { RootReducerState } from '../../reducers'

// Components
import TableComponent from '../Shared/Table'
import ModalComponent from '../Shared/Modal'
import NewLocationModal from '../Modals/NewLocationModal'

// Table
import { EventLocationTableColumns, EventLocationTableActions } from './table'

// Helpers
import { setStateAsync } from '../../helpers/promise'
import * as Constants from '../../constants/index'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

interface ComponentProps {
	showingEditModal: boolean
	onEditModalClose: () => any
}

// State Key Constants
type ModalStateKey = typeof ShowingLocalEditModal | typeof ShowingDeletionModal
const ShowingLocalEditModal = 'showingLocalEditModal'
const ShowingDeletionModal = 'showingDeletionModal'

const initialState = {
	loading: false,
	error: false,
	pageIndex: 0,
	[ShowingLocalEditModal]: false,
	[ShowingDeletionModal]: false,
	locationToEdit: null as string | null,
	locationToDelete: null as string | null,
}

type Props = ComponentProps & ConnectedState & ConnectedActions
type State = typeof initialState

class EventLocationsComponent extends React.Component<Props, State> {
	private pageSize: number

	constructor(props: Props) {
		super(props)
		this.pageSize = 20
		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	// ----------------------------------------------------------------------------------
	// Event Handlers - Modal Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Make the API call to delete the Event Type, when the
	 * user confirms the deletion via the modal
	 */
	executeEventLocationDeletion = async () => {
		const { deleteClubLocation, fireFlashMessage } = this.props
		const { locationToDelete } = this.state

		await setStateAsync(this, { loading: true })
		const [deleteErr] = await to(deleteClubLocation(locationToDelete) as any)
		if (deleteErr) {
			fireFlashMessage(`Problem trying to delete Location. ${deleteErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true, locationToDelete: null })
			return
		}

		fireFlashMessage('Successfully deleted Location.', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, locationToDelete: null })
		await this.handleCloseModal(ShowingDeletionModal)
	}

	handleOpenModal = async (modalStateKey: ModalStateKey) => {
		await setStateAsync(this, () => ({ [modalStateKey]: true }))
	}

	handleCloseModal = async (modalStateKey: ModalStateKey) => {
		const newState: Partial<State> = {
			[modalStateKey]: false,
			locationToEdit: null,
			locationToDelete: null,
		}
		await setStateAsync(this, () => newState)
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Dropdown Actions
	// ----------------------------------------------------------------------------------

	/**
	 * Determines which action to take, based on which dropdown item
	 * the user selected in the table's dropdown menu
	 */
	handleTableDropdownAction = (e: any) => {
		const [action, value] = e
		switch (action) {
			case EventLocationTableActions.Edit:
				return this.handleEditEventLocation(value)
			case EventLocationTableActions.Delete:
				return this.handleDeleteEventLocation(value)
			default:
				break
		}
	}

	handleEditEventLocation = async (locationID: string) => {
		await setStateAsync(this, { locationToEdit: locationID })
		await this.handleOpenModal(ShowingLocalEditModal)
	}

	handleDeleteEventLocation = async (locationID: string) => {
		await setStateAsync(this, { locationToDelete: locationID })
		await this.handleOpenModal(ShowingDeletionModal)
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Handle table pagination
	 */
	handlePage = async (data: any) => {
		const { pageIndex } = this.state

		// Check if the new page is different that the current one
		if (data.selected !== pageIndex) {
			await setStateAsync(this, { pageIndex: data.selected })
		}
	}

	/**
	 * Handle a column header of the table being selected
	 */
	handleTableHeaderAction = (e: any) => {
		// TODO: Add this functionality
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildTable = () => {
		const { pageIndex, loading } = this.state

		const locations = oc(this).props.loggedInClub.locations([])
		const total = locations.length

		const startIdx = pageIndex * this.pageSize
		const endIdx = startIdx + this.pageSize
		const locationsToShow = locations.slice(startIdx, endIdx)

		return (
			<div className='eventLocations-table-container'>
				<TableComponent<core.Club.ResourceType>
					columns={EventLocationTableColumns}
					rowItems={locationsToShow}
					showPaging={true}
					currentPage={pageIndex}
					pageHandler={this.handlePage}
					totalResults={total}
					pageSize={this.pageSize}
					actionHandler={this.handleTableHeaderAction}
					dropdownHandler={this.handleTableDropdownAction}
					isLoading={loading}
				/>
			</div>
		)
	}

	buildEditModal = () => {
		const { showingEditModal, onEditModalClose } = this.props
		const { showingLocalEditModal, locationToEdit } = this.state

		if (!showingEditModal && !showingLocalEditModal) { return null }
		const locations = oc(this).props.loggedInClub.locations([])
		const existingLocation = locations.find((loc) => `${loc._id}` === locationToEdit)
		const modalTitle = (existingLocation) ? 'Edit Location' : 'New Location'

		return (
			<NewLocationModal
				modalTitle={modalTitle}
				locationToEdit={existingLocation}
				submitButtonHandler={async (loc: core.SubModels.Location.Model) => {
					await this.handleCloseModal(ShowingLocalEditModal)
					onEditModalClose()
				}}
				cancelButtonHandler={async () => {
					await this.handleCloseModal(ShowingLocalEditModal)
					onEditModalClose()
				}}
			/>
		)
	}

	buildDeletionModal = () => {
		if (!this.state.showingDeletionModal) { return null }
		return (
			<ModalComponent
				modalTitle={'Delete Location'}
				primaryMessage={'Are you sure you want to delete this Location?'}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={() => this.handleCloseModal(ShowingDeletionModal)}
				submitButtonName={'Confirm'}
				submitButtonHandler={this.executeEventLocationDeletion}
			/>
		)
	}

	render() {
		return (
			<>
				{this.buildTable()}
				{this.buildEditModal()}
				{this.buildDeletionModal()}
			</>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	loggedInClub: state.club.loggedInClub
})

const mapDispatchToProps = {
	...AlertActions,
	...ClubActions
}

const enhance = compose<React.ComponentType<ComponentProps>>(
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(EventLocationsComponent)
