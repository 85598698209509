// External Dependencies
import * as Yup from 'yup'
import * as core from 'club-hub-core'

// Internal Dependencies
import { FormInput, FormInputType, InputSelectionItem } from '../../Shared/Form'

// Helpers
import { clubTypeSelectInputs } from '../../../helpers/form'
import * as Constants from '../../../constants/index'

export interface ClubSettingsFormState {
	name: string,
	logo?: core.SubModels.Image.Model | File[],
	type?: InputSelectionItem,
	domain?: string,
	customDomain?: string,
	primaryName?: string,
	secondaryName?: string,
	coverImage?: core.SubModels.Image.Model | File[],
	iosAppURL?: string,
	androidAppURL?: string,
	canInviteMembers?: InputSelectionItem
}

export const ClubSettingsFormInputs = (primaryColors: InputSelectionItem[], secondaryColors: InputSelectionItem[]): FormInput[] => [
	{
		title: 'Name',
		property: 'name',
		type: FormInputType.TEXT,
		size: 12,
		placeholder: 'Name',
		defaultValue: '',
		validation: Yup.string().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Logo',
		property: 'logo',
		type: FormInputType.FILE,
		size: 12,
		defaultValue: '',
		file_accept: '.png,.jpg,.jpeg',
		file_multipleFiles: false,
	},
	{
		title: 'Custom Domain',
		property: 'customDomain',
		type: FormInputType.TEXT,
		size: 6,
		placeholder: 'Enter custom domain...',
		defaultValue: '',
	},
	{
		title: 'Website',
		property: '',
		type: FormInputType.SECTION,
	},
	{
		title: 'Hero Title',
		property: 'primaryName',
		type: FormInputType.TEXT,
		size: 6,
		placeholder: 'Enter Hero Title...',
		defaultValue: '',
	},
	{
		title: 'Hero Subtitle',
		property: 'secondaryName',
		type: FormInputType.TEXT,
		size: 6,
		placeholder: 'Enter Hero Subtitle...',
		defaultValue: '',
	},
	{
		title: 'Hero Image',
		property: 'coverImage',
		type: FormInputType.FILE,
		size: 12,
		defaultValue: '',
		file_accept: '.png,.jpg,.jpeg',
		file_multipleFiles: false,
	},
	{
		title: 'Mobile Apps',
		property: '',
		type: FormInputType.SECTION,
	},
	{
		title: 'iOS App URL',
		property: 'iosAppURL',
		type: FormInputType.TEXT,
		size: 6,
		placeholder: 'Enter iOS app url...',
		defaultValue: '',
	},
	{
		title: 'Android App URL',
		property: 'androidAppURL',
		type: FormInputType.TEXT,
		size: 6,
		placeholder: 'Enter Android app url...',
		defaultValue: '',
	},
	{
		title: 'Event Settings',
		property: '',
		type: FormInputType.SECTION,
	},
	{
		title: 'Can Invite Members',
		property: 'canInviteMembers',
		type: FormInputType.SELECT,
		size: 6,
		selectItems: [{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }],
		defaultValue: { label: 'Yes', value: 'false' },
	},
]
