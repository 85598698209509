// External Dependencies
import * as Yup from 'yup'

import { FormInputType, FormInput } from '../../Shared/Form'

export interface EditStatusFormState {
	status: string
}

export const EditStatusModalInputs: FormInput[] = [
	{
		title: 'Status',
		property: 'status',
		type: FormInputType.TEXT_AREA,
		size: 12,
		placeholder: 'Enter a status update...',
		defaultValue: '',
		validation: Yup.string().required('Required.')
	}
]
