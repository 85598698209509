// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as moment from 'moment'
import Color from 'color'
import { oc } from 'ts-optchain'

// Internal Dependencies

// SCSS Colors
// @ts-ignore
import colors from '../../../assets/__base.scss'

// Helpers
import { setStateAsync } from '../../../helpers/promise'
import { calendarIDForEvent } from '../../../helpers/event'

// Calendar Imports
import BigCalendar, { View } from 'react-big-calendar'
const localizer = BigCalendar.momentLocalizer(moment)

import CalendarTopBar from './CalendarTopBar'
import EventView from './EventView'

export interface BigCalendarEvent {
	title: string,
	start: Date,
	end: Date,
	allDay?: boolean,
	resource?: core.Event.Model,
}

export interface BigCalendarSlotInfo {
	start: string | Date
	end: string | Date
	slots: Date[] | string[]
	action: 'select' | 'click' | 'doubleClick'
}

export interface EventStyle {
	primary: string
	secondary: string
}

interface ComponentProps {
	styles?: any
	view: View
	isCustomerView: boolean
	events: core.Event.Model[]
	calendars?: core.Calendar.Model[]
	handleViewSelected: (view: View) => void
	handleEventSelected: (event: BigCalendarEvent, e: React.SyntheticEvent<HTMLElement>) => void
	handleSlotSelected: (slotInfo: BigCalendarSlotInfo) => void
}

const initialState = {
	currentDate: new Date() as Date,
}

type Props = ComponentProps
type State = typeof initialState

/**
 * Calendar Component
 */
class CHCalendar extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Handlers
	// ----------------------------------------------------------------------------------

	defaultStyle = (style: EventStyle, pastEvent: boolean) => {
		const primary = document.body.style.getPropertyValue('--primary-color')
		const color = oc(style).primary(primary.trim())
		const lightColor = Color(color.trim()).lighten(0.5).hex()
		const background = pastEvent ? lightColor : color
		const opacity = pastEvent ? 0.5 : 1.0

		if (this.props.view === BigCalendar.Views.MONTH || !this.props.view) {
			return {
				opacity: opacity,
				backgroundColor: 'transparent'
			}
		}
		return {
			backgroundColor: background,
			color: colors.MeshWhite,
			border: '1px solid white',
			borderRadius: '4px',
		}
	}

	monthViewStyle = (style: EventStyle) => {
		return {
			background: 'transparent',
			color: colors.MeshTextPrimary,
			border: 'none',
		}
	}

	handleStyleEvent = (event: BigCalendarEvent) => {
		const now = new Date()
		const pastEvent = (now.toISOString() > event.end.toISOString())
		const calendarID = calendarIDForEvent(event.resource)

		const style: EventStyle = this.props.styles[`${calendarID}`]
		const currentStyle = this.props.view === BigCalendar.Views.MONTH ?
			this.defaultStyle(style, pastEvent) :
			this.defaultStyle(style, pastEvent)
		return {
			className: `calendar-${calendarID}-style`,
			style: currentStyle
		}
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildEvents = (): BigCalendarEvent[] => {
		const events = this.props.events
		if (!events) {
			return []
		}
		return events.map<BigCalendarEvent>((event: core.Event.Model) => {
			return {
				start: new Date(event.start),
				end: new Date(event.end),
				title: event.name,
				resource: event
			}
		})
	}

	handleNavigate = (newDate: Date, view: View, action: any) => {
		setStateAsync(this, { currentDate: newDate })
	}

	public render() {
		const components = {
			toolbar: CalendarTopBar,
			month: {
				event: EventView
			}
		}
		const events: BigCalendarEvent[] = this.buildEvents()
		return (
			<BigCalendar<BigCalendarEvent, Event>
				localizer={localizer}
				components={components as any}
				events={events}
				date={this.state.currentDate}
				onNavigate={this.handleNavigate}
				onView={this.props.handleViewSelected}
				selectable={!this.props.isCustomerView}
				onSelectEvent={this.props.handleEventSelected}
				onSelectSlot={this.props.handleSlotSelected}
				eventPropGetter={this.handleStyleEvent}
				views={['week', 'month', 'day']}
				view={this.props.view}
				timeslots={1}
				defaultView={BigCalendar.Views.MONTH}
				scrollToTime={new Date(1970, 1, 1, 9)}
				step={60}
			/>
		)
	}
}

export default CHCalendar
