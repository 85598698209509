import { indexBy, values, filter } from 'underscore'
import { MongoObject, CoreModelID } from 'club-hub-core'
import { oc } from 'ts-optchain'

/**
 * Override for core types. The '_id' prop is not actually
 * and objectID once it gets serialized - its a string to the clients
 */
export interface CoreObject extends Omit<MongoObject, '_id'> {
	_id?: string | CoreModelID
}

/**
 * Takes in a collection of existing Model objects and either appends or replaces
 * the new Model object
 * @param events
 * @param updatedEvent
 */
export const mergeUpdatedModelObject = <T extends CoreObject>(updated: T, collection: T[]): T[] => {
	const shallowCopy = [...collection]
	if (!oc(updated)._id(undefined)) {
		return shallowCopy
	}

	const index = shallowCopy.findIndex((e) => e._id === updated._id)
	if (index === -1) {
		shallowCopy.push(updated)
	} else {
		shallowCopy[index] = updated
	}
	return shallowCopy
}

/**
 * Takes in a collection of existing Model objects and either appends or replaces
 * the new Model object
 * @param events
 * @param updatedEvent
 */
export const mergeUpdatedModelObjects = <T extends CoreObject>(updated: T[], collection: T[]): T[] => {
	const indexedCollection = indexBy(collection, '_id')
	for (const obj of updated) {
		// Guard for undefined
		if (!oc(obj)._id(undefined)) {
			continue
		}
		indexedCollection[`${obj._id}`] = obj
	}

	return values(indexedCollection)
}

/**
 * Takes in a collection of existing Model objects and an ID, and filters out
 * the matching model object
 * @param events
 * @param updatedEvent
 */
export const removeObject = <T extends CoreObject>(toRemove: string, collection: T[]): T[] => {
	return filter(collection, (obj: T) => `${obj._id}` !== toRemove)
}

/**
 * Takes in a collection of existing Model objects and either appends or replaces
 * the new Model object
 * @param events
 * @param updatedEvent
 */
export const removeObjects = <T extends CoreObject>(toRemove: string[], collection: T[]): T[] => {
	const indexedCollection = indexBy(collection, '_id')
	for (const removeID of toRemove) {
		delete indexedCollection[removeID]
	}
	return values(indexedCollection)
}
