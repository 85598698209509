// External Dependencies
import * as core from 'club-hub-core'
import * as Feather from 'react-feather'

// Internal Dependencies
import { TableColumn } from '../Shared/Table'
import { TableColumnType } from '../Shared/Table/Primitives'

export interface SectionTableItem extends core.Section.Model {
	statusWithColor: { title: string, color: string },
	createdByUser: core.User.Model,
}

export enum SectionTableActions {
	ViewSection = 'viewSection',
	RenameSection = 'renameSection',
	RemoveSection = 'removeSection'
}

export const SectionTableColumns: TableColumn[] = [
	{
		title: '',
		property: '',
		type: TableColumnType.CHECKBOX,
		action: '',
	},
	{
		title: 'Name',
		property: 'name',
		type: TableColumnType.TEXT,
		action: 'name'
	},
	{
		title: 'Status',
		property: 'statusWithColor',
		type: TableColumnType.BADGE,
		action: 'status',
	},
	{
		title: 'Pages',
		property: 'pages',
		type: TableColumnType.ARRAY_LENGTH,
		action: 'pages'
	},
	{
		title: 'Created By',
		property: 'createdByUser',
		type: TableColumnType.AVATAR_AND_NAME,
		action: 'createdBy',
	},
	{
		title: 'Last Updated',
		property: 'updatedAt',
		type: TableColumnType.DATE_TIME,
		action: 'lastUpdated'
	},
	{
		title: '',
		property: '',
		type: TableColumnType.DROPDOWN,
		class: 'table-dropdown-menu',
		dropdownItems: [
			{
				action: SectionTableActions.ViewSection,
				valueProperty: '_id',
				displayText: 'View',
				icon: Feather.Eye
			},
			{
				action: SectionTableActions.RenameSection,
				valueProperty: '_id',
				displayText: 'Edit Section Info',
				icon: Feather.Edit2,
			},
			{
				action: SectionTableActions.RemoveSection,
				valueProperty: '_id',
				displayText: 'Remove Section',
				icon: Feather.Trash
			},
		]
	}
]
