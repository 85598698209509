// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { FormikProps } from 'formik'
import { oc } from 'ts-optchain'

// Internal Dependencies

// UI
import ModalComponent from '../../../Shared/Modal'

// Form
import { SectionPageFormInputs, SectionPageFormState } from './form'
import { BuildWrappedForm, FormikComponent } from '../../../Shared/Formik'

// Actions
import { AlertActions } from '../../../../actions/index'

// Helpers

type ConnectedActions = typeof mapDispatchToProps

interface ComponentProps {
	cancelButtonHandler: () => any
	submitButtonHandler: (sectionName: string) => any
	title?: string
	formResource?: core.Section.Page
}

type Props = ConnectedActions & ComponentProps
type State = any

/**
 * The `NewSectionPageModal` provides UI for creating a new Section Page.
 */
class NewSectionPageModal extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
	}

	// ----------------------------------------------------------------------------------
	// Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Handles submit button clicks.
	 */
	handleSubmitButton = async (formState: SectionPageFormState) => {
		this.props.submitButtonHandler(formState.name)
	}

	/**
	 * Handles cancel button clicks.
	 */
	handleCancelButton = async () => {
		return this.props.cancelButtonHandler()
	}

	// ----------------------------------------------------------------------------------
	// Component Builds
	// ----------------------------------------------------------------------------------

	buildModal = (formikProps: FormikProps<SectionPageFormState>) => {
		const wrappedFormProps = { inputs: SectionPageFormInputs }
		const title = oc(this).props.title('New Page')
		return (
			<ModalComponent
				modalTitle={title}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.handleCancelButton}
				submitButtonName={'Save'}
				submitButtonHandler={formikProps.submitForm}
				buildInputs={() => BuildWrappedForm(wrappedFormProps, formikProps)}
				submitting={formikProps.isSubmitting}
			/>
		)
	}

	public render() {
		return (
			<FormikComponent
				formResource={this.props.formResource}
				enableReinitialize={false}
				inputs={SectionPageFormInputs}
				onSubmit={this.handleSubmitButton}
				render={this.buildModal}
			/>
		)
	}
}

const mapDispatchToProps = {
	...AlertActions
}

const enhance = compose<React.ComponentType<ComponentProps>>(
	connect(undefined, mapDispatchToProps)
)

export default enhance(NewSectionPageModal)
