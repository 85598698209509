// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import { Link } from 'react-router-dom'
import { oc } from 'ts-optchain'

// Internal Dependencies
import { InfoCardData } from '../Shared/Cards/InfoCard'

// Cars
import { PersonalCard } from './cards/personal'
import { AddressCard } from './cards/address'
import { ContactCard } from './cards/contact'
import { MembershipCard } from './cards/membership'
import { CollectionCard } from './cards/collection'
import { CardSubtitle } from 'reactstrap'

export const OtherMemberCards = (
	user: core.User.Model,
	club: core.Club.Model,
	canAddVehicles: boolean,
	publicContact: boolean,
	editVehicleHandler: any): InfoCardData[] => {
	const cards = []
	if (publicContact) {
		cards.push(ContactCard(user))

		if (club.type.toUpperCase() as any === core.Club.Type.Car.toUpperCase()) {
			cards.push(AddressCard(user))
			cards.push(PersonalCard(user))
			cards.push(CollectionCard(user, club, canAddVehicles, editVehicleHandler))
		}
	}
	return cards
}

export const CurrentMemberCards = (user: core.User.Model, club: core.Club.Model, editVehicleHandler: any) => {
	const cards: InfoCardData[] = [
		ContactCard(user),
		PersonalCard(user),
	]

	// Conditionally add address
	const primaryAddress = oc(user).addresses([]).find((address) => address.primary)
	if (primaryAddress) {
		cards.push(AddressCard(primaryAddress))
	}

	cards.push(
		MembershipCard(user, club)
	)

	if (club.type.toUpperCase() as any === core.Club.Type.Car.toUpperCase()) {
		cards.push(CollectionCard(user, club, true, editVehicleHandler))
	}
	return cards
}

export const AdminCards = (user: core.User.Model, club: core.Club.Model, editVehicleHandler: any): InfoCardData[] => {
	const cards: InfoCardData[] = [
		ContactCard(user),
		PersonalCard(user),
	]

	// Conditionally add address
	const primaryAddress = oc(user).addresses([]).find((address) => address.primary)
	if (primaryAddress) {
		cards.push(AddressCard(primaryAddress))
	}

	cards.push(
		MembershipCard(user, club)
	)

	if (club.type.toUpperCase() as any === core.Club.Type.Car.toUpperCase()) {
		cards.push(CollectionCard(user, club, true, editVehicleHandler))
	}
	return cards
}
