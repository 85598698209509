// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as RS from 'reactstrap'
import { connect } from 'react-redux'
import to from 'await-to-js'
import { oc } from 'ts-optchain'
import { DropResult } from 'react-beautiful-dnd'

// Internal Dependencies

// Actions
import { ClubActions, AlertActions } from '../../../actions/index'

// State
import { RootReducerState } from '../../../reducers'

// Components
import QuickLinkForm from '../QuickLinkForm'
import ModalComponent from '../../Shared/Modal'

// Form
import { QuickLinkFormState } from '../QuickLinkForm/form'

// Table
import { QuickLinkColumns, TableAction } from './table'
import TableComponent from '../../Shared/Table'

// Helpers
import { setStateAsync } from '../../../helpers/promise'
import { FlashType } from '../../../constants'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

interface ComponentProps {
	club: core.Club.Model
}

const initialState = {
	loading: false as boolean,
	showModal: false as boolean,
	quickLinkToEdit: null as core.Club.QuickLink,
	quickLinkToDelete: null as core.Club.QuickLink
}

type Props = ComponentProps & ConnectedActions & ConnectedState
type State = typeof initialState

class QuickLinkSection extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Table Action Handlers
	// ----------------------------------------------------------------------------------

	onTableDropdown = async (actionArray: [TableAction, number]) => {
		const [action, index] = actionArray
		const quickLink = this.props.club.website.quickLinks[index]
		switch (action) {
			case TableAction.Edit:
				await setStateAsync(this, { showModal: true, quickLinkToEdit: quickLink })
				break
			case TableAction.Delete:
				await setStateAsync(this, { quickLinkToDelete: quickLink })
				break
		}
	}

	onAddQuickLink = async () => {
		await setStateAsync(this, { showModal: true })
	}

	// ----------------------------------------------------------------------------------
	// Drag & Drop Handler
	// ----------------------------------------------------------------------------------

	onDrop = (result: DropResult) => {
		// Todo Implement
	}

	// ----------------------------------------------------------------------------------
	// Quick Link Form Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Saves a new quick link.
	 */
	handleSaveQuickLink = async (form: QuickLinkFormState) => {
		this.setState({ loading: true })
		const payload = this.buildQuickLinkPayload(form)
		const [err] = await to(this.props.createQuickLink(payload, `${this.props.club._id}`) as any)
		if (err) {
			this.props.fireFlashMessage('Oops, something went wrong. Please try again', FlashType.DANGER)
			this.setState({ loading: false })
			return
		}
		this.props.fireFlashMessage(`Quick Link Created.`, FlashType.SUCCESS)
		this.setState({ loading: false, showModal: false, quickLinkToDelete: null, quickLinkToEdit: null })
	}

	/**
	 * Updates an exiting quick link.
	 */
	handleUpdateQuickLink = async (form: QuickLinkFormState) => {
		this.setState({ loading: true })
		const editID = `${this.state.quickLinkToEdit._id}`
		const payload = this.buildQuickLinkPayload(form)
		const [err] = await to(this.props.updateQuickLink(payload, editID, `${this.props.club._id}`) as any)
		if (err) {
			this.props.fireFlashMessage('Oops, something went wrong. Please try again', FlashType.DANGER)
			this.setState({ loading: false })
			return
		}
		this.props.fireFlashMessage(`Quick Link Update.`, FlashType.SUCCESS)
		this.setState({ loading: false, showModal: false, quickLinkToDelete: null, quickLinkToEdit: null })
	}

	/**
	 * Deletes an existing quick link.
	 */
	handleDeleteQuickLink = async () => {
		this.setState({ loading: true })
		const deleteID = `${this.state.quickLinkToDelete._id}`
		const [err] = await to(this.props.deleteQuickLink(deleteID, `${this.props.club._id}`) as any)
		if (err) {
			this.props.fireFlashMessage('Oops, something went wrong. Please try again', FlashType.DANGER)
			this.setState({ loading: false })
			return
		}
		this.props.fireFlashMessage(`Quick Link Update.`, FlashType.SUCCESS)
		this.setState({ loading: false, showModal: false, quickLinkToDelete: null, quickLinkToEdit: null })
	}

	/**
	 * Build the form data for a quick link.
	 */
	buildQuickLinkPayload = (form: QuickLinkFormState) => {
		const formPayload = new FormData()
		formPayload.append('body', JSON.stringify(form))
		if (form.image) {
			const formImage: File | core.SubModels.Image.Model = form.image[0]
			formPayload.append('image', formImage)
		}
		return formPayload
	}

	/**
	 * Handles closing the quick link form.
	 */
	handleCloseQuickLinkForm = async () => {
		await setStateAsync(this, { showModal: false })
	}

	// ----------------------------------------------------------------------------------
	// Delete Confirmation Form Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Handles closing the delete confirmation form.
	 */
	closeDeleteQuickLinkForm = async () => {
		await setStateAsync(this, { quickLinkToDelete: null })
	}

	// ----------------------------------------------------------------------------------
	// Network Requests
	// ----------------------------------------------------------------------------------

	/**
	 * Updates the website subdocument for a club.
	 */
	updateWebsite = async (website: core.Club.Website, action: string) => {

		const [err] = await to(this.props.updateClubSubDocument('website', website) as any)
		if (err) {
			this.props.fireFlashMessage('Oops, something went wrong. Please try again', FlashType.DANGER)
			return
		}
		this.props.fireFlashMessage(`Quick Link ${action}.`, FlashType.SUCCESS)
		await setStateAsync(this, { showModal: false, quickLinkToDelete: null, quickLinkToEdit: null })
	}

	// ----------------------------------------------------------------------------------
	// UI Builders
	// ----------------------------------------------------------------------------------

	buildQuickLinkModal = () => {
		if (!this.state.showModal) { return null }

		const saveHandler = this.state.quickLinkToEdit ?
			this.handleUpdateQuickLink :
			this.handleSaveQuickLink

		return (
			<QuickLinkForm
				submitting={this.state.loading}
				quickLink={this.state.quickLinkToEdit}
				saveHandler={saveHandler}
				closeHandler={this.handleCloseQuickLinkForm}
			/>
		)
	}

	buildQuickLinkDeletionModal = () => {
		if (!this.state.quickLinkToDelete) { return null }
		return (
			<ModalComponent
				modalTitle={'Delete Quick Link'}
				primaryMessage={'Are you sure you want to delete this Quick Link?'}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.closeDeleteQuickLinkForm}
				submitButtonName={'Confirm'}
				submitButtonHandler={this.handleDeleteQuickLink}
			/>
		)
	}

	// ----------------------------------------------------------------------------------
	// Render
	// ----------------------------------------------------------------------------------

	buildTableData = () => {
		const quickLinks = this.props.club.website.quickLinks
		return quickLinks.map((quickLink: core.Club.QuickLink, idx: number) => {
			const _id = `${idx}`
			const rowIdx = idx
			const title = quickLink.title
			const link = quickLink.link
			const draggableId = idx
			return { _id, rowIdx, title, link, draggableId }
		})
	}

	buildAddButton = () => {
		return (
			<div className='settings-quick-link__add-container'>
				<RS.Button
					className='settings-quick-link__settings-button'
					color='primary'
					onClick={this.onAddQuickLink}
				>
					Add Quick Link
				</RS.Button>
			</div>
		)
	}

	render() {
		const tableColumns = QuickLinkColumns
		const rowItems = this.buildTableData()
		return (
			<div className='settings-quick-link__container'>
				<p>Configure quick links for members.</p>
				<TableComponent
					columns={tableColumns}
					rowItems={rowItems}
					showPaging={false}
					enableDragging={true}
					dropdownHandler={this.onTableDropdown}
					onDrop={this.onDrop}
				/>

				{this.buildAddButton()}
				{this.buildQuickLinkModal()}
				{this.buildQuickLinkDeletionModal()}
			</div>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	userState: state.user,
})

const mapDispatchToProps = {
	...ClubActions,
	...AlertActions,
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickLinkSection)
