// External Dependencies
import { Dispatch } from 'redux'

// Internal Dependencies

// API Helpers
import * as helperActions from '../helpers/api'

// -----------------------------------------------------------------------------
// Google API
// -----------------------------------------------------------------------------

/**
 * Requests the Google Oauth URL from our server.
 */
const submitGoogleLogin = () => (dispatch: Dispatch<any>) => {
	return helperActions.GET('/login/google', null)
		.catch((err) => Promise.reject(err))
}

/**
 * Sends the authorization code to the server after oauth.
 */
const submitGoogleOauth = (payload: string) => (dispatch: Dispatch<any>) => {
	return helperActions.GET(`/oauth/google`, { code: payload })
		.catch((err) => Promise.reject(err))
}

// -----------------------------------------------------------------------------
// ICal Imports
// -----------------------------------------------------------------------------

/**
 * Makes a post request to the server.
 */
const submitICalLink = (payload: string) => (dispatch: Dispatch<any>) => {
	return helperActions.POST('/calendars/ical', { iCal: payload })
		.catch((err) => Promise.reject(err))
}

export const OauthActions = {
	submitGoogleLogin,
	submitGoogleOauth,
	submitICalLink,
}
