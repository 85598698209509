// External Dependencies
import * as React from 'react'
import * as Feather from 'react-feather'
import * as RS from 'reactstrap'

interface ButtonRowProps {
	disableButton?: boolean
	submitButtonName?: string
	submitButtonHandler?: () => any
	cancelButtonName?: string
	cancelButtonHandler?: () => any
	removeButtonName?: string
	removeButtonHandler?: () => any
}

/**
 * Returns a button row with submit and cancel.
 * Flexed with space between.
 * @param props PassedPropsInterface
 */
const ButtonRow: React.FC<ButtonRowProps> = (props: ButtonRowProps) => {
	return (
		<div className='button-row-container'>
			<RS.Col xs={3}>
				<button
					className='btn btn-secondary'
					onClick={props.cancelButtonHandler}
				>
					{props.cancelButtonName}
				</button>
			</RS.Col>
			<RS.Col xs={9} className='d-flex justify-content-end'>

				{!props.removeButtonName ? null : (
					<button
						className={`btn remove-button`}
						onClick={props.removeButtonHandler}
					>
						{props.removeButtonName}
					</button>
				)}

				<button
					className='btn btn-primary'
					disabled={props.disableButton}
					onClick={props.submitButtonHandler}
				>
					{props.submitButtonName}
				</button>

			</RS.Col>
		</div>
	)
}

export default ButtonRow
