// External Dependencies
import * as core from 'club-hub-core'
import { Dispatch, Action } from 'redux'
import to from 'await-to-js'

// Internal Dependencies

// API Helpers
import { POST, GET, PUT, DELETE, PUT_FORM, POST_FORM } from '../helpers/api'

export enum TypeKeys {
	FETCHED_CLUBS = 'FETCHED_CLUBS',
	FETCHED_CLUB = 'FETCHED_CLUB',
	UPDATED_CLUB = 'UPDATED_CLUB',
	CREATED_CLUB_LOCATION = 'CREATED_CLUB_LOCATION',
	UPDATED_CLUB_LOCATION = 'UPDATED_CLUB_LOCATION',
	DELETED_CLUB_LOCATION = 'DELETED_CLUB_LOCATION',
	CREATED_CLUB_TYPE = 'CREATED_CLUB_TYPE',
	UPDATED_CLUB_TYPE = 'UPDATED_CLUB_TYPE',
	UPDATED_CLUB_SUB_DOCUMENT = 'UPDATED_CLUB_SUB_DOCUMENT',
	UPDATED_CLUB_TYPES = 'UPDATED_CLUB_TYPES',
	DELETED_CLUB_TYPE = 'DELETED_CLUB_TYPE',
	FETCHED_CLUB_CONTENT_LINKS = 'FETCHED_CLUB_CONTENT_LINKS',
	UPDATED_CALENDAR_GROUP = 'UPDATED_CALENDAR_GROUP',
}

export type ActionTypes =
	| FetchedClubsAction
	| FetchedClubAction
	| UpdatedClubAction
	| CreatedClubLocationAction
	| UpdatedClubLocationAction
	| DeletedClubLocationAction
	| CreatedClubTypeAction
	| UpdatedClubTypeAction
	| UpdatedClubTypesAction
	| DeletedClubTypeAction
	| UpdatedCalendarGroupAction
	| UpdatedClubSubDocumentAction
	| FetchedClubLinksTypeAction

export interface FetchedClubsAction extends Action {
	type: TypeKeys.FETCHED_CLUBS,
	clubs: core.Club.Model[],
}

export interface FetchedClubAction extends Action {
	type: TypeKeys.FETCHED_CLUB,
	club: core.Club.Model,
}

export interface UpdatedClubAction extends Action {
	type: TypeKeys.UPDATED_CLUB,
	club: core.Club.Model,
}

export interface CreatedClubLocationAction extends Action {
	type: TypeKeys.CREATED_CLUB_LOCATION,
	club: core.Club.Model,
}

export interface UpdatedClubLocationAction extends Action {
	type: TypeKeys.UPDATED_CLUB_LOCATION,
	club: core.Club.Model,
}

export interface DeletedClubLocationAction extends Action {
	type: TypeKeys.DELETED_CLUB_LOCATION,
	club: core.Club.Model,
}

export interface CreatedClubTypeAction extends Action {
	type: TypeKeys.CREATED_CLUB_TYPE,
	club: core.Club.Model,
}

export interface UpdatedClubTypeAction extends Action {
	type: TypeKeys.UPDATED_CLUB_TYPE,
	club: core.Club.Model,
}

export interface UpdatedClubTypesAction extends Action {
	type: TypeKeys.UPDATED_CLUB_TYPES,
	club: core.Club.Model,
}

export interface DeletedClubTypeAction extends Action {
	type: TypeKeys.DELETED_CLUB_TYPE,
	club: core.Club.Model,
}

export interface FetchedClubLinksTypeAction extends Action {
	type: TypeKeys.FETCHED_CLUB_CONTENT_LINKS,
	links: core.SubModels.ContentLink.ClubContentLinks,
}

export interface UpdatedCalendarGroupAction extends Action {
	type: TypeKeys.UPDATED_CALENDAR_GROUP,
	club: core.Club.Model,
}

export interface UpdatedClubSubDocumentAction extends Action {
	type: TypeKeys.UPDATED_CLUB_SUB_DOCUMENT,
	club: core.Club.Model,
}

// -----------------------------------------------------------------------------
// Fetch Clubs
// -----------------------------------------------------------------------------

const fetchClubs = () => async (dispatch: Dispatch<FetchedClubsAction>) => {
	const [err, res] = await to<core.Club.Model[]>(GET('/clubs'))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to fetch Clubs with error: ${err}`)
		throw err
	}

	await dispatch(fetchedClubs(res))
}

const fetchedClubs = (clubs: core.Club.Model[]): FetchedClubsAction => {
	const action: FetchedClubsAction = {
		type: TypeKeys.FETCHED_CLUBS,
		clubs: clubs,
	}
	return action
}

// -----------------------------------------------------------------------------
// Fetch Clubs
// -----------------------------------------------------------------------------

export const fetchedClub = (club: core.Club.Model): FetchedClubAction => {
	const action: FetchedClubAction = {
		type: TypeKeys.FETCHED_CLUB,
		club: club,
	}
	return action
}

// -----------------------------------------------------------------------------
// Update Club Types
// -----------------------------------------------------------------------------

const updateClub = (clubID: string, clubPayload: FormData) => async (dispatch: Dispatch<UpdatedClubAction>) => {
	const [err, res] = await to<core.Club.Model>(PUT_FORM(`/admin/clubs/${clubID}`, clubPayload))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to update Club with error: ${err}`)
		throw err
	}

	dispatch(updatedClub(res))
}

const updatedClub = (club: core.Club.Model): UpdatedClubAction => {
	const action: UpdatedClubAction = {
		type: TypeKeys.UPDATED_CLUB,
		club: club
	}
	return action
}

// -----------------------------------------------------------------------------
// Create Club Location
// -----------------------------------------------------------------------------

const createClubLocation = (location: core.SubModels.Location.Model) => async (dispatch: Dispatch<CreatedClubLocationAction>) => {
	const [err, res] = await to<core.Club.Model>(POST(`/admin/clubs/sub/locations`, location))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to create Location with error: ${err}`)
		throw err
	}

	await dispatch(createdClubLocation(res))
}

const createdClubLocation = (club: core.Club.Model): CreatedClubLocationAction => {
	const action: CreatedClubLocationAction = {
		type: TypeKeys.CREATED_CLUB_LOCATION,
		club: club
	}
	return action
}

// -----------------------------------------------------------------------------
// Update Club Location
// -----------------------------------------------------------------------------

const updateClubLocation = (location: core.SubModels.Location.Model) => async (dispatch: Dispatch<UpdatedClubLocationAction>) => {
	const [err, res] = await to<core.Club.Model>(PUT(`/admin/clubs/sub/locations`, location))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to create Location with error: ${err}`)
		throw err
	}

	await dispatch(updatedClubLocation(res))
}

const updatedClubLocation = (club: core.Club.Model): UpdatedClubLocationAction => {
	const action: UpdatedClubLocationAction = {
		type: TypeKeys.UPDATED_CLUB_LOCATION,
		club: club
	}
	return action
}

// -----------------------------------------------------------------------------
// Delete Club Location
// -----------------------------------------------------------------------------

const deleteClubLocation = (locationID: string) => async (dispatch: Dispatch<DeletedClubLocationAction>) => {
	const [err, res] = await to<core.Club.Model>(DELETE(`/admin/clubs/sub/locations/${locationID}`, {}))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to create Location with error: ${err}`)
		throw err
	}

	dispatch(deletedClubLocation(res))
}

const deletedClubLocation = (club: core.Club.Model): DeletedClubLocationAction => {
	const action: DeletedClubLocationAction = {
		type: TypeKeys.DELETED_CLUB_LOCATION,
		club: club
	}
	return action
}

// -----------------------------------------------------------------------------
// Create Club Types
// -----------------------------------------------------------------------------

const createClubType = (clubType: core.Club.ResourceType, typePath: string) => async (dispatch: Dispatch<CreatedClubTypeAction>) => {
	const [err, res] = await to<core.Club.Model>(POST(`/admin/clubs/sub/${typePath}`, clubType))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to create Type with error: ${err}`)
		throw err
	}

	await dispatch(createdClubType(res))

	return res
}

const createdClubType = (club: core.Club.Model): CreatedClubTypeAction => {
	const action: CreatedClubTypeAction = {
		type: TypeKeys.CREATED_CLUB_TYPE,
		club: club
	}
	return action
}

// -----------------------------------------------------------------------------
// Update Club Types
// -----------------------------------------------------------------------------

const updateClubType = (typePath: string, clubType: core.Club.ResourceType) => async (dispatch: Dispatch<UpdatedClubTypeAction>) => {
	const [err, res] = await to<core.Club.Model>(PUT(`/admin/clubs/sub/${typePath}`, clubType))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to update Type with error: ${err}`)
		throw err
	}

	await dispatch(updatedClubType(res))

	return res
}

const updatedClubType = (club: core.Club.Model): UpdatedClubTypeAction => {
	const action: UpdatedClubTypeAction = {
		type: TypeKeys.UPDATED_CLUB_TYPE,
		club: club
	}
	return action
}

const updateClubTypes = (typePath: string, clubTypes: core.Club.ResourceType[]) => async (dispatch: Dispatch<UpdatedClubTypesAction>) => {
	const [err, res] = await to<core.Club.Model>(PUT(`/admin/clubs/sub/${typePath}`, clubTypes))

	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to update Type with error: ${err}`)
		throw err
	}

	dispatch(updatedClubTypes(res))
}

const updatedClubTypes = (club: core.Club.Model): UpdatedClubTypesAction => {
	const action: UpdatedClubTypesAction = {
		type: TypeKeys.UPDATED_CLUB_TYPES,
		club: club
	}
	return action
}

// -----------------------------------------------------------------------------
// Delete Club Type
// -----------------------------------------------------------------------------

const deleteClubType = (typeID: string, typePath: string) => async (dispatch: Dispatch<DeletedClubTypeAction>) => {
	const [err, res] = await to<core.Club.Model>(DELETE(`/admin/clubs/sub/${typePath}/${typeID}`, {}))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to delete Type with error: ${err}`)
		throw err
	}

	dispatch(deletedClubType(res))
}

const deletedClubType = (club: core.Club.Model): DeletedClubTypeAction => {
	const action: DeletedClubTypeAction = {
		type: TypeKeys.DELETED_CLUB_TYPE,
		club: club
	}
	return action
}

// -----------------------------------------------------------------------------
// Delete Club Type
// -----------------------------------------------------------------------------

const fetchClubContentLinks = () => async (dispatch: Dispatch<FetchedClubLinksTypeAction>) => {
	const [err, res] = await to<core.SubModels.ContentLink.ClubContentLinks>(GET(`/admin/clubs/content_links`, {}))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to delete Type with error: ${err}`)
		throw err
	}

	dispatch(fetchedClubLinks(res))
}

const fetchedClubLinks = (links: core.SubModels.ContentLink.ClubContentLinks): FetchedClubLinksTypeAction => {
	const action: FetchedClubLinksTypeAction = {
		type: TypeKeys.FETCHED_CLUB_CONTENT_LINKS,
		links
	}
	return action
}

// -----------------------------------------------------------------------------
// Quick Links
// -----------------------------------------------------------------------------

const createQuickLink = (form: FormData, clubID: string) => async (dispatch: Dispatch<UpdatedClubAction>) => {
	const [err, res] = await to<core.Club.Model>(POST_FORM(`/admin/clubs/${clubID}/quick_links`, form))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to create Location with error: ${err}`)
		throw err
	}

	await dispatch(updatedClub(res))
}

const updateQuickLink = (form: FormData, quickLinkID: string, clubID: string) => async (dispatch: Dispatch<UpdatedClubAction>) => {
	const [err, res] = await to<core.Club.Model>(PUT_FORM(`/admin/clubs/${clubID}/quick_links/${quickLinkID}`, form))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to create Location with error: ${err}`)
		throw err
	}

	await dispatch(updatedClub(res))
}

const deleteQuickLink = (quickLinkID: string, clubID: string) => async (dispatch: Dispatch<UpdatedClubAction>) => {
	const [err, res] = await to<core.Club.Model>(DELETE(`/admin/clubs/${clubID}/quick_links/${quickLinkID}`, null))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to create Location with error: ${err}`)
		throw err
	}

	await dispatch(updatedClub(res))
}

// -----------------------------------------------------------------------------
// Update Club Sub Document
// -----------------------------------------------------------------------------

const updateClubSubDocument = (typePath: string, sub: any) => async (dispatch: Dispatch<UpdatedClubSubDocumentAction>) => {
	const [err, res] = await to<core.Club.Model>(PUT(`/admin/clubs/sub/${typePath}`, sub))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to update Type with error: ${err}`)
		throw err
	}

	dispatch(updatedClubSubDocument(res))
}

const updatedClubSubDocument = (club: core.Club.Model): UpdatedClubSubDocumentAction => {
	const action: UpdatedClubSubDocumentAction = {
		type: TypeKeys.UPDATED_CLUB_SUB_DOCUMENT,
		club: club
	}
	return action
}

// -----------------------------------------------------------------------------
// Update Calendar
// -----------------------------------------------------------------------------

const updateCalendarGroup = (group: core.Calendar.Group) => async (dispatch: Dispatch<UpdatedCalendarGroupAction>) => {
	const path = `/admin/clubs/sub/calendarGroups`
	const [putErr, putRes] = await to(PUT(path, group))
	if (putErr) {
		// tslint:disable-next-line
		console.error(`Failed to update Calendar with error: ${putErr}`)
		throw putErr
	}
	dispatch(updatedCalendarGroup(putRes))
}

const updatedCalendarGroup = (club: core.Club.Model): UpdatedCalendarGroupAction => {
	const action: UpdatedCalendarGroupAction = {
		type: TypeKeys.UPDATED_CALENDAR_GROUP,
		club,
	}
	return action
}

export const ClubActions = {
	fetchClubs,
	updateClub,
	createClubLocation,
	updateClubLocation,
	deleteClubLocation,
	createClubType,
	updateClubType,
	updateClubTypes,
	deleteClubType,
	createQuickLink,
	updateQuickLink,
	deleteQuickLink,
	fetchClubContentLinks,
	updateCalendarGroup,
	updateClubSubDocument
}
