// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { pick, values } from 'underscore'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies

// Actions
import { GroupActions, AlertActions } from '../../../actions/index'

// State
import { RootReducerState } from '../../../reducers'
import { usersByIDSelector } from '../../../reducers/user'
import { clubUserGroupsByIDSelector } from '../../../reducers/club'

// Components
import DimmedLoader from '../../Shared/DimmedLoader'

// Form
import GenericFormComponent from '../../Shared/Formik/GenericForm'
import { UserGroupInputs, UserGroupFormState, QueryUserGroupInputs } from './form'

// Helpers
import * as Constants from '../../../constants'
import { setStateAsync } from '../../../helpers/promise'
import { userForForm } from '../../../helpers/user'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	error: false,
	loading: true,
	userGroupToUpdate: null as core.User.UserGroup | null,
	usersForForm: [] as core.User.Model[]
}

type Props = RouteComponentProps & ConnectedActions & ConnectedState
type State = typeof initialState

class UserGroupForm extends React.Component<Props, State> {
	private isUpdateForm: boolean

	constructor(props: Props) {
		super(props)

		const { location } = props
		this.isUpdateForm = location.pathname === Constants.UPDATE_USER_GROUP_ROUTE

		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		const { location } = this.props

		// Parse the query string, to see if we need to set state based on it
		const parsedQuery = queryString.parse(location.search)

		if (this.isUpdateForm) {
			await this.setUserGroupForFormWithQueryParams(parsedQuery)
		} else if (!isNullOrUndefined(parsedQuery.userIDs)) {
			await this.setUsersForFormWithQueryParams(parsedQuery)
		}

		await setStateAsync(this, { loading: false })
	}

	/**
	 * Gets the UserGroup, that is being updated, from state using its ID that we get
	 * from the query parameters in the URL.
	 *
	 * The UserGroup will have its information pre-filled into the form.
	 */
	setUserGroupForFormWithQueryParams = async (parsedQuery: { groupID: string }) => {
		const { userGroupsByID } = this.props

		// Get the UserGroup by its name
		const userGroupID = parsedQuery.groupID
		const userGroupToEdit = userGroupsByID[userGroupID]

		// Update the state
		await setStateAsync(this, { userGroupToUpdate: userGroupToEdit })
	}

	setUsersForFormWithQueryParams = async (parsedQuery: { userIDs: string[] }) => {
		const { usersByID } = this.props

		const usersForForm = parsedQuery.userIDs.map((id) => usersByID[id])

		// Update the state
		await setStateAsync(this, { usersForForm })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Returns the user to the Users view when they cancel the form
	 */
	handleCancel = () => {
		this.props.history.push(Constants.USER_GROUP_ROUTE)
	}

	/**
	 * Determines which handler to call (Create or Update) when the user submits the form
	 */
	handleSave = async (form: UserGroupFormState): Promise<void> => {
		switch (this.props.location.pathname) {
			case Constants.CREATE_USER_GROUP_ROUTE:
				return this.handleCreate(form)
			case Constants.UPDATE_USER_GROUP_ROUTE:
				return this.handleUpdate(form)
			default:
				break
		}
	}

	/**
	 * Will create a new UserGroup on the DB with the information in the form.
	 * UserGroups will only be created if the validation conditions for the form are met.
	 */
	handleCreate = async (form: UserGroupFormState): Promise<void> => {
		const { loggedInClub, createUserGroup, fireFlashMessage, history } = this.props
		await setStateAsync(this, { loading: true })

		const clubID = `${loggedInClub._id}`
		const payload = this.buildUserGroupPayload(form)
		const [err] = await to(createUserGroup(clubID, payload) as any)
		if (err) {
			fireFlashMessage(`Problem creating Member Group. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}

		fireFlashMessage('Member Group successfully created.', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, error: false })
		history.push(Constants.USER_GROUP_ROUTE)
	}

	/**
	 * Will update an existing UserGroup on the DB with the information in the form.
	 * UserGroups will only be updated if the validation conditions for the form are met.
	 */
	handleUpdate = async (form: UserGroupFormState): Promise<void> => {
		const { loggedInClub, updateUserGroup, fireFlashMessage, history } = this.props
		const { userGroupToUpdate } = this.state

		if (!userGroupToUpdate) { return }
		await setStateAsync(this, { loading: true })

		const groupID = `${userGroupToUpdate._id}`
		const clubID = `${loggedInClub._id}`
		const payload = this.buildUserGroupPayload(form)
		const [err] = await to(updateUserGroup(clubID, groupID, payload) as any)
		if (err) {
			fireFlashMessage(`Problem updating Member Group. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true, userGroupToUpdate: null })
			return
		}

		fireFlashMessage('Member Group successfully updated.', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, error: false, userGroupToUpdate: null })
		history.push(Constants.USER_GROUP_ROUTE)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	/**
	 * Builds the payload, that will be sent to the server, based on the form
	 */
	buildUserGroupPayload = (form: UserGroupFormState): any => {
		const includeUsers = !this.groupIsQueryFilterBased()
		const payload = {
			name: form.name,
			description: form.description,
			// We want to prevent groups that are generated by Query Filter from having their 'users' array modified
			...((includeUsers) ? { users: form.users.map((member) => member.value) } : {})
		}

		return payload
	}

	groupIsQueryFilterBased = (): boolean => {
		const { userGroupToUpdate } = this.state
		const queryFilter = oc(userGroupToUpdate).queryFilter()
		return !isNullOrUndefined(queryFilter)
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildFormInputs = () => {
		const { userState } = this.props

		if (this.groupIsQueryFilterBased()) {
			return QueryUserGroupInputs()
		}

		const usersForForm = oc(userState).users([]).map(userForForm)
		const formInputs = UserGroupInputs(usersForForm)
		return formInputs
	}

	buildForm = () => {
		const { usersByID } = this.props
		const { userGroupToUpdate, usersForForm } = this.state

		const userGroupForForm = (this.isUpdateForm && userGroupToUpdate) ? userGroupToUpdate : undefined
		const formTitle = (this.isUpdateForm) ? 'Update Member Group' : 'New Member Group'
		const submitButtonName = (this.isUpdateForm) ? 'Update' : 'Save'

		// We have to set the members if we are updating
		let formResource: any
		if (userGroupForForm) {
			const castedUserIDs = userGroupForForm.users as any as string[]
			const usersForFormMap: ReturnType<typeof usersByIDSelector> = pick(usersByID, castedUserIDs)
			const formUsers = values(usersForFormMap).map(userForForm)
			formResource = { ...userGroupForForm, users: formUsers }
		} else if (usersForForm.length > 0) {
			const formUsers = usersForForm.map(userForForm)
			formResource = { users: formUsers }
		}

		return (
			<GenericFormComponent
				title={formTitle}
				inputs={this.buildFormInputs()}
				formResource={formResource}
				enableReinitialize={false}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.handleCancel}
				submitButtonName={submitButtonName}
				submitButtonHandler={this.handleSave}
			/>
		)
	}

	render() {
		const { loading } = this.state

		if (loading) { return <DimmedLoader component={null} isLoading={true} />}

		return (
			<div className='row justify-content-center mt-4 mx-auto'>
				<div className='col col-md-8'>
					{this.buildForm()}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	userState: state.user,
	loggedInClub: state.club.loggedInClub,
	usersByID: usersByIDSelector(state),
	userGroupsByID: clubUserGroupsByIDSelector(state),
})

const mapDispatchToProps = {
	...GroupActions,
	...AlertActions
}

export default connect(mapStateToProps, mapDispatchToProps)(UserGroupForm)
