import * as React from 'react'
import * as Feather from 'react-feather'
import { Link } from 'react-router-dom'
import { RouteComponentProps, withRouter } from 'react-router'
import { History } from 'history'

interface BackHeaderProps {
	to?: History.LocationDescriptor<any>
	backFunc?: () => void
	backTitle: string
	editHandler?: () => void
}

type HeaderProps = BackHeaderProps & RouteComponentProps

class BackHeader extends React.Component<HeaderProps, any> {
	constructor(props: HeaderProps) {
		super(props)
	}

	buildBackButton = () => {
		const primary = document.body.style.getPropertyValue('--primary-color')
		return (
			<div className='back-button-container'>
				<Feather.ChevronLeft size={20} color={primary} />
				{this.buildLinkButton()}
			</div>
		)
	}

	/**
	 * Return either a react link that will navigate the user to the passed in route specified by the 'to' prop.
	 * If no route is passed in, the user will be navigated to the previous route using the react router 'goBack' method.
	 */
	buildLinkButton = () => {
		if (this.props.to) {
			return (
				<Link to={this.props.to} >
					<button className='btn btn-link back-link'>
						{this.props.backTitle}
					</button>
				</Link>
			)
		}
		if (this.props.backFunc) {
			return (
				<button className='btn btn-link back-link' onClick={this.props.backFunc}>
					{this.props.backTitle}
				</button>
			)
		}
		return (
			<button className='btn btn-link back-link' onClick={this.props.history.goBack}>
				{this.props.backTitle}
			</button>
		)
	}

	buildEditButton = () => {
		if (!this.props.editHandler) { return null }

		return (
			<div className='edit-button-container'>
				<button className='btn btn-link back-link' onClick={this.props.editHandler}>
					Edit
				</button>
			</div>
		)
	}

	public render() {
		return (
			<div className='d-flex justify-content-between back-row-container'>
				{this.buildBackButton()}
				{this.buildEditButton()}
			</div >
		)
	}
}

export default withRouter(BackHeader)
