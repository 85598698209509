// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { FormikProps } from 'formik'
import { oc } from 'ts-optchain'

// Internal Dependencies

// UI
import ModalComponent from '../../Shared/Modal'

// Form
import { SectionFormInputs, SectionFormState } from './form'
import { FormikComponent, BuildWrappedForm } from '../../Shared/Formik'

// Actions
import { AlertActions } from '../../../actions/index'

type ConnectedActions = typeof mapDispatchToProps

interface ComponentProps {
	cancelButtonHandler: () => any
	submitButtonHandler: (formState: SectionFormState) => any
	formResource?: core.Section.Model
	title?: string
}

type Props = ConnectedActions & ComponentProps
type State = any

/**
 * The `NewSectionModal` provides UI for creating a new Section.
 */
class NewSectionModal extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
	}

	// ----------------------------------------------------------------------------------
	// Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Handles submit button clicks.
	 */
	handleSubmitButton = async (formState: SectionFormState) => {
		this.props.submitButtonHandler(formState)
	}

	/**
	 * Handles cancel button clicks.
	 */
	handleCancelButton = async () => {
		return this.props.cancelButtonHandler()
	}

	// ----------------------------------------------------------------------------------
	// Component Builds
	// ----------------------------------------------------------------------------------

	buildModal = (formikProps: FormikProps<any>) => {
		const wrappedFormProps = { inputs: SectionFormInputs(this.props.formResource) }
		const title = oc(this).props.title('New Section')
		return (
			<ModalComponent
				modalTitle={title}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.handleCancelButton}
				submitButtonName={'Save'}
				submitButtonHandler={formikProps.submitForm}
				buildInputs={() => BuildWrappedForm(wrappedFormProps, formikProps)}
				submitting={formikProps.isSubmitting}
			/>
		)
	}

	public render() {
		return (
			<FormikComponent
				formResource={this.props.formResource}
				enableReinitialize={false}
				inputs={SectionFormInputs(this.props.formResource)}
				onSubmit={this.handleSubmitButton}
				render={this.buildModal}
			/>
		)
	}
}

const mapDispatchToProps = {
	...AlertActions
}

const enhance = compose<React.ComponentType<ComponentProps>>(
	connect(undefined, mapDispatchToProps)
)

export default enhance(NewSectionModal)
