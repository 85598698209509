// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import * as Feather from 'react-feather'

// Internal Dependencies
import { BaseInputPrimitiveProps } from '../index'
import { TextInput } from '../TextInput'

type Props = BaseInputPrimitiveProps

export const PriceInput: React.FunctionComponent<Props> = (props: Props) => {
	return (
		<TextInput
			{...props}
			addonBefore={<Feather.DollarSign size={18} />}
		/>
	)
}
