import Fuse, { FuseOptions } from 'fuse.js'
import { isNullOrUndefined } from 'util'

import { buildFuseSearch, FuseItem } from './fuse'

export default class SearchHelper {
	private data: any[]
	private formatFunc: any

	private fuseIndex: Fuse<FuseItem, FuseOptions<FuseItem>>

	constructor(data: any[], formatFunc: any) {
		this.data = data
		this.formatFunc = formatFunc

		const fuseItems = this.buildFuseItems()
		this.fuseIndex = buildFuseSearch(fuseItems)
	}

	/**
 	* Returns an array of Fuse user objects.
 	* These objects contain the user's ID and a search string
 	* made up of the user's full name and email.
 	* @param allUsers Users on component state.
 	* @returns FuseUser[].
 	*/
	buildFuseItems = (): FuseItem[] => {
		return this.data.map((d: any) => this.formatFunc(d))
	}

	public search = (term: string) => {
		if (!this.fuseIndex) { return [] }

		if (isNullOrUndefined(term) || term === '') {
			return this.data
		}

		const fuseMatches: FuseItem[] = this.fuseIndex.search(term) as FuseItem[]
		return this.data.filter((u: any) => {
			const found = fuseMatches.find((m) => `${m._id}` === `${u._id}`)
			return !isNullOrUndefined(found)
		})
	}
}
