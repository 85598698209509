// External Dependencies
import * as core from 'club-hub-core'
import { createSelectorCreator, defaultMemoize } from 'reselect'
import { indexBy, isEqual, find } from 'underscore'

// Internal Dependencies
import { ActionTypes, TypeKeys } from '../actions/event'
import { RootReducerState } from './index'
import { mergeUpdatedModelObject } from '../helpers/reducer'

export type EventState = typeof defaultState

const defaultState = {
	currentEvent: null as core.Event.Model | null,
	events: [] as core.Event.Model[],
	eventCount: 0 as number,
	bookableEvents: [] as core.Event.EventDataForCalendar[],
}

function eventReducer(state = defaultState, action: ActionTypes) {
	switch (action.type) {
		case TypeKeys.FETCHED_EVENTS:
			return {
				...state,
				events: action.events,
				eventCount: action.count,
			}
		case TypeKeys.CREATED_EVENT:
			const newEventCount = state.eventCount + 1
			return {
				...state,
				currentEvent: action.event,
				events: mergeUpdatedModelObject(action.event, state.events),
				eventCount: newEventCount
			}
		case TypeKeys.UPDATED_EVENT:
			return {
				...state,
				currentEvent: action.event,
				events: mergeUpdatedModelObject(action.event, state.events)
			}
		case TypeKeys.FETCHED_BOOKABLE_EVENTS:
			return {
				...state,
				bookableEvents: action.bookableEvents,
			}
		case TypeKeys.FETCHED_EVENT:
		case TypeKeys.CREATED_RSVP:
		case TypeKeys.UPDATED_RSVP:
			return {
				...state,
				currentEvent: action.event,
				events: mergeUpdatedModelObject(action.event, state.events)
			}
		case TypeKeys.SET_CURRENT_EVENT:
			return {
				...state,
				currentEvent: action.event,
				events: mergeUpdatedModelObject(action.event, state.events)
			}
		case TypeKeys.CLEAR_CURRENT_EVENT:
			return {
				...state,
				currentEvent: null,
			}
		default:
			return state
	}
}

const createDeepEqualSelector = createSelectorCreator(
	defaultMemoize,
	isEqual
)

// Selectors
const event = (state: RootReducerState) => state.event

export const eventsByIDSelector = createDeepEqualSelector([event], (eventsList) => indexBy(eventsList.events, '_id'))

export default eventReducer
