import * as core from 'club-hub-core'
import { oc } from 'ts-optchain'

export const buildCustomRecurrenceString = (recurrence: core.Event.RecurringFrequency, end: Date) => {
	const label = getRecurrenceLabel(recurrence)
	const dateString = new Date(end).toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric' })
	return `${label} until ${dateString}`
}

export const getRecurrenceLabel = (recurrence: core.Event.RecurringFrequency) => {
	switch (recurrence) {
		case core.Event.RecurringFrequency.DAILY:
			return 'Daily'
		case core.Event.RecurringFrequency.WEEKLY:
			return 'Weekly'
		case core.Event.RecurringFrequency.MONTHLY:
			return 'Monthly'
		case core.Event.RecurringFrequency.YEARLY:
			return 'Yearly'
	}
}

export const calculateEndDate = (start: Date, count: number, calendar: core.Calendar.Model) => {
	const resSettings = calendar.reservationSettings[0]
	const defaultDuration = resSettings.bookingDuration

	const now = new Date()
	const hours = resSettings.hours.find((hour: core.IShared.HoursOfOperation) => hour.dayOfWeek === now.getDay())
	const duration = oc(hours).duration(defaultDuration)

	const endTime = start.getTime() + (duration * 60 * 1000 * count)
	return new Date(endTime)
}

export const getBookingDuration = (calendar: core.Calendar.Model) => {
	const resSettings = calendar.reservationSettings[0]
	const defaultDuration = resSettings.bookingDuration

	const now = new Date()
	const hours = resSettings.hours.find((hour: core.IShared.HoursOfOperation) => hour.dayOfWeek === now.getDay())
	return oc(hours).duration(defaultDuration)
}

export const calculateCount = (event: core.Event.Model, calendar: core.Calendar.Model) => {
	if (!event) { return }

	const duration = getBookingDuration(calendar)
	const start = new Date(event.start)
	const end = new Date(event.end)
	const length = end.getTime() - start.getTime()
	return length / (duration * 60 * 1000)
}
