// External Dependencies
import * as Yup from 'yup'

// Internal Dependencies
import { FormInput, FormInputType } from '../../Shared/Form'
import * as Constants from '../../../constants/index'

export interface RestaurantFormState {
	name: string,
	image?: File[],
	description?: string
}

export const RestaurantFormInputs = (): FormInput[] => [
	{
		title: 'Name',
		property: 'name',
		type: FormInputType.TEXT,
		size: 12,
		placeholder: 'Name...',
		defaultValue: '',
		validation: Yup.string().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Description',
		property: 'description',
		type: FormInputType.TEXT_AREA,
		size: 12,
		placeholder: 'Description...',
		defaultValue: '',
		validation: Yup.string().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Image',
		property: 'image',
		type: FormInputType.FILE,
		size: 12,
		file_accept: '.png,.jpg,.jpeg',
		file_multipleFiles: false,
		defaultValue: null,
	}
]
