// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import * as core from 'club-hub-core'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { FormikProps } from 'formik'
import { debounce } from 'underscore'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies

// Actions
import { AlertActions, ClubActions } from '../../../actions'

// State
import { RootReducerState } from '../../../reducers'

// Components
import DimmedLoader from '../../Shared/DimmedLoader'

// Form
import { FormInput } from '../../Shared/Form'
import { BrandSettingsFormInputs, BrandSettingsFormState } from './form'
import { FormikComponent, BuildWrappedForm } from '../../Shared/Formik'

// Helpers
import * as Constants from '../../../constants'
import { setStateAsync } from '../../../helpers/promise'

interface ComponentProps {
	club: core.Club.Model
}

const initialState = {
	error: false,
	loading: true,
	isSubmitting: false,
}

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

type Props = ConnectedState & ConnectedActions & ComponentProps
type State = typeof initialState

export class BrandSettingsForm extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
	}

	async componentDidMount() {
		await setStateAsync(this, { loading: false })
	}

	// ----------------------------------------------------------------------------------
	// Network
	// ----------------------------------------------------------------------------------

	/**
	 * Update our club settings.
	 */
	handleUpdateClub = async (form: BrandSettingsFormState): Promise<void> => {
		const { club, updateClub, fireFlashMessage } = this.props

		await setStateAsync(this, { isSubmitting: true })
		const payload = this.buildClubUpdatePayload(form)
		const [err] = await to(updateClub(`${club._id}`, payload) as any)
		if (err) {
			fireFlashMessage(`Failed to update Club Settings. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { isSubmitting: false, error: true })
			return
		}

		await setStateAsync(this, { isSubmitting: false })
		fireFlashMessage(`Successfully updated Club Settings.`, Constants.FlashType.SUCCESS)
	}

	/**
	* Builds the payload, that will be sent to the server, based on the form
	*/
	buildClubUpdatePayload = (state: BrandSettingsFormState): FormData => {
		const formPayload = new FormData()
		const clubSettings: Partial<core.Club.ClubSettings> = {
			primaryColor: state.primaryColor,
			secondaryColor: state.secondaryColor,
			font: state.font
		}
		const payload = { ...this.props.club, clubSettings }
		formPayload.append('club', JSON.stringify(payload))
		return formPayload
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildForm = () => {
		const { club } = this.props
		const formResource = this.getFormResource(club)
		const formInputs = BrandSettingsFormInputs()

		return (
			<FormikComponent
				inputs={formInputs}
				enableReinitialize={false}
				formResource={formResource}
				onSubmit={this.handleUpdateClub}
				render={(formikProps: FormikProps<BrandSettingsFormState>) => (
					this.buildFormBody(formInputs, formikProps)
				)}
			/>
		)
	}

	buildFormBody = (inputs: FormInput[], formikProps: FormikProps<BrandSettingsFormState>) => {
		const saveButtonClass = classNames({
			'btn': true,
			'btn-primary': true,
			'clubSettingForm__save-btn': true,
		})
		return (
			<>
				{BuildWrappedForm({ inputs }, formikProps)}
				<button className={saveButtonClass} onClick={formikProps.submitForm}>Save</button>
			</>
		)
	}

	render() {
		const { loading } = this.state
		if (loading) { return <DimmedLoader component={null} isLoading={true} /> }

		return (
			<RS.Row className='clubSettingForm__container'>
				<RS.Col>
					{this.buildForm()}
				</RS.Col>
			</RS.Row>
		)
	}

	// -------------------------------------
	// Helpers
	// -------------------------------------

	getFormResource = (club: core.Club.Model): BrandSettingsFormState => {
		const formResource: BrandSettingsFormState = {
			primaryColor: club.clubSettings.primaryColor,
			secondaryColor: club.clubSettings.secondaryColor,
			font: club.clubSettings.font,
		}
		return formResource
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	userState: state.user,
})

const mapDispatchToProps = {
	...AlertActions,
	...ClubActions,
}

const enhance = compose<React.ComponentType<ComponentProps>>(
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(BrandSettingsForm)
