// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import to from 'await-to-js'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { oc } from 'ts-optchain'

// State
import { RootReducerState } from '../../../reducers'

// Components
import ModalComponent from '../../Shared/Modal'

interface ComponentProps {
	isOpen: boolean
	title: string
	message: string
	name: string
	event: core.Event.Model
	handleDeleteEvent: (date: Date, all: boolean) => void
	handleToggle: () => void
}

const initialState = {
	loading: false,
	selected: core.Event.UpdateType.Single
}

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

type Props = ConnectedActions & ComponentProps & ConnectedState
type State = typeof initialState

/**
 * The `BlockModal` provides UI for blocking a calendar.
 */
class EventDeletionModal extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)

		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Handlers
	// ----------------------------------------------------------------------------------

	handleChange = async (field: string, value: any) => {
		this.setState({ selected: field as core.Event.UpdateType })
	}

	// ----------------------------------------------------------------------------------
	// Component Builders
	// ----------------------------------------------------------------------------------

	public render() {
		const recurring = this.props.event.recurring
		const title = recurring ? 'Delete Recurring Block' : 'Delete Block'
		const message = recurring ? 'Would you like to delete all future blocks or only this one?' : 'Are you sure you want to delete this event?'
		const primaryName = recurring ? 'This Block Only' : 'Confirm'
		const primaryHandler = recurring ? () => this.props.handleDeleteEvent(this.props.event.start, true) : () => this.props.handleDeleteEvent(null, true)
		const secondaryName = recurring ? 'All Blocks' : null
		const secondaryHandler = recurring ? () => this.props.handleDeleteEvent(null, true) : null
		return (
			<ModalComponent
				isOpen={this.props.isOpen}
				modalTitle={title}
				primaryMessage={message}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.props.handleToggle}
				submitButtonName={primaryName}
				submitButtonHandler={primaryHandler}
				secondaryButtonName={secondaryName}
				secondaryButtonHandler={secondaryHandler}
			/>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({})

const mapDispatchToProps = {}

const enhance = compose<React.ComponentType<ComponentProps>>(
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(EventDeletionModal)
