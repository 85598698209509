// External Dependencies
import * as core from 'club-hub-core'
import { createSelector } from 'reselect'
import { indexBy } from 'underscore'

// Internal Dependencies
import { ActionTypes, TypeKeys } from '../actions/post'
import { RootReducerState } from './index'
import { mergeUpdatedModelObject } from '../helpers/reducer'

export interface PostAttachment {
	remoteUrl: string
	attachmentType: 'PDF' | 'JPG' | 'PNG'
}

export type PostState = typeof defaultState

const defaultState = {
	currentPost: null as core.Post.Model | null,
	posts: [] as core.Post.Model[],
	count: 0
}

function postReducer(state = defaultState, action: ActionTypes): PostState {
	switch (action.type) {
		case TypeKeys.FETCHED_POSTS:
			return {
				...state,
				posts: action.posts,
				count: action.count
			}
		case TypeKeys.FETCHED_POST:
			return {
				...state,
				currentPost: action.post,
				posts: mergeUpdatedModelObject(action.post, state.posts),
			}
		case TypeKeys.CREATED_POST:
			return {
				...state,
				posts: mergeUpdatedModelObject(action.post, state.posts),
				currentPost: action.post,
			}
		case TypeKeys.UPDATED_POST:
			return {
				...state,
				posts: mergeUpdatedModelObject(action.post, state.posts),
				currentPost: action.post,
			}
		case TypeKeys.DELETED_POST:
			return {
				...state,
				posts: state.posts.filter((post: core.Post.Model) => `${post._id}` !== action.postID),
			}
		case TypeKeys.SET_CURRENT_POST:
			return {
				...state,
				currentPost: action.post,
				posts: mergeUpdatedModelObject(action.post, state.posts),
			}
		case TypeKeys.CLEAR_CURRENT_POST:
			return {
				...state,
				currentPost: null,
			}
		default:
			return state
	}
}

// Selectors
const posts = (state: RootReducerState) => state.post.posts
const currentPost = (state: RootReducerState) => state.post.currentPost

export const postsByIDSelector = createSelector([posts], (postList) => indexBy(postList, '_id'))
export const currentPostSelector = createSelector([currentPost], (post) => post)

export default postReducer
