// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as Feather from 'react-feather'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter, RouteComponentProps } from 'react-router'
import { groupBy, keys } from 'underscore'
import { isNullOrUndefined } from 'util'

// Internal Dependencies

// State
import { RootReducerState } from '../../../reducers'
import { calendarsByIDSelector, calendarGroupsByIDSelector } from '../../../reducers/calendar'

// Helpers
import * as Constants from '../../../constants'
import { beginningOfDay } from '../../../helpers/date'
import * as eventHelper from '../../../helpers/event'

export enum ReservationType {
	GOLF = 'golf',
	DINING = 'dining',
	SERVICE = 'services',
	RSVP = 'rsvp',
}

type ConnectedState = ReturnType<typeof mapStateToProps>

interface ComponentProps {
	events: core.Event.Model[],
}

const initialState = {
	events: null as core.Event.Model[] | null
}

type Props = RouteComponentProps & ComponentProps & ConnectedState
type State = typeof initialState

class ReservationListComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = { ...initialState, events: props.events }
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	handleReservationClicked = async (eventID: string, reservationID: string, type: ReservationType) => {
		const queryParams = { eventID: eventID, reservationID: reservationID }
		const location = {
			pathname: Constants.VIEW_RESERVATION_DETAILS_ROUTE.replace(':type', type).replace(':action', 'view'),
			search: queryString.stringify(queryParams)
		}
		this.props.history.push(location)
	}

	buildReservationList = () => {
		// Group Events by their start date
		const groupedEvents = groupBy(this.state.events, (event) => {
			const eventStart = new Date(event.start)
			return beginningOfDay(eventStart.toISOString()).toISOString()
		})

		// Sort the Event groups by their start date
		const groupKeys = keys(groupedEvents).sort((a, b) => {
			return new Date(a).valueOf() - new Date(b).valueOf()
		})

		// Build the Reservation items for each of the Event groups
		const sections = groupKeys.map((groupKey, groupIndex) => {
			const groupEvents = groupedEvents[groupKey].sort((a, b) => {
				return new Date(a.start).valueOf() - new Date(b.start).valueOf()
			})
			return this.buildReservationListSection(groupEvents, groupKey, groupIndex)
		})

		return sections
	}

	buildReservationListSection = (sectionEvents: core.Event.Model[], sectionKey: string, sectionIndex: number) => {
		const loggedInUser = this.props.userState.loggedInUser

		// Iterate over the Events in the section, and build and Reservation items for the User
		const reservationSectionItems: JSX.Element[] = []
		for (const event of sectionEvents) {
			for (const reservation of event.reservations) {

				// Skip any reservations that the user isn't included in.
				const userInReservation = reservation.participants.find((p) => `${p.userID}` === `${loggedInUser._id}`)
				if (isNullOrUndefined(userInReservation)) {
					continue
				}

				const sectionItem = this.buildReservationListItem(event, reservation, sectionIndex)
				reservationSectionItems.push(sectionItem)
			}
		}

		const sectionDate = new Date(sectionKey)
		const time = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' }
		const sectionDateString = sectionDate.toLocaleDateString('en-US', time)
		return (
			<div className='reservation-list-section mt-4' key={`${sectionKey}_${sectionIndex}`}>
				<div className='reservation-list-section-header'>
					{sectionDateString}
				</div>
				{reservationSectionItems}
			</div>
		)
	}

	typeForEvent = (event: core.Event.Model) => {
		const calendars = this.props.calendarsByID
		const groups = this.props.calendarGroupsByID
		const group = eventHelper.calendarGroupForEvent(event, calendars, groups)
		switch (group.type) {
			case core.Calendar.GroupType.Golf:
				return ReservationType.GOLF
			case core.Calendar.GroupType.Dining:
				return ReservationType.DINING
			case core.Calendar.GroupType.Service:
				return ReservationType.SERVICE
			default:
				return
		}

	}

	iconForEvent = (event: core.Event.Model) => {
		const calendars = this.props.calendarsByID
		const groups = this.props.calendarGroupsByID
		const group = eventHelper.calendarGroupForEvent(event, calendars, groups)
		switch (group.type) {
			case core.Calendar.GroupType.Golf:
				return (<i className='fas fa-golf-ball'/>)
			case core.Calendar.GroupType.Dining:
				return (<i className='fas fa-utensils'/>)
			case core.Calendar.GroupType.Service:
				return (<i className='fas fa-car'/>)
			default:
				return (<Feather.Calendar size={24} />)
		}
	}

	buildReservationListItem = (event: core.Event.Model, reservation: core.Event.Reservation, sectionIndex: number) => {
		let reservationItem
		const type = this.typeForEvent(event)
		switch (type) {
			case ReservationType.GOLF:
				reservationItem = this.buildGolfReservationItem(event, reservation)
				break
			case ReservationType.DINING:
				reservationItem = this.buildDiningReservationItem(event, reservation)
				break
			case ReservationType.SERVICE:
				reservationItem = this.buildVehicleReservationItem(event, reservation)
				break
			default:
				break
		}

		const key = `event_${event._id}_${reservation._id}_${sectionIndex}`
		const onClick = () => this.handleReservationClicked(`${event._id}`, `${reservation._id}`, type)
		return (
			<div key={key} className='reservation-list-item' onClick={onClick}>
				{reservationItem}
			</div>
		)
	}

	/**
	 * Builds the Golf Item.
	 */
	buildGolfReservationItem = (event: core.Event.Model, reservation: core.Event.Reservation): JSX.Element => {
		const start = new Date(event.start)
		const timeString = start.toLocaleTimeString('us-EN', { hour: 'numeric', minute: 'numeric' })
		const golfer = reservation.participants.length === 1 ? 'Golfer' : 'Golfers'
		const subtitle = `${reservation.participants.length} ${golfer} @ ${timeString}`
		return this.buildReservationItem(event, subtitle)
	}

	/**
	 * Builds the Dining Item.
	 */
	buildDiningReservationItem = (event: core.Event.Model, reservation: core.Event.Reservation): JSX.Element => {
		const start = new Date(event.start)
		const timeString = start.toLocaleTimeString('us-EN', { hour: 'numeric', minute: 'numeric' })
		const subtitle = `Party of ${reservation.participants.length} @ ${timeString}`
		return this.buildReservationItem(event, subtitle)
	}

	/**
	 * Builds the Reservation Item.
	 */
	buildVehicleReservationItem = (event: core.Event.Model, reservation: core.Event.Reservation) => {
		const loggedInUser = this.props.userState.loggedInUser

		// Get the Service Provider
		const eventCalendarID = eventHelper.calendarIDForEvent(event)
		const serviceProvider: core.Calendar.Model = this.props.calendarsByID[`${eventCalendarID}`]
		const serviceProviderName = (serviceProvider) ? serviceProvider.name : ''

		// Get the Vehicle
		const vehicleID = `${(reservation.meta as core.Event.CarReservationMeta).vehicleID}`
		const vehicle: core.SubModels.CarMeta.Vehicle = loggedInUser.meta.car.vehicles.find((v: core.SubModels.CarMeta.Vehicle) => {
			return `${v._id}` === vehicleID
		})

		const title = `${vehicle.year} ${vehicle.make} ${vehicle.model}`
		return this.buildReservationItem(event, serviceProviderName, title)
	}

	/**
	 * Build the reservation item.
	 */
	buildReservationItem = (event: core.Event.Model, subtitle: string, title?: string) => {
		const eventCalendarID = eventHelper.calendarIDForEvent(event)
		const calendar: core.Calendar.Model = this.props.calendarsByID[`${eventCalendarID}`]

		const start = new Date(event.start)
		const dateString = start.toLocaleDateString('us-EN', { weekday: 'long', month: 'short', day: 'numeric', year: 'numeric' })
		return (
			<div className='d-flex justify-content-between align-items-center'>
				<div className='d-flex align-items-center'>
					<span className='icon-circle mr-4 d-flex justify-content-center align-items-center'>
						{this.iconForEvent(event)}
					</span>
					<div>
						<h4>{title ? title : calendar.name}</h4>
						<span className='d-block text-muted'>{subtitle}</span>
						<span className='d-block text-muted'>{dateString}</span>
					</div>
				</div>
				<div className='d-flex align-items-center'>
					<Feather.ChevronRight size={18} />
				</div>
			</div>
		)
	}

	render() {
		return (
			<div className='reservation-list-container mx-auto'>
				{this.buildReservationList()}
			</div>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	userState: state.user,
	calendarState: state.calendar,
	calendarsByID: calendarsByIDSelector(state),
	calendarGroupsByID: calendarGroupsByIDSelector(state)
})

const enhance = compose<React.ComponentType<ComponentProps>>(
	withRouter,
	connect(mapStateToProps, undefined)
)

export default enhance(ReservationListComponent)
