// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import * as Feather from 'react-feather'
import * as core from 'club-hub-core'

interface ComponentProps {
	type: ContentType
	isInPast?: boolean
	actionHandler?: any
	status?: core.IShared.PublicationStatus
}

type Props = ComponentProps
type State = any

export enum ContentType {
	Posts = 'News',
	Events = 'Events',
	Services = 'Services',
	Reservations = 'Reservations',
	TeeTimes = 'Tee Times',
	TeeSheetUnavailable = 'Tee Sheet Unavailable',
	Dining = 'Dining',
	Messages = 'Messages',
	MenuItems = 'Menu Items',
	MenuSections = 'Menu Sections',
	GuestGolfer = 'Guest Golfer'
}

export default class EmptyContent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
	}

	getSubtitle = () => {
		const contentTenseWord = (this.props.isInPast) ? 'past' : 'upcoming'
		switch (this.props.type) {
			case ContentType.Posts:
				return `There are no ${this.props.status.toLowerCase()} posts.`
			case ContentType.Messages:
				return `There are no ${this.props.status.toLowerCase()} messages.`
			case ContentType.Events:
				return `There are no ${contentTenseWord} events.`
			case ContentType.Services:
				return `You have no ${contentTenseWord} service requests.`
			case ContentType.Reservations:
				return `You have no ${contentTenseWord} reservations.`
			case ContentType.TeeTimes:
				return `You have no ${contentTenseWord} Tee Times.`
			case ContentType.Dining:
				return `You have no ${contentTenseWord} dining reservations.`
			case ContentType.MenuItems:
				return `There are no Menu Items`
			case ContentType.MenuSections:
				return `There are no Menu Sections`
			case ContentType.TeeSheetUnavailable:
				return `The tee sheet is not available for the selected date.`
			case ContentType.GuestGolfer:
				return `You have not ${contentTenseWord} guest golfer requests.`
		}
	}

	getIconCircle = () => {
		let icon: any
		switch (this.props.type) {
			case ContentType.Posts:
				icon = (<Feather.Menu size={24} />)
				break
			case ContentType.Messages:
				icon = (<Feather.Mail size={24} />)
				break
			case ContentType.Events:
			case ContentType.Reservations:
				icon = (<Feather.Calendar size={24} />)
				break
			case ContentType.Services:
				icon = (<i className='fas fa-car' />)
				break
			case ContentType.TeeTimes:
			case ContentType.GuestGolfer:
			case ContentType.TeeSheetUnavailable:
				icon = (<i className='fas fa-golf-ball' />)
				break
			case ContentType.Dining:
			case ContentType.MenuItems:
			case ContentType.MenuSections:
				icon = (<i className='fas fa-utensils' />)
				break
		}
		return (
			<div className='empty-content-icon-container'>
				{icon}
			</div>
		)
	}
	getActionButton = () => {
		if (!this.props.actionHandler) {
			return
		}

		let buttonText: string
		switch (this.props.type) {
			case ContentType.Posts:
				buttonText = 'New Post'
				break
			case ContentType.Events:
				buttonText = 'New Event'
				break
			case ContentType.Services:
				buttonText = 'New Service Request'
				break
			case ContentType.TeeTimes:
				buttonText = 'New Tee Time'
				break
			case ContentType.Dining:
				buttonText = 'New Reservation'
				break
			case ContentType.GuestGolfer:
				buttonText = 'New Request'
				break
		}
		return (
			<RS.Button className='empty-content-button' color='primary' onClick={this.props.actionHandler}>
				{buttonText}
			</RS.Button>
		)
	}

	render() {
		const sub = this.getSubtitle()
		return (
			<div className={'empty-content-container'}>
				<div className='text-center'>
					{this.getIconCircle()}
					<h3 className='empty-content-title'>{this.props.type}</h3>
					<p className='empty-content-subtitle text-muted'>{sub}</p>
					{this.getActionButton()}
				</div>
			</div>
		)
	}
}
