import * as core from 'club-hub-core'
import { oc } from 'ts-optchain'

import * as Constants from '../constants'
import { InputSelectionItem, ReactSelectItem } from '../components/Shared/Form'

export const typeSelectInputs = (types: core.Club.ResourceType[]) => {
	const typeInputs = types.map<InputSelectionItem>((type) => ({ label: type.title, value: `${type._id}` }))
	typeInputs.push({ label: 'Add New...', value: `addNew` })
	return typeInputs
}

export const locationSelectInputs = (locations: core.SubModels.Location.Model[]): ReactSelectItem[] => {
	const locationInputs = locations.map<InputSelectionItem>((loc) => ({ label: loc.name, value: `${loc._id}` }))
	locationInputs.push({ label: 'Add New...', value: `addNew` })
	return locationInputs
}

export const calendarSelectInputs = (calendars: core.Calendar.Model[], groups: core.Calendar.Group[]): ReactSelectItem[] => {
	return groups.map((group: core.Calendar.Group) => {
		const groupCals = calendars.filter((cal: core.Calendar.Model) => `${cal.groupID}` === `${group._id}`)
		const options = groupCals.map<InputSelectionItem>((cal) => ({ label: oc(cal).name(), value: `${cal._id}` }))
		return { label: oc(group).name(), options: options }
	})
}

export const clubTypeSelectInputs = () => {
	const clubTypeKeys = Object.keys(core.Club.Type)
	return clubTypeKeys.map<InputSelectionItem>((typeKey) => ({ label: typeKey, value: typeKey.toLocaleUpperCase() }))
}

export const stateSelectionInputs = [
	{ label: 'Alabama', value: 'AL' },
	{ label: 'Alaska', value: 'AK' },
	{ label: 'Arizona', value: 'AZ' },
	{ label: 'Arkansas', value: 'AR' },
	{ label: 'California', value: 'CA' },
	{ label: 'Colorado', value: 'CO' },
	{ label: 'Connecticut', value: 'CT' },
	{ label: 'Delaware', value: 'DE' },
	{ label: 'District of Columbia', value: 'DC' },
	{ label: 'Florida', value: 'FL' },
	{ label: 'Georgia', value: 'GA' },
	{ label: 'Hawaii', value: 'HI' },
	{ label: 'Alaska', value: 'AK' },
	{ label: 'Idaho', value: 'ID' },
	{ label: 'Illinois', value: 'IL' },
	{ label: 'Indiana', value: 'IN' },
	{ label: 'Iowa', value: 'IA' },
	{ label: 'Kansas', value: 'KS' },
	{ label: 'Kentucky', value: 'KY' },
	{ label: 'Louisiana', value: 'LA' },
	{ label: 'Maine', value: 'ME' },
	{ label: 'Maryland', value: 'MD' },
	{ label: 'Massachusetts', value: 'MA' },
	{ label: 'Michigan', value: 'MI' },
	{ label: 'Minnesota', value: 'MN' },
	{ label: 'Mississippi', value: 'MS' },
	{ label: 'Missouri', value: 'MO' },
	{ label: 'Montana', value: 'MT' },
	{ label: 'Nebraska', value: 'NE' },
	{ label: 'Nevada', value: 'NV' },
	{ label: 'New Hampshire', value: 'NH' },
	{ label: 'New Jersey', value: 'NJ' },
	{ label: 'New Mexico', value: 'NM' },
	{ label: 'New York', value: 'NY' },
	{ label: 'North Carolina', value: 'NC' },
	{ label: 'North Dakota', value: 'ND' },
	{ label: 'Ohio', value: 'OH' },
	{ label: 'Oklahoma', value: 'OK' },
	{ label: 'Oregon', value: 'OR' },
	{ label: 'Pennsylvania', value: 'PA' },
	{ label: 'Rhode Island', value: 'RI' },
	{ label: 'South Carolina', value: 'SC' },
	{ label: 'South Dakota', value: 'SD' },
	{ label: 'Tennessee', value: 'TN' },
	{ label: 'Texas', value: 'TX' },
	{ label: 'Utah', value: 'UT' },
	{ label: 'Vermont', value: 'VT' },
	{ label: 'Virginia', value: 'VA' },
	{ label: 'Washington', value: 'WA' },
	{ label: 'West Virginia', value: 'WV' },
	{ label: 'Wisconsin', value: 'WI' },
	{ label: 'Wyoming', value: 'WY' },
]

export const colorSelectionInputs = [
	{ label: 'Blue', value: core.Constants.Colors.BLUE, color: core.Constants.Colors.BLUE },
	{ label: 'Azure', value: core.Constants.Colors.AZURE, color: core.Constants.Colors.AZURE },
	{ label: 'Indigo', value: core.Constants.Colors.INDIGO, color: core.Constants.Colors.INDIGO },
	{ label: 'Purple', value: core.Constants.Colors.PURPLE, color: core.Constants.Colors.PURPLE },
	{ label: 'Pink', value: core.Constants.Colors.PINK, color: core.Constants.Colors.PINK },
	{ label: 'Red', value: core.Constants.Colors.RED, color: core.Constants.Colors.RED },
	{ label: 'Orange', value: core.Constants.Colors.ORANGE, color: core.Constants.Colors.ORANGE },
	{ label: 'Yellow', value: core.Constants.Colors.YELLOW, color: core.Constants.Colors.YELLOW },
	{ label: 'Lime', value: core.Constants.Colors.LIME, color: core.Constants.Colors.LIME },
	{ label: 'Green', value: core.Constants.Colors.GREEN, color: core.Constants.Colors.GREEN },
	{ label: 'Teal', value: core.Constants.Colors.TEAL, color: core.Constants.Colors.TEAL },
	{ label: 'Cyan', value: core.Constants.Colors.CYAN, color: core.Constants.Colors.CYAN },
	{ label: 'Gray', value: core.Constants.Colors.GRAY, color: core.Constants.Colors.GRAY }
]

export const dayOfWeekSelectionInputs = [
	{ label: 'Sunday', value: `${Constants.DayOfWeek.Sunday}` },
	{ label: 'Monday', value: `${Constants.DayOfWeek.Monday}` },
	{ label: 'Tuesday', value: `${Constants.DayOfWeek.Tuesday}` },
	{ label: 'Wednesday', value: `${Constants.DayOfWeek.Wednesday}` },
	{ label: 'Thursday', value: `${Constants.DayOfWeek.Thursday}` },
	{ label: 'Friday', value: `${Constants.DayOfWeek.Friday}` },
	{ label: 'Saturday', value: `${Constants.DayOfWeek.Saturday}` },
]

export const frequencies = [
	{ label: 'Per Day', value: `${Constants.Frequencies.Day}` },
	{ label: 'Per Week', value: `${Constants.Frequencies.Week}` },
	{ label: 'Per Month', value: `${Constants.Frequencies.Month}` },
	{ label: 'Per Year', value: `${Constants.Frequencies.Year}` },
]

export enum PublicationStatus {
	Published = 'PUBLISHED',
	Draft = 'DRAFT',
	Pending = 'PENDING',
	Scheduled = 'SCHEDULED',
	Delivered = 'DELIVERED'
}
