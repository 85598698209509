// External Dependencies
import * as React from 'react'
import { Icon } from 'react-feather'
import { oc } from 'ts-optchain'
import * as RS from 'reactstrap'

// Internal Dependencies
import { InputSelectionItem, FormInputType, ReactSelectItem } from '../Form'

// Components
import ButtonGroupComponent, { HeaderButton } from '../ButtonGroup'
import SearchComponent from '../Search'
import SelectComponent from '../Select'
import DatePickerComponent from '../DatePicker'
import DropdownComponent from '../Dropdown'
import { TabBarButtonInput, TabBarComponent } from '../TabBar'

// Helpers
import { PropsFor } from '../../../helpers/interface'

// Interfaces
export enum PageHeaderInputType {
	Search = 'search',
	Select = 'select',
	Date = 'date',
	Dropdown = 'dropdown',
	Button = 'button',
}

interface PageHeaderInput {
	inputType: PageHeaderInputType
	placeholder?: string
	className?: string
	icon?: Icon
	faIcon?: string
}

export interface PageHeaderButtonInput extends PageHeaderInput {
	clickHandler: () => void
	color: string
	text: string
}

export interface PageHeaderSearchInput extends PageHeaderInput {
	changeHandler: (term: string) => any
	enterHandler: (event: any) => any
	clearHandler: () => any
	defaultValue?: string
}

export interface PageHeaderSelectInput extends PageHeaderInput {
	selected?: InputSelectionItem
	placeholder?: string
	inputs: InputSelectionItem[] | ReactSelectItem[]
	changeHandler: (data: InputSelectionItem) => any
}

export interface PageHeaderDateInput extends PageHeaderInput {
	type: FormInputType.DATE | FormInputType.TIME | FormInputType.DATE_TIME
	selected: Date | null
	changeHandler: (date: Date) => any
	isClearable?: boolean
}

export type PageHeaderDropdownInput = PageHeaderInput & PropsFor<typeof DropdownComponent>

interface PageHeaderProps {
	pageTitle: string
	inputs?: Array<PageHeaderSearchInput | PageHeaderSelectInput | PageHeaderDateInput | PageHeaderDropdownInput>
	tabInputs?: TabBarButtonInput[]
	buttons?: HeaderButton[]
	buttonHandler?: any
	fullScreen?: boolean
}

/**
 * Return a Page header component, ex: 'User Dashboard' with set spacing.
 * @param props
 */
const PageHeaderComponent: React.SFC<PageHeaderProps> = (props: PageHeaderProps) => {

	const buildInputs = () => {
		if (!props.inputs) { return null }
		return props.inputs.map((input: any, index: number) => {
			switch (input.inputType) {
				case PageHeaderInputType.Button:
					return buildButtonInput(input, index)
				case PageHeaderInputType.Date:
					return buildDateInput(input, index)
				case PageHeaderInputType.Search:
					return buildSearchInput(input, index)
				case PageHeaderInputType.Select:
					return buildSelectInput(input, index)
				case PageHeaderInputType.Dropdown:
					return buildDropdownInput(input, index)
				default:
					throw new Error('Invalid Inputs for Page Header')
			}
		})
	}

	const buildSearchInput = (input: PageHeaderSearchInput, index: number) => {
		const className = oc(input).className('')
		return (
			<div className={`${className} ml-2`} key={`search_input_${index}`}>
				<SearchComponent
					searchChangeHandler={input.changeHandler}
					searchClearHandler={input.clearHandler}
					searchEnterHandler={input.enterHandler}
					defaultValue={input.defaultValue}
				/>
			</div>
		)
	}

	const buildSelectInput = (input: PageHeaderSelectInput, index: number) => {
		const className = oc(input).className('')
		return (
			<div
				className={`${className} select-input ml-2`}
				key={`select_input_${index}`}
			>
				<SelectComponent
					placeholder={input.placeholder}
					options={input.inputs}
					onChange={input.changeHandler}
					value={input.selected}
					featherIcon={input.icon}
					faIcon={input.faIcon}
				/>
			</div>
		)
	}

	const buildDateInput = (input: PageHeaderDateInput, index: number) => {
		const className = oc(input).className('')
		return (
			<div
				className={`${className} ml-2`}
				key={`date_input_${index}`}
			>
				<DatePickerComponent
					type={input.type}
					className={`form-control`}
					selected={input.selected}
					onChange={input.changeHandler}
					isClearable={input.isClearable}
					featherIcon={input.icon}
					faIcon={input.faIcon}
				/>
			</div>
		)
	}

	const buildDropdownInput = (dropdown: PageHeaderDropdownInput, index: number) => {
		const className = oc(dropdown).className('')
		return (
			<div className={`${className} ml-2`} key={`dropdown_input_${index}`}>
				<DropdownComponent
					header={dropdown.header}
					category={dropdown.category}
					items={dropdown.items}
					onChange={dropdown.onChange}
					className={`form-control`}
					featherIcon={dropdown.featherIcon}
					faIcon={dropdown.faIcon}
				/>
			</div>
		)
	}

	const buildButtonInput = (input: PageHeaderButtonInput, index: number) => {
		return (
			<RS.Button
				color={input.color}
				onClick={input.clickHandler}
			>
				{input.text}
			</RS.Button>
		)
	}

	const buildButtons = () => {
		if (!props.buttons) { return null }

		return (
			<ButtonGroupComponent
				buttons={props.buttons}
				buttonHandler={props.buttonHandler}
				className={'page-header-btns ml-2'}
			/>
		)
	}

	return (
		<div className='page-header-div justify-content-between'>
			<RS.Container className={'full-width'}>
				<RS.Row>
					<RS.Col sm={12} md={6}>
						<h1 className='page-title flex-fill'>{props.pageTitle}</h1>
					</RS.Col >
					<RS.Col sm={12} md={6} className={'d-flex align-items-center justify-content-end'}>
						{buildInputs()}
						{buildButtons()}
					</RS.Col>
				</RS.Row>
			</RS.Container>
		</div>
	)
}

export default PageHeaderComponent
