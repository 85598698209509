// External Dependencies
import * as core from 'club-hub-core'
import { Dispatch, Action } from 'redux'
import to from 'await-to-js'

// Internal Dependencies

// Actions
import { UserActions, fetchedAllUsers } from './user'
import { fetchedCalendarGroups, fetchedCalendars } from './calendar'

// API Helpers
import { GET, POST } from '../helpers/api'

// Actions
export enum TypeKeys {
	FETCHED_CUSTOMER_STATE = 'FETCHED_CUSTOMER_STATE',
	CREATED_GUEST_USER = 'CREATED_GUEST_USER'
}

export type ActionTypes =
	| FetchedCustomerStateAction
	| CreatedGuestUserAction

/**
 * FetchedCustomerStateAction.
 */
export interface FetchedCustomerStateAction extends Action {
	type: TypeKeys.FETCHED_CUSTOMER_STATE,
	restaurants: core.Restaurant.Model[],
	calendars: core.Calendar.Model[],
	sections: core.Section.Model[]
}

/**
* CreatedGuestUserAction.
*/
export interface CreatedGuestUserAction extends Action {
	type: TypeKeys.CREATED_GUEST_USER,
	user: core.User.Model,
	receivedAt: Date
}

// -----------------------------------------------------------------------------
// Fetch Customer State
// -----------------------------------------------------------------------------

const fetchCustomerState = () => async (dispatch: Dispatch<any>) => {
	const [err, res] = await to(GET('/users/me', {}))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to fetch Member State with error: ${err}`)
		throw err
	}
	dispatch(fetchedCustomerState(res))
	dispatch(UserActions.authenticatedUser(res.user, res.club))

	// Dispatch the relevant pieces of the response
	// to their respective reducers
	dispatch(fetchedAllUsers(res.users))
	dispatch(fetchedCalendarGroups(res.club.calendarGroups))
	dispatch(fetchedCalendars({ calendars: res.calendars, count: res.calendars.length }))
}

const fetchedCustomerState = (customerState: any): FetchedCustomerStateAction => {
	const action: FetchedCustomerStateAction = {
		type: TypeKeys.FETCHED_CUSTOMER_STATE,
		restaurants: customerState.restaurants,
		calendars: customerState.calendars,
		sections: customerState.sections
	}
	return action
}

// -----------------------------------------------------------------------------
// Create Guest User
// -----------------------------------------------------------------------------

/*
 * Creates a new guest user.
 */
const createGuestUser = (userPayload: core.User.Model, clubID: string) => async (dispatch: Dispatch<CreatedGuestUserAction>) => {
	const [userErr, userRes] = await to<core.User.Model>(POST('/users', userPayload))
	if (userErr) {
		// tslint:disable-next-line
		console.error(`Failed to create User with error: ${userErr}`)
		throw userErr
	}

	await dispatch(createdGuestUser(userRes))
	const [fetchUserErr] = await to(dispatch(UserActions.getUsers({ clubID, pageSize: 0, page: 0 }) as any))
	if (fetchUserErr) {
		// tslint:disable-next-line
		console.error(`Failed to fetch Users with error: ${fetchUserErr}`)
		throw fetchUserErr
	}

	return userRes
}

/**
 * Builds a `CreatedGuestUserAction` upon successful creation of a guest user.
 */
const createdGuestUser = (user: core.User.Model): CreatedGuestUserAction => {
	const action: CreatedGuestUserAction = {
		receivedAt: new Date(),
		type: TypeKeys.CREATED_GUEST_USER,
		user: user,
	}
	return action
}

export const CustomerActions = {
	fetchCustomerState,
	createGuestUser
}
