// External Dependencies
import * as React from 'react'
import classNames from 'classnames'
import { FormikProps, FormikValues, FormikActions, Formik } from 'formik'

// Internal Dependencies
import { FormikComponent, BuildWrappedForm } from '../index'
import { FormInput } from '../../Form'

// Helpers
import { setStateAsync } from '../../../../helpers/promise'

interface ComponentProps {
	title: string,
	inputs: FormInput[]
	enableReinitialize: boolean
	formResource?: any,
	formikRef?: React.LegacyRef<Formik>
	cancelButtonName?: string
	cancelButtonHandler?: any
	submitButtonName?: string
	submitButtonHandler?: (values: FormikValues, actions?: FormikActions<FormikValues>) => any
	secondaryButtonName?: string
	secondaryButtonHandler?: (values: FormikValues, actions?: FormikActions<FormikValues>) => any
	dangerSecondary?: boolean
	draftButtonName?: string
	draftButtonHandler?:  (values: FormikValues, actions?: FormikActions<FormikValues>) => any
	buttonOverrides?: JSX.Element
	onChangeHandler?: (field: string, value: any, formikProps?: FormikProps<FormikValues>) => any
	render?: (formikProps: FormikProps<any>) => any
}

const initialState = {
	submitAction: null as string | null
}

type Props = ComponentProps
type State = typeof initialState

class GenericFormComponent extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * This method is called on submit and will pass the form's values
	 * up to the submit handler passed in via props.
	 */
	onSubmitHandler = (values: FormikValues, actions: FormikActions<FormikValues>) => {
		switch (this.state.submitAction) {
			case 'primary':
				return this.props.submitButtonHandler(values, actions)
			case 'secondary':
				return this.props.secondaryButtonHandler(values, actions)
			case 'draft':
				return this.props.draftButtonHandler(values, actions)
			default:
				throw Error(`Invalid submission type: ${this.state.submitAction}`)
		}
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildFormBody = (formikProps: FormikProps<FormikValues>) => {
		const wrappedFormProps = {
			inputs: this.props.inputs,
			onChange: (field: string, value: string) => {
				if (this.props.onChangeHandler) {
					return this.props.onChangeHandler(field, value, formikProps)
				}
			},
		}

		const primaryHandler = async () => {
			await setStateAsync(this, { submitAction: 'primary' })
			formikProps.submitForm()
		}

		const secondaryHandler = async () => {
			await setStateAsync(this, { submitAction: 'secondary' })
			formikProps.submitForm()
		}

		const draftHandler = async () => {
			await setStateAsync(this, { submitAction: 'draft' })
			formikProps.submitForm()
		}

		const submitBtnClass = classNames({
			'btn': true,
			'btn-primary': true,
			'btn-loading': formikProps.isSubmitting
		})
		const secondaryButtonClass = classNames({
			'btn': true,
			'btn-secondary': !this.props.dangerSecondary,
			'btn btn-outline-danger': this.props.dangerSecondary
		})
		const renderFunc = (this.props.render) ?
			this.props.render :
			(renderFormikProps: FormikProps<any>) => BuildWrappedForm(wrappedFormProps, renderFormikProps)

		// Allow caller to supply customized overrides for form action components
		const primaryAndSecondaryButtons = this.props.buttonOverrides ? this.props.buttonOverrides : (
			<div>
				<button
					className={secondaryButtonClass}
					hidden={!this.props.secondaryButtonName}
					onClick={secondaryHandler}
					data-cy='GenericForm__secondary-btn'
				>
					{this.props.secondaryButtonName}
				</button>

				<button
					className={submitBtnClass}
					hidden={!this.props.draftButtonName}
					onClick={draftHandler}
					data-cy='GenericForm__draft-btn'
				>
					{this.props.draftButtonName}
				</button>

				<button
					className={submitBtnClass}
					hidden={!this.props.submitButtonName}
					onClick={primaryHandler}
					data-cy='GenericForm__submit-btn'
				>
					{this.props.submitButtonName}
				</button>
			</div>
		)

		return (
			<>
				<div className='card-body'>
					{renderFunc(formikProps)}
				</div>
				<div className='card-footer'>
					<div className='row no-gutters d-flex align-items-center justify-content-between'>
						<button
							className='btn btn-secondary'
							hidden={!this.props.cancelButtonName}
							onClick={this.props.cancelButtonHandler}
							data-cy='GenericForm__cancel-btn'
						>
							{this.props.cancelButtonName}
						</button>

						{primaryAndSecondaryButtons}
					</div>
				</div>
			</>
		)
	}

	render() {
		return (
			<div className='form-component-container'>
				<div className='card'>
					<div className='card-header'>
						<h3 className='card-title'>{this.props.title}</h3>
					</div>
					<FormikComponent
						inputs={this.props.inputs}
						enableReinitialize={this.props.enableReinitialize}
						formResource={this.props.formResource}
						onSubmit={this.onSubmitHandler}
						render={(formikProps) => this.buildFormBody(formikProps)}
						formikRef={this.props.formikRef}
					/>
				</div>
			</div>
		)
	}
}

export default GenericFormComponent
