// External Dependencies
import * as React from 'react'
import { Button, Radio, Icon } from 'antd'
import { RouteComponentProps } from 'react-router'

type Props = RouteComponentProps

export default class Four0Four extends React.Component<Props> {

	render() {
		return (
			<div className={'not-found-page d-flex flex-column align-content-center'}>
				<h1 className='title'>{'404'}</h1>
				<h3 className='sub-title'>{'Oops... You just landed on a dead link.'}</h3>
				<p className='sub-text'>{'Either head back to where you came, or start from home'}</p>
				<div className='buttons d-flex align-content-center'>
					<Button id='back' type='primary' size='large' onClick={this.props.history.goBack}>
						<Icon type='left' />
						Back
				</Button>
					<Button id='home' type='primary' size='large' onClick={() => this.props.history.push('/home')}>
						<Icon type='home' />
						Home
				</Button>
				</div>
			</div>
		)
	}
}
