// External Dependencies
import * as Sentry from '@sentry/browser'
import createSentryMiddleware from 'redux-sentry-middleware'

// Internal Deps
import { RootReducerState } from '../../reducers'
import { COMMITHASH, UNAUTHORIZED_ACCESS_NOTIFICATION } from './../../constants'

const prodSentryDSN = 'https://fb53eda3c37c44e985772a7ad13333f8@sentry.io/1369883'
const stageSentryDSN = 'https://4832456fb6a44bbeb8de1dd68e442b8e@sentry.io/1399863'

const defaultOptions: Sentry.BrowserOptions = {
	release: COMMITHASH,
	environment: process.env.ENV
}

// Sentry
if (process.env.ENV === 'production' && process.env.NODE_ENV !== 'local') {
	Sentry.init({ ...defaultOptions, dsn: prodSentryDSN, ignoreErrors: [UNAUTHORIZED_ACCESS_NOTIFICATION] })
} else if (process.env.ENV === 'staging' && process.env.NODE_ENV !== 'local') {
	Sentry.init({ ...defaultOptions, dsn: stageSentryDSN, ignoreErrors: [UNAUTHORIZED_ACCESS_NOTIFICATION] })
} else {
	// tslint:disable-next-line
	console.warn('Development Mode')
	// tslint:disable-next-line
	console.warn('Sentry Disabled')
}

export default createSentryMiddleware(Sentry, {
	stateTransformer: (state: RootReducerState) => {
		// Filter out the user list in mem due to size
		const newState: any = {
			...state,
			club: {
				...state.club,
				clubs: [],
			},
			user: {
				...state.user,
				users: []
			},
			statement: {}
		}
		return newState
	},
})
