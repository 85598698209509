// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'

interface DescriptionProps {
	title?: string
	description?: string
	className?: string
}

/**
 * Returns a section with an optional title and description.
 * @param props PassedPropsInterface
 */
const DescriptionSection: React.SFC<DescriptionProps> = (props: DescriptionProps) => {

	return (
			<div className={`${props.className || ''} description-section`}>
				<RS.Alert>{props.description}</RS.Alert>
			</div>
		)
}

export default DescriptionSection
