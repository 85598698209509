// External Dependencies
import * as React from 'react'

// Internal Dependencies
import { BaseTablePrimitiveProps } from '../index'
import { BaseTableCell } from '../BaseTableCell'

type Props<T> = BaseTablePrimitiveProps<T>

export const TableImage: React.FC<Props<any>> = (props: Props<any>) => {
	const rawValue = props.item[props.column.property]
	return (
		<BaseTableCell {...props}>
			<img src={rawValue} />
		</BaseTableCell>
	)
}
