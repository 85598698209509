// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as RS from 'reactstrap'
import * as Feather from 'react-feather'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { oc } from 'ts-optchain'
import BigCalendar from 'react-big-calendar'
import to from 'await-to-js'

// State
import { RootReducerState } from '../../../../reducers'
import { clubCalendarsForCurrentClub } from '../../../../reducers/calendar'

// Actions
import { UserActions, CalendarActions, EventActions, AlertActions } from '../../../../actions/index'

// Components
import Select from '../../Select'
import EditCalendarModal from '../EditCalendarModal'
import { InputSelectionItem } from '../../Form'
import { selectInputsForCalendars } from '../../../../helpers/types'
import { setStateAsync } from '../../../../helpers/promise'

// Constants
import * as Constants from '../../../../constants'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

interface ComponentProps {
	view: string
	date: Date
	onView: (a: string) => void
	onNavigate: (a: string, date: Date) => void
}

const initialState = {
	loading: false,
	error: false,
	currentDate: new Date() as Date,
	creatingCalendar: false,
	selectedCalendar: null as InputSelectionItem | null
}

type Props = ComponentProps & ConnectedState & ConnectedActions & RouteComponentProps
type State = typeof initialState

/**
 * CalendarTopBar
 */
class CalendarTopBar extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)

		// Determine which Calendar to show
		const calendar = oc(props).calendarState.currentCalendar()
		const selectedCalendar: InputSelectionItem = this.getSelectedCalendarInput(calendar)
		this.state = { ...initialState, selectedCalendar }
	}

	// ----------------------------------------------------------------------------------
	// Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Handles clicks on the `Save` button in the New Calendar modal.
	 */
	handleCreateNewCalendar = async (name: string, color: string) => {
		await setStateAsync(this, { loading: true })

		// Create the calendar
		const group = this.props.calendarState.currentCalendarGroup
		const payload = this.buildCalendarPayload(name, color, `${group._id}`)
		const [err] = await to(this.props.createCalendar(payload) as any)
		if (err) {
			this.props.fireFlashMessage(`Problem creating calendar. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true, creatingCalendar: false })
			return
		}

		this.props.fireFlashMessage('Calendar successfully created.', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, error: false, creatingCalendar: false })
	}

	/**
	 * Handles clicks on the cancel button in the New Calendar modal.
	 */
	handleCancelNewCalendar = async () => {
		await setStateAsync(this, { creatingCalendar: false })
	}

	/**
	 * Handles changes to view select.
	 */
	onViewChange = (item: InputSelectionItem) => {
		this.props.onView(item.value)
	}

	/**
	 * Handles changes to calendar.
	 */
	onCalendarChange = async (item: InputSelectionItem) => {
		const group = this.props.calendarState.currentCalendarGroup
		if (item.value === 'All') {
			this.props.setCurrentCalendar(null, group)
			await setStateAsync(this, { selectedCalendar: item })
			return
		}
		if (item.value === 'Edit') {
			await setStateAsync(this, { creatingCalendar: true })
			return
		}
		const calendars = this.props.calendarState.calendars
		const calendar = calendars.find((c) => `${c._id}` === item.value)
		this.props.setCurrentCalendar(calendar, group)
		await setStateAsync(this, { selectedCalendar: item })
	}

	/**
	 * Handles clicks on today.
	 */
	onTodayClick = () => {
		this.props.onNavigate('', new Date())
	}

	/**
	 * Handles clicks on next.
	 */
	onNextClick = () => {
		const date = this.getNextDate()
		this.props.onNavigate('', new Date(date))
	}

	/**
	 * Handles clicks on previous.
	 */
	onPreviousClick = () => {
		const date = this.getPreviousDate()
		this.props.onNavigate('', new Date(date))
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildCalendarSelectInputs = () => {
		const calendars = this.getCalendarsForSelect()
		const options = selectInputsForCalendars(calendars)
		return (
			<Select
				className='react-select'
				value={this.state.selectedCalendar}
				options={options}
				onChange={this.onCalendarChange}
				placeholder='Actions...'
			/>
		)
	}

	buildSelect = () => {
		const items = [
			{ label: 'Month', value: BigCalendar.Views.MONTH },
			{ label: 'Week', value: BigCalendar.Views.WEEK },
			{ label: 'Day', value: BigCalendar.Views.DAY },
		]
		return (
			<Select
				className='react-select time-select'
				options={items}
				onChange={this.onViewChange}
				value={this.getCurrentSelect()}
			/>
		)
	}

	/**
	 * Builds a `NewCalendarModal` component.
	 */
	buildNewCalendarModal = () => {
		if (!this.state.creatingCalendar) { return null }
		return (
			<EditCalendarModal
				submitButtonHandler={this.handleCreateNewCalendar}
				cancelButtonHandler={this.handleCancelNewCalendar}
			/>
		)
	}

	public render() {
		return (
			<RS.Container className='cal-header-container'>
				<RS.Row noGutters={true}>
					<RS.Col className='cal-header-col left-justify' md='4' sm='12'>
						<RS.Button className='today-button' onClick={this.onTodayClick}>
							Today
						</RS.Button>
						<RS.ButtonGroup>
							<RS.Button onClick={this.onPreviousClick}>
								<Feather.ChevronLeft />
							</RS.Button>
							<RS.Button onClick={this.onNextClick}>
								<Feather.ChevronRight />
							</RS.Button>
						</RS.ButtonGroup>
					</RS.Col>
					<RS.Col className='cal-header-col center' md='4' sm='12'>
						<h3>{this.getTitle()}</h3>
					</RS.Col>
					<RS.Col className='cal-header-col right-justify d-flex justify-content-end' md='2' sm='12'>
						{this.buildCalendarSelectInputs()}
					</RS.Col>
					<RS.Col className='cal-header-col right-justify d-flex justify-content-end' md='2' sm='12'>
						{this.buildSelect()}
					</RS.Col>
				</RS.Row>
				{this.buildNewCalendarModal()}
			</RS.Container >
		)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	getCalendarsForSelect = () => {
		const group = oc(this).props.calendarState.currentCalendarGroup()
		const calendars = oc(this).props.calendarState.calendars([])
		return calendars.filter((c) => `${c.groupID}` === `${group._id}`)
	}

	/**
	 * Gets the selected calendar for the component.
	 */
	getSelectedCalendarInput = (calendar?: core.Calendar.Model) => {
		// If we have a calendar ID, use this one.
		if (calendar) {
			return { label: calendar.name, value: `${calendar._id}` }
		}

		// If we have one calendar in the group, use that one.
		const calendars = this.getCalendarsForSelect()
		if (calendars.length === 1) {
			const cal = calendars[0]
			return { label: cal.name, value: `${cal._id}` }
		}

		// If we have multiple cals, select em all.
		return { label: 'All', value: 'All' }
	}

	getNextDate = () => {
		const currentDate: Date = this.props.date
		switch (this.props.view) {
			case BigCalendar.Views.MONTH:
				return currentDate.setMonth(currentDate.getMonth() + 1)
			case BigCalendar.Views.WEEK:
				return currentDate.setDate(currentDate.getDate() + 7)
			case BigCalendar.Views.DAY:
				return currentDate.setDate(currentDate.getDate() + 1)
		}
	}

	getPreviousDate = () => {
		const currentDate: Date = this.props.date
		switch (this.props.view) {
			case BigCalendar.Views.MONTH:
				return currentDate.setMonth(currentDate.getMonth() - 1)
			case BigCalendar.Views.WEEK:
				return currentDate.setDate(currentDate.getDate() - 7)
			case BigCalendar.Views.DAY:
				return currentDate.setDate(currentDate.getDate() - 1)
		}
	}

	getCurrentSelect = () => {
		switch (this.props.view) {
			case BigCalendar.Views.MONTH:
				return { label: 'Month', value: BigCalendar.Views.MONTH }
			case BigCalendar.Views.WEEK:
				return { label: 'Week', value: BigCalendar.Views.WEEK }
			case BigCalendar.Views.DAY:
				return { label: 'Day', value: BigCalendar.Views.DAY }
		}
	}

	/**
	 * Builds a new calendar payload.
	 */
	buildCalendarPayload = (name: string, color: string, groupID: string): core.Calendar.Model => {
		const { loggedInClub } = this.props

		const payload: core.Calendar.Model = {
			name: name,
			clubID: loggedInClub._id as any,
			groupID: groupID as any,
			maxParticipants: null,
			location: loggedInClub.locations[0],
			color: color
		}
		return payload
	}

	getTitle = () => {
		const currentDate: Date = this.props.date
		let dateFormatOptions: Intl.DateTimeFormatOptions = {
			month: 'long',
			year: 'numeric',
		}
		if (this.props.view === BigCalendar.Views.DAY) {
			dateFormatOptions = {
				...dateFormatOptions,
				day: 'numeric',
			}
		}
		return currentDate.toLocaleString('en-us', dateFormatOptions)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	userState: state.user,
	calendarState: state.calendar,
	currentClubCalendars: clubCalendarsForCurrentClub(state),
	loggedInClub: state.club.loggedInClub,
})

const mapDispatchToProps = {
	...AlertActions,
	...UserActions,
	...EventActions,
	...CalendarActions
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarTopBar)
