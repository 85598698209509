// External Dependencies
import { Action, ActionCreator, Dispatch } from 'redux'
import { ThunkAction } from 'redux-thunk'
import * as core from 'club-hub-core'
import { Store } from 'react-redux'
import to from 'await-to-js'

// Internal Dependencies
import { GeneralMap, QueryOptions } from '../helpers/interface'
import rootReducer, { RootReducerState } from '../reducers'
import { eventsByIDSelector } from '../reducers/event'

// API Helpers
import { GET, POST, POST_FORM, PUT_FORM, DELETE, PUT } from '../helpers/api'

// Actions
export enum TypeKeys {
	CREATED_RSVP = 'CREATED_RSVP',
	UPDATED_RSVP = 'UPDATED_RSVP',
	CANCELED_RSVP = 'CANCELED_RSVP',
	FETCHED_BOOKABLE_EVENTS = 'FETCHED_BOOKABLE_EVENTS',
	RESERVED_BOOKABLE_EVENT = 'RESERVED_BOOKABLE_EVENT',
	FETCHED_EVENTS = 'FETCHED_EVENTS',
	FETCHED_EVENT = 'FETCHED_EVENT',
	CREATED_EVENT = 'CREATED_EVENT',
	UPDATED_EVENT = 'UPDATED_EVENT',
	DELETED_EVENT = 'DELETED_EVENT',
	SET_CURRENT_EVENT = 'SET_CURRENT_EVENT',
	CLEAR_CURRENT_EVENT = 'CLEAR_CURRENT_EVENT',
}

export type ActionTypes =
	| CreatedRsvpAction
	| UpdatedRsvpAction
	| CanceledRsvpAction
	| FetchedBookableEventsAction
	| ReservedBookableEventAction
	| FetchedEventsAction
	| FetchedEventAction
	| CreatedEventAction
	| UpdatedEventAction
	| DeletedEventAction
	| SetCurrentEventAction
	| ClearCurrentEventAction

export interface CreatedRsvpAction extends Action {
	type: TypeKeys.CREATED_RSVP,
	event: core.Event.Model,
}

export interface UpdatedRsvpAction extends Action {
	type: TypeKeys.UPDATED_RSVP,
	event: core.Event.Model,
}

export interface CanceledRsvpAction extends Action {
	type: TypeKeys.CANCELED_RSVP,
}

export interface FetchedBookableEventsAction extends Action {
	type: TypeKeys.FETCHED_BOOKABLE_EVENTS,
	bookableEvents: core.Event.EventDataForCalendar[]
}

export interface ReservedBookableEventAction extends Action {
	type: TypeKeys.RESERVED_BOOKABLE_EVENT,
}

export interface FetchedEventsAction extends Action {
	type: TypeKeys.FETCHED_EVENTS,
	events: core.Event.Model[],
	count: number
}

export interface FetchedEventAction extends Action {
	type: TypeKeys.FETCHED_EVENT,
	event: core.Event.Model,
}

export interface CreatedEventAction extends Action {
	type: TypeKeys.CREATED_EVENT,
	event: core.Event.Model,
}

export interface UpdatedEventAction extends Action {
	type: TypeKeys.UPDATED_EVENT,
	event: core.Event.Model,
}

export interface DeletedEventAction extends Action {
	type: TypeKeys.DELETED_EVENT,
	eventID: string,
}

export interface SetCurrentEventAction extends Action {
	type: TypeKeys.SET_CURRENT_EVENT,
	event: core.Event.Model
}

export interface ClearCurrentEventAction extends Action {
	type: TypeKeys.CLEAR_CURRENT_EVENT,
}

// -----------------------------------------------------------------------------
// Create Event RSVP
// -----------------------------------------------------------------------------

const createEventRsvp = (reservation: core.Event.Reservation, eventID: string) => async (dispatch: Dispatch<CreatedRsvpAction>) => {
	const [err, eventRes] = await to<core.Event.Model>(POST(`/events/${eventID}/rsvp`, { reservation: reservation }))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to create RSVP for Event with error: ${err}`)
		throw err
	}

	dispatch(createdEventRsvp(eventRes))
}

const createdEventRsvp = (createdEventWithRsvp: core.Event.Model): CreatedRsvpAction => {
	const action: CreatedRsvpAction = {
		type: TypeKeys.CREATED_RSVP,
		event: createdEventWithRsvp,
	}
	return action
}

// -----------------------------------------------------------------------------
// Create Event RSVP
// -----------------------------------------------------------------------------

/**
 * Create RSVPs for multiple users.
 * @param eventID The event to RSVP to.
 * @param userIDs THe ids for the users.
 */
const createEventRsvps = (eventID: string, userIDs: string[]) => async (dispatch: Dispatch<CreatedRsvpAction>) => {
	const [err, eventRes] = await to<core.Event.Model>(POST(`/events/${eventID}/rsvp`, { userIDs }))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to create RSVP for Event with error: ${err}`)
		throw err
	}

	dispatch(createdEventRsvp(eventRes))
}

// -----------------------------------------------------------------------------
// Update Event RSVP
// -----------------------------------------------------------------------------

const updateEventRsvp = (reservationID: string, reservation: core.Event.Reservation, eventID: string) => async (dispatch: Dispatch<any>) => {
	const [err, eventRes] = await to<core.Event.Model>(PUT(`/events/${eventID}/rsvp/${reservationID}`, { reservation: reservation }))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to update RSVP for Event with error: ${err}`)
		throw err
	}

	dispatch(updatedEventRsvp(eventRes))
}

const updatedEventRsvp = (updatedEventWithRsvp: core.Event.Model): UpdatedRsvpAction => {
	const action: UpdatedRsvpAction = {
		type: TypeKeys.UPDATED_RSVP,
		event: updatedEventWithRsvp,
	}
	return action
}

// -----------------------------------------------------------------------------
// Cancel Event RSVP
// -----------------------------------------------------------------------------

const cancelEventRsvp = (reservationID: string, eventID: string, page?: number, pageSize?: number, groupID?: string) => async (dispatch: Dispatch<any>) => {
	const [err] = await to(DELETE(`/events/${eventID}/rsvp/${reservationID}`, {}))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to cancel RSVP for Event with error: ${err}`)
		throw err
	}

	dispatch(canceledEventRsvp())

	// Why is this stuff in here? Refactor this stuff out another day?
	const currentPage = page || 0
	const currentPageSize = pageSize || 20
	const eventQueryParams: QueryOptions = {
		limit: currentPageSize,
		offset: currentPage * currentPageSize,
		groupID: groupID
	}
	await dispatch(fetchEvents(eventQueryParams))
}

const canceledEventRsvp = (): CanceledRsvpAction => {
	const action: CanceledRsvpAction = {
		type: TypeKeys.CANCELED_RSVP,
	}
	return action
}

// -----------------------------------------------------------------------------
// Fetch Bookable Events
// -----------------------------------------------------------------------------

const fetchBookableEvents = (date: string, calendarIDs: string[], isAdmin: boolean) => async (dispatch: Dispatch<FetchedBookableEventsAction>) => {
	const route = (isAdmin) ? `/admin/calendar/available` : `/calendar/available`
	const [err, eventsRes] = await to(GET(route, { date: date, calendarIDs: calendarIDs }))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to fetch bookable Events with error: ${err}`)
		throw err
	}
	const bookableEvents = eventsRes || []
	dispatch(fetchedBookableEvents(bookableEvents))
}

const fetchedBookableEvents = (bookableEvents: core.Event.EventDataForCalendar[]): FetchedBookableEventsAction => {
	const action: FetchedBookableEventsAction = {
		type: TypeKeys.FETCHED_BOOKABLE_EVENTS,
		bookableEvents: bookableEvents,
	}
	return action
}

// -----------------------------------------------------------------------------
// Reserve Bookable Event
// -----------------------------------------------------------------------------

const reserveBookableEvent = (reservation: core.Event.Reservation, calendarID: string, startTime: string, endTime?: string) => async (dispatch: Dispatch<ReservedBookableEventAction>) => {
	const body: any = { startTime, reservation, calendarID }
	if (endTime) {
		body.endTime = endTime
	}
	const [err, eventRes] = await to<core.Event.Model>(POST(`/calendar/${calendarID}/reserve`, body))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to reserve bookable Event with error: ${err}`)
		throw err
	}

	dispatch(reservedBookableEvent(eventRes))
}

const reservedBookableEvent = (reservedEvent: core.Event.Model): ReservedBookableEventAction => {
	const action: ReservedBookableEventAction = {
		type: TypeKeys.RESERVED_BOOKABLE_EVENT,
	}
	return action
}

// -----------------------------------------------------------------------------
// Fetch Events
// -----------------------------------------------------------------------------

const fetchEventsForUser = (queryParams: QueryOptions) => async (dispatch: Dispatch<FetchedEventsAction>) => {
	const [err, res] = await to(GET('/events/attending', queryParams))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to fetch Events for User with error: ${err}`)
		throw err
	}

	// To ensure uniqueness for Events, we need to append the start date
	// to the ID, because recurring Events are all built from a single Event
	const resEvents = res.events || []
	const paginationRes = {
		events: resEvents,
		count: res.count
	}

	dispatch(fetchedEvents(paginationRes))
}

const fetchEvents = (queryParams: QueryOptions) => async (dispatch: Dispatch<FetchedEventsAction>) => {
	if (queryParams.start) {
		queryParams.timeField = 'start'
	}
	const [err, eventRes] = await to(GET('/events', queryParams))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to fetch Events with error: ${err}`)
		throw err
	}

	const resEvents = eventRes.events || []
	const paginationRes = {
		events: resEvents,
		count: eventRes.count
	}
	return dispatch(fetchedEvents(paginationRes))
}

const fetchedEvents = (eventRes: GeneralMap<core.Event.Model[] | number>): FetchedEventsAction => {
	const action: FetchedEventsAction = {
		type: TypeKeys.FETCHED_EVENTS,
		events: eventRes.events as core.Event.Model[],
		count: eventRes.count as number,
	}
	return action
}

// -----------------------------------------------------------------------------
// Fetch Event
// -----------------------------------------------------------------------------
const fetchEvent = (eventID: string, date?: Date) => async (dispatch: Dispatch<FetchedEventAction>): Promise<FetchedEventAction> => {
	let query = {}
	if (date) {
		query = { date: date.toISOString() }
	}
	const [err, event] = await to<core.Event.Model>(GET(`/events/${eventID}`, query))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to fetch Event with error: ${err}`)
		throw err
	}
	return dispatch(fetchedEvent(event))
}

const fetchedEvent = (event: core.Event.Model): FetchedEventAction => {
	const action: FetchedEventAction = {
		type: TypeKeys.FETCHED_EVENT,
		event: event,
	}
	return action
}

// -----------------------------------------------------------------------------
// Create Event
// -----------------------------------------------------------------------------

const createEvent = (eventPayload: FormData, page?: number, pageSize?: number) => async (dispatch: Dispatch<CreatedEventAction>) => {
	const [createErr, res] = await to(POST_FORM('/admin/events', eventPayload))
	if (createErr) {
		// tslint:disable-next-line
		console.error(`Failed to create Event with error: ${createErr}`)
		throw createErr
	}

	dispatch(createdEvent(res))

	const currentPage = page || 0
	const currentPageSize = pageSize || 20
	const eventQueryParams: QueryOptions = {
		limit: currentPageSize,
		offset: currentPage * currentPageSize,
		groupID: res.groupID,
	}
	const [fetchError] = await to(dispatch(fetchEvents(eventQueryParams) as any))
	if (fetchError) {
		// tslint:disable-next-line
		console.error(`Failed to fetch`)
		throw fetchError
	}
}

const createdEvent = (event: core.Event.Model): CreatedEventAction => {
	const action: CreatedEventAction = {
		type: TypeKeys.CREATED_EVENT,
		event: event,
	}
	return action
}

// -----------------------------------------------------------------------------
// Update Event
// -----------------------------------------------------------------------------

const updateEvent = (eventID: string, groupID: string, eventPayload: FormData, type?: core.Event.UpdateType) => async (dispatch: Dispatch<UpdatedEventAction>) => {
	const [updateErr, updateRes] = await to<core.Event.Model>(PUT_FORM(`/admin/events/${eventID}`, eventPayload, { type }))
	if (updateErr) {
		// tslint:disable-next-line
		console.error(`Failed to update Event with error: ${updateErr}`)
		throw updateErr
	}
	dispatch(updatedEvent(updateRes))

	const eventQueryParams: QueryOptions = {
		limit: 0,
		offset: 0,
		groupID: groupID
	}
	const [fetchError] = await to(dispatch(fetchEvents(eventQueryParams) as any))
	if (fetchError) {
		// tslint:disable-next-line
		console.error(`Failed to fetch`)
		throw fetchError
	}
}

const updatedEvent = (event: core.Event.Model): UpdatedEventAction => {
	const action: UpdatedEventAction = {
		type: TypeKeys.UPDATED_EVENT,
		event: event
	}
	return action
}

// -----------------------------------------------------------------------------
// Deleted Event
// -----------------------------------------------------------------------------

/**
 * Deletes an event. If a date is supplied, only that occurrence of the date is deleted from a recurring set of dates.
 * @param eventID The event to delete.
 * @param date The specific occurrence to delete.
 */
const deleteEvent = (eventID: string, date?: Date) => async (dispatch: Dispatch<DeletedEventAction>) => {
	const query = date ? { date: date.toISOString() } : null

	const [deleteErr] = await to(DELETE(`/admin/events/${eventID}`, query))
	if (deleteErr) {
		// tslint:disable-next-line
		console.error(`Failed to delete Event with error: ${deleteErr}`)
		throw deleteErr
	}

	dispatch(deletedEvent(eventID))
}

const deletedEvent = (eventID: string): DeletedEventAction => {
	const action: DeletedEventAction = {
		type: TypeKeys.DELETED_EVENT,
		eventID: eventID,
	}
	return action
}

// -----------------------------------------------------------------------------
// Set Current Event
// -----------------------------------------------------------------------------

const setCurrentEvent = (event: core.Event.Model) => async (dispatch: Dispatch<SetCurrentEventAction>): Promise<SetCurrentEventAction> => {
	const action: SetCurrentEventAction = {
		type: TypeKeys.SET_CURRENT_EVENT,
		event: event
	}
	return dispatch(action)
}

// -----------------------------------------------------------------------------
// Fetch Event If Needed
// -----------------------------------------------------------------------------

const fetchEventIfNeeded: ActionCreator<ThunkAction<Promise<FetchedEventAction | SetCurrentEventAction>, RootReducerState, void, FetchedEventAction>> = (eventID: string) =>
	async (dispatch: Dispatch, getState: () => RootReducerState) => {
		const event = eventsByIDSelector(getState())[eventID]
		if (!event) {
			return dispatch(fetchEvent(eventID) as any)
		} else {
			return dispatch(setCurrentEvent(event) as any)
		}
	}

// -----------------------------------------------------------------------------
// Clear Current Event
// -----------------------------------------------------------------------------

const clearCurrentEvent = () => async (dispatch: Dispatch<ClearCurrentEventAction>) => {
	const action: ClearCurrentEventAction = {
		type: TypeKeys.CLEAR_CURRENT_EVENT,
	}
	await dispatch(action)
}

export const EventActions = {
	createEventRsvp,
	createEventRsvps,
	updateEventRsvp,
	cancelEventRsvp,
	fetchBookableEvents,
	reserveBookableEvent,
	fetchEventsForUser,
	fetchEventIfNeeded,
	fetchEvents,
	fetchEvent,
	createEvent,
	updateEvent,
	deleteEvent,
	setCurrentEvent,
	clearCurrentEvent,
}
