// External Dependencies
import * as Feather from 'react-feather'

// Internal Dependencies
import { TableColumn } from '../Shared/Table'
import { TableColumnType } from '../Shared/Table/Primitives'

export const UserGroupTableColumns: TableColumn[] = [
	{
		title: '',
		property: '',
		type: TableColumnType.CHECKBOX,
		action: '',
	},
	{
		title: 'Name',
		property: 'name',
		action: 'name',
		type: TableColumnType.TEXT,
	},
	{
		title: 'Type',
		property: 'type',
		action: 'type',
		type: TableColumnType.BADGE,
	},
	{
		title: 'Description',
		property: 'description',
		action: 'description',
		type: TableColumnType.TEXT,
	},
	{
		title: 'Last Updated',
		property: 'updatedAt',
		action: 'updatedAt',
		type: TableColumnType.DATE_TIME,
	},
	{
		title: 'Members',
		property: 'users',
		action: 'users',
		type: TableColumnType.AVATAR_LIST,
	},
	{
		title: '',
		property: '',
		type: TableColumnType.DROPDOWN,
		class: 'table-dropdown-menu',
		dropdownItems: [
			{
				action: 'viewUserGroup',
				valueProperty: '_id',
				displayText: 'View Member Group',
				icon: Feather.Eye,
			},
			{
				action: 'messageUserGroup',
				valueProperty: '_id',
				displayText: 'Message Member Group',
				icon: Feather.MessageSquare,
			},
			{
				action: 'sendInvite',
				valueProperty: '_id',
				displayText: 'Send Invite',
				icon: Feather.Send,
			},
			{
				action: 'editUserGroup',
				valueProperty: '_id',
				displayText: 'Edit Member Group',
				icon: Feather.Edit,
			},
			{
				action: 'removeUserGroup',
				valueProperty: '_id',
				displayText: 'Remove Member Group',
				icon: Feather.Trash,
			}
		]
	}
]
