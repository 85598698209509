// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import * as core from 'club-hub-core'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { isNullOrUndefined } from 'util'
import { RouteComponentProps } from 'react-router'
import to from 'await-to-js'
import { oc } from 'ts-optchain'

// Internal Dependencies

// State
import { RootReducerState } from '../../reducers/index'
import { inCustomerViewSelector } from '../../reducers/user'

// Actions
import { EventActions, AlertActions, CalendarActions } from '../../actions/index'

// Components
import ErrorComponent from '../Shared/ErrorComponent'
import ModalComponent from '../Shared/Modal'
import ReservationListComponent from '../Shared/ReservationList'
import DimmedLoader from '../Shared/DimmedLoader'
import EmptyContent, { ContentType } from '../Shared/EmptyContent'
import { TabBarButtonInput } from '../Shared/TabBar'
import TableHeader from '../Shared/TableHeader'
import { HeaderButton, ButtonType } from '../Shared/ButtonGroup'

// Helpers
import * as Constants from '../../constants'
import { setStateAsync } from '../../helpers/promise'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	selectedGroupID: null as string | null,
	selectedCalendarID: null as string | null,
	selectedUserID: null as string | null,
	eventToCancel: null as string | null,
	reservationToCancel: null as string | null,
	error: false,
	initialLoad: true,
	loading: false,
	pageIndex: 0,
	viewingPast: false,
}

type Props = RouteComponentProps & ConnectedState & ConnectedActions
type State = typeof initialState

class MyReservationsComponent extends React.Component<Props, State> {
	private pageSize: number
	private club: core.Club.Model
	private calGroup: core.Calendar.Group
	private buttonName: string
	private newReservationRoute: string

	constructor(props: Props) {
		super(props)
		this.pageSize = 20
		this.club = this.props.loggedInClub
		this.setInitialValues()
		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		await Promise.all([
			this.fetchEvents({ start: new Date().toISOString(), timeField: 'start' }),
			this.fetchCalendars()
		])
	}

	fetchEvents = async (queryOpts?: core.IShared.QueryOptions) => {
		await setStateAsync(this, { loading: true })

		const eventQueryParams: core.IShared.QueryOptions = { ...queryOpts }
		eventQueryParams.limit = this.pageSize
		eventQueryParams.offset = this.pageSize * this.state.pageIndex
		eventQueryParams.groupIDs = [this.calGroup._id]

		// Fetch upcoming events by default.
		if (!eventQueryParams.start && !eventQueryParams.end) {
			eventQueryParams.start = new Date().toISOString()
			eventQueryParams.timeField = 'start'
		}

		const [err] = await to(this.props.fetchEventsForUser(eventQueryParams) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to fetch your Events. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}
		await setStateAsync(this, { loading: false, initialLoad: false })
	}

	fetchCalendars = async () => {
		const queryParams: core.IShared.QueryOptions = { offset: 0, limit: 0 }
		const [fetchCalendarsErr] = await to(this.props.fetchCalendars(queryParams) as any)
		if (fetchCalendarsErr) {
			this.props.fireFlashMessage(`Failed to fetch your Events. ${fetchCalendarsErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * When editing an Event, navigate to the Event update
	 * form and pass the Event's ID via the query parameters
	 */
	handleEditEvent = (reservationID: string) => {
		// Find the Service with the Reservation
		const service = this.props.eventState.events.find((e) => {
			return !isNullOrUndefined(e.reservations.find((r) => `${r._id}` === reservationID))
		})

		// Query params
		const queryParams = {
			serviceID: `${service._id}`,
			reservationID: reservationID,
		}

		const location = {
			pathname: Constants.UPDATE_SCHEDULED_SERVICE_ROUTE,
			search: queryString.stringify(queryParams)
		}

		this.props.history.push(location)
	}

	/**
	 * When attempting to cancel an Event, display the
	 * Event cancellation confirmation modal
	 */
	handleCancelEvent = async (reservationID: string) => {
		// Find the Service with the Reservation
		const service = this.props.eventState.events.find((e) => {
			return !isNullOrUndefined(e.reservations.find((r) => `${r._id}` === reservationID))
		})

		const actualEventID = `${service._id}`
		return setStateAsync(this, { eventToCancel: actualEventID, reservationToCancel: reservationID })
	}

	handleTimeFilterChange = async (isViewingPast: boolean) => {
		await setStateAsync(this, { viewingPast: isViewingPast, loading: true })

		const eventQueryParams = (isViewingPast) ?
			({ end: new Date().toISOString(), timeField: 'end' }) :
			({ start: new Date().toISOString(), timeField: 'start' })

		await this.fetchEvents(eventQueryParams)
		await setStateAsync(this, { loading: false })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Modal Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Make the API call to cancel the Event, when the
	 * user confirms the cancellation via the modal
	 */
	executeEventDeletion = async () => {
		const reservationID = this.state.reservationToCancel
		const eventID = this.state.eventToCancel
		const groupID = this.state.selectedGroupID
		await setStateAsync(this, { loading: true })
		const currentPage = this.state.pageIndex
		const [deleteErr] = await to(this.props.cancelEventRsvp(reservationID, eventID, currentPage, this.pageSize, groupID) as any)
		if (deleteErr) {
			this.props.fireFlashMessage(`Problem trying to cancel Service. ${deleteErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true, eventToCancel: null, reservationToCancel: null })
			return
		}

		this.props.fireFlashMessage('Successfully cancelled Service', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, eventToCancel: null, reservationToCancel: null })
	}

	/**
	 * Hide the cancellation modal when the user closes it
	 */
	closeCancelEventModal = async () => {
		return setStateAsync(this, { eventToCancel: null, reservationToCancel: null })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Event Handlers
	// ----------------------------------------------------------------------------------

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	/**
	 * Sets component values used to fill out the reservation confirmation UI.
	 */
	setInitialValues = (): void => {
		const calGroupType = (this.props.match.params as any).type
		this.calGroup = oc(this.club).calendarGroups().find((g) => g.type === calGroupType.toUpperCase())

		switch (this.calGroup.type) {
			case core.Calendar.GroupType.Dining:
				this.buttonName = 'New Reservation'
				this.newReservationRoute = Constants.DINING_RESERVATIONS_ROUTE
				break
			case core.Calendar.GroupType.Golf:
				this.buttonName = 'New Tee Time'
				this.newReservationRoute = Constants.GOLF_RESERVATION_ROUTE
				break
			case core.Calendar.GroupType.Service:
				this.buttonName = 'New Service'
				this.newReservationRoute = Constants.CREATE_SCHEDULED_SERVICE_ROUTE
				break
			default:
				throw new Error(`Invalid calendar group found: ${this.calGroup.type}`)
		}
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildCancelConfirmationModal = () => {
		if (!this.state.eventToCancel || !this.state.reservationToCancel) { return null }
		return (
			<ModalComponent
				modalTitle={'Cancel Service'}
				primaryMessage={'Are you sure you want to cancel this Service?'}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.closeCancelEventModal}
				submitButtonName={'Confirm'}
				submitButtonHandler={this.executeEventDeletion}
			/>
		)
	}

	buildHeaderButtons = (): HeaderButton[] => {
		return [{
			action: '',
			type: ButtonType.DEFAULT,
			text: this.buttonName,
			class: 'btn-primary',
		}
		]
	}

	/**
	 * This will navigate the user to the reservation form
	 * that corresponds to the calendar group type.
	 */
	handleHeaderButtonAction = async () => {
		this.props.history.push(this.newReservationRoute)
	}

	buildPageHeader = () => {
		const buttons: TabBarButtonInput[] = [
			{ title: 'Upcoming', onClick: () => this.handleTimeFilterChange(false) },
			{ title: 'Past', onClick: () => this.handleTimeFilterChange(true) }
		]
		return (
			<TableHeader
				fullScreen={!this.props.isCustomerView}
				tabInputs={buttons}
				pageTitle={this.calGroup.name}
				buttons={this.buildHeaderButtons()}
				buttonHandler={this.handleHeaderButtonAction}
			/>
		)
	}

	buildContent = () => {
		const eventState = this.props.eventState
		if (!eventState.events || eventState.events.length === 0) { return this.buildEmptyCustomerView() }

		const reservationList = (<ReservationListComponent events={eventState.events} />)
		return (
			<RS.Col className='my-reservation__reservation-list-container' lg={12}>
				<DimmedLoader component={reservationList} isLoading={this.state.loading} />
			</RS.Col>
		)
	}

	buildEmptyCustomerView = () => {
		let emptyContentType
		switch (this.calGroup.type) {
			case core.Calendar.GroupType.Dining:
				emptyContentType = ContentType.Dining
				break
			case core.Calendar.GroupType.Golf:
				emptyContentType = ContentType.TeeTimes
				break
			case core.Calendar.GroupType.Service:
				emptyContentType = ContentType.Services
				break
			default:
				emptyContentType = ContentType.Reservations
				break
		}

		return <EmptyContent type={emptyContentType} isInPast={this.state.viewingPast} actionHandler={this.handleHeaderButtonAction} />
	}

	render() {
		const { loggedInClub } = this.props
		if (this.state.initialLoad) { return <DimmedLoader component={null} isLoading={true} /> }
		if (this.state.error) { return <ErrorComponent club={loggedInClub} isAdmin={this.props.userState.isAdmin} /> }

		return (
			<RS.Col className={'my-reservation__content-container'} xs={12} md={8}>
				{this.buildPageHeader()}
				{this.buildContent()}
			</RS.Col>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	eventState: state.event,
	calendarState: state.calendar,
	loggedInClub: state.club.loggedInClub,
	userState: state.user,
	isCustomerView: inCustomerViewSelector(state),
})

const mapDispatchToProps = {
	...CalendarActions,
	...EventActions,
	...AlertActions,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyReservationsComponent)
