// External Dependencies
import * as React from 'react'

// Internal Dependencies
import { BaseTablePrimitiveProps } from '../index'
import { BaseTableCell } from '../BaseTableCell'

type Props<T> = BaseTablePrimitiveProps<T>

export const TableArrayLength: React.FC<Props<any>> = (props: Props<any>) => {
	const rawValue = props.item[props.column.property] as any[]
	return (
		<BaseTableCell {...props}>
			{rawValue.length || 0}
		</BaseTableCell>
	)
}
