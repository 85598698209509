// External Dependencies
import * as React from 'react'

// Internal Dependencies
import { BaseInputPrimitiveProps } from '../index'
import { InputSelectionItem } from '../../../Form'

type Props = BaseInputPrimitiveProps

export const RadioGroupInput: React.FunctionComponent<Props> = (props: Props) => {
	const selectItems = props.item.selectItems as InputSelectionItem[]
	const items = selectItems.map((selectItem: InputSelectionItem, selectIndex: number) => {
		const hasLabel = selectItem.label !== ''
		const iconClass = (hasLabel) ? 'mr-2' : ''
		const FeatherIcon = selectItem.icon
		const icon = (selectItem.icon) ? (<FeatherIcon size={15} className={iconClass} />) : null
		return (
			<label className='selectgroup-item' key={`selectgroup-item_${selectIndex}`}>
				<input
					className='selectgroup-input'
					type='radio'
					id={`radio_${selectIndex}`}
					name={props.item.property}
					defaultChecked={`${props.value}` === `${selectItem.value}`}
					value={selectItem.value}
					disabled={props.item.disabled}
					onChange={props.onChange}
					placeholder={props.item.placeholder}
				/>
				<span className='selectgroup-button selectgroup-button-icon d-flex justify-content-center align-items-center'>
					{icon}
					<span>{selectItem.label}</span>
				</span>
			</label>
		)
	})

	return (
		<div className={`selectgroup w-100 ${props.item.class}`} id={props.item.property}>
			{items}
		</div>
	)
}
