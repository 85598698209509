// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as RS from 'reactstrap'
import * as queryString from 'query-string'
import * as Feather from 'react-feather'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { isEqual } from 'underscore'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies

// State
import { RootReducerState } from '../../reducers/index'
import { inCustomerViewSelector, usersByIDSelector } from '../../reducers/user'
import { calendarsByIDSelector, clubCalendarsForCurrentClub } from '../../reducers/calendar'
import { eventsByIDSelector } from '../../reducers/event'

// Actions
import { EventActions, AlertActions, MessageActions, CalendarActions } from '../../actions/index'

// Components
import { HeaderButton, ButtonType } from '../Shared/ButtonGroup'
import ErrorComponent from '../Shared/ErrorComponent'
import TableComponent from '../Shared/Table'
import ModalComponent from '../Shared/Modal'
import DimmedLoader from '../Shared/DimmedLoader'
import { InputSelectionItem } from '../Shared/Form'
import EmptyContent, { ContentType } from '../Shared/EmptyContent'
import ClubTypes from '../Shared/ClubTypes'
import ClubCalendarsComponent from '../ClubCalendars'
import EventLocationsComponent from '../EventLocations'
import RichEventCard from './RichEventCard'

// Tables
import { EventTableColumns, EventTableActions } from './table'
import { TabBarButtonInput } from '../Shared/TabBar'
import TableHeader, { PageHeaderSearchInput, PageHeaderInputType } from '../Shared/TableHeader'

// Helpers
import * as Constants from '../../constants'
import { setStateAsync } from '../../helpers/promise'
import { usersForEvent, calendarIDForEvent } from '../../helpers/event'
import { selectInputsForCalendar } from '../../helpers/calendar'
import { clubResourceTypeBadge, calendarBadge } from '../../helpers/badge'
import { buildFuseSearch, FuseItem } from '../../helpers/fuse'
import { FuseOptions } from 'fuse.js'

// Connected State Interface
type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

enum EventResourceTabs {
	Types = 'types',
	Locations = 'locations',
	Calendars = 'calendars'
}

// Header Button Actions
type HeaderButtonActions =
	typeof ShowRichContent |
	typeof ShowTableContent |
	typeof CreateEventType |
	typeof CreateClubCalendar |
	typeof CreateEventLocation |
	typeof CreateEvent
const ShowRichContent = 'showRichContent'
const ShowTableContent = 'showTableContent'
const CreateEventType = 'createEventType'
const CreateClubCalendar = 'createClubCalendar'
const CreateEventLocation = 'createEventLocation'
const CreateEvent = 'createEvent'

// State Key Constants
type ModalStateKey = typeof ShowingEventTypeCreationModal | typeof ShowingEventCalendarsCreationModal | typeof ShowingEventLocationCreationModal
const ShowingEventTypeCreationModal = 'showingEventTypeCreationModal'
const ShowingEventCalendarsCreationModal = 'showingEventCalendarsCreationModal'
const ShowingEventLocationCreationModal = 'showingEventLocationCreationModal'

const initialState = {
	showingTable: true,
	eventToDelete: null as string | null,
	error: false,
	initialLoading: true,
	loading: false,
	pageIndex: 0,
	searchTerm: '',
	filter: 'All',
	viewingPast: false,
	viewingDrafts: false,
	viewingTab: null as EventResourceTabs | null,
	[ShowingEventTypeCreationModal]: false,
	[ShowingEventCalendarsCreationModal]: false,
	[ShowingEventLocationCreationModal]: false
}

type Props = RouteComponentProps & ConnectedState & ConnectedActions
type State = typeof initialState

class EventComponent extends React.Component<Props, State> {
	private pageSize: number
	private club: core.Club.Model
	private clubCalGroup: core.Calendar.Group
	private myEventsFilter: string = 'My Events'
	private fuseEventSearch: Fuse<FuseItem, FuseOptions<FuseItem>>

	constructor(props: Props) {
		super(props)

		const { loggedInClub } = props

		this.club = loggedInClub

		// Get the club calendar.
		const calendarGroups = oc(this.club).calendarGroups([])
		this.clubCalGroup = calendarGroups.find((g) => g.type === core.Calendar.GroupType.Club)

		this.pageSize = 20
		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		await this.fetchEvents()
		this.buildFuseEventSearch()
		await setStateAsync(this, { initialLoading: false })
	}

	async componentDidUpdate(prevProps: Props) {
		const prevEvents = oc(prevProps).eventsByID({})
		const currentEvents = oc(this).props.eventsByID({})

		const eventsChanged = !isEqual(prevEvents, currentEvents)
		if (eventsChanged) {
			this.buildFuseEventSearch()
		}
	}

	// ----------------------------------------------------------------------------------
	// Network Requests
	// ----------------------------------------------------------------------------------

	fetchEvents = async () => {
		const { fetchEvents, fetchEventsForUser, fireFlashMessage } = this.props
		const { filter } = this.state

		this.setState({ loading: true })

		const eventQueryParams: core.IShared.QueryOptions = this.buildQueryOpts()
		const fetchEventsFunc = (filter === this.myEventsFilter) ? fetchEventsForUser : fetchEvents
		// Load up the calendars async
		this.props.fetchCalendars()
		const [err] = await to(fetchEventsFunc(eventQueryParams) as any)
		if (err) {
			fireFlashMessage(`Failed to fetch Events. ${err.message}`, Constants.FlashType.DANGER)
			this.setState({ loading: false, error: true })
			return
		}
		this.setState({ loading: false })
	}

	/**
	 * Builds the query options used to fetch events.
	 */
	buildQueryOpts = () => {
		const { isCustomerView } = this.props
		const { filter, viewingPast, viewingDrafts } = this.state

		const defaultQueryOpts: core.IShared.QueryOptions = {
			limit: 0,
			offset: 0,
			thin: true
		}

		if (filter !== 'All' && filter !== this.myEventsFilter) {
			defaultQueryOpts.calendarIDs = filter
		}

		if (filter === this.myEventsFilter) {
			defaultQueryOpts.groupIDs = [this.clubCalGroup._id]
		}

		if (viewingPast && !viewingDrafts) {
			defaultQueryOpts.end = new Date().toISOString()
			defaultQueryOpts.timeField = 'end'
			defaultQueryOpts.sortField = 'end'
			defaultQueryOpts.sortValue = '-1'
		}

		if (!viewingPast && !viewingDrafts) {
			const start = new Date()
			start.setHours(0, 0, 0, 0)
			defaultQueryOpts.start = start.toISOString()
			defaultQueryOpts.timeField = 'start'
		}

		if (isCustomerView) {
			defaultQueryOpts.displayInFeed = true
		}

		if (viewingDrafts) {
			defaultQueryOpts.drafts = true
		}
		return defaultQueryOpts
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Dropdown Actions
	// ----------------------------------------------------------------------------------

	/**
	 * Determines which action to take, based on which dropdown item
	 * the user selected in the table's dropdown menu
	 */
	handleTableDropdownAction = (e: [EventTableActions, string]) => {
		const [action, value] = e
		switch (action) {
			case EventTableActions.ViewEvent:
				return this.handleViewEvent(value)
			case EventTableActions.MessageEvent:
				return this.handleMessageEvent(value)
			case EventTableActions.MessageRSVPs:
				return this.handleMessageEventRSVPs(value)
			case EventTableActions.EditEvent:
				return this.handleEditEvent(value)
			case EventTableActions.DeleteEvent:
				return this.handleDeleteEvent(value)
			default:
				break
		}
	}

	handleViewEvent = (eventID: string) => {
		const { history } = this.props

		const queryParams = { eventID }
		const location = {
			pathname: Constants.VIEW_EVENT_ROUTE,
			search: queryString.stringify(queryParams)
		}
		history.push(location)
	}

	handleViewCalendar = (calendarID: string) => {
		const { history } = this.props

		const queryParams = { calendarID }
		const location = {
			pathname: Constants.CALENDAR_ROUTE,
			search: queryString.stringify(queryParams)
		}
		history.push(location)
	}

	/**
	 * When creating a Message about an Event, navigate to the Message creation
	 * form and pass information about the Event via query parameters
	 */
	handleMessageEvent = async (eventID: string) => {
		const { eventsByID, setCurrentEvent, clearCurrentMessage, history } = this.props

		const queryParams = { eventID }
		const location = {
			pathname: Constants.CREATE_MESSAGE_ROUTE,
			search: queryString.stringify(queryParams)
		}
		const currentEvent = eventsByID[eventID]
		await setCurrentEvent(currentEvent)
		await clearCurrentMessage()

		// Go to the route
		history.push(location)
	}

	/**
	 * When creating a Message for an Event's RSVPs, navigate to the Message creation
	 * form and pass information about the Event via query parameters
	 */
	handleMessageEventRSVPs = async (eventID: string) => {
		const { eventsByID, setCurrentEvent, clearCurrentMessage, history } = this.props

		const queryParams = { recipientEventID: eventID }
		const location = {
			pathname: Constants.CREATE_MESSAGE_ROUTE,
			search: queryString.stringify(queryParams)
		}
		const currentEvent = eventsByID[eventID]
		await setCurrentEvent(currentEvent)
		await clearCurrentMessage()

		// Go to the route
		history.push(location)
	}

	/**
	 * When editing an Event, navigate to the Event update
	 * form and pass the Event's ID via the query parameters
	 */
	handleEditEvent = (eventID: string) => {
		const { history } = this.props

		const queryParams = { eventID }
		const location = {
			pathname: Constants.UPDATE_EVENT_ROUTE,
			search: queryString.stringify(queryParams)
		}

		history.push(location)
	}

	/**
	 * When attempting to delete an Event, display the
	 * Event deletion confirmation modal
	 */
	handleDeleteEvent = async (eventID: string) => {
		const actualEventID = eventID.split(Constants.EVENT_DATE_PREFIX)[0]
		return setStateAsync(this, { eventToDelete: actualEventID })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Modal Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Make the API call to delete the Event, when the
	 * user confirms the deletion via the modal
	 */
	executeEventDeletion = async () => {
		const { deleteEvent, fireFlashMessage } = this.props
		const { eventToDelete } = this.state

		await setStateAsync(this, { loading: true })
		const [deleteErr] = await to(deleteEvent(eventToDelete) as any)
		if (deleteErr) {
			fireFlashMessage(`Problem trying to delete Event. ${deleteErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true, eventToDelete: null })
			return
		}

		// Refresh our event list.
		await this.fetchEvents()
		fireFlashMessage('Successfully deleted Event', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, eventToDelete: null })
	}

	/**
	 * Hide the deletion modal when the user cancels it
	 */
	closeDeleteEventModal = async () => {
		return setStateAsync(this, { eventToDelete: null })
	}

	openModal = async (modalStateKey: ModalStateKey) => {
		await setStateAsync(this, () => ({ [modalStateKey]: true }))
	}

	closeModal = async (modalStateKey: ModalStateKey) => {
		await setStateAsync(this, () => ({ [modalStateKey]: false }))
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Handle table pagination
	 */
	handlePage = async (data: any) => {
		const { pageIndex } = this.state

		// Check if the new page is different that the current one
		if (data.selected !== pageIndex) {
			await setStateAsync(this, { pageIndex: data.selected })
		}
	}

	/**
	 * Handle a column header of the table being selected
	 */
	handleTableHeaderAction = (e: any) => {
		// TODO: Add this functionality
	}

	/**
	 * Handle a table row being clicked
	 */
	handleTableRowClicked = (event: core.Event.Model) => {
		return this.handleViewEvent(event._id as any)
	}

	/**
	 * Handle a calendar table row being clicked
	 */
	handleTableCalendarRowClicked = (calendar: core.Calendar.Model) => {
		return this.handleViewCalendar(calendar._id as any)
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Header Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Determine which action to take, based on the header
	 * button that the user selected
	 */
	handleHeaderButtonAction = async (e: { target: { value: HeaderButtonActions } }) => {
		const action = e.target.value
		switch (action) {
			case ShowRichContent:
				await setStateAsync(this, { showingTable: false })
				break
			case ShowTableContent:
				await setStateAsync(this, { showingTable: true })
				break
			case CreateEventType:
				await this.openModal(ShowingEventTypeCreationModal)
				break
			case CreateClubCalendar:
				await this.openModal(ShowingEventCalendarsCreationModal)
				break
			case CreateEventLocation:
				await this.openModal(ShowingEventLocationCreationModal)
				break
			case CreateEvent:
				return this.handleCreateEvent()
			default:
				break
		}
	}

	/**
	 * Navigate to the Event creation form
	 */
	handleCreateEvent = () => {
		this.props.history.push(Constants.CREATE_EVENT_ROUTE)
	}

	handleFilterChange = async (eventType: InputSelectionItem) => {
		await setStateAsync(this, { filter: eventType.value, pageIndex: 0, viewingTab: null, loading: true })
		await this.fetchEvents()
	}

	handleTimeFilterChange = async (isViewingPast: boolean) => {
		await setStateAsync(this, { viewingPast: isViewingPast, viewingDrafts: false, pageIndex: 0, viewingTab: null, loading: true })
		await this.fetchEvents()
	}

	handleDraftsFilterChange = async () => {
		await setStateAsync(this, { viewingDrafts: true, pageIndex: 0, viewingTab: null, loading: true })
		await this.fetchEvents()
	}

	handleTypeTabChange = async (tabTitle: EventResourceTabs) => {
		await setStateAsync(this, { viewingTab: tabTitle, pageIndex: 0 })
	}

	handleSearchChange = async (term: string) => {
		await setStateAsync(this, { searchTerm: term, pageIndex: 0 })
	}

	handleSearchEnter = async (event: any) => {
		if (event.key === 'Enter') {
			await setStateAsync(this, { pageIndex: 0 })
		}
	}

	handleSearchClear = async () => {
		await setStateAsync(this, { searchTerm: '', pageIndex: 0 })
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildDeleteConfirmationModal = () => {
		const { eventToDelete } = this.state

		if (!eventToDelete) { return null }
		return (
			<ModalComponent
				modalTitle={'Delete Event'}
				primaryMessage={'Are you sure you want to delete this Event?'}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.closeDeleteEventModal}
				submitButtonName={'Confirm'}
				submitButtonHandler={this.executeEventDeletion}
			/>
		)
	}

	buildPageHeader = () => {
		const { isCustomerView } = this.props
		const { showingTable } = this.state

		const tabInputs: TabBarButtonInput[] = [
			{ title: 'Upcoming', onClick: () => this.handleTimeFilterChange(false) },
			{ title: 'Past', onClick: () => this.handleTimeFilterChange(true) },
			!isCustomerView && { title: 'Drafts', onClick: () => this.handleDraftsFilterChange() },
		]

		if (!isCustomerView && showingTable) {
			tabInputs.push(
				{ title: 'Calendars', onClick: () => this.handleTypeTabChange(EventResourceTabs.Calendars) },
				{ title: 'Locations', onClick: () => this.handleTypeTabChange(EventResourceTabs.Locations) },
				{ title: 'Types', onClick: () => this.handleTypeTabChange(EventResourceTabs.Types) },
			)
		}

		const searchItem = this.buildSearchItem()
		const selectItems = this.buildSelectItems()
		const headerButtons = this.buildHeaderButtons()
		const buttonItem = this.buildButtonItem()
		const inputs = [searchItem, ...selectItems, headerButtons, buttonItem]
		const showFullScreen = isCustomerView || !showingTable
		return (
			<TableHeader
				pageTitle='Events'
				tabInputs={tabInputs}
				inputs={inputs}
				fullScreen={!showFullScreen}
				buttonHandler={this.handleHeaderButtonAction}
			/>
		)
	}

	buildSearchItem = () => {
		return {
			inputType: PageHeaderInputType.Search,
			changeHandler: this.handleSearchChange,
			enterHandler: this.handleSearchEnter,
			clearHandler: this.handleSearchClear
		}
	}

	buildHeaderButtons = (): any => {
		const { isCustomerView } = this.props
		if (isCustomerView) {
			return { inputType: PageHeaderInputType.None }
		}
		const buttons = [
			{
				color: 'secondary',
				value: ShowTableContent,
				icon: Feather.Menu,
				defaultSelected: true,
				itemClass: 'event-content-btn'
			},
			{
				color: 'secondary',
				value: ShowRichContent,
				icon: Feather.Grid,
				itemClass: 'event-content-btn'
			}
		]
		return { inputType: PageHeaderInputType.ButtonGroup, buttons }
	}

	buildSelectItems = () => {
		const { currentClubCalendars } = this.props
		const { filter, viewingTab } = this.state

		// Build filter inputs.
		const selectInputs = selectInputsForCalendar(currentClubCalendars, filter, this.handleFilterChange)
		const allEventsInput = { label: this.myEventsFilter, value: this.myEventsFilter }
		selectInputs[0].inputs.push(allEventsInput)
		return !isNullOrUndefined(viewingTab) ? [] : selectInputs
	}

	buildButtonItem = () => {
		const { viewingTab } = this.state
		switch (viewingTab) {
			case EventResourceTabs.Types:
				return this.buildHeaderButton(CreateEventType, 'New Type')
			case EventResourceTabs.Calendars:
				return this.buildHeaderButton(CreateClubCalendar, 'New Calendar')
			case EventResourceTabs.Locations:
				return this.buildHeaderButton(CreateEventLocation, 'New Location')
			default:
				return this.buildHeaderButton(CreateEvent, 'New Event')
				break
		}
	}

	buildHeaderButton = (type: string, text: string): any => {
		const button = {
			value: type,
			type: ButtonType.DEFAULT,
			text: text,
			color: 'primary'
		}
		return { inputType: PageHeaderInputType.Button, button }
	}

	buildTableRowItems = () => {
		const { loggedInClub, calendarsByID, usersByID } = this.props

		const { eventsToShow, total } = this.getEventsToShow()
		const eventsForTable = eventsToShow.map((e) => {
			const calendarID = calendarIDForEvent(e)
			return {
				...e,
				calendar: calendarBadge(calendarsByID[`${calendarID}`]),
				rsvps: usersForEvent(e, usersByID),
				type: clubResourceTypeBadge(loggedInClub, 'events', e.type as any)
			}
		})

		return { eventsForTable, total }
	}

	buildTableContent = () => {
		const {
			viewingTab,
			showingEventTypeCreationModal,
			showingEventCalendarsCreationModal,
			showingEventLocationCreationModal
		} = this.state

		if (viewingTab === EventResourceTabs.Types) {
			return (
				<div className='event-table-container'>
					<ClubTypes
						resourceType={'events'}
						showingEditModal={showingEventTypeCreationModal}
						onEditModalClose={() => this.closeModal(ShowingEventTypeCreationModal)}
					/>
				</div>
			)
		} else if (viewingTab === EventResourceTabs.Calendars) {
			return (
				<ClubCalendarsComponent
					onTableRowClick={this.handleTableCalendarRowClicked}
					showingEditModal={showingEventCalendarsCreationModal}
					onEditModalClose={() => this.closeModal(ShowingEventCalendarsCreationModal)}
				/>
			)
		} else if (viewingTab === EventResourceTabs.Locations) {
			return (
				<EventLocationsComponent
					showingEditModal={showingEventLocationCreationModal}
					onEditModalClose={() => this.closeModal(ShowingEventLocationCreationModal)}
				/>
			)
		}

		return this.buildEventTable()
	}

	buildEventTable = () => {
		const { viewingPast, pageIndex, loading } = this.state

		const { eventsForTable, total } = this.buildTableRowItems()
		if (total === 0) {
			return <EmptyContent type={ContentType.Events} isInPast={viewingPast} />
		}
		return (
			<div className='event-table-container'>
				<TableComponent<Event | any>
					columns={EventTableColumns}
					rowItems={eventsForTable}
					showPaging={true}
					currentPage={pageIndex}
					pageHandler={this.handlePage}
					totalResults={total}
					pageSize={this.pageSize}
					actionHandler={this.handleTableHeaderAction}
					dropdownHandler={this.handleTableDropdownAction}
					onTableRowClick={this.handleTableRowClicked}
					isLoading={loading}
				/>
			</div>
		)
	}

	buildRichContent = () => {
		const { eventState } = this.props
		const { viewingPast, loading } = this.state

		const events = oc(eventState).events([]).filter((e: core.Event.Model) => e.displayInFeed)

		// Build the Rich Content, or show an EmptyContent component if we have no upcoming events
		const eventContent = (events.length === 0) ?
			<EmptyContent type={ContentType.Events} isInPast={viewingPast} /> :
			events.map(this.buildRichContentItem)

		return (
			<DimmedLoader
				component={eventContent}
				isLoading={loading}
			/>
		)
	}

	buildRichContentItem = (event: core.Event.Model, index: number) => {
		const { loggedInClub } = this.props
		const hasRSVP = this.userHasRSVP(event)
		const calendarID = oc(event).calendarIDs[0]()
		const calendar = this.props.calendarsByID[`${calendarID}`]
		return (
			<RichEventCard
				key={index}
				calendar={calendar}
				club={loggedInClub}
				event={event}
				showRSVPBadge={hasRSVP}
				onClick={() => this.handleViewEvent(`${event._id}`)}
			/>
		)
	}

	/**
	 * Returns an RSVP badge (pill) if the logged in user is RSVP'd to the event.
	 * @returns JSX.Element (pill) | null.
	 */
	userHasRSVP = (event: core.Event.Model): boolean => {
		if (this.state.filter === this.myEventsFilter) {
			return true
		}
		const user = this.props.loggedInUser
		for (const reservation of event.reservations) {
			const found = reservation.participants.find((p) => p.userID && `${p.userID}` === `${user._id}`)
			if (found) {
				return true
			}
		}
		return false
	}

	render() {
		const { isCustomerView } = this.props
		const { initialLoading, error, showingTable } = this.state

		const { loggedInClub } = this.props
		if (initialLoading) { return <DimmedLoader component={null} isLoading={true} /> }
		if (error) { return <ErrorComponent club={loggedInClub} isAdmin={this.props.userState.isAdmin} /> }

		// Determine which content we are showing
		const richContent = isCustomerView || !showingTable
		const content = (richContent) ? this.buildRichContent() : this.buildTableContent()
		const width = (isCustomerView || richContent) ? '8' : '12'
		return (
			<div className='row justify-content-center event-view-row'>
				<div className={`col-lg-${width} no-padding`}>
					{this.buildPageHeader()}
					{content}
					{this.buildDeleteConfirmationModal()}
				</div>
			</div>
		)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	getEventsToShow = (): { eventsToShow: core.Event.Model[], total: number } => {
		const { searchTerm, pageIndex } = this.state
		const eventResults = oc(this.searchEvents(searchTerm))([])
		const start = pageIndex * this.pageSize
		const end = start + this.pageSize
		const eventsToShow = eventResults.slice(start, end)
		return { eventsToShow, total: eventResults.length }
	}

	searchEvents = (searchTerm: string) => {
		const { eventState, eventsByID } = this.props

		if (!this.fuseEventSearch) { return }

		if (oc(searchTerm)('') === '') {
			return eventState.events
		}

		const fuseMatches: FuseItem[] = this.fuseEventSearch.search(searchTerm) as FuseItem[]
		return fuseMatches.map(({ _id }) => eventsByID[_id])
	}

	buildFuseEventSearch = () => {
		const { eventState } = this.props
		const eventsForFuse: FuseItem[] = oc(eventState).events([]).map((e) => ({ _id: `${e._id}`, searchString: e.name }))
		this.fuseEventSearch = buildFuseSearch(eventsForFuse)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	eventState: state.event,
	calendarState: state.calendar,
	loggedInUser: state.user.loggedInUser,
	loggedInClub: state.club.loggedInClub,
	isCustomerView: inCustomerViewSelector(state),
	calendarsByID: calendarsByIDSelector(state),
	usersByID: usersByIDSelector(state),
	eventsByID: eventsByIDSelector(state),
	userState: state.user,
	currentClubCalendars: clubCalendarsForCurrentClub(state)
})

const mapDispatchToProps = {
	...AlertActions,
	...CalendarActions,
	...EventActions,
	...MessageActions
}

export default connect(mapStateToProps, mapDispatchToProps)(EventComponent)
