// External Dependencies
import * as React from 'react'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies

// Core
import * as core from 'club-hub-core'

// Actions
import { AlertActions, RestaurantActions } from '../../../actions/index'

// State
import { RootReducerState } from '../../../reducers'

// Components
import DimmedLoader from '../../Shared/DimmedLoader'

// Form
import GenericFormComponent from '../../Shared/Formik/GenericForm'
import { MenuItemFormInputs, MenuItemFormState } from './form'

// Helpers
import * as Constants from '../../../constants'
import { setStateAsync } from '../../../helpers/promise'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	error: false,
	loading: true,
	restaurant: null as core.Restaurant.Model | null,
	menuID: null as string | null,
	menuItemToUpdate: null as core.Restaurant.MenuItem | null
}

type Props = RouteComponentProps & ConnectedState & ConnectedActions
type State = typeof initialState

class MenuItemForm extends React.Component<Props, State> {
	private isUpdateForm: boolean

	constructor(props: Props) {
		super(props)

		const routeAction = this.props.location.pathname.split('/').pop()
		this.isUpdateForm = routeAction === 'update'

		// Grab the IDs of the restaurant and menu off the URL.
		const restaurantID = (props.match.params as any).restaurant_id
		const menuID = (props.match.params as any).menu_id

		// Set the resources in state.
		const restaurants = oc(this).props.restaurantState.restaurants([])
		const restaurant = restaurants.find((r) => r._id === restaurantID)

		this.state = {
			...initialState,
			restaurant,
			menuID
		}
	}

	async componentDidMount() {
		if (this.isUpdateForm) {
			await this.setMenuFromQueryParams()
		}
		await setStateAsync(this, { loading: false })
	}

	/**
	 * Gets the Menu Item that is being updated from state using its ID that we get
	 * from the query parameters in the URL.
	 *
	 * The Menu Item will have its information pre-filled into the form.
	 */
	setMenuFromQueryParams = async () => {
		// Parse the query string of the URL into an object
		const parsedQuery = queryString.parse(this.props.location.search)
		const menuItemID = parsedQuery.menuItemID
		const menuItems = this.state.restaurant.menuItems

		// Get the Menu Item by its ID
		const menuItemToEdit = menuItems.find((menuItem) => menuItem._id === menuItemID)

		// Update the state
		await setStateAsync(this, { menuItemToUpdate: menuItemToEdit })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Returns the user to the Menu table when they cancel the form.
	 */
	handleCancel = () => {
		this.props.history.goBack()
	}

	/**
	 * Determines which handler to call (Create or Update) when the user submits the form.
	 */
	handleSave = async (form: MenuItemFormState): Promise<void> => {
		await ((this.isUpdateForm) ? this.handleUpdate(form) : this.handleCreate(form))

		const route = Constants.MENU_ITEMS_ROUTE
			.replace(':restaurant_id', `${this.state.restaurant._id}`)
			.replace(':menu_id', this.state.menuID)
		this.props.history.push(route)
	}

	/**
	 * Handle Update
	 */
	handleCreate = async (form: MenuItemFormState): Promise<void> => {
		await setStateAsync(this, { loading: true })
		const payload = this.buildMenuItemPayload(form)
		const restID = `${this.state.restaurant._id}`
		const [err] = await to(this.props.createMenuItem(restID, payload) as any)
		if (err) {
			this.props.fireFlashMessage('Failed to Create Item', Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false })
			return
		}
		this.props.fireFlashMessage('Successfully Created Item', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false })
	}

	/**
	 * Handle Update
	 */
	handleUpdate = async (form: MenuItemFormState): Promise<void> => {
		await setStateAsync(this, { loading: true })
		const payload = this.buildMenuItemPayload(form)
		payload._id = this.state.menuItemToUpdate._id
		const restID = `${this.state.restaurant._id}`
		const [err, updated] = await to(this.props.updateMenuItem(restID, payload) as any)
		if (err) {
			this.props.fireFlashMessage('Failed to Update Item', Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false })
			return
		}
		this.props.fireFlashMessage('Successfully Updated Item', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false })
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	/**
	 * Builds the payload, that will be sent to the server, based on the form.
	 * Must be sent as multi part form data so that the server can process any images.
	 */
	buildMenuItemPayload = (form: MenuItemFormState): core.Restaurant.MenuItem => {
		// Build the form data payload
		const menuItem: core.Restaurant.MenuItem = {
			name: form.name,
			description: form.description,
			available: oc(form).available.value() === 'true',
			price: form.price,
			sku: form.sku,
			menuID: oc(form).menu.value() as any,
			sectionID: oc(form).section.value() as any,
		}
		return menuItem
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildFormInputs = () => {
		// Build the menu inputs.
		const menuInputs = this.state.restaurant.menus.map((menu: core.Restaurant.Menu) => {
			return { label: menu.name, value: `${menu._id}` }
		})

		// Build display group inputs.
		const sectionInputs = this.state.restaurant.menuSections.map((section: core.Restaurant.MenuSection) => {
			return { label: section.name, value: `${section._id}` }
		})

		return MenuItemFormInputs(menuInputs, sectionInputs)
	}

	getMenu = (restaurant: core.Restaurant.Model, menuID: string): core.Restaurant.Menu => {
		const menus = oc(restaurant).menus([])
		return menus.find((m: core.Restaurant.Menu) => `${m._id}` === menuID)
	}

	buildForm = () => {
		const menuItemToUpdate = (this.isUpdateForm && this.state.menuItemToUpdate) ? this.state.menuItemToUpdate : undefined

		const formTitle = (this.isUpdateForm) ? 'Update Menu Item' : 'New Menu Item'
		const submitButtonName = (this.isUpdateForm) ? 'Update' : 'Save'
		const menuItemInputs = this.buildFormInputs()

		// If we are updating a menu item, pass in a map with menu item's values so the form can be prefilled.
		let formResource: any
		if (this.isUpdateForm) {
			// Build placeholder values.
			const menu = this.getMenu(this.state.restaurant, this.state.menuID)
			const menuDisplay = { label: menu.name, value: menu._id }
			const sectionDisplay = `${menuItemToUpdate.sectionID}`

			formResource = {
				...menuItemToUpdate,
				section: sectionDisplay,
				menu: menuDisplay,
				available: `${oc(menuItemToUpdate).available(false)}`
			}
		}

		return (
			<GenericFormComponent
				title={formTitle}
				inputs={menuItemInputs}
				enableReinitialize={false}
				formResource={formResource}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.handleCancel}
				submitButtonName={submitButtonName}
				submitButtonHandler={this.handleSave}
			/>
		)
	}

	render() {
		if (this.state.loading) { return <DimmedLoader component={null} isLoading={true} /> }
		return (
			<div className='menu-form-container row justify-content-center mx-auto'>
				<div className='col col-md-10 col-xl-8 d-flex justify-content-center'>
					{this.buildForm()}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	restaurantState: state.restaurant
})

const mapDispatchToProps = {
	...AlertActions,
	...RestaurantActions
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemForm)
