import * as core from 'club-hub-core'
import { oc } from 'ts-optchain'

import { InfoCardData } from '../../Shared/Cards/InfoCard'
import { buildEmailLink } from '../../../helpers/formatting'

export const AddressCard = (address: core.SubModels.Location.Model): InfoCardData => {
	return {
		title: 'Address',
		bodyClass: 'card-body',
		content: [[
			{
				title: 'Street',
				value: oc(address).address1(),
				size: 6,
			},
			{
				title: 'City',
				value: oc(address).city(),
				size: 6,
			},
		], [
			{
				title: 'State',
				value: oc(address).state(),
				size: 6,
			},
			{
				title: 'Zip',
				value: oc(address).zip(),
				size: 6,
			},
		]]
	}
}
