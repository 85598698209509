// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies

// State
import { RootReducerState } from '../../reducers/index'

// Actions
import { AlertActions, RestaurantActions } from '../../actions/index'

// Components
import PageHeaderComponent from '../Shared/PageHeader'
import { HeaderButton, ButtonType } from '../Shared/ButtonGroup'
import ErrorComponent from '../Shared/ErrorComponent'
import TableComponent from '../Shared/Table'
import ModalComponent from '../Shared/Modal'
import DimmedLoader from '../Shared/DimmedLoader'
import BackHeader from '../Shared/BackHeader'

// Tables
import { MenuTableColumns } from './table'

// Helpers
import * as Constants from '../../constants'
import { setStateAsync } from '../../helpers/promise'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	restaurantID: '',
	menus: null as core.Restaurant.Menu[] | null,
	menuItems: null as core.Restaurant.MenuItem[] | null,
	menuToDelete: null as string | null,
	error: false,
	initialLoading: false,
	loading: false,
	pageIndex: 0
}

type Props = RouteComponentProps & ConnectedState & ConnectedActions
type State = typeof initialState

class MenuComponent extends React.Component<Props, State> {
	private pageSize: number
	private isCustomerView: boolean

	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
		this.pageSize = 20
	}

	/**
	 * Gets the Menu that is being updated from state using its ID that we get
	 * from the query parameters in the URL.
	 *
	 * The Menu will have its information pre-filled into the form.
	 */
	setValuesFromQueryParams = async () => {
		// Grab the restaurantID off of the route.
		const restaurantID = (this.props.match.params as any).restaurant_id

		// Update the state
		await setStateAsync(this, { restaurantID: restaurantID })
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	componentDidMount() {
		this.setValuesFromQueryParams()
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Dropdown Actions
	// ----------------------------------------------------------------------------------

	/**
	 * Determines which action to take, based on which dropdown item
	 * the user selected in the table's dropdown menu
	 */
	handleTableDropdownAction = (e: any) => {
		const [action, value] = e
		switch (action) {
			case 'viewMenuItems':
				return this.handleViewMenu(value)
			case 'editMenu':
				return this.handleEditMenu(value)
			case 'deleteMenu':
				return this.handleDeleteMenu(value)
			default:
				break
		}
	}

	handleViewMenu = (menuID: string) => {
		const route = Constants.MENU_ITEMS_ROUTE
			.replace(':restaurant_id', this.state.restaurantID)
			.replace(':menu_id', menuID)

		this.props.history.push(route)
	}

	handleEditMenu = (menuID: string) => {
		const restaurantID = this.state.restaurantID
		const route = Constants.MENU_UPDATE_ROUTE.replace(':restaurant_id', restaurantID)

		// Query params
		const queryParams = { menuID }
		const location = {
			pathname: route,
			search: queryString.stringify(queryParams)
		}

		this.props.history.push(location)
	}

	handleDeleteMenu = async (menuID: string) => {
		return setStateAsync(this, { menuToDelete: menuID })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Modal Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Make the API call to delete the Menu, when the
	 * user confirms the deletion via the modal
	 */
	executeMenuDeletion = async () => {
		await setStateAsync(this, { loading: true })

		const menuID = this.state.menuToDelete
		const restID = this.state.restaurantID
		const [err, deleted] = await to(this.props.deleteMenu(restID, menuID) as any)
		if (err) {
			this.props.fireFlashMessage('Failed to Delete Menu.', Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, menuToDelete: null })
			return
		}
		this.props.fireFlashMessage('Menu Deleted!', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, menuToDelete: null })
	}

	/**
	 * Hide the deletion modal when the user cancels it
	 */
	closeDeleteMenuModal = async () => {
		return setStateAsync(this, { menuToDelete: null })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Handle table pagination
	 */
	handlePage = async (data: any) => {
		const { pageIndex } = this.state

		// Check if the new page is different that the current one
		if (data.selected !== pageIndex) {
			await setStateAsync(this, { pageIndex: data.selected })
		}
	}

	/**
	 * Handle a column header of the table being selected
	 */
	handleTableHeaderAction = (e: any) => {
		// TODO: Add this functionality
	}

	/**
	 * Handle a table row being clicked
	 */
	handleTableRowClicked = (menu: core.Restaurant.Menu) => {
		return this.handleViewMenu(menu._id as any)
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Header Event Handlers
	// ----------------------------------------------------------------------------------

	handleHeaderButtonAction = async (e: any) => {
		const action = e.target.value
		switch (action) {
			case 'createMenu':
				return this.handleCreateMenu()
			default:
				throw new Error(`Unrecognized table button action: ${action}`)
				break
		}
	}

	/**
	 * Navigate to the Menu creation form
	 */
	handleCreateMenu = () => {
		const route = Constants.MENU_CREATE_ROUTE
			.replace(':restaurant_id', this.state.restaurantID)

		this.props.history.push(route)
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildDeleteConfirmationModal = () => {
		if (!this.state.menuToDelete) { return null }
		return (
			<ModalComponent
				modalTitle={'Delete Menu'}
				primaryMessage={'Are you sure you want to delete this Menu?'}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.closeDeleteMenuModal}
				submitButtonName={'Confirm'}
				submitButtonHandler={this.executeMenuDeletion}
			/>
		)
	}

	buildPageHeader = (restaurant: core.Restaurant.Model) => {
		const title = `${restaurant.name} - Menus`
		return (
			<div className='page-header-with-back-btn'>
				<BackHeader
					to={Constants.RESTAURANT_ROUTE}
					backTitle={'Restaurants'}
				/>
				<PageHeaderComponent
					pageTitle={title}
					buttons={this.buildHeaderButtons()}
					buttonHandler={this.handleHeaderButtonAction}
				/>
			</div>
		)
	}

	buildHeaderButtons = (): HeaderButton[] => {
		const createMenu = 'createMenu'
		return [
			{
				action: createMenu,
				type: ButtonType.DEFAULT,
				text: 'New Menu',
				class: 'btn-primary',
			},
		]
	}

	buildTableRowItems = (restaurant: core.Restaurant.Model) => {
		const menuItems = restaurant.menuItems
		return restaurant.menus.map((m) => {
			const filteredItems = this.buildMenuItemsForMenu(menuItems, m)
			return { ...m, items: filteredItems }
		})
	}

	buildMenuItemsForMenu = (restaurantMenuItems: core.Restaurant.MenuItem[], menu: core.Restaurant.Menu): core.Restaurant.MenuItem[] => {
		return restaurantMenuItems.filter((menuItem: core.Restaurant.MenuItem) => {
			return `${menuItem.menuID}` === `${menu._id}`
		})
	}

	buildMenuTable = (restaurant: core.Restaurant.Model) => {
		const tableRows = this.buildTableRowItems(restaurant)
		const total = tableRows.length

		const startIdx = this.state.pageIndex * this.pageSize
		const endIdx = startIdx + this.pageSize
		const itemsForPage = tableRows.slice(startIdx, endIdx)

		return (
			<TableComponent<core.Restaurant.Menu>
				columns={MenuTableColumns}
				rowItems={itemsForPage}
				showPaging={true}
				currentPage={this.state.pageIndex}
				pageHandler={this.handlePage}
				totalResults={total}
				pageSize={this.pageSize}
				actionHandler={this.handleTableHeaderAction}
				dropdownHandler={this.handleTableDropdownAction}
				onTableRowClick={this.handleTableRowClicked}
				isLoading={this.state.loading}
			/>
		)
	}

	render() {
		// Find the restaurant by ID from the admin state.
		const restaurants = oc(this).props.restaurantState.restaurants([])
		const restaurant = restaurants.find((r) => `${r._id}` === this.state.restaurantID)
		if (!restaurant) { return <DimmedLoader component={null} isLoading={true} /> }

		const { loggedInClub } = this.props
		if (this.state.initialLoading) { return <DimmedLoader component={null} isLoading={true} /> }
		if (this.state.error) { return <ErrorComponent club={loggedInClub} isAdmin={this.props.userState.isAdmin} /> }

		return (
			<div className='menuComponent row justify-content-center'>
				<div className={'col-lg-12'}>
					{this.buildPageHeader(restaurant)}

					<div className='menu-content-container mx-auto'>
						{this.buildMenuTable(restaurant)}
					</div>

					{this.buildDeleteConfirmationModal()}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	loggedInClub: state.club.loggedInClub,
	restaurantState: state.restaurant,
	userState: state.user,
})

const mapDispatchToProps = {
	...AlertActions,
	...RestaurantActions
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuComponent)
