// External Dependencies
import * as core from 'club-hub-core'
import * as Yup from 'yup'

// Internal Dependencies
import { FormInput, FormInputType, InputSelectionItem } from '../../Shared/Form'
import { stateSelectionInputs } from '../../../helpers/form'

// Utils
import * as Constants from '../../../constants'

export interface UserFormState {
	firstName: string
	middleName: string
	lastName: string
	birthday: Date
	jobTitle?: string
	maritalStatus: InputSelectionItem
	image?: File[]
	email: string
	primaryAccountHolder: InputSelectionItem
	admin: InputSelectionItem
	phone: string
	address: string
	city: string
	state: InputSelectionItem
	zip: string
	joined: Date
	memberStatus: InputSelectionItem
	membershipType: InputSelectionItem
	number: string
	suffix: string
	publicProfile: InputSelectionItem
	publicContact: InputSelectionItem
	canBookReservations: InputSelectionItem
	agentAccessCode?: string
	stallNumbers?: string
	keySpots?: string
	groups?: InputSelectionItem[]
}

export const AdminUserFormInputs = (membershipTypes: InputSelectionItem[], groups: InputSelectionItem[]): FormInput[] => {
	const inputs: FormInput[] = [
		{
			title: 'First Name',
			property: 'firstName',
			type: FormInputType.TEXT,
			size: 4,
			placeholder: 'First...',
			defaultValue: '',
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Middle',
			property: 'middleName',
			type: FormInputType.TEXT,
			size: 2,
			placeholder: 'Middle...',
			defaultValue: ''
		},
		{
			title: 'Last Name',
			property: 'lastName',
			type: FormInputType.TEXT,
			size: 6,
			placeholder: 'Last...',
			defaultValue: '',
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Profile Image',
			property: 'image',
			type: FormInputType.FILE,
			size: 12,
			file_accept: '.png,.jpg,.jpeg',
			file_multipleFiles: false,
			defaultValue: null
		},
		{
			title: 'Personal Info',
			property: 'personalInfo',
			type: FormInputType.SECTION,
		},
		{
			title: 'Birthday',
			property: 'birthday',
			type: FormInputType.DATE_YEAR,
			size: 6,
			placeholder: 'Select Date of Birth...',
			defaultValue: null
		},
		{
			title: 'Profession',
			property: 'jobTitle',
			type: FormInputType.TEXT,
			size: 6,
			placeholder: 'Profession...',
			defaultValue: ''
		},
		{
			title: 'Contact Info',
			property: 'contactInfo',
			type: FormInputType.SECTION,
		},
		{
			title: 'Email Address',
			property: 'email',
			type: FormInputType.EMAIL,
			size: 6,
			placeholder: 'Email...',
			defaultValue: '',
			validation: Yup.string().when('primaryAccountHolder', {
				is: (val: InputSelectionItem) => val.value === 'true',
				then: Yup.string().required(Constants.REQUIRED_FIELD),
			})
		},
		{
			title: 'Phone Number',
			property: 'phone',
			type: FormInputType.TEXT,
			size: 6,
			placeholder: '(xxx) xxx-xxxx',
			defaultValue: '',
		},
		{
			title: 'Address',
			property: 'address',
			type: FormInputType.TEXT,
			size: 6,
			placeholder: 'Street Address...',
			defaultValue: ''
		},
		{
			title: 'City',
			property: 'city',
			type: FormInputType.TEXT,
			size: 6,
			placeholder: 'City...',
			defaultValue: ''
		},
		{
			title: 'State',
			property: 'state',
			type: FormInputType.SELECT,
			size: 6,
			placeholder: 'Select State...',
			selectItems: stateSelectionInputs,
			defaultValue: null
		},
		{
			title: 'Zip',
			property: 'zip',
			type: FormInputType.TEXT,
			size: 6,
			placeholder: 'Zip Code...',
			defaultValue: ''
		},
		{
			title: '',
			property: '',
			type: FormInputType.BLANK,
			size: 6,
		},
		{
			title: 'Club Info',
			property: 'clubInfo',
			type: FormInputType.SECTION,
		},
		{
			title: 'Membership',
			property: 'membershipType',
			type: FormInputType.GROUPED_SELECT,
			size: 6,
			placeholder: 'Select Membership...',
			selectItems: membershipTypes,
			defaultValue: null,
			validation: Yup.string().nullable().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Member Number',
			property: 'number',
			type: FormInputType.TEXT,
			size: 3,
			placeholder: 'Member Number...',
			defaultValue: '',
		},
		{
			title: 'Suffix',
			property: 'suffix',
			type: FormInputType.TEXT,
			size: 3,
			placeholder: 'Suffix...',
			defaultValue: '',
		},
		{
			title: 'Primary Account Holder',
			property: 'primaryAccountHolder',
			type: FormInputType.SELECT,
			size: 6,
			placeholder: 'Primary Account Holder...',
			selectItems: [{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }],
			defaultValue: { label: 'Yes', value: 'true' },
		},
		{
			title: 'Status',
			property: 'memberStatus',
			type: FormInputType.SELECT,
			size: 6,
			placeholder: 'Select Status...',
			selectItems: [
				{ label: 'Active', value: core.User.MemberStatus.Active },
				{ label: 'Prospect', value: core.User.MemberStatus.Prospect },
				{ label: 'Lapsed', value: core.User.MemberStatus.Lapsed },
				{ label: 'Guest', value: core.User.MemberStatus.Guest },
			],
			defaultValue: null,
		},
		{
			title: 'Date Joined',
			property: 'joined',
			type: FormInputType.DATE,
			size: 6,
			placeholder: 'Select Date Joined...',
			defaultValue: null
		},
		{
			title: 'Groups',
			property: 'groups',
			type: FormInputType.MULTI_SELECT,
			size: 12,
			selectItems: groups,
			defaultValue: null
		},
		{
			title: 'Privacy Settings',
			property: 'privacySettings',
			type: FormInputType.SECTION,
		},
		{
			title: 'Profile',
			property: 'publicProfile',
			type: FormInputType.SELECT,
			size: 6,
			selectItems: [
				{ label: 'Public', value: 'true' },
				{ label: 'Private', value: 'false' }
			],
			defaultValue: { label: 'Public', value: 'true' }
		},
		{
			title: 'Contact Info',
			property: 'publicContact',
			type: FormInputType.SELECT,
			size: 6,
			selectItems: [
				{ label: 'Public', value: 'true' },
				{ label: 'Private', value: 'false' }
			],
			defaultValue: { label: 'Public', value: 'true' }
		},
		{
			title: 'Permission Settings',
			property: 'privacySettings',
			type: FormInputType.SECTION,
		},
		{
			title: 'Admin',
			property: 'admin',
			type: FormInputType.SELECT,
			size: 6,
			placeholder: 'Is Admin...',
			selectItems: [{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }],
			defaultValue: { label: 'No', value: 'false' },
		},
		{
			title: 'Can Book Reservations',
			property: 'canBookReservations',
			type: FormInputType.SELECT,
			size: 6,
			selectItems: [
				{ label: 'Yes', value: 'true' },
				{ label: 'No', value: 'false' }
			],
			defaultValue: { label: 'No', value: 'false' }
		},
	]
	return inputs
}

export const MemberUserFormInputs = (club: core.Club.Model, privateProfile: boolean): FormInput[] => {
	const inputs: FormInput[] = [
		{
			title: 'First Name',
			property: 'firstName',
			type: FormInputType.TEXT,
			size: 4,
			placeholder: 'First',
			defaultValue: '',
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Middle',
			property: 'middleName',
			type: FormInputType.TEXT,
			size: 2,
			placeholder: 'Middle',
			defaultValue: '',
		},
		{
			title: 'Last Name',
			property: 'lastName',
			type: FormInputType.TEXT,
			size: 6,
			placeholder: 'Last',
			defaultValue: '',
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Profile Image',
			property: 'image',
			type: FormInputType.FILE,
			size: 12,
			file_accept: '.png,.jpg,.jpeg',
			file_multipleFiles: false,
			defaultValue: null
		},
		{
			title: 'Personal Info',
			property: 'personalInfo',
			type: FormInputType.SECTION,
		},
		{
			title: 'Birthday',
			property: 'birthday',
			type: FormInputType.DATE_YEAR,
			size: 6,
			placeholder: 'Select Date of Birth...',
			defaultValue: null,
			validation: Yup.date().nullable().default(null).required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Profession',
			property: 'jobTitle',
			type: FormInputType.TEXT,
			size: 6,
			placeholder: 'Profession',
			defaultValue: '',
		},
		{
			title: 'Contact Info',
			property: 'contactInfo',
			type: FormInputType.SECTION,
		},
		{
			title: 'Email Address',
			property: 'email',
			type: FormInputType.EMAIL,
			size: 6,
			placeholder: 'Email...',
			defaultValue: '',
			validation: Yup.string().when('primaryAccountHolder', {
				is: (val: InputSelectionItem) => val.value === 'true',
				then: Yup.string().required(Constants.REQUIRED_FIELD),
			})
		},
		{
			title: 'Phone Number',
			property: 'phone',
			type: FormInputType.TEXT,
			size: 6,
			placeholder: '(xxx) xxx-xxxx',
			defaultValue: '',
		},
		{
			title: 'Address',
			property: 'address',
			type: FormInputType.TEXT,
			size: 6,
			placeholder: 'Street Address',
			defaultValue: '',
		},
		{
			title: 'City',
			property: 'city',
			type: FormInputType.TEXT,
			size: 6,
			placeholder: 'City',
			defaultValue: '',
		},
		{
			title: 'State',
			property: 'state',
			type: FormInputType.SELECT,
			size: 3,
			placeholder: 'Select State...',
			selectItems: stateSelectionInputs,
			defaultValue: null,
		},
		{
			title: 'Zip',
			property: 'zip',
			type: FormInputType.TEXT,
			size: 3,
			placeholder: 'Zip Code',
			defaultValue: '',
		},
		{
			title: 'Privacy Settings',
			property: 'privacySettings',
			type: FormInputType.SECTION,
		},
		{
			title: 'Profile',
			property: 'publicProfile',
			type: FormInputType.SELECT,
			size: 6,
			selectItems: [
				{ label: 'Public', value: 'true' },
				{ label: 'Private', value: 'false' }
			],
			defaultValue: null,
		},
		{
			title: 'Contact Info',
			property: 'publicContact',
			type: FormInputType.SELECT,
			size: 6,
			selectItems: [
				{ label: 'Public', value: 'true' },
				{ label: 'Private', value: 'false' }
			],
			defaultValue: null,
		},
	]

	/**
	 * Add the hide and show options to forms
	 * for 'Car' type clubs.
	 */
	if (club.type.toUpperCase() === core.Club.Type.Car.toUpperCase()) {
		inputs.push({
			title: 'Display Vehicles',
			property: 'displaySettings',
			type: FormInputType.SELECT,
			size: 6,
			selectItems: [
				{ label: 'Public', value: 'true' },
				{ label: 'Private', value: 'false' }
			],
			defaultValue: null,
		})
	}

	return inputs
}
