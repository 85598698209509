// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import * as Feather from 'react-feather'
import * as core from 'club-hub-core'
import { connect } from 'react-redux'
import { Link, RouteComponentProps } from 'react-router-dom'
import { oc } from 'ts-optchain'
import classNames from 'classnames'
import to from 'await-to-js'
import { flatten } from 'underscore'

// Internal Dependencies
import * as Constants from '../../constants'

// Components
import AvatarComponent from '../Shared/Avatar'
import NavbarDropdownItem from './NavbarDropdownItem'

// Reducer Interface Imports
import { RootReducerState } from '../../reducers'
import { userIsAdminSelector, inCustomerViewSelector } from '../../reducers/user'
import { ClubNavigationSection, ClubNavigationItem } from '../../reducers/club'

// Actions
import { UserActions } from '../../actions/index'

// Helpers
import { fullName } from '../../helpers/user'
import { GET } from '../../helpers/api'
import { setStateAsync } from '../../helpers/promise'
import { getClubDomain } from '../../helpers/url'
import { updateGlobalStyle } from '../../helpers/style'
import { imageForResource, ImageSize } from '../../helpers/image'

// Images
// tslint:disable-next-line
const clubhubLogo = require('../../assets/images/clubhublogo.png')

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	loading: false,
	toggleNav: false,
	clubs: null as core.Club.Model[],
	clubDomain: null as string | null,
}

// Component Props Type
type Props = RouteComponentProps & ConnectedState & ConnectedActions
type State = typeof initialState

class NavBarComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		if (this.props.userState.loggedInUser) { return }

		// If displaying the navbar to an unauthenticated user, fetch clubs to get the logo.
		const clubDomain = getClubDomain()
		const route = `/clubs`
		const [err, res] = await to(GET(route, {}))
		if (err) {
			await setStateAsync(this, { loading: false })
			return
		}

		await setStateAsync(this, { clubs: res, clubDomain: clubDomain, loading: false })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	toggleNav = () => {
		this.setState({ toggleNav: !this.state.toggleNav })
	}

	buildLogo = () => {
		const { loggedInClub } = this.props

		if (loggedInClub && loggedInClub.image) {
			return <img src={imageForResource(loggedInClub.image, loggedInClub, ImageSize.Medium)} />
		}

		// Find the Club by domain, and get its logo
		const clubs = oc(this.state.clubs)([])
		const clubByDomain = clubs.find((club: core.Club.Model) => club.domain === this.state.clubDomain)
		if (clubByDomain) {
			updateGlobalStyle(clubByDomain)
			return (<img src={imageForResource(clubByDomain.image, clubByDomain, ImageSize.Medium)} />)
		}

		return null
	}

	// ----------------------------------------------------------------------------------
	// Calls To Actions
	// ----------------------------------------------------------------------------------

	setupCalendar = () => {
		return this.props.history.push(Constants.CALENDAR_ROUTE)
	}

	myProfile = () => {
		return this.props.history.push(Constants.USER_PROFILE_ROUTE)
	}

	myStatements = () => {
		const { loggedInClub } = this.props

		if (loggedInClub.name === core.Constants.Clubs.WING_POINT) {
			window.location.href = 'https://www.wingpointgolf.com/stmt_statements/stmt_default.asp'
		}
	}

	clubSettings = () => {
		return this.props.history.push(Constants.SETTINGS_ROUTE)
	}

	toggleCustomerView = async () => {
		await this.props.toggleCustomerView()
	}

	handleLogout = async () => {
		const [err] = await to(this.props.signOut() as any)
		this.props.history.push(Constants.LOGIN_ROUTE)
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildNavigationItems = () => {
		const { loggedInClub, inCustomerView } = this.props
		if (!loggedInClub) { return null }

		const classes: { [key: string]: boolean } = { 'd-none': true }
		if (inCustomerView) {
			classes['d-lg-flex'] = true
		}

		const classNamez = classNames(classes)
		const navigationConfig = loggedInClub.navigationConfig
		const navigationSections = (this.props.inCustomerView) ? navigationConfig.customer : navigationConfig.admin
		const items = flatten(navigationSections.map((section: ClubNavigationSection, index: number) => {
			if (section.title === 'CLUB INFO') {
				return this.buildClubInfoNavigationItems(section)
			}
			return this.buildNavigationItem(section)
		}), true)
		return (
			<div className={classNamez}>
				{items}
			</div>
		)
	}

	buildClubInfoNavigationItems = (section: ClubNavigationSection) => {
		const clubSections = [...oc(this).props.sectionState.sections([])]
		const sortedClubSections = clubSections.sort((a, b) => oc(a).orderingIndex(0) - oc(b).orderingIndex(0)).filter((s) => s.pages.length > 0)
		const filteredClubSections = sortedClubSections.filter((s: core.Section.Model) => (!s.public && s.status === core.IShared.PublicationStatus.Published))

		const items = filteredClubSections.map((item: core.Section.Model, idx: number) => {
			const icon = (item.icon) ? this.buildIconBox(item.icon) : null
			const path = Constants.VIEW_SECTION_ROUTE.replace(':section_id', `${item._id}`)
			return (
				<RS.DropdownItem key={`nav-item-${item.name}-${idx}`} >
					<Link key={`nav-item-${item.name}-${idx}`}className={'d-flex align-items-center'} to={path} data-cy={item.name}>
						{icon}
						{item.name}
					</Link>
				</RS.DropdownItem>
			)
		})
		return this.buildNavDropdown(section.title, items)
	}

	buildNavigationItem = (section: ClubNavigationSection) => {
		const items = section.items.map((item: ClubNavigationItem) => {
			const icon = (item.featherIcon) ? this.buildFeatherIconBox(item.featherIcon) : this.buildIconBox(item.fontAwesomeIcon)
			return (
				<RS.DropdownItem key={`nav-item-${item.title}`} >
					<Link key={`nav-item-${item.title}`} className={'d-flex align-items-center'} to={item.path} data-cy={item.title}>
						{icon}
						{item.title}
					</Link>
				</RS.DropdownItem>
			)
		})
		return this.buildNavDropdown(section.title, items)
	}

	buildNavDropdown = (title: string, items: any[]) => {
		const lowercase = this.toTitleCase(title)
		const formatted = lowercase.charAt(0).toUpperCase() + lowercase.slice(1)
		return (
			<RS.UncontrolledDropdown nav={true} inNavbar={true}>
				<RS.DropdownToggle key={`toggle-${title}`} nav={true} caret={false}>
					{formatted}
				</RS.DropdownToggle>
				<RS.DropdownMenu key={`drop-${title}`}className={'dropdown-menu-arrow'}>
					{items}
				</RS.DropdownMenu>
			</RS.UncontrolledDropdown>
		)
	}

	buildFeatherIconBox = (featherIcon: string) => {
		const FeatherIcon = (Feather as any)[featherIcon]
		return (
			<div className='icon-box mr-3 d-flex justify-content-center'>
				<FeatherIcon />
			</div>
		)
	}

	buildIconBox = (iconIdentifier: string) => {
		return (
			<div className='icon-box mr-3 d-flex justify-content-center'>
				<i className={`fas fa-${iconIdentifier}`} />
			</div>
		)
	}

	buildDropdown = () => {
		const loggedInUser = this.props.userState.loggedInUser
		if (!loggedInUser) { return null }

		const { loggedInClub } = this.props
		return (
			<div className='dropdown' data-cy='TopNav__dropdown-btn'>
				{/** Avatar */}
				{this.buildNavAvatar()}

				{/** Dropdown Items */}
				<div className='dropdown-menu dropdown-menu-right dropdown-menu-arrow'>
					{this.props.userIsAdmin && (
						<NavbarDropdownItem
							text={`View as: ${(this.props.inCustomerView) ? 'Admin' : 'Member'}`}
							icon={Feather.UserCheck}
							onClick={this.toggleCustomerView}
						/>
					)}

					<NavbarDropdownItem text={'My Profile'} icon={Feather.User} onClick={this.myProfile} data-cy='TopNav__profile-btn' />
					{loggedInClub.name === core.Constants.Clubs.WING_POINT && (
						<NavbarDropdownItem text={'My Statement'} icon={Feather.DollarSign} onClick={this.myStatements} />
					)}

					<NavbarDropdownItem text={'Settings'} icon={Feather.Settings} onClick={this.clubSettings} data-cy='TopNav__settings-btn' />
					<RS.DropdownItem divider={true} />
					<NavbarDropdownItem text={'Logout'} icon={Feather.LogOut} onClick={this.handleLogout} />
				</div>
			</div>
		)
	}

	buildNavAvatar = () => {
		const loggedInUser = this.props.userState.loggedInUser
		const userType = (this.props.userIsAdmin) ?
			((this.props.inCustomerView) ? 'Viewing as Member' : 'Administrator') :
			'Member'

		return (
			<a className='nav-link pr-0 leading-none' data-toggle='dropdown' aria-expanded='false'>
				<AvatarComponent user={loggedInUser} />
				<span className='ml-2 d-none d-lg-block'>
					<span className='text-default'>{fullName(loggedInUser)}</span>
					<small className='text-muted d-block'>
						{userType}
					</small>
				</span>
			</a>
		)
	}

	buildNavbarCollapse = () => {
		const { userState } = this.props

		const loggedInUser = userState.loggedInUser
		if (!loggedInUser) { return null }

		return (
			<a
				className='header-toggler d-lg-none'
				data-toggle='collapse'
				data-target='#headerMenuCollapse'
				href='#headerMenuCollapse'
			>
				<span className='header-toggler-icon' />
			</a>
		)
	}

	render() {
		return (
			<div className='navbar fixed-top navbar-light flex-md-nowrap p-0'>
				<div className='header'>
					<div className='d-flex align-items-center justify-content-between nav-bar-container'>
						{this.buildNavbarCollapse()}
						<div className={'left-nav-items d-flex  align-items-center'}>
							<Link to={'/'} className='header-brand'>
								{this.buildLogo()}
							</Link>
							{this.buildNavigationItems()}
						</div>
						{this.buildDropdown()}
					</div>
				</div>
			</div>
		)
	}

	toTitleCase = (text: string) => {
		const split = text.toLowerCase().split(' ')
		for (let i = 0; i < split.length; i++) {
			split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1)
		}
		return split.join(' ')
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	userState: state.user,
	sectionState: state.section,
	loggedInClub: state.club.loggedInClub,
	userIsAdmin: userIsAdminSelector(state),
	inCustomerView: inCustomerViewSelector(state),
})

const mapDispatchToProps = {
	...UserActions,
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBarComponent as any)
