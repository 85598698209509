import * as React from 'react'
import * as RS from 'reactstrap'
import * as core from 'club-hub-core'
import { oc } from 'ts-optchain'

// Shared
import { InfoCardData } from '../../Shared/Cards/InfoCard'
import { capitalizeString } from '../../../helpers/formatting'

// Helpers
import { clubResourceTypeBadge } from '../../../helpers/badge'
import { getMemberNumber } from '../../../helpers/user'

export const MembershipCard = (user: core.User.Model, club: core.Club.Model): InfoCardData => {
	const membershipTypeID = (user.membershipType) ? `${user.membershipType}` : ''
	const badge = clubResourceTypeBadge(club, 'members', membershipTypeID)
	const memberNumber = getMemberNumber(user, club)
	const membershipStatus = capitalizeString(user.memberStatus)
	const membershipCardData = {
		title: 'Membership',
		bodyClass: 'card-body',
		content: [[
			{
				title: 'Type',
				value: (
					<RS.Badge style={{ backgroundColor: badge.color }}>
						{badge.title}
					</RS.Badge>
				),
				size: 6
			},
			{
				title: 'Joined',
				value: (user.joined) ? new Date(user.joined).toLocaleDateString('en-US') : '',
				size: 6
			}
		],
		[
			{
				title: 'Status',
				value: membershipStatus,
				size: 6
			},
			{
				title: 'Number',
				value: memberNumber,
				size: 6
			}
		]]
	}

	// Add the Agent Access Code data if we have a car club and the user is an admin.
	if (club.type.toUpperCase() === core.Club.Type.Car.toUpperCase()) {
		membershipCardData.content.push([
			{
				title: 'Agent Access Code',
				value: oc(user).meta.car.agentAccessCode('--'),
				size: 6
			},
			{
				title: 'Stall Numbers',
				value: oc(user).meta.car.stallNumbers('--'),
				size: 6
			}
		])
	}

	return membershipCardData
}
