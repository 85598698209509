// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import * as core from 'club-hub-core'
import * as queryString from 'query-string'
import to from 'await-to-js'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'
import { View } from 'react-big-calendar'

// State
import { RootReducerState } from '../../reducers'
import { inCustomerViewSelector } from '../../reducers/user'
import { calendarsByIDSelector, calendarGroupsByIDSelector } from '../../reducers/calendar'

// Actions
import { UserActions, CalendarActions, EventActions, AlertActions } from '../../actions/index'

// UI
import CHCalendar, { BigCalendarEvent, BigCalendarSlotInfo } from '../Shared/CHCalendar'
import BlockModal from '../Modals/BlockModal'

// Helpers
import * as Constants from '../../constants'
import * as eventHelper from '../../helpers/event'
import { setStateAsync } from '../../helpers/promise'
import { getMostSpecificSetting } from '../../helpers/reservation'

// Connected Props
type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	showingModal: false,
	showingBlockModal: false,
	selectedDate: null as Date,
	selectedEvent: null as core.Event.Model,
	loading: false,
	events: [] as core.Event.Model[]
}

type Props = RouteComponentProps & ConnectedState & ConnectedActions
type State = typeof initialState

/**
 * Calendar Component displays the Rich Calendar screen.
 */
class CalendarComponent extends React.Component<Props, State> {
	/**
	 * The current calendar GroupID.
	 */
	private groupID: string
	private calendarGroup: core.Calendar.Group

	constructor(props: Props) {
		super(props)

		// Check if we have a calendar group query param.
		// Fall back to the Social Calendar if no query param found.
		const locationQuery = queryString.parse(this.props.location.search)
		if (!locationQuery.groupID) {
			// Get the ID for the Social Calendar -- set it on the location query
			const socialCalendarID = oc(this.calendarGroupForType(core.Calendar.GroupType.Club))._id()
			locationQuery.groupID = socialCalendarID

			// Update the current path
			const queryParams = queryString.stringify(locationQuery)
			const updatedPath = `${this.props.location.pathname}?${queryParams}`
			this.props.history.replace(updatedPath)
		}

		this.groupID = locationQuery.groupID
		this.calendarGroup = this.calendarGroupForID(this.groupID)

		// Check if we have a calendar query param
		const calendarID = locationQuery.calendarID
		const currentCalendar = this.getCurrentCalendar(calendarID, this.groupID, props)
		this.props.setCurrentCalendar(currentCalendar, this.calendarGroup)
		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Life-cycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		await this.refreshEvents()
	}

	async componentDidReceiveProps(nextProps: any) {
		// Load the Events for all Calendars in the Group
		const calendar = nextProps.calendarState.currentCalendar
		await this.setEventsForComponent(calendar)
	}

	getCurrentCalendar = (calendarID: string, groupID: string, props: Props) => {
		return (calendarID) ? oc(props).calendarState.calendars([]).find((cal) => {
			return `${cal._id}` === calendarID && `${cal.groupID}` === groupID
		}) : null
	}

	// ----------------------------------------------------------------------------------
	// Event Fetching
	// ----------------------------------------------------------------------------------

	refreshEvents = async () => {
		await this.fetchEventsForGroup(this.groupID)
		const calendar = this.props.calendarState.currentCalendar
		await this.setEventsForComponent(calendar)
	}

	fetchEventsForGroup = async (groupID: string) => {
		let eventQueryParams: any = { groupID: groupID, limit: 0 }
		const group = this.props.calendarGroupsById[`${groupID}`]

		// If we are not looking at a club cal, only fetch block events.
		if (group.type === core.Calendar.GroupType.Golf) {
			eventQueryParams = { groupID: groupID, limit: 0, blockCalendar: true }
		}
		const [err] = await to(this.props.fetchEvents(eventQueryParams) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to fetch events. ${err.message}`, Constants.FlashType.DANGER)
			return
		}
	}

	// ----------------------------------------------------------------------------------
	// Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Handles the selection of a calendar event.
	 */
	handleEventSelected = (event: BigCalendarEvent, e: any) => {
		switch (this.calendarGroup.type) {
			case core.Calendar.GroupType.Golf:
				this.displayBlockModal(event.resource.start, event.resource)
				break
			case core.Calendar.GroupType.Dining:
			case core.Calendar.GroupType.Club:
			default:
				this.displayExistingEventForm(event)
		}
	}

	handleViewSelected = (v: View) => {
		const locationQuery = queryString.parse(this.props.location.search)
		const queryParams = queryString.stringify({ ...locationQuery, view: v })
		const updatedPath = `${this.props.location.pathname}?${queryParams}`
		this.props.history.replace(updatedPath)
	}

	/**
	 * Handles clicks on empty calendar slots.
	 */
	handleSlotSelected = (event: BigCalendarSlotInfo): any => {
		const { isCustomerView } = this.props
		if (isCustomerView) { return null }

		switch (this.calendarGroup.type) {
			case core.Calendar.GroupType.Golf:
				this.displayBlockModal(new Date(event.start))
				break
			case core.Calendar.GroupType.Dining:
			case core.Calendar.GroupType.Club:
			default:
				this.displayNewEventForm(event)
		}
	}

	// ----------------------------------------------------------------------------------
	// Event Form Display
	// ----------------------------------------------------------------------------------

	displayNewEventForm = (event: any) => {
		const queryParams = {
			calendarGroupID: this.groupID,
			calendarID: oc(this).props.calendarState.currentCalendar._id(),
			start: new Date(event.start).toISOString(),
			end: new Date(event.end).toISOString()
		}
		const search = queryString.stringify(queryParams)
		const pathname = Constants.CREATE_EVENT_ROUTE
		const location = { pathname, search }
		this.props.history.push(location)
	}

	displayExistingEventForm = (event: BigCalendarEvent) => {
		const queryParams = { eventID: event.resource._id }
		const search = queryString.stringify(queryParams)
		const pathname = Constants.VIEW_EVENT_ROUTE
		const location = { pathname, search }
		this.props.history.push(location)
	}

	// ----------------------------------------------------------------------------------
	// Block Modal Handlers
	// ----------------------------------------------------------------------------------

	displayBlockModal = (date: Date, event?: core.Event.Model) => {
		this.setState({ showingBlockModal: true, selectedDate: date, selectedEvent: event })
	}

	handleBlockModalClose = () => {
		this.setState({ showingBlockModal: false, selectedDate: null, selectedEvent: null })
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	/**
	 * Build styles for calendars.
	 */
	buildStyles = (calendars: core.Calendar.Model[]): any => {
		const styles: any = {}
		calendars.map((calendar: core.Calendar.Model) => {
			const style = { primary: calendar.color, secondary: calendar.color }
			styles[calendar._id as any] = style
		})
		return styles
	}

	buildBlockModal = () => {
		if (!this.state.showingBlockModal) { return null }
		if (this.props.isCustomerView) { return null }

		const eventTime = this.state.selectedEvent ? this.state.selectedEvent.start : this.state.selectedDate
		const setting = this.getReservationSetting(eventTime)
		return (
			<BlockModal
				start={eventTime}
				setting={setting}
				event={this.state.selectedEvent}
				handleToggle={this.handleBlockModalClose}
				refreshEvents={this.refreshEvents}
			/>
		)
	}

	public render() {
		const locationQuery = queryString.parse(this.props.location.search)
		const calendars = this.calendarsForGroup(this.calendarGroup)
		const styles = this.buildStyles(calendars)
		return (
			<RS.Row className='calendar-component-container'>
				<RS.Col className='cal-container'>
					<CHCalendar
						handleViewSelected={this.handleViewSelected}
						handleEventSelected={this.handleEventSelected}
						handleSlotSelected={this.handleSlotSelected}
						view={locationQuery.view}
						events={this.state.events}
						styles={styles}
						isCustomerView={this.props.isCustomerView}
					/>
					{this.buildBlockModal()}
				</RS.Col>
			</RS.Row>
		)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	getReservationSetting = (date: Date) => {
		const calendar = this.props.calendarState.currentCalendar
		const reservationSettings = oc(calendar).reservationSettings([])
		return getMostSpecificSetting(reservationSettings, date)
	}

	/**
	 * Sets the events for the component based on calendarID.
	 */
	setEventsForComponent = async (calendar: core.Calendar.Model) => {
		// Determine if we are on a Calendar where we need to only return blocking Events
		const events = this.filterEventsForCalendar(calendar)
		await setStateAsync(this, { events: events })
	}

	/**
	 * Gets a Calendar Group by an ID
	 */
	calendarGroupForID = (calendarGroupID: string) => {
		const groups = this.props.calendarState.groups
		if (!groups) {
			return null
		}
		return groups.find((cg) => `${cg._id}` === calendarGroupID)
	}

	/**
	 * Filters calendars for a specific calendar group.
	 */
	calendarsForGroup = (group: core.Calendar.Group) => {
		if (!group) {
			return []
		}

		const calendars = this.props.calendarState.calendars
		return calendars.filter((c) => c.groupID === group._id)
	}

	/**
	 * Filters a calendar group for a specific type.
	 */
	calendarGroupForType = (type: core.Calendar.GroupType) => {
		const groups = this.props.calendarState.groups
		if (!groups) {
			return null
		}
		const filtered = groups.filter((cg) => cg.type === type)
		return filtered[0]
	}

	/**
	 * Filters events for a specific Calendar.
	 * If no calendar is supplied, events will only be returned if they match the calendar group.
	 */
	filterEventsForCalendar = (calendar: core.Calendar.Model): core.Event.Model[] => {
		const events = oc(this).props.eventState.events([])
		return events.filter((e) => {
			const calendarID = eventHelper.calendarIDForEvent(e, calendar)
			const matchedEvent = (!isNullOrUndefined(calendar)) ?
				`${calendarID}` === `${calendar._id}` :
				eventHelper.eventBelongsToGroup(e, this.props.calendarsByID, this.groupID)
			return matchedEvent
		})
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	userState: state.user,
	eventState: state.event,
	calendarState: state.calendar,
	calendarsByID: calendarsByIDSelector(state),
	calendarGroupsById: calendarGroupsByIDSelector(state),
	isCustomerView: inCustomerViewSelector(state),
})

const mapDispatchToProps = {
	...UserActions,
	...EventActions,
	...CalendarActions,
	...AlertActions,
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarComponent)
