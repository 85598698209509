// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import * as core from 'club-hub-core'
import classNames from 'classnames'
import { FormikActions, FormikProps } from 'formik'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { debounce } from 'underscore'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies

// Actions
import { AlertActions, ClubActions, UserActions } from '../../../actions'

// State
import { RootReducerState } from '../../../reducers'

// Components
import DimmedLoader from '../../Shared/DimmedLoader'

// Form
import { FormInput } from '../../Shared/Form'
import { SecuritySettingsFormInputs, SecuritySettingsFormState } from './form'
import { FormikComponent, BuildWrappedForm } from '../../Shared/Formik'
import FormModal from '../../Shared/Formik/FormModal'

// Helpers
import * as Constants from '../../../constants'
import { setStateAsync } from '../../../helpers/promise'

interface ComponentProps {
	club: core.Club.Model
	modal?: boolean
	toggle?: () => void
}

const initialState = {
	error: false,
	loading: true,
	isSubmitting: false,
}

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

type Props = ConnectedState & ConnectedActions & ComponentProps
type State = typeof initialState

export class BrandSettingsForm extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
	}

	async componentDidMount() {
		await setStateAsync(this, { loading: false })
	}

	// ----------------------------------------------------------------------------------
	// Network
	// ----------------------------------------------------------------------------------

	private handleChangePassword = async (form: SecuritySettingsFormState, formikActions: FormikActions<SecuritySettingsFormState>) => {
		const [err] = await to(this.props.changePassword(form.oldPassword, form.newPassword) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to change password.`, Constants.FlashType.DANGER)
			return
		}

		this.props.fireFlashMessage(`Successfully changed password.`, Constants.FlashType.SUCCESS)
		formikActions.setSubmitting(false)
		formikActions.resetForm()
		if (this.props.toggle) { this.props.toggle() }
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildForm = () => {
		const formResource = {}
		const formInputs = SecuritySettingsFormInputs()

		const form = (
			<FormikComponent
				inputs={formInputs}
				enableReinitialize={false}
				formResource={formResource}
				onSubmit={this.handleChangePassword}
				render={(formikProps: FormikProps<SecuritySettingsFormState>) => (
					this.buildFormBody(formInputs, formikProps)
				)}
			/>
		)
		return (
			<RS.Row className='clubSettingForm__container'>
				<RS.Col>
					{form}
				</RS.Col>
			</RS.Row>
		)
	}

	buildModal = () => {
		const formInputs = SecuritySettingsFormInputs()

		return (
			<FormModal
				modalTitle={'Update Password '}
				formSpec={formInputs}
				formResource={{}}
				cancelButtonHandler={this.props.toggle}
				submitButtonHandler={this.handleChangePassword}
			/>
		)
	}

	buildFormBody = (inputs: FormInput[], formikProps: FormikProps<SecuritySettingsFormState>) => {
		const saveButtonClass = classNames({
			'btn': true,
			'btn-primary': true,
			'clubSettingForm__save-btn': true,
		})
		return (
			<>
				{BuildWrappedForm({ inputs }, formikProps)}
				<button className={saveButtonClass} onClick={formikProps.submitForm}>Save</button>
			</>
		)
	}

	render() {
		const { loading } = this.state
		if (loading) { return <DimmedLoader component={null} isLoading={true} /> }

		return this.props.modal ? this.buildModal() : this.buildForm()
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	userState: state.user,
})

const mapDispatchToProps = {
	...AlertActions,
	...ClubActions,
	...UserActions
}

const enhance = compose<React.ComponentType<ComponentProps>>(
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(BrandSettingsForm)
