// External Dependencies
import * as core from 'club-hub-core'
import { Dispatch, Action } from 'redux'
import to from 'await-to-js'

// Internal Dependencies

// API Helpers
import { POST, PUT, DELETE, GET } from '../helpers/api'

// Actions
export enum TypeKeys {
	FETCHED_NOTIF_PREFERENCES = 'FETCHED_NOTIF_PREFERENCES',
	CREATED_NOTIF_PREFERENCES = 'CREATED_NOTIF_PREFERENCES',
	UPDATED_NOTIF_PREFERENCES = 'UPDATED_NOTIF_PREFERENCES'
}

export type ActionTypes =
	| FetchedNotifPreferencesAction
	| CreatedNotifPreferencesAction
	| UpdatedNotifPreferencesAction

export interface FetchedNotifPreferencesAction extends Action {
	type: TypeKeys.FETCHED_NOTIF_PREFERENCES,
	notifPreferences: core.Response.NotificationPreferenceResponse
}

export interface CreatedNotifPreferencesAction extends Action {
	type: TypeKeys.CREATED_NOTIF_PREFERENCES,
	notifPreference: core.NotificationPreference.Model
}

export interface UpdatedNotifPreferencesAction extends Action {
	type: TypeKeys.UPDATED_NOTIF_PREFERENCES,
	notifPreference: core.NotificationPreference.Model
}

// -----------------------------------------------------------------------------
// Fetch Notification Preferences
// -----------------------------------------------------------------------------

const fetchNotifPreferences = (userID: string) => async (dispatch: Dispatch<FetchedNotifPreferencesAction>) => {
	const [err, notifPrefRes] = await to<core.Response.NotificationPreferenceResponse>(GET(`/notificationPreferences/user/${userID}`, {}))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to fetch Notification Preferences with error: ${err}`)
		throw err
	}

	dispatch(fetchedNotifPreferences(notifPrefRes))
	return notifPrefRes
}

const fetchedNotifPreferences = (notifPrefs: core.Response.NotificationPreferenceResponse): FetchedNotifPreferencesAction => {
	const action: FetchedNotifPreferencesAction = {
		type: TypeKeys.FETCHED_NOTIF_PREFERENCES,
		notifPreferences: notifPrefs
	}
	return action
}

// -----------------------------------------------------------------------------
// Create Notification Preference
// -----------------------------------------------------------------------------

const createNotifPreference = (payload: core.NotificationPreference.Model) => async (dispatch: Dispatch<CreatedNotifPreferencesAction>) => {
	const [err, notifPref] = await to<core.NotificationPreference.Model>(POST(`/notificationPreferences`, payload))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to create Notification Preferences with error: ${err}`)
		throw err
	}

	dispatch(createdNotifPreference(notifPref))
	return notifPref
}

const createdNotifPreference = (notifPref: core.NotificationPreference.Model): CreatedNotifPreferencesAction => {
	const action: CreatedNotifPreferencesAction = {
		type: TypeKeys.CREATED_NOTIF_PREFERENCES,
		notifPreference: notifPref
	}
	return action
}

// -----------------------------------------------------------------------------
// Update Notification Preference
// -----------------------------------------------------------------------------

const updateNotifPreference = (preferenceID: string, payload: core.NotificationPreference.Model) => async (dispatch: Dispatch<UpdatedNotifPreferencesAction>) => {
	const [err, notifPref] = await to<core.NotificationPreference.Model>(PUT(`/notificationPreferences/${preferenceID}`, payload))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to update Notification Preferences with error: ${err}`)
		throw err
	}

	dispatch(updatedNotifPreference(notifPref))
	return notifPref
}

const updatedNotifPreference = (notifPref: core.NotificationPreference.Model): UpdatedNotifPreferencesAction => {
	const action: UpdatedNotifPreferencesAction = {
		type: TypeKeys.UPDATED_NOTIF_PREFERENCES,
		notifPreference: notifPref
	}
	return action
}

export const NotificationActions = {
	fetchNotifPreferences,
	createNotifPreference,
	updateNotifPreference
}
