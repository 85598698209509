// External Dependencies
import * as core from 'club-hub-core'

// Internal Dependencies
import { FormInputType, InputSelectionItem } from '../Shared/Form'
import { IsEqual, IsNotEqual, IsBefore, IsAfter } from '../../helpers/queryFilter'
import { QueryFilterFieldAttribute } from '../Shared/QueryFilter/form'

export const UserQueryFilterFields = (membershipTypes: InputSelectionItem[]): QueryFilterFieldAttribute[] => [
	{
		label: 'First Name',
		value: 'firstName',
		type: FormInputType.TEXT,
		operators: [IsEqual, IsNotEqual]
	},
	{
		label: 'Last Name',
		value: 'lastName',
		type: FormInputType.TEXT,
		operators: [IsEqual, IsNotEqual]
	},
	{
		label: 'Birthday',
		value: 'birthday',
		type: FormInputType.DATE,
		operators: [IsBefore, IsAfter]
	},
	{
		label: 'Joined',
		value: 'joined',
		type: FormInputType.DATE,
		operators: [IsBefore, IsAfter]
	},
	{
		label: 'Type',
		value: 'membershipType',
		type: FormInputType.SELECT,
		operators: [IsEqual, IsNotEqual],
		values: membershipTypes
	},
	{
		label: 'Status',
		value: 'memberStatus',
		type: FormInputType.SELECT,
		operators: [IsEqual, IsNotEqual],
		values: [
			{ label: 'Active', value: core.User.MemberStatus.Active },
			{ label: 'Prospect', value: core.User.MemberStatus.Prospect },
			{ label: 'Lapsed', value: core.User.MemberStatus.Lapsed },
			{ label: 'Guest', value: core.User.MemberStatus.Guest },
		]
	}
]
