// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import * as Core from 'club-hub-core'
import Intercom from 'react-intercom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { RouteComponentProps } from 'react-router'
import { Switch, withRouter, matchPath, Redirect } from 'react-router-dom'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies
import * as Constants from '../../constants'
import { Load as LoadAnalytics } from '../../helpers/analytics'

// Actions
import { AppActions, AdminActions, CustomerActions, UserActions, SectionActions, AlertActions } from '../../actions/index'
import { urlForEntryOverride } from '../../actions/app'

// State
import { RootReducerState } from '../../reducers'
import { clearState } from '../../store/localStorage'
import { userIsAdminSelector, inCustomerViewSelector } from '../../reducers/user'

// Router
import { RouteComponent } from '../../router'

// Utility Components
import ScrollToTop from '../../components/Shared/ScrollToTop'

// Components
import OauthComponent from '../../components/Oauth'
import LoginComponent from '../../components/Login'
import HomeComponent from '../../components/Home'
import UserComponent from '../../components/User'
import UserGroupComponent from '../../components/UserGroup'
import MessageComponent from '../../components/Message'
import NavbarComponent from '../../components/Navbar'
import CalendarComponent from '../../components/Calendar'
import AlertComponent from '../../components/Shared/AlertComponent'
import PostComponent from '../../components/Post'
import EventComponent from '../../components/Event'
import ReservationComponent from '../../components/Reservation/TeeTime'
import SidebarComponent from '../../components/Navbar/Sidebar'
import PostDetail from '../../components/PostDetail'
import EventDetailComponent from '../../components/EventDetail'
import DiningReservationComponent from '../../components/Reservation/Dining'
import UserDetailComponent from '../../components/UserDetail'
import ServiceProviderComponent from '../../components/ServiceProvider'
import ServiceComponent from '../../components/Service'
import GuestGolferComponent from '../../components/Reservation/GuestGolfer'
import UserGroupDetailComponent from '../../components/UserGroupDetail'
import RsvpComponent from '../../components/RSVP'
import VehicleComponent from '../../components/Vehicle'
import PublicEventComponent from '../../components/PublicEvent'
import ReservationDetailsComponent from '../../components/Shared/ReservationDetails'
import ForgotPasswordComponent from '../../components/ForgotPassword'
import DimmedLoader from '../../components/Shared/DimmedLoader'
import SectionDetailComponent from '../../components/SectionDetail'
import SectionComponent from '../../components/Section'
import SectionPageComponent from '../../components/Section/SectionPage'
import ErrorBoundary from '../../components/Shared/ErrorBoundary'
import PublicFormComponent from '../../components/PublicForm'
import RestaurantComponent from '../../components/Restaurant'
import MenuComponent from '../../components/Menu'
import MenuItemsComponent from '../../components/MenuItems'
import ReservationSettingsComponent from '../../components/ReservationSettings'
import MyReservationsComponent from '../../components/MyReservations'
import ConfirmReservationComponent from '../../components/ConfirmReservation/OldReservation'
import EventConfirmationComponent from '../../components/ConfirmReservation/EventReservation'
import GolfConfirmationComponent from '../../components/ConfirmReservation/GolfReservation'
import SettingsComponent from '../../components/Settings'
import MessageUsers from '../../components/MessageUsers'
import FullStoryComponent from '../../components/Fullstory'
import FourOFourPage from '../../components/Shared/Error/404'
import StatementComponent from '../../components/Statement'

// Forms
import EventForm from '../../components/Forms/Event'
import MessageForm from '../../components/Forms/Message'
import PostForm from '../../components/Forms/Post'
import UserForm from '../../components/Forms/User'
import UserGroupForm from '../../components/Forms/UserGroup'
import ServiceProviderForm from '../../components/Forms/ServiceProvider'
import ServiceForm from '../../components/Forms/Service'
import VehicleForm from '../../components/Forms/Vehicle'
import SectionPageForm from '../../components/Forms/SectionPage'
import RestaurantForm from '../../components/Forms/Restaurant'
import MenuForm from '../../components/Forms/Menu'
import MenuItemForm from '../../components/Forms/MenuItem'

// Helpers
import { setStateAsync } from '../../helpers/promise'
import { fullName } from '../../helpers/user'
import { constants } from 'os'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

type Props = RouteComponentProps & ConnectedState & ConnectedActions
interface State {
	error: boolean
	loading: boolean
}

const initialState: State = {
	error: false,
	loading: false,
}

/**
 * AppComponent is the root component for our application.
 */
class AppComponent extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props)
		LoadAnalytics()
		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	componentDidMount = async () => {
		this.props.clearFlashMessage()

		/**
		 * If the app container mounts and we have a logged in user, refresh their state.
		 * This will happen if a user navigates to our site and is already logged in and
		 * will prevent stale user state.
		 */
		if (this.props.userState.loggedInUser) {
			this.fetchStateForUser()
		}
	}

	componentDidUpdate = async (prevProps: Props, prevState: State) => {
		// Check for logout
		const prevPath = prevProps.location.pathname
		const currentPath = this.props.location.pathname

		// Check for a forced redirect
		if (currentPath === Constants.HOME_ROUTE && this.props.appState.appEntryOverride) {
			const locationPush = urlForEntryOverride(this.props.appState.appEntryOverride)
			this.props.history.push(locationPush)
			return this.props.clearAppEntryOverride()
		}
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildHelmet = () => {
		const club = this.props.loggedInClub
		const siteName = oc(club).name('Warehouse')
		const clubSite = 'https://app.warehousepc.com'

		return (
			<Helmet>
				<meta charSet='utf-8' />
				<meta name='branch' content={Constants.BRANCH} />
				<meta name='commit' content={Constants.COMMITHASH} />
				<meta name='version' content={Constants.VERSION} />
				<meta property='og:url' content={clubSite} />
				<meta name='viewport' content='width=device-width, initial-scale=1.0' />
				<meta property='og:title' content={siteName} />
				<title>{siteName}</title>
				<link rel='canonical' href={clubSite} />
			</Helmet>
		)
	}

	buildLoginComponent = () => {
		return (
			<div className='login-view'>
				<AlertComponent />
				<LoginComponent {...this.props} />
			</div>
		)
	}

	buildForgotPasswordComponent = () => {
		return (
			<div>
				<AlertComponent />
				<ForgotPasswordComponent {...this.props} />
			</div>
		)
	}

	buildPublicEventComponent = () => {
		return (
			<div className='application-root'>
				<NavbarComponent {...this.props} />
				<div className='public-event-content-container'>
					<ErrorBoundary {...this.props} isAdminUser={this.props.userState.isAdmin}>
						<AlertComponent />
						<PublicEventComponent publicViewer={true} />
					</ErrorBoundary>
				</div>
			</div>
		)
	}

	buildPublicFormComponent = () => {
		return (
			<div className='container-fluid px-0 h-100'>
				<NavbarComponent {...this.props} />
				<AlertComponent />
				<PublicFormComponent publicViewer={true} />
			</div>
		)
	}

	buildFullStory = () => {
		return <FullStoryComponent user={oc(this).props.userState.loggedInUser()} isMeshAdmin={this.props.userState.isMeshAdmin} />
	}

	buildIntercomComponent = () => {
		const loggedInUser = oc(this).props.userState.loggedInUser()
		if (isNullOrUndefined(loggedInUser)) {
			return null
		}

		const { loggedInClub } = this.props
		if (isNullOrUndefined(loggedInClub)) {
			return null
		}

		const user_id = loggedInUser._id
		const email = loggedInUser.email
		const name = fullName(loggedInUser)
		const user = { user_id, email, name }

		if (loggedInClub.name === Core.Constants.Clubs.OTTO) {
			return <Intercom appID={Constants.INTERCOM_APP_KEY} hide_default_launcher={true} alignment={'right'} {...user} />
		}
		return <Intercom appID={Constants.INTERCOM_APP_KEY} alignment={'right'} {...user} />
	}

	buildApplicationRouter = () => {
		const loggedInUser = this.props.userState.loggedInUser
		const loggedInClub = this.props.loggedInClub
		const navigationConfig = (loggedInUser.admin) ? loggedInClub.navigationConfig.admin : loggedInClub.navigationConfig.customer

		// Determine the default path to redirect to
		const defaultPath = navigationConfig[0].items[0].path

		return (
			<Switch>
				{/* Login / Registration / Forgot Password */}
				<RouteComponent path={Constants.LOGIN_ROUTE} component={LoginComponent} />
				<RouteComponent path={Constants.REGISTER_ROUTE} component={LoginComponent} />
				<RouteComponent path={Constants.OAUTH_ROUTE} component={OauthComponent} />
				<RouteComponent path={Constants.FORGOT_PASSWORD_ROUTE} component={ForgotPasswordComponent} />

				{/* Home */}
				<RouteComponent path={Constants.HOME_ROUTE} exact={true} component={HomeComponent} />
				<RouteComponent path={Constants.MY_RESERVATIONS_ROUTE} exact={true} component={MyReservationsComponent} key={this.props.location.key} />

				{/* Posts */}
				<RouteComponent path={Constants.POSTS_ROUTE} exact={true} component={PostComponent} />
				<RouteComponent path={Constants.CREATE_POST_ROUTE} component={PostForm} />
				<RouteComponent path={Constants.UPDATE_POST_ROUTE} component={PostForm} />
				<RouteComponent path={Constants.VIEW_POST_ROUTE} component={PostDetail} />

				{/* Messages */}
				<RouteComponent path={Constants.MESSAGES_ROUTE} exact={true} component={MessageComponent} />
				<RouteComponent path={Constants.MESSAGES_DRAFT_ROUTE} exact={true} component={MessageComponent} />
				<RouteComponent path={Constants.MESSAGES_TEMPLATE_ROUTE} exact={true} component={MessageComponent} />
				<RouteComponent path={Constants.CREATE_MESSAGE_ROUTE} component={MessageForm} />
				<RouteComponent path={Constants.EDIT_MESSAGE_ROUTE} component={MessageForm} />
				<RouteComponent path={Constants.UPDATE_MESSAGE_ROUTE} component={MessageForm} />
				<RouteComponent path={Constants.UPDATE_PREVIEW_MESSAGE_ROUTE} component={MessageForm} />
				<RouteComponent path={Constants.CREATE_PREVIEW_MESSAGE_ROUTE} component={MessageForm} />
				<RouteComponent path={Constants.VIEW_MESSAGE_USERS_ROUTE} component={MessageUsers} />

				{/* Calendar / Events */}
				<RouteComponent path={Constants.CALENDAR_ROUTE} component={CalendarComponent} />
				<RouteComponent path={Constants.EVENTS_ROUTE} exact={true} component={EventComponent} />
				<RouteComponent path={Constants.CREATE_EVENT_ROUTE} component={EventForm} />
				<RouteComponent path={Constants.UPDATE_EVENT_ROUTE} component={EventForm} />
				<RouteComponent path={Constants.VIEW_EVENT_ROUTE} component={EventDetailComponent} />
				<RouteComponent path={Constants.VIEW_EVENT_RSVPS_ROUTE} component={RsvpComponent} />
				<RouteComponent path={Constants.VIEW_PUBLIC_EVENT_ROUTE} component={PublicEventComponent} />
				<RouteComponent path={Constants.RSVP_TO_EVENT_ROUTE} exact={true} component={EventConfirmationComponent} />

				{/* Service Providers */}
				<RouteComponent path={Constants.SERVICE_PROVIDERS_ROUTE} exact={true} component={ServiceProviderComponent} />
				<RouteComponent path={Constants.CREATE_SERVICE_PROVIDER_ROUTE} component={ServiceProviderForm} />
				<RouteComponent path={Constants.UPDATE_SERVICE_PROVIDER_ROUTE} component={ServiceProviderForm} />

				{/* Services */}
				<RouteComponent path={Constants.SCHEDULED_SERVICES_ROUTE} exact={true} component={ServiceComponent} />
				<RouteComponent path={Constants.CREATE_SCHEDULED_SERVICE_ROUTE} component={ServiceForm} />
				<RouteComponent path={Constants.UPDATE_SCHEDULED_SERVICE_ROUTE} component={ServiceForm} />

				{/* Guest Requests */}
				<RouteComponent path={Constants.GUEST_GOLFER_ROUTE} exact={true} component={GuestGolferComponent} />
				<RouteComponent path={Constants.GUEST_GOLFER_CALENDAR_ROUTE} exact={true} component={CalendarComponent} />

				{/* Vehicles */}
				<RouteComponent path={Constants.VEHICLES_ROUTE} exact={true} component={VehicleComponent} />
				<RouteComponent path={Constants.CREATE_VEHICLE_ROUTE} component={VehicleForm} />
				<RouteComponent path={Constants.UPDATE_VEHICLE_ROUTE} component={VehicleForm} />

				{/* Tee Time Reservations */}
				<RouteComponent path={Constants.GOLF_RESERVATION_ROUTE} exact={true} component={ReservationComponent} />
				<RouteComponent path={Constants.GOLF_RESERVATION_CALENDAR_ROUTE} exact={true} component={CalendarComponent} />

				<RouteComponent path={Constants.DINING_RESERVATIONS_ROUTE} exact={true} component={DiningReservationComponent} />

				<RouteComponent path={Constants.VIEW_RESERVATION_DETAILS_ROUTE} component={ReservationDetailsComponent} />
				<RouteComponent path={Constants.RESERVATION_SETTINGS_ROUTE} component={ReservationSettingsComponent} />

				{/* Customer Reservations */}
				<RouteComponent path={Constants.CONFIRM_RESERVATION} exact={true} component={ConfirmReservationComponent} />
				<RouteComponent path={Constants.GOLF_RESERVATION} exact={true} component={GolfConfirmationComponent} />

				{/* Restaurants */}
				<RouteComponent path={Constants.RESTAURANT_ROUTE} exact={true} component={RestaurantComponent} />
				<RouteComponent path={Constants.RESTAURANT_CREATE_ROUTE} component={RestaurantForm} />
				<RouteComponent path={Constants.RESTAURANT_UPDATE_ROUTE} component={RestaurantForm} />

				{/* Menus */}
				<RouteComponent path={Constants.MENU_ROUTE} exact={true} component={MenuComponent} />
				<RouteComponent path={Constants.MENU_CREATE_ROUTE} component={MenuForm} />
				<RouteComponent path={Constants.MENU_UPDATE_ROUTE} component={MenuForm} />

				{/* Menu Items */}
				<RouteComponent path={Constants.MENU_ITEMS_ROUTE} exact={true} component={MenuItemsComponent} />
				<RouteComponent path={Constants.MENU_ITEM_CREATE_ROUTE} component={MenuItemForm} />
				<RouteComponent path={Constants.MENU_ITEM_UPDATE_ROUTE} component={MenuItemForm} />

				{/* Users */}
				<RouteComponent path={Constants.USER_ROUTE} exact={true} component={UserComponent} />
				<RouteComponent path={Constants.USER_PROFILE_ROUTE} exact={true} component={UserDetailComponent} />
				<RouteComponent path={Constants.CREATE_USER_ROUTE} component={UserForm} />
				<RouteComponent path={Constants.UPDATE_USER_ROUTE} component={UserForm} />
				<RouteComponent path={Constants.VIEW_USER_ROUTE} component={UserDetailComponent} />

				{/* Statements */}
				<RouteComponent path={Constants.USER_STATEMENT_ROUTE} component={StatementComponent} />

				{/* User Groups */}
				<RouteComponent path={Constants.USER_GROUP_ROUTE} exact={true} component={UserGroupComponent} />
				<RouteComponent path={Constants.CREATE_USER_GROUP_ROUTE} component={UserGroupForm} />
				<RouteComponent path={Constants.UPDATE_USER_GROUP_ROUTE} component={UserGroupForm} />
				<RouteComponent path={Constants.VIEW_USER_GROUP_ROUTE} component={UserGroupDetailComponent} />

				{/* Sections */}
				<RouteComponent path={Constants.CLUB_INFO_ROUTE} exact={true} component={SectionComponent} />
				<RouteComponent path={Constants.SECTION_PAGES_ROUTE} exact={true} component={SectionPageComponent} />
				<RouteComponent path={Constants.CREATE_SECTION_PAGE_ROUTE} component={SectionPageForm} />
				<RouteComponent path={Constants.UPDATE_SECTION_PAGE_ROUTE} component={SectionPageForm} />
				<RouteComponent path={Constants.VIEW_SECTION_ROUTE} component={SectionDetailComponent} />

				{/* Settings */}
				<RouteComponent path={Constants.SETTINGS_ROUTE} component={SettingsComponent} />

				{/* Default Route */}
				<Redirect from={'/'} exact={true} to={defaultPath} />
				<RouteComponent component={FourOFourPage} />

			</Switch>
		)
	}

	buildAdminUI = () => {
		const fullWidth = this.getWidth()
		const containerClass = `application-container h-100 ${fullWidth}`
		return (
			<div className='row application-content-row flex-xl-nowrap w-100'>
				<SidebarComponent {...this.props} />
				<RS.Col md={12} lg={9} xl={10} className='content-col'>
					<ScrollToTop>
						<ErrorBoundary  {...this.props} isAdminUser={this.props.isAdmin}>

							<RS.Container className={containerClass}>
								<AlertComponent />
								{this.buildApplicationRouter()}
							</RS.Container>
						</ErrorBoundary>
					</ScrollToTop>
				</RS.Col>
			</div>

		)
	}

	buildMemberUI = () => {
		const fullWidth = this.getWidth()
		const containerClass = `application-container h-100 ${fullWidth}`
		return (
			<div className='row application-content-row d-flex justify-content-center'>
				<SidebarComponent {...this.props} />
				<div className='col-12 col-md-12 col-lg-12 col-xl-12 content-col'>
					<ScrollToTop>
						<ErrorBoundary  {...this.props} isAdminUser={this.props.isAdmin}>
							<RS.Container className={containerClass}>
								<AlertComponent />
								{this.buildApplicationRouter()}
							</RS.Container>
						</ErrorBoundary>
					</ScrollToTop>
				</div>
			</div>
		)
	}

	buildLoggedInUser = () => {
		const content = this.props.isCustomerView ? this.buildMemberUI() : this.buildAdminUI()
		return (
			<div className='app-body-container container-fluid h-100'>
				<div className='application-root h-100'>
					{this.buildFullStory()}
					{this.buildIntercomComponent()}
					<div className='application-content-container container-fluid px-0'>
						{content}
					</div>
				</div>
			</div>
		)
	}

	getWidth = () => {
		const path = this.props.location.pathname
		switch (path) {
			case Constants.CALENDAR_ROUTE:
			case Constants.MESSAGES_ROUTE:
			case Constants.MESSAGES_DRAFT_ROUTE:
			case Constants.MESSAGES_TEMPLATE_ROUTE:
			case Constants.EVENTS_ROUTE:
			case Constants.POSTS_ROUTE:
			case Constants.SCHEDULED_SERVICES_ROUTE:
			case Constants.CLUB_INFO_ROUTE:
			case Constants.USER_ROUTE:
			case Constants.USER_GROUP_ROUTE:
			case Constants.GOLF_RESERVATION_ROUTE:
			case Constants.CREATE_POST_CONTENT_ROUTE:
			case Constants.GUEST_GOLFER_ROUTE:
				return 'full-width'
		}

		const partialPaths = [
			Constants.CREATE_POST_CONTENT_ROUTE,
			Constants.UPDATE_POST_CONTENT_ROUTE,
			Constants.MENU_ITEMS_ROUTE,
			Constants.CREATE_SECTION_PAGE_ROUTE,
			Constants.UPDATE_SECTION_PAGE_ROUTE,
			Constants.CREATE_PREVIEW_MESSAGE_ROUTE,
			Constants.UPDATE_PREVIEW_MESSAGE_ROUTE,
			Constants.VIEW_EVENT_RSVPS_ROUTE
		]

		const matched = partialPaths.find((partialPath: string) => {
			const match = matchPath(path, { path: partialPath, exact: true })
			return match
		})
		if (!isNullOrUndefined(matched)) {
			return 'full-width'
		}
		return ''
	}

	/**
	 * Renders a Grid component responsible for displaying UI based on the current application path.
	 */
	public render() {
		const { location } = this.props
		const { loading } = this.state

		if (loading) { return <DimmedLoader component={null} isLoading={true} /> }

		// Check for password reset
		const passwordReset = location.pathname === Constants.FORGOT_PASSWORD_ROUTE || location.pathname === Constants.SET_PASSWORD_ROUTE
		if (passwordReset) {
			return this.buildForgotPasswordComponent()
		}

		// Determine if the user is logged in by checking for specific state
		const isLoggedIn = !isNullOrUndefined(oc(this).props.userState.loggedInUser()) && !isNullOrUndefined(this.props.loggedInClub)

		// Determine if on public route and not logged in
		const onPublicEventRoute = matchPath(location.pathname, { path: Constants.VIEW_PUBLIC_EVENT_ROUTE })
		const onPrivateEventRoute = matchPath(location.pathname, { path: Constants.VIEW_EVENT_ROUTE })
		if (!isLoggedIn && (onPublicEventRoute || onPrivateEventRoute)) {
			return this.buildPublicEventComponent()
		}

		// Determine if on a public route and not logged in
		const publicFormRoute = matchPath(location.pathname, { path: Constants.PUBLIC_FORMS })
		if (publicFormRoute) {
			return this.buildPublicFormComponent()
		}

		// Determine which content to show
		const onLoginRoute = location.pathname === Constants.LOGIN_ROUTE
		let content: React.ReactNode
		let navbar: React.ReactNode
		if (isLoggedIn && !onLoginRoute) {
			navbar = <NavbarComponent {...this.props} />
			content = this.buildLoggedInUser()
		} else {
			content = this.buildLoginComponent()
		}

		const helmet = this.buildHelmet()

		return (
			<div className='app-root-component h-100'>
				{helmet}
				{navbar}
				{content}
			</div>
		)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	fetchStateForUser = async () => {
		const { fetchAdminState, fetchCustomerState, fetchSections, isAdmin, history, loggedInClub } = this.props
		const stateFetchFunc = (isAdmin) ? fetchAdminState : fetchCustomerState

		// Conditionally load / re-render state
		const [err] = await to(stateFetchFunc() as any)
		if (err) {
			// Check for a known error
			switch (err.message) {
				case Constants.UNAUTHORIZED_ACCESS_NOTIFICATION:
					this.props.signOut()
				default:
					// tslint:disable-next-line
					console.error(`Failed to fetch User State with error: ${err}`)
					this.setState({ loading: false, error: true })
					return
			}
		}

		const [sectionErr] = await to(fetchSections(isAdmin) as any)
		if (sectionErr) {
			// tslint:disable-next-line
			console.error(`Failed to fetch Sections with error: ${err}`)
			this.setState({ loading: false, error: true })
			return
		}
	}

	clearStateAndRedirectToLogin = async () => {
		// Sign the user out
		await this.props.signOut()

		// Clear the state
		clearState()

		// Redirect to the login route
		this.props.history.push(Constants.LOGIN_ROUTE)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	appState: state.app,
	userState: state.user,
	loggedInClub: state.club.loggedInClub,
	isAdmin: userIsAdminSelector(state),
	isCustomerView: inCustomerViewSelector(state),
})

const mapDispatchToProps = {
	...AppActions,
	...UserActions,
	...AdminActions,
	...CustomerActions,
	...SectionActions,
	...AlertActions
}

const enhance = compose<React.ComponentType<{}>>(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)

export const App = enhance(AppComponent)
