// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import * as core from 'club-hub-core'
import * as Feather from 'react-feather'
import { Form, FieldArray, FieldArrayRenderProps } from 'formik'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'

// Internal Dependencies

// Components
import GlyphTextButton from '../../Shared/GlyphTextButton'
import ModalComponent from '../../Shared/Modal'

// Form
import { PrivilegeFormInputs, PrivilegeFormState, LimitForPeriodFormValue } from './form'
import { ReactSelectItem, FormInput, FormInputType } from '../../Shared/Form'
import { buildLabeledInputPrimitive, buildUnlabeledInputPrimitive, FormikComponent } from '../../Shared/Formik'
import { Label } from '../../Shared/Formik/InputPrimitives/Label'

// Helpers
import { frequencies } from '../../../helpers/form'
import { setStateAsync } from '../../../helpers/promise'

interface ComponentProps {
	membershipTypes: core.Club.ResourceType[]
	onMemberTypeChange: (item: ReactSelectItem) => void
	saveHandler: (formState: core.Calendar.Privilege) => Promise<void>
	closeHandler: () => void
	existingRestrictions?: PrivilegeFormState
}

type Props = ComponentProps
interface State {
	existingRestrictions?: PrivilegeFormState
}

class PrivilegeFormComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = { existingRestrictions: props.existingRestrictions }
		this.configureFormState(props.existingRestrictions)
	}

	async componentDidUpdate(prevProps: Props) {
		const newFormState = this.props.existingRestrictions
		const oldFormState = prevProps.existingRestrictions
		if (`${oc(newFormState).memberType()}` !== `${oc(oldFormState).memberType()}`) {
			await this.configureFormState(this.props.existingRestrictions)
		}
	}

	configureFormState = async (existingRestrictions?: PrivilegeFormState) => {
		const restrictions = { ...existingRestrictions }
		if (oc(restrictions).maxBookings.length(0) === 0) {
			delete restrictions.maxBookings
		}
		if (oc(restrictions).guestBookings.length(0) === 0) {
			delete restrictions.guestBookings
		}
		await setStateAsync(this, { existingRestrictions: restrictions })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	handleSave = async (values: PrivilegeFormState) => {
		// Convert Max Bookings/Guests for Server
		const maxBookingsForServer = this.convertLimitedBookingInputForServer([...values.maxBookings])
		const maxGuestsForServer = this.convertLimitedBookingInputForServer([...values.guestBookings])

		const memberType = values.memberType as ReactSelectItem
		const privilegeForServer: core.Calendar.Privilege = {
			memberType: memberType.value as any,
			bookingWindow: values.bookingWindow,
			maxBookings: maxBookingsForServer,
			maxGuests: maxGuestsForServer,
		}

		await this.props.saveHandler(privilegeForServer)
		this.props.closeHandler()
	}

	handleClose = async () => {
		this.props.closeHandler()
	}

	// ----------------------------------------------------------------------------------
	// Component Builders
	// ----------------------------------------------------------------------------------

	onChange = (field: string, value: ReactSelectItem) => {
		this.props.onMemberTypeChange(value)
	}

	buildInputs = () => {
		// Determine the Membership Types that will appear in the form
		const membershipTypesForForm = this.props.membershipTypes.map((membership) => ({
			label: membership.title,
			value: `${membership._id}`
		}))

		// Build the form inputs
		const [
			memberTypeInput,
			bookingWindowInput,
			maxBookingInput,
			guestBookingInput
		] = PrivilegeFormInputs(membershipTypesForForm)

		const wrappedMemberTypeProps = { inputs: [memberTypeInput], onChange: this.onChange }
		return (
			<Form>
				<div className='privilegeForm-section member-type'>
					{buildLabeledInputPrimitive(memberTypeInput, wrappedMemberTypeProps)}
				</div>

				<div className='privilegeForm-section booking-window'>
					{buildLabeledInputPrimitive(bookingWindowInput)}
				</div>

				<div className='privilegeForm-section max-bookings'>
					{this.buildLimitedBookingSection(maxBookingInput)}
				</div>

				<div className='privilegeForm-section guest-bookings'>
					{this.buildLimitedBookingSection(guestBookingInput)}
				</div>
			</Form>
		)
	}

	/**
	 * Builds a custom 'LimitedBooking' input section given a FormInput.
	 *
	 * These are the important fields on FormInput:
	 * - title: Will appear in the 'Add Rule' button and above the first input in plural form
	 * - property: The identifier for the inputs in the form
	 */
	buildLimitedBookingSection = (input: FormInput) => {
		const buttonTitle = `Add Rule`
		return (
			<FieldArray name={input.property}>
				{(props) => {
					const formInputValues: LimitForPeriodFormValue[] = props.form.values[input.property]
					return (
						<>
							<Label><span>{`${input.title}s`}</span></Label>
							{formInputValues.map((inputValue: any, idx: number) =>
								this.buildLimitedBookingInput(input, idx, formInputValues, props)
							)}
							<RS.Button
								className={'privilegeForm-add-btn'}
								disabled={formInputValues.length === frequencies.length}
								onClick={() => props.push({ limit: '', period: null })}
							>
								{buttonTitle}
							</RS.Button>
						</>
					)
				}}
			</FieldArray>
		)
	}

	/**
	 * Builds a custom 'LimitedBooking' input section given a FormInput
	 *
	 * @param input The FormInput spec for the Input
	 * @param index The row index of the Input that is being built
	 * @param selectedPeriods The periods that have been selected in this section
	 */
	buildLimitedBookingInput = (input: FormInput, index: number, selectedPeriods: LimitForPeriodFormValue[], fieldArrayProps: FieldArrayRenderProps) => {
		const bookingLimitInput: FormInput = {
			title: '',
			property: `${input.property}[${index}].limit`,
			type: FormInputType.NUMBER,
			placeholder: 'Enter Number...',
			defaultValue: '',
		}

		const bookingPeriodInput: FormInput = {
			title: '',
			property: `${input.property}[${index}].period`,
			type: FormInputType.SELECT,
			placeholder: 'Select Range...',
			selectItems: this.frequencyOptions(selectedPeriods),
			defaultValue: null,
		}
		const removeInputButton = (
			<RS.Col xs={2}>
				<RS.Button
					className='delete-button d-flex justify-content-center'
					onClick={() => fieldArrayProps.remove(index)}
					outline={true}
				>
					<Feather.XCircle size={18} />
				</RS.Button>
			</RS.Col>
		)
		return (
			<div key={`${input.property}_${index}`}>
				<RS.Row key={`${input.property}_${index}`} className='privilegeForm-split-input'>
					<RS.Col>
						{buildUnlabeledInputPrimitive(bookingLimitInput)}
					</RS.Col>
					<RS.Col>
						{buildUnlabeledInputPrimitive(bookingPeriodInput)}
					</RS.Col>
					{removeInputButton}
				</RS.Row>
			</div>
		)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	membershipTypesForForm = () => {
		return this.props.membershipTypes.map((membership) => ({
			label: membership.title,
			value: `${membership._id}`
		}))
	}

	membershipTypeForForm = (membership: core.Club.ResourceType) => {
		return {
			label: membership.title,
			value: `${membership._id}`
		}
	}

	/**
	 * Converts the given set of values from the form into the proper format for the server
	 */
	convertLimitedBookingInputForServer = (inputValues: LimitForPeriodFormValue[]): core.IShared.LimitForPeriod[] => {
		return inputValues.filter((inputValue) => {
			const limitValid = !isNullOrUndefined(inputValue.limit) && `${inputValue.limit}` !== ''
			const periodValid = !isNullOrUndefined(inputValue.period)
			return limitValid && periodValid
		}).map((filteredInputValue) => ({
			period: Number(filteredInputValue.period.value),
			limit: filteredInputValue.limit
		}))
	}

	frequencyOptions = (bookingRestrictions: LimitForPeriodFormValue[]): ReactSelectItem[] => {
		const allFrequencies = frequencies

		// Determine which options from 'allFrequencies' have not already been selected
		const filteredFrequencies = []
		for (const frequency of allFrequencies) {
			let optionSelected = false
			for (const bookingRestriction of bookingRestrictions) {
				if (bookingRestriction.period && Number(bookingRestriction.period.value) === Number(frequency.value)) {
					optionSelected = true
					break
				}
			}

			// If the option has not been selected, add it to the list
			if (!optionSelected) {
				filteredFrequencies.push(frequency)
			}
		}

		return filteredFrequencies
	}

	render() {
		return (
			<FormikComponent
				inputs={PrivilegeFormInputs(this.membershipTypesForForm())}
				enableReinitialize={true}
				formResource={this.state.existingRestrictions}
				onSubmit={this.handleSave}
				render={(formikProps) => (
					<ModalComponent
						modalTitle={'Member Privilege'}
						className={'privilegeForm-modal'}
						submitButtonName={'Save'}
						cancelButtonName={'Cancel'}
						submitButtonHandler={formikProps.submitForm}
						cancelButtonHandler={this.handleClose}
						buildInputs={this.buildInputs}
					/>
				)}
			/>
		)
	}
}

export default PrivilegeFormComponent
