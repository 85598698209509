// External Dependencies
import * as React from 'react'

// Internal Dependencies
import { BaseTablePrimitiveProps } from '../index'
import { BaseTableCell } from '../BaseTableCell'

type Props<T> = BaseTablePrimitiveProps<T>

export const TableBlank: React.FC<Props<any>> = (props: Props<any>) => {
	return (
		<BaseTableCell {...props}>
			{null}
		</BaseTableCell>
	)
}
