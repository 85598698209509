// External Dependencies
import * as React from 'react'
import * as Feather from 'react-feather'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Col } from 'reactstrap'

// Internal Dependencies

// Reducers
import { RootReducerState } from '../../../reducers'

// Constants
import * as Constants from '../../../constants'

type ConnectedState = ReturnType<typeof mapStateToProps>

const initialState = {
	showAlert: false
}

type Props = ConnectedState
type State = typeof initialState

/**
 * Renders an Alert message.
 * The Alert component is used in the App component (UI Root level of the app)
 * Anytime a message is present in the Alert reducer,
 * the alert message will be rendered over the top of all other components.
 * In order to have this component rendered,
 * make a call to the Alert action: fireFlashMessage()
 */
class AlertComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
	}

	buildAlertContent = () => {
		const message = this.props.alertState.message
		const flashType: string = this.props.alertState.alertType

		// Set the Icon class based on type
		let FeatherIcon: Feather.Icon
		let alertClass = ''
		switch (flashType) {
			case Constants.FlashType.PRIMARY:
				FeatherIcon = Feather.Bell
				alertClass = 'alert-primary'
				break
			case Constants.FlashType.SUCCESS:
				FeatherIcon = Feather.Check
				alertClass = 'alert-success'
				break
			case Constants.FlashType.WARNING:
				FeatherIcon = Feather.AlertTriangle
				alertClass = 'alert-warning'
				break
			case Constants.FlashType.DANGER:
				FeatherIcon = Feather.AlertTriangle
				alertClass = 'alert-danger'
				break
			default:
				FeatherIcon = null
				alertClass = ''
				break
		}

		// Build the Icon if we have a valid class
		const alertIcon = (FeatherIcon) ?
			(<FeatherIcon size={20} className={'mr-2'} aria-hidden='true' />) :
			null

		return (
			<div className={`alert-icon ${alertClass} alert d-flex justify-content-center`} role='alert'>
				{alertIcon}
				<span>{message}</span>
			</div>
		)
	}

	render() {
		const message = this.props.alertState.message
		if (!message) { return null }

		return (
			<Col xs={12} className='alert-col'>
				{this.buildAlertContent()}
			</Col>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	alertState: state.alert
})

const enhance = compose<React.ComponentType<{}>>(
	connect(mapStateToProps)
)

export default enhance(AlertComponent)
