// External Dependencies
import { createStore, applyMiddleware, compose, Middleware, MiddlewareAPI, Dispatch, Action } from 'redux'
import { Store } from 'react-redux'
import thunk from 'redux-thunk'
import { throttle } from 'underscore'
import logger from 'redux-logger'
import * as Sentry from '@sentry/browser'

// Internal Deps
import * as Constants from '../constants'
import { SignOutUserAction, TypeKeys } from '../actions/user'

// Middleware
import sentry from './middlewares/sentry'

import rootReducer, { RootReducerState } from '../reducers'
import { saveState as saveToLocalStorage } from './localStorage'

export const errorHandler: Middleware = (api: MiddlewareAPI<Dispatch>) => (next: Dispatch<Action>) => <A extends Action>(action: A) => {
	const middlewareAction = next(action) as any as Promise<void>
	if (middlewareAction && typeof middlewareAction.catch === 'function') {
		middlewareAction.catch((err: Error) => {
			if (err.message === Constants.UNAUTHORIZED_ACCESS_NOTIFICATION) {
				// Clears the store and forces the app back to login
				const signOutAction: SignOutUserAction = { type: TypeKeys.SIGN_OUT }
				api.dispatch(signOutAction)
			}
			// Log the actual error with Sentry.
			Sentry.captureException(err)
		})
	}
	return middlewareAction
}

/**
 * @method      configureStore
 * @description Returns the created store for the application
 * @param       {Object}       initialState
 * @return      {Object}
 */
export default function configureStore(initialState: RootReducerState) {
	// Adding thunk
	const middlewareList = [errorHandler, thunk, sentry]
	if (process.env.NODE_ENV === 'local') {
		middlewareList.push(logger)
	}

	const middleware = applyMiddleware(...middlewareList)

	let composeEnhancers = compose

	// enables Redux devtools in browsers (needs extension installed separately from Chrome store) To get rid of it, remove following two lines, composeEnhancers var and compose from redux
	if (typeof window !== 'undefined' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
		composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
	}

	// Creating our store
	const store = createStore(
		rootReducer,
		initialState,
		composeEnhancers(middleware as any)
	)
	// Add Subscribers to store
	addSubscribersToStore(store)
	return store
}

/**
 * @method      addSubscribersToStore
 * @description Takes a store and adds subscribers to the store
 * @param       {object}            store
 */
function addSubscribersToStore(store: Store<RootReducerState>) {
	// Only save updates to the session and report
	// and limit saves to once per second
	store.subscribe(throttle(() => {
		// Persist State to Local Storage
		saveToLocalStorage({
			app: store.getState().app,
			restaurant: store.getState().restaurant,
			message: store.getState().message,
			alert: store.getState().alert,
			user: store.getState().user,
			post: store.getState().post,
			event: store.getState().event,
			club: store.getState().club,
			section: store.getState().section,
			calendar: store.getState().calendar,
			statement: store.getState().statement,
			queryFilter: store.getState().queryFilter,
		})
	}, 1000))
}
