// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as queryString from 'query-string'
import { withRouter, RouteComponentProps } from 'react-router'
import { Button, Menu, Dropdown, Icon, Breadcrumb } from 'antd'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { FormikValues, FormikProps, Formik } from 'formik'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'
import * as Feather from 'react-feather'
import * as RS from 'reactstrap'

// Internal Dependencies

// Reducers
import { RootReducerState } from '../../../../reducers'
import { adminsSelector } from '../../../../reducers/user'

// Actions
import { AlertActions } from '../../../../actions/index'

// Components
import ScrollToTopOnMount from '../../../Shared/ScrollToTopOnMount'

// Form
import GenericFormComponent from '../../../Shared/Formik/GenericForm'
import { ReactSelectItem, FormInput } from '../../../Shared/Form'
import { MessageFormState, MessageFormInputs, SCHEDULED_DELIVERY, IMMEDIATE_DELIVERY } from '../form'

// Helpers
import * as Constants from '../../../../constants'
import { setStateAsync } from '../../../../helpers/promise'
import { userForForm, userGroupForForm } from '../../../../helpers/user'

interface ButtonInfo {
	handler?: any
	label?: string
}

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

interface ComponentProps {
	handleCancel: () => any
	handleSaveDraft: (payload: Partial<core.Message.Model>) => any
	handleSaveTemplate: (payload: Partial<core.Message.Model>, redirect: boolean) => any
	handleSendTemplate: (payload: Partial<core.Message.Model>) => any
	handlePreview?: (payload: Partial<core.Message.Model>) => any
	handlePublish?: (payload: Partial<core.Message.Model>) => any
	formikRef?: (formikRef: Formik) => void
	status: core.IShared.PublicationStatus
	subjectType?: string
}

const initialState = {
	subjectResource: null as core.Post.Model | core.Event.Model | null,
	immediateDelivery: true,
	dropdownOpen: false
}

type Props = ConnectedState & ConnectedActions & ComponentProps & RouteComponentProps
type State = typeof initialState

/**
 * MessageCreationForm displays inputs specific to a message type (Email, Text or Push)
 */
class MessageCreationForm extends React.Component<Props, State> {

	/**
	 * Local resource model for our form.
	 */
	formResource: any
	formikRef: Formik

	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
	}

	async componentDidMount() {
		if (this.props.subjectType) {
			const resource = this.getResource()
			await setStateAsync(this, { subjectResource: resource })
		}
		const currentMessage = oc(this).props.messageState.currentMessage()
		if (currentMessage) {
			const deliveryDate = new Date(currentMessage.deliveryDate)
			await setStateAsync(this, { immediateDelivery: deliveryDate < new Date() })
		}
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Handles saving a draft message.
	 */
	handleSaveDraft = async (form: MessageFormState): Promise<void> => {
		const payload = this.buildMessagePayload(form, this.props.status)
		return this.props.handleSaveDraft(payload)
	}

	/**
	 * Handles previewing a  message.
	 */
	handlePreview = async (form: MessageFormState): Promise<void> => {
		const payload = this.buildMessagePayload(form, this.props.status)
		return this.props.handlePreview(payload)
	}

	/**
	 * Handles publishing a  message.
	 */
	handlePublish = async (form: MessageFormState): Promise<void> => {
		const payload = this.buildMessagePayload(form, this.props.status)
		return this.props.handlePublish(payload)
	}

	handleFormChange = async (field: string, value: any, formikProps: FormikProps<FormikValues>) => {
		if (field === 'delivery') {
			const immediateDelivery = value.value === IMMEDIATE_DELIVERY

			// Check if we are sending immediately
			if (immediateDelivery) {
				formikProps.setFieldValue('deliveryDate', new Date())
			}

			await setStateAsync(this, { immediateDelivery })
			return
		}
	}

	/**
  	* Handles errors.
  	*/
	handleError = async (message: string) => {
		this.props.fireFlashMessage(message, Constants.FlashType.DANGER)
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	/**
	 * Builds our email message form.
	 */
	buildForm = () => {
		let formResource

		const sender = oc(this).props.userState.loggedInUser._id()
		const delivery = oc(this).state.immediateDelivery() ? IMMEDIATE_DELIVERY : SCHEDULED_DELIVERY
		// Check if we have a current Message
		const currentMessage = oc(this).props.messageState.currentMessage()
		if (oc(currentMessage)._id()) {
			formResource = this.buildFormResource()
		} else if (this.props.subjectType) {
			const subject = this.getMessageSubject()
			formResource = { subject: subject, title: subject, delivery: delivery, sender }
		} else {
			// Determine if Recipients are coming via query params
			const parsedQuery: { recipientEventID: string, individualUserIDs: string[] | string, userGroupID: string } = queryString.parse(this.props.location.search)
			const eventID = oc(parsedQuery).recipientEventID()
			const userIDs = oc(parsedQuery).individualUserIDs()
			const groupID = oc(parsedQuery).userGroupID()

			// Check if the individualUserIDs that came through are in array form,
			// and ensure that single IDs are held in an array
			const userIDArray = Array.isArray(userIDs) ? userIDs : [userIDs]

			const messageRecipients = [eventID, ...userIDArray, groupID].filter((item) => !isNullOrUndefined(item))
			formResource = { delivery: delivery, messageRecipients, sender }
		}

		let buildTemplateButtonGroup
		if (oc(this).props.messageState.currentMessage.richContent.status() === core.IShared.PublicationStatus.Template) {
			buildTemplateButtonGroup = this.buildTemplateButtonGroup()
		}

		// Send in a ref capture to get formik ref
		const formikRef: React.LegacyRef<Formik> = (formik: Formik) => {
			this.formikRef = formik
			if (this.props.formikRef) {
				this.props.formikRef(formik)
			}
		}

		return (
			<GenericFormComponent
				title={this.getFormTitle()}
				inputs={this.buildFormInputs()}
				formResource={formResource}
				enableReinitialize={true}
				onChangeHandler={this.handleFormChange}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.props.handleCancel}
				submitButtonName={this.submitButtonInfo().label}
				submitButtonHandler={this.submitButtonInfo().handler}
				secondaryButtonName={this.secondaryButtonInfo().label}
				secondaryButtonHandler={this.secondaryButtonInfo().handler}
				buttonOverrides={buildTemplateButtonGroup}
				formikRef={formikRef}
			/>
		)
	}

	buildFormInputs = () => {
		// Build Recipients & senders.
		let inputs: FormInput[]
		if (oc(this).props.messageState.currentMessage.richContent.status() !== core.IShared.PublicationStatus.Template) {
			const recipients = this.buildRecipientSelect()
			const senders = this.buildSenderSelect()
			inputs = MessageFormInputs(recipients, senders)
		} else {
			inputs = MessageFormInputs()
		}

		if (this.state.subjectResource) {
			inputs = inputs.filter((item) => item.property !== 'richContent')
		}

		return inputs
	}

	render() {
		return (
			<div className='message-creation-form'>
				<ScrollToTopOnMount />
				{this.buildForm()}
			</div>
		)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	// TEMPLATE SPECIFIC HANDLERS

	toggleDropDown = () => {
		this.setState({ dropdownOpen: !this.state.dropdownOpen })
	}

	buildTemplateButtonGroup = () => {
		const menuItems = [
			(
				<RS.DropdownItem key={`1`} onClick={this.handleSaveAndSendTemplate}>{'Save and Send'}</RS.DropdownItem>
			),
			(
				<RS.DropdownItem key={`2`} onClick={this.handleSaveTemplate}>{'Save'}</RS.DropdownItem>
			),

		]
		if (this.props.messageState.currentMessage.deliveryType === core.Message.DeliveryType.Email) {
			menuItems.push(
				<RS.DropdownItem key={`3`} onClick={this.handlePreviewTemplate}>{'Preview'}</RS.DropdownItem>
			)
		}

		const menu = (
			<Menu className='message-editor-action-menu'>
				{menuItems}
			</Menu>
		)

		return (
			<RS.ButtonDropdown
				className={`top-bar-dropdown-button`}
				isOpen={this.state.dropdownOpen}
				toggle={this.toggleDropDown}
			>
				<RS.Button
					id={'message-creation-action-button'}
					className={'primary-action-button'}
					key={'Send'}
					color='primary'
					onClick={this.handleSendTemplate}
				>
					{'Send'}
				</RS.Button>
				<RS.DropdownToggle color='primary' caret={true} />
				<RS.DropdownMenu>
					{menuItems}
				</RS.DropdownMenu>
			</RS.ButtonDropdown>

		)
	}

	handleSendTemplate = async (): Promise<void> => {
		const values = this.formikRef.getFormikBag().values
		const payload = this.buildMessagePayload(values as MessageFormState, this.props.status)
		this.props.handleSendTemplate(payload)
	}

	handleSaveAndSendTemplate = async (): Promise<void> => {
		const values = this.formikRef.getFormikBag().values
		const payload = this.buildMessagePayload(values as MessageFormState, this.props.status)
		await this.props.handleSaveTemplate(payload, false)
		this.props.handleSendTemplate(payload)
	}

	handleSaveTemplate = async (): Promise<void> => {
		const values = this.formikRef.getFormikBag().values
		const payload = this.buildMessagePayload(values as MessageFormState, this.props.status)
		return this.props.handleSaveTemplate(payload, true)
	}

	handlePreviewTemplate = async (): Promise<void> => {
		const values = this.formikRef.getFormikBag().values
		const payload = this.buildMessagePayload(values as MessageFormState, this.props.status)
		return this.props.handlePreview(payload)
	}

	// GENRAL BUTTON HANDLERS

	/**
	 * Returns the submit button info for the delivery type.
	 */
	submitButtonInfo = (): ButtonInfo => {
		if (this.props.messageState.currentMessage.deliveryType === core.Message.DeliveryType.Email) {
			return { label: 'Preview', handler: this.handlePreview }
		}
		return { label: 'Send', handler: this.handlePublish }
	}

	/**
	 * Returns the secondary button info for the delivery type.
	 */
	secondaryButtonInfo = (): ButtonInfo => {
		return { label: 'Save Draft', handler: this.handleSaveDraft }
	}

	/**
	 * Builds the message form resource.
	 */
	buildFormResource = () => {
		const messageToUpdate = oc(this).props.messageState.currentMessage()
		const messageText = oc(messageToUpdate).richContent.text()
		const author = oc(messageToUpdate).author() as core.User.Model
		const subject = messageToUpdate.title

		let senderID
		if (oc(author)._id()) {
			senderID = author._id
		} else if (author) {
			senderID = author
		} else {
			senderID = oc(this).props.userState.loggedInUser._id()
		}

		// Calculate Recipients.
		const groupRecipients = oc(messageToUpdate).userGroupIDs([])
		const individualRecipients = oc(messageToUpdate).individualUserIDs([])
		const eventRecipients = oc(messageToUpdate).eventIDs([])
		const messageRecipients = [...groupRecipients, ...individualRecipients, ...eventRecipients]
		const delivery = oc(this).state.immediateDelivery() ? IMMEDIATE_DELIVERY : SCHEDULED_DELIVERY
		const formResource = {
			...messageToUpdate,
			sender: senderID,
			subject: subject,
			title: subject,
			richContent: messageToUpdate.richContent,
			messageText: messageText,
			messageRecipients: messageRecipients,
			delivery: delivery,
			status: oc(messageToUpdate).richContent.status()
		}
		return formResource
	}

	/**
	 * Builds a payload that can be used to create or update a message based on the message form state.
	 */
	buildMessagePayload = (form: MessageFormState, status: core.IShared.PublicationStatus): Partial<core.Message.Model | any> => {
		const richContent = this.getRichContent(form)
		const [memberIDs, groupIDs, eventIDs] = this.getRecipientsForPayload(form)
		const title = form.title
		const author = status === core.IShared.PublicationStatus.Template ? this.props.userState.loggedInUser._id : oc(form).sender.value()
		const date = oc(form).deliveryDate(new Date())
		const messagePayload: Partial<core.Message.Model | any> = {
			richContent: richContent,
			title: title,
			deliveryType: this.props.messageState.currentMessage.deliveryType,
			deliveryDate: new Date(date).toISOString(),
			recipients: memberIDs,
			individualRecipients: memberIDs,
			groupRecipients: groupIDs,
			eventRecipients: eventIDs,
			author
		}

		// Add resource specific props.
		if (this.state.subjectResource) {
			const subjectID = `${this.state.subjectResource._id}`
			messagePayload.subjectID = subjectID
			messagePayload.subjectType = this.props.subjectType
		}
		return messagePayload
	}

	/**
	 * Gets the title for the message form.
	 */
	getFormTitle = () => {
		let title: string
		const currentMessage = this.props.messageState.currentMessage
		const status = oc(currentMessage).richContent.status()
		switch (currentMessage.deliveryType) {
			case core.Message.DeliveryType.Email:
				title = 'New Email'
				break
			case core.Message.DeliveryType.Push:
				title = 'New Push Notification'
				break
			case core.Message.DeliveryType.Text:
				title = 'New Text Message'
				break
		}

		// Override title for template
		if (status === core.IShared.PublicationStatus.Template) {
			title = 'Edit Template'
		}

		if (this.props.subjectType) {
			title = (this.props.subjectType === core.Message.SubjectType.Event) ?
				`${title} - ${oc(this).props.eventState.currentEvent.name('')}` :
				`${title} - ${oc(this).props.postState.currentPost.title('')}`
		}
		return title
	}

	/**
	 * Gets the subject for a message.
	 */
	getMessageSubject = () => {
		const { subjectType } = this.props
		return (subjectType === core.Message.SubjectType.Event) ?
			oc(this).props.eventState.currentEvent.name('') :
			oc(this).props.postState.currentPost.title('')
	}

	/**
	 * Gets the rich content object out of the form state.
	 */
	getRichContent = (form: MessageFormState) => {
		const resource: core.Post.Model | core.Event.Model = this.state.subjectResource
		if (resource) {
			return {
				...resource.richContent,
				status: this.props.status,
			}
		}

		return {
			text: form.richContent.text,
			html: form.richContent.html,
			status: this.props.status,
		}
	}

	/**
	 * Builds a React Select Item array for selecting recipients in a message form.
	 */
	buildRecipientSelect = (): ReactSelectItem[] => {
		const users = oc(this).props.userState.users([])
		const usersForForm = users.map((user) => ({ ...userForForm(user), group: 'MEMBERS' }))

		const groups = oc(this).props.loggedInClub.userGroups([])
		const groupsForForm = groups.map((group) => ({ ...userGroupForForm(group), group: 'GROUPS' }))

		const events = oc(this).props.eventState.events([])
		const eventsForForm = events.filter((event) => {
			const hasReservations = oc(event).reservations.length(0) > 0
			return hasReservations
		}).map((event) => ({ label: event.name, value: `${event._id}`, group: 'EVENT RSVPs' }))

		// Create a list of recipients for the form
		return [...groupsForForm, ...eventsForForm, ...usersForForm]
	}

	/**
	 * Builds a React Select Item array for selecting sender in a message form.
	 */
	buildSenderSelect = (): ReactSelectItem[] => {
		return oc(this).props.admins([]).map(userForForm)
	}

	/**
	 * Gets the recipients object for us in message payload.
	 */
	getRecipientsForPayload = (form: MessageFormState): [string[], string[], string[]] => {
		const memberIDs: string[] = []
		const groupIDs: string[] = []
		const eventIDs: string[] = []

		// The recipient fields use a 'type' value to determine which set they belong in (group/individuals)
		// so we need to filter the form's recipients by 'type' first, before returning an array of values
		for (const recipient of oc(form).messageRecipients([])) {
			if (recipient.group === 'MEMBERS') { memberIDs.push(recipient.value) }
			if (recipient.group === 'GROUPS') { groupIDs.push(recipient.value) }
			if (recipient.group === 'EVENT RSVPs') { eventIDs.push(recipient.value) }
		}
		return [memberIDs, groupIDs, eventIDs]
	}

	/**
	 * Fetches the resource for the current form.
	 */
	getResource = (): core.Post.Model | core.Event.Model => {
		const { subjectType } = this.props
		if (subjectType === core.Message.SubjectType.Event) {
			return this.props.eventState.currentEvent
		}
		return this.props.postState.currentPost
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	userState: state.user,
	messageState: state.message,
	eventState: state.event,
	postState: state.post,
	loggedInClub: state.club.loggedInClub,
	admins: adminsSelector(state)
})

const mapDispatchToProps = {
	...AlertActions
}

const enhance = compose<React.ComponentType<ComponentProps>>(
	withRouter,
	connect<ConnectedState, ConnectedActions, ComponentProps>(mapStateToProps, mapDispatchToProps)
)

export default enhance(MessageCreationForm)
