// External Dependencies
import * as core from 'club-hub-core'
import { Dispatch, Action } from 'redux'
import to from 'await-to-js'

// Internal Dependencies

// Actions
import { AdminActions } from './admin'

// API Helpers
import { GET, POST, PUT, DELETE } from '../helpers/api'
import { QueryOptions, GeneralMap } from '../helpers/interface'

// Actions
export enum TypeKeys {
	FETCHED_CALENDARS = 'FETCHED_CALENDARS',
	CREATED_CALENDAR = 'CREATED_CALENDAR',
	UPDATED_CALENDAR = 'UPDATED_CALENDAR',
	DELETED_CALENDAR = 'DELETED_CALENDAR',
	FETCHED_CALENDAR_GROUPS = 'FETCHED_CALENDAR_GROUPS',
	CREATED_CALENDAR_RESERVATION_SETTING = 'CREATED_CALENDAR_RESERVATION_SETTING',
	UPDATED_CALENDAR_RESERVATION_SETTING = 'UPDATED_CALENDAR_RESERVATION_SETTING',
	DELETED_CALENDAR_RESERVATION_SETTING = 'DELETED_CALENDAR_RESERVATION_SETTING',
	SET_CURRENT_CALENDAR = 'SET_CURRENT_CALENDAR',
}

export type ActionTypes =
	| FetchedCalendarsAction
	| CreatedCalendarAction
	| UpdatedCalendarAction
	| DeletedCalendarAction
	| FetchedCalendarGroupsAction
	| CreatedCalendarReservationSettingAction
	| UpdatedCalendarReservationSettingAction
	| DeletedCalendarReservationSettingAction
	| SetCurrentCalendar

export interface FetchedCalendarsAction extends Action {
	type: TypeKeys.FETCHED_CALENDARS,
	calendars: core.Calendar.Model[],
	count: number
}

export interface CreatedCalendarAction extends Action {
	type: TypeKeys.CREATED_CALENDAR,
	calendar: core.Calendar.Model
}

export interface UpdatedCalendarAction extends Action {
	type: TypeKeys.UPDATED_CALENDAR,
	calendar: core.Calendar.Model
}

export interface DeletedCalendarAction extends Action {
	type: TypeKeys.DELETED_CALENDAR,
	calendarID: string,
}

export interface FetchedCalendarGroupsAction extends Action {
	type: TypeKeys.FETCHED_CALENDAR_GROUPS,
	groups: core.Calendar.Group[],
}

export interface CreatedCalendarReservationSettingAction extends Action {
	type: TypeKeys.CREATED_CALENDAR_RESERVATION_SETTING,
	calendar: core.Calendar.Model,
}

export interface UpdatedCalendarReservationSettingAction extends Action {
	type: TypeKeys.UPDATED_CALENDAR_RESERVATION_SETTING,
	calendar: core.Calendar.Model,
}

export interface DeletedCalendarReservationSettingAction extends Action {
	type: TypeKeys.DELETED_CALENDAR_RESERVATION_SETTING,
	calendar: core.Calendar.Model
}

export interface SetCurrentCalendar extends Action {
	type: TypeKeys.SET_CURRENT_CALENDAR,
	calendar: core.Calendar.Model
	group: core.Calendar.Group
}

// -----------------------------------------------------------------------------
// Set Current Calendar
// -----------------------------------------------------------------------------

const setCurrentCalendar = (calendar: core.Calendar.Model, group: core.Calendar.Group) => async (dispatch: Dispatch<SetCurrentCalendar>) => {
	dispatch({
		type: TypeKeys.SET_CURRENT_CALENDAR,
		calendar: calendar,
		group: group
	})
}

// -----------------------------------------------------------------------------
// Fetch Calendars
// -----------------------------------------------------------------------------

const fetchCalendars = (queryParams: QueryOptions = {}) => async (dispatch: Dispatch<FetchedCalendarsAction>) => {
	const [err, res] = await to<GeneralMap<core.Calendar.Model[] | number>>(GET('/calendars', queryParams))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to fetch Calendars with error: ${err}`)
		throw err
	}

	dispatch(fetchedCalendars(res))
}

export const fetchedCalendars = (calendarRes: GeneralMap<core.Calendar.Model[] | number>): FetchedCalendarsAction => {
	const action: FetchedCalendarsAction = {
		type: TypeKeys.FETCHED_CALENDARS,
		calendars: calendarRes.calendars as core.Calendar.Model[],
		count: calendarRes.count as number,
	}
	return action
}

// -----------------------------------------------------------------------------
// Create Calendar
// -----------------------------------------------------------------------------

const createCalendar = (payload: core.Calendar.Model) => async (dispatch: Dispatch<CreatedCalendarAction>) => {
	const [postErr, postRes] = await to<core.Calendar.Model>(POST('/admin/calendars', payload))
	if (postErr) {
		// tslint:disable-next-line
		console.error(`Failed to create Calendar with error: ${postErr}`)
		throw postErr
	}

	dispatch(createdCalendar(postRes))
}

const createdCalendar = (calendar: core.Calendar.Model): CreatedCalendarAction => {
	const action: CreatedCalendarAction = {
		type: TypeKeys.CREATED_CALENDAR,
		calendar
	}
	return action
}

// -----------------------------------------------------------------------------
// Update Calendar
// -----------------------------------------------------------------------------

const updateCalendar = (calendarID: string, payload: core.Calendar.Model) => async (dispatch: Dispatch<UpdatedCalendarAction>) => {
	const [putErr, putRes] = await to<core.Calendar.Model>(PUT(`/admin/calendars/${calendarID}`, payload))
	if (putErr) {
		// tslint:disable-next-line
		console.error(`Failed to update Calendar with error: ${putErr}`)
		throw putErr
	}
	dispatch(updatedCalendar(putRes))
}

const updatedCalendar = (calendar: core.Calendar.Model): UpdatedCalendarAction => {
	const action: UpdatedCalendarAction = {
		type: TypeKeys.UPDATED_CALENDAR,
		calendar
	}
	return action
}

// -----------------------------------------------------------------------------
// Delete Calendar
// -----------------------------------------------------------------------------

const deleteCalendar = (calendarID: string) => async (dispatch: Dispatch<DeletedCalendarAction>) => {
	const [deleteErr] = await to(DELETE(`/admin/calendars/${calendarID}`, {}))
	if (deleteErr) {
		// tslint:disable-next-line
		console.error(`Failed to delete Calendar with error: ${deleteErr}`)
		throw deleteErr
	}

	dispatch(deletedCalendar(calendarID))
}

const deletedCalendar = (calendarID: string): DeletedCalendarAction => {
	const action: DeletedCalendarAction = {
		type: TypeKeys.DELETED_CALENDAR,
		calendarID
	}
	return action
}

// -----------------------------------------------------------------------------
// Fetch Calendar Groups
// -----------------------------------------------------------------------------

export const fetchedCalendarGroups = (groups: core.Calendar.Group[]): FetchedCalendarGroupsAction => {
	const action: FetchedCalendarGroupsAction = {
		type: TypeKeys.FETCHED_CALENDAR_GROUPS,
		groups: groups
	}
	return action
}

// -----------------------------------------------------------------------------
// Create Calendar
// -----------------------------------------------------------------------------

const createCalendarReservationSetting = (calendarID: string, payload: core.Calendar.ReservationSetting) => async (dispatch: Dispatch<CreatedCalendarReservationSettingAction>) => {
	const [postErr, postRes] = await to<core.Calendar.Model>(POST(`/admin/calendars/${calendarID}/sub/reservationSettings`, payload))
	if (postErr) {
		// tslint:disable-next-line
		console.error(`Failed to create Calendar with error: ${postErr}`)
		throw postErr
	}

	dispatch(createdCalendarReservationSetting(postRes))
}

const createdCalendarReservationSetting = (calendar: core.Calendar.Model): CreatedCalendarReservationSettingAction => {
	const action: CreatedCalendarReservationSettingAction = {
		type: TypeKeys.CREATED_CALENDAR_RESERVATION_SETTING,
		calendar,
	}
	return action
}

// -----------------------------------------------------------------------------
// Update Calendar
// -----------------------------------------------------------------------------

const updateCalendarReservationSetting = (calendarID: string, payload: core.Calendar.ReservationSetting) => async (dispatch: Dispatch<UpdatedCalendarReservationSettingAction>) => {
	const [putErr, putRes] = await to<core.Calendar.Model>(PUT(`/admin/calendars/${calendarID}/sub/reservationSettings`, payload))
	if (putErr) {
		// tslint:disable-next-line
		console.error(`Failed to update Calendar with error: ${putErr}`)
		throw putErr
	}
	dispatch(updatedCalendarReservationSetting(putRes))
}

const updatedCalendarReservationSetting = (calendar: core.Calendar.Model): UpdatedCalendarReservationSettingAction => {
	const action: UpdatedCalendarReservationSettingAction = {
		type: TypeKeys.UPDATED_CALENDAR_RESERVATION_SETTING,
		calendar,
	}
	return action
}

// -----------------------------------------------------------------------------
// Delete Calendar
// -----------------------------------------------------------------------------

const deleteCalendarReservationSetting = (calendarID: string, reservationSettingID: string) => async (dispatch: Dispatch<DeletedCalendarReservationSettingAction>) => {
	const [deleteErr, deleteRes] = await to<core.Calendar.Model>(DELETE(`/admin/calendars/${calendarID}/sub/${reservationSettingID}`, {}))
	if (deleteErr) {
		// tslint:disable-next-line
		console.error(`Failed to delete Calendar with error: ${deleteErr}`)
		throw deleteErr
	}

	dispatch(deletedCalendarReservationSetting(deleteRes))
}

const deletedCalendarReservationSetting = (calendar: core.Calendar.Model): DeletedCalendarReservationSettingAction => {
	const action: DeletedCalendarReservationSettingAction = {
		type: TypeKeys.DELETED_CALENDAR_RESERVATION_SETTING,
		calendar
	}
	return action
}

export const CalendarActions = {
	fetchCalendars,
	createCalendar,
	updateCalendar,
	deleteCalendar,
	createCalendarReservationSetting,
	updateCalendarReservationSetting,
	deleteCalendarReservationSetting,
	setCurrentCalendar,
}
