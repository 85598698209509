// External Dependencies
import * as core from 'club-hub-core'
import { Dispatch, Action } from 'redux'
import to from 'await-to-js'

// Internal Dependencies

// API Helpers
import { GET, POST_FORM, PUT_FORM, DELETE, PUT, POST } from '../helpers/api'

// Helpers
import { GeneralMap } from '../helpers/interface'

// API Types
export interface CreateRestaurantPayload {
	menuTitle: string
	foodItems: File
}

export enum TypeKeys {
	FETCHED_RESTAURANTS = 'FETCHED_RESTAURANTS',
	FETCHED_RESTAURANT = 'FETCHED_RESTAURANT',
	CREATED_RESTAURANT = 'CREATED_RESTAURANT',
	UPDATED_RESTAURANT = 'UPDATED_RESTAURANT',
	DELETED_RESTAURANT = 'DELETED_RESTAURANT',
	CREATED_MENU = 'CREATED_MENU',
	UPDATED_MENU = 'UPDATED_MENU',
	DELETE_MENU = 'DELETED_MENU',
	CREATED_MENU_ITEM = 'CREATED_MENU_ITEM',
	UPDATED_MENU_ITEM = 'UPDATED_MENU_ITEM',
	DELETE_MENU_ITEM = 'DELETED_MENU_ITEM',
	CREATED_MENU_SECTION = 'CREATED_MENU_SECTION',
	UPDATED_MENU_SECTION = 'UPDATED_MENU_SECTION',
	DELETED_MENU_SECTION = 'DELETED_MENU_SECTION'
}

export type ActionTypes =
	| FetchedRestaurantsAction
	| FetchedRestaurantAction
	| CreatedRestaurantAction
	| UpdatedRestaurantAction
	| DeletedRestaurantAction
	| CreatedMenuAction
	| UpdatedMenuAction
	| DeletedMenuAction
	| CreatedMenuItemAction
	| UpdatedMenuItemAction
	| DeletedMenuItemAction
	| CreatedMenuSectionAction
	| UpdatedMenuSectionAction
	| DeletedMenuSectionAction

export interface FetchedRestaurantsAction extends Action {
	type: TypeKeys.FETCHED_RESTAURANTS,
	restaurants: core.Restaurant.Model[],
	count: number
}

export interface FetchedRestaurantAction extends Action {
	type: TypeKeys.FETCHED_RESTAURANT,
	restaurant: core.Restaurant.Model,
}

export interface CreatedRestaurantAction extends Action {
	type: TypeKeys.CREATED_RESTAURANT,
	restaurant: core.Restaurant.Model,
}

export interface UpdatedRestaurantAction extends Action {
	type: TypeKeys.UPDATED_RESTAURANT,
	restaurant: core.Restaurant.Model,
}

export interface DeletedRestaurantAction extends Action {
	type: TypeKeys.DELETED_RESTAURANT,
	restaurantID: string
}

export interface CreatedMenuAction extends Action {
	type: TypeKeys.CREATED_MENU,
	restaurant: core.Restaurant.Model,
}

export interface UpdatedMenuAction extends Action {
	type: TypeKeys.UPDATED_MENU,
	restaurant: core.Restaurant.Model,
}

export interface DeletedMenuAction extends Action {
	type: TypeKeys.DELETE_MENU,
	restaurant: core.Restaurant.Model,
}

export interface CreatedMenuItemAction extends Action {
	type: TypeKeys.CREATED_MENU_ITEM,
	restaurant: core.Restaurant.Model,
}

export interface UpdatedMenuItemAction extends Action {
	type: TypeKeys.UPDATED_MENU_ITEM,
	restaurant: core.Restaurant.Model,
}

export interface DeletedMenuItemAction extends Action {
	type: TypeKeys.DELETE_MENU_ITEM,
	restaurant: core.Restaurant.Model,
}

export interface CreatedMenuSectionAction extends Action {
	type: TypeKeys.CREATED_MENU_SECTION,
	restaurant: core.Restaurant.Model,
}

export interface UpdatedMenuSectionAction extends Action {
	type: TypeKeys.UPDATED_MENU_SECTION,
	restaurant: core.Restaurant.Model,
}

export interface DeletedMenuSectionAction extends Action {
	type: TypeKeys.DELETED_MENU_SECTION,
	restaurant: core.Restaurant.Model,
}

// -----------------------------------------------------------------------------
// Fetch Restaurants
// -----------------------------------------------------------------------------

/**
 * Fetches the Restaurants for a Club.
 */
const fetchRestaurants = (clubID: string) => async (dispatch: Dispatch<FetchedRestaurantsAction>) => {
	const [err, res] = await to<GeneralMap<core.Restaurant.Model[] | number>>(GET('/restaurants', {}))
	if (err) {
		throw err
	}

	await dispatch(fetchedRestaurants(res))
}

/**
 * Builds a `FetchedRestaurantsAction` upon successfully fetching the Restaurant for a Club.
 */
export const fetchedRestaurants = (restaurantRes: GeneralMap<core.Restaurant.Model[] | number>): FetchedRestaurantsAction => {
	const action: FetchedRestaurantsAction = {
		type: TypeKeys.FETCHED_RESTAURANTS,
		restaurants: restaurantRes.restaurants as core.Restaurant.Model[],
		count: restaurantRes.count as number
	}
	return action
}

// -----------------------------------------------------------------------------
// Fetch Restaurant
// -----------------------------------------------------------------------------

/**
 * Fetches a Restaurant by ID.
 */
const fetchRestaurant = (restaurantID: string) => async (dispatch: Dispatch<FetchedRestaurantAction>) => {
	const [err, res] = await to(GET(`/restaurants/${restaurantID}`, {}))
	if (err) {
		throw err
	}

	await dispatch(fetchedRestaurant(res))
}

const fetchedRestaurant = (res: core.Restaurant.Model): FetchedRestaurantAction => {
	const action: FetchedRestaurantAction = {
		type: TypeKeys.FETCHED_RESTAURANT,
		restaurant: res,
	}
	return action
}

// -----------------------------------------------------------------------------
// Create Restaurant
// -----------------------------------------------------------------------------

const createRestaurant = (payload: any) => async (dispatch: Dispatch<CreatedRestaurantAction>) => {
	const [err, res] = await to<core.Restaurant.Model>(POST_FORM(`/admin/restaurants`, payload))
	if (err) {
		throw err
	}

	await dispatch(createdRestaurant(res))
}

const createdRestaurant = (res: core.Restaurant.Model): CreatedRestaurantAction => {
	const action: CreatedRestaurantAction = {
		type: TypeKeys.CREATED_RESTAURANT,
		restaurant: res
	}
	return action
}

// -----------------------------------------------------------------------------
// Update Restaurant
// -----------------------------------------------------------------------------

const updateRestaurant = (restaurantID: string, payload: any) => async (dispatch: Dispatch<UpdatedRestaurantAction>) => {
	const [err, res] = await to<core.Restaurant.Model>(PUT_FORM(`/admin/restaurants/${restaurantID}`, payload))
	if (err) {
		throw err
	}

	await dispatch(updatedRestaurant(res))
}

const updatedRestaurant = (res: core.Restaurant.Model): UpdatedRestaurantAction => {
	const action: UpdatedRestaurantAction = {
		type: TypeKeys.UPDATED_RESTAURANT,
		restaurant: res
	}
	return action
}

// -----------------------------------------------------------------------------
// Delete Restaurant
// -----------------------------------------------------------------------------

const deleteRestaurant = (restaurantID: string) => async (dispatch: Dispatch<DeletedRestaurantAction>) => {
	const [err] = await to(DELETE(`/admin/restaurants/${restaurantID}`, {}))
	if (err) {
		throw err
	}

	await dispatch(deletedRestaurant(restaurantID))
}

const deletedRestaurant = (restaurantID: string): DeletedRestaurantAction => {
	const action: DeletedRestaurantAction = {
		type: TypeKeys.DELETED_RESTAURANT,
		restaurantID
	}
	return action
}

// -----------------------------------------------------------------------------
// Create Menu
// -----------------------------------------------------------------------------

const createMenu = (restaurantID: string, payload: core.Restaurant.Menu) => async (dispatch: Dispatch<CreatedMenuAction>) => {
	const path = `/admin/restaurants/${restaurantID}/sub/menus`
	const [err, res] = await to<core.Restaurant.Model>(POST(path, payload))
	handleError(err)

	await dispatch(createdMenu(res))
}

const createdMenu = (res: core.Restaurant.Model): CreatedMenuAction => {
	return { type: TypeKeys.CREATED_MENU, restaurant: res }
}

// -----------------------------------------------------------------------------
// Update Menu Items
// -----------------------------------------------------------------------------

const updateMenu = (restaurantID: string, payload: core.Restaurant.Menu) => async (dispatch: Dispatch<UpdatedMenuAction>) => {
	const path = `/admin/restaurants/${restaurantID}/sub/menus`
	const [err, res] = await to<core.Restaurant.Model>(PUT(path, payload))
	handleError(err)

	await dispatch(updatedMenu(res))
}

const updatedMenu = (res: core.Restaurant.Model): UpdatedMenuAction => {
	return { type: TypeKeys.UPDATED_MENU, restaurant: res }
}

// -----------------------------------------------------------------------------
// Delete Menu Items
// -----------------------------------------------------------------------------

const deleteMenu = (restaurantID: string, menuID: string) => async (dispatch: Dispatch<DeletedMenuAction>) => {
	const path = `/admin/restaurants/${restaurantID}/sub/menus/${menuID}`
	const [err, res] = await to<core.Restaurant.Model>(DELETE(path, null))
	handleError(err)

	await dispatch(deletedMenu(res))
}

const deletedMenu = (res: core.Restaurant.Model): DeletedMenuAction => {
	return { type: TypeKeys.DELETE_MENU, restaurant: res }
}

// -----------------------------------------------------------------------------
// Create Menu Items
// -----------------------------------------------------------------------------

const createMenuItem = (restaurantID: string, payload: core.Restaurant.MenuItem) => async (dispatch: Dispatch<CreatedMenuItemAction>) => {
	const path = `/admin/restaurants/${restaurantID}/sub/menuItems`
	const [err, res] = await to<core.Restaurant.Model>(POST(path, payload))
	handleError(err)

	await dispatch(createdMenuItem(res))
}

const createdMenuItem = (res: core.Restaurant.Model): CreatedMenuItemAction => {
	return { type: TypeKeys.CREATED_MENU_ITEM, restaurant: res }
}

// -----------------------------------------------------------------------------
// Update Menu Items
// -----------------------------------------------------------------------------

const updateMenuItem = (restaurantID: string, payload: core.Restaurant.MenuItem) => async (dispatch: Dispatch<UpdatedMenuItemAction>) => {
	const path = `/admin/restaurants/${restaurantID}/sub/menuItems`
	const [err, res] = await to<core.Restaurant.Model>(PUT(path, payload))
	handleError(err)

	await dispatch(updatedMenuItem(res))
}

const updatedMenuItem = (res: core.Restaurant.Model): UpdatedMenuItemAction => {
	return { type: TypeKeys.UPDATED_MENU_ITEM, restaurant: res }
}

// -----------------------------------------------------------------------------
// Delete Menu Items
// -----------------------------------------------------------------------------

const deleteMenuItem = (restaurantID: string, itemID: string) => async (dispatch: Dispatch<DeletedMenuItemAction>) => {
	const path = `/admin/restaurants/${restaurantID}/sub/menuItems/${itemID}`
	const [err, res] = await to<core.Restaurant.Model>(DELETE(path, null))
	handleError(err)

	await dispatch(deletedMenuItem(res))
}

const deletedMenuItem = (res: core.Restaurant.Model): DeletedMenuItemAction => {
	return { type: TypeKeys.DELETE_MENU_ITEM, restaurant: res }
}

// -----------------------------------------------------------------------------
// Create Menu Section
// -----------------------------------------------------------------------------

const createMenuSection = (restaurantID: string, payload: core.Restaurant.MenuSection) => async (dispatch: Dispatch<CreatedMenuSectionAction>) => {
	const path = `/admin/restaurants/${restaurantID}/sub/menuSections`
	const [err, res] = await to<core.Restaurant.Model>(POST(path, payload))
	handleError(err)

	await dispatch(createdMenuSection(res))
}

const createdMenuSection = (res: core.Restaurant.Model): CreatedMenuSectionAction => {
	return { type: TypeKeys.CREATED_MENU_SECTION, restaurant: res }
}

// -----------------------------------------------------------------------------
// Update Menu Section
// -----------------------------------------------------------------------------

const updateMenuSection = (restaurantID: string, payload: core.Restaurant.MenuSection | core.Restaurant.MenuSection[]) => async (dispatch: Dispatch<UpdatedMenuSectionAction>) => {
	const path = `/admin/restaurants/${restaurantID}/sub/menuSections`
	const [err, res] = await to<core.Restaurant.Model>(PUT(path, payload))
	handleError(err)

	await dispatch(updatedMenuSection(res))
}

const updatedMenuSection = (res: core.Restaurant.Model): UpdatedMenuSectionAction => {
	return { type: TypeKeys.UPDATED_MENU_SECTION, restaurant: res }
}

// -----------------------------------------------------------------------------
// Delete Menu Section
// -----------------------------------------------------------------------------

const deleteMenuSection = (restaurantID: string, sectionID: string) => async (dispatch: Dispatch<DeletedMenuSectionAction>) => {
	const path = `/admin/restaurants/${restaurantID}/sub/menuSections/${sectionID}`
	const [err, res] = await to<core.Restaurant.Model>(DELETE(path, null))
	handleError(err)

	await dispatch(deletedMenuSection(res))
}

const deletedMenuSection = (res: core.Restaurant.Model): DeletedMenuSectionAction => {
	return { type: TypeKeys.DELETED_MENU_SECTION, restaurant: res }
}

// -----------------------------------------------------------------------------
// Error Handling
// -----------------------------------------------------------------------------

const handleError = (err: Error) => {
	if (err !== null) {
		// tslint:disable-next-line
		console.error(`Failed to refresh state with error: ${err}`)
		throw err
	}
}

export const RestaurantActions = {
	fetchRestaurants,
	fetchRestaurant,
	createRestaurant,
	updateRestaurant,
	deleteRestaurant,
	createMenu,
	updateMenu,
	deleteMenu,
	createMenuItem,
	updateMenuItem,
	deleteMenuItem,
	createMenuSection,
	updateMenuSection,
	deleteMenuSection,
}
