
import * as React from 'react'
import * as core from 'club-hub-core'
import * as _ from 'underscore'
// tslint:disable-next-line
const FullStory = require('react-fullstory')

// Fullstory Key
const FULL_STORY_APP_ID = 'MHRRP'

interface FullStoryProps {
	user: core.User.Model
	isMeshAdmin: boolean
}

/**
 * Returns the FullStory component
 */
const FullStoryComponent: React.FC<FullStoryProps> = (props: FullStoryProps) => {
	if (!props.isMeshAdmin) {
		// const appID = FULL_STORY_APP_ID
		// const fullStory = <FullStory.default org={appID} />
		// const whiteListUser = _.pick(props.user, 'email', 'firstName', 'lastName', 'clubID', '_id')
		// FullStory.FullStoryAPI('identify', props.user._id, whiteListUser)
		// return (
		// 	<div className='full-story-container'>
		// 		{fullStory}
		// 	</div>
		// )
	}

	return null
}

export default FullStoryComponent
