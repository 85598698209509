// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import * as core from 'club-hub-core'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Table, Collapse, Select, Icon } from 'antd'
import { ExpandIconProps } from 'antd/lib/table'
import * as moment from 'moment'
import { sortBy } from 'underscore'
import { RouteComponentProps } from 'react-router'
import { oc } from 'ts-optchain'
import to from 'await-to-js'
import { capitalizeString, formatPrice } from '../../helpers/formatting'
const { Panel } = Collapse
const { Option } = Select

// Actions
import { StatementActions, AlertActions } from '../../actions/index'

// Components
import Card from '../Shared/Cards/Card'
import ButtonRow from '../Shared/ButtonRow'
import BackHeader from '../Shared/BackHeader'
import DimmedLoader from '../Shared/DimmedLoader'
import ErrorComponent from '../Shared/ErrorComponent'

// State
import { RootReducerState } from '../../reducers'

// Child Components
import DetailRow, { DetailRowItem } from '../Shared/ReservationDetails/DetailRow'

// Helpers
import * as Constants from '../../constants'
import { isNullOrUndefined } from 'util'

type ConnectedActions = typeof mapDispatchToProps
type ConnectedState = ReturnType<typeof mapStateToProps>

const initialState = {
	statementRows: [] as StatementDetailRowData[],
	subTotal: '',
	tax: '',
	serviceCharge: '',
	total: '',
	error: false,
	loading: false,
	statement: undefined as core.Statement.Model
}

interface StatementDetailRowData {
	key: string
	canExpand: boolean
	date: string
	shortDescription: string
	description: string
	amount: string
	serviceCharge: string
	tax: string
	total: string,
	details?: core.Ticket.Model
}

interface StatementComponentProps {
	handleBack: () => void,
	statement: core.Statement.Model,
}

type Props = StatementComponentProps & RouteComponentProps & ConnectedActions & ConnectedState
type State = typeof initialState

class StatementComponent extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
	}

	componentDidMount = () => {
		const parsedQuery = queryString.parse(this.props.location.search)
		const userID = parsedQuery.userID
		if (!userID) {
			return this.setState({ error: true })
		}
		this.fetchStatements(userID)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	setCurrentStatement(statement: core.Statement.Model) {
		const rowData: StatementDetailRowData[] = []
		const sortedDetails = sortBy(statement.details, (detail: core.Statement.Detail) => {
			return detail.transDate
		})

		let runningSubTotal = 0
		let runningTax = 0
		let runningSvc = 0
		let runningTotal = 0

		for (const detail of sortedDetails) {
			const dateTime = new Date(detail.transDate)
			const dateTimeStr = moment(dateTime).format('LL')
			runningSubTotal += detail.baseAmount
			runningTax += detail.taxTotal
			runningSvc += detail.serviceChargeTotal
			runningTotal += detail.extension

			const maxTableDescLength = 45
			let shortDescription = detail.description
			if (shortDescription.length > maxTableDescLength) {
				shortDescription = `${shortDescription.slice(0, maxTableDescLength)}...`
			}

			rowData.push({
				key: `${detail._id}`,
				canExpand: !isNullOrUndefined(detail.ticket),
				date: dateTimeStr,
				shortDescription,
				description: detail.description,
				amount: oc(detail).baseAmount(0).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
				serviceCharge: oc(detail).serviceChargeTotal(0).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
				tax: oc(detail).taxTotal(0).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
				total: oc(detail).extension(0).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
				details: detail.ticket as any
			})
		}

		this.setState({
			subTotal: runningSubTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
			tax: runningTax.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
			serviceCharge: runningSvc.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
			total: runningTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
			statementRows: rowData,
			statement: statement
		})
	}

	// ----------------------------------------------------------------------------------
	// Form Handlers
	// ----------------------------------------------------------------------------------

	handleStatementChanged = (selectedVal: any) => {
		const key = selectedVal.key
		const statement = this.props.statementState.statements.find((st: core.Statement.Model) => st._id === key)
		this.setCurrentStatement(statement)
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	handleBack = () => {
		this.props.history.goBack()
	}

	handlePayNow = () => {
		// Todo: buildout billing :)
	}

	// ----------------------------------------------------------------------------------
	// Network Requests
	// ----------------------------------------------------------------------------------

	fetchStatements = async (userID: string) => {
		const [err] = await to(this.props.fetchStatements(userID) as any)
		if (err) {
			this.props.fireFlashMessage(`Could not fetch your statements at this time. Please try again.`, Constants.FlashType.DANGER)
			this.setState({ loading: false, error: true })
			return
		}

		if (this.props.statementState.statements.length) {
			this.setCurrentStatement(this.props.statementState.statements[0])
		}
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	/**
	 * Builds the reservation card which includes a form.
	 * The logic for the form is held in the form component that matches the reservation type.
	 */
	buildCard = (content: JSX.Element, footer: JSX.Element) => {
		return (
			<Card
				title={`My Statements`}
				headerClass={'d-flex'}
				bodyClass={'card-body'}
				content={content}
				footer={footer}
				hideHeader={true}
			/>
		)
	}

	// CARD HEADER
	buildCardHeaderInfo = (): JSX.Element => {
		if (!this.state.statement) {
			return null
		}

		const total = formatPrice(this.state.statement.balanceTotal)
		const arPeriod = moment(this.state.statement.arPeriodDesc)
		const statementSummary = (
			<RS.Col sm={6} className='header-col align-items-end flex-column d-none d-sm-flex'>
				<div className='amount-due'>
					<p className='card-header-title'>{'Amount Due'}</p>
					<p className='value'>{total}</p>
				</div>

				<div className='statement-date'>
					<p className='card-header-title'>{'Statement Period'}</p>
					<p className='value'>{arPeriod.format('MMMM, YYYY')}</p>
				</div>
			</RS.Col>
		)
		return statementSummary
	}

	buildStatementSelect = (): JSX.Element => {
		const options: JSX.Element[] = []
		const statements = this.props.statementState.statements
		for (const statement of statements) {
			options.push((
				<Option key={`${statement._id}`} value={`${statement._id}`}>{statement.arPeriodDesc}</Option>
			))
		}

		const currentStatement = oc(this).state.statement({})
		const statementSelect = (
			<RS.Col xs={12} sm={6} className='header-col d-flex select-and-pay'>
				<p className='select-statement-header'>Select statement</p>
				<Select value={{ key: `${currentStatement._id}` }} className={'statement-select'} onChange={this.handleStatementChanged} labelInValue={true}>
					{options}
				</Select>
				<div className='amount-due'>
					<button
						className='btn btn-primary d-none d-sm-block'
						onClick={this.handlePayNow}
					>
						{'Submit Payment'}
					</button>
				</div>

			</RS.Col>
		)

		return statementSelect
	}

	buildCardHeader = () => {
		const statementInfo = this.buildCardHeaderInfo()
		const statementSelect = this.buildStatementSelect()

		return (
			<div className='card-header'>
				{statementSelect}
				{statementInfo}
			</div>
		)
	}

	// CARD BODY

	buildInvoiceHeader = () => {
		// Need to change to the statment address
		const address = this.props.userState.loggedInUser.addresses[0]
		const fullName = `${this.props.userState.loggedInUser.firstName} ${this.props.userState.loggedInUser.lastName}`
		const memberInfo = (
			<RS.Col xs={4} className='header-col d-flex align-items-start flex-column member-info'>
				<div className='mt-auto header-line d-flex justify-content-between'>
					<span className='name'>{fullName}</span>
				</div>

				<div className='mt-auto header-line d-flex justify-content-between'>
					<span className='address-1'>{address.address1}</span>
				</div>
				<div className='mt-auto header-line d-flex justify-content-between'>
					<span className='address-2'>{`${address.city}, ${address.state} ${address.zip}`}</span>
				</div>
				<div className='mt-auto header-line d-flex justify-content-between'>
					<span className='contact'>{'360-386-2926'}</span>
				</div>
			</RS.Col>
		)

		const logo = (
			<RS.Col xs={4} className='header-image-col d-flex justify-content-center'>
				<img src={'https://clubhubs3.s3-us-west-2.amazonaws.com/assets/wingpoint/wingpoint-logo-sq.png'} className='img-fluid' alt='Responsive image' />
			</RS.Col>
		)

		const clubAddress = this.props.loggedInClub.locations[0]
		const clubInfo = (
			<RS.Col xs={4} className='header-col d-flex align-items-end flex-column club-info'>
				<div className='mt-auto header-line d-flex justify-content-between'>
					<span className='name'>{this.props.loggedInClub.name}</span>
				</div>

				<div className='mt-auto header-line d-flex justify-content-between'>
					<span className='address-1'>{clubAddress.address1}</span>
				</div>
				<div className='mt-auto header-line d-flex justify-content-between'>
					<span className='address-2'>{`${clubAddress.city}, ${clubAddress.state} ${clubAddress.zip}`}</span>
				</div>
				<div className='mt-auto header-line d-flex justify-content-between'>
					<span className='contact'>{clubAddress.phone}</span>
				</div>
			</RS.Col>
		)

		return (
			<RS.Row className='statement-header-container d-none d-sm-flex'>
				{memberInfo}
				{logo}
				{clubInfo}
			</RS.Row>
		)
	}

	// CARD FOOTER
	buildSubTotal = () => {
		return (
			<RS.Row className='subtotal-container'>
				<RS.Col xs={12} className='subtotal-col d-flex align-items-end flex-column'>
					<div className='subtotal-line d-flex justify-content-between'>
						<span className='title'>{'SubTotal'}</span>
						<span className='value'>{this.state.subTotal}</span>
					</div>

					<div className='subtotal-line d-flex justify-content-between'>
						<span className='title'>{'Service Charge'}</span>
						<span className='value'>{this.state.serviceCharge}</span>
					</div>
					<div className='subtotal-line d-flex justify-content-between'>
						<span className='title'>{'Tax'}</span>
						<span className='value'>{this.state.tax}</span>
					</div>
					<div className='subtotal-line d-flex justify-content-between'>
						<span className='title'>{'Total'}</span>
						<span className='value'>{this.state.total}</span>
					</div>
				</RS.Col>
			</RS.Row>
		)
	}

	buildNotes = () => {
		if (!this.state.statement) {
			return null
		}

		const footerNotes: React.ReactNode[] = []
		if (this.state.statement.minimumMessage) {
			footerNotes.push((
				<div className='footernote-line'>
					<p className='title'>{'Food Minimum'}</p>
					<p className='value'>{this.state.statement.minimumMessage}</p>
				</div>
			))
		}

		if (this.state.statement.headerMessage1) {
			footerNotes.push((
				<div className='footernote-line'>
					<p className='title'>{'Club Note'}</p>
					<p className='value'>{this.state.statement.headerMessage1}</p>
				</div>
			))
		}
		if (this.state.statement.headerMessage2) {
			footerNotes.push((
				<div className='footernote-line'>
					<p className='title'>{'Club Note'}</p>
					<p className='value'>{this.state.statement.headerMessage2}</p>
				</div>
			))
		}
		if (this.state.statement.headerMessage3) {
			footerNotes.push((
				<div className='footernote-line'>
					<p className='title'>{'Club Note'}</p>
					<p className='value'>{this.state.statement.headerMessage3}</p>
				</div>
			))
		}
		if (this.state.statement.lateFeeMessage1) {
			footerNotes.push((
				<div className='footernote-line'>
					<p className='title'>{'Late Fee Message'}</p>
					<p className='value'>{this.state.statement.lateFeeMessage1}</p>
				</div>
			))
		}
		if (this.state.statement.lateFeeMessage2) {
			footerNotes.push((
				<div className='footernote-line'>
					<p className='title'>{'Late Fee Message'}</p>
					<p className='value'>{this.state.statement.lateFeeMessage2}</p>
				</div>
			))
		}
		if (this.state.statement.bodyPreMessage) {
			footerNotes.push((
				<div className='footernote-line'>
					<p className='title'>{'Club note'}</p>
					<p className='value'>{this.state.statement.bodyPreMessage}</p>
				</div>
			))
		}
		if (this.state.statement.bodySubMessage) {
			footerNotes.push((
				<div className='footernote-line'>
					<p className='title'>{'Club note'}</p>
					<p className='value'>{this.state.statement.bodySubMessage}</p>
				</div>
			))
		}
		if (this.state.statement.footerMessage) {
			footerNotes.push((
				<div className='footernote-line'>
					<p className='title'>{'Club note'}</p>
					<p className='value'>{this.state.statement.footerMessage}</p>
				</div>
			))
		}

		return (
			<Collapse bordered={false}>
				<Panel header='Statement Notes' key='1'>
					<RS.Row className='footernote-container' key='1'>
						<RS.Col xs={12} className='footernote-col'>
							{footerNotes}
						</RS.Col>
					</RS.Row>
				</Panel>
			</Collapse>
		)
	}

	buildButtonRow = () => {
		return (
			<ButtonRow
				disableButton={null}
				submitButtonName={'Pay Bill'}
				submitButtonHandler={null}
				cancelButtonName={'Go Back'}
				cancelButtonHandler={null}
			/>
		)
	}

	buildColumns = () => {
		const columns = [
			{ title: 'Date', dataIndex: 'date', key: 'date' },
			{ title: 'Description', dataIndex: 'description', key: 'description' },
			{ title: 'Amount', dataIndex: 'amount', key: 'amount' },
			{ title: 'Svc', dataIndex: 'serviceCharge', key: 'serviceCharge' },
			{ title: 'Tax', dataIndex: 'tax', key: 'tax' },
			{ title: 'Total', dataIndex: 'total', key: 'total' },
		]
		return columns
	}

	expandIcon = ({ expandable, record, expanded, onExpand }: ExpandIconProps<StatementDetailRowData>) => {
		if (!expandable || record.canExpand === false) { return null }

		return (
			<a onClick={(e: any) => onExpand(record, e)}>
				{expanded ? <Icon type='minus-square' /> : <Icon type='plus-square' />}
			</a>
		)
	}

	expanedRowRender = (record: StatementDetailRowData, index: number, indent: number, expanded: boolean): React.ReactNode => {
		if (!record.details) {
			return null
		}
		const ticket = record.details
		const ticketDeets = ticket.details

		const detailColumns = [
			{ title: 'Item', dataIndex: 'itemDesc', key: 'itemDesc' },
			{ title: 'Qty', dataIndex: 'quantity', key: 'quantity' },
			{ title: 'Price', dataIndex: 'price', key: 'price' },
		]

		const data = []
		for (let i = 0; i < ticketDeets.length; ++i) {
			const ticketDeet = ticketDeets[i]
			const totalPrice = ticketDeet.quantity * (ticketDeet.price + ticketDeet.taxTotal + ticketDeet.gratuityTotal)
			const totalPriceStr = totalPrice.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
			data.push({
				key: i,
				itemDesc: ticketDeet.itemDesc,
				quantity: ticketDeet.quantity,
				price: totalPriceStr,
			})
		}
		return <Table columns={detailColumns} dataSource={data} pagination={false} />
	}

	rowClassName = (record: StatementDetailRowData, index: number): string => {
		if (record.canExpand) {
			return 'expandable'
		}
		return 'not-expandable'
	}

	buildTable = () => {
		const columns = this.buildColumns()
		const rows = this.state.statementRows
		return (
			<Table
				columns={columns}
				loading={this.state.statement === undefined}
				expandIcon={this.expandIcon}
				expandedRowRender={this.expanedRowRender}
				dataSource={rows}
				pagination={false}
				expandRowByClick={true}
				rowClassName={this.rowClassName}
			/>
		)
	}

	// ----------------------------------------------------------------------------------
	// General Content Builders
	// ----------------------------------------------------------------------------------

	render() {
		if (this.state.loading) { return <DimmedLoader component={null} isLoading={true} /> }
		if (this.state.error) { return <ErrorComponent club={this.props.loggedInClub} isAdmin={this.props.userState.isAdmin} /> }

		const cardContent = (
			<div>
				{this.buildCardHeader()}
				{this.buildInvoiceHeader()}
				{this.buildTable()}
				<div className='statement-footer'>
					{this.buildSubTotal()}
					{this.buildNotes()}
				</div>
			</div>
		)

		const cardFooter = this.buildButtonRow()

		return (
			<RS.Col sm={12} md={8} className='statement-container'>
				<BackHeader backFunc={this.handleBack} backTitle={'Profile'} />
				{this.buildCard(cardContent, cardFooter)}
			</RS.Col>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	userState: state.user,
	loggedInClub: state.club.loggedInClub,
	statementState: state.statement,
})

const mapDispatchToProps = {
	...AlertActions,
	...StatementActions
}

const enhance = compose<React.ComponentType<StatementComponentProps>>(
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(StatementComponent)
