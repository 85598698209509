// External Dependencies
import * as core from 'club-hub-core'
import { Dispatch, Action } from 'redux'
import to from 'await-to-js'

// Internal Dependencies
import { GeneralMap, QueryOptions } from '../helpers/interface'

// API Helpers
import { GET, POST, POST_FORM, PUT_FORM, DELETE, PUT } from '../helpers/api'

// Actions
export enum TypeKeys {
	CREATED_LOTTERY = 'CREATED_LOTTERY',
	UPDATED_LOTTERY = 'UPDATED_LOTTERY',
	CANCELED_LOTTERY = 'CANCELED_LOTTERY',
}

export type ActionTypes =
	| CreatedLotteryAction
	| UpdatedLotteryAction
	| CanceledLotteryAction

export interface UpdatedLotteryAction extends Action {
	type: TypeKeys.UPDATED_LOTTERY,
	event: core.Event.Model,
}

export interface CanceledLotteryAction extends Action {
	type: TypeKeys.CANCELED_LOTTERY,
}

// -----------------------------------------------------------------------------
// Create Event RSVP
// -----------------------------------------------------------------------------

export interface CreatedLotteryAction extends Action {
	type: TypeKeys.CREATED_LOTTERY,
	event: core.Event.Model,
}

const createLottery = (lottery: core.Lottery.Model, reservation: core.Event.Reservation, calendarID: string, startTime: Date) => async (dispatch: Dispatch<CreatedLotteryAction>) => {
	const payload = { lottery, reservation, startTime }
	const [err, eventRes] = await to<core.Event.Model>(POST(`/calendars/${calendarID}/lotteries`, payload))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to create RSVP for Event with error: ${err}`)
		throw err
	}

	dispatch(createdLottery(eventRes))
}

const createdLottery = (createdEventWithLottery: core.Event.Model): CreatedLotteryAction => {
	const action: CreatedLotteryAction = {
		type: TypeKeys.CREATED_LOTTERY,
		event: createdEventWithLottery,
	}
	return action
}

// -----------------------------------------------------------------------------
// Update Lottery
// -----------------------------------------------------------------------------

export interface UpdatedLotteryAction extends Action {
	type: TypeKeys.UPDATED_LOTTERY,
	event: core.Event.Model,
}

const updateLottery = (lottery: core.Lottery.Model, calendarID: string) => async (dispatch: Dispatch<CreatedLotteryAction>) => {
	const [err, eventRes] = await to<core.Event.Model>(PUT(`/calendars/${calendarID}/lotteries/${lottery._id}`, lottery))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to create RSVP for Event with error: ${err}`)
		throw err
	}

	dispatch(updatedLottery(eventRes))
}

const updatedLottery = (createdEventWithLottery: core.Event.Model): CreatedLotteryAction => {
	const action: CreatedLotteryAction = {
		type: TypeKeys.CREATED_LOTTERY,
		event: createdEventWithLottery,
	}
	return action
}

export const LotteryActions = {
	createLottery,
	updateLottery
}
