// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as RS from 'reactstrap'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { pick, values, isEqual } from 'underscore'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies

// Tables
import { UserGroupTableColumns } from './table'

// Constants
import * as Constants from '../../constants'

// Helpers
import { setStateAsync } from '../../helpers/promise'
import { buildFuseSearch, FuseItem } from '../../helpers/fuse'
import { groupBadge } from '../../helpers/badge'

// Actions and Action Interface Imports
import { GroupActions, AlertActions, InvitationActions } from '../../actions/index'

// Reducer Interface Imports
import { RootReducerState } from '../../reducers'
import { usersByIDSelector } from '../../reducers/user'
import { clubUserGroupsSelector, clubUserGroupsByIDSelector } from '../../reducers/club'

// -----------------------------------------
// Component Type Declarations
// -----------------------------------------
import PageHeaderComponent, { PageHeaderSearchInput, PageHeaderInputType } from '../Shared/PageHeader'
import TableHeader from '../Shared/TableHeader'
import { ButtonType, HeaderButton } from '../Shared/ButtonGroup'
import TableComponent from '../Shared/Table'
import ErrorComponent from '../Shared/ErrorComponent'
import ModalComponent from '../Shared/Modal'
import { log } from 'util'
import { FuseOptions } from 'fuse.js'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	error: false,
	loading: false,
	pageIndex: 0,
	userGroupToDelete: null as string | null,
	userGroupToInvite: null as string | null,
	searchTerm: '',
}

type Props = RouteComponentProps & ConnectedState & ConnectedActions
type State = typeof initialState

/**
 * User Component
 */
class UserGroupComponent extends React.Component<Props, State> {
	private pageSize: number
	private fuseGroupSearch: Fuse<FuseItem, FuseOptions<FuseItem>>

	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
		this.pageSize = 20
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		this.buildFuseGroupSearch()
	}

	async componentDidUpdate(prevProps: Props) {
		const prevGroups = oc(prevProps).userGroupsByID({})
		const currentGroups = oc(this).props.userGroupsByID({})

		const groupsChanged = !isEqual(prevGroups, currentGroups)
		if (groupsChanged) {
			this.buildFuseGroupSearch()
		}
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Dropdown Actions
	// ----------------------------------------------------------------------------------

	/**
	 * Determines which action to take, based on which dropdown item
	 * the user selected in the table's dropdown menu
	 */
	handleTableDropdownAction = (e: any) => {
		const [action, value] = e
		switch (action) {
			case 'viewUserGroup':
				return this.handleViewUserGroup(value)
			case 'messageUserGroup':
				return this.handleMessageUserGroup(value)
			case 'editUserGroup':
				return this.handleEditUserGroup(value)
			case 'sendInvite':
				return this.handleSendGroupInvite(value)
			case 'removeUserGroup':
				return this.handleDeleteUserGroup(value)
			default:
				break
		}
	}

	handleViewUserGroup = (groupID: string) => {
		const { history } = this.props

		// Query params
		const search = queryString.stringify({ groupID })
		const pathname = Constants.VIEW_USER_GROUP_ROUTE
		const location = { pathname, search }
		history.push(location)
	}

	/**
	 * When creating a Message for a UserGroup, navigate to the Message creation
	 * form and pass information about the UserGroup via query parameters
	 */
	handleMessageUserGroup = (groupID: string) => {
		const { history } = this.props

		const search = queryString.stringify({ userGroupID: groupID })
		const pathname = Constants.CREATE_MESSAGE_ROUTE
		const location = { pathname, search }
		history.push(location)
	}

	/**
	 * When editing a UserGroup, navigate to the UserGroup update
	 * form and pass the UserGroup's name via the query parameters
	 */
	handleEditUserGroup = (groupID: string) => {
		const { history } = this.props

		const search = queryString.stringify({ groupID })
		const pathname = Constants.UPDATE_USER_GROUP_ROUTE
		const location = { pathname, search }
		history.push(location)
	}

	handleSendGroupInvite = async (groupID: string) => {
		await setStateAsync(this, { userGroupToInvite: groupID })
	}

	/**
	 * When attempting to delete a UserGroup, display
	 * the UserGroup deletion confirmation modal
	 */
	handleDeleteUserGroup = async (value: string) => {
		await setStateAsync(this, { userGroupToDelete: value })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Modal Event Handlers
	// ----------------------------------------------------------------------------------

	sendUserInvite = async () => {
		const { sendInvitationToGroup, fireFlashMessage } = this.props
		await setStateAsync(this, { loading: true })

		const groupID = this.state.userGroupToInvite
		const [inviteErr] = await to(sendInvitationToGroup(groupID) as any)
		if (inviteErr) {
			fireFlashMessage(`Failed to send Group invite. ${inviteErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, userGroupToInvite: null })
			return
		}

		fireFlashMessage('Successfully sent invitation to group.', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, userGroupToInvite: null })
	}

	closeInviteUserGroupModal = async () => {
		await setStateAsync(this, { userGroupToInvite: null })
	}

	/**
	 * Make the API call to delete the UserGroup, when the
	 * user confirms the deletion via the modal
	 */
	executeUserGroupDeletion = async () => {
		const { loggedInClub, deleteUserGroup, fireFlashMessage } = this.props
		const { userGroupToDelete } = this.state

		const clubID = `${loggedInClub._id}`
		const groupID = userGroupToDelete
		const [deleteErr] = await to(deleteUserGroup(clubID, groupID) as any)
		if (deleteErr) {
			fireFlashMessage(`Failed to delete Member Group. ${deleteErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, userGroupToDelete: null })
			return
		}

		fireFlashMessage('Member Group successfully deleted.', Constants.FlashType.SUCCESS)

		await setStateAsync(this, { loading: false, userGroupToDelete: null })
	}

	/**
	 * Hide the deletion modal when the user cancels it
	 */
	closeDeleteUserGroupModal = async () => {
		await setStateAsync(this, { userGroupToDelete: null })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Handle table pagination
	 */
	handlePage = async (data: any) => {
		const { pageIndex } = this.state

		// Check if the new page is different that the current one
		if (data.selected !== pageIndex) {
			await setStateAsync(this, { pageIndex: data.selected })
		}
	}

	/**
	 * Handle a column header of the table being selected
	 */
	handleTableHeaderAction = (e: any) => {
		// TODO: Add Functionality
	}

	/**
	 * Handle a table row being clicked
	 */
	handleTableRowClicked = (item: core.User.UserGroup) => {
		return this.handleViewUserGroup(`${item._id}`)
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Header Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Determine which action to take, based on the header
	 * button that the user selected
	 */
	handleHeaderButtonAction = async (e: any) => {
		const action = e.target.value
		switch (action) {
			case 'createUserGroup':
				this.handleCreateUserGroup()
				break
			default:
				break
		}
	}

	/**
	 * Navigate to the User Group creation form
	 */
	handleCreateUserGroup = () => {
		this.props.history.push(Constants.CREATE_USER_GROUP_ROUTE)
	}

	handleSearchChange = async (term: string) => {
		await setStateAsync(this, { searchTerm: term, pageIndex: 0 })
	}

	handleSearchEnter = async (event: any) => {
		if (event.key === 'Enter') {
			await setStateAsync(this, { pageIndex: 0 })
		}
	}

	handleSearchClear = async () => {
		await setStateAsync(this, { searchTerm: '', pageIndex: 0 })
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildHeaderButtons = (): HeaderButton[] => {
		return [
			{
				action: 'createUserGroup',
				type: ButtonType.DEFAULT,
				text: 'New Group',
				class: 'btn-primary',
			}
		]
	}

	buildPageHeader = () => {
		const title = 'Groups'

		const searchInputs: PageHeaderSearchInput[] = [{
			inputType: PageHeaderInputType.Search,
			changeHandler: this.handleSearchChange,
			enterHandler: this.handleSearchEnter,
			clearHandler: this.handleSearchClear,
		}]

		return (
			<TableHeader
				fullScreen={true}
				pageTitle={title}
				inputs={searchInputs}
				buttons={this.buildHeaderButtons()}
				buttonHandler={this.handleHeaderButtonAction}
			/>
		)
	}

	buildDeleteConfirmationModal = () => {
		if (!this.state.userGroupToDelete) { return null }

		return (
			<ModalComponent
				modalTitle={`Delete Member Group`}
				primaryMessage={`Are you sure you want to delete this Member Group?`}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.closeDeleteUserGroupModal}
				submitButtonName={'Confirm'}
				submitButtonHandler={this.executeUserGroupDeletion}
			/>
		)
	}

	buildInviteConfirmationModal = () => {
		if (!this.state.userGroupToInvite) { return null }
		return (
			<ModalComponent
				modalTitle={'Send Group Invite'}
				primaryMessage={'Are you sure you want to send an invite email to this group?'}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.closeInviteUserGroupModal}
				submitButtonName={'Confirm'}
				submitButtonHandler={this.sendUserInvite}
			/>
		)
	}

	buildUserGroupTable = () => {
		const { loading, pageIndex } = this.state

		// Items for the Table
		const { groupsToShow, total } = this.getGroupsToShow()
		const hydratedGroups = this.hydrateGroupUsers(groupsToShow)

		return (
			<TableComponent
				columns={UserGroupTableColumns}
				rowItems={hydratedGroups}
				showPaging={true}
				pageSize={this.pageSize}
				pageHandler={this.handlePage}
				totalResults={total}
				currentPage={pageIndex}
				actionHandler={this.handleTableHeaderAction}
				dropdownHandler={this.handleTableDropdownAction}
				onTableRowClick={this.handleTableRowClicked}
				isLoading={loading}
			/>
		)
	}

	render() {
		const { loggedInClub } = this.props
		if (this.state.error) { return <ErrorComponent club={loggedInClub} isAdmin={this.props.userState.isAdmin} /> }

		return (
			<RS.Row className={'user-group-container'}>
				<RS.Col className={'no-padding'}>
					{this.buildPageHeader()}

					<div className='user-group-table-container'>
						{this.buildUserGroupTable()}
					</div>

					{this.buildDeleteConfirmationModal()}
					{this.buildInviteConfirmationModal()}
				</RS.Col>
			</RS.Row>
		)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	/**
	 * Group users come to the FE as an array of ObjectIDs.
	 * We need to hydrate full user objects before display.
	 */
	hydrateGroupUsers = (userGroups: core.User.UserGroup[]) => {
		const { usersByID } = this.props

		return userGroups.map((group: core.User.UserGroup) => {
			const userIDs = [...group.users] as any as string[]
			const userGroupUsers = pick(usersByID, userIDs)
			const typeBadge = groupBadge(group)
			return { ...group, type: typeBadge, users: values(userGroupUsers) }
		})
	}

	/**
	 * Returns a list of the Groups to show, with the search term acting as a filter
	 */
	getGroupsToShow = (): { groupsToShow: core.User.UserGroup[], total: number } => {
		const { searchTerm, pageIndex } = this.state

		const groupResults = oc(this.searchGroups(searchTerm))([])
		const start = pageIndex * this.pageSize
		const end = start + this.pageSize
		const groupsToShow = groupResults.slice(start, end)
		return { groupsToShow, total: groupResults.length }
	}

	searchGroups = (searchTerm: string) => {
		const { userGroups, userGroupsByID } = this.props
		if (!this.fuseGroupSearch || oc(searchTerm)('') === '') {
			return userGroups
		}

		const fuseMatches: FuseItem[] = this.fuseGroupSearch.search(searchTerm) as FuseItem[]
		return fuseMatches.map(({ _id }) => userGroupsByID[_id])
	}

	buildFuseGroupSearch = () => {
		const { userGroups } = this.props
		const groupsForFuse: FuseItem[] = oc(userGroups)([]).map((ug: core.User.UserGroup) => ({ _id: `${ug._id}`, searchString: ug.name }))
		this.fuseGroupSearch = buildFuseSearch(groupsForFuse)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	loggedInClub: state.club.loggedInClub,
	userState: state.user,
	usersByID: usersByIDSelector(state),
	userGroups: clubUserGroupsSelector(state),
	userGroupsByID: clubUserGroupsByIDSelector(state),
})

const mapDispatchToProps = {
	...GroupActions,
	...AlertActions,
	...InvitationActions
}

export default connect(mapStateToProps, mapDispatchToProps)(UserGroupComponent)
