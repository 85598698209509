// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as RS from 'reactstrap'
import * as Sentry from '@sentry/browser'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { RouteComponentProps } from 'react-router'
import { oc } from 'ts-optchain'

// Actions
import { AlertActions, CustomerActions, EventActions, LotteryActions } from '../../../actions/index'

// State
import { RootReducerState } from '../../../reducers'
import { inCustomerViewSelector, bookableMembers } from '../../../reducers/user'
import { eventsByIDSelector } from '../../../reducers/event'

// Components
import DimmedLoader from '../../Shared/DimmedLoader'
import { ReservationType } from '../../Shared/ReservationList'
import { BaseReservationComponent } from '../BaseReservation'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	event: null as core.Event.Model | null,
	reservation: null as core.Event.Reservation | null,
	calendar: null as core.Calendar.Model | null,
	group: null as core.Calendar.Group | null,
	existingRSVP: null as core.Event.Reservation | null,
	showTimeSelectionModal: false,
	error: false,
	loading: false
}

type Props = RouteComponentProps & ConnectedState & ConnectedActions
type State = typeof initialState

class EventReservationComponent extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props)

		this.state = { ...initialState }
	}

	async componentDidMount() {
		await this.setEventWithQueryParameters()
	}

	setEventWithQueryParameters = async () => {
		const parsedQuery = queryString.parse(this.props.location.search)
		const eventID = parsedQuery.eventID
		if (!eventID) {
			this.setState({ loading: false })
			return
		}
		await this.props.fetchEvent(eventID, new Date())
		const event = this.props.eventState.currentEvent

		const calendars = oc(this).props.calendarState.calendars([])
		const calendar = calendars.find((c: core.Calendar.Model) => `${c._id}` === `${event.calendarIDs[0]}`)

		const groups = oc(this).props.calendarState.groups([])
		const group = groups.find((g: core.Calendar.Group) => `${g._id}` === `${calendar!.groupID}`)

		let reservation: core.Event.Reservation
		if (this.props.isCustomerView) {
			const reservations = oc(event).reservations([])
			reservation = reservations.find((r: core.Event.Reservation) => r.owner === this.props.userState.loggedInUser._id)
		}
		this.setState({ event, calendar, group, reservation, loading: false })
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildContent = () => {
		return (
			<BaseReservationComponent
				{...this.props}
				event={this.state.event}
				reservation={this.state.reservation}
				calendar={this.state.calendar}
				calendarGroup={this.state.group}
				eventName={this.state.event.name}
				backName={'Event Detail'}
				type={ReservationType.RSVP}
				cardTitle={'Confirm Reservation'}
				submitButtonName={'Confirm'}
				maxGuests={this.state.event.maxGuests}
				bookingDate={this.state.event.start}
			/>
		)
	}

	render() {
		if (this.state.loading || !this.state.event) { return <DimmedLoader component={null} isLoading={true} /> }
		return (
			<RS.Col className='event-reservation-container d-flex justify-content-center'>
				{this.buildContent()}
			</RS.Col>
		)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	addSentryBreadcrumb = () => {
		const location = this.props.location
		const user = this.props.userState.loggedInUser
		const data = { location, user }
		Sentry.addBreadcrumb({ data })
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	bookableEvents: oc(state).event.bookableEvents([]),
	calendars: oc(state).calendar.calendars([]),
	loggedInClub: state.club.loggedInClub,
	userState: state.user,
	eventState: state.event,
	calendarState: state.calendar,
	isCustomerView: inCustomerViewSelector(state),
	bookableMembers: bookableMembers(state),
	eventsByID: eventsByIDSelector(state),
})

const mapDispatchToProps = {
	...AlertActions,
	...CustomerActions,
	...EventActions,
	...LotteryActions
}

const enhance = compose<React.ComponentType>(
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(EventReservationComponent)
