// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import to from 'await-to-js'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { oc } from 'ts-optchain'

// Actions
import { AlertActions, CustomerActions } from '../../../actions/index'

// State
import { RootReducerState } from '../../../reducers'

// Form
import { EventUpdateState, EventUpdateInputs } from './form'

// Components
import FormModal from '../../Shared/Formik/FormModal'
import { InputSelectionItem, FormInput } from '../../Shared/Form'
import * as Constants from '../../../constants'

// Helpers
import { setStateAsync } from '../../../helpers/promise'
import { fullName } from '../../../helpers/user'

interface ComponentProps {
	isOpen: boolean,
	title: string
	message: string
	name: string
	handleUpdateType: (selection: string) => void
	handleToggle: () => void
}

const initialState = {
	loading: false,
	selected: core.Event.UpdateType.Single
}

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

type Props = ConnectedActions & ComponentProps & ConnectedState
type State = typeof initialState

/**
 * The `BlockModal` provides UI for blocking a calendar.
 */
class EventUpdateModal extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)

		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Handlers
	// ----------------------------------------------------------------------------------

	handleChange = async (field: string, value: any) => {
		this.setState({ selected: field as core.Event.UpdateType })
	}

	handleSubmit = async (values: EventUpdateState) => {
		this.props.handleUpdateType(this.state.selected)
	}

	// ----------------------------------------------------------------------------------
	// Component Builders
	// ----------------------------------------------------------------------------------

	buildFormResource = () => {
		const resource: any = {}
		EventUpdateInputs('').map((input: FormInput) => {
			const value = (this.state.selected === input.property)
			resource[input.property] = value
		})
		return resource
	}

	public render() {
		const resource = this.buildFormResource()
		const inputs = EventUpdateInputs(this.props.name)
		return (
			<FormModal<any, EventUpdateState>
				isOpen={this.props.isOpen}
				modalTitle={this.props.title}
				enableReinitialize={true}
				onChange={this.handleChange}
				formSpec={inputs}
				formResource={resource}
				cancelButtonHandler={this.props.handleToggle}
				submitButtonHandler={this.handleSubmit}
			/>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	userState: state.user,
	calendarState: state.calendar
})

const mapDispatchToProps = {
	...AlertActions,
	...CustomerActions
}

const enhance = compose<React.ComponentType<ComponentProps>>(
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(EventUpdateModal)
