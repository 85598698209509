// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as RS from 'reactstrap'
import to from 'await-to-js'
import { connect } from 'react-redux'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'

// Internal Dependencies

// State
import { RootReducerState } from '../../../reducers/index'
import { inCustomerViewSelector } from '../../../reducers/user'
import { calendarsByIDSelector } from '../../../reducers/calendar'

// Actions
import { EventActions, AlertActions, CalendarActions } from '../../../actions/index'

// Tables
import { ReservationTableRow } from './table'

// Components
import ErrorComponent from '../../Shared/ErrorComponent'
import TableComponent from '../../Shared/Table'
import ModalComponent from '../../Shared/Modal'
import ReservationListComponent from '../../Shared/ReservationList'
import DimmedLoader from '../../Shared/DimmedLoader'
import EmptyContent, { ContentType } from '../../Shared/EmptyContent'
import ClubTypes from '../../Shared/ClubTypes'
import GuestGolferModal from '../../Modals/GuestGolferModal'

// Helpers
import * as Constants from '../../../constants'
import { setStateAsync } from '../../../helpers/promise'
import { QueryOptions } from '../../../helpers/interface'
import { beginningOfDay } from '../../../helpers/date'

export enum ComponentTabs {
	All = 'all',
	Day = 'day',
	Upcoming = 'upcoming',
	Past = 'past',
	Types = 'types',
}

interface ComponentProps {
	calendar: core.Calendar.Model
	calendarGroup: core.Calendar.Group
	tableColumns: any[]
	tableRows: core.Event.Model[] // These will be an extended event model.
	header: JSX.Element
	selectedDate: Date
	contentType: ContentType
	currentTab: ComponentTabs
	showReservationModal: boolean
	toggleReservationModal: () => void
	showTypeCreationModal: boolean
	toggleTypeCreationModal: () => void
}

const initialState = {
	error: false,
	initialLoad: true,
	loading: false,
	pageIndex: 0,
	viewingTab: null as ComponentTabs | null,
	calendarGroupID: null as string,
	calendarGroup: null as core.Calendar.Group,
	selectedCalendarID: null as string | null,
	selectedUserID: null as string | null,
	selectedEvent: null as core.Event.Model,
	selectedReservation: null as core.Event.Reservation,
	showDeletionModal: false
}

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps
type Props = ComponentProps & ConnectedState & ConnectedActions
type State = typeof initialState

class BaseReservationComponent extends React.Component<Props, State> {
	private pageSize: number

	constructor(props: Props) {
		super(props)
		this.pageSize = 20

		const state = { ...initialState }
		state.calendarGroup = this.props.calendarGroup
		state.calendarGroupID = `${this.props.calendarGroup._id}`
		state.selectedCalendarID = `${this.props.calendar._id}`
		this.state = { ...state }
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		if (!this.state.calendarGroup) {
			this.props.fireFlashMessage(`Failed to Calendar Group.`, Constants.FlashType.DANGER)
			await setStateAsync(this, { initialLoad: false, error: true })
			return
		}

		// Refresh our calendars.
		await this.refreshCalendars()

		// Refresh our events.
		await this.refreshEvents()

		await setStateAsync(this, { initialLoad: false })
	}

	// ----------------------------------------------------------------------------------
	// Network Requests
	// ----------------------------------------------------------------------------------

	// Fetch the Calendars for the Customer
	refreshCalendars = async () => {
		const queryParams: QueryOptions = { limit: 0, offset: 0, groupID: this.state.calendarGroupID }
		const [fetchCalendarsErr] = await to(this.props.fetchCalendars(queryParams) as any)
		if (fetchCalendarsErr) {
			this.props.fireFlashMessage(`Failed to fetch Providers.${fetchCalendarsErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { initialLoad: false, error: true })
			return
		}
	}

	// Customer
	refreshEvents = async () => {
		const queryParams: QueryOptions = this.buildQueryParams()
		await this.fetchEvents(queryParams)
	}

	// Fetch Events
	fetchEvents = async (params: any) => {
		await setStateAsync(this, { loading: true })
		const [err] = await to(this.props.fetchEvents(params) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to fetch Services.${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}
		await setStateAsync(this, { loading: false })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Dropdown Actions
	// ----------------------------------------------------------------------------------

	/**
	 * Determines which action to take, based on which dropdown item
	 * the user selected in the table's dropdown menu
	 */
	handleTableDropdownAction = (e: any) => {
		const [action, value] = e
		switch (action) {
			case 'editEvent':
				return this.handleEditEvent(value)
			case 'cancelEvent':
				return this.handleCancelEvent(value)
			default:
				break
		}
	}

	/**
	 * Handle row clicks by displaying event detail.
	 */
	handleTableRowClicked = (row: ReservationTableRow): any => {
		// Nothing for now.
	}

	/**
	 * When editing an Event, navigate to the Event update
	 * form and pass the Event's ID via the query parameters
	 */
	handleEditEvent = (reservationID: string) => {
		// Find the Service with the Reservation
		const event = this.props.eventState.events.find((e) => {
			return !isNullOrUndefined(e.reservations.find((r) => `${r._id}` === `${reservationID}`))
		})

		const reservation = event.reservations.find((res) => `${res._id}` === reservationID)
		this.setState({ selectedReservation: reservation, selectedEvent: event })
		this.props.toggleReservationModal()
	}

	/**
	 * When attempting to cancel an Event, display the
	 * Event cancellation confirmation modal
	 */
	handleCancelEvent = async (reservationID: string) => {
		// Find the Service with the Reservation
		const event = this.props.eventState.events.find((e) => {
			return !isNullOrUndefined(e.reservations.find((r) => `${r._id}` === reservationID))
		})

		const reservation = event.reservations.find((res) => `${res._id}` === reservationID)
		this.setState({ selectedEvent: event, selectedReservation: reservation, showDeletionModal: true })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Modal Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Make the API call to cancel the Event, when the
	 * user confirms the cancellation via the modal
	 */
	executeEventDeletion = async () => {
		await setStateAsync(this, { loading: true })

		const reservationID = `${this.state.selectedReservation._id}`
		const eventID = `${this.state.selectedEvent._id}`
		const groupID = this.state.calendarGroupID
		const currentPage = this.state.pageIndex
		const [deleteErr] = await to(this.props.cancelEventRsvp(reservationID, eventID, currentPage, this.pageSize, groupID) as any)
		if (deleteErr) {
			this.props.fireFlashMessage(`Problem trying to cancel Service.${deleteErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true, selectedEvent: null, selectedReservation: null, showDeletionModal: false })
			return
		}

		this.props.fireFlashMessage('Successfully cancelled Service', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, selectedEvent: null, selectedReservation: null, showDeletionModal: false })
	}

	/**
	 * Hide the cancellation modal when the user closes it
	 */
	closeCancelEventModal = async () => {
		return setStateAsync(this, { selectedEvent: null, selectedReservation: null, showDeletionModal: false })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Handle table pagination
	 */
	handlePage = async (data: any) => {
		const { pageIndex } = this.state

		// Check if the new page is different that the current one
		if (data.selected !== pageIndex) {
			await setStateAsync(this, { pageIndex: data.selected })
			await this.refreshEvents()
		}
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Header Event Handlers
	// ----------------------------------------------------------------------------------

	handleReservationCreated = async () => {
		await this.refreshEvents()
		this.props.toggleReservationModal()
	}

	// ----------------------------------------------------------------------------------
	// New Reservation Handlers
	// ----------------------------------------------------------------------------------

	handleCreateReservation = () => {
		this.props.toggleReservationModal()
	}

	handleCancelNewReservation = () => {
		this.props.toggleReservationModal()
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildCancelConfirmationModal = () => {
		if (!this.state.showDeletionModal) { return null }

		return (
			<ModalComponent
				modalTitle={'Cancel Reservation'}
				primaryMessage={'Are you sure you want to cancel this reservation?'}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.closeCancelEventModal}
				submitButtonName={'Confirm'}
				submitButtonHandler={this.executeEventDeletion}
			/>
		)
	}

	buildTypesView = () => {
		return (
			<div className='event-table-container'>
				<ClubTypes
					resourceType={'services'}
					showingEditModal={this.props.showTypeCreationModal}
					onEditModalClose={this.props.toggleTypeCreationModal}
				/>
			</div>
		)
	}

	buildServiceTable = () => {
		if (this.state.viewingTab === ComponentTabs.Types) {
			return this.buildTypesView()
		}

		if (this.props.eventState.eventCount === 0) {
			return (<EmptyContent type={this.props.contentType} isInPast={false} />)
		}

		return (
			<TableComponent<core.Event.Model>
				columns={this.props.tableColumns}
				rowItems={this.props.tableRows}
				showPaging={true}
				currentPage={this.state.pageIndex}
				pageHandler={this.handlePage}
				totalResults={this.props.eventState.eventCount}
				pageSize={this.pageSize}
				dropdownHandler={this.handleTableDropdownAction}
				onTableRowClick={this.handleTableRowClicked}
				isLoading={this.state.loading}
			/>
		)
	}

	buildReservationModal = () => {
		if (!this.props.showReservationModal) { return }

		switch (this.props.calendarGroup.type) {
			case core.Calendar.GroupType.Service:
			case core.Calendar.GroupType.GuestGolfer:
				return this.buildGuestGolferModal()
		}
	}

	buildGuestGolferModal = () => {
		return (
			<GuestGolferModal
				reservation={this.state.selectedReservation}
				event={this.state.selectedEvent}
				calendar={this.props.calendar}
				handleSave={this.handleReservationCreated}
				handleCancel={this.handleCancelNewReservation}
			/>
		)
	}

	buildCustomerView = () => {
		const eventState = oc(this).props.eventState()
		if (eventState.events.length === 0) {
			return (<EmptyContent type={ContentType.Services} actionHandler={this.handleCreateReservation} />)
		}

		const reservationList = (<ReservationListComponent events={eventState.events} />)

		return (
			<div className='base-reservation-container'>
				{this.props.header}
				<DimmedLoader component={reservationList} isLoading={this.state.loading} />
			</div>
		)
	}

	buildAdminView = () => {
		return (
			<div className='base-reservation-container'>
				{this.props.header}
				<div className='reservation-table-container'>
					{this.buildServiceTable()}
				</div>
				{this.buildCancelConfirmationModal()}
			</div>
		)
	}

	render() {
		const { loggedInClub } = this.props
		if (this.state.initialLoad) { return <DimmedLoader component={null} isLoading={true} /> }
		if (this.state.error) { return <ErrorComponent club={loggedInClub} isAdmin={this.props.userState.isAdmin} /> }

		const size = (this.props.isCustomerView) ? 8 : 12
		const content = (this.props.isCustomerView) ? this.buildCustomerView() : this.buildAdminView()
		return (
			<RS.Col md={size} className={'base-reservation-container d-flex justify-content-center'}>
				{content}
				{this.buildReservationModal()}
			</RS.Col>
		)
	}

	// -------------------------------------------------------
	//
	// -------------------------------------------------------

	buildQueryParams = (): core.IShared.QueryOptions => {
		if (this.props.isCustomerView) {
			return { limit: 0, offset: 0, groupIDs: [this.state.calendarGroupID] }
		}

		const currentDate = new Date().toISOString()
		const begOfDate = beginningOfDay(currentDate).toISOString()
		const specificCalendarSelected = (this.state.selectedCalendarID && this.state.selectedCalendarID !== 'All')
		const selectedCalendarIDs = specificCalendarSelected ? [this.state.selectedCalendarID] : undefined

		const queryParams: core.IShared.QueryOptions = {}
		queryParams.limit = this.pageSize
		queryParams.offset = this.pageSize * this.state.pageIndex
		queryParams.groupID = this.state.calendarGroupID
		queryParams.calendarIDs = selectedCalendarIDs
		queryParams.hasReservations = true
		queryParams.start = begOfDate
		queryParams.timeField = 'start'
		return queryParams
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	eventState: state.event,
	userState: state.user,
	calendarState: state.calendar,
	loggedInClub: state.club.loggedInClub,
	isCustomerView: inCustomerViewSelector(state),
	calendarsByID: calendarsByIDSelector(state),
})

const mapDispatchToProps = {
	...CalendarActions,
	...EventActions,
	...AlertActions
}

export default connect(mapStateToProps, mapDispatchToProps)(BaseReservationComponent)
