// External Dependencies
import * as Feather from 'react-feather'
import * as core from 'club-hub-core'
import { Moment as MomentType } from 'moment'

// Page Header
import { PageHeaderSelectInput, PageHeaderInputType, PageHeaderDateInput, PageHeaderDropdownInput } from '../../Shared/PageHeader'
import { DropdownItemType } from '../../Shared/Dropdown'
import { FormInputType } from '../../Shared/Form'
import { ButtonType, HeaderButton } from '../../Shared/ButtonGroup'

export enum TeeSheetActions {
	NewBlock = 'NEW_BLOCK',
	NewTeeTime = 'NEW_TEE_TIME',
	NewTeeSheetEvent = 'NEW_TEE_SHEET_EVENT',
	ViewCalendar = 'VIEW_CALENDAR',
	ViewLotteryRequests = 'VIEW_LOTTERY_REQUESTS',
	ViewSettings = 'VIEW_SETTINGS'
}

export const buildHeaderButtons = (): HeaderButton[] => {
	return [
		{
			action: TeeSheetActions.ViewCalendar,
			type: ButtonType.DEFAULT,
			icon: Feather.Calendar,
			text: 'Calendar',
			class: 'btn-secondary',
		},
		{
			action: TeeSheetActions.NewBlock,
			type: ButtonType.DEFAULT,
			icon: Feather.XCircle,
			text: 'Block',
			class: 'btn-primary',
		},
	]
}

export const ReservationSettingsHeaderSelectInputs = (context: any, live: boolean, onChange: any): PageHeaderSelectInput[] => {
	if (context.isCustomerView) { return [] }

	const statuses = [
		{
			label: 'Live',
			value: 'live',
			color: core.Constants.Colors.GREEN,
			type: 'tee-time-status',
			leadingText: 'Status:',
		},
		{
			label: 'Off',
			value: 'off',
			color: core.Constants.Colors.RED,
			type: 'tee-time-status',
			leadingText: 'Status:',
		},
	]
	return [
		{
			inputType: PageHeaderInputType.Select,
			className: 'tee-time-status-input',
			icon: Feather.Power,
			inputs: statuses,
			selected: live ? statuses[0] : statuses[1],
			changeHandler: onChange
		},
	]
}

export const ReservationSettingsHeaderDateInputs = (context: any): PageHeaderDateInput[] => {
	return [
		{
			inputType: PageHeaderInputType.Date,
			className: 'tee-time-datepicker',
			type: FormInputType.DATE,
			selected: new Date(context.state.selectedDate),
			changeHandler: (newDate: Date) => context.handleDateChanged(newDate.toISOString()),
			isClearable: false,
			icon: Feather.Calendar,
		}
	]
}

export const ReservationSettingsDropdownInputs = (context: any, lotteryDay: boolean): PageHeaderDropdownInput[] => {
	if (context.isCustomerView) { return [] }

	const items: any[] = [
		{
			type: DropdownItemType.Default,
			icon: Feather.Calendar,
			text: 'New Golf Event',
			value: TeeSheetActions.NewTeeSheetEvent,
		},
		{
			type: DropdownItemType.Default,
			icon: Feather.PlusCircle,
			text: 'New Tee Time',
			value: TeeSheetActions.NewTeeTime,
		}
	]

	if (lotteryDay) {
		items.push({ type: DropdownItemType.Divider })
		items.push({
			type: DropdownItemType.Default,
			icon: Feather.Settings,
			text: 'View Lottery Requests',
			value: TeeSheetActions.ViewLotteryRequests,
		})
	}

	items.push({ type: DropdownItemType.Divider })
	items.push({
		type: DropdownItemType.Default,
		icon: Feather.Settings,
		text: 'Settings',
		value: TeeSheetActions.ViewSettings,
	})
	return [
		{
			inputType: PageHeaderInputType.Dropdown,
			className: 'tee-time-action-dropdown',
			header: 'Menu',
			category: 'Menu',
			items: items,
			onChange: context.handleActionSelected,
			featherIcon: Feather.MoreVertical,
		}
	]
}
