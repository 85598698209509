// External Dependencies
import * as Feather from 'react-feather'

// Internal Dependencies
import { TableColumn } from '../../Shared/Table'
import { TableColumnType } from '../../Shared/Table/Primitives'

export enum TableAction {
	Edit = 'edit',
	Delete = 'delete'
}

export const QuickLinkColumns: TableColumn[] = [
	{
		title: 'Title',
		property: 'title',
		type: TableColumnType.TEXT,
	},
	{
		title: 'Link',
		property: 'link',
		type: TableColumnType.TEXT,
	},
	{
		title: '',
		property: '',
		type: TableColumnType.DROPDOWN,
		class: 'table-dropdown-menu',
		dropdownItems: [
			{
				action: TableAction.Edit,
				valueProperty: 'rowIdx',
				displayText: 'Edit',
				icon: Feather.Edit,
			},
			{
				action: TableAction.Delete,
				valueProperty: 'rowIdx',
				displayText: 'Remove',
				icon: Feather.Trash,
			}
		],
	}
]
