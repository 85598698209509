// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import * as queryString from 'query-string'
import * as Feather from 'react-feather'
import * as core from 'club-hub-core'
import update from 'immutability-helper'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter, RouteComponentProps } from 'react-router'
import { oc } from 'ts-optchain'
import to from 'await-to-js'
import { isNullOrUndefined } from 'util'

// Actions
import { AlertActions, CustomerActions, EventActions, LotteryActions } from '../../../actions/index'

// State
import { RootReducerState } from '../../../reducers'
import { inCustomerViewSelector, bookableMembers } from '../../../reducers/user'
import { eventsByIDSelector } from '../../../reducers/event'

// Components
import Card from '../../Shared/Cards/Card'
import AsyncImage from '../../Shared/AsyncImage'
import ButtonRow from '../../Shared/ButtonRow'
import BackHeader from '../../Shared/BackHeader'
import UnderlineHeader from '../../Shared/ReservationDetails/UnderlineHeader'
import DimmedLoader from '../../Shared/DimmedLoader'
import ErrorComponent from '../../Shared/ErrorComponent'
import FormModal from '../../Shared/Formik/FormModal'
import GolferInputComponent from '../../Shared/GolferInput'
import { ReservationType } from '../../Shared/ReservationList'
import { SelectInput } from '../../Shared/Formik/InputPrimitives/SelectInput'
import { InputSelectionItem, FormInput, FormInputType } from '../../Shared/Form'
import { TextAreaInput } from '../../Shared/Formik/InputPrimitives/TextAreaInput'
import GuestModal from '../../Modals/GuestModal'

// Child Components
import DetailRow, { DetailRowItem } from '../../Shared/ReservationDetails/DetailRow'
import DescriptionComponent from '../../Shared/ReservationDetails/DescriptionSection'
import MemberInputComponent from '../../Shared/GuestInputs'

// Helpers
import * as Constants from '../../../constants'
import { setStateAsync } from '../../../helpers/promise'
import { userForForm, fullName, memberInputForForm } from '../../../helpers/user'
import { getMostSpecificSetting } from '../../../helpers/reservation'
import { ImageSize } from '../../../helpers/image'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

interface GolferInput {
	participant?: InputSelectionItem,
	needsCart: boolean,
	numHoles: number,
}

const emptyInput = () => {
	return { label: null as any, value: null as any }
}

const initialState = {
	reservation: null as core.Event.Reservation,
	calendarGroup: null as core.Calendar.Group | null,
	calendarID: null as string | null,
	bookingDate: null as Date | null,
	eventID: null as string | null,
	action: null as string | null,
	type: null as string | null,
	maxGuests: 0,
	guestIndex: 0,
	guestModal: false,
	participants: [] as GolferInput[],
	guestCount: 0,
	showConfirmDelete: false,
	notes: '',
	error: false,
	loading: true,
	reservationID: null as string | null,
	duration: null as number | null,
	lottery: false
}

type Props = ConnectedState & RouteComponentProps & ConnectedActions
type State = typeof initialState

const MEMBER_PLACEHOLDER = 'Member'

export class ConfirmReservationComponent extends React.Component<Props, State> {
	private reservationType: ReservationType
	private eventName: string
	private eventID: string
	private backTitle: string
	private backRoute: string
	private calendar: core.Calendar.Model
	private cardTitle: string
	private guestUnderlineLabel: string
	private submitButton: string
	private reservedUserIDsMap: core.IShared.GeneralMap<string> = {}

	constructor(props: Props) {
		super(props)

		// Set class fields.
		const routeAction = (this.props.match.params as any).action
		this.submitButton = (routeAction === 'update') ? 'Update' : 'Confirm'
		this.reservationType = (this.props.match.params as any).type

		const calendars = props.calendarState.calendars
		const calendarID = this.props.location.state.calendarID
		this.calendar = calendars.find((calendar: core.Calendar.Model) => `${calendar._id}` === calendarID)

		const participantArray = Array.from({ length: this.props.location.state.guestCount || 1 }, (v) => {
			return { participant: emptyInput(), needsCart: false, numHoles: 18 }
		})

		// If the user is a Member, initialize the 'guests' array to include them
		if (props.isCustomerView) {
			const member = this.props.userState.loggedInUser
			const input = memberInputForForm(member)
			participantArray[0] = input
		}

		// Check for lottery
		const parsedQuery = queryString.parse(this.props.location.search)
		const lottery = parsedQuery.lottery

		// Set initial state.
		this.state = { ...initialState, ...this.props.location.state, participants: participantArray, action: routeAction, lottery }
		this.setInitialClassValues()
	}

	/**
	 * If there is a reservationID in state (passed in from route state),
	 * then we know we are updating an existing reservation.
	 * Fetch the event and reservation, then set state values.
	 */
	async componentDidMount() {
		if (!this.state.eventID) {
			await setStateAsync(this, { loading: false })
			return
		}

		// Fetch the event with the reservation we are updating.
		const [err, event] = await to(this.props.fetchEvent(this.state.eventID) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to find event. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}

		// Add the event's existing reserved users to a hash.
		this.getExistingResUserIDs()

		if (!this.state.reservationID) {
			await setStateAsync(this, { loading: false })
			return
		}

		const currentEvent = oc(this).props.eventState.currentEvent()
		const currentReservation = currentEvent.reservations.find((r: core.Event.Reservation) => `${r._id}` === this.state.reservationID)
		await this.setPlaceholderFormValues(currentReservation)
		const maxGuests = this.determineMaxGuests()
		await setStateAsync(this, { maxGuests: maxGuests, loading: false })
	}

	/**
	 * Set state values using an existing reservation.
	 */
	setPlaceholderFormValues = async (reservation: core.Event.Reservation): Promise<void> => {
		const participants = this.getGuestInputsForReservation(reservation)
		const notes = oc(reservation).notes(oc(reservation).meta.notes(''))
		await setStateAsync(this, { participants, notes, reservation })
	}

	getGuestInputsForReservation = (reservation: core.Event.Reservation) => {
		const participants = oc(reservation).participants([])
		return participants.map((participant) => {
			const label = oc(participant).name()
			const value = label === MEMBER_PLACEHOLDER ? null : `${oc(participant).userID()}`
			return { participant: { label, value }, needsCart: oc(participant).golfCart(false), numHoles: oc(participant).holeCount(18) }
		})
	}

	/**
	 * Sets the class fields used to fill out the reservation confirmation UI.
	 */
	setInitialClassValues = () => {
		const passedProps = this.props.location.state.calendar
		switch (this.state.calendarGroup.type) {
			case core.Calendar.GroupType.Golf:
				this.eventName = this.calendar.name
				this.backTitle = 'Tee Times'
				const golfRouteQuery = queryString.stringify({ date: new Date(this.state.bookingDate).toISOString() })
				this.backRoute = `${Constants.GOLF_RESERVATION_ROUTE}?${golfRouteQuery}`
				this.reservationType = ReservationType.GOLF
				this.cardTitle = this.state.lottery ? 'Lottery Request' : 'Tee Time'
				this.guestUnderlineLabel = 'Golfers'
				break
			case core.Calendar.GroupType.Club:
				const event = this.props.eventsByID[this.state.eventID]
				this.eventName = event.name
				this.eventID = `${event._id}`
				this.backTitle = 'Event Detail'
				const viewEventRouteQuery = queryString.stringify({ eventID: event._id })
				this.backRoute = `${Constants.VIEW_EVENT_ROUTE}?${viewEventRouteQuery}`
				this.reservationType = ReservationType.RSVP
				this.cardTitle = 'Reservation'
				this.guestUnderlineLabel = 'Guests'
				break
			case core.Calendar.GroupType.Dining:
				this.eventName = this.calendar.name
				this.backTitle = 'Dining'
				const diningRouteQuery = queryString.stringify({ date: new Date(this.state.bookingDate).toISOString() })
				this.backRoute = `${Constants.DINING_RESERVATIONS_ROUTE}?${diningRouteQuery}`
				this.reservationType = ReservationType.DINING
				this.cardTitle = 'Reservation'
				this.guestUnderlineLabel = 'Guests'
				break
			default:
				throw new Error(`Invalid reservation type found: ${this.reservationType}`)
		}
	}

	// ----------------------------------------------------------------------------------
	// Form Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Handles form changes.
	 */
	handleFormChange = async (e: any) => {
		// Get the field/value from the event
		const field: keyof State = e.target.name
		const value = e.target.value
		await setStateAsync(this, () => ({ [field]: value }))
	}

	/**
	 * Handles selections in a Member select.
	 */
	handleMemberSelected = async (data: any, index: number) => {
		if (data.value === 'addGuest') {
			await setStateAsync(this, { guestModal: true, guestIndex: index })
			return
		}
		await this.updateGolferState(index, 'participant', data)
	}

	handleAddGuest = async () => {
		if (this.state.participants.length >= this.state.maxGuests) {
			return
		}
		const participants = [...this.state.participants]
		participants.push({ participant: emptyInput(), needsCart: false, numHoles: 18 })
		await setStateAsync(this, { participants })
	}

	removeGuestInput = async (rowIndex: number): Promise<void> => {
		if (rowIndex === 0) { return null }
		const participants = [...this.state.participants]
		participants.splice(rowIndex, 1)
		await setStateAsync(this, { participants })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Returns the user to Tee Time table.
	 */
	handleCancel = () => {
		// Send Member to the My Reservations route if they are backing out of an update form
		if (this.props.isCustomerView && this.state.action === 'update') {
			const route = Constants.MY_RESERVATIONS_ROUTE.replace(':type', this.state.calendarGroup.type)
			return this.props.history.push(route)
		}

		this.props.history.push(this.backRoute)
	}

	/**
	 * Toggles the guest modal to display or not.
	 */
	closeGuestModal = async () => {
		await setStateAsync(this, { guestModal: !this.state.guestModal })
	}

	handleDelete = async () => {
		if (this.state.showConfirmDelete) {
			await this.removeRSVP()
			return
		}
		await setStateAsync(this, { showConfirmDelete: true })
	}

	// ----------------------------------------------------------------------------------
	// Network Requests
	// ----------------------------------------------------------------------------------

	/**
	 * Creates a reservation for an event.
	 * This will be done using the create RSVP or
	 * reserve bookable event API. Both take in a reservation object.
	 * @returns void (Triggers a route change).
	 */
	handleSubmit = async (): Promise<void> => {
		await setStateAsync(this, { loading: true })

		// Build the Reservation payload
		const reservation = this.buildReservationPayload()

		// If we have a lottery request, create it.
		if (this.state.lottery) {
			return this.createLottery(reservation)
		}
		// Check to see if we are updating an existing reservation.
		if (this.state.action === 'update') {
			return this.updateRSVP(reservation)
		}

		// Choose which Reservation function to use based on the Calendar Group type
		const calendarGroupType = this.state.calendarGroup.type
		const createRsvpFunction = (calendarGroupType === core.Calendar.GroupType.Club) ?
			this.createRSVP :
			this.reserveBookableEvent

		const [requestError] = await to(createRsvpFunction(reservation))
		if (requestError) {
			this.props.fireFlashMessage(`Failed to create reservation. ${requestError.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false })
			return
		}

		await setStateAsync(this, { loading: false })
		this.handleRouteChange()
	}

	createLottery = async (res: core.Event.Reservation) => {
		const { loggedInClub } = this.props
		const userID = this.props.userState.loggedInUser._id
		const clubID = loggedInClub._id
		const calendarID = this.state.calendarID

		const lottery: core.Lottery.Model = { userID, clubID }
		const [err] = await to(this.props.createLottery(lottery, res, calendarID, this.state.bookingDate) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to submit lottery request. ${err.message}`, Constants.FlashType.DANGER)
			throw err
		}

		const [fetchErr] = await to(this.props.fetchEvents({ limit: 0, offset: 0 }) as any)
		if (fetchErr) {
			this.props.fireFlashMessage(`Failed to fetch updated Events. ${err.message}`, Constants.FlashType.DANGER)
			return
		}
		this.props.fireFlashMessage(`Successfully created Lottery Request.`, Constants.FlashType.SUCCESS)
		this.handleRouteChange()
	}

	/**
	 * Sends the reservation payload to the create RSVP API.
	 * @returns void (Triggers a route change).
	 */
	createRSVP = async (reservation: core.Event.Reservation) => {
		const [err] = await to(this.props.createEventRsvp(reservation, this.eventID!) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to create RSVP for Event. ${err.message}`, Constants.FlashType.DANGER)
			throw err
		}

		const [fetchErr] = await to(this.props.fetchEvents({ limit: 0, offset: 0 }) as any)
		if (fetchErr) {
			this.props.fireFlashMessage(`Failed to fetch updated Events. ${err.message}`, Constants.FlashType.DANGER)
			return
		}

		this.props.fireFlashMessage(`Successfully created RSVP.`, Constants.FlashType.SUCCESS)
	}

	/**
	 * Sends the reservation payload to the update RSVP API.
	 * @returns void (Triggers a route change).
	 */
	updateRSVP = async (reservation: core.Event.Reservation) => {
		const [err] = await to(this.props.updateEventRsvp(this.state.reservationID, reservation, this.state.eventID!) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to update RSVP for Event. ${err.message}`, Constants.FlashType.DANGER)
			throw err
		}

		const [fetchErr] = await to(this.props.fetchEvents({ limit: 0, offset: 0 }) as any)
		if (fetchErr) {
			this.props.fireFlashMessage(`Failed to fetch updated Events. ${err.message}`, Constants.FlashType.DANGER)
			return
		}

		this.props.fireFlashMessage(`Successfully updated RSVP.`, Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false })
		this.handleRouteChange()
	}

	/**
	 * Removes the reseravtion.
	 * @returns void (Triggers a route change).
	 */
	removeRSVP = async () => {
		await setStateAsync(this, { loading: true })

		const [err] = await to(this.props.cancelEventRsvp(this.state.reservationID, this.state.eventID, 0, 0) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to cancel Reservation for Event. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}

		this.props.fireFlashMessage(`Successfully cancelled Reservation.`, Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false })
		this.handleRouteChange()
	}

	/**
	 * Sends the reservation payload to the reserve bookable event API.
	 * @returns void (Triggers a route change).
	 */
	reserveBookableEvent = async (reservation: core.Event.Reservation) => {
		const calendarID = this.state.calendarID
		const startTime = new Date(this.state.bookingDate).toISOString()
		const endTime = this.determineEndTime(this.state.bookingDate)

		const [reserveErr] = await to(this.props.reserveBookableEvent(reservation, calendarID, startTime, endTime) as any)
		if (reserveErr) {
			this.props.fireFlashMessage(`Failed to reserve Tee Time. ${reserveErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false })
			throw reserveErr
		}

		const [fetchErr] = await to(this.props.fetchBookableEvents(startTime, [calendarID], !this.props.isCustomerView) as any)
		if (fetchErr) {
			this.props.fireFlashMessage(`Failed to fetch Events. ${fetchErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false })
			throw fetchErr
		}

		this.props.fireFlashMessage(`You've successfully confirmed your reservation`, Constants.FlashType.SUCCESS)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	/**
 	* Creates a new guest user in the DB.
 	* This guest user will be added to the input that 'Add Guest' was selected from.
 	* @returns void.
 	*/
	handleNewGuests = async (guest: core.User.Model): Promise<void> => {
		const guestData = { label: fullName(guest), value: `${guest._id}` }
		await this.handleMemberSelected(guestData, this.state.guestIndex)
		await setStateAsync(this, { loading: false, error: false, guestModal: false })
	}

	/**
	 * Sends Members back to the My Reservation's view, and sends Admins back using the 'backRoute'.
	 * NOTE: If the user isn't an admin, and the reservation was made on the club calendar (Event RSVP),
	 * navigate to the Event detail component.
	 */
	handleRouteChange = () => {
		if (this.props.isCustomerView && this.state.calendarGroup.type !== core.Calendar.GroupType.Club) {
			const route = Constants.MY_RESERVATIONS_ROUTE.replace(':type', this.state.calendarGroup.type)
			return this.props.history.push(route)
		}
		this.props.history.push(this.backRoute)
	}

	/**
	 * Adds all users who have a RSVP to the current event to a hash.
	 * This is used when building the member select inputs to prevent
	 * the same user from having a duplicate RSVP.
	 */
	getExistingResUserIDs = (): void => {
		const currentEvent = oc(this).props.eventState.currentEvent()
		if (isNullOrUndefined(currentEvent)) { return }

		currentEvent.reservations.forEach((res: core.Event.Reservation) =>
			res.participants.forEach((p: core.Event.Participant) =>
				this.reservedUserIDsMap[`${p.userID}`] = `${p.userID}`
			))
	}

	/**
	 * Builds the reservation payload used to create the golf event.
	 * For guest users (without emails / userIDs), the participant
	 * will have a userID of undefined and name of 'Guest'.
	 */
	buildReservationPayload = (): core.Event.Reservation => {
		const participants: core.Event.Participant[] = []
		for (const participant of this.state.participants) {
			const newParticipant: core.Event.Participant = {
				userID: participant.participant.value as any,
				name: participant.participant.label || MEMBER_PLACEHOLDER,
				checkedIn: false,
				paid: false,
			}
			participants.push(newParticipant)
		}

		// Create the reservation for the payload
		const reservation: core.Event.Reservation = {
			creator: this.props.userState.loggedInUser._id,
			owner: participants[0].userID,
			participants: participants,
			notes: this.state.notes
		}

		// Attach golf Reservation metadata.
		if (this.state.calendarGroup.type.toLocaleLowerCase() === core.Calendar.GroupType.Golf.toLocaleLowerCase()) {
			reservation.meta = { notes: this.state.notes }
		}
		return reservation
	}

	/**
	 * Determines the max participants that can be added on this reservation.
	 * Must take into account max guests coming from (in order based on priority):
	 * Route props.
	 * Reservation settings.
	 * Event field (event.maxGuests).
	 * Max participant prop (admins).
	 * Fallback to disallow any added guests.
	 * @returns Number
	 */
	determineMaxGuests = (): number => {
		let maxGuests: number

		// Use max guests from route props.
		if (!isNullOrUndefined(this.props.location.state.maxGuests)) {
			maxGuests = this.props.location.state.maxGuests
		}

		// If an eventID is passed in by route props, determine max guests using the existing event's participants and reservation settings.
		else if (isNullOrUndefined(this.props.location.state.maxGuests) && this.props.location.state.eventID) {
			maxGuests = this.maxGuestsForExistingEvent()
		}

		// If max guests still isn't set and the user is an admin. Check for the max participants props.
		if (isNullOrUndefined(maxGuests) && !this.props.isCustomerView && !isNullOrUndefined(this.props.location.state.maxParticipants)) {
			maxGuests = this.props.location.state.maxParticipants
		}
		// We shouldn't reach this state, if we do, don't allow any additional guests to be added.
		return (!isNullOrUndefined(maxGuests)) ? maxGuests : 0
	}

	/**
	 * Determines the max guests that can be allowed on an existing event.
	 * First looks at the max guests from reservation settings, then the max guest field on the event.
	 * If either of these are null, null is returned.
	 * If we have a max guest value from one of these, return this number + the guest count for the current reservation.
	 * @returns Number | null
	 */
	maxGuestsForExistingEvent = (): number | null => {
		// Grab the event. Figure out how many total participants we have.
		const eventID = this.props.location.state.eventID
		const currentEvent: core.Event.Model = this.props.eventState.currentEvent
		const totalParticipants: number = currentEvent.reservations.reduce((total: number, current: core.Event.Reservation) => {
			return total += current.participants.length
		}, 0)

		// Attempt to get the max guests from settings. Fallback to using the max guests set on the event.
		const maxGuestsForSetting = this.maxGuestForReservationSetting()
		const maxGuestsForReservation = (!isNullOrUndefined(maxGuestsForSetting)) ? maxGuestsForSetting : currentEvent.maxGuests
		if (isNullOrUndefined(maxGuestsForReservation)) {
			return null
		}

		const openSpots = maxGuestsForReservation - totalParticipants
		const maxGuests = openSpots + this.props.location.state.guestCount
		return maxGuests
	}

	/**
	 * Returns the max guests allowed by the calendar's reservation setting.
	 * @returns number | null
	 */
	maxGuestForReservationSetting = (): number | null => {
		const reservationSettings = oc(this.calendar).reservationSettings([])
		const specificSetting = getMostSpecificSetting(reservationSettings, this.props.location.state.bookingDate)
		if (!isNullOrUndefined(specificSetting)) {
			return (this.props.isCustomerView) ?
				specificSetting.maxGuestsMember :
				specificSetting.maxGuestsAdmin
		}
		return null
	}

	/**
	 * For the dining calendar group we need to determine an end time.
	 * If 'duration' isn't supplied on component state, default to 90 minutes.
	 * In the future we could probably grab this value off of the calendar settings - SG.
	 */
	determineEndTime = (bookingTime: Date): string => {
		if (this.state.calendarGroup.type !== core.Calendar.GroupType.Dining) {
			return undefined
		}

		const defaultDuration = 89 // Eighty nine minutes.
		const duration = oc(this).state.duration(defaultDuration)

		// Determine the start/end
		const startTime = new Date(bookingTime).toISOString()
		const temp = new Date(startTime)
		temp.setMinutes(temp.getMinutes() + duration, 0, 0)
		return temp.toISOString()
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	/**
	 * Builds the reservation card which includes a form.
	 * The logic for the form is held in the form component that matches the reservation type.
	 */
	buildContent = () => {
		const prefix = this.state.reservationID ? 'Update' : 'New'
		return (
			<Card
				title={`${prefix} ${this.cardTitle}`}
				headerClass={'d-flex'}
				bodyClass={'card-body'}
				hideHeader={true}
				content={this.buildCardBody()}
				footer={this.buildButtonRow()}
			/>
		)
	}

	buildImageComponent = () => {
		const group = this.state.calendarGroup
		const { loggedInClub } = this.props
		return (
			<div className='event-photo-container'>
				<AsyncImage
					image={oc(group).image({})}
					size={ImageSize.Large}
					club={loggedInClub}
					className={'card-img-top'}
					placeholderClass={'card-img-top-placeholder'}
				/>
			</div>
		)
	}

	buildCardBody = () => {
		const dateString = new Date(this.state.bookingDate).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })
		const startTimeString = new Date(this.state.bookingDate).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' })

		const club = oc(this).props.loggedInClub()
		const desc = `Please review your reservation information and confirm below. If you have any questions, please contact ${oc(club).name('')} at ${oc(club).locations[0].phone('')}`

		const dateTimeDetailRows: DetailRowItem[] = [
			{ icon: Feather.MapPin, label: 'Location', value: this.eventName },
			{ icon: Feather.Calendar, label: 'Time & Date', value: `${dateString}, ${startTimeString}` }
		]

		return (
			<>
				{this.buildImageComponent()}
				<RS.Alert color={'primary'}>{desc}</RS.Alert>
				<div className='card-details'>
					{dateTimeDetailRows.map((i) => <DetailRow rowItem={i} key={i.label} />)}
				</div>
				<>
					{this.buildGuestInputs()}
					{this.buildTextArea()}
				</>
			</>
		)
	}

	// Update the golfer array with John's fun way.
	// https://medium.com/pro-react/a-brief-talk-about-immutability-and-react-s-helpers-70919ab8ae7c#7d62
	updateGolferState = async (index: number, property: string, value: any) => {
		const participants: GolferInput[] = this.state.participants.concat()
		const updatedParticipants = update(participants, {
			[index]: { [property]: { $set: value } }
		})
		await setStateAsync(this, { participants: updatedParticipants })
	}

	/**
	 * Builds the Guest selection inputs section of the form.
	 */
	buildGuestInputs = () => {
		const users = oc(this).props.bookableMembers([])
		if (this.state.calendarGroup.type === core.Calendar.GroupType.Golf) {
			return (
				<GolferInputComponent
					club={this.props.loggedInClub}
					golfers={this.state.participants}
					eventTime={this.state.bookingDate}
					user={this.props.userState.loggedInUser}
					users={users}
					reservedUsers={oc(this).state.reservation.participants([])}
					handleAddGuest={(index: number) => null}
					handleMemberSelected={this.handleMemberSelected}
					updateGolferState={this.updateGolferState}
				/>
			)
		}

		const guests = this.state.participants.map((g: GolferInput) => g.participant)
		return (
			<MemberInputComponent
				title={this.guestUnderlineLabel}
				club={this.props.loggedInClub}
				users={users}
				maxGuests={this.state.maxGuests}
				guestCount={guests.length}
				guests={guests}
				loggedInUser={this.props.userState.loggedInUser}
				isCustomerView={this.props.isCustomerView}
				handleAddGuest={this.handleAddGuest}
				handleFormChange={this.handleFormChange}
				handleMemberSelected={this.handleMemberSelected}
				removeGuestInput={this.removeGuestInput}
				reservedUserIDsMap={this.reservedUserIDsMap}
			/>
		)
	}

	buildTextArea = () => {
		return (
			<>
				<RS.Label className='form-label'>
					<span>{'Notes'}</span>
				</RS.Label>
				<TextAreaInput
					item={{
						title: 'Notes',
						property: 'notes',
						type: FormInputType.TEXT_AREA,
						placeholder: 'Reservation notes...'
					}}
					value={this.state.notes}
					onChange={this.handleFormChange}
				/>
			</>
		)
	}

	buildButtonRow = () => {
		const { participants, reservationID, showConfirmDelete } = this.state
		const disableButton = isNullOrUndefined(oc(participants)[0].participant.label())
		let deleteText = null
		let removeButtonHandler = null

		if (reservationID) {
			deleteText = `${(showConfirmDelete) ? 'Confirm Cancellation?' : 'Cancel Reservation'}`
			removeButtonHandler = this.handleDelete
		}

		return (
			<ButtonRow
				disableButton={disableButton}
				submitButtonName={this.submitButton}
				submitButtonHandler={this.handleSubmit}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.handleCancel}
				removeButtonName={deleteText}
				removeButtonHandler={removeButtonHandler}
			/>
		)
	}

	buildGolfOptionInputs = () => {
		const golfOptionInputs: FormInput[] = [
			{
				title: 'Holes',
				property: 'holeCount',
				type: FormInputType.GROUPED_SELECT,
				selectItems: [{ label: '9', value: '9' }, { label: '18', value: '18' }],
				defaultValue: null,
			},
			{
				title: 'Carts',
				property: 'transport',
				type: FormInputType.GROUPED_SELECT,
				selectItems: [{ label: '1', value: '1' }, { label: '2', value: '2' }, { label: '3', value: '3' }],
				defaultValue: null,
			}
		]

		const selectInputs: JSX.Element[] = golfOptionInputs.map((input: FormInput) => {
			return (
				<div className='selectgroup' key={input.title}>
					<RS.Label className='form-label'>
						<span>{input.title}</span>
					</RS.Label>
					<SelectInput
						item={input}
						onChange={(property, data) => this.handleFormChange({ target: { name: property, value: data } })}
						value={(this.state as any)[input.property]}
						onBlur={() => null}
					/>
				</div>
			)
		})

		return (
			<>
				<UnderlineHeader title={'OPTIONS'} />
				<RS.FormGroup className='hole-select-container d-flex'>
					{selectInputs}
				</RS.FormGroup>
			</>
		)
	}

	buildGuestModal = () => {
		if (!this.state.guestModal) { return }

		return (
			<GuestModal
				club={this.props.loggedInClub}
				handleNewGuest={this.handleAddGuest}
				handleToggle={this.closeGuestModal}
			/>
		)
	}

	// ----------------------------------------------------------------------------------
	// General Content Builders
	// ----------------------------------------------------------------------------------

	render() {
		const { loggedInClub } = this.props
		if (this.state.loading) { return <DimmedLoader component={null} isLoading={true} /> }
		if (this.state.error) { return <ErrorComponent club={loggedInClub} isAdmin={this.props.userState.isAdmin} /> }
		return (
			<RS.Col sm={12} md={6} className='customer-reservation-container'>
				<BackHeader to={this.backRoute} backTitle={this.backTitle} />
				{this.buildContent()}
				{this.buildGuestModal()}
			</RS.Col>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	userState: state.user,
	calendarState: state.calendar,
	eventState: state.event,
	isCustomerView: inCustomerViewSelector(state),
	bookableMembers: bookableMembers(state),
	eventsByID: eventsByIDSelector(state),
	loggedInClub: state.club.loggedInClub,
})

const mapDispatchToProps = {
	...AlertActions,
	...CustomerActions,
	...EventActions,
	...LotteryActions
}

const enhance = compose<React.ComponentType<{}>>(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(ConfirmReservationComponent)
