// Internal Dependencies
import { ReactSelectItem, InputSelectionItem } from '../components/Shared/Form'
import { Omit, GeneralMap } from './interface'
import { DayOfWeek } from '../constants'
import { uniq, sortBy } from 'underscore'

export interface SelectedDayOfWeek extends Omit<ReactSelectItem, 'value'> {
	value: DayOfWeek
}

/**
 * Returns a string representation of the selected days of the week,
 * grouped together in consecutive ranges.
 *
 * If a user selected:
 * 	Monday, Tuesday, Wednesday, Thursday, and Saturday
 *
 * The result from this function would be:
 * 	'Monday - Thursday, Saturday'
 */
export const dayOfWeekRanges = (days: SelectedDayOfWeek[]): string => {
	// Sort the Days
	const sortedDays = days.sort((a, b) => a.value - b.value)

	const ranges: Array<{ start: SelectedDayOfWeek, end: SelectedDayOfWeek }> = []
	let prevDay: SelectedDayOfWeek
	let currentRangeStart: SelectedDayOfWeek
	for (let i = 0; i < sortedDays.length; i++) {
		const currentDay = sortedDays[i]

		// Check if we are starting the loop
		if (i === 0) {
			prevDay = currentDay
			currentRangeStart = currentDay

			// Check if we are also on the last iteration
			if (i === sortedDays.length - 1) {
				ranges.push({ start: currentRangeStart, end: currentDay })
			}
			continue
		}

		// Check if we are on the last iteration of the loop
		if (i === sortedDays.length - 1) {
			// Check if we are part of a consecutive range
			if (currentDay.value === prevDay.value + 1) {
				ranges.push({ start: currentRangeStart, end: currentDay })
			} else {
				ranges.push({ start: currentRangeStart, end: prevDay })
				ranges.push({ start: currentDay, end: currentDay })
			}
			continue
		}

		// Check if we are part of a consecutive range
		if (currentDay.value === prevDay.value + 1) {
			prevDay = currentDay
			continue
		} else {
			// A consecutive range has ended, add it to the list
			// and start a new range with the current day
			ranges.push({ start: currentRangeStart, end: prevDay })
			prevDay = currentDay
			currentRangeStart = currentDay
			continue
		}
	}

	return ranges.map((range) => {
		if (range.start.value === range.end.value) {
			return range.start.label
		}
		return (`${range.start.label} - ${range.end.label}`)
	}).join(', ')
}

export const isDateValid = (date: Date) => {
	return !isNaN(date.getTime())
}

export const beginningOfDay = (isoString: string) => {
	const date = new Date(isoString)
	date.setHours(0, 0, 0, 0)
	return date
}

export const endOfDay = (isoString: string) => {
	const date = new Date(isoString)
	date.setHours(23, 59, 59, 0)
	return date
}

export const dateAndTime = (date: Date) => {
	const options = { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }
	return date.toLocaleDateString('us-EN', options)
}

export const timeAndPeriod = (date: Date) => {
	const options = { hour: 'numeric', minute: 'numeric' }
	return date.toLocaleTimeString('en-US', options)
}

export const timeString = (date: Date) => {
	if (!date) { return '' }

	if (typeof date === 'string') {
		date = new Date(date)
	}
	const options = { hour: '2-digit', minute: '2-digit' }
	return date.toLocaleTimeString('en-US', options)
}

export const dateString = (date: Date) => {
	if (!date) { return '' }
	if (typeof date === 'string') {
		date = new Date(date)
	}
	const options = { month: 'numeric', day: 'numeric', year: 'numeric' }
	return date.toLocaleDateString('en-US', options)
}

export const dayString = (date: Date) => {
	if (!date) { return '' }
	if (typeof date === 'string') {
		date = new Date(date)
	}
	const weekdayOptions = { weekday: 'long' }
	return date.toLocaleDateString('en-US', weekdayOptions)
}

export const dateIsInPast = (date: Date) => {
	const current = new Date()
	return current.getTime() > date.getTime()
}

export const setTimeForDate = (date: Date, dateWithTime: Date) => {
	date.setHours(dateWithTime.getHours(), dateWithTime.getMinutes(), dateWithTime.getSeconds())
	return date
}

/**
 * Returns a date rounded up to the nearest hour.
 * If no date is passed in, the starting date is set to the current time.
 * @param d Date object.
 * @returns Date object.
 */
export const roundDateToNextHour = (d?: Date) => {
	const date = (d) ? d : new Date()
	date.setHours(date.getHours() + Math.ceil(date.getMinutes() / 60))
	date.setMinutes(0)
	return new Date(date)
}

/**
 * Adds the specified amount of minutes to a date.
 * @param date Date Object.
 * @param numMinutes Number of minutes.
 */
export const addMinutesToDate = (date: Date, numMinutes: number) => {
	return new Date(date.getTime() + (numMinutes * 60000))
}

/**
 * To be used as the sorting function for an array of Date objects
 * or objects that contain a date field that we want to sort on.
 *
 * e.g. [new Date(), new Date(1)].sort(sortDates)
 *
 * e.g. [{ fieldOne: new Date(), fieldTwo: 2 }, { fieldOne: new Date(1), fieldTwo: 4 }].sort((a, b) => sortDates(a, b, 'fieldOne'))
 *
 * @param a the first parameter of the .sort() compareFn
 * @param b the second parameter of the .sort() compareFn
 * @param field an optional field to pass if you need to sort on a date within an object
 * @returns A number that will determine the ordering of elements 'a' and 'b' (-1, 1, or 0)
 */
export const sortDates = (a: Date | GeneralMap<any>, b: Date | GeneralMap<any>, field?: string) => {
	const valueOne = (field) ? (a as GeneralMap<any>)[field] : a
	const valueTwo = (field) ? (b as GeneralMap<any>)[field] : b

	const dateOne = new Date(valueOne).valueOf()
	const dateTwo = new Date(valueTwo).valueOf()

	if (dateOne < dateTwo) {
		return -1
	}
	if (dateOne > dateTwo) {
		return 1
	}
	return 0
}

export const inputSelectionItemsForRange = (start: Date, end: Date, interval: number, additionalTimes?: Date[]): InputSelectionItem[] => {
	let startTime = start.getTime()
	const endTime = end.getTime()
	const difference = endTime - startTime
	const items = difference / (interval * 60 * 1000)

	const selectionItems: any = []
	for (let i = 0; i < items; i++) {
		const date = new Date(startTime)
		selectionItems.push({ value: date.getTime(), label: timeAndPeriod(date) })
		startTime += (interval * 1000 * 60)
	}

	if (additionalTimes) {
		additionalTimes.map((additionalDate: Date) => {
			const date = new Date(additionalDate)
			selectionItems.push({ value: date.getTime(), label: timeAndPeriod(date) })
		})
	}
	const distinctItems = uniq(selectionItems, 'value')
	return sortBy(distinctItems, (item: InputSelectionItem) => item.value) as InputSelectionItem[]
}
