// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as RS from 'reactstrap'
import * as queryString from 'query-string'
import * as Feather from 'react-feather'
import { connect } from 'react-redux'
import { flatten } from 'underscore'
import { isNullOrUndefined } from 'util'
import { RouteComponentProps } from 'react-router'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies

// State
import { RootReducerState } from '../../reducers/index'
import { inCustomerViewSelector } from '../../reducers/user'
import { calendarsByIDSelector } from '../../reducers/calendar'

// Actions
import { EventActions, AlertActions, CalendarActions } from '../../actions/index'

// Components
import {
	PageHeaderSelectInput,
	PageHeaderInputType
} from '../Shared/PageHeader'
import { HeaderButton, ButtonType } from '../Shared/ButtonGroup'
import ErrorComponent from '../Shared/ErrorComponent'
import TableComponent from '../Shared/Table'
import ModalComponent from '../Shared/Modal'
import { InputSelectionItem } from '../Shared/Form'
import ReservationListComponent from '../Shared/ReservationList'
import DimmedLoader from '../Shared/DimmedLoader'
import EmptyContent, { ContentType } from '../Shared/EmptyContent'
import ClubTypes from '../Shared/ClubTypes'

// Tables
import { ServiceTableColumns, ServiceTableRow } from './table'
import { TabBarButtonInput } from '../Shared/TabBar'
import TableHeader from '../Shared/TableHeader'

// Helpers
import * as Constants from '../../constants'
import { setStateAsync } from '../../helpers/promise'
import { calendarIDForEvent } from '../../helpers/event'
import { QueryOptions } from '../../helpers/interface'
import { userForForm } from '../../helpers/user'
import { clubResourceTypeBadge } from '../../helpers/badge'

enum ServiceResourceTabs {
	Upcoming = 'service',
	Past = 'past',
	Types = 'types',
}

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	selectedGroupID: null as string | null,
	selectedCalendarID: null as string | null,
	selectedUserID: null as string | null,
	eventToCancel: null as string | null,
	reservationToCancel: null as string | null,
	error: false,
	initialLoad: true,
	loading: false,
	pageIndex: 0,
	viewingPast: false,
	viewingTab: null as ServiceResourceTabs | null,
	showingServiceTypeCreationModal: false
}

type Props = RouteComponentProps & ConnectedState & ConnectedActions
type State = typeof initialState

class EventComponent extends React.Component<Props, State> {
	private pageSize: number

	constructor(props: Props) {
		super(props)
		this.pageSize = 20

		// Get the service calendar group and set the ID on state.
		const calendarGroups = oc(this).props.loggedInClub.calendarGroups([])
		const defaultGroup = calendarGroups.find((cg) => cg.type === core.Calendar.GroupType.Service)

		this.state = {
			...initialState,
			selectedGroupID: `${defaultGroup._id}`
		}
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		if (!this.state.selectedGroupID) {
			this.props.fireFlashMessage(`Failed to find Service Providers.`, Constants.FlashType.DANGER)
			await setStateAsync(this, { initialLoad: false, error: true })
			return
		}

		// Customer
		if (this.props.isCustomerView) {
			// Fetch the Calendars for the Customer
			const queryParams: QueryOptions = { limit: 0, offset: 0, groupID: this.state.selectedGroupID }
			const [fetchCalendarsErr] = await to(this.props.fetchCalendars(queryParams) as any)
			if (fetchCalendarsErr) {
				this.props.fireFlashMessage(`Failed to fetch Providers. ${fetchCalendarsErr.message}`, Constants.FlashType.DANGER)
				await setStateAsync(this, { initialLoad: false, error: true })
				return
			}

			// Fetch the Events for the Customer
			queryParams.groupIDs = [this.state.selectedGroupID]
			const [fetchEventsForUserErr] = await to(this.props.fetchEventsForUser(queryParams) as any)
			if (fetchEventsForUserErr) {
				this.props.fireFlashMessage(`Failed to fetch Services. ${fetchEventsForUserErr.message}`, Constants.FlashType.DANGER)
				await setStateAsync(this, { initialLoad: false, error: true })
				return
			}
		} else {
			const [err] = await to(this.fetchEvents())
			if (err) {
				this.props.fireFlashMessage(`Failed to fetch Services. ${err.message}`, Constants.FlashType.DANGER)
				await setStateAsync(this, { initialLoad: false, error: true })
				return
			}
		}

		await setStateAsync(this, { initialLoad: false })
	}

	fetchEvents = async () => {
		await setStateAsync(this, { loading: true })

		const eventQueryParams = this.buildQueryParams()
		const [err] = await to(this.props.fetchEvents(eventQueryParams) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to fetch Services. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}
		await setStateAsync(this, { loading: false })
	}

	buildQueryParams = (): core.IShared.QueryOptions => {
		const currentDate = new Date().toISOString()
		const selectedCalendarIDs = (this.state.selectedCalendarID && this.state.selectedCalendarID !== 'All') ? [this.state.selectedCalendarID] : undefined

		const queryParams: core.IShared.QueryOptions = {}
		queryParams.limit = this.pageSize
		queryParams.offset = this.pageSize * this.state.pageIndex
		queryParams.groupID = this.state.selectedGroupID
		queryParams.calendarIDs = selectedCalendarIDs
		queryParams.hasReservations = true

		if (this.state.viewingPast) {
			queryParams.end = currentDate
			queryParams.timeField = 'end'
		} else {
			queryParams.start = currentDate
			queryParams.timeField = 'start'
		}
		return queryParams
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Dropdown Actions
	// ----------------------------------------------------------------------------------

	handleTypeTabChange = async (tabTitle: ServiceResourceTabs) => {
		const originalTab = this.state.viewingTab
		const viewingPast = tabTitle === ServiceResourceTabs.Past
		await this.setState({ viewingTab: tabTitle, viewingPast, pageIndex: 0 })
		if (originalTab !== tabTitle) {
			await this.fetchEvents()
		}
	}

	/**
	 * Determines which action to take, based on which dropdown item
	 * the user selected in the table's dropdown menu
	 */
	handleTableDropdownAction = (e: any) => {
		const [action, value] = e
		switch (action) {
			case 'editEvent':
				return this.handleEditEvent(value)
			case 'cancelEvent':
				return this.handleCancelEvent(value)
			default:
				break
		}
	}

	/**
	 * Handle row clicks by displaying event detail.
	 */
	handleTableRowClicked = (row: ServiceTableRow): any => {
		const queryParams = { reservationID: row.serviceReservationID, eventID: row._id }
		const location = {
			pathname: Constants.VIEW_SCHEDULED_SERVICE_ROUTE,
			search: queryString.stringify(queryParams)
		}
		this.props.history.push(location)
	}

	/**
	 * When editing an Event, navigate to the Event update
	 * form and pass the Event's ID via the query parameters
	 */
	handleEditEvent = (reservationID: string) => {
		// Find the Service with the Reservation
		const service = this.props.eventState.events.find((e) => {
			return !isNullOrUndefined(e.reservations.find((r) => `${r._id}` === reservationID))
		})

		// Query params
		const queryParams = {
			serviceID: `${service._id}`.split(Constants.EVENT_DATE_PREFIX)[0],
			reservationID: reservationID,
		}

		const location = {
			pathname: Constants.UPDATE_SCHEDULED_SERVICE_ROUTE,
			search: queryString.stringify(queryParams)
		}

		this.props.history.push(location)
	}

	/**
	 * When attempting to cancel an Event, display the
	 * Event cancellation confirmation modal
	 */
	handleCancelEvent = async (reservationID: string) => {
		// Find the Service with the Reservation
		const service = this.props.eventState.events.find((e) => {
			return !isNullOrUndefined(e.reservations.find((r) => `${r._id}` === reservationID))
		})

		const actualEventID = `${service._id}`.split(Constants.EVENT_DATE_PREFIX)[0]
		return setStateAsync(this, { eventToCancel: actualEventID, reservationToCancel: reservationID })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Modal Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Make the API call to cancel the Event, when the
	 * user confirms the cancellation via the modal
	 */
	executeEventDeletion = async () => {
		const reservationID = this.state.reservationToCancel
		const eventID = this.state.eventToCancel
		const groupID = this.state.selectedGroupID
		await setStateAsync(this, { loading: true })
		const currentPage = this.state.pageIndex
		const [deleteErr] = await to(this.props.cancelEventRsvp(reservationID, eventID, currentPage, this.pageSize, groupID) as any)
		if (deleteErr) {
			this.props.fireFlashMessage(`Problem trying to cancel Service. ${deleteErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true, eventToCancel: null, reservationToCancel: null })
			return
		}

		this.props.fireFlashMessage('Successfully cancelled Service', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, eventToCancel: null, reservationToCancel: null })
	}

	/**
	 * Hide the cancellation modal when the user closes it
	 */
	closeCancelEventModal = async () => {
		return setStateAsync(this, { eventToCancel: null, reservationToCancel: null })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Handle table pagination
	 */
	handlePage = async (data: any) => {
		const { pageIndex } = this.state

		// Check if the new page is different that the current one
		if (data.selected !== pageIndex) {
			await setStateAsync(this, { pageIndex: data.selected })
			await this.fetchEvents()
		}
	}

	/**
	 * Handle a column header of the table being selected
	 */
	handleTableHeaderAction = (e: any) => {
		// TODO: Add this functionality
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Header Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Determine which action to take, based on the header
	 * button that the user selected
	 */
	handleHeaderButtonAction = async (e: any) => {
		const action = e.target.value
		switch (action) {
			case ServiceResourceTabs.Upcoming:
				return this.handleCreateService()
			case ServiceResourceTabs.Past:
				return this.handleCreateService()
			case ServiceResourceTabs.Types:
				return this.openTypeCreationModal()
			default:
				break
		}
	}

	/**
	 * Navigate to the Service creation form
	 */
	handleCreateService = () => {
		this.props.history.push(Constants.CREATE_SCHEDULED_SERVICE_ROUTE)
	}

	handleCalendarChange = async (data: InputSelectionItem) => {
		await setStateAsync(this, { selectedCalendarID: data.value, pageIndex: 0 })
		await this.fetchEvents()
	}

	handleUserChange = async (data: InputSelectionItem) => {
		await setStateAsync(this, { selectedUserID: data.value })
	}

	openTypeCreationModal = async () => {
		await setStateAsync(this, () => ({ showingServiceTypeCreationModal: true }))
	}

	closeTypeCreationModal = async () => {
		await setStateAsync(this, () => ({ showingServiceTypeCreationModal: false }))
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildCancelConfirmationModal = () => {
		if (!this.state.eventToCancel || !this.state.reservationToCancel) { return null }
		return (
			<ModalComponent
				modalTitle={'Cancel Service'}
				primaryMessage={'Are you sure you want to cancel this Service?'}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.closeCancelEventModal}
				submitButtonName={'Confirm'}
				submitButtonHandler={this.executeEventDeletion}
			/>
		)
	}

	buildButtonItem = () => {
		const { viewingTab } = this.state
		switch (viewingTab) {
			case ServiceResourceTabs.Types:
				return this.buildHeaderButton(ServiceResourceTabs.Types, 'New Type')
			default:
				return this.buildHeaderButton(ServiceResourceTabs.Upcoming, 'New Service')
				break
		}
	}

	buildHeaderButton = (type: string, text: string): any => {
		const button = {
			value: type,
			type: ButtonType.DEFAULT,
			text: text,
			color: 'primary',
		}
		return { inputType: PageHeaderInputType.Button, button }
	}

	buildPageHeader = () => {
		const { isCustomerView } = this.props

		const headerInputs: PageHeaderSelectInput[] = (this.props.isCustomerView) ? null : [
			this.buildProviderSelect(),
			this.buildUserSelect(),
		]

		const buttons: TabBarButtonInput[] = [
			{ title: 'Upcoming', onClick: () => { this.handleTypeTabChange(ServiceResourceTabs.Upcoming) } },
			{ title: 'Past', onClick: () => { this.handleTypeTabChange(ServiceResourceTabs.Past) } },
		]

		if (!isCustomerView) {
			buttons.push(
				{ title: 'Types', onClick: () => this.handleTypeTabChange(ServiceResourceTabs.Types) },
			)
		}

		const buttonItem = this.buildButtonItem()
		const inputs = [...headerInputs, ...buttons, buttonItem]
		return (
			<TableHeader
				fullScreen={!this.props.isCustomerView}
				tabInputs={buttons}
				pageTitle='Services'
				inputs={inputs}
				buttonHandler={this.handleHeaderButtonAction}
			/>
		)
	}

	buildProviderSelect = (): PageHeaderSelectInput => {
		const { loggedInClub, calendarState } = this.props
		const calendarGroups = loggedInClub.calendarGroups

		const calendars = calendarState.calendars
		const serviceProviderCalendarGroup = calendarGroups.find((cg) => cg.type === core.Calendar.GroupType.Service)
		const serviceProviders = calendars.filter((c) => c.groupID === serviceProviderCalendarGroup._id)

		// Define the options for Select Component
		const providersForSelect = serviceProviders.map((sp) => {
			return {
				label: sp.name,
				value: `${sp._id}`,
			}
		})
		const defaultSelection = { label: 'All Providers', value: 'All' }
		const allProvidersForSelect = [defaultSelection, ...providersForSelect]

		// Determine what the value of the Select Component will be
		const selectedCalendarID = this.state.selectedCalendarID
		const selectedCalendar = (selectedCalendarID) ? serviceProviders.find((sp) => `${sp._id}` === selectedCalendarID) : undefined
		const selectedValue = (selectedCalendar) ? { label: selectedCalendar.name, value: `${selectedCalendar._id}` } : defaultSelection

		return {
			inputType: PageHeaderInputType.Select,
			className: 'serviceHeader-service-user-input',
			faIcon: 'fas fa-wrench',
			selected: selectedValue,
			inputs: allProvidersForSelect,
			changeHandler: this.handleCalendarChange,
		}
	}

	buildUserSelect = (): PageHeaderSelectInput => {
		const users = oc(this).props.userState.users([])

		// Define the options for Select Component
		const usersForSelect = users.map((user) => userForForm(user))
		const defaultSelection = { label: 'All Members', value: 'All' }
		const allUsersForSelect = [defaultSelection, ...usersForSelect]

		// Determine what the value of the Select Component will be
		const selectedUserID = this.state.selectedUserID
		const selectedUser = (selectedUserID) ? users.find((user) => `${user._id}` === `${selectedUserID}`) : undefined
		const selectedValue = (selectedUser) ? userForForm(selectedUser) : defaultSelection

		return {
			inputType: PageHeaderInputType.Select,
			className: 'serviceHeader-service-provider-input',
			icon: Feather.User,
			selected: selectedValue,
			inputs: allUsersForSelect,
			changeHandler: this.handleUserChange,
		}
	}

	buildCustomerView = () => {
		const eventState = this.props.eventState
		if (!eventState.events || eventState.events.length === 0) { return this.buildEmptyCustomerView() }

		const reservationList = (
			<ReservationListComponent events={eventState.events} />
		)

		return (
			<RS.Col md={8} className={'no-padding'}>
				{this.buildPageHeader()}
				<DimmedLoader component={reservationList} isLoading={this.state.loading} />
			</RS.Col>
		)
	}

	buildEmptyCustomerView = () => {
		return (
			<EmptyContent type={ContentType.Services} actionHandler={this.handleCreateService} />
		)
	}

	buildAdminView = () => {
		return (
			<RS.Col className={'no-padding'}>
				{this.buildPageHeader()}

				<div className='service-table-container'>
					{this.buildServiceTable()}
				</div>

				{this.buildCancelConfirmationModal()}
			</RS.Col>
		)
	}

	buildServiceTable = () => {
		const { loggedInClub, isCustomerView, calendarsByID, userState, eventState } = this.props
		const { selectedUserID, viewingPast, pageIndex, loading } = this.state

		const { viewingTab, showingServiceTypeCreationModal } = this.state

		if (viewingTab === ServiceResourceTabs.Types) {
			return (
				<div className='event-table-container'>
					<ClubTypes
						resourceType={'services'}
						showingEditModal={showingServiceTypeCreationModal}
						onEditModalClose={() => this.closeTypeCreationModal()}
					/>
				</div>
			)
		}

		const total = eventState.eventCount

		// TODO: Check that deleting a Service Provider, and then coming to this view doesn't blow up the app - JD

		// If there is a selected User, find all the Events that they have reservations for
		const servicesForUser = (!selectedUserID || selectedUserID === 'All') ?
			eventState.events :
			eventState.events.filter((e) => e.reservations.find((r) => r.owner === selectedUserID))

		const users = oc(userState).users([])

		const servicesForTable: ServiceTableRow[][] = servicesForUser.map((service) => {
			const calendarID = calendarIDForEvent(service)
			const serviceProvider = calendarsByID[`${calendarID}`].name
			return service.reservations.map((reservation) => {
				const reservationOwner = users.find((user) => `${user._id}` === `${reservation.owner}`)
				const reservationVehicle: core.SubModels.CarMeta.Vehicle = reservationOwner.meta.car.vehicles.find((vehicle: core.SubModels.CarMeta.Vehicle) => {
					if (!reservation.meta) { return false }
					return `${vehicle._id}` === `${(reservation.meta as core.Event.CarReservationMeta).vehicleID}`
				})
				const serviceName = (reservationVehicle) ? `${reservationVehicle.year} ${reservationVehicle.make} ${reservationVehicle.model}` : 'Vehicle'
				const serviceReservationID = `${reservation._id}`
				const serviceTypeID = (reservation.meta as core.Event.CarReservationMeta).type || ''
				const serviceTypeBadge = clubResourceTypeBadge(loggedInClub, 'services', `${serviceTypeID}`)
				return { ...service, serviceProvider, serviceName, serviceReservationID, owner: reservationOwner, serviceTypeBadge }
			})
		})
		const flattenedServicesForTable: ServiceTableRow[] = flatten(servicesForTable)

		// Hide the table dropdown items from Customers
		const columnsForTable = (isCustomerView) ?
			ServiceTableColumns.slice(0, ServiceTableColumns.length - 1) :
			ServiceTableColumns

		if (total === 0) {
			return (<EmptyContent type={ContentType.Services} isInPast={viewingPast} />)
		}
		return (
			<TableComponent<core.Event.Model>
				columns={columnsForTable}
				rowItems={flattenedServicesForTable}
				showPaging={true}
				currentPage={pageIndex}
				pageHandler={this.handlePage}
				totalResults={total}
				pageSize={this.pageSize}
				actionHandler={this.handleTableHeaderAction}
				dropdownHandler={this.handleTableDropdownAction}
				onTableRowClick={this.handleTableRowClicked}
				isLoading={loading}
			/>
		)
	}

	buildContent = () => {
		return (this.props.isCustomerView) ? this.buildCustomerView() : this.buildAdminView()
	}

	render() {
		const { loggedInClub } = this.props
		if (this.state.initialLoad) { return <DimmedLoader component={null} isLoading={true} /> }
		if (this.state.error) { return <ErrorComponent club={loggedInClub} isAdmin={this.props.userState.isAdmin} /> }

		return (
			<RS.Row className='justify-content-center scheduled-services-container'>
				{this.buildContent()}
			</RS.Row >
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	eventState: state.event,
	userState: state.user,
	calendarState: state.calendar,
	loggedInClub: state.club.loggedInClub,
	isCustomerView: inCustomerViewSelector(state),
	calendarsByID: calendarsByIDSelector(state),
})

const mapDispatchToProps = {
	...CalendarActions,
	...EventActions,
	...AlertActions
}

export default connect(mapStateToProps, mapDispatchToProps)(EventComponent)
