// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import to from 'await-to-js'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { oc } from 'ts-optchain'

// Actions
import { AlertActions, ClubActions, EventActions } from '../../../actions/index'

// State
import { RootReducerState } from '../../../reducers'

// Form
import { BlockModalInputs, BlockModalState } from './form'

// Components
import FormModal from '../../Shared/Formik/FormModal'
import RecurrenceModal from '../RecurrenceModal'
import EventUpdateModal from '../EventUpdateModal'
import EventDeletionModal from '../EventDeletionModal'
import { RecurrenceModalState } from '../RecurrenceModal/form'
import { InputSelectionItem } from '../../Shared/Form'

// Helpers
import { buildCustomRecurrenceString } from '../../../helpers/recurrence'
import { inputSelectionItemsForRange } from '../../../helpers/date'
import { EditorInput } from '../../Shared/Formik/InputPrimitives/EditorInput'
import * as Constants from '../../../constants'

interface ComponentProps {
	event: core.Event.Model
	start?: Date | string
	recurring?: core.Event.RecurringFrequency
	recurringEnd?: Date
	setting: core.Calendar.ReservationSetting
	blockCount?: number
	refreshEvents: () => void
	handleToggle: () => void
}

const initialState = {
	recurringStart: null as Date,
	start: null as Date,
	end: null as Date,
	title: '',
	recurring: null as core.Event.RecurringFrequency,
	recurringEnd: null as Date,
	duration: null as number,
	hours: null as core.IShared.HoursOfOperation,
	confirmingDelete: false,
	loading: false,
	currentModalState: null as BlockModalState,
	showingUpdateModal: false,
	showingDeletionModal: false,
	showingRecurrenceModal: false,
	formHasChanged: false
}

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

type Props = ConnectedActions & ComponentProps & ConnectedState
type State = typeof initialState

/**
 * The `BlockModal` provides UI for blocking a calendar.
 */
class BlockModal extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)

		const state = { ...initialState }
		state.title = oc(props).event.name() as string

		if (props.event) {
			const hours = this.getHoursOfOperation(props.event.start)
			const duration = oc(hours).duration(props.setting.bookingDuration)
			state.recurringStart = oc(props).event.rRule.dtstart()
			state.start = props.event.start
			state.end = props.event.end
			state.duration = duration
			state.hours = hours
			state.recurring = oc(this).props.event.recurring()
			state.recurringEnd = oc(this).props.event.recurringEnd()
		} else {
			const start = new Date(props.start)
			const hours = this.getHoursOfOperation(start)
			const duration = oc(hours).duration(props.setting.bookingDuration)
			const end = start.getTime() + duration * 60 * 1000 // Add minutes
			state.start = start
			state.end = new Date(end)
			state.duration = duration
			state.hours = hours
			state.recurring = this.props.recurring
			state.recurringEnd = this.props.recurringEnd
		}
		this.state = { ...state }
	}

	// ----------------------------------------------------------------------------------
	// Handlers
	// ----------------------------------------------------------------------------------

	handleDeleteButton = async () => {
		if (!this.state.confirmingDelete) {
			return this.setState({ confirmingDelete: true })
		}
		this.deleteBlockEvent()
	}

	handleChange = async (field: string, item: InputSelectionItem) => {
		this.setState({ formHasChanged: true })
	}

	handleSelect = async (field: string, item: InputSelectionItem) => {
		if (field === 'recurring') {
			this.handleRecurrenceSelection(item)
		}
	}

	handleRecurrenceSelection = async (item: InputSelectionItem) => {
		if (item.value === 'custom') {
			this.setState({ showingRecurrenceModal: true })
			return
		}

		const recurring = item.value === 'none' ? null : item.value
		this.setState({ recurring: recurring, recurringEnd: null })
	}

	// ----------------------------------------------------------------------------------
	// Block Deletion Handlers
	// ----------------------------------------------------------------------------------

	handleDisplayDeletionModal = async () => {
		this.setState({ showingDeletionModal: true })
	}

	// ----------------------------------------------------------------------------------
	// Update Confirmation Modal
	// ----------------------------------------------------------------------------------

	handleDisplayConfirmUpdateModal = async (modalState: BlockModalState) => {
		this.setState({ showingUpdateModal: true, currentModalState: modalState })
	}

	handleCloseConfirmUpdateModal = async () => {
		this.setState({ showingRecurrenceModal: false })
	}

	// ----------------------------------------------------------------------------------
	// Recurrence Modal Handlers
	// ----------------------------------------------------------------------------------

	handleRecurrenceModalSave = async (form: RecurrenceModalState) => {
		const recurring = Number(form.recurring.value) as any
		const recurringEnd = form.recurringEnd
		this.setState({ recurring, recurringEnd, showingRecurrenceModal: false })
	}

	handleRecurrenceModalClose = async () => {
		this.setState({ showingRecurrenceModal: false })
	}

	handleUpdateWithType = (type: core.Event.UpdateType) => {
		this.updateBlockEvent(this.state.currentModalState!, type)
	}

	// ----------------------------------------------------------------------------------
	// Block Network Requests
	// ----------------------------------------------------------------------------------

	submitBlockEvent = async (state: BlockModalState) => {
		this.setState({ loading: true })
		if (this.props.event && this.props.event.blockCalendar && !this.state.formHasChanged) {
			return this.props.handleToggle()
		}

		if (oc(this).props.event()) {
			if (oc(this).props.event.rRule()) {
				return this.handleDisplayConfirmUpdateModal(state)
			}
			this.updateBlockEvent(state)
		} else {
			this.saveBlockEvent(state)
		}
	}

	saveBlockEvent = async (form?: BlockModalState, updateAllDates?: boolean) => {
		const { createEvent, fireFlashMessage } = this.props

		const payload = this.buildEventPayload(form, updateAllDates)
		const [err] = await to(createEvent(payload) as any)
		if (err) {
			fireFlashMessage(`Problem creating Block. ${err.message}`, Constants.FlashType.DANGER)
			this.setState({ loading: false })
			return
		}

		await this.props.refreshEvents()
		this.props.handleToggle()
		fireFlashMessage(`Tee Sheet Block Created`, Constants.FlashType.SUCCESS)
	}

	updateBlockEvent = async (form?: BlockModalState, type?: core.Event.UpdateType) => {
		const { updateEvent, fireFlashMessage } = this.props
		const group = this.getCalendarGroup()

		const eventID = oc(this).props.event._id()
		const payload = this.buildEventPayload(form, true)
		const [err] = await to(updateEvent(`${eventID}`, `${group._id}`, payload, type) as any)
		if (err) {
			fireFlashMessage(`Problem updating Block. ${err.message}`, Constants.FlashType.DANGER)
			this.setState({ loading: false })
			return
		}

		await this.props.refreshEvents()
		this.props.handleToggle()
		fireFlashMessage(`Tee Sheet Block Updated`, Constants.FlashType.SUCCESS)
	}

	deleteBlockEvent = async (date?: Date, toggle?: boolean) => {
		this.setState({ loading: true })

		const { deleteEvent, fireFlashMessage } = this.props

		const eventID = oc(this).props.event._id()
		const dateToDelete = date ? new Date(date) : null
		const [err] = await to(deleteEvent(`${eventID}`, dateToDelete) as any)
		if (err) {
			fireFlashMessage(`Problem deleting Block. ${err.message}`, Constants.FlashType.DANGER)
			this.setState({ loading: false })
			return
		}

		// Toggle if needed.

		await this.props.refreshEvents()
		this.props.handleToggle()
		fireFlashMessage(`Tee Sheet Block Deleted`, Constants.FlashType.SUCCESS)

	}

	// ----------------------------------------------------------------------------------
	// Component Builders
	// ----------------------------------------------------------------------------------

	buildDeletionModal = () => {
		return (
			<EventDeletionModal
				isOpen={this.state.showingDeletionModal}
				title={'Delete Recurring Block'}
				message={'Would you delete all blocks or only this one?'}
				name={'Block'}
				event={this.props.event}
				handleDeleteEvent={this.deleteBlockEvent}
				handleToggle={this.props.handleToggle}
			/>
		)
	}

	buildUpdateConfirmationModal = () => {
		return (
			<EventUpdateModal
				isOpen={this.state.showingUpdateModal}
				title={'Update Recurring Block'}
				message={'Would you  update all blocks or only this one?'}
				name={'Block'}
				handleUpdateType={this.handleUpdateWithType}
				handleToggle={this.props.handleToggle}
			/>
		)
	}

	buildRecurrenceModal = () => {
		return (
			<RecurrenceModal
				isOpen={this.state.showingRecurrenceModal}
				recurring={this.state.recurring}
				recurringEnd={this.state.recurringEnd}
				submitButtonHandler={(state) => this.handleRecurrenceModalSave(state)}
				cancelButtonHandler={() => this.handleRecurrenceModalClose()}
			/>)
	}

	buildNestedModal = () => {
		if (this.state.showingRecurrenceModal) {
			return this.buildRecurrenceModal()
		}
		if (this.state.showingUpdateModal) {
			return this.buildUpdateConfirmationModal()
		}
		if (this.state.showingDeletionModal) {
			return this.buildDeletionModal()
		}
	}

	buildBlockModal = () => {
		const title = this.props.event ? 'Update Block' : 'New Block'
		const label = this.getCustomRecurrenceLabel()

		const timeSelectInputs = this.getTimeSelectInputs()
		const formInputs = BlockModalInputs(label, timeSelectInputs)
		const resource: any = this.buildFormResource()

		const existingBlock = this.props.event && this.props.event.blockCalendar
		const submitName = existingBlock ? 'Update' : 'Save'
		const secondaryName = existingBlock ? 'Delete' : null
		const secondaryHandler = existingBlock ? this.handleDisplayDeletionModal : null

		return (
			<FormModal
				enableReinitialize={false}
				className={'block-modal'}
				modalTitle={title}
				formSpec={formInputs}
				formResource={resource}
				onChange={this.handleChange}
				onSelect={this.handleSelect}
				submitting={this.state.loading}
				submitButtonName={submitName}
				submitButtonHandler={this.submitBlockEvent}
				secondaryButtonName={secondaryName}
				secondaryButtonHandler={secondaryHandler}
				dangerSecondary={true}
				cancelButtonHandler={this.props.handleToggle}
				nestedModal={this.buildNestedModal()}
			/>
		)
	}

	public render() {
		return this.buildBlockModal()
	}

	buildFormResource = () => {
		const recurring = this.getCustomRecurrenceValue()
		return {
			title: this.state.title,
			date: this.state.start,
			start: new Date(this.state.start).getTime(),
			end: new Date(this.state.end).getTime(),
			recurring: `${recurring}`,
			recurringEnd: this.state.recurringEnd
		}
	}

	getTimeSelectInputs = () => {
		const hours = oc(this).state.hours()
		if (!hours) { return [] }

		const opens = hours.opens ? new Date(hours.opens) : null
		const closes = hours.closes ? new Date(hours.closes) : null

		const startTime = new Date(this.state.start)
		startTime.setHours(opens.getHours())
		startTime.setMinutes(opens.getMinutes())

		const endTime = new Date(this.state.start)
		endTime.setHours(closes.getHours())
		endTime.setMinutes(closes.getMinutes())

		const additionalTimes = [new Date(this.state.start), new Date(this.state.end)]
		return inputSelectionItemsForRange(startTime, endTime, this.state.duration, additionalTimes)
	}

	getCustomRecurrenceLabel = () => {
		const existingCustomRecurrence = this.state.recurring && this.state.recurringEnd
		if (existingCustomRecurrence) {
			const recurring = Number(this.state.recurring)
			return buildCustomRecurrenceString(recurring, this.state.recurringEnd)
		}
		return 'Custom...'
	}

	getHoursOfOperation = (date: Date) => {
		const dateForHours = date ? new Date(date) : new Date()
		const hours = oc(this).props.setting.hours([])
		return hours.find((hour: core.IShared.HoursOfOperation) => hour.dayOfWeek === dateForHours.getDay())
	}

	getCustomRecurrenceValue = () => {
		const existingCustomRecurrence = this.state.recurring && this.state.recurringEnd
		if (existingCustomRecurrence) {
			return 'custom'
		}
		if (this.state.recurring) {
			return this.state.recurring
		}
		return null
	}

	buildEventPayload = (modalState?: BlockModalState, updateAllDates?: boolean) => {
		const formPayload = new FormData()
		const [start, end] = this.getEventDates(modalState, updateAllDates)
		const calendar = this.getGolfCalendar()
		const recurring = this.state.recurring ? Number(this.state.recurring) : null
		const recurringEnd = this.state.recurringEnd
		const event: any = {
			calendarIDs: [`${calendar!._id}`],
			name: modalState.title,
			start: start.getTime(),
			end: end.getTime(),
			public: false,
			recurring: recurring,
			recurringEnd: recurringEnd,
			blockCalendar: true,
		}
		formPayload.append('event', JSON.stringify(event))
		return formPayload
	}

	getGolfCalendar = () => {
		const group = this.getCalendarGroup()
		const calendars = oc(this).props.calendarState.calendars([])
		return calendars.find((cal: core.Calendar.Model) => `${cal.groupID}` === `${group._id}`)
	}

	getCalendarGroup = () => {
		const calendarGroups = oc(this).props.calendarState.groups([])
		return calendarGroups.find((cg) => cg.type === core.Calendar.GroupType.Golf)
	}

	getEventDates = (modalState?: BlockModalState, updateAllDates?: boolean) => {
		const date = new Date(modalState.date)
		const start = new Date(modalState.start.value)
		start.setMonth(date.getMonth())
		start.setDate(date.getDate())

		const end = new Date(modalState.end.value)
		end.setMonth(date.getMonth())
		end.setDate(date.getDate())
		return [start, end]
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	userState: state.user,
	calendarState: state.calendar
})

const mapDispatchToProps = {
	...AlertActions,
	...ClubActions,
	...EventActions
}

const enhance = compose<React.ComponentType<ComponentProps>>(
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(BlockModal)
