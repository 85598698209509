// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import { oc } from 'ts-optchain'

// Internal Dependencies
import { BaseInputPrimitiveProps } from '../index'

type Props = BaseInputPrimitiveProps

export const NumberInput: React.FunctionComponent<Props> = (props: Props) => {
	return (
		<RS.Input
			{...props as any}
			id={props.id}
			type={props.item.type}
			placeholder={props.item.placeholder}
			value={props.value}
			disabled={props.item.disabled}
			onChange={props.onChange}
			min={oc(props).item.number_min() || ''}
			max={oc(props).item.number_max() || ''}
		/>
	)
}
