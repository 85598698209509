// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import * as core from 'club-hub-core'
import * as queryString from 'query-string'
import * as Feather from 'react-feather'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps, matchPath } from 'react-router'
import { omit, indexBy, without, clone } from 'underscore'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'
import to from 'await-to-js'
import Fuse, { FuseOptions } from 'fuse.js'

// Internal Dependencies

// Actions
import { QueryFilterActions, UserActions, InvitationActions, GroupActions, AlertActions, EventActions } from '../../actions/index'

// State
import { RootReducerState } from '../../reducers'
import { inCustomerViewSelector, userClubIDSelector, usersByIDSelector } from '../../reducers/user'
import { eventsByIDSelector } from '../../reducers/event'

// Components
import PageHeaderComponent, {
	PageHeaderInputType,
	PageHeaderSearchInput,
	PageHeaderSelectInput,
	PageHeaderDropdownInput,
} from '../Shared/PageHeader'
import { ButtonType, HeaderButton } from '../Shared/ButtonGroup'
import TableComponent from '../Shared/Table'
import ErrorComponent from '../Shared/ErrorComponent'
import ModalComponent from '../Shared/Modal'
import AvatarComponent from '../Shared/Avatar'
import Card from '../Shared/Cards/Card'
import PaginationComponent from '../Shared/Pagination'
import DimmedLoader from '../Shared/DimmedLoader'
import QueryFilterComponent from '../Shared/QueryFilter'
import ClubTypes from '../Shared/ClubTypes'
import { DropdownItemType, DropdownItem } from '../Shared/Dropdown'
import FormModal from '../Shared/Formik/FormModal'
import SecuritySettingsForm from '../Settings/SecuritySettingsForm'

// Tables
import TableHeader from '../Shared/TableHeader'
import { TabBarButtonInput } from '../Shared/TabBar'
import { UserTableColumns, UserTableActions } from './table'

// Forms
import { InputSelectionItem, FormInput, FormInputType } from '../Shared/Form'
import { UserQueryFilterFields } from './form'

// Constants
import * as Constants from '../../constants'

// Helpers
import { setStateAsync } from '../../helpers/promise'
import { fullName, getUserSubtitle, getMemberPrimaryPhoneNumber } from '../../helpers/user'
import { clubResourceTypeBadge } from '../../helpers/badge'
import { typeSelectInputs } from '../../helpers/form'
import { buildFuseSearch, FuseItem } from '../../helpers/fuse'

enum UserResourceTabs {
	Members = 'members',
	Types = 'types'
}

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

interface ComponentProps {
	title?: string
	users?: core.User.Model[]
	refreshUsers?: () => Promise<void>
}

// HeaderButtonActions
type HeaderButtonActions =
	typeof CreateUser |
	typeof CreateType |
	typeof DeleteUsers |
	typeof CreateUserGroup |
	typeof MessageUsers |
	typeof UpdateUserMemberships
const CreateUser = 'createUser'
const CreateType = 'createType'
const DeleteUsers = 'deleteUsers'
const CreateUserGroup = 'createUserGroup'
const MessageUsers = 'messageUsers'
const UpdateUserMemberships = 'updateUserMemberships'

// State Key Constants
const ShowingTypeCreationModal = 'showingTypeCreationModal'
const ShowingUsersDeletionModal = 'showingUsersDeletionModal'
const ShowingUpdateMembershipModal = 'showingUpdateMembershipModal'
const ShowingChangePasswordModal = 'ShowingChangePasswordModal'

const initialState = {
	error: false,
	loading: true,
	pageIndex: 0,
	searchTerm: null as string | null,
	tempSearchTerm: null as string | null,
	userToDelete: null as string | null,
	allUsers: null as core.User.Model[] | null,
	selectedFilter: null as InputSelectionItem | null,
	creatingFilter: false,
	pendingFilter: null as core.QueryFilter.Model | null,
	pendingFilterPredicates: [] as core.QueryFilter.Predicate[],
	viewingTab: null as UserResourceTabs | null,
	event: null as core.Event.Model,
	selectedUsers: ({} as any) as { [key: string]: core.User.Model },
	selectingAllUsers: false,
	[ShowingTypeCreationModal]: false,
	[ShowingUsersDeletionModal]: false,
	[ShowingUpdateMembershipModal]: false,
	[ShowingChangePasswordModal]: false
}

type Props = RouteComponentProps & ConnectedState & ConnectedActions & ComponentProps
type State = typeof initialState

/**
 * User Component
 */
class UserComponent extends React.Component<Props, State> {
	private pageSize: number
	private fuseMemberSearch: Fuse<FuseItem, FuseOptions<FuseItem>>

	constructor(props: Props) {
		super(props)
		const parsedQuery = queryString.parse(location.search) as core.IShared.GeneralMap<any>
		this.state = { ...initialState, searchTerm: oc(parsedQuery).searchTerm('') }
		this.pageSize = 20
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		await this.activeTabFromURL()
		await this.setComponentState()
	}

	activeTabFromURL = async () => {
		const { location, isCustomerView } = this.props
		if (isCustomerView) { return }

		// Parse the query string of the URL
		const parsedQuery = queryString.parse(location.search)
		const activeTab = parsedQuery.tab ? parsedQuery.tab : UserResourceTabs.Members

		const eventID = parsedQuery.eventID
		const event = this.props.eventsByID[eventID]
		await setStateAsync(this, { viewingTab: activeTab, event: event })
	}

	/**
	 * Sets the state for the component.
	 * This method is called on component mount and after user deletion.
	 */
	async setComponentState() {
		if (this.props.users) {
			this.buildFuseMemberSearch(this.props.users)
			return setStateAsync(this, { loading: false, allUsers: this.props.users })
		}
		await setStateAsync(this, { loading: true })

		// Fetch the Filters
		if (!this.props.isCustomerView) {
			await this.refreshFilters()
		}

		await this.refreshUsers()

		this.buildFuseMemberSearch(this.props.userState.users)
		await setStateAsync(this, { loading: false, allUsers: this.props.userState.users })
	}

	async componentDidUpdate(prevProps: Props, prevState: State) {
		const usersChanged = oc(prevProps).users.length(0) !== oc(this).props.users.length(0)
		if (this.state.event && usersChanged) {
			this.buildFuseMemberSearch(this.props.users)
			await this.activeTabFromURL()
			await setStateAsync(this, { loading: false, allUsers: this.props.users })
			return
		}
	}

	// ----------------------------------------------------------------------------------
	// API Requests
	// ----------------------------------------------------------------------------------

	refreshUsers = async () => {
		const clubID = `${oc(this).props.userState.loggedInUser.clubID()}`
		const activeFilter = oc(this).state.selectedFilter.value()
		const pendingFilter = oc(this).state.pendingFilter()
		const [err] = await to(this.props.getUsers({ clubID, pageSize: 0, page: 0, filter: activeFilter, pendingFilter }) as any)
		if (err) {
			await setStateAsync(this, { loading: false, error: true })
			return
		}
	}

	refreshFilters = async () => {
		const [fetchFilterErr] = await to(this.props.fetchFilters() as any)
		if (fetchFilterErr) {
			await setStateAsync(this, { loading: false, error: true })
			return
		}
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Dropdown Actions
	// ----------------------------------------------------------------------------------

	/**
	 * Determines which action to take, based on which dropdown item
	 * the user selected in the table's dropdown menu
	 */
	handleTableDropdownAction = (e: [UserTableActions, string]) => {
		const [action, value] = e
		switch (action) {
			case UserTableActions.ViewUser:
				return this.handleViewUser(value)
			case UserTableActions.MessageUser:
				return this.handleMessageUsers(value)
			case UserTableActions.EditUser:
				return this.handleEditUser(value)
			case UserTableActions.RemoveUser:
				return this.handleDeleteUser(value)
			case UserTableActions.SendInvite:
				return this.sendUserInvite(value)
			case UserTableActions.CancelRSVP:
				return this.handleCancelRSVP(value)
			case UserTableActions.ResetPassword:
				return this.handleResetPassword(value)
			default:
				break
		}
	}

	handleViewUser = (userID: string) => {
		// Query params
		const queryParams: core.IShared.GeneralMap<string> = {
			userID: userID
		}

		if (this.state.searchTerm) {
			queryParams.searchTerm = this.state.searchTerm
		}

		const location = {
			pathname: Constants.VIEW_USER_ROUTE,
			search: queryString.stringify(queryParams)
		}

		this.props.history.push(location)
	}

	/**
	 * When creating a Message for a User, navigate to the Message creation
	 * form and pass information about the User via query parameters
	 */
	handleMessageUsers = (individualUserID?: string) => {
		const { users, history } = this.props
		const { selectedUsers } = this.state

		const queryParams: { individualUserIDs?: string[] | string } = {}
		if (!isNullOrUndefined(individualUserID)) {
			// Get the User by their ID
			const allUsers = users ? users : this.props.userState.users
			const selectedUser = allUsers.find((user: core.User.Model) => `${user._id}` === individualUserID)
			queryParams.individualUserIDs = `${selectedUser._id}`
		} else {
			queryParams.individualUserIDs = Object.keys(selectedUsers)
		}

		const location = {
			pathname: Constants.CREATE_MESSAGE_ROUTE,
			search: queryString.stringify(queryParams)
		}

		// Go to the route
		history.push(location)
	}

	/**
	 * When editing a User, navigate to the User update
	 * form and pass the User's ID via the query parameters
	 */
	handleEditUser = (userID: string) => {
		const queryParams = { userID: userID }
		const location = {
			pathname: Constants.UPDATE_USER_ROUTE,
			search: queryString.stringify(queryParams)
		}
		this.props.history.push(location)
	}

	/**
	 * When attempting to delete a User, display the
	 * User deletion confirmation modal
	 */
	handleDeleteUser = async (userID: string) => {
		await setStateAsync(this, { userToDelete: userID })
	}

	sendUserInvite = async (userID: string) => {
		const { sendInvitation, fireFlashMessage } = this.props
		await setStateAsync(this, { loading: true })

		const [inviteErr] = await to(sendInvitation(userID) as any)
		if (inviteErr) {
			fireFlashMessage(`Failed to send User invite. ${inviteErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false })
			return
		}

		fireFlashMessage('Successfully sent invitation to user.', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false })
	}

	handleCancelRSVP = async (userID: string) => {
		await setStateAsync(this, { loading: true })
		const { cancelEventRsvp, updateEventRsvp, fireFlashMessage } = this.props

		// Fetch the res.
		// TH - Needs to get out of this component...
		const reservations = oc(this).props.eventState.currentEvent.reservations()

		// TH - N^2... :(... this need to be refactored into the RSVP Component. Not sure why
		// it lives here. Want to hit it with #3546
		let reservation: core.Event.Reservation
		for (const res of reservations) {
			for (const participant of res.participants) {
				if (`${participant.userID}` === userID) {
					const newParticipants = without(res.participants, participant)
					reservation = {
						...clone(res),
						participants: newParticipants
					}
					break
				}
			}
			if (reservation) {
				break
			}
		}

		// TH - If we have no members, let's cancel the RSVP. We really need to revise this whole
		// ui and target the reservations individually, not just a list of all participants...
		let error: Error
		if (reservation.participants.length) {
			const [err] = await to(updateEventRsvp(`${reservation._id}`, reservation, `${this.state.event._id}`) as any)
			error = err
		} else {
			const [err] = await to(cancelEventRsvp(`${reservation._id}`, `${this.state.event._id}`) as any)
			error = err
		}

		if (error) {
			fireFlashMessage(`Failed to cancel RSVP for Event. ${error.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false })
			return
		}
		await this.props.refreshUsers()
		fireFlashMessage('Successfully canceled RSVP.', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false })
	}

	handleResetPassword = async (userID: string) => {
		await setStateAsync(this, { loading: true })

		const user = this.props.usersByIDSelector[userID]
		const [err] = await to(this.props.requestNewPassword(user.email, this.props.loggedInClub.domain) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to reset password. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false })
			return
		}
		this.props.fireFlashMessage('Password reset email sent!', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Modal Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Make the API call to delete the User, when the
	 * user confirms the deletion via the modal
	 */
	executeUserDeletion = async () => {
		const { deleteUser, fireFlashMessage } = this.props
		const userID = this.state.userToDelete
		const [deleteErr] = await to(deleteUser(userID) as any)
		if (deleteErr) {
			fireFlashMessage(`Failed to delete User. ${deleteErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, userToDelete: null })
			return
		}

		await this.setComponentState()
		fireFlashMessage('User successfully deleted.', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, userToDelete: null })
	}

	executeUsersDeletion = async () => {
		const { deleteUsers, fireFlashMessage } = this.props
		const { selectedUsers } = this.state

		const userIDs = Object.keys(selectedUsers)
		const [deleteErr] = await to(deleteUsers(userIDs) as any)
		if (deleteErr) {
			fireFlashMessage(`Failed to delete Users. ${deleteErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false })
			return
		}

		await this.setComponentState()
		fireFlashMessage('Users successfully deleted.', Constants.FlashType.SUCCESS)
		await this.closeModal(ShowingUsersDeletionModal)
		await setStateAsync(this, { loading: false, selectedUsers: {} })
	}

	executeUsersMembershipUpdate = async (form: { membershipType: InputSelectionItem }) => {
		const { updateUsers, fireFlashMessage } = this.props
		const { selectedUsers } = this.state

		await setStateAsync(this, { loading: true })

		const userIDs = Object.keys(selectedUsers)
		const payload = { membershipType: form.membershipType.value }
		const [updateErr] = await to(updateUsers(userIDs, payload) as any)
		if (updateErr) {
			fireFlashMessage(`Failed to update Users. ${updateErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false })
			return
		}

		await this.setComponentState()
		fireFlashMessage('Users successfully updated.', Constants.FlashType.SUCCESS)
		await this.closeModal(ShowingUpdateMembershipModal)
		await setStateAsync(this, { loading: false, selectedUsers: {} })
	}

	/**
	 * Hide the deletion modal when the user cancels it
	 */
	closeDeleteUserModal = async () => {
		await setStateAsync(this, { userToDelete: null })
	}

	openModal = async (modalStateKey: keyof State) => {
		await setStateAsync(this, (prevState) => ({ ...prevState, [modalStateKey]: true }))
	}

	closeModal = async (modalStateKey: keyof State) => {
		const newState: Partial<State> = {
			[modalStateKey]: false,
			userToDelete: null
		}
		await setStateAsync(this, (prevState) => ({ ...prevState, ...newState }))
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Handle table pagination by updating the page index in component state.
	 * This causes a re-render which will then pass the table component
	 * a portion of our users array.
	 */
	handlePage = async (data: any) => {
		const { pageIndex } = this.state

		// Check if the new page is different that the current one
		if (data.selected !== pageIndex) {
			await setStateAsync(this, { pageIndex: data.selected })
		}
	}

	/**
	 * Handle a column header of the table being selected
	 */
	handleTableHeaderAction = (e: any) => {
		// TODO: Add Functionality
	}

	/**
	 * Handle a table row being clicked
	 */
	handleTableRowClicked = (item: core.User.Model) => {
		return this.handleViewUser(`${item._id}`)
	}

	handleTableCheckboxChange = async (selectedUser: core.User.Model) => {
		const { selectedUsers } = this.state

		const userIsSelected = !isNullOrUndefined(selectedUsers[`${selectedUser._id}`])

		const newSelectedUsers = (userIsSelected) ?
			omit(selectedUsers, [`${selectedUser._id}`]) :
			{ ...selectedUsers, [`${selectedUser._id}`]: selectedUser }

		await setStateAsync(this, { selectedUsers: newSelectedUsers, selectingAllUsers: false })
	}

	handleTableCheckboxAll = async (selectingAll: boolean) => {
		let newSelectedUsers: { [key: string]: core.User.Model }
		if (selectingAll) {
			const { usersForTable } = this.buildUsersForTable()
			newSelectedUsers = indexBy(usersForTable, '_id')
		} else {
			newSelectedUsers = {}
		}

		await setStateAsync(this, { selectingAllUsers: selectingAll, selectedUsers: newSelectedUsers })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Header Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Determine which action to take, based on the header
	 * button that the user selected
	 */
	handleHeaderButtonAction = async (e: { target: { value: HeaderButtonActions } }) => {
		const action = e.target.value
		switch (action) {
			case CreateUser:
				this.handleCreateUser()
				break
			case CreateType:
				this.openModal(ShowingTypeCreationModal)
				break
			default:
				break
		}
	}

	handleHeaderDropdownAction = async (item: DropdownItem) => {
		switch (item.value) {
			case DeleteUsers:
				this.openModal(ShowingUsersDeletionModal)
				break
			case CreateUserGroup:
				this.handleCreateUserGroup()
				break
			case MessageUsers:
				this.handleMessageUsers()
				break
			case UpdateUserMemberships:
				this.openModal(ShowingUpdateMembershipModal)
			default:
				break
		}
	}

	/**
	 * Navigate to the User creation form
	 */
	handleCreateUser = () => {
		this.props.history.push(Constants.CREATE_USER_ROUTE)
	}

	handleCreateUserGroup = () => {
		const { history } = this.props
		const { selectedUsers } = this.state

		// Query parameters
		const queryParams = { userIDs: Object.keys(selectedUsers) }
		const location = {
			pathname: Constants.CREATE_USER_GROUP_ROUTE,
			search: queryString.stringify(queryParams)
		}

		// Go to the route
		history.push(location)
	}

	handleSearchChange = async (term: string) => {
		if (term.length === 0) {
			this.props.history.replace(this.props.history.location.pathname)
		}
		await setStateAsync(this, { tempSearchTerm: term, searchTerm: term, pageIndex: 0 })
	}

	handleSearchEnter = async (event: any) => {
		if (event.key === 'Enter') {
			await setStateAsync(this, { searchTerm: this.state.tempSearchTerm, pageIndex: 0 })
		}
	}

	handleSearchClear = async () => {
		this.props.history.replace(this.props.history.location.pathname)
		await setStateAsync(this, { searchTerm: '', tempSearchTerm: '', pageIndex: 0 })
	}

	handleFilterChange = async (data: InputSelectionItem) => {
		if (data.value === 'resetFilter') {
			await setStateAsync(this, { creatingFilter: true, selectedFilter: null, pendingFilter: null, pendingFilterPredicates: [] })
			this.setComponentState()
			return
		}
		if (data.value === 'clearFilter') {
			await setStateAsync(this, { creatingFilter: false, selectedFilter: null, pendingFilter: null, pendingFilterPredicates: [] })
			this.setComponentState()
			return
		}
		if (data.value === 'newFilter') {
			await setStateAsync(this, { creatingFilter: true, selectedFilter: null, pendingFilter: null, pendingFilterPredicates: [] })
			return
		}
		await setStateAsync(this, { creatingFilter: false, selectedFilter: data, pendingFilter: null, pendingFilterPredicates: [] })
		this.setComponentState()
	}

	handleFilterSaved = async (savedFilterID?: string) => {
		const savedFilter = oc(this).props.queryFilterState.filtersByID[savedFilterID]()
		const filterData = { label: oc(savedFilter).name(), value: savedFilterID }
		await setStateAsync(this, { selectedFilter: filterData, pendingFilter: null, pendingFilterPredicates: [] })

		// Refresh
		await this.setComponentState()
	}

	handlePendingFilterUpdated = async (queryFilter: core.QueryFilter.Model, pendingFilterPredicates: core.QueryFilter.Predicate[]) => {
		await setStateAsync(this, { pendingFilter: queryFilter, pendingFilterPredicates })

		// Refresh
		await this.setComponentState()
	}

	handleTabChange = async (tabTitle: UserResourceTabs) => {
		const { location, history } = this.props
		await setStateAsync(this, { viewingTab: tabTitle })

		// Update the query param in the current path with the tab being viewed.
		const queryParams = queryString.stringify({ tab: tabTitle })
		const updatedPath = `${location.pathname}?${queryParams}`
		history.replace(updatedPath)
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildHeaderButtons = (): HeaderButton[] => {
		const { viewingTab, selectedUsers } = this.state
		if (viewingTab === UserResourceTabs.Types) {
			return [
				{
					action: CreateType,
					type: ButtonType.DEFAULT,
					text: 'New Type',
					class: 'btn-primary'
				}
			]
		}

		if (Object.keys(selectedUsers).length > 0) {
			return []
		}

		return [
			{
				action: CreateUser,
				type: ButtonType.DEFAULT,
				text: 'New User',
				class: 'btn-primary',
			},
		]
	}

	buildPageHeader = () => {
		const { title, isCustomerView, queryFilterState, location } = this.props
		const { viewingTab, selectedUsers } = this.state

		const pageTitle = oc(title)('Members')

		const searchInputs: PageHeaderSearchInput[] = [{
			inputType: PageHeaderInputType.Search,
			changeHandler: this.handleSearchChange,
			enterHandler: this.handleSearchEnter,
			clearHandler: this.handleSearchClear,
			defaultValue: this.state.searchTerm
		}]

		const queryFiltersByID = oc(queryFilterState).filtersByID()
		const queryFilters = oc(queryFilterState).filterIDs([]).map((filterID) => {
			const queryFilter = queryFiltersByID[filterID]
			return { label: queryFilter.name, value: filterID }
		})

		const selectInputs: PageHeaderSelectInput[] = [{
			placeholder: 'New Filter',
			inputType: PageHeaderInputType.Select,
			icon: Feather.Filter,
			inputs: [
				{ label: null, options: [...queryFilters] },
				{ label: '', options: [{ label: 'New Filter', value: 'newFilter' }] },
			],
			selected: this.state.selectedFilter,
			changeHandler: this.handleFilterChange,
		}]

		const dropdownInputs: PageHeaderDropdownInput[] = (Object.keys(selectedUsers).length > 0) ? [{
			inputType: PageHeaderInputType.Dropdown,
			className: 'userContainer__dropdown',
			header: 'More',
			category: 'More',
			items: [
				{
					type: DropdownItemType.Default,
					icon: Feather.Users,
					text: 'Create Group',
					value: CreateUserGroup,
				},
				{
					type: DropdownItemType.Default,
					icon: Feather.MessageSquare,
					text: 'Message User(s)',
					value: MessageUsers,
				},
				{
					type: DropdownItemType.Default,
					icon: Feather.User,
					text: 'Update User Membership(s)',
					value: UpdateUserMemberships,
				},
				{
					type: DropdownItemType.Default,
					icon: Feather.Delete,
					text: 'Delete User(s)',
					value: DeleteUsers,
				},
			],
			onChange: this.handleHeaderDropdownAction,
			featherIcon: Feather.MoreVertical
		}] : []

		const tabBarButtons: TabBarButtonInput[] = [
			{
				title: 'Members',
				onClick: () => this.handleTabChange(UserResourceTabs.Members),
				active: viewingTab === UserResourceTabs.Members,
			},
			{
				title: 'Types',
				onClick: () => this.handleTabChange(UserResourceTabs.Types),
				active: viewingTab === UserResourceTabs.Types,
			},
		]

		// Check if we are viewing the User Component from somewhere other than the primary route.
		// We do this for Event RSVPs and Message Users, where it doesn't make sense to have full header UI
		const notOnPrimaryRoute = isNullOrUndefined(matchPath(location.pathname, { path: Constants.USER_ROUTE }))
		return (isCustomerView || notOnPrimaryRoute) ?
			(
				<div className='customer-user-view'>
					<PageHeaderComponent pageTitle={pageTitle} inputs={searchInputs} />
				</div>
			) :
			(
				<TableHeader
					fullScreen={true}
					tabInputs={tabBarButtons}
					pageTitle={pageTitle}
					inputs={(viewingTab !== UserResourceTabs.Types) ? [...searchInputs, ...selectInputs, ...dropdownInputs] : []}
					buttons={this.buildHeaderButtons()}
					buttonHandler={this.handleHeaderButtonAction}
				/>
			)
	}

	buildQueryFilter = () => {
		const { loggedInClub, isCustomerView } = this.props
		const { creatingFilter, selectedFilter } = this.state

		if (isCustomerView || !creatingFilter && !selectedFilter) { return null }

		const selectedFilterValue = oc(selectedFilter)()

		const membershipTypes = oc(loggedInClub).resources.members.types([])
		const membershipTypeInputs = typeSelectInputs(membershipTypes)
		const queryFieldOptions = UserQueryFilterFields(membershipTypeInputs as any)

		return (
			<QueryFilterComponent
				activeFilterID={oc(selectedFilterValue).value()}
				pendingFilterPredicates={oc(this).state.pendingFilterPredicates([])}
				queryFields={queryFieldOptions}
				queryResource={'Users'}
				onSaveFilter={this.handleFilterSaved}
				onClearFilter={() => this.handleFilterChange({ label: '', value: 'clearFilter' })}
				onResetFilter={() => this.handleFilterChange({ label: '', value: 'resetFilter' })}
				onUpdatePendingFilters={this.handlePendingFilterUpdated}
			/>
		)
	}

	buildDeleteConfirmationModal = () => {
		if (!this.state.userToDelete) { return null }

		const title = `Delete User`
		const primaryMessage = `Are you sure you want to delete this User?`
		const closeHandler = this.closeDeleteUserModal
		const submitHandler = this.executeUserDeletion

		return (
			<ModalComponent
				modalTitle={title}
				primaryMessage={primaryMessage}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={closeHandler}
				submitButtonName={'Confirm'}
				submitButtonHandler={submitHandler}
			/>
		)
	}

	buildDeleteUsersConfirmationModal = () => {
		const { showingUsersDeletionModal, selectedUsers } = this.state
		if (!showingUsersDeletionModal || selectedUsers.length === 0) { return null }

		const title = `Delete Users`
		const primaryMessage = `Are you sure you want to delete the selected Users?`
		const closeHandler = () => this.closeModal(ShowingUsersDeletionModal)
		const submitHandler = this.executeUsersDeletion

		return (
			<ModalComponent
				modalTitle={title}
				primaryMessage={primaryMessage}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={closeHandler}
				submitButtonName={'Confirm'}
				submitButtonHandler={submitHandler}
			/>
		)
	}

	buildUpdateUserMembershipTypeModal = () => {
		const { loggedInClub } = this.props
		const { showingUpdateMembershipModal, selectedUsers } = this.state
		if (!showingUpdateMembershipModal || selectedUsers.length === 0) { return null }

		// Get the Membership types for the form
		const membershipTypes = oc(loggedInClub).resources.members.types([])
		const typesForForm = typeSelectInputs(membershipTypes)

		const title = `Update User Memberships`
		const formSpec: FormInput[] = [
			{
				title: 'Type',
				property: 'membershipType',
				type: FormInputType.GROUPED_SELECT,
				size: 12,
				placeholder: 'Select Membership Type',
				selectItems: typesForForm as any,
				defaultValue: null,
				validation: Yup.string().nullable().required(Constants.REQUIRED_FIELD)
			}
		]
		const closeHandler = () => this.closeModal(ShowingUpdateMembershipModal)
		const submitHandler = this.executeUsersMembershipUpdate

		return (
			<FormModal<any, { membershipType: InputSelectionItem }>
				modalTitle={title}
				formSpec={formSpec}
				cancelButtonHandler={closeHandler}
				submitButtonHandler={submitHandler}
			/>
		)
	}

	buildChangePasswordModal = () => {
		if (!this.state[ShowingChangePasswordModal]) { return null }

		return (
			<SecuritySettingsForm
				club={this.props.loggedInClub}
				modal={true}
				toggle={() => this.closeModal(ShowingChangePasswordModal)}
			/>
		)
	}

	buildContent = () => {
		const { isCustomerView } = this.props
		const { viewingTab, showingTypeCreationModal } = this.state

		// Check if we are in the customer view
		if (isCustomerView) {
			const { usersToShow, total } = this.getUsersToShow()
			return this.buildRichContent(usersToShow, total)
		}

		// Check if we are on a type other than 'Members'
		if (viewingTab === UserResourceTabs.Types) {
			return (
				<ClubTypes
					resourceType={'members'}
					showingEditModal={showingTypeCreationModal}
					onEditModalClose={() => this.closeModal(ShowingTypeCreationModal)}
				/>
			)
		}

		// We are on the 'Members' tab -- show the table
		return this.buildUserTable()
	}

	buildUsersForTable = (): { usersForTable: core.User.Model[], total: number } => {
		const { users, loggedInClub, location } = this.props

		const { usersToShow, total } = this.getUsersToShow()

		const canUpdateUser = isNullOrUndefined(users)
		const usersForTable = usersToShow.map((user) => {
			return {
				...user,
				membershipTypeBadge: clubResourceTypeBadge(loggedInClub, 'members', user.membershipType as any),
				fullMemberNumber: this.getFullMemberNumberForUser(user),
				primaryPhoneNumber: oc(getMemberPrimaryPhoneNumber(user))(''),
				canInvite: user.memberStatus !== core.User.MemberStatus.Active,
				canDelete: canUpdateUser,
				canEdit: canUpdateUser,
				canChangePassword: canUpdateUser,
				canCancelRSVP: !isNullOrUndefined(matchPath(location.pathname, { path: Constants.VIEW_EVENT_RSVPS_ROUTE })),
				avatar: <AvatarComponent user={user} />
			}
		})
		return { usersForTable, total }
	}

	buildUserTable = () => {
		const { selectedUsers, selectingAllUsers, pageIndex, loading } = this.state

		const { usersForTable, total } = this.buildUsersForTable()

		return (
			<TableComponent<core.User.Model>
				columns={UserTableColumns}
				rowItems={usersForTable}
				showPaging={true}
				currentPage={pageIndex}
				pageHandler={this.handlePage}
				totalResults={total}
				pageSize={this.pageSize}
				actionHandler={this.handleTableHeaderAction}
				dropdownHandler={this.handleTableDropdownAction}
				onTableRowClick={this.handleTableRowClicked}
				onCheckboxChange={this.handleTableCheckboxChange}
				onSelectAll={this.handleTableCheckboxAll}
				checkedAllRows={selectingAllUsers}
				checkedRowMap={selectedUsers}
				isLoading={loading}
			/>
		)
	}

	buildRichContent = (users: core.User.Model[], total: number) => {
		const content = (
			<div className='row'>
				{users.map(this.buildRichContentItem)}
			</div>
		)

		return (
			<div>
				<DimmedLoader
					component={content}
					isLoading={this.state.loading}
				/>
				<PaginationComponent
					initialPage={this.state.pageIndex}
					currentPage={this.state.pageIndex}
					pageSize={this.pageSize}
					totalResults={total}
					onPageChange={this.handlePage}
				/>
			</div>
		)
	}

	buildRichContentItem = (user: core.User.Model, index: number) => {
		const { loggedInClub } = this.props

		const subtitle = getUserSubtitle(user, loggedInClub)
		const content = (
			<div className='d-flex align-items-center'>
				<AvatarComponent user={user} className={'avatar-xxl'} />
				<div className='user-card-info'>
					<div className='user-card-name'>{fullName(user)}</div>
					<div className='text-muted'>{subtitle}</div>
				</div>
			</div>
		)

		return (
			<div className='col col-12 col-lg-6 col-xl-4' key={`user_card_${index}`}>
				<Card
					content={content}
					hideHeader={true}
					cardClass={'user-card'}
					bodyClass={'card-body user-card-body'}
					onClick={() => this.handleViewUser(`${user._id}`)}
				/>
			</div>
		)
	}

	render() {
		const { loggedInClub } = this.props
		const { loading, error } = this.state
		if (loading) { return <DimmedLoader component={null} isLoading={true} /> }
		if (error) { return <ErrorComponent club={loggedInClub} isAdmin={this.props.userState.isAdmin} /> }

		return (
			<RS.Row>
				<RS.Col className={'no-padding'}>
					<div className='user-container'>
						{this.buildPageHeader()}

						<div className='user-table-container-div'>
							{this.buildQueryFilter()}
							{this.buildContent()}
						</div>

						{this.buildDeleteConfirmationModal()}
						{this.buildDeleteUsersConfirmationModal()}
						{this.buildUpdateUserMembershipTypeModal()}
						{this.buildChangePasswordModal()}
					</div>
				</RS.Col>
			</RS.Row>
		)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	/**
	 * The Fuse search is built using an array of Fuse user objects.
	 * These objects contain a search string (full name and email).
	 * Fuse attempts to match the input search term to this search string.
	 * IDs of the matched Fuse users will be used to locate the users
	 * in component state.
	 * @param term Search term from user input.
	 * @return Matching users core.User.Model[].
	 */
	searchMembers(term: string): core.User.Model[] {
		if (!this.fuseMemberSearch) {
			return
		}

		if (isNullOrUndefined(term) || term === '') {
			return this.state.allUsers
		}

		const fuseMatches: FuseItem[] = this.fuseMemberSearch.search(term) as FuseItem[]
		return this.state.allUsers.filter((u: core.User.Model) => {
			const found = fuseMatches.find((m) => `${m._id}` === `${u._id}`)
			return !isNullOrUndefined(found)
		})
	}

	/**
	 * Sets up the Fuse member search. The users passed into this method
	 * will be filtered as the search term changes.
	 * Fuzzy search options: https://fusejs.io/
	 * @param users core.User.Model[]
	 */
	buildFuseMemberSearch = (users: core.User.Model[]): void => {
		// Build our fuse user objects.
		const usersForFuse: FuseItem[] = this.buildFuseUsers(users)
		this.fuseMemberSearch = buildFuseSearch(usersForFuse)
	}

	/**
	 * Returns an array of Fuse user objects.
	 * These objects contain the user's ID and a search string
	 * made up of the user's full name and email.
	 * @param allUsers Users on component state.
	 * @returns FuseUser[].
	 */
	buildFuseUsers = (allUsers: core.User.Model[]): FuseItem[] => {
		return allUsers.map((u: core.User.Model) => {
			if (isNullOrUndefined(u)) { return { _id: '', searchString: '' } }
			return {
				_id: `${u._id}`,
				searchString: `${fullName(u)} ${u.email}`
			}
		})
	}

	/**
	 * Returns a list of the Users to show, with the search term
	 * acting as a filter
	 */
	getUsersToShow = (): { usersToShow: core.User.Model[]; total: number } => {
		const { searchTerm, pageIndex } = this.state

		const userResults = oc(this.searchMembers(searchTerm))([])
		const start = pageIndex * this.pageSize
		const end = start + this.pageSize
		const usersToShow = userResults.slice(start, end)
		return { usersToShow, total: userResults.length }
	}

	getFullMemberNumberForUser = (user: core.User.Model) => {
		const memberNumber = oc(user).memberNumber.number('--')
		const suffix = oc(user).memberNumber.suffix()

		const suffixExists = !isNullOrUndefined(suffix) && suffix.length > 0
		if (memberNumber !== '--' && suffixExists) {
			return `${memberNumber}-${suffix}`
		}
		return memberNumber
	}
}

const mapStateToProps = (state: RootReducerState) => {
	return {
		eventState: state.event,
		userState: state.user,
		queryFilterState: state.queryFilter,
		loggedInClub: state.club.loggedInClub,
		usersByIDSelector: usersByIDSelector(state),
		userClubID: userClubIDSelector(state),
		isCustomerView: inCustomerViewSelector(state),
		eventsByID: eventsByIDSelector(state)
	}
}

const mapDispatchToProps = {
	...QueryFilterActions,
	...UserActions,
	...GroupActions,
	...InvitationActions,
	...AlertActions,
	...EventActions
}

export default withRouter<ComponentProps & RouteComponentProps>(connect<ConnectedState, ConnectedActions, Props>(mapStateToProps, mapDispatchToProps)(UserComponent))
