// External Dependencies
import * as Yup from 'yup'
import { isNullOrUndefined } from 'util'

// Internal Dependencies
import { InputSelectionItem, FormInputType, FormInput, ReactSelectItem } from '../../Shared/Form'

export interface PrivilegeFormState {
	memberType: ReactSelectItem | string,
	bookingWindow: number,
	maxBookings: LimitForPeriodFormValue[],
	guestBookings: LimitForPeriodFormValue[],
}

// The type for values in the form for Max Booking and Guest Booking inputs
export interface LimitForPeriodFormValue {
	period: InputSelectionItem,
	limit: number
}

export const PrivilegeFormInputs = (membershipTypes: InputSelectionItem[]): FormInput[] => [
	{
		title: 'Member Type',
		property: 'memberType',
		type: FormInputType.SELECT,
		placeholder: 'Select Member Type...',
		selectItems: membershipTypes,
		defaultValue: null,
		validation: Yup.mixed().required('required.')
	},
	{
		title: 'Booking Window (Days)',
		property: 'bookingWindow',
		type: FormInputType.NUMBER,
		placeholder: 'Enter number of days...',
		defaultValue: '',
		validation: Yup.number().positive('must be positive.').nullable()
	},
	{
		title: 'Max Booking',
		property: 'maxBookings',
		type: null,
		defaultValue: [{ limit: '' as any, period: null }],
		validation: limitedBookingInputSchema
	},
	{
		title: 'Guest Booking',
		property: 'guestBookings',
		type: null,
		defaultValue: [{ limit: '' as any, period: null }],
		validation: limitedBookingInputSchema
	}
]

// The validation schema for Max Booking and Guest Booking inputs
const limitedBookingInputSchema = Yup.array().of(
	Yup.object().shape({
		limit: Yup.mixed().when('period', {
			is: (value) => !isNullOrUndefined(value),
			then: Yup.number().positive('must be positive.').required('required.'),
		}),
		period: Yup.mixed().when('limit', {
			is: (value) => !isNullOrUndefined(value) && value !== '',
			then: Yup.object().required('required.'),
		}),
	}, [['limit', 'period']])
)
