// External Dependencies
import * as React from 'react'
import { Input, Icon } from 'antd'

// tslint:disable-next-line:no-submodule-imports
import { InputProps } from 'antd/lib/input'
import { BaseInputPrimitiveProps } from '../index'

interface Props extends BaseInputPrimitiveProps {
	prefixCls?: string
	size?: string
	onPressEnter?: React.KeyboardEventHandler<HTMLInputElement>
	addonBefore?: React.ReactNode
	addonAfter?: React.ReactNode
	prefix?: React.ReactNode
	suffix?: React.ReactNode
	allowClear?: boolean
}

export const TextInput: React.FunctionComponent<Props> = (props: Props) => {
	let addonAfter: React.ReactNode
	let value = props.value
	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {

		value = event.target.value

		if (props.onChange) {
			props.onChange(event.target.value)
		}
	}

	if (props.item.copyToClipboardHandler) {
		const copyToClipboardHandler = props.item.copyToClipboardHandler
		const onClickHandler = () => {
			copyToClipboardHandler(value)
		}

		addonAfter = (
			<Icon type='copy' onClick={onClickHandler} />
		)
	}

	const properties: InputProps = {
		...props,
		form: null,
		title: props.item.title,
		property: props.item.property,
		defaultValue: props.item.defaultValue,
		disabled: props.item.disabled,
		placeholder: props.item.placeholder,
		id: props.id,
		className: 'text-input',
		type: props.type,
		value: props.value,
		onChange: onChange,
		size: 'large',
		addonAfter,
		autoCapitalize: 'on'
	}
	const rootNode = props.item.type === 'password' ? <Input.Password {...properties} /> : <Input {...properties} />

	return rootNode
}
