// External Dependencies
import * as Feather from 'react-feather'

// Internal Dependencies
import { TableColumn } from '../../Shared/Table'
import { TableColumnType } from '../../Shared/Table/Primitives'

export enum ClubTypeTableActions {
	Edit = 'editClubType',
	Delete = 'deleteClubType',
}

export const ClubTypeTableColumns: TableColumn[] = [
	{
		title: '',
		property: '',
		type: TableColumnType.CHECKBOX,
		action: '',
	},
	{
		title: 'Title',
		property: 'title',
		type: TableColumnType.TEXT,
		action: 'title',
	},
	{
		title: 'Color',
		property: 'colorBadge',
		type: TableColumnType.BADGE,
		class: 'text-uppercase',
		action: 'color',
	},
	{
		title: '',
		property: '',
		type: TableColumnType.DROPDOWN,
		class: 'table-dropdown-menu',
		dropdownItems: [
			{
				action: ClubTypeTableActions.Edit,
				valueProperty: '_id',
				displayText: 'Edit Type',
				icon: Feather.Edit,
			},
			{
				action: ClubTypeTableActions.Delete,
				valueProperty: '_id',
				displayText: 'Delete Type',
				icon: Feather.Trash,
			},
		]
	}
]
