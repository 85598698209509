// External Dependencies
import * as React from 'react'

// Internal Dependencies
import { BaseTablePrimitiveProps, TableColumnType } from '../index'
import { BaseTableCell } from '../BaseTableCell'

// Helpers
import { dateAndTime, timeString } from '../../../../../helpers/date'

type Props<T> = BaseTablePrimitiveProps<T>

export const TableDate: React.FC<Props<any>> = (props: Props<any>) => {
	const rawValue = props.item[props.column.property]
	let cellContents
	switch (props.column.type) {
		case TableColumnType.DATE_TIME:
			cellContents = dateAndTime(new Date(rawValue as any))
			break
		case TableColumnType.DATE:
			cellContents = new Date(rawValue).toLocaleDateString()
			break
		case TableColumnType.TIME:
			cellContents = timeString(new Date(rawValue))
			break
		default:
			cellContents = null
			break
	}
	return (
		<BaseTableCell {...props}>
			{cellContents}
		</BaseTableCell>
	)
}
