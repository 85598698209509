// External Dependencies
import * as React from 'react'
import * as Sentry from '@sentry/browser'
import * as core from 'club-hub-core'
import * as Yup from 'yup'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter, matchPath, RouteComponentProps } from 'react-router'
import to from 'await-to-js'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'

// Internal Dependencies

// Actions
import { RootReducerState } from '../../reducers/index'
import { AlertActions, AppActions } from '../../actions/index'

// Components
import FormModal from '../Shared/Formik/FormModal'
import EventDetail from '../EventDetail'
import ErrorComponent from '../Shared/ErrorComponent'
import DimmedLoader from '../Shared/DimmedLoader'
import { FormInput } from '../Shared/Form'

// Helpers
import * as Constants from '../../constants'
import { setStateAsync } from '../../helpers/promise'
import { getClubDomain } from '../../helpers/url'
import { GET, POST } from '../../helpers/api'
import { RequestError } from '../../helpers/error'
import { AllowedEntryResourceType } from '../../actions/app'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

interface ComponentProps {
	publicViewer?: boolean
}

const initialState = {
	loading: true,
	errorCode: null as number | null,
	event: null as core.Event.Model | null,
	clubDomain: null as string | null,
	showPublicRsvpModal: false,
	form: null as core.Form.Model | null,
}

type Props = RouteComponentProps & ComponentProps & ConnectedActions & ConnectedState
type State = typeof initialState

class PublicEventComponent extends React.Component<Props, State> {
	private eventIDFromQuery: string

	constructor(props: Props) {
		super(props)
		this.state = { ...initialState, clubDomain: getClubDomain() }
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		const match = matchPath<any>(this.props.location.pathname, { path: Constants.VIEW_EVENT_ROUTE })
		Sentry.addBreadcrumb({data: {
			match,
			location: this.props.location,
			club: this.props.loggedInClub
		}})

		if (match) {
			const parsedQuery = queryString.parse(this.props.location.search)
			this.eventIDFromQuery = parsedQuery.eventID
			await this.getPublicEventByID(this.eventIDFromQuery)
			return
		}

		const publicMatch = matchPath<any>(this.props.location.pathname, { path: Constants.VIEW_PUBLIC_EVENT_ROUTE })
		if (publicMatch) {
			const shortLink = publicMatch.params.event_shortlink
			await this.getEventByShortLink(shortLink)
			return
		}

		const message = 'We were unable to find your event. Please try again.'
		this.props.fireFlashMessage(message, Constants.FlashType.SUCCESS)
		this.props.history.push(Constants.HOME_ROUTE)
	}

	// ----------------------------------------------------------------------------------
	// Network Requests
	// ----------------------------------------------------------------------------------

	getEventByShortLink = async (shortLink: string) => {
		const [err, res] = await to<core.Event.Model, RequestError>(GET(`/events/shortLink/${shortLink}`, { domain: this.state.clubDomain }))
		if (err) {
			await setStateAsync(this, { errorCode: err.code, loading: false })
			return
		}
		await this.getPublicRsvpForm()
		await setStateAsync(this, { event: res, loading: false })
	}

	getPublicEventByID = async (eventID: string) => {
		const [err, res] = await to<core.Event.Model, RequestError>(GET(`/events/${eventID}/public`, { domain: this.state.clubDomain }))
		if (err) {
			await setStateAsync(this, { errorCode: err.code, loading: false })
			return
		}
		await this.getPublicRsvpForm()
		await setStateAsync(this, { event: res, loading: false })
	}

	getPublicRsvpForm = async () => {
		const formName = 'publicRSVP'
		const formClubDomain = oc(this).state.clubDomain('cedar') // Override for testing
		const route = `/forms/${this.state.clubDomain}/${formName}`
		const [err, res] = await to<core.Form.Model, RequestError>(GET(route, {}))
		if (err) {
			return
		}
		await setStateAsync(this, { form: res })
	}

	submitPublicRsvpForm = async (form: core.Form.Model): Promise<void> => {
		// Build our POST payload and send request.
		const payload = this.buildFormPayload(form)
		const [err, res] = await to<any, RequestError>(POST(`/forms/submit`, payload))
		if (err) {
			await setStateAsync(this, { errorCode: err.code, loading: false })
			return
		}

		await setStateAsync(this, { form: null, loading: false })
		this.props.fireFlashMessage(`You've successfully submitted your form`, Constants.FlashType.SUCCESS)
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	handlePublicRsvp = async () => {
		await setStateAsync(this, { showPublicRsvpModal: !this.state.showPublicRsvpModal })
	}

	submitPublicRsvp = async (form: core.Form.Model) => {
		await setStateAsync(this, { showPublicRsvpModal: !this.state.showPublicRsvpModal, loading: false })
		await this.submitPublicRsvpForm(form)
		await setStateAsync(this, { showPublicRsvpModal: false, loading: false })
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	buildFormPayload = (form: core.Form.Model) => {
		return {
			form: form,
			formID: this.state.form._id,
			clubDomain: this.state.clubDomain,
			eventID: this.state.event._id
		}
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildPublicRSVPConfirmation = () => {
		if (!this.state.showPublicRsvpModal) { return null }

		const title = 'RSVP Confirmation'

		// Set up the Yup validation here
		const inputsForForm = this.state.form.inputs.map((i) => {
			if (i.validation) {
				return { ...i, validation: Yup.mixed().required(Constants.REQUIRED_FIELD) }
			}
			return i
		}) as any as FormInput[]

		return (
			<FormModal
				modalTitle={title}
				formSpec={inputsForForm}
				cancelButtonHandler={this.handlePublicRsvp}
				submitButtonHandler={this.submitPublicRsvp}
			/>
		)
	}

	privateEventErrorProps = () => {
		return {
			errorMessage: 'This Event is Private',
			detailsMessage: 'Please sign in to view this Event',
			buttonText: 'Sign In To View',
			buttonPath: Constants.LOGIN_ROUTE
		}
	}

	eventNotFoundErrorProps = () => {
		return {
			errorCode: '404',
			errorMessage: 'Event not found',
			detailsMessage: `Sorry, we were unable to find the Event`,
		}
	}

	defaultErrorProps = () => {
		return {
			errorCode: '500'
		}
	}

	buildErrorComponent = () => {
		let errorProps
		let onErrorButtonClick
		switch (this.state.errorCode) {
			case 403:
				errorProps = this.privateEventErrorProps()
				onErrorButtonClick = () => {
					this.props.setAppEntryOverride(AllowedEntryResourceType.Event, this.eventIDFromQuery)
				}
				break
			case 404:
				errorProps = this.eventNotFoundErrorProps()
				break
			default:
				errorProps = this.defaultErrorProps()
				break
		}
		const { loggedInClub } = this.props
		return (
			<ErrorComponent {...errorProps} club={loggedInClub} isAdmin={this.props.userState.isAdmin} buttonOnClick={onErrorButtonClick} />
		)
	}

	buildEventDetail = () => {
		return (
			<EventDetail
				{...this.props}
				event={this.state.event}
				handlePublicRsvp={this.handlePublicRsvp}
				showRSVPButton={!isNullOrUndefined(this.state.form)}
			/>
		)
	}

	render() {
		if (this.state.errorCode) { return this.buildErrorComponent() }
		if (this.state.loading) { return (<DimmedLoader component={null} isLoading={true} />) }

		return (
			<div>
				{this.buildPublicRSVPConfirmation()}
				{this.buildEventDetail()}
			</div>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	userState: state.user,
	loggedInClub: state.club.loggedInClub
})

const mapDispatchToProps = {
	...AppActions,
	...AlertActions
}

const enhance = compose<React.ComponentType<ComponentProps>>(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(PublicEventComponent)
