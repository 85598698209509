// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import { oc } from 'ts-optchain'

// Internal Dependencies
import Card from '../Card'

// Components
import AvatarComponent from '../../Avatar'

// Helpers
import { fullName, getUserSubtitle } from '../../../../helpers/user'

interface ComponentProps {
	user: core.User.Model
	club: core.Club.Model
	ownProfile: boolean
}

type Props = ComponentProps
type State = any

class ProfileCard extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
	}

	buildProfileCard = () => {
		const user = this.props.user
		const club = this.props.club
		const subtitle = getUserSubtitle(user, club)
		const content = (
			<div>
				<AvatarComponent className='card-profile-img avatar-xxl' user={user} />
				<h3 className='mb-1'>{fullName(user)}</h3>
				<p className='mb-0 text-muted'>{subtitle}</p>
				{this.buildDriversClubJSX()}
			</div>
		)

		return (
			<Card
				content={content}
				cardClass={'card-profile'}
				bodyClass={'card-body text-center'}
				hideHeader={true}
			/>
		)
	}

	/**
	 * Returns JSX containing the access code for the user.
	 * This only returns if:
	 * - The club is Drivers Club.
	 * - The passed in user is the profile owner.
	 */
	buildDriversClubJSX = (): JSX.Element | null => {
		const clubType = oc(this).props.club.type()
		if (clubType.toUpperCase() === core.Club.Type.Car.toUpperCase() && this.props.ownProfile) {
			const accessCode = oc(this).props.user.meta.car.agentAccessCode('--')
			return <p className='mb-1 text-muted'>{`Agent Access Code: ${accessCode}`}</p>
		}
		return null
	}

	render() {
		return this.buildProfileCard()
	}
}

export default ProfileCard
