// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import { connect } from 'react-redux'
import { compose } from 'redux'

// Actions
import { AlertActions, ClubActions } from '../../../actions/index'

// State
import { RootReducerState } from '../../../reducers'

// Form
import { RecurrenceModalInputs, RecurrenceModalState } from './form'

// Components
import FormModal from '../../Shared/Formik/FormModal'

interface ComponentProps {
	isOpen?: boolean
	recurring?: core.Event.RecurringFrequency
	recurringEnd?: Date
	cancelButtonHandler: () => any
	submitButtonHandler: (formState: RecurrenceModalState) => any
}

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

type Props = ConnectedActions & ComponentProps & ConnectedState

/**
 * The `UserSelectModal` provides UI for selecting multiple users.
 */
class RecurrenceModal extends React.Component<Props, null> {
	constructor(props: Props) {
		super(props)
	}

	// ----------------------------------------------------------------------------------
	// Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Handles submit button clicks.
	 */
	handleSubmitButton = async (formState: RecurrenceModalState) => {
		this.props.submitButtonHandler(formState)
	}

	// ----------------------------------------------------------------------------------
	// Component Builders
	// ----------------------------------------------------------------------------------

	public render() {
		const formInputs = RecurrenceModalInputs()
		const formResource = {
			recurring: `${this.props.recurring}`,
			recurringEnd: this.props.recurringEnd
		}
		return (
			<FormModal
				enableReinitialize={true}
				isOpen={this.props.isOpen}
				modalTitle={'Custom Recurrence'}
				formSpec={formInputs}
				formResource={formResource}
				submitButtonHandler={this.handleSubmitButton}
				cancelButtonHandler={this.props.cancelButtonHandler}
			/>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	userState: state.user,
})

const mapDispatchToProps = {
	...AlertActions,
	...ClubActions
}

const enhance = compose<React.ComponentType<ComponentProps>>(
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(RecurrenceModal)
