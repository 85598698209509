// External Dependencies
import * as Yup from 'yup'

// Internal Dependencies
import { FormInput, FormInputType, InputSelectionItem } from '../../Shared/Form'
import * as Constants from '../../../constants/index'

export interface MenuItemFormState {
	name: string
	menu: InputSelectionItem
	section: InputSelectionItem
	price: number
	sku: string
	available: InputSelectionItem
	description: string
}

export const MenuItemFormInputs = (menus: InputSelectionItem[], sections: InputSelectionItem[]): FormInput[] => [
	{
		title: 'Name',
		property: 'name',
		type: FormInputType.TEXT,
		size: 6,
		placeholder: 'Enter item name...',
		defaultValue: '',
		validation: Yup.string().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Menu',
		property: 'menu',
		type: FormInputType.SELECT,
		size: 6,
		placeholder: 'Select a menu...',
		selectItems: menus,
		defaultValue: null,
		validation: Yup.string().nullable().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Sections',
		property: 'section',
		type: FormInputType.SELECT,
		size: 6,
		placeholder: 'Select a section...',
		selectItems: sections,
		defaultValue: null,
		validation: Yup.string().nullable().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Price',
		property: 'price',
		type: FormInputType.PRICE,
		size: 6,
		placeholder: 'Enter price...',
		defaultValue: '',
		validation: Yup.string().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Sku',
		property: 'sku',
		type: FormInputType.TEXT,
		size: 6,
		placeholder: 'Enter a sku...',
		defaultValue: '',
		validation: Yup.string().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Available',
		property: 'available',
		type: FormInputType.SELECT,
		size: 6,
		placeholder: 'Yes or No...',
		selectItems: [{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }],
		defaultValue: null,
		validation: Yup.string().nullable().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Description',
		property: 'description',
		type: FormInputType.TEXT_AREA,
		size: 12,
		placeholder: 'Enter item description...',
		defaultValue: '',
	}
]
