// External Dependencies
import * as React from 'react'
import * as Yup from 'yup'
import * as core from 'club-hub-core'
import { Field, ErrorMessage, FieldProps } from 'formik'

// Internal Dependencies

// Components
import { InputSelectionItem, FormInput, FormInputType } from '../../Shared/Form'
import { buildInputPrimitive } from '../../Shared/Formik'
import { InputFeedback } from '../../Shared/Formik/InputPrimitives/InputFeedback'

interface SettingConfig {
	key: string
	title: string
	description: string
	type: FormInputType
	validation: Yup.Schema<any>
}

interface ComponentProps {
	selectedRange: InputSelectionItem
	reservationSettings: core.Calendar.ReservationSetting[]
	handleSave: () => void
}

type Props = ComponentProps

export const GeneralSettingsConfig: SettingConfig[] = [
	{
		key: 'maxGuestsAdmin',
		title: 'Max Guests - Admin',
		description: 'How many guests can an Admin add to a reservation?',
		type: FormInputType.NUMBER,
		validation: Yup.number().nullable().required('required.')
	},
	{
		key: 'bookingDuration',
		title: 'Booking Duration',
		description: 'How far apart should each reservation slot be spaced (Minutes)?',
		type: FormInputType.NUMBER,
		validation: Yup.number().nullable().required('required.')
	},
	{
		key: 'maxGuestsMember',
		title: 'Max Guests - Member',
		description: 'How many guests can a Member add to a reservation?',
		type: FormInputType.NUMBER,
		validation: Yup.number().nullable().required('required.')
	},
	{
		key: 'publicBookings',
		title: 'Public Bookings',
		description: 'Can Members view who has booked other reservations?',
		type: FormInputType.RADIO_GROUP,
		validation: Yup.boolean().nullable().required('required.')
	},
	{
		key: 'joinableBookings',
		title: 'Joinable Bookings',
		description: 'Can Members join an existing reservation?',
		type: FormInputType.RADIO_GROUP,
		validation: Yup.boolean().nullable().required('required.')
	},
]

/**
 * This section contains a form of general settings -- these settings will conform to
 * the form specification found in the shared Form component
 */
export const GeneralSettingsSection: React.FunctionComponent<Props> = (props: Props) => {
	const reservationSetting = props.reservationSettings.find((s) => (s._id as any) === props.selectedRange.value)

	return (
		<div className='reservationSettings-general-section'>
			<h3 className='reservationSettings-section-heading'>General</h3>
			<hr/>

			{GeneralSettingsConfig.map((configItem: SettingConfig) => buildSetting(configItem, reservationSetting))}
		</div>
	)
}

// ----------------------------------------------------------------------------------
// Helper Builders
// ----------------------------------------------------------------------------------

/**
 * This function builds a 'Setting', which will be found in the General section
 * of this component
 */
const buildSetting = (configItem: SettingConfig, reservationSetting: core.Calendar.ReservationSetting) => {
	const settingInput = buildSettingInput(configItem, reservationSetting)
	return (
		<div
			key={`reservation_${reservationSetting._id}_${configItem.key}`}
			className='reservationSettings-setting'
		>
			<div>
				<h5 className='reservationSettings-setting-title'>{configItem.title}</h5>
				<p className='reservationSettings-setting-description'>{configItem.description}</p>
			</div>

			<div className='reservationSettings-setting-input'>
				<Field
					name={configItem.key}
					render={settingInput}
				/>
				<ErrorMessage component={InputFeedback} name={configItem.key} />
			</div>
		</div>
	)
}

const buildSettingInput = (configItem: SettingConfig, reservationSetting: core.Calendar.ReservationSetting) => {
	const baseInput: FormInput = {
		title:    '',
		type: configItem.type,
		property: configItem.key,
		defaultValue: (reservationSetting as any)[configItem.key],
		validation: configItem.validation
	}

	let inputToRender = {...baseInput}
	if (configItem.type === FormInputType.RADIO_GROUP) {
		inputToRender = {
			...inputToRender,
			class: 'reservationSettings-boolean-switch',
			selectItems: [
				{ label: 'No', value: 'false' },
				{ label: 'Yes', value: 'true' }
			]
		}
	}

	return ((fieldProps: FieldProps) => {
		// Configure the inputs to fire the submit handler when they are changed (autosaving)
		const customFieldProps: FieldProps = {
			...fieldProps,
			field: {
				...fieldProps.field,
				onChange: (async (e: React.ChangeEvent) => {
					// Call the normal onChange function given by Formik
					await fieldProps.field.onChange(e)
					fieldProps.form.submitForm()
				}) as any
			},
		}
		return buildInputPrimitive(inputToRender, customFieldProps, {} as any)
	})
}
