// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import * as core from 'club-hub-core'
import * as queryString from 'query-string'
import * as Feather from 'react-feather'
import { connect } from 'react-redux'
import { flatten } from 'underscore'
import { RouteComponentProps } from 'react-router'
import { oc } from 'ts-optchain'

// Internal Dependencies

// State
import { RootReducerState } from '../../../reducers/index'
import { inCustomerViewSelector } from '../../../reducers/user'
import { calendarsByIDSelector } from '../../../reducers/calendar'

// Actions
import { EventActions, AlertActions, CalendarActions } from '../../../actions/index'

// Components
import ErrorComponent from '../../Shared/ErrorComponent'
import DimmedLoader from '../../Shared/DimmedLoader'
import TableHeader from '../../Shared/TableHeader'

// Shared
import { PageHeaderSelectInput, PageHeaderInputType } from '../../Shared/PageHeader'
import { ButtonType } from '../../Shared/ButtonGroup'
import { InputSelectionItem, FormInputType } from '../../Shared/Form'
import { ContentType } from '../../Shared/EmptyContent'

// Tables
import { ReservationTableColumns, ReservationTableRow } from './table'

// Helpers
import { calendarIDForEvent } from '../../../helpers/event'
import { clubResourceTypeBadge } from '../../../helpers/badge'
import { userForForm } from '../../../helpers/user'
import * as Constants from '../../../constants'

import BaseReservationComponent, { ComponentTabs } from '../BaseReservation'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

export enum HeaderActions {
	NewRequest = 'New Request',
	NewType = 'New Type',
	ViewCalendar = 'View Calendar'
}

const initialState = {
	error: false,
	initialLoad: true,
	loading: false,
	calendarGroupID: null as string,
	calendarGroup: null as core.Calendar.Group,
	selectedUserID: null as string | null,
	selectedDate: null as Date,
	showReservationModal: false,
	showTypeCreationModal: false,
	currentTab: ComponentTabs.All
}

type Props = RouteComponentProps & ConnectedState & ConnectedActions
type State = typeof initialState

class GenericReservationComponent extends React.Component<Props, State> {
	private pageSize: number

	constructor(props: Props) {
		super(props)
		this.pageSize = 20

		const state = { ...initialState }

		// Find the calendar group from the query param
		const calendarGroups = oc(this).props.loggedInClub.calendarGroups([])
		const calendarGroup = calendarGroups.find((cg) => cg.type === core.Calendar.GroupType.GuestGolfer)

		state.calendarGroup = calendarGroup
		state.calendarGroupID = `${calendarGroup._id}`
		state.selectedDate = new Date()
		this.state = { ...state }
	}

	componentDidMount() {
		this.setState({ initialLoad: false })
	}

	// ----------------------------------------------------------------------------------
	// Header Action Handlers
	// ----------------------------------------------------------------------------------

	/**
 	* Determine which action to take, based on the header
 	* button that the user selected
 	*/
	handleHeaderButtonAction = async (e: any) => {
		const action = e.target.value
		switch (action) {
			case HeaderActions.NewRequest:
				return this.handleToggleNewReservation()
			case HeaderActions.NewType:
				return this.handleToggleTypeCreation()
			case HeaderActions.ViewCalendar:
				return this.handleViewCalendar()
			default:
				break
		}
	}

	handleTypeTabChange = (tab: ComponentTabs) => {
		this.setState({ currentTab: tab })
	}

	handleUserChange = (data: InputSelectionItem) => {
		this.setState({ selectedUserID: data.value })
	}

	handleDateChange = (date: Date) => {
		this.setState({ selectedDate: date })
	}

	// ----------------------------------------------------------------------------------
	// Base Component Handlers Handlers
	// ----------------------------------------------------------------------------------

	handleToggleNewReservation = () => {
		this.setState({ showReservationModal: !this.state.showReservationModal })
	}

	handleToggleTypeCreation = () => {
		this.setState({ showTypeCreationModal: this.state.showTypeCreationModal })
	}

	handleViewCalendar = () => {
		const pathname = Constants.GUEST_GOLFER_CALENDAR_ROUTE
		const search = queryString.stringify({ groupID: this.state.calendarGroupID })
		this.props.history.push({ pathname, search })
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildPageHeader = () => {
		const inputs = this.buildHeaderInputs()

		const tabInputs = this.buildTabInputs()
		return (
			<TableHeader
				fullScreen={!this.props.isCustomerView}
				tabInputs={tabInputs}
				pageTitle='Guest Reservations'
				inputs={inputs}
				buttonHandler={this.handleHeaderButtonAction}
			/>
		)
	}

	buildBaseReservationComponent = () => {
		const reservationRows: ReservationTableRow[] = this.getReservationRows()
		const calendarAlias = this.state.calendarGroup.calendarAlias
		const columnsForTable = ReservationTableColumns(calendarAlias)
		const header = this.buildPageHeader()
		const calendar = this.getCalendar()
		return (
			<BaseReservationComponent
				header={header}
				contentType={ContentType.GuestGolfer}
				selectedDate={this.state.selectedDate}
				currentTab={this.state.currentTab}
				tableColumns={columnsForTable}
				tableRows={reservationRows}
				calendar={calendar}
				calendarGroup={this.state.calendarGroup}
				showReservationModal={this.state.showReservationModal}
				toggleReservationModal={this.handleToggleNewReservation}
				showTypeCreationModal={this.state.showTypeCreationModal}
				toggleTypeCreationModal={this.handleToggleTypeCreation}
			/>
		)
	}

	render() {
		const { loggedInClub } = this.props
		if (this.state.initialLoad) { return <DimmedLoader component={null} isLoading={true} /> }
		if (this.state.error) { return <ErrorComponent club={loggedInClub} isAdmin={this.props.userState.isAdmin} /> }

		return (
			<RS.Row className={'guest-reservation-container justify-content-center'}>
				{this.buildBaseReservationComponent()}
			</RS.Row>
		)
	}

	// -------------------------------------------------------
	// Helpers
	// -------------------------------------------------------

	getCalendar = () => {
		const calendars = oc(this).props.calendarState.calendars([])
		return calendars.find((cal: core.Calendar.Model) => `${cal.groupID}` === `${this.state.calendarGroupID}`)
	}

	getReservationRows = () => {
		const { loggedInClub, calendarsByID, userState, eventState } = this.props
		const { selectedUserID } = this.state

		let events = eventState.events
		if (selectedUserID && selectedUserID !== 'All') {
			events = eventState.events.filter((e) => e.reservations.find((r) => r.owner === selectedUserID))
		}
		const users = oc(userState).users([])
		const filteredEvents = events.filter((e: core.Event.Model) => `${e.groupID}` === `${this.state.calendarGroupID}`)
		const reservationItems: ReservationTableRow[][] = filteredEvents.map((event: core.Event.Model) => {
			const calendarID = calendarIDForEvent(event)
			const calendarName = calendarsByID[`${calendarID}`].name
			return event.reservations.map((reservation: core.Event.Reservation) => {
				const owner = users.find((user) => `${user._id}` === `${reservation.owner}`)
				const reservationID = `${reservation._id}`
				const eventType = { title: 'N/A', color: core.Constants.Colors.GRAY }
				return { ...event, owner, calendarName, reservationID, eventType }
			})
		})
		return flatten(reservationItems)
	}

	buildUserSelect = (): PageHeaderSelectInput => {
		const users = oc(this).props.userState.users([])
		const usersForSelect = users.map((user) => userForForm(user))
		const defaultSelection = { label: 'All Members', value: 'All' }
		const allUsersForSelect = [defaultSelection, ...usersForSelect]

		// Determine what the value of the Select Component will be
		const selectedUser = users.find((user) => `${user._id}` === `${this.state.selectedUserID}`)
		const selectedValue = (selectedUser) ? userForForm(selectedUser) : defaultSelection

		return {
			inputType: PageHeaderInputType.Select,
			className: 'serviceHeader-service-provider-input',
			icon: Feather.User,
			selected: selectedValue,
			inputs: allUsersForSelect,
			changeHandler: this.handleUserChange,
		}
	}

	buildDateSelect = () => {
		return {
			inputType: PageHeaderInputType.Date,
			className: 'reservation-datepicker',
			type: FormInputType.DATE,
			selected: this.state.selectedDate,
			changeHandler: this.handleDateChange,
			isClearable: false,
			icon: Feather.Calendar,
		}
	}

	buildTabInputs = () => {
		const buttons = []
		buttons.push({ title: 'Upcoming', onClick: () => this.handleTypeTabChange(ComponentTabs.All) })
		buttons.push({ title: 'Past', onClick: () => this.handleTypeTabChange(ComponentTabs.Past) })
		return buttons
	}

	buildHeaderInputs = () => {
		const buttonItem = this.buildButtonItem()
		if (this.props.isCustomerView) { return [buttonItem] }

		const adminInputs = [this.buildUserSelect()]
		return [...adminInputs, buttonItem]
	}

	buildButtonItem = () => {
		switch (this.state.currentTab) {
			case ComponentTabs.Types:
				return this.buildHeaderButton(HeaderActions.NewType, 'New Type')
			default:
				return this.buildHeaderButton(HeaderActions.NewRequest, 'New Request')
				break
		}
	}

	buildHeaderButton = (value: string, text: string): any => {
		const type = ButtonType.DEFAULT
		const color = 'primary'
		const button = { value, text, type, color }
		return { inputType: PageHeaderInputType.Button, button }
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	eventState: state.event,
	userState: state.user,
	calendarState: state.calendar,
	loggedInClub: state.club.loggedInClub,
	isCustomerView: inCustomerViewSelector(state),
	calendarsByID: calendarsByIDSelector(state),
})

const mapDispatchToProps = {
	...CalendarActions,
	...EventActions,
	...AlertActions
}

export default connect(mapStateToProps, mapDispatchToProps)(GenericReservationComponent)
