// External Dependencies
import * as core from 'club-hub-core'
import { Dispatch, Action } from 'redux'
import to from 'await-to-js'

// Internal Dependencies

// API Helpers
import { GET } from '../helpers/api'

// Actions
export enum TypeKeys {
	FETCHED_MOST_RECENT_STATEMENT = 'FETCHED_MOST_RECENT_STATEMENT',
	FETCHED_STATEMENTS = 'FETCHED_STATEMENTS',
	SET_CURRENT_STATEMENT = 'SET_CURRENT_STATEMENT',
	CLEAR_CURRENT_STATEMENT = 'CLEAR_CURRENT_STATEMENT',
}

// -----------------------------------------------------------------------------
// Interfaces
// -----------------------------------------------------------------------------

interface PagedStatementResponse {
	count: number
	statements: core.Statement.Model[]
}

// -----------------------------------------------------------------------------
// Actions
// -----------------------------------------------------------------------------

export type ActionTypes =
	| FetchedStatementsAction
	| FetchedCurrentStatementAction
	| ClearCurrentStatementAction
	| SetCurrentStatementAction

export interface FetchedCurrentStatementAction extends Action {
	type: TypeKeys.FETCHED_MOST_RECENT_STATEMENT,
	currentStatement: core.Statement.Model
}

export interface FetchedStatementsAction extends Action {
	type: TypeKeys.FETCHED_STATEMENTS,
	statements: core.Statement.Model[]
}

export interface SetCurrentStatementAction extends Action {
	type: TypeKeys.SET_CURRENT_STATEMENT,
	currentStatement: core.Statement.Model
}

export interface ClearCurrentStatementAction extends Action {
	type: TypeKeys.CLEAR_CURRENT_STATEMENT,
}

// -----------------------------------------------------------------------------
// Clear Current Statement
// -----------------------------------------------------------------------------

export const SetCurrentStatement = (statement: core.Statement.Model): SetCurrentStatementAction => {
	const action: SetCurrentStatementAction = {
		type: TypeKeys.SET_CURRENT_STATEMENT,
		currentStatement: statement
	}
	return action
}

// -----------------------------------------------------------------------------
// Clear Current Statement
// -----------------------------------------------------------------------------

export const ClearCurrentStatement = (): ClearCurrentStatementAction => {
	const action: ClearCurrentStatementAction = {
		type: TypeKeys.CLEAR_CURRENT_STATEMENT
	}
	return action
}

// -----------------------------------------------------------------------------
// Fetch Current Statement
// -----------------------------------------------------------------------------

/**
 * fetchCurrentStatement will fetch the most current statement for the provided userID.
 * If the authenticated user is not an admin, the userID must match the authenticated user.
 * @param userID
 */
const fetchCurrentStatement = (userID: string) => async (dispatch: Dispatch<ClearCurrentStatementAction | FetchedCurrentStatementAction>) => {
	dispatch(ClearCurrentStatement())
	const [groupErr, statement] = await to<core.Statement.Model>(GET(`/statements/latest/${userID}`))
	if (groupErr) {
		// tslint:disable-next-line
		console.error(`Failed to fetch statement with error: ${groupErr}`)
		throw groupErr
	}

	dispatch(FetchedCurrentStatement(statement))
}

export const FetchedCurrentStatement = (statement: core.Statement.Model): FetchedCurrentStatementAction => {
	const action: FetchedCurrentStatementAction = {
		type: TypeKeys.FETCHED_MOST_RECENT_STATEMENT,
		currentStatement: statement
	}
	return action
}

// -----------------------------------------------------------------------------
// Fetch Statements
// -----------------------------------------------------------------------------

/**
 * FetchStatements retuns an index of statements to the caller. The `userID` is optional, and will
 * default to the authenticated user. If admin, they can supply a userID.
 * Note: It looks like the controller accepts further query constraints (date, etc), but is not tested.
 * At this time, using an unbounded query for statements for a user.
 * @param userID
 */
const fetchStatements = (userID?: string) => async (dispatch: Dispatch<FetchedStatementsAction>) => {
	const query = {
		userID
	}

	const [groupErr, resp] = await to<PagedStatementResponse>(GET(`/statements`), query)
	if (groupErr) {
		// tslint:disable-next-line
		console.error(`Failed to update User Group with error: ${groupErr}`)
		throw groupErr
	}

	dispatch(FetchedStatements(resp.statements))
}

export const FetchedStatements = (statements: core.Statement.Model[]): FetchedStatementsAction => {
	const action: FetchedStatementsAction = {
		type: TypeKeys.FETCHED_STATEMENTS,
		statements
	}
	return action
}

export const StatementActions = {
	fetchCurrentStatement,
	fetchStatements,
}
