// External Dependencies
import * as React from 'react'
import * as Yup from 'yup'
import * as core from 'club-hub-core'
import { Field, FieldProps, connect as FormikConnect, FormikContext, ErrorMessage } from 'formik'
import { isEqual } from 'underscore'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies
import { FilterCreationForm, QueryFilterFormState, QueryFilterFieldAttribute } from '../form'
import { FormInput, FormInputType } from '../../Form'

// Components
import { buildInputPrimitive } from '../../Formik'

interface FormikConnectedProps {
	formik: FormikContext<QueryFilterFormState>
}

interface ComponentProps {
	queryFields: QueryFilterFieldAttribute[]
	onCancel: () => any
}

type Props = ComponentProps & FormikConnectedProps
type State = any

class FilterCreationSection extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Validate the proposed filter and add it to the list
	 * of pending filters if it is valid.
	 */
	handleApplyFilter = async () => {
		const { values } = this.props.formik
		const newPendingFilter: core.QueryFilter.Predicate = {
			attribute: oc(values).queryField.attribute.value(),
			comparison: oc(values).queryField.comparison.value() as any,
			type: oc(values).queryField.attribute.type(),
			value: oc(values).queryField.value(),
		}

		const [err, filterIsValid] = await to(this.validateFilter(newPendingFilter))
		if (!isNullOrUndefined(err) || !filterIsValid) { return }

		const newFormState: QueryFilterFormState = {
			queryField: {
				attribute: null,
				comparison: null,
				value: null,
			},
			pendingFilters: [...values.pendingFilters, newPendingFilter],
			activeFilters: [],
		}
		this.props.formik.resetForm(newFormState)
	}

	handleCancel = async () => {
		this.props.onCancel()
	}

	/**
	 * Validates the filter that is going to be added to the list
	 * of pending filters. Returns true if the filter is valid.
	 * @param filter The proposed filter
	 */
	validateFilter = async (filter: core.QueryFilter.Predicate) => {
		// Check if the filter is valid based on schema
		const filterSchema = Yup.object().shape({
			attribute: Yup.mixed().required(),
			comparison: Yup.mixed().required(),
			value: Yup.string().required(),
		})

		const [validationErr] = await to(filterSchema.validate(filter))
		if (!isNullOrUndefined(validationErr)) {
			this.props.formik.setFieldError('queryField', 'Invalid query filter.')
			return false
		}

		// Check if there is an existing filter with the same values already
		const existingFilters = this.props.formik.values.pendingFilters

		let foundDuplicateFilter: boolean = false
		for (const existingFilter of existingFilters) {
			foundDuplicateFilter = isEqual(existingFilter, filter)
			if (foundDuplicateFilter) { break }
		}

		// Did we find a duplicate filter?
		if (foundDuplicateFilter) {
			this.props.formik.setFieldError('queryField', 'This query filter already exists.')
			return false
		}
		return true
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildFilterInput = (item: FormInput, idx?: number) => {
		return (
			<Field
				key={`filter-input-${item.property}-${idx}`}
				name={item.property}
			>
				{(fieldProps: FieldProps<QueryFilterFormState>) => {
					let wrappedProps = {}
					// We are adding a custom change handler for the 'attribute' input here,
					// so that we can clear the 'comparison', and 'value' inputs when 'attribute' changes.
					if (item.property === 'queryField.attribute') {
						wrappedProps = {
							onChange: ((field: string, value: any) => {
								fieldProps.form.setFieldValue('queryField.comparison', null)
								fieldProps.form.setFieldValue('queryField.value', null)
							})
						}
					}

					// KC - This is some what of a hack. The query filter text input was not updating
					// And I'm not sure where in John's setup the correct fix is. This does the
					// trick for now.
					if (item.property === 'queryField.value') {
						fieldProps.field.onChange = (value: any) => {
							fieldProps.form.setFieldValue('queryField.value', value)
						}
					}

					return (
						<div className={item.class}>
							{buildInputPrimitive(item, fieldProps, wrappedProps as any)}
						</div>
					)
				}}
			</Field>
		)
	}

	buildFormSpec = () => {
		const attributeFields = this.props.queryFields
		const { queryField } = this.props.formik.values
		const comparisonOperators = oc(queryField).attribute.operators([])
		const attributeType = oc(queryField).attribute.type(FormInputType.TEXT)
		const attributeValues = oc(queryField).attribute.values([])
		return FilterCreationForm(attributeFields, comparisonOperators, attributeType, attributeValues)
	}

	buildFilterCreationField = () => {
		const formSpec = this.buildFormSpec()
		const filterInputs = formSpec.map(this.buildFilterInput)
		return (
			<div className='query-filter-section'>
				{filterInputs}

				<button
					className='btn btn-primary query-filter-apply-button'
					onClick={this.handleApplyFilter}
				>
					Apply
				</button>

				<ErrorMessage
					component={'div'}
					className='query-filter-error'
					name={'queryField'}
				/>

				<button
					className='btn btn-secondary query-filter-cancel-button'
					onClick={this.handleCancel}
				>
					Cancel
				</button>
			</div>
		)
	}

	render() {
		return this.buildFilterCreationField()
	}
}

export default FormikConnect<ComponentProps>(FilterCreationSection)
