// External Dependencies
import * as core from 'club-hub-core'
import * as Feather from 'react-feather'

// Internal Dependencies
import { TableColumn } from '../../Shared/Table'
import { TableColumnType } from '../../Shared/Table/Primitives'

export interface ReservationTableRow extends core.Event.Model {
	owner: core.User.Model
	start?: Date,
	calendarName: string,
	eventType?: core.Club.ResourceType
	reservationID: string,
}

export const ReservationTableColumns = (calendarAlias: string): TableColumn[] => {
	return [
		{
			title: '',
			property: '',
			type: TableColumnType.CHECKBOX,
			action: '',
		},
		{
			title: 'Requesting Member',
			property: 'owner',
			type: TableColumnType.AVATAR_AND_NAME,
			action: 'owner',
			hideOnMobile: true,
		},
		{
			title: 'Date',
			property: 'start',
			type: TableColumnType.DATE,
			action: 'start',
		},
		{
			title: 'Time',
			property: 'start',
			type: TableColumnType.TIME,
			action: 'start',
		},
		{
			title: calendarAlias,
			property: 'calendarName',
			type: TableColumnType.TEXT,
			action: 'calendarName',
		},
		{
			title: 'Type',
			property: 'eventType',
			type: TableColumnType.BADGE,
			action: 'eventType',
		},
		{
			title: '',
			property: '',
			type: TableColumnType.DROPDOWN,
			class: 'table-dropdown-menu',
			dropdownItems: [
				{
					action: 'editEvent',
					valueProperty: 'reservationID',
					displayText: 'Edit Service',
					icon: Feather.Edit,
				},
				{
					action: 'cancelEvent',
					valueProperty: 'reservationID',
					displayText: 'Cancel Service',
					icon: Feather.X,
				},
			]
		}
	]
}
