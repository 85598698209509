// Internal Dependencies

import * as Constants from '../constants'

// Action Types
import { ActionTypes, TypeKeys } from '../actions/alert'

export type AlertState = typeof defaultState

const defaultState = {
	alertType: null as string | null,
	message: null as Constants.FlashType | null,
}

export type ClubState = typeof defaultState
function alert(state = defaultState, action: ActionTypes): ClubState {
	switch (action.type) {
		case TypeKeys.FIRE_FLASH_MESSAGE:
			return {
				...state,
				alertType: action.alertType,
				message: action.message as Constants.FlashType,
			}
		case TypeKeys.CLEAR_FLASH_MESSAGE:
			return {
				...state,
				alertType: null,
				message: null,
			}
		default:
			return state
	}
}

export default alert
