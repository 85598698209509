// External Dependencies
import * as core from 'club-hub-core'
import { Dispatch, Action } from 'redux'
import to from 'await-to-js'

// Internal Dependencies

// API Helpers
import { POST, GET, PUT, DELETE } from '../helpers/api'

// Helpers
import { GeneralMap } from '../helpers/interface'

// Actions
export enum TypeKeys {
	CLEAR_MESSAGES = 'CLEAR_MESSAGES',
	FETCHED_MESSAGES = 'FETCHED_MESSAGES',
	CREATED_MESSAGE = 'CREATED_MESSAGE',
	UPDATED_MESSAGE = 'UPDATED_MESSAGE',
	DELETED_MESSAGE = 'DELETED_MESSAGE',
	SENT_MESSAGE = 'SENT_MESSAGE',
	SENT_TEST_MESSAGE = 'SENT_TEST_MESSAGE',
	PREVIEW_MESSAGE = 'PREVIEW_MESSAGE',
	SET_CURRENT_MESSAGE = 'SET_CURRENT_MESSAGE',
	CLEAR_CURRENT_MESSAGE = 'CLEAR_CURRENT_MESSAGE',
}

export type ActionTypes =
	| ClearMessagesAction
	| FetchedMessageAction
	| CreatedMessageAction
	| UpdatedMessageAction
	| DeletedMessageAction
	| SentMessageAction
	| PreviewMessageAction
	| SetCurrentMessageAction
	| ClearCurrentMessageAction

/**
 * FetchedMessageAction.
 */
export interface FetchedMessageAction extends Action {
	type: TypeKeys.FETCHED_MESSAGES,
	messages: core.Message.Model[],
	receivedAt: number,
	count: number
}

/**
 * CreatedMessageAction.
 */
export interface CreatedMessageAction extends Action {
	type: TypeKeys.CREATED_MESSAGE,
	message: any,
	receivedAt: number
}

/**
 * UpdatedMessageAction.
 */
export interface UpdatedMessageAction extends Action {
	type: TypeKeys.UPDATED_MESSAGE,
	message: core.Message.Model,
	receivedAt: number,
}

/**
 * DeletedMessageAction.
 */
export interface DeletedMessageAction extends Action {
	type: TypeKeys.DELETED_MESSAGE,
}

/**
 * SentMessageAction.
 */
export interface SentMessageAction extends Action {
	type: TypeKeys.SENT_MESSAGE,
	message: any
}

/**
 * SentMessageAction.
 */
export interface SentTestMessageAction extends Action {
	type: TypeKeys.SENT_TEST_MESSAGE
}

/**
 * PreviewMessageAction.
 */
export interface PreviewMessageAction extends Action {
	type: TypeKeys.PREVIEW_MESSAGE,
	preview: string
}

/**
 * SetCurrentMessage.
 */
export interface SetCurrentMessageAction extends Action {
	type: TypeKeys.SET_CURRENT_MESSAGE,
	message: any
}

/**
 * ClearCurrentMessage.
 */
export interface ClearCurrentMessageAction extends Action {
	type: TypeKeys.CLEAR_CURRENT_MESSAGE,
}

/**
* ClearMessagesAction.
*/
export interface ClearMessagesAction extends Action {
	type: TypeKeys.CLEAR_MESSAGES,
}

// -----------------------------------------------------------------------------
// Fetch Messages
// -----------------------------------------------------------------------------

/**
 * Fetches all messages.
 */
const fetchMessages = (pageSize: number, page: number, status: string[]) => async (dispatch: Dispatch<any>) => {
	const limit = pageSize
	const offset = page * limit
	const [err, messageRes] = await to(GET(`/admin/messages`, { limit, offset, status, omitAutomated: true }))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to fetch Messages with error: ${err}`)
		throw err
	}

	dispatch(fetchedMessages(messageRes))
}

/**
 * Builds a `FetchedMessageAction` upon successful creation of a new message.
 * @param response The response from the register API call.
 * @return The `FetchedMessageAction` instance.
 */
const fetchedMessages = (messageRes: GeneralMap<core.Message.Model[] | number>): FetchedMessageAction => {
	const action: FetchedMessageAction = {
		receivedAt: Date.now(),
		type: TypeKeys.FETCHED_MESSAGES,
		messages: messageRes.messages as core.Message.Model[],
		count: messageRes.count as number,
	}
	return action
}

// -----------------------------------------------------------------------------
// Create Message
// -----------------------------------------------------------------------------

/**
 * Creates a new message.
 */
const createMessage = (message: core.Message.Model) => async (dispatch: Dispatch<CreatedMessageAction>) => {
	const [err, newMessage] = await to(POST(`/admin/messages`, message))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to create Message with error: ${err}`)
		throw err
	}

	dispatch(createdMessage(newMessage))
}

/**
 * Builds a `CreatedMessageAction` upon successful creation of a new message.
 * @param response The response from the register API call.
 * @return The `CreatedMessageAction` instance.
 */
const createdMessage = (message: core.Message.Model): CreatedMessageAction => {
	const action: CreatedMessageAction = {
		receivedAt: Date.now(),
		type: TypeKeys.CREATED_MESSAGE,
		message: message,
	}
	return action
}

// -----------------------------------------------------------------------------
// Update Message
// -----------------------------------------------------------------------------

const updateMessage = (messageID: string, message: core.Message.Model) => async (dispatch: Dispatch<UpdatedMessageAction>) => {
	const [err, updatedMessageRes] = await to(PUT(`/admin/messages/${messageID}`, message))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to update Message. ${err}`)
		throw err
	}

	dispatch(updatedMessage(updatedMessageRes))
}

const updatedMessage = (message: core.Message.Model): UpdatedMessageAction => {
	const action: UpdatedMessageAction = {
		receivedAt: Date.now(),
		type: TypeKeys.UPDATED_MESSAGE,
		message: message,
	}
	return action
}

// -----------------------------------------------------------------------------
// Delete Message
// -----------------------------------------------------------------------------

const deleteMessage = (messageID: string) => async (dispatch: Dispatch<DeletedMessageAction>) => {
	const [err, deletedMessageRes] = await to(DELETE(`/admin/messages/${messageID}`, {}))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to delete Message. ${err}`)
		throw err
	}

	dispatch(deletedMessage(deletedMessageRes))
}

const deletedMessage = (message: core.Message.Model): DeletedMessageAction => {
	const action: DeletedMessageAction = {
		type: TypeKeys.DELETED_MESSAGE,
	}
	return action
}

// -----------------------------------------------------------------------------
// Preview Message
// -----------------------------------------------------------------------------

const fetchPreviewMessage = (messageID: string) => async (dispatch: Dispatch<PreviewMessageAction>) => {
	const [err, messagePreview] = await to(GET(`/admin/messages/${messageID}/preview`, {}))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to send Message with error: ${err}`)
		throw err
	}

	dispatch(fetchedPreviewMessage(messagePreview))
}

const fetchedPreviewMessage = (preview: any): PreviewMessageAction => {
	const action: PreviewMessageAction = {
		type: TypeKeys.PREVIEW_MESSAGE,
		preview: preview,
	}
	return action
}

// -----------------------------------------------------------------------------
// Send Message
// -----------------------------------------------------------------------------

const sendMessage = (messageID: string) => async (dispatch: Dispatch<SentMessageAction>) => {
	const [err, message] = await to(POST(`/admin/messages/${messageID}/send`, {}))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to send Message with error: ${err}`)
		throw err
	}

	dispatch(sentMessage(message))
}

const sentMessage = (message: core.Message.Model): SentMessageAction => {
	const action: SentMessageAction = {
		type: TypeKeys.SENT_MESSAGE,
		message: message,
	}
	return action
}

// -----------------------------------------------------------------------------
// Send Test Message
// This action dispatches a sample message to the author and retains its
// current 'draft'/'template' status
// -----------------------------------------------------------------------------

const sendTestMessage = (messageID: string) => async (dispatch: Dispatch<SentTestMessageAction>) => {
	const [err, message] = await to(POST(`/admin/messages/${messageID}/send_test`, {}))
	if (err) {
		// tslint:disable-next-line
		console.error(`Failed to send Message with error: ${err}`)
		throw err
	}

	dispatch(sentTestMessage(message))
}

const sentTestMessage = (message: core.Message.Model): SentTestMessageAction => {
	const action: SentTestMessageAction = {
		type: TypeKeys.SENT_TEST_MESSAGE
	}
	return action
}

// -----------------------------------------------------------------------------
// Set Current Message
// -----------------------------------------------------------------------------

const setCurrentMessage = (message: core.Message.Model) => async (dispatch: Dispatch<SetCurrentMessageAction>) => {
	const action: SetCurrentMessageAction = {
		type: TypeKeys.SET_CURRENT_MESSAGE,
		message: message
	}
	dispatch(action)
}

// -----------------------------------------------------------------------------
// Clear Current Message
// -----------------------------------------------------------------------------

const clearCurrentMessage = () => async (dispatch: Dispatch<ClearCurrentMessageAction>) => {
	const action: ClearCurrentMessageAction = {
		type: TypeKeys.CLEAR_CURRENT_MESSAGE,
	}
	dispatch(action)
}

// -----------------------------------------------------------------------------
// Clear Messages
// -----------------------------------------------------------------------------

/**
 * Clears messages.
 */
const clearMessages = () => (dispatch: Dispatch<ClearMessagesAction>) => {
	const action: ClearMessagesAction = {
		type: TypeKeys.CLEAR_MESSAGES,
	}
	dispatch(action)
}

export const MessageActions = {
	createMessage,
	updateMessage,
	deleteMessage,
	fetchMessages,
	fetchPreviewMessage,
	sendMessage,
	sendTestMessage,
	setCurrentMessage,
	clearCurrentMessage,
	clearMessages
}
