// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as RS from 'reactstrap'
import * as Feather from 'react-feather'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { oc } from 'ts-optchain'
import BigCalendar from 'react-big-calendar'
import to from 'await-to-js'

import { RootReducerState } from '../../../../reducers'
import { calendarsByIDSelector } from '../../../../reducers/calendar'

import { BigCalendarEvent } from '../'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

interface ComponentProps {
	isAllDay: boolean
	event: BigCalendarEvent
	title: string
}

const initialState = {
	loading: false,
	error: false,
}

type Props = ComponentProps & ConnectedState & ConnectedActions & RouteComponentProps
type State = typeof initialState

/**
 * CalendarTopBar
 */
class EventView extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
	}

	getContainerStyle = (color: string, fullDay: boolean) => {
		const backgroundColor = fullDay ? color : 'transparent'
		return { backgroundColor }
	}

	getLabelStyle = (color: string, fullDay: boolean) => {
		return fullDay ? { color: 'white' } : null
	}

	getIndicator = (color: string, fullDay: boolean) => {
		if (fullDay) { return null }
		const style = { backgroundColor: color }
		return (<div className={'calendar-color-indicator'} style={style} />)
	}

	render() {
		const event = this.props.event.resource
		const calendar = this.props.calendarsByID[`${event.calendarIDs[0]}`]
		const color = calendar.color

		const eventEnd = new Date(event.end)
		const endOfDay = new Date(event.start)
		endOfDay.setHours(23, 59, 59, 999)

		const fullDay = (eventEnd.getTime() > endOfDay.getTime())
		const containerStyle = this.getContainerStyle(color, fullDay)
		const labelStyle = this.getLabelStyle(color, fullDay)
		const indicator = this.getIndicator(color, fullDay)

		const options = { hour: 'numeric', minute: 'numeric' }
		const start = this.props.event.start.toLocaleTimeString('en-US', options)
		return (
			<div className={'event-view-container'} style={containerStyle}>
				{indicator}
				<p className={'event-time-label'} style={labelStyle}>{start}</p>
				<p className={'event-title-label'} style={labelStyle}>{this.props.title}</p>
			</div>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	calendarsByID: calendarsByIDSelector(state)
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(EventView)
