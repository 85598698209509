// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { FormikActions, FormikProps } from 'formik'
import { isNullOrUndefined } from 'util'
import to from 'await-to-js'
import { oc } from 'ts-optchain'

// Internal Dependencies

// Actions
import { AlertActions, UserActions } from '../../../actions/index'

// State
import { RootReducerState } from '../../../reducers'
import { inCustomerViewSelector } from '../../../reducers/user'

// Form
import GenericFormComponent from '../../Shared/Formik/GenericForm'
import { FormInput, ReactSelectItem } from '../../Shared/Form'
import ModalComponent from '../../Shared/Modal'
import { AdminVehicleFormInputs, CustomerVehicleFormInputs, VehicleFormState } from './form'
import { BuildWrappedForm } from '../../Shared/Formik'

// Helpers
import * as Constants from '../../../constants'
import { setStateAsync } from '../../../helpers/promise'
import { userForForm } from '../../../helpers/user'

// Components
import DimmedLoader from '../../Shared/DimmedLoader'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	error: false,
	loading: true,
	userToUpdate: null as core.User.Model | null,
	vehicleToUpdate: null as core.SubModels.CarMeta.Vehicle | null,
	vehicleToDelete: null as core.SubModels.CarMeta.Vehicle | null,
}

type Props = RouteComponentProps & ConnectedActions & ConnectedState
type State = typeof initialState

class VehicleForm extends React.Component<Props, State> {
	private isUpdateForm: boolean

	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
		this.isUpdateForm = this.props.location.pathname === Constants.UPDATE_VEHICLE_ROUTE

	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		await this.setVehicleForFormWithQueryParams()
		await setStateAsync(this, { loading: false })
	}

	/**
	 * Gets the Vehicle, that is being updated, from state using its ID that we get
	 * from the query parameters in the URL.
	 *
	 * The Vehicle will have its information pre-filled into the form.
	 */
	setVehicleForFormWithQueryParams = async () => {
		// Parse the query string of the URL into an object
		const parsedQuery = queryString.parse(this.props.location.search)

		// Get the User by their ID
		const userID = parsedQuery.userID
		const vehicleID = parsedQuery.vehicleID

		// Get the User
		const adminMembers = this.props.userState.users
		const userToUpdate = adminMembers.find((member) => member._id === userID)

		// Get the Vehicle
		const vehicleToUpdate = userToUpdate.meta.car.vehicles.find((vehicle: core.SubModels.CarMeta.Vehicle) => vehicle._id === vehicleID)

		// Update the state
		await setStateAsync(this, { userToUpdate: userToUpdate, vehicleToUpdate: vehicleToUpdate })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Returns the user to the Vehicles view when they cancel the form
	 */
	handleCancel = () => {
		this.redirectFromForm()
	}

	/**
	 * Determines which handler to call (Create or Update) when the user submits the form
	 */
	handleSave = async (form: VehicleFormState, actions?: FormikActions<VehicleFormState>): Promise<void> => {
		switch (this.props.location.pathname) {
			case Constants.CREATE_VEHICLE_ROUTE:
				actions.setSubmitting(true)
				await this.handleCreate(form)
				actions.setSubmitting(false)
				break
			case Constants.UPDATE_VEHICLE_ROUTE:
				actions.setSubmitting(true)
				await this.handleUpdate(form)
				actions.setSubmitting(false)
				break
			default:
				break
		}
	}

	/**
	 * Will create a new Vehicle on the DB with the information in the form.
	 * Vehicles will only be created if the validation conditions for the form are met.
	 */
	handleCreate = async (form: VehicleFormState): Promise<void> => {
		await setStateAsync(this, { loading: true })

		const userState = this.props.userState
		const userID = (this.props.isCustomerView) ? `${userState.loggedInUser._id}` : form.member.value
		const metaField = 'meta.car.vehicles'
		const formData = this.buildVehiclePayload(form, userID)
		const [err] = await to(this.props.createUserMeta(userID, metaField, formData, !this.props.isCustomerView) as any)
		if (err) {
			this.props.fireFlashMessage(`Problem creating Vehicle. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}

		this.props.fireFlashMessage('Vehicle successfully created.', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, error: false })

		this.redirectFromForm()
	}

	/**
	 * Will update an existing vehicle on the DB with the information in the form.
	 * vehicles will only be updated if the validation conditions for the form are met.
	 */
	handleUpdate = async (form: VehicleFormState): Promise<void> => {
		if (!this.state.userToUpdate || !this.state.vehicleToUpdate) { return }
		await setStateAsync(this, { loading: true })

		const vehicleID = this.state.vehicleToUpdate._id
		const userState = this.props.userState
		const userID = (this.props.isCustomerView) ? `${userState.loggedInUser._id}` : form.member.value
		const metaField = 'meta.car.vehicles'
		const metaID = this.state.vehicleToUpdate._id
		const formData = this.buildVehiclePayload(form, userID, vehicleID)
		const [err] = await to(this.props.updateUserMeta(userID, metaField, formData, !this.props.isCustomerView) as any)
		if (err) {
			this.props.fireFlashMessage(`Problem updating Vehicle. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true, vehicleToUpdate: null })
			return
		}

		this.props.fireFlashMessage('Vehicle successfully updated.', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, error: false, vehicleToUpdate: null })

		this.redirectFromForm()
	}

	/**
	 * Handles deleting a vehicle
	 */
	handleDelete = async (): Promise<void> => {
		if (!this.state.userToUpdate || !this.state.vehicleToUpdate) { return }
		await setStateAsync(this, { loading: true })

		const userState = this.props.userState
		const userID = (this.props.isCustomerView) ? `${userState.loggedInUser._id}` : `${this.state.userToUpdate._id}`
		const metaField = 'meta.car.vehicles'
		const metaID = `${this.state.vehicleToUpdate._id}`
		const [err] = await to(this.props.deleteUserMeta(userID, metaField, metaID, !this.props.isCustomerView) as any)
		if (err) {
			this.props.fireFlashMessage(`Problem deleting Vehicle. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true, vehicleToUpdate: null })
			return
		}

		this.props.fireFlashMessage('Vehicle successfully deleted.', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, error: false, vehicleToUpdate: null })
		this.redirectFromForm()
	}

	showDeleteConfirmation = (vehicle: core.SubModels.CarMeta.Vehicle) => {
		setStateAsync(this, { vehicleToDelete: vehicle })
	}

	closeDeleteVehicleModal = () => {
		setStateAsync(this, { vehicleToDelete: null })
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	redirectFromForm = () => {
		// Redirect back to the User's Detail View if the user is a Customer
		if (this.props.isCustomerView) {
			const queryParams = {
				userID: this.props.userState.loggedInUser._id
			}

			const location = {
				pathname: Constants.VIEW_USER_ROUTE,
				search: queryString.stringify(queryParams)
			}

			this.props.history.push(location)
			return
		}

		this.props.history.push(Constants.VEHICLES_ROUTE)
	}

	/**
	 * Builds the payload, that will be sent to the server, based on the form
	 */
	buildVehiclePayload = (form: VehicleFormState, userID: string, vehicleID?: any): FormData => {
		const { loggedInClub } = this.props

		const clubID = loggedInClub._id

		const formData = new FormData()
		formData.append('club', JSON.stringify(clubID))

		const vehicle: core.SubModels.CarMeta.Vehicle = {
			make: form.make,
			model: form.model,
			year: form.year,
			color: form.color,
			vehicleNumber: form.vehicleNumber,
			vin: form.vin,
			description: form.description,
			userID: userID as any,
			clubID: clubID as any,
			keySpots: form.keySpots,
			bay: form?.bay ? form.bay.toString() : ''
		}

		if (vehicleID) {
			vehicle._id = vehicleID
		}
		formData.append('vehicle', JSON.stringify(vehicle))

		if (Array.isArray(form.image)) {
			// tslint:disable-next-line
			for (let i = 0; i < form.image.length; i++) {
				const formImage: File | core.SubModels.Image.Model = form.image[i]
				if ((formImage as core.SubModels.Image.Model)._id) {
					vehicle.image = formImage as core.SubModels.Image.Model
					break
				}
				formData.append('image', formImage)
				break
			}
		} else if (!isNullOrUndefined(form.image)) {
			// If form.image is not an array, then we know that
			// it is an existing image coming from the resource
			vehicle.image = form.image
		}
		return formData
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildDeleteConfirmationModal = () => {
		if (!this.state.vehicleToDelete) { return null }
		return (
			<ModalComponent
				modalTitle={'Delete Vehicle'}
				primaryMessage={'Are you sure you want to delete this Vehicle?'}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.closeDeleteVehicleModal}
				submitButtonName={'Confirm'}
				submitButtonHandler={this.handleDelete}
			/>
		)
	}

	buildFormInputs = () => {
		const { userState } = this.props

		const users = oc(userState).users([])
		const usersForForm = users.map(userForForm)
		const formInputs = this.getFormInputs(usersForForm)
		return formInputs
	}

	buildForm = () => {
		const vehicleToUpdate = (this.isUpdateForm && this.state.vehicleToUpdate) ?
			this.state.vehicleToUpdate :
			undefined
		const formTitle = (this.isUpdateForm) ? 'Update Vehicle' : 'New Vehicle'
		const submitButtonName = (this.isUpdateForm) ? 'Update' : 'Save'

		// We have to set the member if we are updating
		let formResource: any = {}
		if (vehicleToUpdate) {
			formResource = {
				...vehicleToUpdate,
				image: oc(vehicleToUpdate).image()
			}
		}
		formResource.member = oc(this).state.userToUpdate._id()
		const secondaryButtonName = (this.isUpdateForm) ? 'Delete' : null
		const secondaryButtonHandler = (this.isUpdateForm) ? this.showDeleteConfirmation : null

		return (
			<GenericFormComponent
				title={formTitle}
				inputs={this.buildFormInputs()}
				formResource={formResource}
				enableReinitialize={true}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.handleCancel}
				secondaryButtonName={secondaryButtonName}
				secondaryButtonHandler={(values, actions) => {
					actions.setSubmitting(false)
					return secondaryButtonHandler(vehicleToUpdate)
				}}
				dangerSecondary={true}
				submitButtonName={submitButtonName}
				submitButtonHandler={this.handleSave}
				render={(formikProps) => this.buildFormBody(this.buildFormInputs(), formikProps)}
			/>
		)
	}

	buildFormBody = (inputs: FormInput[], formikProps: FormikProps<VehicleFormState>) => {
		return (
			<>
				{BuildWrappedForm({ inputs }, formikProps)}
			</>
		)
	}

	render() {
		return (
			<div className='row vehicle-form-container justify-content-center mt-4 mx-auto'>
				<div className='col col-md-10 col-xl-8 d-flex justify-content-center'>
					<DimmedLoader component={this.buildForm()} isLoading={this.state.loading} />
					{this.buildDeleteConfirmationModal()}
				</div>
			</div>
		)
	}

	// ----------------------------------------------------------------------------------
	// Form Helpers
	// ----------------------------------------------------------------------------------

	getFormInputs = (membersForForm: ReactSelectItem[]): FormInput[] => {
		const { isCustomerView } = this.props

		const formInputs = (isCustomerView) ?
			CustomerVehicleFormInputs() :
			AdminVehicleFormInputs(membersForForm)

		return formInputs
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	userState: state.user,
	loggedInClub: state.club.loggedInClub,
	isCustomerView: inCustomerViewSelector(state),
})

const mapDispatchToProps = {
	...UserActions,
	...AlertActions,
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleForm)
