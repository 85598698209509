// External Dependencies
import * as Yup from 'yup'

// Internal Dependencies
import { FormInput, FormInputType } from '../../Shared/Form'
import * as Constants from '../../../constants/index'

export interface PDFModalState {
	name: string,
	link: string,
}

export const PDFModalInputs = (): FormInput[] => {
	return [
		{
			title: 'Name',
			property: 'name',
			type: FormInputType.TEXT,
			size: 12,
			placeholder: 'Date...',
			defaultValue: '',
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Link',
			property: 'link',
			type: FormInputType.TEXT,
			size: 12,
			placeholder: 'Link...',
			defaultValue: '',
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		}
	]
}
