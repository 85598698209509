// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import to from 'await-to-js'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { oc } from 'ts-optchain'

// Actions
import { AlertActions, CustomerActions } from '../../../actions/index'

// State
import { RootReducerState } from '../../../reducers'

// Form
import { guestFormInputs, GuestFormState } from './form'

// Components
import FormModal from '../../Shared/Formik/FormModal'
import { InputSelectionItem } from '../../Shared/Form'
import * as Constants from '../../../constants'

// Helpers
import { setStateAsync } from '../../../helpers/promise'
import { fullName } from '../../../helpers/user'

interface ComponentProps {
	club: core.Club.Model
	handleNewGuest: (guest: core.User.Model) => void
	handleToggle: () => void
}

const initialState = {
	loading: false,
}

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

type Props = ConnectedActions & ComponentProps & ConnectedState
type State = typeof initialState

/**
 * The `BlockModal` provides UI for blocking a calendar.
 */
class BlockModal extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)

		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Handlers
	// ----------------------------------------------------------------------------------

	handleChange = async (field: string, item: InputSelectionItem) => {
		return
	}

	// ----------------------------------------------------------------------------------
	// Block Network Requests
	// ----------------------------------------------------------------------------------

	handleSaveGuest = async (form?: GuestFormState) => {
		await setStateAsync(this, { loading: true })

		const createPayload: core.User.Model = form
		const clubID = this.props.club._id

		// Create the guest user
		const [err, guestUser] = await to(this.props.createGuestUser(createPayload, `${clubID}`) as any)
		if (err) {
			this.props.fireFlashMessage(`Problem creating User. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false })
			return
		}

		this.props.fireFlashMessage('User successfully created.', Constants.FlashType.SUCCESS)
		const guestData = { label: fullName(guestUser), value: `${guestUser._id}` }

		await this.props.handleNewGuest(guestUser)
		await setStateAsync(this, { loading: false })
	}

	// ----------------------------------------------------------------------------------
	// Component Builders
	// ----------------------------------------------------------------------------------

	public render() {
		return (
			<FormModal<any, GuestFormState>
				modalTitle={'New Guest'}
				formSpec={guestFormInputs()}
				cancelButtonHandler={this.props.handleToggle}
				submitButtonHandler={this.handleSaveGuest}
			/>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	userState: state.user,
	calendarState: state.calendar
})

const mapDispatchToProps = {
	...AlertActions,
	...CustomerActions
}

const enhance = compose<React.ComponentType<ComponentProps>>(
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(BlockModal)
