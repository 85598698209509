// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import * as Feather from 'react-feather'
import { connect } from 'react-redux'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'

// Internal Dependencies

// Core
import * as core from 'club-hub-core'

// Components
import { InputSelectionItem } from '../Form'
import { MemberSelectInput } from '../Formik/InputPrimitives/MemberSelectInput'

interface CustomProps {
	title: string
	loggedInUser: core.User.Model
	club: core.Club.Model,
	isCustomerView: boolean
	guestCount: number
	maxGuests: number
	guests: InputSelectionItem[]
	users: core.User.Model[]
	handleAddGuest?: () => void
	handleFormChange?: (e: any) => void
	handleMemberSelected: (data: InputSelectionItem, rowIndex: number) => void
	removeGuestInput: (rowIndex: number) => void
	reservedUserIDsMap: core.IShared.GeneralMap<string>
}

type Props = CustomProps

/**
 * Builds select inputs made up of club members.
 * Used in the Confirm Reservation component to build members / guest member dropdowns.
 */
class MemberInputComponent extends React.Component<Props> {

	constructor(props: Props) {
		super(props)
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildContent = () => {
		const items = Array.from({ length: this.props.guestCount }, (v, i) => {
			const disableInput = (this.props.isCustomerView && i === 0)
			return this.buildMemberSelectInput(i, disableInput, 'Select Guest...')
		})

		let addGuestButton
		if (this.props.handleAddGuest) {
			addGuestButton = (this.props.guestCount >= this.props.maxGuests) ? null :
				(
					<div className='add-guest-button-container'>
						<RS.Button color={'link'} onClick={this.props.handleAddGuest}>Add Guest</RS.Button>
					</div>
				)
		}

		return (
			<RS.FormGroup onChange={this.props.handleFormChange} className='guest-inputs'>
				<RS.Label className='form-label'>{this.props.title}</RS.Label>
				{items}
				{addGuestButton}
			</RS.FormGroup>
		)
	}

	/**
	 * Builds the select input for picking a guest.
	 * @param rowIndex The index of the row that the input belongs to
	 */
	buildMemberSelectInput = (rowIndex: number, disabled: boolean, placeholder: string) => {
		return (
			<MemberSelectInput
				{...this.props}
				key={rowIndex}
				rowIndex={rowIndex}
				disabled={disabled}
				removeGuestInput={this.props.removeGuestInput}
			/>
		)
	}

	render() {
		return (
			this.buildContent()
		)
	}
}

const mapStateToProps = () => ({})
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(MemberInputComponent)
