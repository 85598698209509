// We need to import the polyfills before anything else
import 'whatwg-fetch'
import '@babel/polyfill'

// External Dependencies
import { AppContainer } from 'react-hot-loader'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

// Internal Dependencies
import { App } from './containers/App'
import { VERSION, COMMITHASH, BRANCH } from './constants'

import './assets/base_assets.scss'

import configureStore from './store'
import { loadState } from './store/localStorage'

const persistedState = loadState()
const appStore = configureStore(persistedState)

// Stating the current git info
// tslint:disable-next-line
console.info(`VERSION: ${VERSION}\nCOMMIT: ${COMMITHASH}\nBRANCH: ${BRANCH}`)

const render = (Component: typeof App) => {
	ReactDOM.render(
		<AppContainer>
			<Provider store={appStore}>
				<BrowserRouter>
					<Component />
				</BrowserRouter>
			</Provider>
		</AppContainer>,
		document.getElementById('app')
	)
}
render(App)
// Webpack Hot Module Replacement API
if (module.hot) {
	module.hot.accept('./containers/App', () => render(App))
}
// expose store during tests
if ((window as any).Cypress) {
	(window as any).store = appStore
}
