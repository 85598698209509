// External Dependencies
import React from 'react'
import scrollIntoView from 'scroll-into-view-if-needed'
import { connect, FormikContext, FormikValues } from 'formik'

interface ComponentProps {
	formik: FormikContext<FormikValues>
}

type Props = ComponentProps

/**
 * HOC for scrolling to the input element that failed Formik validation.
 * Example: https://gist.github.com/dphrag/4db3b453e02567a0bb52592679554a5b - SG.
 */
class ErrorFocus extends React.Component<Props> {

	componentDidUpdate(prevProps: Props) {
		const { isSubmitting, isValidating, errors } = prevProps.formik
		const errorKeys = Object.keys(errors)

		if (errorKeys.length > 0 && isSubmitting && !isValidating) {
			const elementID = errorKeys[0]
			const element = document.getElementById(elementID)

			if (element) {
				scrollIntoView(element, { behavior: 'smooth', scrollMode: 'if-needed', inline: 'start' })
			}
		}
	}

	render(): null {
		return null
	}
}

export default connect<{}>(ErrorFocus)
