// External Dependencies
import * as React from 'react'
import * as Feather from 'react-feather'
import * as core from 'club-hub-core'

// Internal Dependencies
import Card from '../../../Shared/Cards/Card'

interface ComponentProps {
	onClick: (type: core.Message.DeliveryType) => any
}

type Props = ComponentProps
type State = any

export default class DeliveryTypePicker extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildContent = () => {
		const items = [
			{
				title: 'Email',
				description: `Emails will be delivered to your member's inbox.`,
				featherIcon: Feather.Mail,
				type: core.Message.DeliveryType.Email,
			},
			{
				title: 'Push Notification',
				description: `Push notifications are delivered to your member's mobile application.`,
				featherIcon: Feather.Bell,
				type: core.Message.DeliveryType.Push,
			},
			{
				title: 'Text Message',
				description: `Text messages are sent to your member's phone number.`,
				featherIcon: Feather.MessageCircle,
				type: core.Message.DeliveryType.Text,
			}
		]
		const content = items.map((item, idx) => this.buildDeliveryTypePickerItem(item.type, item.title, item.description, item.featherIcon, idx))

		return (
			<Card
				content={content}
				cardClass={'delivery-type-card mx-auto'}
				bodyClass={'mx-auto'}
				hideHeader={true}
			/>
		)
	}

	buildDeliveryTypePickerItem = (type: core.Message.DeliveryType, title: string, description: string, featherIcon: any, index: number) => {
		const FeatherIcon = featherIcon
		return (
			<div
				key={`deliveryTypePickerItem_${index}`}
				className='delivery-type-item row no-gutters d-flex align-items-center'
				onClick={() => this.props.onClick(type)}
			>
				<div className='col-2'>
					<FeatherIcon className={'delivery-type-icon'} size={40} />
				</div>

				<div className='col-8'>
					<div className='delivery-type-item-title'>{title}</div>
					<div className='delivery-type-item-description'>
						{description}
					</div>
				</div>

				<div className='col-2 d-flex justify-content-end'>
					<Feather.ChevronRight size={24}/>
				</div>
			</div>
		)
	}

	render() {
		return this.buildContent()
	}
}
