// External Dependencies
import * as core from 'club-hub-core'
import * as Feather from 'react-feather'

// Internal Dependencies
import { TableColumn } from '../Shared/Table'
import { TableColumnType } from '../Shared/Table/Primitives'

export interface ServiceTableRow extends core.Event.Model {
	serviceReservationID: string,
	serviceName: string,
	serviceProvider: string,
}

export const ServiceTableColumns: TableColumn[] = [
	{
		title: '',
		property: '',
		type: TableColumnType.CHECKBOX,
		action: '',
	},
	{
		title: 'Vehicle',
		property: 'serviceName',
		type: TableColumnType.TEXT,
		action: 'serviceName',
	},
	{
		title: 'Date',
		property: 'start',
		type: TableColumnType.DATE,
		action: 'start',
	},
	{
		title: 'Service Provider',
		property: 'serviceProvider',
		type: TableColumnType.TEXT,
		action: 'serviceProvider',
	},
	{
		title: 'Type',
		property: 'serviceTypeBadge',
		type: TableColumnType.BADGE,
		action: 'serviceType',
	},
	{
		title: 'Member',
		property: 'owner',
		type: TableColumnType.AVATAR_AND_NAME,
		action: 'owner',
		hideOnMobile: true,
	},
	{
		title: '',
		property: '',
		type: TableColumnType.DROPDOWN,
		class: 'table-dropdown-menu',
		dropdownItems: [
			{
				action: 'editEvent',
				valueProperty: 'serviceReservationID',
				displayText: 'Edit Service',
				icon: Feather.Edit,
			},
			{
				action: 'cancelEvent',
				valueProperty: 'serviceReservationID',
				displayText: 'Cancel Service',
				icon: Feather.X,
			},
		]
	}
]
