// External Dependencies.
import * as Yup from 'yup'

// Internal Dependencies.
import { FormInput, FormInputType } from '../../Shared/Form'
import * as Constants from '../../../constants/index'

export interface GuestFormState {
	firstName: string
	lastName: string
	email: string
}

/**
 * Returns the form input fields used in the guest creation modal.
 */
export const guestFormInputs = (): FormInput[] => {
	return [
		{
			title: 'First Name',
			property: 'firstName',
			type: FormInputType.TEXT,
			size: 12,
			placeholder: 'Enter first name...',
			defaultValue: '',
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Last Name',
			property: 'lastName',
			type: FormInputType.TEXT,
			size: 12,
			placeholder: 'Enter last name...',
			defaultValue: '',
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Email',
			property: 'email',
			type: FormInputType.TEXT,
			size: 12,
			placeholder: 'Enter email...',
			defaultValue: '',
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		}
	]
}
