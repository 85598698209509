// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import * as core from 'club-hub-core'
import { oc } from 'ts-optchain'

// Internal Dependencies

// Components
import TileCard from '../TileCard'
import { link } from 'fs'

interface Props {
	club: core.Club.Model
	onClick: (post: core.Club.QuickLink) => any
}

export default class TileCardContainer extends React.Component<Props, any> {
	constructor(props: Props) {
		super(props)
	}

	render() {
		const links = oc(this).props.club.website.quickLinks([])
		if (links.length === 0) { return null }

		const sliced = links.slice(0, 6)
		const cards = sliced.map((l: core.Club.QuickLink, index: number) => {
			return (
				<RS.Col key={`${l.title}_${index}`} xs={6} sm={4}>
					<TileCard
						club={this.props.club}
						quickLink={l}
						onClick={this.props.onClick}
					/>
				</RS.Col>
			)
		})

		return (
			<RS.Row className='home-tile-content-row'>
				{cards}
			</RS.Row>
		)
	}
}
