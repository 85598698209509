import { FormInputType, FormInput, InputSelectionItem } from '../../Form'
import * as Yup from 'yup'

import { colorSelectionInputs } from '../../../../helpers/form'

export interface NewCalendarFormState {
	name: string
	color: InputSelectionItem
}

export const CalendarFormInputs: FormInput[] = [
	{
		title: 'Name',
		property: 'name',
		type: FormInputType.TEXT,
		size: 8,
		placeholder: 'Enter a name...',
		defaultValue: '',
		validation: Yup.string().required('Required.')
	},
	{
		title: 'Color',
		property: 'color',
		type: FormInputType.SELECT,
		selectItems: colorSelectionInputs,
		size: 4,
		placeholder: 'Select...',
		defaultValue: '',
		validation: Yup.string().required('Required.')
	}
]
