import * as React from 'react'
import ReactAddToCalendar, { CalendarEventInterface } from 'react-add-to-calendar'
import * as moment from 'moment'
import * as core from 'club-hub-core'
import { oc } from 'ts-optchain'

interface Props {
	event: core.Event.Model
}

/**
 * Component used for adding an Event to the User's personal calendar.
 */
const AddToCalendarComponent: React.FC<Props> = ({ event }: Props) => {

	const calendarEvent: CalendarEventInterface = {
		title: oc(event).name(''),
		description: oc(event).richContent.text(''),
		location: `${oc(event).location.address1('N/A')} ${oc(event).location.city('N/A')}, ${oc(event).location.state('N/A')} ${oc(event).location.zip('')}`,
		startTime: moment(event.start),
		endTime: moment(event.end)
	}

	return (
		<ReactAddToCalendar event={calendarEvent} />
	)
}

export default AddToCalendarComponent
