// External Dependencies
import * as Yup from 'yup'
import * as core from 'club-hub-core'
import { oc } from 'ts-optchain'

// Internal Dependencies
import { FormInputType, FormInput, InputSelectionItem } from '../../Shared/Form'

export interface SectionFormState {
	name: string,
	icon: string,
	image: File[],
	visibility: InputSelectionItem
	description: string
}

export const SectionFormInputs = (section: core.Section.Model): FormInput[] => {
	const visibilityLabel = oc(section).public(false) ? 'Public' : 'Private'
	const visibilityValue = oc(section).public(false) ? 'public' : 'private'
	return [
		{
			title: 'Name',
			property: 'name',
			type: FormInputType.TEXT,
			size: 12,
			placeholder: 'Enter a name...',
			defaultValue: '',
			validation: Yup.string().required('Field is required.'),
		},
		{
			title: 'Icon',
			property: 'icon',
			type: FormInputType.TEXT,
			size: 12,
			placeholder: 'Enter icon...',
			defaultValue: '',
		},
		{
			title: 'Visibility',
			property: 'visibility',
			type: FormInputType.SELECT,
			size: 12,
			placeholder: 'Select section visibility...',
			defaultValue: { label: visibilityLabel, value: visibilityValue },
			selectItems: [{ label: 'Public', value: 'public' }, { label: 'Private', value: 'private' }],
		},
		{
			title: 'Image',
			property: 'image',
			type: FormInputType.FILE,
			size: 12,
			file_accept: '.png,.jpg,.jpeg',
			file_multipleFiles: false,
		},
		{
			title: 'Description',
			property: 'description',
			type: FormInputType.TEXT_AREA,
			size: 12,
			placeholder: 'Enter description ...',
			defaultValue: '',
		}
	]
}
