// External Dependencies
import * as Yup from 'yup'

// Internal Dependencies
import { FormInput, FormInputType, InputSelectionItem } from '../Shared/Form'
import { colorSelectionInputs } from '../../helpers/form'

export interface ClubCalendarForm {
	name: string
	color: string
	groupID: string
}

export const ClubCalendarFormInputs = (groupID: string): FormInput[] => ([
	{
		title: 'Name',
		property: 'name',
		type: FormInputType.TEXT,
		size: 8,
		placeholder: 'Enter a name...',
		defaultValue: '',
		validation: Yup.string().required('Required.')
	},
	{
		title: 'Color',
		property: 'color',
		type: FormInputType.COLOR,
		size: 4,
		placeholder: 'Select...',
		defaultValue: '',
		validation: Yup.string().required('Required.')
	},
	{
		title: '',
		property: 'groupID',
		type: FormInputType.BLANK,
		defaultValue: groupID
	}
])
