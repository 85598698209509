// External Dependencies
import * as core from 'club-hub-core'
import * as Yup from 'yup'

// Internal Dependencies
import { FormInputType, FormInput, InputSelectionItem } from '../../Shared/Form'
import * as Constants from '../../../constants/index'

export interface RecurrenceModalState {
	recurring: InputSelectionItem
	recurringEnd: Date
}

export const RecurrenceModalInputs = (): FormInput[] => {
	return [
		{
			title: 'Recurring',
			property: 'recurring',
			type: FormInputType.SELECT,
			size: 6,
			selectItems: [
				{ label: 'Daily', value: `${core.Event.RecurringFrequency.DAILY}` },
				{ label: 'Weekly', value: `${core.Event.RecurringFrequency.WEEKLY}` },
				{ label: 'Monthly', value: `${core.Event.RecurringFrequency.MONTHLY}` },
				{ label: 'Annually', value: `${core.Event.RecurringFrequency.YEARLY}` },
			],
			placeholder: 'Recurring...',
			validation: Yup.string().nullable().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'End Date',
			property: 'recurringEnd',
			type: FormInputType.DATE,
			size: 6,
			placeholder: 'Select End Date...',
			date_end_field: 'end',
			defaultValue: null,
			validation: Yup.date().nullable().default(null).required(Constants.REQUIRED_FIELD),
		},
	]
}
