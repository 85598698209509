// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'

// Internal Dependencies
import { BaseInputPrimitiveProps } from '../index'

type Props = BaseInputPrimitiveProps

export const TextAreaInput: React.FunctionComponent<Props> = (props: Props) => {
	return (
		<RS.Input
			{...props as any}
			id={props.id || props.item.property}
			name={props.id || props.item.property}
			type={props.item.type}
			rows={5}
			disabled={props.item.disabled}
			placeholder={props.item.placeholder}
			value={props.value}
			onChange={props.onChange}
		/>
	)
}
