// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import * as core from 'club-hub-core'
import * as queryString from 'query-string'
import * as Feather from 'react-feather'
import { oc } from 'ts-optchain'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter, RouteComponentProps } from 'react-router'
import to from 'await-to-js'

// Helpers
import { setStateAsync } from '../../helpers/promise'

// Actions and Action Interface Imports
import { AppActions, UserActions, PostActions, EventActions, ClubActions, AlertActions } from '../../actions/index'

// Reducer Interface Imports
import { RootReducerState } from '../../reducers'
import { inCustomerViewSelector } from '../../reducers/user'
import { calendarsByIDSelector } from '../../reducers/calendar'

// Cards
import HomeCard from './HomeCard'
import CoverImageCard from './CoverImageCard'
import TileCardContainer from './TileCardContainer'
import EditStatusModal from './EditStatusModal'
import EditCoverImageModal from './EditCoverImageModal'

import ErrorComponent from '../Shared/ErrorComponent'
import DimmedLoader from '../Shared/DimmedLoader'
import EmptyContent, { ContentType } from '../Shared/EmptyContent'

import * as Constants from '../../constants'
import * as WeatherService from '../../services/weather'

// Connected State Interface
type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

// State Key Constants
type ModalStateKey = typeof EditingStatus | typeof EditingCoverImage
const EditingStatus = 'editingStatus'
const EditingCoverImage = 'editingCoverImage'

const initialState = {
	loading: false,
	error: false,
	weather: null as WeatherService.WeatherResponse | null,
	[EditingStatus]: false,
	[EditingCoverImage]: false,
}

type Props = RouteComponentProps & ConnectedState & ConnectedActions
type State = typeof initialState

/**
 * Home Component is the home screen for the ClubHub application.
 */
class HomeComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		this.setState({loading: true})
		await Promise.all([this.fetchEvents(), this.fetchPosts(), this.fetchWeather()])
		this.setState({ loading: false })
	}

	// ----------------------------------------------------------------------------------
	// Network Requests
	// ----------------------------------------------------------------------------------

	/**
	 * Fetches the most recent 3 events.
	 */
	fetchEvents = async () => {
		const eventQueryParams: core.IShared.QueryOptions = { limit: 3, thin: true, timeField: 'start', start: new Date(), displayInFeed: true }
		const [err] = await to(this.props.fetchEvents(eventQueryParams) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to fetch Events. ${err.message}`, Constants.FlashType.DANGER)
			this.setState({ loading: false, error: true })
		}
	}

	/**
	 * Fetches the most recent 3 posts
	 */
	fetchPosts = async () => {
		const queryParams = { limit: 3, status: core.IShared.PublicationStatus.Published }
		const [err] = await to(this.props.fetchPosts(queryParams) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to fetch Posts. ${err.message}`, Constants.FlashType.DANGER)
			this.setState({ loading: false, error: true })
		}
	}

	fetchWeather = async () => {
		const { loggedInClub } = this.props
		if (!loggedInClub || !loggedInClub.lat || !loggedInClub.lon) {
			return
		}

		const weather = await WeatherService.getWeather(loggedInClub.lat, loggedInClub.lon)
		this.setState({weather})
	}

	// ----------------------------------------------------------------------------------
	// Content Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Handles clicks to view all events.
	 */
	handleViewEvents = () => {
		this.props.history.push(Constants.EVENTS_ROUTE)
	}

	/**
	 * Handles clicks on an event card.
	 */
	onEventCardClick = (event: core.Event.Model) => {
		const queryParams = { eventID: event._id }
		const location = {
			pathname: Constants.VIEW_EVENT_ROUTE,
			search: queryString.stringify(queryParams)
		}
		this.props.history.push(location)
	}

	/**
	 * Handles clicks to view all posts.
	 */
	handleViewPosts = () => {
		this.props.history.push(Constants.POSTS_ROUTE)
	}

	/**
	 * Handles clicks to a post card.
	 */
	onPostCardClick = (post: core.Post.Model) => {
		const queryParams = { postID: post._id }
		const location = {
			pathname: Constants.VIEW_POST_ROUTE,
			search: queryString.stringify(queryParams)
		}
		this.props.history.push(location)
	}

	// ----------------------------------------------------------------------------------
	// Alert Handlers
	// ----------------------------------------------------------------------------------

	handleEdit = async (editingKey: ModalStateKey) => {
		await setStateAsync(this, () => ({ [editingKey]: true }))
	}

	/**
	 * Handles canceling out of the edit status modal.
	 */
	handleCancel = async (cancelKey: ModalStateKey) => {
		await setStateAsync(this, () => ({ [cancelKey]: false }))
	}

	/**
	 * Handles submitting a status update.
	 */
	handleSubmitStatus = async (status: string) => {
		const { loggedInClub, updateClub, fireFlashMessage } = this.props

		this.setState({ loading: true })
		const formPayload = new FormData()

		const club = loggedInClub
		const clubPayload: core.Club.Model = {
			...club,
			website: {
				...club.website,
				status: status,
			}
		}
		formPayload.append('club', JSON.stringify(clubPayload))

		const [updateErr] = await to(updateClub(`${club._id}`, formPayload) as any)
		if (updateErr) {
			fireFlashMessage(`Problem trying to update status. ${updateErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true, editingStatus: false })
			return
		}
		fireFlashMessage('Successfully updated club status!', Constants.FlashType.SUCCESS)
		this.setState({ loading: false, editingStatus: false })
	}

	/**
	 * Handles submitting a cover image.
	 */
	handleSubmitCoverImage = async (image: core.SubModels.Image.Model | File[]) => {
		const { loggedInClub, updateClub, fireFlashMessage } = this.props

		// An existing image was provided -- we can bail, as there is no update to be made
		if (!Array.isArray(image)) {
			this.setState({ loading: false, editingCoverImage: false })
			return
		}

		this.setState({ loading: true })
		const formPayload = new FormData()

		// Attach new image to the formPayload
		// tslint:disable-next-line
		for (let i = 0; i < image.length; i++) {
			const formImage: File = image[i]
			formPayload.append('coverImage', formImage)
			break
		}

		const club = loggedInClub
		formPayload.append('club', JSON.stringify(club))

		const [updateErr] = await to(updateClub(`${club._id}`, formPayload) as any)
		if (updateErr) {
			fireFlashMessage(`Problem trying to update cover image. ${updateErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true, editingCoverImage: false })
			return
		}
		fireFlashMessage('Successfully updated cover image!', Constants.FlashType.SUCCESS)
		this.setState({ loading: false, editingCoverImage: false })
	}

	// ----------------------------------------------------------------------------------
	// Quick Link Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Handles clicks on a quick link.
	 */
	handleQuickLinkClick = (quickLink: core.Club.QuickLink) => {
		let link = quickLink.link
		if (!link) {
			this.props.fireFlashMessage(`Our apologies. Thats not hooked up yet!`, Constants.FlashType.WARNING)
			return
		}

		// If the user isn't an admin, send them to the reservations view for customers.
		if (link === '/reservations/services' && this.props.isCustomerView) {
			link = '/reservations/service/viewing'
		}
		window.location.href = link
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	/**
	 * Builds an alert row.
	 */
	buildAlertRow = () => {
		const { loggedInClub, isCustomerView } = this.props

		const status = oc(loggedInClub).website.status('')
		const onClick = () => this.handleEdit(EditingStatus)
		const editButton = isCustomerView ? null : (<Feather.Edit className='edit-icon' onClick={onClick} size={20} />)
		return (
			<RS.Alert className='d-flex justify-content-between' color='primary'>
				<div className='alert-content d-flex'>
					<Feather.Bell className='alert-icon' size={20} />
					<p>{status}</p>
				</div>
				{editButton}
			</RS.Alert >
		)
	}

	/**
	 * Builds a cover image card.
	 */
	buildCoverImageCard = () => {
		const { loggedInClub, isCustomerView, userState } = this.props

		const onClick = () => this.handleEdit(EditingCoverImage)
		const name = oc(userState).loggedInUser.firstName('')
		const club = oc(loggedInClub)()
		return (
			<RS.Row>
				<RS.Col>
					<CoverImageCard
						customerView={isCustomerView}
						weather={this.state.weather}
						name={name}
						club={club}
						onEditClick={onClick}
					/>
				</RS.Col>
			</RS.Row>
		)
	}

	/**
	 * Builds our quick link component.
	 */
	buildQuickLinksCard = () => {
		const { loggedInClub } = this.props
		return (
			<TileCardContainer club={loggedInClub} onClick={this.handleQuickLinkClick} />
		)
	}

	/**
	 * Builds our post row.
	 */
	buildPostRow = () => {
		const { loggedInClub, postState } = this.props

		const posts = oc(postState).posts([])
		const cards = posts.map((post: core.Post.Model, index: number) => {
			return (
				<RS.Col key={`${post.title}_${index}`} sm={4}>
					<HomeCard
						key={post.title}
						post={post}
						image={post.image}
						club={loggedInClub}
						onClickPost={this.onPostCardClick}
					/>
				</RS.Col>
			)
		})
		const title = 'Club News & Announcements'
		const actionTitle = 'View All News'
		return this.buildRow(cards, title, actionTitle, ContentType.Posts, this.handleViewPosts)
	}

	/**
	 * Builds our events row.
	 */
	buildEventsRow = () => {
		const { loggedInClub, eventState } = this.props

		const events = oc(eventState).events([])
		const cards = events.map((event: core.Event.Model, index: number) => {
			const calendarID = oc(event).calendarIDs[0](event.calendarID)
			const calendar = this.props.calendarsByID[`${calendarID}`]
			return (
				<RS.Col key={`${event.name}_${index}`} sm={4}>
					<HomeCard
						key={event.name}
						event={event}
						calendar={calendar}
						image={oc(event).images[0]({})}
						club={loggedInClub}
						onClickEvent={this.onEventCardClick}
					/>
				</RS.Col>
			)
		})
		const title = 'Upcoming Events'
		const actionTitle = 'View All Events'
		return this.buildRow(cards, title, actionTitle, ContentType.Events, this.handleViewEvents)
	}

	/**
	 * Builds a row.
	 */
	buildRow = (cards: JSX.Element[], title: string, actionTitle: string, contentType: ContentType, handler: any) => {
		const emptyContent = (
			<RS.Col className='d-flex justify-content-between'>
				<div className='home-empty-content-container'>
					<EmptyContent type={contentType} status={core.IShared.PublicationStatus.Published} />
				</div>
			</RS.Col>
		)
		const rowContent = (cards.length > 0) ? cards : emptyContent
		return (
			<div>
				<RS.Row className='header-row'>
					<RS.Col className='d-flex justify-content-between'>
						<h3>{title.toUpperCase()}</h3>
						<RS.Button className='no-padding d-flex align-items-center' color='link' onClick={handler}>
							{actionTitle}
							<Feather.ChevronRight size={18} />
						</RS.Button>
					</RS.Col>
				</RS.Row>
				<RS.Row className='home-content-row'>
					{rowContent}
				</RS.Row>
			</div>
		)
	}

	/**
	 * Builds our status modal.
	 */
	buildEditStatusModal = () => {
		const { loggedInClub } = this.props
		const { editingStatus } = this.state

		if (!editingStatus) { return null }
		const cancel = () => this.handleCancel(EditingStatus)
		return (
			<EditStatusModal
				club={loggedInClub}
				submitButtonHandler={this.handleSubmitStatus}
				cancelButtonHandler={cancel}
			/>
		)
	}

	buildEditCoverImageModal = () => {
		const { loggedInClub } = this.props
		const { editingCoverImage } = this.state

		if (!editingCoverImage) { return null }
		const cancel = () => this.handleCancel(EditingCoverImage)
		return (
			<EditCoverImageModal
				submitButtonHandler={this.handleSubmitCoverImage}
				cancelButtonHandler={cancel}
				club={loggedInClub}
			/>
		)
	}

	render() {
		const { loggedInClub } = this.props
		const { loading, error } = this.state

		if (error) { return <ErrorComponent club={loggedInClub} isAdmin={this.props.userState.isAdmin} /> }
		if (loading) { return <DimmedLoader component={null} isLoading={true} /> }

		return (
			<RS.Container className={'home-container'}>
				<RS.Row>
					<RS.Col xs={12}>
						{this.buildCoverImageCard()}
					</RS.Col>
				</RS.Row>
				<RS.Row className={'justify-content-center'}>
					<RS.Col md={10}>
						{this.buildQuickLinksCard()}
					</RS.Col>
				</RS.Row>
				<RS.Row className={'justify-content-center'}>
					<RS.Col xs={12} lg={10}>
						{this.buildEventsRow()}
						{this.buildPostRow()}
					</RS.Col>
				</RS.Row>
				{this.buildEditStatusModal()}
				{this.buildEditCoverImageModal()}
			</RS.Container>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	userState: state.user,
	eventState: state.event,
	postState: state.post,
	loggedInClub: state.club.loggedInClub,
	isCustomerView: inCustomerViewSelector(state),
	calendarsByID: calendarsByIDSelector(state)
})

const mapDispatchToProps = {
	...AppActions,
	...UserActions,
	...EventActions,
	...PostActions,
	...ClubActions,
	...AlertActions,
}

const enhance = compose<React.ComponentType<{}>>(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(HomeComponent)
