// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as RS from 'reactstrap'
import * as Sentry from '@sentry/browser'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { RouteComponentProps } from 'react-router'
import { oc } from 'ts-optchain'
import { to } from 'await-to-js'
import { isNullOrUndefined } from 'util'

// Actions
import { AlertActions, CustomerActions, EventActions, LotteryActions } from '../../../actions/index'

// State
import { RootReducerState } from '../../../reducers'
import { inCustomerViewSelector, bookableMembers } from '../../../reducers/user'
import { eventsByIDSelector } from '../../../reducers/event'

// Components
import DimmedLoader from '../../Shared/DimmedLoader'
import { ReservationType } from '../../Shared/ReservationList'
import { BaseReservationComponent } from '../BaseReservation'
import { string } from 'prop-types'

// Constants
import * as Constants from '../../../constants'
import { getMostSpecificSetting } from '../../../helpers/reservation'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	time: null as Date,
	availableEvent: null as core.Event.AvailableEvent,
	event: null as core.Event.Model | null,
	reservation: null as core.Event.Reservation | null,
	calendar: null as core.Calendar.Model | null,
	group: null as core.Calendar.Group | null,
	existingRSVP: null as core.Event.Reservation | null,
	showTimeSelectionModal: false,
	error: false,
	loading: false
}

type Props = RouteComponentProps & ConnectedState & ConnectedActions
type State = typeof initialState

class GolfReservationComponent extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props)

		this.state = { ...initialState }
	}

	async componentDidMount() {
		await this.setEventWithQueryParameters()
	}

	setEventWithQueryParameters = async () => {
		const parsedQuery = queryString.parse(this.props.location.search)
		const time = parsedQuery.time

		// Grab the golf calendar group.
		const groups = oc(this).props.calendarState.groups([])
		const group = groups.find((g: core.Calendar.Group) => g.type === core.Calendar.GroupType.Golf)

		// Grab the cal for the golf group.
		const calendars = oc(this).props.calendarState.calendars([])
		const calendar = calendars.find((c: core.Calendar.Model) => `${c.groupID}` === `${group._id}`)

		// Ensure we get bookable events for the day of the time query param.
		// We don't have a way to fetch a single bookable event for now...which is fine.
		await this.fetchBookableEventsForTime(time, calendar)

		// Get the bookable event based on the time query param.
		const bookableEvents = oc(this).props.eventState.bookableEvents([])
		const eventInfo = oc(bookableEvents)[0].eventInfo([])
		const availableEvent = eventInfo.find((e: core.Event.AvailableEvent) => e.time === time)
		const event = availableEvent.existingEvent

		// Check for a reservation in two places.
		let reservation
		const reservations = oc(event).reservations([])
		if (parsedQuery.reservationID) {
			reservation = reservations.find((r: core.Event.Reservation) => `${r._id}` === parsedQuery.reservationID)
		} else {
			reservation = reservations.find((r: core.Event.Reservation) => r.owner === this.props.userState.loggedInUser._id)
		}
		this.setState({ time, availableEvent, event, calendar, group, reservation, loading: false })
	}

	/**
	 * Ensures we have our bookable event in state.
	 */
	fetchBookableEventsForTime = async (time: string, cal: core.Calendar.Model) => {
		if (this.props.isCustomerView) { return }
		const [err] = await to(this.props.fetchBookableEvents(time, [`${cal._id}`], true) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to fetch Events. ${err.message}`, Constants.FlashType.DANGER)
			this.setState({ loading: false, error: true })
		}
	}

	// ----------------------------------------------------------------------------------
	// Network Request
	// ----------------------------------------------------------------------------------

	/**
	 * Creates a new lottery request.
	 */
	createLottery = async (res: core.Event.Reservation) => {
		const { loggedInClub } = this.props
		const userID = this.props.userState.loggedInUser._id
		const clubID = loggedInClub._id
		const calendarID = `${this.state.calendar._id}`

		const lottery: core.Lottery.Model = { userID, clubID }
		const [err] = await to(this.props.createLottery(lottery, res, calendarID, this.state.time) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to submit lottery request.${err.message}`, Constants.FlashType.DANGER)
			throw err
		}

		const [fetchErr] = await to(this.props.fetchEvents({ limit: 0, offset: 0 }) as any)
		if (fetchErr) {
			this.props.fireFlashMessage(`Failed to fetch updated lottery requests. Please check your connection.`, Constants.FlashType.DANGER)
			return
		}
		this.props.fireFlashMessage(`Successfully created Lottery Request.`, Constants.FlashType.SUCCESS)
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildContent = () => {
		const maxGuests = this.maxGuestForReservationSetting()
		return (
			<BaseReservationComponent
				{...this.props}
				event={this.state.event}
				reservation={this.state.reservation}
				calendar={this.state.calendar}
				calendarGroup={this.state.group}
				eventName={this.state.group.name}
				backName={'Tee Sheet'}
				handleCreateLottery={this.createLottery}
				type={ReservationType.GOLF}
				cardTitle={'Confirm Tee Time'}
				submitButtonName={'Confirm'}
				guestCount={4}
				maxGuests={maxGuests}
				bookingDate={this.state.time}
			/>
		)
	}

	render() {
		if (this.state.loading || !this.state.availableEvent) { return <DimmedLoader component={null} isLoading={true} /> }
		return (
			<RS.Col className='event-reservation-container d-flex justify-content-center'>
				{this.buildContent()}
			</RS.Col>
		)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	addSentryBreadcrumb = () => {
		const location = this.props.location
		const user = this.props.userState.loggedInUser
		const data = { location, user }
		Sentry.addBreadcrumb({ data })
	}

	/**
 	* Returns the max guests allowed by the calendar's reservation setting.
 	* @returns number | null
 	*/
	maxGuestForReservationSetting = (): number | null => {
		const reservationSettings = oc(this).state.calendar.reservationSettings([])
		const specificSetting = getMostSpecificSetting(reservationSettings, this.state.time)
		if (!isNullOrUndefined(specificSetting)) {
			return (this.props.isCustomerView) ?
				specificSetting.maxGuestsMember :
				specificSetting.maxGuestsAdmin
		}
		return null
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	bookableEvents: oc(state).event.bookableEvents([]),
	calendars: oc(state).calendar.calendars([]),
	loggedInClub: state.club.loggedInClub,
	userState: state.user,
	eventState: state.event,
	calendarState: state.calendar,
	isCustomerView: inCustomerViewSelector(state),
	bookableMembers: bookableMembers(state),
	eventsByID: eventsByIDSelector(state),
})

const mapDispatchToProps = {
	...AlertActions,
	...CustomerActions,
	...EventActions,
	...LotteryActions
}

const enhance = compose<React.ComponentType>(
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(GolfReservationComponent)
