// External Dependencies
import * as React from 'react'
import { FormikProps, FormikActions } from 'formik'
import { oc } from 'ts-optchain'

// Internal Dependencies

// Components
import ModalComponent from '../../Modal'

// Form
import { FormikComponent, BuildWrappedForm } from '../index'
import { FormInput } from '../../Form'

interface ComponentProps<T, S> {
	isOpen?: boolean
	enableReinitialize?: boolean
	className?: string
	modalTitle: string
	formSpec: FormInput[]
	formResource?: T
	primaryMessage?: string
	submitting?: boolean
	onChange?: (field: string, value: any) => any
	onSelect?: (field: string, value: any) => any
	submitButtonHandler: (values: S, actions: FormikActions<S>) => any
	submitButtonName?: string
	secondaryButtonHandler?: (values: S, actions: FormikActions<S>) => any
	secondaryButtonName?: string
	dangerSecondary?: boolean
	cancelButtonHandler: () => any
	nestedModal?: any
}

type Props<T, S> = ComponentProps<T, S>

export default class FormModal<T, S> extends React.Component<Props<T, S>> {
	constructor(props: Props<T, S>) {
		super(props)
	}

	// ----------------------------------------------------------------------------------
	// Component Builders
	// ----------------------------------------------------------------------------------

	buildModal = (formikProps: FormikProps<S>) => {
		const wrappedFormProps = { inputs: this.props.formSpec, onChange: this.props.onChange, onSelect: this.props.onSelect }
		const modalInputs = BuildWrappedForm(wrappedFormProps, formikProps)
		const submitButtonName = oc(this).props.submitButtonName('Save')
		return (
			<ModalComponent
				isOpen={oc(this).props.isOpen(true)}
				className={this.props.className}
				modalTitle={this.props.modalTitle}
				primaryMessage={this.props.primaryMessage}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.props.cancelButtonHandler}
				submitting={this.props.submitting}
				submitButtonName={submitButtonName}
				submitButtonHandler={formikProps.submitForm}
				secondaryButtonName={this.props.secondaryButtonName}
				secondaryButtonHandler={this.props.secondaryButtonHandler}
				dangerSecondary={this.props.dangerSecondary}
				buildInputs={() => modalInputs}
				nestedModal={this.props.nestedModal}
			/>
		)
	}

	render() {
		return (
			<FormikComponent
				inputs={this.props.formSpec}
				enableReinitialize={oc(this).props.enableReinitialize(true)}
				formResource={this.props.formResource}
				onSubmit={this.props.submitButtonHandler}
				render={this.buildModal}
			/>
		)
	}
}
