// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import { isNullOrUndefined } from 'util'
import DimmedLoader from '../../../../components/Shared/DimmedLoader'

// Internal Dependencies
import Card from '../Card'
import * as Feather from 'react-feather'

export interface InfoCardData {
	title: string | JSX.Element
	bodyClass: string
	content: InfoCardItem[][]
	editHandler?: any
}

interface InfoCardItem {
	title: string
	value: string | JSX.Element
	size?: number
	isHeader?: boolean
	hideOnEmpty?: boolean
}

interface ComponentProps {
	cardData: InfoCardData
}

type Props = ComponentProps & React.HTMLProps<any>
type State = any

class InfoCard extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = { loading: isNullOrUndefined(props.cardData) }
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------
	handleEditClick = (event: React.MouseEvent, index: number) => {
		this.props.cardData.editHandler(index)
	}

	buildEditButton = (index: number) => {
		const data = this.props.cardData
		return data.editHandler ? (
			<RS.Col sm={1}>
				<RS.Button color='link' onClick={(e: any) => this.handleEditClick(e, index)}>
					<Feather.Edit size={16} />
				</RS.Button>
			</RS.Col>
		) : null
	}

	buildInfoCard = () => {
		const data = this.props.cardData

		const cols = data.editHandler ? 11 : 12
		const cardRows = data.content.map((rowItems, idx) => {
			const rowContent = rowItems.map(this.buildInfoCardItem).filter((test) => !isNullOrUndefined(test))
			return (
				<RS.Row className='info-card-row no-gutters' key={`card-row-${idx}`}>
					<RS.Col sm={cols}>
						<RS.Row>
							{rowContent}
						</RS.Row>
					</RS.Col>
					{this.buildEditButton(idx)}
				</RS.Row>
			)
		})
		const content = (
			<div>
				{cardRows}
			</div>
		)

		return (
			<Card
				title={data.title}
				bodyClass={data.bodyClass}
				content={content}
				editHandler={this.props.cardData.editHandler}
			/>
		)
	}

	buildInfoCardItem = (item: InfoCardItem, index: number) => {
		if (!item.value && item.hideOnEmpty) { return null }

		const value = (item.value) ? item.value : '-'
		const content = (item.isHeader) ? (
			<div className='info-header'>
				{item.title}
			</div>
		) : (
				<div className='info-field'>
					<div className='info-label text-muted text-uppercase'>{item.title}</div>
					<div className='info-detail'>{value}</div>
				</div>
			)

		return (
			<RS.Col md={item.size || 12} sm={6} key={`info-card_${index}`}>
				{content}
			</RS.Col>
		)
	}

	render() {
		if (this.state.loading)  { return <DimmedLoader component={null} isLoading={true} /> }
		return this.buildInfoCard()
	}
}

export default InfoCard
