// External Dependencies
import * as core from 'club-hub-core'
import * as Feather from 'react-feather'

// Internal Dependencies
import { TableColumn } from '../Shared/Table'
import { TableColumnType } from '../Shared/Table/Primitives'

export interface VehicleTableRow extends core.SubModels.CarMeta.Vehicle {
	owner: core.User.Model,
}

export const VehicleTableColumns: TableColumn[] = [
	{
		title: '',
		property: '',
		type: TableColumnType.CHECKBOX,
		action: '',
	},
	{
		title: 'Make',
		property: 'make',
		type: TableColumnType.TEXT,
	},
	{
		title: 'Model',
		property: 'model',
		type: TableColumnType.TEXT,
	},
	{
		title: 'Year',
		property: 'year',
		type: TableColumnType.TEXT,
	},
	{
		title: 'Color',
		property: 'color',
		type: TableColumnType.TEXT,
	},
	{
		title: 'Owner',
		property: 'owner',
		type: TableColumnType.AVATAR_AND_NAME,
		action: 'Owner',
		hideOnMobile: true,
	},
	{
		title: '',
		property: '',
		type: TableColumnType.DROPDOWN,
		class: 'table-dropdown-menu',
		dropdownItems: [
			{
				action: 'editVehicle',
				valueProperty: '_id',
				displayText: 'Edit Vehicle',
				icon: Feather.Edit,
			},
			{
				action: 'deleteVehicle',
				valueProperty: '_id',
				displayText: 'Delete Vehicle',
				icon: Feather.Trash,
			}
		]
	}
]
