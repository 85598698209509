// External Dependencies
import * as React from 'react'

// Internal Dependencies

interface ComponentProps {
	content: JSX.Element | JSX.Element[]
	hideHeader?: boolean
	title?: JSX.Element | string
	cardClass?: string
	footer?: JSX.Element
	headerClass?: string
	bodyClass?: string
	editHandler?: () => any
	onClick?: () => any
}

type Props = ComponentProps
type State = any

class Card extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildCard = () => {
		const title = this.props.title
		const content = this.props.content
		const header = (this.props.hideHeader) ? null : (
			<div className={`d-flex justify-content-between card-header ${this.props.headerClass || ''}`}>
				{title || null}
			</div>
		)

		const footer = (!this.props.footer) ? null : (
			<div className={`d-flex card-footer`}>
				{this.props.footer}
			</div>
		)

		return (
			<div className={`card ${this.props.cardClass || ''}`} onClick={this.props.onClick}>
				{header}
				<div className={`${this.props.bodyClass || ''}`}>
					{content}
				</div>
				{footer}
			</div>
		)
	}

	render() {
		if (!this.props.content) { return null }
		return this.buildCard()
	}
}

export default Card
