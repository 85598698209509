// External Dependencies
import * as React from 'react'
import { setStateAsync } from '../../../helpers/promise'

export interface LoadedImage {
	src: string,
	isCached: boolean
}

export interface SrcSetData {
	srcSet: string,
	sizes: string
}

interface ComponentProps {
	children: (placeholder: LoadedImage, fullImage: LoadedImage, initialLoading: boolean, loading: boolean, srcSetData: SrcSetData) => any
	onError?: (errorEvent: Event) => void,
	placeholder: string,
	src: string,
	srcSetData?: SrcSetData
}

const initialState = {
	placeholderImage: null as LoadedImage | null,
	image: null as LoadedImage | null,
	initialLoading: true,
	loading: false,
	srcSetData: { srcSet: '', sizes: '' } as any as SrcSetData
}

type Props = ComponentProps
type State = typeof initialState

export default class ProgressiveImage extends React.Component<Props, State> {
	loadedPlaceholderImage: HTMLImageElement
	loadedFullImage: HTMLImageElement
	private isMounted: boolean
	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	componentDidMount() {
		this.isMounted = true
		const src = this.props.src
		const srcSetData = this.props.srcSetData
		this.loadPlaceholder(this.props.placeholder, srcSetData)
		this.loadFullImage(src, srcSetData)
	}

	async componentDidUpdate(prevProps: Props) {
		const src = this.props.src
		const placeholder = this.props.placeholder
		const srcSetData = this.props.srcSetData

		// We only invalidate the current image if the src has changed.
		if (src !== prevProps.src) {
			await setStateAsync(this, { initialLoading: true })
			this.loadPlaceholder(placeholder, srcSetData)
			this.loadFullImage(src, srcSetData)
		}
	}

	componentWillUnmount() {
		this.isMounted = false
		if (this.loadedPlaceholderImage) {
			this.loadedPlaceholderImage.onload = null
			this.loadedPlaceholderImage.onerror = null
		}
		if (this.loadedFullImage) {
			this.loadedFullImage.onload = null
			this.loadedFullImage.onerror = null
		}
	}

	loadPlaceholder = (src: string, srcSetData?: SrcSetData) => {
		if (this.loadedPlaceholderImage) {
			this.loadedPlaceholderImage.onload = null
			this.loadedPlaceholderImage.onerror = null
		}

		const image = new Image()
		this.loadedPlaceholderImage = image
		this.loadImage(image, src, srcSetData).then((loadedImage: LoadedImage) => {
			if (this.isMounted) {
				return setStateAsync(this, { placeholderImage: loadedImage, initialLoading: false })
			}
		}).catch((err) => {
			if (this.props.onError) {
				return this.props.onError
			}
		})
	}

	loadFullImage = (src: string, srcSetData?: SrcSetData) => {
		// If there is already an image we nullify the onload
		// and onerror props so it does not incorrectly set state
		// when it resolves
		if (this.loadedFullImage) {
			this.loadedFullImage.onload = null
			this.loadedFullImage.onerror = null
		}

		const image = new Image()
		this.loadedFullImage = image
		this.loadImage(image, src, srcSetData).then((loadedImage: LoadedImage) => {
			if (this.isMounted) {
				return setStateAsync(this, { image: loadedImage, loading: false })
			}
		}).catch(this.props.onError)
	}

	loadImage = (image: HTMLImageElement, src: string, srcSetData?: SrcSetData): Promise<LoadedImage> => {
		return new Promise((resolve, reject) => {
			image.src = src
			if (image.complete) {
				return resolve({ src, isCached: true })
			}
			image.onload = () => resolve({ src, isCached: false })
			image.onerror = (err) => reject(err)
			if (srcSetData) {
				image.srcset = srcSetData.srcSet
				image.sizes = srcSetData.sizes
			}
		})
	}

	render() {
		const { placeholderImage, image, initialLoading, loading, srcSetData } = this.state
		const { children } = this.props

		if (!children || typeof children !== 'function') {
			throw new Error(`ProgressiveImage requires a function as its only child`)
		}

		return children(placeholderImage, image, initialLoading, loading, srcSetData)
	}
}
