// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { FormikProps } from 'formik'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies

// Actions
import { UserActions, AlertActions } from '../../../actions/index'

// State
import { RootReducerState } from '../../../reducers'
import { inCustomerViewSelector, usersByIDSelector } from '../../../reducers/user'

// Form
import GenericFormComponent from '../../Shared/Formik/GenericForm'
import { BuildWrappedForm } from '../../Shared/Formik'
import { InputSelectionItem, FormInput } from '../../Shared/Form'
import { AdminUserFormInputs, UserFormState, MemberUserFormInputs } from './form'
import * as DriversClub from './clubs/driversclub'

// Components
import ModalComponent from '../../Shared/Modal'
import TypeCreationModal from '../../Shared/TypeCreationModal'
import DimmedLoader from '../../Shared/DimmedLoader'

// Helpers
import * as Constants from '../../../constants'
import { setStateAsync } from '../../../helpers/promise'
import { formatPhoneNumber } from '../../../helpers/formatting'
import { getMemberPrimaryPhoneNumber, groupsForUser } from '../../../helpers/user'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	error: false,
	loading: true,
	userToUpdate: null as core.User.Model | null,
	creatingType: false,
	showDeletionModal: false,
}

type Props = RouteComponentProps & ConnectedActions & ConnectedState
type State = typeof initialState

class UserForm extends React.Component<Props, State> {
	private club: core.Club.Model

	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
		this.club = props.loggedInClub
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		await this.setUserForFormWithQueryParams()
		await setStateAsync(this, { loading: false })
	}

	/**
	 * Gets the User, that is being updated, from state using its ID that we get
	 * from the query parameters in the URL.
	 *
	 * The User will have its information pre-filled into the form.
	 */
	setUserForFormWithQueryParams = async () => {
		// Parse the query string of the URL into an object
		const parsedQuery = queryString.parse(this.props.location.search)
		const userID = parsedQuery.userID
		if (userID) {
			let userToEdit = this.props.usersByID[`${userID}`]
			if (!userToEdit) {
				await this.props.getUser(userID) // Current user gets updated in state.
				userToEdit = this.props.userState.currentUser
			}
			await setStateAsync(this, { userToUpdate: userToEdit })
		}
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Returns the user to the Users view when they cancel the form
	 */
	handleCancel = () => {
		this.props.history.goBack()
	}

	handleFormChange = async (field: string, value: string | InputSelectionItem) => {
		// Check to see if the User is trying to create a new Membership Type
		if (field === 'membershipType' && (value as InputSelectionItem).value === 'addType') {
			await setStateAsync(this, { creatingType: true })
		}
	}

	/**
	 * Determines which handler to call (Create or Update) when the user submits the form
	 */
	handleSave = async (form: UserFormState): Promise<void> => {
		switch (this.props.location.pathname) {
			case Constants.CREATE_USER_ROUTE:
				return this.handleCreate(form)
			case Constants.UPDATE_USER_ROUTE:
				return this.handleUpdate(form)
			default:
				break
		}
	}

	/**
	 * Will create a new User on the DB with the information in the form.
	 * Users will only be created if the validation conditions for the form are met.
	 */
	handleCreate = async (form: UserFormState): Promise<void> => {
		this.setState({ loading: true })

		const payload = this.buildUserPayload(form)
		const [err] = await to(this.props.createUser(payload) as any)
		if (err) {
			this.props.fireFlashMessage(`Problem creating User. ${err.message}`, Constants.FlashType.DANGER)
			this.setState({ loading: false })
			return
		}
		this.props.fireFlashMessage('User successfully created.', Constants.FlashType.SUCCESS)
		this.setState({ loading: false })
		this.props.history.push(Constants.USER_ROUTE)
	}

	/**
	 * Will update an existing User on the DB with the information in the form.
	 * Users will only be updated if the validation conditions for the form are met.
	 */
	handleUpdate = async (form: UserFormState): Promise<void> => {
		if (!this.state.userToUpdate) { return }
		const userID = `${this.state.userToUpdate._id}`

		await setStateAsync(this, { loading: true })
		const payload = this.buildUserPayload(form)
		const [err] = await to(this.props.updateUser(userID, payload, this.props.userState.isAdmin) as any)
		if (err) {
			this.props.fireFlashMessage(`Problem updating User. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}

		this.props.fireFlashMessage('User successfully updated.', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, error: false })
		this.props.history.push(Constants.USER_ROUTE)
	}

	/**
	 * When attempting to delete a User, display the
	 * User deletion confirmation modal
	 */
	handleDelete = async () => {
		await setStateAsync(this, { showDeletionModal: true })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Type Creation Modal
	// ----------------------------------------------------------------------------------

	handleTypeCreationModalClose = async () => {
		await setStateAsync(this, { creatingType: false })
	}

	handleTypeCreationModalSave = async (formikProps: FormikProps<UserFormState>, newType?: core.Club.ResourceType) => {
		const newTypeForForm: InputSelectionItem = (!isNullOrUndefined(newType)) ?
			({ label: newType.title, value: `${newType._id}` }) : null

		// Update the form state to use the new Type
		formikProps.setFieldValue('membershipType', newTypeForForm)
		await this.handleTypeCreationModalClose()
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Deletion Modal
	// ----------------------------------------------------------------------------------

	/**
	 * Make the API call to delete the User, when the user
	 * confirms the deletion via the modal
	 */
	executeUserDeletion = async (): Promise<void> => {
		if (!this.state.userToUpdate) { return }

		await setStateAsync(this, { loading: true })
		const [deleteErr] = await to(this.props.deleteUser(`${this.state.userToUpdate._id}`) as any)
		if (deleteErr) {
			this.props.fireFlashMessage(`Problem deleting User. ${deleteErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}

		this.props.fireFlashMessage('User successfully deleted.', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, error: false })
		this.props.history.push(Constants.USER_ROUTE)
	}

	/**
	 * Hide the deletion modal when the user cancels it
	 */
	closeDeleteUserModal = async () => {
		await setStateAsync(this, { showDeletionModal: false })
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	buildUserPayload = (form: UserFormState): FormData => {
		const formPayload = new FormData()

		// Display Settings
		const publicProfile = oc(form).publicProfile.value(null) === 'true'
		const publicContact = oc(form).publicContact.value(null) === 'true'
		const canBookReservations = oc(form).canBookReservations.value(null) === 'true'

		const userAddresses: core.SubModels.Location.Model[] = [{
			address1: oc(form).address(),
			city: oc(form).city(),
			state: oc(form).state.value(),
			zip: oc(form).zip(),
			primary: true,
		}]

		const groups = oc(form).groups([])
		const userGroups = groups.map((groupInput) => groupInput.value)
		const primaryAccountHolder = oc(form).primaryAccountHolder.value() === undefined || oc(form).primaryAccountHolder.value() !== 'false'

		// Create the User payload and add it to the FormData
		const userPayload: core.User.Model = {
			firstName: form.firstName.trim(),
			middleName: form.middleName.trim(),
			lastName: form.lastName.trim(),
			birthday: form.birthday,
			maritalStatus: oc(form).maritalStatus.value() as core.User.MaritalStatus,
			email: form.email.trim(),
			phoneNumbers: [{ name: 'Cell', number: formatPhoneNumber(form.phone), primary: true }],
			addresses: userAddresses,
			joined: form.joined,
			memberStatus: oc(form).memberStatus.value() as core.User.MemberStatus,
			membershipType: oc(form).membershipType.value(null) as any,
			memberNumber: { number: oc(form).number(), suffix: oc(form).suffix() },
			displaySettings: {
				publicProfile: publicProfile,
				publicContact: publicContact,
				canBookReservations: canBookReservations,
			},
			groups: userGroups,
			primaryAccountHolder: primaryAccountHolder,
			admin: form.admin ? oc(form).admin.value() === 'true' : undefined
		}
		if (this.club.name === core.Constants.Clubs.DRIVERS_CLUB && !this.props.isCustomerView) {
			const existingMeta = oc(this).state.userToUpdate.meta.car(null)
			const carMeta: core.SubModels.CarMeta.Model = {
				...existingMeta,
				stallNumbers: form.stallNumbers,
				agentAccessCode: form.agentAccessCode
			}
			userPayload.meta = { car: carMeta }
		}

		if (form.jobTitle) {
			userPayload.jobTitle = form.jobTitle
		}

		formPayload.append('user', JSON.stringify(userPayload))

		// Check for existing image
		if (Array.isArray(form.image)) {
			// tslint:disable-next-line
			for (let i = 0; i < form.image.length; i++) {
				const formImage: File | core.SubModels.Image.Model = form.image[i]
				if ((formImage as core.SubModels.Image.Model)._id) {
					userPayload.image = formImage as core.SubModels.Image.Model
					break
				}
				formPayload.append('image', formImage)
				break
			}
		} else if (!isNullOrUndefined(form.image)) {
			// If form.image is not an array, then we know that
			// it is an existing image coming from the resource
			userPayload.image = form.image
		}

		return formPayload
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildDeleteConfirmationModal = () => {
		if (!this.state.userToUpdate || !this.state.showDeletionModal) { return null }
		return (
			<ModalComponent
				modalTitle={'Delete User'}
				primaryMessage={'Are you sure you want to delete this User?'}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.closeDeleteUserModal}
				submitButtonName={'Confirm'}
				submitButtonHandler={this.executeUserDeletion}
			/>
		)
	}

	buildFormInputs = () => {
		const { loggedInClub, isCustomerView } = this.props

		if (isCustomerView) {
			return this.memberInputsForComponent(loggedInClub)
		}

		const membershipTypes = oc(loggedInClub).resources.members.types([])
		const membershipTypesForForm: any[] = [
			{ options: membershipTypes.map((t) => ({ label: t.title, value: t._id })) },
			{ label: '', options: [{ label: 'Add New...', value: 'addType' }] },
		]
		const groups = oc(loggedInClub).userGroups([])
		const groupInputs: InputSelectionItem[] = groups.map((g) => {
			return { label: g.name, value: `${g._id}`, isFixed: !isNullOrUndefined(g.queryFilter) }
		})
		return this.adminInputsForComponent(membershipTypesForForm, groupInputs, loggedInClub)
	}

	buildForm = () => {
		const userToUpdate = (this.state.userToUpdate) ? this.state.userToUpdate : undefined
		const formTitle = (this.state.userToUpdate) ? 'Update User' : 'New User'
		const submitButtonName = (this.state.userToUpdate) ? 'Update' : 'Save'

		let formResource: any
		if (userToUpdate) {
			const userType = (userToUpdate.admin) ? 'admin' : 'member'
			const carMeta = (this.club.name === core.Constants.Clubs.DRIVERS_CLUB) ? oc(userToUpdate).meta.car() : null
			const userAddress = oc(userToUpdate).addresses([]).find((address) => address.primary)
			const memberNumber = oc(userToUpdate).memberNumber.number()
			const suffix = oc(userToUpdate).memberNumber.suffix()
			const userGroups = groupsForUser(userToUpdate, this.club)
			const queryGroups = userGroups.filter((ug) => !isNullOrUndefined(ug.queryFilter))
			const nonQueryGroups = userGroups.filter((ug) => isNullOrUndefined(ug.queryFilter))
			const groupNames = [...queryGroups, ...nonQueryGroups].map((g) => `${g._id}`)

			formResource = {
				...userToUpdate,
				address: oc(userAddress).address1(),
				city: oc(userAddress).city(),
				state: oc(userAddress).state(),
				zip: oc(userAddress).zip(),
				phone: getMemberPrimaryPhoneNumber(userToUpdate),
				userType: userType,
				number: memberNumber,
				suffix: suffix,
				groups: groupNames,
				publicProfile: `${oc(userToUpdate).displaySettings.publicProfile(false)}`,
				publicContact: `${oc(userToUpdate).displaySettings.publicContact(false)}`,
				canBookReservations: `${oc(userToUpdate).displaySettings.canBookReservations(false)}`,
				...carMeta,
				primaryAccountHolder: `${oc(userToUpdate).primaryAccountHolder(true)}`,
				admin: `${oc(userToUpdate).admin(false)}`,
				image: oc(userToUpdate).image()
			}
		}

		return (
			<GenericFormComponent
				title={formTitle}
				inputs={this.buildFormInputs()}
				formResource={formResource}
				enableReinitialize={false}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.handleCancel}
				submitButtonName={submitButtonName}
				submitButtonHandler={this.handleSave}
				onChangeHandler={this.handleFormChange}
				render={(formikProps) => this.buildFormBody(this.buildFormInputs(), formikProps)}
			/>
		)
	}

	buildFormBody = (formInputs: FormInput[], formikProps: FormikProps<UserFormState>) => {
		return (
			<>
				{BuildWrappedForm({ inputs: formInputs, onChange: this.handleFormChange }, formikProps)}
				{this.buildTypeCreationModal(formikProps)}
			</>
		)
	}

	buildTypeCreationModal = (formikProps: FormikProps<UserFormState>) => {
		const { loggedInClub } = this.props
		const { creatingType } = this.state

		if (!creatingType) { return null }

		const existingTypesForModal = oc(loggedInClub).resources.members.types([])

		return (
			<TypeCreationModal
				typeName={'Membership'}
				resourceType={'members'}
				existingTypes={existingTypesForModal}
				onClose={async () => {
					formikProps.setFieldValue('membershipType', null)
					return this.handleTypeCreationModalClose()
				}}
				onSave={(newType: core.Club.ResourceType) => this.handleTypeCreationModalSave(formikProps, newType)}
			/>
		)
	}

	render() {
		if (this.state.loading) { return <DimmedLoader isLoading={true} component={null} /> }

		return (
			<div>
				<div className='row justify-content-center mt-4 mx-auto'>
					<div className='col col-md-10 col-xl-8 d-flex justify-content-center'>
						<DimmedLoader component={this.buildForm()} isLoading={this.state.loading} />
					</div>
				</div>
				{this.buildDeleteConfirmationModal()}
			</div>
		)
	}

	inputsForComponent = () => {
		const { loggedInClub, isCustomerView } = this.props

		let inputs: FormInput[]
		if (!isCustomerView) {
			inputs = this.adminInputsForComponent([], [], loggedInClub)
		} else {
			inputs = this.memberInputsForComponent(loggedInClub)
		}
		return inputs
	}

	adminInputsForComponent = (types: any[], groups: InputSelectionItem[], club: core.Club.Model) => {
		const { loggedInClub } = this.props
		switch (loggedInClub.name) {
			case core.Constants.Clubs.DRIVERS_CLUB:
				return DriversClub.AdminUserFormInputs(types, club)
			default:
				return AdminUserFormInputs(types, groups)
		}
	}

	memberInputsForComponent = (club: core.Club.Model) => {
		const { loggedInClub } = this.props
		switch (loggedInClub.name) {
			case core.Constants.Clubs.DRIVERS_CLUB:
				return DriversClub.MemberUserFormInputs(club)
			default:
				return MemberUserFormInputs(club, false)
		}
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	userState: state.user,
	loggedInClub: state.club.loggedInClub,
	isCustomerView: inCustomerViewSelector(state),
	usersByID: usersByIDSelector(state)
})

const mapDispatchToProps = {
	...UserActions,
	...AlertActions
}

export default connect(mapStateToProps, mapDispatchToProps)(UserForm)
