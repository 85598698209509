// External Dependencies
import * as core from 'club-hub-core'
import { oc } from 'ts-optchain'

// Internal Dependencies

// Interfaces
import { InfoCardData } from '../../Shared/Cards/InfoCard'

// Helpers
import { buildEmailLink } from '../../../helpers/formatting'
import { getMemberPrimaryPhoneNumber } from '../../../helpers/user'

export const ContactCard = (user: core.User.Model): InfoCardData => {
	return {
		title: 'Contact',
		bodyClass: 'card-body',
		content: [[
			{
				title: 'Email',
				value: buildEmailLink(user.email),
				size: 6,
			},
			{
				title: 'Phone Number',
				value: oc(getMemberPrimaryPhoneNumber(user))('--'),
				size: 6,
			},
		]]
	}
}
