// External Dependencies
import * as core from 'club-hub-core'
import { createSelector } from 'reselect'
import { indexBy } from 'underscore'
import { oc } from 'ts-optchain'

// Internal Dependencies
import { ActionTypes as UserActionTypes, TypeKeys as UserActionTypeKeys } from '../actions/user'
import { ActionTypes as ClubActionTypes, TypeKeys as ClubActionTypeKeys } from '../actions/club'
import { ActionTypes as GroupActionTypes, TypeKeys as GroupActionTypeKeys } from '../actions/group'
import { mergeUpdatedModelObject } from '../helpers/reducer'
import { RootReducerState } from './index'

export type ClubResourceName = 'events' | 'posts' | 'members' | 'messages' | 'services'
export interface ClubNavigation {
	admin: ClubNavigationSection[],
	customer: ClubNavigationSection[],
	mobile: ClubNavigationSection[],
}

export interface ClubNavigationSection {
	title: string,
	items: ClubNavigationItem[]
}

export interface ClubNavigationItem {
	title: string,
	fontAwesomeIcon?: string,
	featherIcon?: string,
	path: string,
}

export type ClubState = typeof defaultState
type ActionTypes = UserActionTypes | ClubActionTypes | GroupActionTypes

const defaultState = {
	loggedInClub: null as core.Club.Model,
	clubs: [] as core.Club.Model[],
	contentLinks: null as core.SubModels.ContentLink.ClubContentLinks
}

function clubReducer(state = defaultState, action: ActionTypes): ClubState {
	switch (action.type) {
		case ClubActionTypeKeys.FETCHED_CLUBS:
			return {
				...state,
				clubs: action.clubs,
			}

		case ClubActionTypeKeys.FETCHED_CLUB_CONTENT_LINKS:
			return {
				...state,
				contentLinks: action.links
			}
		case UserActionTypeKeys.AUTHENTICATED_USER:
		case ClubActionTypeKeys.FETCHED_CLUB:
		case ClubActionTypeKeys.UPDATED_CLUB:
		case ClubActionTypeKeys.CREATED_CLUB_LOCATION:
		case ClubActionTypeKeys.UPDATED_CLUB_LOCATION:
		case ClubActionTypeKeys.DELETED_CLUB_LOCATION:
		case ClubActionTypeKeys.CREATED_CLUB_TYPE:
		case ClubActionTypeKeys.UPDATED_CLUB_TYPE:
		case ClubActionTypeKeys.DELETED_CLUB_TYPE:
		case ClubActionTypeKeys.UPDATED_CLUB_TYPES:
		case ClubActionTypeKeys.UPDATED_CALENDAR_GROUP:
		case ClubActionTypeKeys.UPDATED_CLUB_SUB_DOCUMENT:
		case GroupActionTypeKeys.CREATED_USER_GROUP:
		case GroupActionTypeKeys.UPDATED_USER_GROUP:
		case GroupActionTypeKeys.DELETED_USER_GROUP:
			return {
				...state,
				loggedInClub: action.club,
				clubs: mergeUpdatedModelObject(action.club, state.clubs)
			}
		default:
			return state
	}
}

// Selectors
const loggedInClub = (state: RootReducerState) => state.club.loggedInClub

export const clubUserGroupsSelector = createSelector([loggedInClub], (club) => {
	return oc(club).userGroups([])
})

export const clubUserGroupsByIDSelector = createSelector([loggedInClub], (club) => {
	const userGroups = oc(club).userGroups([])
	return indexBy(userGroups, '_id')
})

export default clubReducer
