// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import * as core from 'club-hub-core'
import { oc } from 'ts-optchain'

// Internal Dependencies

// Components
import AsyncImage from '../../Shared/AsyncImage'

// Helpers
import { ImageSize } from '../../../helpers/image'

interface Props {
	club: core.Club.Model
	quickLink: core.Club.QuickLink
	onClick: (post: core.Post.Model) => any
}

export default class TileCard extends React.Component<Props, any> {
	constructor(props: Props) {
		super(props)
	}

	onClickHandler = () => {
		this.props.onClick(this.props.quickLink)
	}

	render() {
		const quickLink = this.props.quickLink
		const club = this.props.club
		const children = (
			<div className={'quick-link-mask d-flex justify-content-center align-items-center'}>
				<h3>{oc(quickLink).title('').toUpperCase()}</h3>
			</div>
		)
		return (
			<RS.Card className={'home-tile-card  d-flex justify-content-center align-items-center'} onClick={this.onClickHandler}>
				<AsyncImage
					className={'home-tile-image'}
					image={quickLink.image}
					club={club}
					size={ImageSize.Medium}
					children={children}
				/>
			</RS.Card>
		)
	}
}
