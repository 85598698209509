// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'

// Internal Dependencies
import { BaseInputPrimitiveProps } from '../index'

type Props = BaseInputPrimitiveProps

export const AlertInput: React.FunctionComponent<Props> = (props: Props) => {
	return (
		<RS.Alert color='primary'>{props.item.placeholder}</RS.Alert>
	)
}
