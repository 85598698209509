// External Dependencies
import * as Yup from 'yup'
import * as core from 'club-hub-core'
import { oc } from 'ts-optchain'
import * as Feather from 'react-feather'

// Internal Dependencies
import { FormInput, FormInputType, InputSelectionItem } from '../../Shared/Form'
import * as Constants from '../../../constants/index'

export interface BlockModalState {
	date: Date,
	start: InputSelectionItem,
	end: InputSelectionItem,
	title?: string,
	recurring?: InputSelectionItem,
	recurringEnd?: Date,
	count?: number,
	notes?: string,
	addToCalendar?: string
}

export const BlockModalInputs = (customLabel: string, timeSelectItems: InputSelectionItem[]): FormInput[] => {

	const recurringSelectItems: InputSelectionItem[] = [
		{ label: 'None', value: 'none' },
		{ label: 'Daily', value: `${core.Event.RecurringFrequency.DAILY}` },
		{ label: 'Weekly', value: `${core.Event.RecurringFrequency.WEEKLY}` },
		{ label: 'Monthly', value: `${core.Event.RecurringFrequency.MONTHLY}` },
		{ label: 'Annually', value: `${core.Event.RecurringFrequency.YEARLY}` },
		{ label: customLabel, value: 'custom' }
	]

	return [
		{
			title: 'Title',
			property: 'title',
			type: FormInputType.TEXT,
			size: 12,
			placeholder: 'Title...',
			defaultValue: '',
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Date',
			property: 'date',
			type: FormInputType.DATE,
			size: 12,
			placeholder: 'Date...',
			defaultValue: '',
			validation: Yup.date().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Start',
			property: 'start',
			type: FormInputType.SELECT,
			size: 6,
			placeholder: 'Start...',
			selectItems: timeSelectItems,
			icon: Feather.Clock,
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'End',
			property: 'end',
			type: FormInputType.SELECT,
			size: 6,
			placeholder: 'End...',
			selectItems: timeSelectItems,
			icon: Feather.Clock,
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Recurring',
			property: 'recurring',
			type: FormInputType.SELECT,
			size: 12,
			selectItems: recurringSelectItems,
			placeholder: 'Recurring...',
			defaultValue: '',
		},
		// TODO - HOOK THIS UP. LEAVING HERE ON PURPOSE FOR LATER.
		// {
		// 	title: 'Add to Calendar',
		// 	property: 'addToCalendar',
		// 	type: FormInputType.CHECKBOX,
		// 	size: 6,
		// 	defaultValue: false,
		// }
	]
}
