// External Dependencies
import * as React from 'react'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import to from 'await-to-js'
import * as core from 'club-hub-core'
import { oc } from 'ts-optchain'

// Internal Dependencies

// State
import { RootReducerState } from '../../reducers/index'

// Actions
import { AlertActions, CalendarActions } from '../../actions/index'

// Components
import PageHeaderComponent from '../Shared/PageHeader'
import { HeaderButton, ButtonType } from '../Shared/ButtonGroup'
import ErrorComponent from '../Shared/ErrorComponent'
import TableComponent from '../Shared/Table'
import ModalComponent from '../Shared/Modal'

// Table
import { ServiceProviderTableColumns, ServiceProviderTableItem } from './table'

// Helpers
import * as Constants from '../../constants'
import { setStateAsync } from '../../helpers/promise'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	serviceProviderToDelete: null as string | null,
	error: false,
	loading: false,
}

type Props = RouteComponentProps & ConnectedState & ConnectedActions
type State = typeof initialState

class ServiceProviderComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Dropdown Actions
	// ----------------------------------------------------------------------------------

	/**
	 * Determines which action to take, based on which dropdown item
	 * the user selected in the table's dropdown menu
	 */
	handleTableDropdownAction = (e: any) => {
		const [action, value] = e
		switch (action) {
			case 'editServiceProvider':
				return this.handleEditServiceProvider(value)
			case 'removeServiceProvider':
				return this.handleDeleteServiceProvider(value)
			default:
				break
		}
	}

	/**
	 * When editing a Service Provider, navigate to the Service Provider update
	 * form and pass the Service Provider's ID via the query parameters
	 */
	handleEditServiceProvider = (serviceProviderID: string) => {
		// Query parameters
		const queryParams = {
			serviceProviderID: serviceProviderID
		}

		const location = {
			pathname: Constants.UPDATE_SERVICE_PROVIDER_ROUTE,
			search: queryString.stringify(queryParams)
		}

		// Go to the route
		this.props.history.push(location)
	}

	/**
	 * When attempting to delete a Service Provider, display the
	 * Service Provider deletion confirmation modal
	 */
	handleDeleteServiceProvider = async (value: string) => {
		await setStateAsync(this, { serviceProviderToDelete: value })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Modal Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Make the API call to delete the Service Provider, when the
	 * user confirms the deletion via the modal
	 */
	executeServiceProviderDeletion = async () => {
		// Delete the Service Provider and fetch the Service Providers
		const serviceProviderID = this.state.serviceProviderToDelete
		const [deleteErr] = await to(this.props.deleteCalendar(serviceProviderID) as any)
		if (deleteErr) {
			this.props.fireFlashMessage(`Failed to delete Post. ${deleteErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, serviceProviderToDelete: null })
		}

		await setStateAsync(this, { loading: false, serviceProviderToDelete: null })
	}

	/**
	 * Hide the deletion modal when the user cancels it
	 */
	closeDeleteServiceProviderModal = async () => {
		await setStateAsync(this, { serviceProviderToDelete: null })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Header Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Determine which action to take, based on the header
	 * button that the user selected
	 */
	handleHeaderButtonAction = (e: any) => {
		const action = e.target.value
		switch (action) {
			case 'createServiceProvider':
				this.handleCreateButton()
				break
			default:
				break
		}
	}

	/**
	 * Navigate to the Service Provider creation form
	 */
	handleCreateButton = () => {
		this.props.history.push(Constants.CREATE_SERVICE_PROVIDER_ROUTE)
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildHeaderButtons = (): HeaderButton[] => {
		return [
			{
				action: 'createServiceProvider',
				type: ButtonType.DEFAULT,
				text: 'New Service Provider',
				class: 'btn-primary',
			},
		]
	}

	buildPageHeader = () => {
		return (
			<PageHeaderComponent
				pageTitle='Service Providers'
				buttons={this.buildHeaderButtons()}
				buttonHandler={this.handleHeaderButtonAction}
			/>
		)
	}

	buildDeleteConfirmationModal = () => {
		if (!this.state.serviceProviderToDelete) { return null }
		return (
			<ModalComponent
				modalTitle={'Delete Service Provider'}
				primaryMessage={'Are you sure you want to delete this Service Provider?'}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.closeDeleteServiceProviderModal}
				submitButtonName={'Confirm'}
				submitButtonHandler={this.executeServiceProviderDeletion}
			/>
		)
	}

	buildServiceProvidersTable = () => {
		const { loggedInClub, calendars } = this.props
		const { loading } = this.state

		// Check if we have Service Providers
		const calendarGroups = loggedInClub.calendarGroups
		const serviceProviderCalendarGroup = calendarGroups.find((cg) => cg.type === core.Calendar.GroupType.Service)
		const serviceProviders = calendars.filter((c) => c.groupID === serviceProviderCalendarGroup._id)

		// Add the address information
		const serviceProvidersForTable: ServiceProviderTableItem[] = serviceProviders.map((sp) => {
			// TODO: Remove when Locations come through
			sp.location = (sp.location) ? sp.location : {}
			const streetAddress = sp.location.address1
			const city = sp.location.city
			const state = sp.location.state
			const zip = sp.location.zip
			const email = sp.location.email
			const phone = sp.location.phone
			const contactName = sp.location.contactName
			return { ...sp, streetAddress, city, state, zip, email, phone, contactName }
		})

		return (
			<TableComponent<ServiceProviderTableItem>
				columns={ServiceProviderTableColumns}
				rowItems={serviceProvidersForTable}
				showPaging={false}
				dropdownHandler={this.handleTableDropdownAction}
				isLoading={loading}
			/>
		)
	}

	render() {
		const { loggedInClub } = this.props
		const { error } = this.state
		if (error) { return <ErrorComponent club={loggedInClub} isAdmin={this.props.userState.isAdmin} /> }
		if (!this.props.userState.isAdmin) { return null }

		// Determine which content we are showing
		const content = this.buildServiceProvidersTable()

		return (
			<div>
				{this.buildPageHeader()}

				<div className='post-content-container-div'>
					{content}
				</div>

				{this.buildDeleteConfirmationModal()}
			</div>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	userState: state.user,
	calendars: state.calendar.calendars,
	loggedInClub: state.club.loggedInClub,
})

const mapDispatchToProps = {
	...CalendarActions,
	...AlertActions
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceProviderComponent)
