// External Dependencies
import * as Yup from 'yup'
import { oc } from 'ts-optchain'

// Internal Dependencies
import { FormInput, FormInputType } from '../Shared/Form'
import * as Constants from '../../constants/index'

export const ForgotPasswordFormInputs = (): FormInput[] => {
	return [
		{
			title: 'Email',
			property: 'email',
			type: FormInputType.TEXT,
			placeholder: 'Email',
			defaultValue: '',
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		}
	]
}

export const NewPasswordFormInputs = (): FormInput[] => {
	return [
		{
			title: 'New Password',
			property: 'newPassword',
			type: FormInputType.PASSWORD,
			defaultValue: '',
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Confirm New Password',
			property: 'newPasswordConfirm',
			type: FormInputType.PASSWORD,
			defaultValue: '',
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		}
	]
}
