// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { FormikProps } from 'formik'
import { oc } from 'ts-optchain'

// Internal Dependencies

// UI
import ModalComponent from '../../Shared/Modal'

// Form
import { EditCoverImageModalInputs, EditCoverImageState } from './form'
import { FormikComponent, BuildWrappedForm } from '../../Shared/Formik'

// Actions
import { AlertActions } from '../../../actions/index'

type ConnectedActions = typeof mapDispatchToProps

interface ComponentProps {
	cancelButtonHandler: () => any
	submitButtonHandler: (image: core.SubModels.Image.Model | File[]) => any
	club?: core.Club.Model
}

type Props = ConnectedActions & ComponentProps
type State = any

/**
 * The `EditCoverImageModal` provides UI for updating the cover image.
 */
class EditCoverImageModal extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
	}

	// ----------------------------------------------------------------------------------
	// Handlers
	// ----------------------------------------------------------------------------------

	handleSave = async (formState: EditCoverImageState) => {
		this.props.submitButtonHandler(formState.image)
	}

	/**
	 * Handles cancel button clicks.
	 */
	handleCancelButton = async () => {
		return this.props.cancelButtonHandler()
	}

	// ----------------------------------------------------------------------------------
	// Component Builds
	// ----------------------------------------------------------------------------------

	buildModal = (formikProps: FormikProps<EditCoverImageState>) => {
		const wrappedFormProps = { inputs: EditCoverImageModalInputs }
		return (
			<ModalComponent
				modalTitle={'Edit Cover Image'}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.handleCancelButton}
				submitButtonName={'Save'}
				submitButtonHandler={formikProps.submitForm}
				buildInputs={() => BuildWrappedForm(wrappedFormProps, formikProps)}
			/>
		)
	}

	public render() {
		const formResource = { image: oc(this).props.club.website.coverImage() }
		return (
			<FormikComponent
				inputs={EditCoverImageModalInputs}
				enableReinitialize={false}
				onSubmit={this.handleSave}
				render={this.buildModal}
				formResource={formResource}
			/>
		)
	}
}

const mapDispatchToProps = {
	...AlertActions
}

const enhance = compose<React.ComponentType<ComponentProps>>(
	connect(undefined, mapDispatchToProps)
)

export default enhance(EditCoverImageModal)
