// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import * as Yup from 'yup'
import * as core from 'club-hub-core'
import { Formik, FormikProps } from 'formik'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { isNullOrUndefined } from 'util'
import { debounce } from 'underscore'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies

// Actions
import { AlertActions, CalendarActions, ClubActions } from '../../actions/index'

// State
import { RootReducerState } from '../../reducers'
import { calendarsByIDSelector, calendarGroupsByIDSelector } from '../../reducers/calendar'

// Form
import { InputSelectionItem, ReactSelectItem } from '../Shared/Form'

// Components
import { DateRangeSection } from './DateRangeSection'
import { GeneralSettingsSection, GeneralSettingsConfig } from './GeneralSettingsSection'
import { LotterySettingsSection, LotterySettingsConfig } from './LotterySettings'
import { HoursSection } from './HoursSection'
import { PrivilegeSection } from './PrivilegeSection'
import PrivilegeFormComponent from './PrivilegeForm'
import { PrivilegeFormState } from './PrivilegeForm/form'
import HoursFormComponent from './HoursForm'
import DateRangeFormComponent, { DateRangeForm } from './DateRangeForm'
import DimmedLoader from '../Shared/DimmedLoader'
import BackHeader from '../Shared/BackHeader'
import ModalComponent from '../Shared/Modal'

// Helpers
import { getReservationSettings } from '../../helpers/calendar'
import { setStateAsync } from '../../helpers/promise'
import * as Constants from '../../constants'
import { frequencies, dayOfWeekSelectionInputs } from '../../helpers/form'

interface ComponentFormState {
	settingDateRange: InputSelectionItem
	bookingDuration: number
	maxGuestsAdmin: number
	maxGuestsMember: number
	publicBookings: boolean
	joinableBookings: boolean
	lotteryEnabled: boolean
	lotteryStart: number
	lotteryEnd: number
	hours: core.IShared.HoursOfOperation[]
	privileges: core.Calendar.Privilege[]
}

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

// State Key Constants
type ModalStateKey = typeof ShowingDateRangeForm | typeof ShowingHoursForm | typeof ShowingPrivilegeForm | typeof ShowingDeletionModal
const ShowingDateRangeForm = 'showingDateRangeForm'
const ShowingHoursForm = 'showingHoursForm'
const ShowingPrivilegeForm = 'showingPrivilegeForm'
const ShowingDeletionModal = 'showingDeletionModal'

const initialState = {
	initialLoading: true,
	loading: false,
	submittingForm: false,
	selectedCalendarGroup: null as core.Calendar.Group | null,
	selectedCalendar: null as core.Calendar.Model | null,
	selectedCalendarID: null as string | null,
	calendarSettingIDs: [] as string[],
	settingsSelectedRange: null as InputSelectionItem | null,
	settingsHoursToEdit: null as core.IShared.HoursOfOperation | null,
	settingsHoursToDeleteIdx: null as number | null,
	settingsHoursForm: [] as core.IShared.HoursOfOperation[],
	settingsPrivilegeToDeleteIdx: null as number | null,
	settingsPrivilegeForm: [] as core.Calendar.Privilege[],
	settingsPrivilegeFormToEdit: null as core.Calendar.Privilege | null,
	[ShowingDateRangeForm]: false,
	[ShowingHoursForm]: false,
	[ShowingPrivilegeForm]: false,
	[ShowingDeletionModal]: false,
	dateRangeToEdit: null as any,
}

type Props = ConnectedActions & ConnectedState & RouteComponentProps
type State = typeof initialState

class ReservationSettingsComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)

		// Determine what the selected Calendar is based on the URL parameter
		const selectedCalendarID = (props.match.params as any).calendar_id

		// Determine what the Calendar Group is for the Calendar
		const selectedCalendar = props.calendarsByID[selectedCalendarID]
		const selectedCalendarGroup = props.calendarGroupsByID[`${selectedCalendar.groupID}`]

		this.state = { ...initialState, selectedCalendarID, selectedCalendar, selectedCalendarGroup }
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Method
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		// Get the default Resource Settings
		const defaultReservationSettings = this.getDefaultSetting()
		const selectedRange: InputSelectionItem = (defaultReservationSettings) ?
			{ label: defaultReservationSettings.name, value: `${defaultReservationSettings._id}` } :
			null
		const hoursForm = [...oc(defaultReservationSettings).hours([])]
		const privilegeForm = [...oc(defaultReservationSettings).privileges([])]

		const newState = {
			initialLoading: false,
			settingsSelectedRange: selectedRange,
			settingsHoursForm: hoursForm,
			settingsPrivilegeForm: privilegeForm,
		}

		await setStateAsync(this, newState)
	}

	// ----------------------------------------------------------------------------------
	// Setting Create & Update requests.
	// ----------------------------------------------------------------------------------

	/**
	 * Creates a new reservation setting.
	 */
	createReservationSetting = async (payload: Partial<core.Calendar.ReservationSetting>) => {
		const calSettings = oc(this.state.selectedCalendar).reservationSettings([])
		if (calSettings.length) {
			await this.createCalendarSetting(payload)
			return
		}

		const groupSettings = oc(this.state.selectedCalendarGroup).reservationSettings([])
		if (groupSettings.length) {
			await this.updateGroupSetting(payload)
		}
	}

	/**
 * Creates the calendar settings for a specific calendar.
 */
	createCalendarSetting = async (payload: Partial<core.Calendar.ReservationSetting>) => {
		const calendarID = this.state.selectedCalendarID
		const [err] = await to(this.props.updateCalendarReservationSetting(calendarID, payload as any) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to save Reservation Settings. ${err}`, Constants.FlashType.DANGER)
			return
		}
		this.props.fireFlashMessage(`Successfully saved Reservation Settings.`, Constants.FlashType.SUCCESS)
	}

	/**
	 * Updates the calendar settings for s specific group.
	 */
	createGroupSetting = async (payload: Partial<core.Calendar.ReservationSetting>) => {
		// Update the setting object.
		const settings = [...this.state.selectedCalendarGroup.reservationSettings]
		settings.push(payload as core.Calendar.ReservationSetting)

		// Update our group
		const group = { ...this.state.selectedCalendarGroup }
		group.reservationSettings = settings

		const [err] = await to(this.props.updateCalendarGroup(group) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to save Reservation Settings. ${err}`, Constants.FlashType.DANGER)
			return
		}
		this.props.fireFlashMessage(`Successfully saved Reservation Settings.`, Constants.FlashType.SUCCESS)
	}

	/**
	 * Updates our reservation settings.
	 */
	updateReservationSetting = async (updatedSettings: Partial<core.Calendar.ReservationSetting>) => {
		const activeSetting = this.getActiveSetting()
		const payload = { ...activeSetting, ...updatedSettings }
		const calSettings = oc(this.state.selectedCalendar).reservationSettings([])
		if (calSettings.length) {
			await this.updateCalendarSetting(payload)
			return
		}

		const groupSettings = oc(this.state.selectedCalendarGroup).reservationSettings([])
		if (groupSettings.length) {
			await this.updateGroupSetting(payload)
		}
	}

	/**
	 * Updates the calendar settings for a specific calendar.
	 */
	updateCalendarSetting = async (payload: core.Calendar.ReservationSetting) => {
		const calendarID = this.state.selectedCalendarID
		const [err] = await to(this.props.updateCalendarReservationSetting(calendarID, payload as any) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to save Reservation Settings. ${err}`, Constants.FlashType.DANGER)
			return
		}
		this.props.fireFlashMessage(`Successfully saved Reservation Settings.`, Constants.FlashType.SUCCESS)
	}

	/**
	 * Updates the calendar settings for s specific group.
	 */
	updateGroupSetting = async (payload: Partial<core.Calendar.ReservationSetting>) => {
		// Update the setting object.
		const settings = [...this.state.selectedCalendarGroup.reservationSettings]
		const filteredSettings = settings.filter((setting) => payload._id !== setting._id)
		filteredSettings.push(payload as core.Calendar.ReservationSetting)

		// Update our group
		const group = { ...this.state.selectedCalendarGroup }
		group.reservationSettings = filteredSettings

		const [err] = await to(this.props.updateCalendarGroup(group) as any)
		if (err) {
			this.props.fireFlashMessage(`Failed to save Reservation Settings. ${err}`, Constants.FlashType.DANGER)
			return
		}
		this.props.fireFlashMessage(`Successfully saved Reservation Settings.`, Constants.FlashType.SUCCESS)
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	// ----------------------------------------------------------------------------------
	// Form Handlers
	// ----------------------------------------------------------------------------------

	debouncedOnSubmit = debounce(async (values: ComponentFormState) => {
		this.handleOnSubmit(values)
	}, 300)

	handleOnSubmit = async (values: ComponentFormState) => {
		await setStateAsync(this, { submittingForm: false })
		const payload = {
			...values,
			hours: (this.state.settingsHoursForm) ? [...this.state.settingsHoursForm] : [...values.hours],
			privileges: (this.state.settingsPrivilegeForm) ? [...this.state.settingsPrivilegeForm] : [...values.privileges]
		}

		await this.updateReservationSetting(payload)
	}

	// ----------------------------------------------------------------------------------
	// Base Modal Handlers
	// ----------------------------------------------------------------------------------
	handleOpenForm = async (formStateKey: ModalStateKey) => {
		await setStateAsync(this, (() => ({ [formStateKey]: true })))
	}

	handleCloseForm = async (formStateKey: ModalStateKey) => {
		await setStateAsync(this, (() => ({
			[formStateKey]: false,
			dateRangeToEdit: null,
			settingsPrivilegeFormToEdit: null,
			settingsHoursToEdit: null,
			settingsHoursToDeleteIdx: null,
			settingsPrivilegeToDeleteIdx: null,
			submittingForm: false,
		})))
	}

	// ----------------------------------------------------------------------------------
	// Date Range Handlers
	// ----------------------------------------------------------------------------------

	handleDateRangeSelectChange = async (field: string, data: InputSelectionItem) => {
		if (data.value === 'addRange') {
			const settings = this.getSettings()
			const existingSettingIDs = settings.map((setting) => `${setting._id}`)
			await setStateAsync(this, { calendarSettingIDs: existingSettingIDs })
			return this.handleOpenForm(ShowingDateRangeForm)
		}

		await setStateAsync(this, { settingsSelectedRange: data })
	}

	handleEditDateRange = async () => {
		// Get the Setting
		const activeSetting = this.getActiveSetting()
		const formResource = {
			_id: activeSetting._id,
			name: activeSetting.name,
			start: new Date(activeSetting.dateRangeStart),
			end: new Date(activeSetting.dateRangeEnd),
		}

		await setStateAsync(this, { dateRangeToEdit: { ...formResource } })
		return this.handleOpenForm(ShowingDateRangeForm)
	}

	handleDateRangeFormUpdate = async (dateRangeForm: DateRangeForm) => {
		// Create the payload
		const updatedSettings = {
			isDefault: false,
			name: dateRangeForm.name,
			dateRangeStart: dateRangeForm.start,
			dateRangeEnd: dateRangeForm.end,
		}
		// Update the settings.
		await this.updateReservationSetting(updatedSettings)

		const selectedRange: InputSelectionItem = {
			label: dateRangeForm.name,
			value: dateRangeForm._id,
		}

		await setStateAsync(this, {
			loading: false,
			settingsSelectedRange: selectedRange,
		})
	}

	handleDateRangeFormCreate = async (dateRangeForm: DateRangeForm) => {
		const defaultSetting = this.getDefaultSetting()

		// Create a new Reservation Setting
		const reservationSetting = {
			...defaultSetting,
			isDefault: false,
			name: dateRangeForm.name,
			dateRangeStart: dateRangeForm.start,
			dateRangeEnd: dateRangeForm.end,
		}
		delete reservationSetting._id
		await this.createReservationSetting(reservationSetting)
	}

	// ----------------------------------------------------------------------------------
	// Privilege Handlers
	// ----------------------------------------------------------------------------------

	handleSavePrivilegeForm = async (privilegeForm: core.Calendar.Privilege) => {
		// Check if Privilege Exists
		const existingPrivilegeIdx = this.state.settingsPrivilegeForm.findIndex((privilege) => {
			return privilege.memberType === privilegeForm.memberType
		})

		let newPrivilegeForm
		if (existingPrivilegeIdx !== -1) {
			newPrivilegeForm = [...this.state.settingsPrivilegeForm]
			newPrivilegeForm.splice(existingPrivilegeIdx, 1, privilegeForm)
		} else {
			newPrivilegeForm = [...this.state.settingsPrivilegeForm, privilegeForm]
		}

		await setStateAsync(this, {
			settingsPrivilegeForm: newPrivilegeForm,
			settingsPrivilegeFormToEdit: null,
			submittingForm: true
		})
	}

	handlePrivilegeTableDropdownAction = async (e: any) => {
		const [action, value] = e
		switch (action) {
			case 'editPrivilege':
				return this.handleEditTablePrivilege(value)
			case 'deletePrivilege':
				return this.handleRemoveTablePrivilege(value)
			default:
				break
		}
	}

	handleEditTablePrivilege = async (rowIdx: number) => {
		const privilegeToEdit = [...this.state.settingsPrivilegeForm][rowIdx]

		await setStateAsync(this, {
			showingPrivilegeForm: true,
			settingsPrivilegeFormToEdit: privilegeToEdit
		})
	}

	handleRemoveTablePrivilege = async (rowIdx: number) => {
		await setStateAsync(this, {
			[ShowingDeletionModal]: true,
			settingsPrivilegeToDeleteIdx: rowIdx,
			submittingForm: true,
		})
	}

	handleConfirmRemoveTablePrivilege = async () => {
		const newPrivilegeForm = [...this.state.settingsPrivilegeForm]
		newPrivilegeForm.splice(this.state.settingsPrivilegeToDeleteIdx, 1)

		await setStateAsync(this, {
			settingsPrivilegeForm: newPrivilegeForm,
			submittingForm: true
		})
		await this.handleCloseForm(ShowingDeletionModal)
	}

	// ----------------------------------------------------------------------------------
	// Hours Handlers
	// ----------------------------------------------------------------------------------
	handleSaveHoursForm = async (hoursForm: core.IShared.HoursOfOperation[]) => {
		const savedHours = hoursForm[0]
		let newSettingsHoursForm
		if (this.state.settingsHoursToEdit) {
			newSettingsHoursForm = this.state.settingsHoursForm.map((hours) => {
				const hoursToEdit = this.state.settingsHoursToEdit
				const daysMatch = hours.dayOfWeek === hoursToEdit.dayOfWeek
				const opensMatch = new Date(hours.opens).getTime() === new Date(hoursToEdit.opens).getTime()
				const closesMatch = new Date(hours.closes).getTime() === new Date(hoursToEdit.closes).getTime()
				if (daysMatch && opensMatch && closesMatch) {
					return savedHours
				}
				return hours
			})
		} else {
			newSettingsHoursForm = [...this.state.settingsHoursForm, savedHours]
		}

		await setStateAsync(this, {
			settingsHoursForm: newSettingsHoursForm,
			settingsHoursToEdit: null,
			submittingForm: true,
		})
	}

	handleHoursTableDropdownAction = async (e: any) => {
		const [action, value] = e
		switch (action) {
			case 'editHours':
				return this.handleEditTableHours(value)
			case 'deleteHours':
				return this.handleRemoveTableHours(value)
			default:
				break
		}
	}

	handleEditTableHours = async (rowIdx: number) => {
		const existingHours = [...this.state.settingsHoursForm][rowIdx]
		await setStateAsync(this, { settingsHoursToEdit: existingHours, showingHoursForm: true })
	}

	handleRemoveTableHours = async (rowIdx: number) => {
		await setStateAsync(this, { [ShowingDeletionModal]: true, settingsHoursToDeleteIdx: rowIdx })
	}

	handleConfirmRemoveTableHours = async () => {
		const newHoursForm = [...this.state.settingsHoursForm]
		newHoursForm.splice(this.state.settingsHoursToDeleteIdx, 1)

		await setStateAsync(this, {
			settingsHoursForm: newHoursForm,
			submittingForm: true,
		})
		await this.handleCloseForm(ShowingDeletionModal)
	}

	handleMembershipTypeChange = async (item: ReactSelectItem) => {
		const form = this.state.settingsPrivilegeForm
		const formToEdit = form.find((priv: core.Calendar.Privilege) => `${priv.memberType}` === `${item.value}`)
		await setStateAsync(this, { settingsPrivilegeFormToEdit: formToEdit })
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	/**
	 * Builds the content of the component
	 */
	buildContent = (formikProps: FormikProps<ComponentFormState>) => {
		// Check if a form submission has been trigged outside of the Formik component
		if (this.state.submittingForm) {
			formikProps.submitForm()
			setStateAsync(this, { submittingForm: false })
		}

		// Get our settings.
		const settings = this.getSettings()

		// Determine which setting is selected
		const selectedRangeOption = oc(formikProps).values.settingDateRange()
		let selectedRange = selectedRangeOption
		if (selectedRangeOption.value === 'addRange' && this.state.calendarSettingIDs.length > 0) {
			// While the Setting range is being created, keep the selected range
			// variable pointing at whatever the default settings are
			const defaultSetting = this.getDefaultSetting()
			selectedRange = { label: defaultSetting.name, value: defaultSetting._id as any }

			// Check if we created a new Setting on the Calendar. If we did, then:
			// 1. Update the selectedRange variable
			// 2. Manually set the Formik field value for settingDateRange
			// 3. Reset the calendarSettingIDs array in state
			const calendarSettings = settings.map((setting) => setting)
			for (const setting of calendarSettings) {
				const isNew = isNullOrUndefined(this.state.calendarSettingIDs.find((s) => s === `${setting._id}`))
				if (isNew) {
					selectedRange = { label: setting.name, value: setting._id as any }
					formikProps.setFieldValue('settingDateRange', selectedRange)
					setStateAsync(this, { calendarSettingIDs: [] })
					break
				}
			}
		}

		return (
			<RS.Row noGutters={true}>
				<RS.Col className='reservationSettings-page-body-container'>
					<div className='reservationSettings-page-body'>
						{/* Date Range */}
						<DateRangeSection
							reservationSettings={settings}
							value={selectedRange}
							onChange={this.handleDateRangeSelectChange}
							submitForm={formikProps.submitForm}
							handleEdit={this.handleEditDateRange}
						/>

						{/* General */}
						<GeneralSettingsSection
							selectedRange={selectedRange}
							reservationSettings={settings}
							handleSave={formikProps.submitForm}
						/>

						{/* Hours of Operation */}
						<HoursSection
							settingHours={this.state.settingsHoursForm}
							onTableDropdown={this.handleHoursTableDropdownAction}
							onAddHours={() => this.handleOpenForm(ShowingHoursForm)}
						/>

						{/* Privileges */}
						<PrivilegeSection
							membershipTypes={oc(this).props.loggedInClub.resources.members.types([])}
							dataForTable={[...this.state.settingsPrivilegeForm]}
							onTableDropdown={this.handlePrivilegeTableDropdownAction}
							onAddPrivilege={() => this.handleOpenForm(ShowingPrivilegeForm)}
						/>

						{/* Lottery */}
						{/* <LotterySettingsSection
							selectedRange={selectedRange}
							reservationSettings={settings}
							handleSave={formikProps.submitForm}
						/> */}
					</div>
				</RS.Col>
			</RS.Row>
		)
	}

	buildDateRangeForm = () => {
		if (!this.state.showingDateRangeForm) { return null }

		return (
			<DateRangeFormComponent
				selectedCalendar={this.getSelectedCalendar()}
				createHandler={this.handleDateRangeFormCreate}
				updateHandler={this.handleDateRangeFormUpdate}
				closeHandler={() => this.handleCloseForm(ShowingDateRangeForm)}
				existingRangeConfig={this.state.dateRangeToEdit}
			/>
		)
	}

	buildPrivilegeForm = () => {
		if (!this.state.showingPrivilegeForm) { return null }
		const membershipTypes = oc(this).props.loggedInClub.resources.members.types([])

		// Determine if we are editing
		let privilegeToEdit: PrivilegeFormState
		const existingPrivilege = oc(this).state.settingsPrivilegeFormToEdit()
		if (existingPrivilege) {
			// Convert to PrivilegeFormState
			const maxBookingsToEdit = oc(existingPrivilege).maxBookings([]).map((existing) => {
				return {
					limit: existing.limit,
					period: frequencies[existing.period]
				}
			})
			const guestBookingsToEdit = oc(existingPrivilege).maxGuests([]).map((existing) => {
				return {
					limit: existing.limit,
					period: frequencies[existing.period]
				}
			})
			privilegeToEdit = {
				memberType: existingPrivilege.memberType as any,
				bookingWindow: existingPrivilege.bookingWindow,
				maxBookings: maxBookingsToEdit,
				guestBookings: guestBookingsToEdit,
			}
		}
		return (
			<PrivilegeFormComponent
				membershipTypes={membershipTypes}
				onMemberTypeChange={this.handleMembershipTypeChange}
				saveHandler={this.handleSavePrivilegeForm}
				closeHandler={() => this.handleCloseForm(ShowingPrivilegeForm)}
				existingRestrictions={privilegeToEdit}
			/>
		)
	}

	buildHoursForm = () => {
		if (!this.state.showingHoursForm) { return null }
		let existingHoursResource
		const existingHoursToEdit = oc(this).state.settingsHoursToEdit()
		if (existingHoursToEdit) {
			existingHoursResource = {
				dayOfWeek: dayOfWeekSelectionInputs[existingHoursToEdit.dayOfWeek],
				opens: existingHoursToEdit.opens,
				closes: existingHoursToEdit.closes,
				duration: existingHoursToEdit.duration,
			}
		}

		return (
			<HoursFormComponent
				saveHandler={this.handleSaveHoursForm}
				closeHandler={() => this.handleCloseForm(ShowingHoursForm)}
				existingHours={existingHoursResource}
			/>
		)
	}

	buildConfirmDeletionModal = () => {
		if (!this.state.showingDeletionModal) { return null }

		let deletionHandler
		if (!isNullOrUndefined(this.state.settingsHoursToDeleteIdx)) {
			deletionHandler = this.handleConfirmRemoveTableHours
		}

		if (!isNullOrUndefined(this.state.settingsPrivilegeToDeleteIdx)) {
			deletionHandler = this.handleConfirmRemoveTablePrivilege
		}

		return (
			<ModalComponent
				modalTitle={'Confirm Deletion'}
				className='reservation-settings-deletion-modal'
				primaryMessage='Are you sure you want to delete this?'
				submitButtonName={'Confirm'}
				cancelButtonName={'Cancel'}
				submitButtonHandler={deletionHandler}
				cancelButtonHandler={() => this.handleCloseForm(ShowingDeletionModal)}
			/>
		)
	}

	getInitialFormValues = (): ComponentFormState => {
		const settings = this.getSettings()
		const selectedRange = oc(this).state.settingsSelectedRange()

		const activeSetting = settings.find((s: any) => (s._id as any) === selectedRange.value)
		if (!activeSetting) {
			return
		}
		const initialFormValues = {
			settingDateRange: selectedRange,
			bookingDuration: activeSetting.bookingDuration,
			maxGuestsAdmin: activeSetting.maxGuestsAdmin,
			maxGuestsMember: activeSetting.maxGuestsMember,
			publicBookings: activeSetting.publicBookings,
			joinableBookings: activeSetting.joinableBookings,
			hours: activeSetting.hours,
			privileges: activeSetting.privileges,
			lotteryEnabled: oc(activeSetting).lotteryEnabled(false),
			lotteryStart: oc(activeSetting).lotteryStart(0),
			lotteryEnd: oc(activeSetting).lotteryEnd(0)
		}
		return initialFormValues
	}

	buildValidationSchema = () => {
		let schemaShape = {}
		const generalConfig = GeneralSettingsConfig
		for (const config of generalConfig) {
			if (!config.validation) { continue }
			schemaShape = { ...schemaShape, [config.key]: config.validation }
		}

		return Yup.object().shape({ ...schemaShape })
	}

	buildBackHeader = () => {
		const backTitle = oc(this).state.selectedCalendarGroup.name('Back')
		let backRoute: string
		switch (oc(this).state.selectedCalendarGroup.type()) {
			case core.Calendar.GroupType.Golf:
				backRoute = Constants.GOLF_RESERVATION_ROUTE
				break
			case core.Calendar.GroupType.Dining:
				backRoute = Constants.DINING_RESERVATIONS_ROUTE
				break
			default:
				backRoute = Constants.HOME_ROUTE
				break
		}

		return <BackHeader to={backRoute} backTitle={backTitle} />
	}

	render() {
		if (this.state.initialLoading || this.state.loading) {
			return <DimmedLoader component={null} isLoading={true} />
		}

		return (
			<div className='reservation-settings-container mx-auto'>
				{this.buildBackHeader()}
				<div className='card reservationSettings-container-div'>
					<Formik<ComponentFormState>
						initialValues={this.getInitialFormValues()}
						validationSchema={this.buildValidationSchema()}
						enableReinitialize={true}
						onSubmit={this.debouncedOnSubmit}
						render={this.buildContent}
					/>

					{this.buildDateRangeForm()}
					{this.buildPrivilegeForm()}
					{this.buildHoursForm()}
					{this.buildConfirmDeletionModal()}
				</div>
			</div>
		)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------
	getSelectedCalendar = () => {
		const { calendarsByID } = this.props
		const { selectedCalendarID } = this.state

		return calendarsByID[selectedCalendarID]
	}

	/**
	 * Gets the active setting.
	 */
	getActiveSetting = () => {
		const settings = this.getSettings()
		return settings.find((setting) => {
			return (setting._id as any) === this.state.settingsSelectedRange.value
		})
	}

	/**
	 * Gets the default setting.
	 */
	getDefaultSetting = () => {
		const settings = this.getSettings()
		return settings.find((setting) => setting.isDefault)
	}

	/**
	 * Fetches the settings array for the view. Settings come from:
	 * 1. The calendar.
	 * 2. The calendar group.
	 */
	getSettings = () => {
		const group = this.state.selectedCalendarGroup
		const calendar = this.getSelectedCalendar()
		return getReservationSettings(group, calendar)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	loggedInClub: state.club.loggedInClub,
	calendarsByID: calendarsByIDSelector(state),
	calendarGroupsByID: calendarGroupsByIDSelector(state),
})

const mapDispatchToProps = {
	...AlertActions,
	...CalendarActions,
	...ClubActions
}

export default connect(mapStateToProps, mapDispatchToProps)(ReservationSettingsComponent)
