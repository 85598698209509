// External Dependencies
import * as Sentry from '@sentry/browser'
import * as React from 'react'
import ReactDOMServer from 'react-dom/server'

import * as RS from 'reactstrap'
import * as core from 'club-hub-core'
import path from 'path'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { oc } from 'ts-optchain'
import { Editor } from '@tinymce/tinymce-react'

// @ts-ignore
import juice from 'juice'

// Internal Dependencies
import * as Constants from '../../../constants'

// Actions
import { AlertActions, ImageActions, ClubActions } from '../../../actions/index'

// State
import { RootReducerState } from '../../../reducers'

import PDFModal from '../../../components/Modals/PDFModal'
import { PDFModalState } from '../../../components/Modals/PDFModal/form'

// Helpers
import { setStateAsync } from '../../../helpers/promise'

// Components
import DimmedLoader from '../DimmedLoader'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

// Image Upload Interfaces
interface RemoteImageCB {
	image: string
	url: string
}

type ImageUpoadCB = (err: Error, info?: RemoteImageCB, file?: File) => void

interface ComponentProps {
	richContent: core.SubModels.RichContent.Model
	className?: string
	placeholder?: string
	formEditor?: boolean
	fullScreen?: boolean
	onChange: (state: core.SubModels.RichContent.Model) => void
	onBlur?: () => void
}

const initialState = {
	loading: true,
	editorLoading: true,
	showingPDFModal: false,
	editor: null as any,
	currentFileName: null as string,
	currentImage: null as RemoteImageCB,
	richContent: null as core.SubModels.RichContent.Model | null
}

type Props = ConnectedState & ConnectedActions & ComponentProps
type State = typeof initialState

class EditorComponent extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props)

		// Set the Initial State
		this.state = {
			...initialState,
			richContent: oc(this).props.richContent({})
		}
	}

	handleEditorChange = async (e: any, editor: any) => {
		const plainText = editor.getContent({ format: 'text' })
		const html = editor.getContent()
		const inlinedHTML = await juice(html, { extraCss: contentStyle })
		const newState: core.SubModels.RichContent.Model = {
			html: inlinedHTML,
			text: plainText,
		}
		await setStateAsync(this, { richContent: newState })
		return this.props.onChange(newState)
	}

	/**
	 * Called after the editor is initialized.
	 * Sets loading to false to remove the dimmed loader.
	 * @returns void.
	 */
	handleEditorInitialized = async () => {
		await setStateAsync(this, { loading: false })
	}

	/**
	 * Upload and image.
	 */
	uploadCallbackImage = async (file: File, cb: ImageUpoadCB) => {
		const formData = new FormData()
		formData.append('image', file, file.name)
		try {
			const res: core.SubModels.Image.Model = await ImageActions.createImage(formData)
			cb(null, { image: res.lg, url: res.lg }, file)
		} catch (err) {
			cb(err)
		}
	}

	/**
	 * Uploads a PDF.
	 */
	uploadCallbackPDF = async (file: File, cb: ImageUpoadCB) => {
		const formData = new FormData()
		formData.append('file', file, file.name)
		try {
			const { pdfUrl, imageBuffer, images } = await ImageActions.createPdfImage(formData)
			cb(null, { image: images.lg, url: pdfUrl }, file)
		} catch (err) {
			cb(err)
		}
	}

	/**
	 * Uploads a tiny file.
	 */
	uploadTinyFile = async (editor: any, fileType: string, cb: ImageUpoadCB) => {
		const fileInput = document.createElement('input')
		fileInput.setAttribute('type', 'file')
		fileInput.setAttribute('accept', fileType)
		fileInput.onchange = async () => {
			// Show progress in the editor.
			editor.setProgressState(true)

			// Determine what we are uploading
			const file = fileInput.files[0]
			const fileExtension = path.extname(file.name)
			const fileIsPdf = fileExtension === '.pdf'

			// Pick the appropriate callback handler function
			const callbackFunction = (fileIsPdf) ? this.uploadCallbackPDF : this.uploadCallbackImage
			callbackFunction(file, cb)
		}
		fileInput.click()
	}

	imageUploadHandler = (blobInfo: any, success: (location: string) => {}, failure: (errMessage: string) => {}) => {
		this.uploadCallbackImage(blobInfo.blob(), (err: Error, info: { image: string, url: string }) => {
			if (err) {
				return (failure(err.message))
			}
			success(info.image)
		})
	}

	uploadAndInsertFile = (editor: any) => {
		const fileType = '.pdf,image/*'
		this.uploadTinyFile(editor, fileType, (err: Error, upload: RemoteImageCB, file: File) => {
			if (err) {
				this.props.fireFlashMessage(`Failed to upload file.`, Constants.FlashType.DANGER)
			}
			const thisOne: any = `<p><a href='${upload.url}'><img src='${upload.image}'/></a></p>`
			editor.execCommand('mceInsertContent', false, thisOne)
			editor.setProgressState(false)
		})
	}

	uploadAndInsertPDF = async (editor: any) => {
		const fileType = '.pdf'
		this.uploadTinyFile(editor, fileType, async (err: Error, upload: RemoteImageCB, file: File) => {
			if (err) {
				this.props.fireFlashMessage(`Failed to upload file.`, Constants.FlashType.DANGER)
			}

			editor.setProgressState(false)
			await setStateAsync(this, { currentImage: upload, currentFileName: file.name, editor })
		})
	}

	handleInsertPDF = async (state: PDFModalState) => {
		const editor = this.state.editor
		const currentImage = this.state.currentImage
		editor.setProgressState(true)

		const element = this.buildPDFElem(currentImage, state.name)
		editor.execCommand('mceInsertContent', false, element)
		editor.setProgressState(false)

		await setStateAsync(this, { currentImage: null, currentFileName: null, editor: null })
	}

	handlePDFUploadCancel = async () => {
		await setStateAsync(this, { currentImage: null, currentFileName: null, editor: null })
	}

	buildPDFElem = (upload: RemoteImageCB, fileName: string) => {
		const tStyle = { maWidth: '100%', width: '100%', borderCollapse: 'collapse' as any, tableLayout: 'fixed' as any }
		const tBodyStyle = { verticalAlign: 'top' }
		const tdStyle = { padding: '0px', width: '100%', border: '1px solid white' }
		const divStyle = { display: 'flex', alignItems: 'center', border: '1px solid #E3E8EE', borderRadius: 4, padding: '12px 20px' }
		const imgStyle = { display: 'inline-block', width: '40px' }
		const textContainerStyle = { marginLeft: '20px' }
		const h4Style = { display: 'block', fontSize: '17px', margin: '0', fontWeight: 500, color: '#495057' }
		const h6Style = { display: 'block', margin: '0', fontWeight: 300, color: '#495057' }
		const elem = (
			<RS.Table style={tStyle}>
				<tbody style={tBodyStyle}>
					<tr>
						<td style={tdStyle}>
							<div style={divStyle}>
								<a href={`${upload.url}`} target='_blank'>
									<img style={imgStyle} src='https://clubhubs3.s3-us-west-2.amazonaws.com/assets/pdf.png' />
								</a>
								<div style={textContainerStyle}>
									<a style={h4Style} target='_blank' href={`${upload.url}`}>{fileName}</a>
									<a style={h6Style} target='_blank' href={`${upload.url}`}>PDF Document</a>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</RS.Table>
		)
		return ReactDOMServer.renderToStaticMarkup(elem)
	}

	getCustomButtons = () => {
		return (editor: any) => {
			editor.ui.registry.addButton('upload', {
				icon: 'image',
				tooltip: 'Insert Image',
				onAction: () => this.uploadAndInsertFile(editor)
			})

			editor.ui.registry.addButton('pdf', {
				icon: 'upload',
				tooltip: 'Upload PDF',
				onAction: () => this.uploadAndInsertPDF(editor)
			})
		}
	}

	getToolBarConfig = () => {
		return `fontselect | formatselect | bold italic underline removeformat |
			alignleft aligncenter alignright | forecolor backcolor | numlist bullist | indent outdent | undo redo | upload pdf media link table emoticons`
	}

	generateTinyLinks = async (success: (links: any) => void) => {
		await this.props.fetchClubContentLinks()
		const linkMapper = (link: core.SubModels.ContentLink.Model) => ({ title: link.name, value: link.link })
		const events = this.props.clubState.contentLinks.events.map(linkMapper)
		const posts = this.props.clubState.contentLinks.posts.map(linkMapper)
		success(events.concat(posts))
	}

	getTinyConfig = () => {
		const minHeight = this.props.formEditor ? 260 : 1200

		return {
			menubar: false,
			plugins: 'link image code table lists advlist media emoticons paste autolink autoresize',
			toolbar: this.getToolBarConfig(),
			contextmenu: false,
			block_formats: 'Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3; Header 4=h4;',
			file_picker_types: 'file image media',
			link_list: this.generateTinyLinks,
			content_style: contentStyle,
			font_formats: fontFormats,
			min_height: minHeight,
			paste_as_text: true,
			paste_data_images: true,
			images_upload_handler: this.imageUploadHandler,
			width: '100%',
			toolbar_drawer: 'floating',
			statusbar: false,
			convert_urls: false,
			browser_spellcheck: true,
			setup: this.getCustomButtons(),
			table_default_attributes: tableAttributes,
			table_default_styles: tableStyle,
			theme_advanced_toolbar_align : 'center',
			init_instance_callback: this.handleEditorInitialized // Called after the editor is initialized.
		}
	}

	buildPDFModal = () => {
		if (!this.state.currentImage) { return null }
		return (
			<PDFModal
				name={this.state.currentFileName}
				link={this.state.currentImage.url}
				submitHandler={this.handleInsertPDF}
				cancelHandler={this.handlePDFUploadCancel}
			/>
		)
	}

	render() {
		const apiKey = '520xr24e2n9o7mvirfqbzy4bgvtszetjltrdue2qc7mookjc'
		const options = this.getTinyConfig()
		const initialValue = this.state.richContent.html
		const bottomMargin = this.props.formEditor ? 0 : 60
		const isLoading = this.state.loading
		const fullScreen = this.props.fullScreen ? 'full-screen' : ''
		const editor = (
			<div id={'editor-container'} className={`editor-container ${fullScreen} d-flex justify-content-center`} style={{ marginBottom: bottomMargin }}>
				<Editor
					id={'richContent'}
					apiKey={apiKey}
					value={initialValue}
					init={options}
					onEditorChange={this.handleEditorChange}
				/>
				{this.buildPDFModal()}
			</div>
		)
		return (
			<DimmedLoader
				component={editor}
				isLoading={isLoading}
			/>
		)
	}
}

const contentStyle = `
h1, h2, h3, p, ul {
	margin: 0 !important;
}

img {
	max-width: 100%;
	height: auto;
}

table {
	max-width: 100%;
}

tbody {
	vertical-align: top;
}

body {
	font-family: Avenir, Helvetica, sans-serif;
	font-size: let(--medium-font)
}
`

const tableStyle = {
	'width': '100%',
	'border-collapse': 'collapse',
	'table-layout': 'fixed',
}

const tableAttributes = {
	cellpadding: 10,
	border: 1,
	bordercolor: '##2880B9'

}
const fontFormats = `
Andale Mono=andale mono, times;
Arial=arial, helvetica, sans-serif;
Arial Black=arial black, avant garde;
Avenir=Avenir;
Arapey=Arapey;
Book Antiqua=book antiqua, palatino;
Comic Sans MS=comic sans ms, sans-serif;
Courier New=courier new, courier;
Georgia=georgia, palatino;
Helvetica=helvetica;
Impact=impact, chicago;
Symbol=symbol;
Tahoma=tahoma, arial, helvetica, sans-serif;
Terminal=terminal, monaco;
Times New Roman=times new roman, times;
Trebuchet MS=trebuchet ms, geneva;
Verdana=verdana, geneva;
Webdings=webdings;
Wingdings=wingdings, zapf dingbats;
`

const mapStateToProps = (state: RootReducerState) => ({
	clubState: state.club,
})

const mapDispatchToProps = {
	...AlertActions,
	...ClubActions,
}

const enhance = compose<React.ComponentType<ComponentProps>>(
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(EditorComponent)
