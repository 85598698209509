// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { flatten } from 'underscore'
import { isNullOrUndefined } from 'util'
import { RouteComponentProps } from 'react-router'
import { FormikProps } from 'formik'
import to from 'await-to-js'
import { oc } from 'ts-optchain'

// Internal Dependencies

// Actions
import { AlertActions, EventActions, CalendarActions } from '../../../actions/index'

// State
import { RootReducerState } from '../../../reducers'
import { inCustomerViewSelector, membersSelector } from '../../../reducers/user'
import { eventsByIDSelector } from '../../../reducers/event'

// Form
import { ReactSelectItem, InputSelectionItem, FormInput } from '../../Shared/Form'
import { ServiceFormState, CustomerServiceFormInputs, AdminServiceFormInputs } from './form'
import GenericFormComponent from '../../Shared/Formik/GenericForm'
import { BuildWrappedForm } from '../../Shared/Formik'

// Components
import TypeCreationModal from '../../Shared/TypeCreationModal'
import ErrorComponent from '../../Shared/ErrorComponent'
import DimmedLoader from '../../Shared/DimmedLoader'

// Helpers
import * as Constants from '../../../constants'
import { setStateAsync } from '../../../helpers/promise'
import { calendarIDForEvent } from '../../../helpers/event'
import { QueryOptions } from '../../../helpers/interface'
import { userForForm, getVehiclesForUser } from '../../../helpers/user'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	error: false,
	loading: true,
	serviceToUpdate: null as core.Event.Model | null,
	reservationToUpdate: null as string | null,
	selectedMemberID: null as string | null,
	selectedVehicleID: null as string | null,
	creatingType: false,
}

type Props = RouteComponentProps & ConnectedActions & ConnectedState
type State = typeof initialState

class ServiceForm extends React.Component<Props, State> {
	private isUpdateForm: boolean
	private club: core.Club.Model

	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
		this.isUpdateForm = this.props.location.pathname === Constants.UPDATE_SCHEDULED_SERVICE_ROUTE
		this.club = props.loggedInClub
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		if (this.props.isCustomerView) {
			const serviceProviderGroup = this.club.calendarGroups.find((cg) => cg.type === core.Calendar.GroupType.Service)
			const queryParams: QueryOptions = { offset: 0, limit: 0, groupID: serviceProviderGroup._id }
			const [err] = await to(this.props.fetchCalendars(queryParams) as any)
			if (err) {
				this.props.fireFlashMessage(`Problem fetching Providers. ${err.message}`, Constants.FlashType.DANGER)
				await setStateAsync(this, { loading: false, error: true })
				return
			}
		}

		if (this.isUpdateForm) {
			await this.setServiceForFormWithQueryParams()
		}

		await setStateAsync(this, { loading: false })
	}

	/**
	 * Gets the Service Event, that is being updated, from state using its ID that we get
	 * from the query parameters in the URL.
	 *
	 * The Service will have its information pre-filled into the form.
	 */
	setServiceForFormWithQueryParams = async () => {
		// Parse the query string of the URL into an object
		const parsedQuery = queryString.parse(this.props.location.search)

		const serviceID = parsedQuery.serviceID
		const reservationID = parsedQuery.reservationID
		const serviceToEdit = this.props.eventsByID[serviceID]

		// Update the state
		await setStateAsync(this, { serviceToUpdate: serviceToEdit, reservationToUpdate: reservationID })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	onFormChange = async (field: string, value: string | InputSelectionItem) => {
		if (field === 'vehicle') {
			const valueOrNull = (value) ? (value as InputSelectionItem).value : undefined
			await setStateAsync(this, { selectedVehicleID: valueOrNull })
		}
		if (field === 'member') {
			const valueOrNull = (value) ? (value as InputSelectionItem).value : undefined
			await setStateAsync(this, { selectedMemberID: valueOrNull })
		}

		if (field === 'type' && (value as InputSelectionItem).value === 'addType') {
			await setStateAsync(this, { creatingType: true })
		}
	}

	/**
	 * Returns the user to the Services view when they cancel the form
	 */
	handleCancel = () => {
		this.props.history.push(this.buildGoBackRoute())
	}

	handleCreationModalClose = async () => {
		await setStateAsync(this, { creatingType: false })
	}

	handleTypeCreationModalSave = async (formikProps: FormikProps<ServiceFormState>, newType?: core.Club.ResourceType) => {
		const newTypeForForm: InputSelectionItem = (!isNullOrUndefined(newType)) ?
			({ label: newType.title, value: `${newType._id}` }) : null

		// Update the form state to use the new Type
		formikProps.setFieldValue('type', newTypeForForm)
		await this.handleCreationModalClose()
	}

	/**
	 * Determines which handler to call (Create or Update) when the user submits the form
	 */
	handleSave = async (form: ServiceFormState): Promise<void> => {
		switch (this.props.location.pathname) {
			case Constants.CREATE_SCHEDULED_SERVICE_ROUTE:
				return this.handleCreate(form)
			case Constants.UPDATE_SCHEDULED_SERVICE_ROUTE:
				return this.handleUpdate(form)
			default:
				break
		}
	}

	/**
	 * Will create a new Service Event on the DB with the information in the form.
	 * Service Events will only be created if the validation conditions for the form are met.
	 */
	handleCreate = async (form: ServiceFormState): Promise<void> => {
		await setStateAsync(this, { loading: true })

		const [isValid, validationMessage] = this.validateNewServicePayload(form)
		if (!isValid) {
			this.props.fireFlashMessage(validationMessage, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false })
			return null
		}

		const payload = this.buildServicePayload(form)

		// TODO: Find a solution to this hack - JD
		form.date.setHours(3)
		const formDate = form.date.toISOString()
		const providerID = form.provider.value
		const [err] = await to(this.props.reserveBookableEvent(payload, providerID, formDate) as any)
		if (err) {
			this.props.fireFlashMessage(`Problem creating Service. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}

		this.props.fireFlashMessage('Service successfully created.', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, error: false })

		this.props.history.push(this.buildGoBackRoute())
	}

	/**
	 * Will update an existing Service Event on the DB with the information in the form.
	 * Service Events will only be updated if the validation conditions for the form are met.
	 */
	handleUpdate = async (form: ServiceFormState): Promise<void> => {
		if (!this.state.serviceToUpdate || !this.state.reservationToUpdate) { return }
		await setStateAsync(this, { loading: true })
		const [isValid, validationMessage] = this.validateNewServicePayload(form)
		if (!isValid) {
			this.props.fireFlashMessage(validationMessage, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false })
			return null
		}

		const reservationID = this.state.reservationToUpdate
		const payload = this.buildServicePayload(form)
		const serviceID = `${this.state.serviceToUpdate._id}`.split(Constants.EVENT_DATE_PREFIX)[0]
		const [err] = await to(this.props.updateEventRsvp(reservationID, payload, serviceID) as any)
		if (err) {
			this.props.fireFlashMessage(`Problem updating Service. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true })
			return
		}

		this.props.fireFlashMessage('Service successfully updated.', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, error: false })

		this.props.history.push(this.buildGoBackRoute())
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	/**
	 * Validates that the form meets the validation criteria
	 */
	validateNewServicePayload = (service: ServiceFormState): [boolean, string] => {
		const userState = this.props.userState
		const loggedInUser = userState.loggedInUser

		// Ensure that the selected Member is the owner of the Vehicle
		const vehicleID = service.vehicle.value
		let memberID: any
		if (this.state.serviceToUpdate) {
			memberID = (this.state.serviceToUpdate as any).member
		} else {
			memberID = (this.props.isCustomerView) ? loggedInUser._id : service.member.value
		}

		const member = (this.props.isCustomerView) ?
			loggedInUser :
			this.props.members.find((m) => m._id === memberID)

		// Check that the User linked to the Service owns the Vehicle
		const vehiclesForMember = getVehiclesForUser(member)
		if (isNullOrUndefined(vehiclesForMember.find((v) => `${v._id}` === vehicleID))) {
			const message = (this.props.isCustomerView) ?
				'Cannot create a Service for a vehicle you do not own.' :
				'Cannot create Service. User must own the vehicle.'
			return [false, message]
		}

		return [true, '']
	}

	/**
	 * Builds the payload, that will be sent to the server, based on the form
	 */
	buildServicePayload = (form: ServiceFormState): any => {
		let member
		if (this.props.isCustomerView) {
			// We are in the Customer view - just use the current User
			member = userForForm(this.props.userState.loggedInUser)
		} else if (this.isUpdateForm) {
			// We are in the Admin view, and are updating a Reservation - the member is not in the form, so get it from state
			const updatingMember = this.getUpdatingMember()
			member = userForForm(updatingMember)
		} else {
			// We are in the Admin view, and are creating a Reservation - the user is in the form
			member = form.member
		}

		const memberName = member.label
		const serviceCreator = member.value
		const participants: core.Event.Participant[] = [
			{ userID: member.value as any, name: memberName, checkedIn: false, paid: false }
		]

		const serviceReservation: core.Event.Reservation = {
			creator: serviceCreator as any,
			owner: participants[0].userID as any,
			participants,
			meta: {
				notes: form.notes || '',
				vehicleID: form.vehicle.value as any,
				type: oc(form).type.value() as any,
			}
		}

		return serviceReservation
	}

	/**
	 * Returns the route that the user will be navigated to after create, update or cancel actions.
	 * Non admin - My reservations component.
	 * Admin - Service table component.
	 */
	buildGoBackRoute = (): string => {
		return (this.props.isCustomerView) ?
			Constants.MY_RESERVATIONS_ROUTE.replace(':type', core.Calendar.GroupType.Service.toLocaleLowerCase()) :
			Constants.SCHEDULED_SERVICES_ROUTE
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildTypeCreationModal = (formikProps: FormikProps<ServiceFormState>) => {
		if (!this.state.creatingType) { return null }

		const existingTypesForModal = [].concat(oc(this.club).resources.services.types([]))

		return (
			<TypeCreationModal
				typeName={'Service'}
				resourceType={'services'}
				existingTypes={existingTypesForModal}
				onClose={async () => {
					formikProps.setFieldValue('type', null)
					return this.handleCreationModalClose()
				}}
				onSave={(newType: core.Club.ResourceType) => this.handleTypeCreationModalSave(formikProps, newType)}
			/>
		)
	}

	buildFormInputs = () => {
		const userState = this.props.userState
		const calendars = this.props.calendarState.calendars

		// Determine the Members based on context
		let memberUsers: core.User.Model[]
		if (this.props.isCustomerView) {
			// We are in the Customer view - the only member should be the current User
			memberUsers = [userState.loggedInUser]
		} else if (this.isUpdateForm) {
			// We are in the Admin view, and are updating - the only member should be the one linked to the Reservation
			memberUsers = [this.getUpdatingMember()]
		} else {
			// We are in the Admin view, and are creating a reservation - show all members
			memberUsers = oc(this).props.members([])
		}

		const serviceProviderGroup = this.club.calendarGroups.find((cg) => cg.type === core.Calendar.GroupType.Service)
		const providers = calendars.filter((sp) => sp.groupID === serviceProviderGroup._id)

		// If a Vehicle has been selected, only show its owner in the members input
		if (this.state.selectedVehicleID) {
			memberUsers = memberUsers.filter((m) => {
				const vehicles = getVehiclesForUser(m) || []
				return !isNullOrUndefined(vehicles.find((v) => `${v._id}` === this.state.selectedVehicleID))
			})
		}

		// Build service inputs.
		const serviceTypes = oc(this.club).resources.services.types([])
		const serviceTypeInputs: any[] = [
			{ options: serviceTypes.map<InputSelectionItem>((type) => ({ label: type.title, value: `${type._id}` })) }
		]

		if (!this.props.isCustomerView) {
			serviceTypeInputs.push({
				label: '', options: [{ label: 'Add New...', value: 'addType' }]
			})
		}

		const membersForForm = memberUsers.map((member) => userForForm(member))
		const vehiclesForForm = this.getVehiclesForForm(memberUsers)
		const providersForForm = this.getProvidersForForm(providers)

		const formInputs = this.getFormInputs(membersForForm, vehiclesForForm, providersForForm, serviceTypeInputs)

		return formInputs
	}

	buildForm = () => {
		const userState = this.props.userState

		const formTitle = (this.isUpdateForm) ? 'Update Service' : 'New Service'
		const submitButtonName = (this.isUpdateForm) ? 'Update' : 'Save'
		const formInputs = this.buildFormInputs()

		let formResource: any
		if (this.isUpdateForm && this.state.serviceToUpdate) {
			const serviceToUpdate = this.state.serviceToUpdate

			// Get the Reservation that we are updating
			const reservationToUpdate = serviceToUpdate.reservations.find((r) => `${r._id}` === this.state.reservationToUpdate)
			const reservationVehicleID = (reservationToUpdate && reservationToUpdate.meta) ? (reservationToUpdate.meta as core.Event.CarReservationMeta).vehicleID : null
			const reservationNotes = (reservationToUpdate && reservationToUpdate.meta) ? reservationToUpdate.meta.notes : ''
			const reservationType = (reservationToUpdate.meta as core.Event.CarReservationMeta).type || ''
			const calendarID = calendarIDForEvent(serviceToUpdate)

			// Get the User that owns the Reservation
			const memberUsers = oc(this).props.members([])
			const memberForUpdate = (this.props.isCustomerView) ?
				userState.loggedInUser :
				memberUsers.find((member) => member._id === reservationToUpdate.owner)

			// Update the form
			formResource = serviceToUpdate
			formResource.member = memberForUpdate._id
			formResource.vehicle = reservationVehicleID
			formResource.provider = calendarID
			formResource.notes = reservationNotes
			formResource.type = reservationType
		}

		return (
			<GenericFormComponent
				title={formTitle}
				inputs={formInputs}
				formResource={formResource}
				enableReinitialize={true}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.handleCancel}
				submitButtonName={submitButtonName}
				submitButtonHandler={this.handleSave}
				onChangeHandler={this.onFormChange}
				render={(formikProps) => this.buildFormBody(formInputs, formikProps)}
			/>
		)
	}

	buildFormBody = (formInputs: FormInput[], formikProps: FormikProps<ServiceFormState>) => {
		return (
			<>
				{BuildWrappedForm({ inputs: formInputs, onChange: this.onFormChange }, formikProps)}
				{this.buildTypeCreationModal(formikProps)}
			</>
		)
	}

	render() {
		const { loggedInClub } = this.props
		if (this.state.loading) { return <DimmedLoader component={null} isLoading={true} /> }
		if (this.state.error) { return <ErrorComponent club={loggedInClub} isAdmin={this.props.userState.isAdmin} /> }

		return (
			<div className='row justify-content-center mt-4 mx-auto'>
				<div className='col col-md-8'>
					{this.buildForm()}
				</div>
			</div>
		)
	}

	// ----------------------------------------------------------------------------------
	// Helpers
	// ----------------------------------------------------------------------------------

	getUpdatingMember = (): core.User.Model => {
		if (!this.isUpdateForm) { return undefined }

		if (this.props.isCustomerView) { return this.props.userState.loggedInUser }

		// Find the Reservation
		const reservation = this.state.serviceToUpdate.reservations.find((r) => `${r._id}` === this.state.reservationToUpdate)
		const memberID = reservation.owner

		// Find the Member
		const reservationMember = this.props.members.find((clubMember) => clubMember._id === memberID)
		return reservationMember
	}

	// ----------------------------------------------------------------------------------
	// Form Helpers
	// ----------------------------------------------------------------------------------

	getFormInputs = (membersForForm: ReactSelectItem[], vehiclesForForm: ReactSelectItem[], providersForForm: ReactSelectItem[], serviceTypes: ReactSelectItem[]) => {
		let formInputs = (this.props.isCustomerView) ?
			CustomerServiceFormInputs(vehiclesForForm, providersForForm, serviceTypes) :
			AdminServiceFormInputs(membersForForm, vehiclesForForm, providersForForm, serviceTypes)

		// If we are on the Update form -- do not show the member or date
		if (this.isUpdateForm) {
			formInputs = formInputs.filter((formInput) => formInput.property !== 'date' && formInput.property !== 'member')
		}

		if (this.club.name === core.Constants.Clubs.DRIVERS_CLUB) {
			formInputs = formInputs.filter((formInput) => formInput.property !== 'type')
		}

		return formInputs
	}

	getVehiclesForForm = (memberUsers: core.User.Model[]): ReactSelectItem[] => {
		return flatten(
			memberUsers.filter((member) => {
				if (isNullOrUndefined(this.state.selectedMemberID)) { return true }
				return `${member._id}` === this.state.selectedMemberID
			}).map((member) => {
				const vehiclesForUser = getVehiclesForUser(member)
				return vehiclesForUser.map((vehicle) => this.getVehicleForForm(vehicle, member))
			}),
			true
		)
	}

	getVehicleForForm = (vehicle: core.SubModels.CarMeta.Vehicle, vehicleOwner: core.User.Model): ReactSelectItem => {
		return {
			label: `${vehicle.year} ${vehicle.make} ${vehicle.model}`,
			value: `${vehicle._id}`,
		}
	}

	getProvidersForForm = (providers: core.Calendar.Model[]): ReactSelectItem[] => {
		return providers.map((serviceProvider) => {
			return {
				label: serviceProvider.name,
				value: `${serviceProvider._id}`,
			}
		})
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	eventState: state.event,
	calendarState: state.calendar,
	userState: state.user,
	loggedInClub: state.club.loggedInClub,
	isCustomerView: inCustomerViewSelector(state),
	members: membersSelector(state),
	eventsByID: eventsByIDSelector(state),
})

const mapDispatchToProps = {
	...CalendarActions,
	...EventActions,
	...AlertActions
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceForm)
