// External Dependencies
import * as core from 'club-hub-core'

// Internal Dependencies
import { ActionTypes, TypeKeys } from '../actions/restaurant'
import { mergeUpdatedModelObject } from '../helpers/reducer'

export type RestaurantState = typeof defaultState

const defaultState = {
	restaurants: null as core.Restaurant.Model[] | null,
	restaurant: null as core.Restaurant.Model | null
}

function restaurant(state = defaultState, action: ActionTypes): RestaurantState {
	switch (action.type) {
		case TypeKeys.FETCHED_RESTAURANTS:
			return { ...state, restaurants: action.restaurants }
		case TypeKeys.FETCHED_RESTAURANT:
			return {
				...state,
				restaurant: action.restaurant,
				restaurants: mergeUpdatedModelObject(action.restaurant, state.restaurants),
			}
		case TypeKeys.CREATED_RESTAURANT:
			return {
				...state,
				restaurants: mergeUpdatedModelObject(action.restaurant, state.restaurants),
			}
		case TypeKeys.UPDATED_RESTAURANT:
		case TypeKeys.CREATED_MENU:
		case TypeKeys.UPDATED_MENU:
		case TypeKeys.DELETE_MENU:
		case TypeKeys.CREATED_MENU_ITEM:
		case TypeKeys.UPDATED_MENU_ITEM:
		case TypeKeys.DELETE_MENU_ITEM:
		case TypeKeys.CREATED_MENU_SECTION:
		case TypeKeys.UPDATED_MENU_SECTION:
		case TypeKeys.DELETED_MENU_SECTION:
			return {
				...state,
				restaurants: mergeUpdatedModelObject(action.restaurant, state.restaurants),
			}
		case TypeKeys.DELETED_RESTAURANT:
			return {
				...state,
				restaurants: state.restaurants.filter((res: core.Restaurant.Model) => {
					return `${res._id}` !== `${action.restaurantID}`
				})
			}
		default:
			return state
	}
}

export default restaurant
