// External Dependencies
import * as React from 'react'
import * as core from 'club-hub-core'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { FormikProps } from 'formik'
import { oc } from 'ts-optchain'

// Internal Dependencies

// UI
import ModalComponent from '../../Shared/Modal'

// Form
import { EditStatusModalInputs, EditStatusFormState } from './form'
import { FormikComponent, BuildWrappedForm } from '../../Shared/Formik'

// Actions
import { AlertActions } from '../../../actions/index'

// Helpers
import { setStateAsync } from '../../../helpers/promise'

type ConnectedActions = typeof mapDispatchToProps

interface ComponentProps {
	club?: core.Club.Model
	cancelButtonHandler: () => any
	submitButtonHandler: (status: string) => any
}

type Props = ConnectedActions & ComponentProps
type State = any

/**
 * The `EditStatusModal` provides UI for editing a status update.
 */
class EditStatusModal extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
	}

	// ----------------------------------------------------------------------------------
	// Handlers
	// ----------------------------------------------------------------------------------

	handleSave = async (formState: EditStatusFormState) => {
		this.props.submitButtonHandler(formState.status)
	}

	/**
	 * Handles cancel button clicks.
	 */
	handleCancelButton = async () => {
		await setStateAsync(this, { clickedSubmit: false })
		return this.props.cancelButtonHandler()
	}

	// ----------------------------------------------------------------------------------
	// Component Builds
	// ----------------------------------------------------------------------------------

	buildModal = (formikProps: FormikProps<any>) => {
		const wrappedFormProps = { inputs: EditStatusModalInputs }
		return (
			<ModalComponent
				modalTitle={'Edit Status'}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={this.handleCancelButton}
				submitButtonName={'Save'}
				submitButtonHandler={formikProps.submitForm}
				buildInputs={() => BuildWrappedForm(wrappedFormProps, formikProps)}
			/>
		)
	}

	public render() {
		return (
			<FormikComponent
				inputs={EditStatusModalInputs}
				enableReinitialize={false}
				formResource={{ status: oc(this).props.club.website.status('') }}
				onSubmit={this.handleSave}
				render={this.buildModal}
			/>
		)
	}
}

const mapDispatchToProps = {
	...AlertActions
}

const enhance = compose<React.ComponentType<ComponentProps>>(
	connect(undefined, mapDispatchToProps)
)

export default enhance(EditStatusModal)
