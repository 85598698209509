// External Dependencies
import * as core from 'club-hub-core'
import * as Yup from 'yup'

// Internal Dependencies
import { FormInput, FormInputType, InputSelectionItem } from '../../Shared/Form'
import * as Constants from '../../../constants/index'

export interface PostFormState {
	title: string,
	publicationDate: Date,
	author: InputSelectionItem,
	description: string,
	html: string,
	status: core.IShared.PublicationStatus,
	image?: File[],
	type: InputSelectionItem,
}

export const PostFormInputs = (authors: InputSelectionItem[], types: InputSelectionItem[]): FormInput[] => [
	{
		title: 'Title',
		property: 'title',
		type: FormInputType.TEXT,
		size: 12,
		placeholder: 'Enter a title for your post...',
		validation: Yup.string().required(Constants.REQUIRED_FIELD),
		defaultValue: '',
	},
	{
		title: 'Image',
		property: 'image',
		type: FormInputType.FILE,
		size: 12,
		file_accept: '.png,.jpg,.jpeg',
		file_multipleFiles: false,
		defaultValue: null,
	},
	{
		title: 'Publication Date',
		property: 'publicationDate',
		type: FormInputType.DATE,
		size: 6,
		placeholder: 'Today',
		defaultValue: null,
	},
	{
		title: 'Author',
		property: 'author',
		type: FormInputType.SELECT,
		size: 6,
		placeholder: 'Who wrote this post?',
		selectItems: authors,
		defaultValue: null,
		validation: Yup.object().shape({
			label: Yup.string(),
			value: Yup.string(),
		}).nullable().required(Constants.REQUIRED_FIELD),
	},
	{
		title: 'Type',
		property: 'type',
		type: FormInputType.SELECT,
		size: 6,
		selectItems: types,
		defaultValue: null,
	},
]
