import * as React from 'react'
import * as RS from 'reactstrap'
import * as core from 'club-hub-core'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'

// Form
import { InputSelectionItem } from '../../Shared/Form'
import { MemberSelectInput } from '../../Shared/Formik/InputPrimitives/MemberSelectInput'

export interface GolferInput {
	participant?: InputSelectionItem,
	needsCart: boolean,
	numHoles: number,
}

interface ComponentProps {
	golfers: GolferInput[]
	eventTime: Date
	club: core.Club.Model
	user: core.User.Model
	users: core.User.Model[],
	reservedUsers: core.User.Model[]
	handleAddGuest: (index: number) => void,
	handleMemberSelected: (data: any, index: number) => void,
	updateGolferState: (index: number, property: string, value: any) => void
}

type Props = ComponentProps
type State = any

export default class GolferInputComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
	}

	buildMemberSelectInput = (rowIndex: number) => {
		const guests = this.props.golfers.map((g: GolferInput) => g.participant)
		const reservedUserIds: core.IShared.GeneralMap<string> = {}
		this.props.reservedUsers.forEach((p: core.Event.Participant) => reservedUserIds[`${p.userID}`] = `${p.userID}`)

		const isDisabled = isNullOrUndefined(this.props.eventTime)
		return (
			<MemberSelectInput
				loggedInUser={this.props.user}
				club={this.props.club}
				users={this.props.users}
				guests={guests}
				reservedUserIDsMap={reservedUserIds}
				rowIndex={rowIndex}
				disabled={isDisabled}
				handleMemberSelected={this.props.handleMemberSelected}
			/>
		)
	}

	/**
 * Builds our cart button.
 */
	buildCartButton = (golfer: GolferInput, index: number) => {
		return (
			<RS.Button
				active={golfer.needsCart}
				className={'golf-reservation-button'}
				onClick={() => this.props.updateGolferState(index, 'needsCart', !golfer.needsCart)}
			>
				<i className='fas fa-car-side' />
			</RS.Button>
		)
	}

	/**
	 * Builds our holes buttons.
	 */
	buildHolesButtonGroup = (golfer: GolferInput, index: number) => {
		return (
			<RS.ButtonGroup>
				<RS.Button
					active={golfer.numHoles === 9}
					className={'golf-reservation-button'}
					onClick={() => this.props.updateGolferState(index, 'numHoles', 9)}
				>
					9
				</RS.Button>
				<RS.Button
					active={golfer.numHoles === 18}
					className={'golf-reservation-button'}
					onClick={() => this.props.updateGolferState(index, 'numHoles', 18)}
				>
					18
				</RS.Button>
			</RS.ButtonGroup>
		)
	}

	render() {
		const items = this.props.golfers.map((golfer: GolferInput, i: number) => {
			return (
				<RS.Row key={`golfer-input-row_${i}`} className='golfer-input-row no-gutters'>
					<RS.Col xs={8} md={8}>
						{this.buildMemberSelectInput(i)}
					</RS.Col>
					<RS.Col xs={4} md={4}>
						<RS.Row>
							<RS.Col xs={5} md={5} className={'cart-button-col'}>
								{this.buildCartButton(golfer, i)}
							</RS.Col>
							<RS.Col xs={7} md={7} className={'holes-button-col'}>
								{this.buildHolesButtonGroup(golfer, i)}
							</RS.Col>
						</RS.Row>
					</RS.Col>
				</RS.Row>
			)
		})

		return (
			<RS.Row className={'golfer-input-row-container'}>
				<RS.Col>
					<RS.FormGroup className='golfers-inputs'>
						<RS.Label className='form-label'>{'Golfers'}</RS.Label>
						{items}
					</RS.FormGroup>
				</RS.Col>
			</RS.Row>
		)
	}
}
