// External Dependencies
import * as React from 'react'
import { compose } from 'redux'
import * as core from 'club-hub-core'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies
import { UserActions, PostActions } from '../../actions/index'

// State
import { inCustomerViewSelector } from '../../reducers/user'
import { RootReducerState } from '../../reducers'

// Components
import BackHeader from '../Shared/BackHeader'
import AvatarComponent from '../Shared/Avatar'
import AsyncImage from '../Shared/AsyncImage'
import RichContent from '../Shared/RichContent'

// Helpers
import { setStateAsync } from '../../helpers/promise'
import { fullName } from '../../helpers/user'
import { ImageSize } from '../../helpers/image'
import * as Constants from '../../constants'
import { type } from 'os'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

const initialState = {
	post: null as core.Post.Model | null
}

type Props = RouteComponentProps & ConnectedActions & ConnectedState
type State = typeof initialState

class PostDetailComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Lifecycle Methods
	// ----------------------------------------------------------------------------------

	async componentDidMount() {
		await this.setPostWithQueryParameters()
	}

	setPostWithQueryParameters = async () => {
		const { postState, location } = this.props

		// Parse the query string of the URL into an object
		const parsedQuery = queryString.parse(location.search)

		// Get the Post by its ID
		const postID = parsedQuery.postID
		this.fetchPostAndUser(postID)
	}

	fetchPostAndUser = async (postID: string) => {
		const [postErr] = await to(this.props.fetchPost(postID) as any)
		if (postErr) {
			// tslint:disable-next-line
			console.error(`Failed to fetch post with error: ${postErr}`)
			throw postErr
		}
		if (typeof this.props.postState.currentPost.author === 'string') {
			const [userErr, userRes] = await to(this.props.getUser(this.props.postState.currentPost.author) as any)
			if (userErr) {
				// tslint:disable-next-line
				console.error(`Failed to fetch post author with error: ${JSON.stringify(userErr)}`)
				throw userErr
			}
			this.props.postState.currentPost.author = userRes
		}

		// Update the state
		await setStateAsync(this, { post: this.props.postState.currentPost })
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * When editing an Post, navigate to the Post update
	 * form and pass the Post's ID via the query parameters
	 */
	handleEditPost = () => {
		// Query params
		const queryParams = {
			postID: this.state.post._id
		}

		const location = {
			pathname: Constants.UPDATE_POST_ROUTE,
			search: queryString.stringify(queryParams)
		}

		this.props.history.push(location)
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildBackHeader = () => {
		const editHandler = (this.props.isCustomerView) ? undefined : this.handleEditPost
		return (
			<BackHeader
				to={Constants.POSTS_ROUTE}
				backTitle={'News'}
				editHandler={editHandler}
			/>
		)
	}

	buildPostImage = () => {
		const { loggedInClub } = this.props
		const { post } = this.state

		const postImage = oc(post).image()
		if (!postImage.lg && !postImage.original) { return null }
		return (
			<div className='post-detail-image-container'>
				<AsyncImage
					image={postImage}
					size={ImageSize.Large}
					club={loggedInClub}
					className={'post-detail-image'}
					placeholderClass={'post-detail-image-placeholder'}
				/>
			</div>
		)
	}

	buildAuthorLink = (user: core.User.Model) => {
		const queryParams = { userID: user._id }
		const route = `${Constants.VIEW_USER_ROUTE}?${queryString.stringify(queryParams)}`
		const authorName = fullName(user)
		return <Link to={route}>{authorName}</Link>
	}

	render() {
		const post = this.state.post
		if (!post) {
			return null
		}
		const postAuthor = post.author as core.User.Model
		const postDate = new Date(post.publicationDate).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })
		const postHTML = oc(post).richContent.html()

		return (
			<div className='post-detail-container'>
				{this.buildBackHeader()}
				<div className='post-detail-card card mt-2 mx-auto'>
					<div className='card-body'>
						<div className='post-header-container text-center'>
							<AvatarComponent className='card-profile-img avatar-xl' user={postAuthor} />
							<h3 className='post-detail-title mt-4 mb-3'>{post.title}</h3>
							<div className='mb-6'>
								{this.buildAuthorLink(postAuthor)}
								{` on ${postDate}`}
							</div>
							{this.buildPostImage()}
						</div>
						<RichContent class={'post-detail-description'} content={postHTML} />
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	postState: state.post,
	loggedInClub: state.club.loggedInClub,
	isCustomerView: inCustomerViewSelector(state),
})

const mapDispatchToProps = {
	...UserActions,
	...PostActions
}

const enhance = compose<React.ComponentType<Props>>(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(PostDetailComponent)
