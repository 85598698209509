import * as React from 'react'
import * as core from 'club-hub-core'
import { oc } from 'ts-optchain'
import { flatten, Dictionary } from 'underscore'
import { isNullOrUndefined } from 'util'

// Components
import { ReservationCellType } from './ReservationCell'

// Constants
import * as Constants from '../../../constants'

export interface TeeSheetHelperProps {
	index: string
	memberID: string
	isCustomerView: boolean
	bookableEvent: core.Event.AvailableEvent
	usersByID: Dictionary<core.User.Model>
	lotteryDay?: boolean
}

export interface CellInfo {
	type: ReservationCellType
	index: number
	resIndex: number
	cellIndex: number
	color: string
	reservation: core.Event.Reservation
	user: core.User.Model
	participant: core.Event.Participant
	event?: core.Event.Model
	lotteryDay?: boolean
}

const BaseParticipants = 4

export default class TeeSheetHelper extends React.Component<TeeSheetHelperProps, any> {

	public ownedEvent: boolean

	public reservations: core.Event.Reservation[]

	public allParticipants: core.Event.Participant[]

	constructor(props: TeeSheetHelperProps) {
		super(props)

		// Get all reservations for the event.
		this.reservations = oc(props).bookableEvent.existingEvent.reservations([])

		// Get all participants for the event.
		const allParticipants = this.reservations.map((res: core.Event.Reservation) => res.participants)
		this.allParticipants = flatten(allParticipants, true)

		// Calculate if it is an owned event.
		const ownedEvent = this.allParticipants.find((participant: core.Event.Participant) => {
			const participantID = oc(participant).userID()
			if (isNullOrUndefined(participantID)) { return false }
			return `${participantID}` === props.memberID
		})
		this.ownedEvent = !isNullOrUndefined(ownedEvent)
	}

	getCellInfo = () => {
		// The first cell is an unreserved cell.
		const type = this.getCellType(this.props.bookableEvent, true)
		const unreservedInfo = { type: type, index: this.props.index }

		// If the event is open, we can just return.
		if (this.props.bookableEvent.status === core.Event.AvailableEventStatus.Open) {
			return [unreservedInfo]
		}

		// If the event is Blocked, we return.
		if (this.props.bookableEvent.status === core.Event.AvailableEventStatus.Blocked) {
			return [unreservedInfo]
		}

		// If the event is fully private and not my event, we can return.
		const privateNotJoinable = (this.props.bookableEvent.status === core.Event.AvailableEventStatus.PrivateNotJoinable)
		if (this.props.isCustomerView && !this.ownedEvent && privateNotJoinable) {
			return [unreservedInfo]
		}

		// Build the rest of our cell info.
		const flattenedCellInfo = this.buildReservationCellInfo()

		// If we have no more open spots, we can return just our reserved cell info.
		const bookedSpots = this.props.bookableEvent.totalSpots - this.props.bookableEvent.openSpots
		if (bookedSpots >= BaseParticipants) {
			return flattenedCellInfo
		}

		// If we have more than zero open spots, we all the unreserved info.
		return [...flattenedCellInfo, unreservedInfo]
	}

	buildReservationCellInfo = (): CellInfo[] => {
		const info = this.reservations.map((reservation: core.Event.Reservation, resIndex: number) => {

			const type = this.getCellType(this.props.bookableEvent, false, reservation)
			const participants = oc(reservation).participants([])

			// Determine if the current user is part of the reservation.
			const ownedReservation = participants.find((participant: core.Event.Participant) => {
				const participantID = oc(participant).userID()
				if (isNullOrUndefined(participantID)) { return false }
				return `${participantID}` === this.props.memberID
			})

			// If the current user doesn't own the res and can't view the res, we bail with a single cell.
			const blockedOrUnavailable = ((type === ReservationCellType.Blocked) || (type === ReservationCellType.Unavailable))
			if (!ownedReservation && blockedOrUnavailable) {
				return { type: type, index: this.props.index }
			}

			// For visible reservations, we can build individual user cells.
			const color = Constants.GROUP_COLORS[resIndex]
			return participants.map((participant: core.Event.Participant, cellIndex: number) => {
				const user = this.props.usersByID[`${participant.userID}`]
				return {
					type: type,
					index: this.props.index,
					resIndex: resIndex,
					cellIndex: cellIndex,
					color: color,
					reservation: reservation,
					user: user,
					participant: participant,
					event: oc(this.props.bookableEvent).existingEvent()
				}
			})
		})
		return flatten(info, true)
	}

	// ----------------------------------------------------------------------------------
	// Cell Type Helpers
	// This stuff sucks and is verbose, but is the most elegant way to do this that I have found.
	// ----------------------------------------------------------------------------------

	getCellType = (bookableEvent: core.Event.AvailableEvent, placeholder: boolean, reservation?: core.Event.Reservation) => {
		return (this.props.isCustomerView) ?
			this.getCellTypeForMember(bookableEvent, placeholder, reservation) :
			this.getCellTypeForAdmin(bookableEvent, placeholder)
	}

	getCellTypeForAdmin = (bookableEvent: core.Event.AvailableEvent, placeholder: boolean) => {
		if (bookableEvent.status === core.Event.AvailableEventStatus.Blocked) {
			return ReservationCellType.Blocked
		}
		if (bookableEvent.status === core.Event.AvailableEventStatus.Open) {
			return ReservationCellType.Open
		}
		if (placeholder) {
			return ReservationCellType.Open
		}
		return ReservationCellType.Reserved
	}

	getCellTypeForMember = (bookableEvent: core.Event.AvailableEvent, placeholder: boolean, reservation?: core.Event.Reservation) => {
		const ownedReservation = oc(reservation).participants([]).find((participant: core.Event.Participant) => {
			const participantID = oc(participant).userID()
			if (isNullOrUndefined(participantID)) { return false }
			return `${participantID}` === this.props.memberID
		})

		if (ownedReservation) {
			return ReservationCellType.Reserved
		}

		switch (bookableEvent.status) {
			case core.Event.AvailableEventStatus.PrivateNotJoinable:
				return ReservationCellType.Unavailable
			case core.Event.AvailableEventStatus.PublicNotJoinable:
				if (placeholder) {
					return ReservationCellType.Unavailable
				}
				return ReservationCellType.Reserved
			case core.Event.AvailableEventStatus.PublicJoinable:
				if (placeholder) {
					return ReservationCellType.Open
				}
				return ReservationCellType.Reserved
			case core.Event.AvailableEventStatus.PrivateJoinable:
				if (placeholder) {
					return ReservationCellType.Open
				}
				return ReservationCellType.Unavailable
			case core.Event.AvailableEventStatus.PublicBooked:
				return ReservationCellType.Reserved
			case core.Event.AvailableEventStatus.PrivateBooked:
				return ReservationCellType.Unavailable
			case core.Event.AvailableEventStatus.Blocked:
				return ReservationCellType.Blocked
			case core.Event.AvailableEventStatus.Open:
			default:
				return ReservationCellType.Open
		}
	}
}
