// External Dependencies
import * as Yup from 'yup'
import { oc } from 'ts-optchain'

// Internal Dependencies
import { FormInput, FormInputType } from '../Shared/Form'
import * as Constants from '../../constants/index'

export const LoginFormInputs = (): FormInput[] => {
	return [
		{
			title: 'Email',
			property: 'email',
			type: FormInputType.TEXT,
			placeholder: 'Email',
			defaultValue: '',
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		},
		{
			title: 'Password',
			property: 'password',
			type: FormInputType.PASSWORD,
			placeholder: 'Password',
			defaultValue: '',
			validation: Yup.string().required(Constants.REQUIRED_FIELD),
		}
	]
}
