// External Dependencies
import * as React from 'react'
import * as RS from 'reactstrap'
import * as core from 'club-hub-core'
import * as Feather from 'react-feather'
import { oc } from 'ts-optchain'

// Internal Dependencies

// SCSS Colors
// @ts-ignore
import colors from '../../../assets/__base.scss'

// Components
import AsyncBackgroundImage from '../../Shared/AsyncBackgroundImage'
import WeatherCard from '../WeatherCard'
import { ImageSize } from '../../../helpers/image'
import * as WeatherService from '../../../services/weather'

interface Props {
	weather: WeatherService.WeatherResponse
	customerView: boolean
	club: core.Club.Model
	name: string
	onEditClick: () => void
}

export default class CoverImageCard extends React.Component<Props, any> {
	constructor(props: Props) {
		super(props)
	}

	buildEditIcon = () => {
		if (this.props.customerView) { return null }
		return (
			<div className='icon-flex-container'>
				<Feather.Edit
					className='alert-icon'
					size={20}
					color={colors.MeshWhite}
					onClick={this.props.onEditClick}
				/>
			</div>
		)
	}

	buildWeatherCard = () => {
		const temp = oc(this).props.weather.main.temp(0)
		const desc = oc(this).props.weather.weather[0].description('')
		const condition = oc(this).props.weather.weather[0].main()
		return (
			<div className='weather-container d-flex flex-row-reverse'>
				<WeatherCard
					club={this.props.club}
					temp={temp}
					description={desc}
					condition={condition}
				/>
			</div>
		)
	}

	render() {
		const username = oc(this).props.name('')
		const clubName = oc(this).props.club.name('')

		return (
			<div className='cover-image-backer'>
				<AsyncBackgroundImage
					className='card cover-image-card'
					image={this.props.club.website.coverImage}
					size={ImageSize.Large}
					club={this.props.club}
				>
					<div className={'hero-content-container'}>
						<RS.Row>
							<RS.Col>
								{this.buildEditIcon()}
							</RS.Col>
							<RS.Col>
								{this.buildWeatherCard()}
							</RS.Col>
						</RS.Row>
						<div className='hero-text-container'>
							<h1 className={'hero-welcome-text'}>{`Welcome back, ${username}`}</h1>
							<p>{clubName}</p>
						</div>
					</div>
				</AsyncBackgroundImage>
			</div >
		)
	}
}
