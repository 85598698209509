// External Dependencies
import * as React from 'react'
import * as queryString from 'query-string'
import * as core from 'club-hub-core'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter, RouteComponentProps } from 'react-router'
import { isNullOrUndefined } from 'util'
import { oc } from 'ts-optchain'
import to from 'await-to-js'

// Internal Dependencies

// Actions
import { AlertActions, CalendarActions } from '../../actions/index'

// State
import { RootReducerState } from '../../reducers'
import { calendarsByIDSelector } from '../../reducers/calendar'

// Components
import TableComponent from '../Shared/Table'
import ModalComponent from '../Shared/Modal'
import FormModal from '../Shared/Formik/FormModal'

// Table
import { ClubCalendarTableActions, ClubCalendarTableColumns } from './table'

// Form
import { ClubCalendarFormInputs, ClubCalendarForm } from './form'

// Helpers
import { setStateAsync } from '../../helpers/promise'
import * as Constants from '../../constants/index'

type ConnectedState = ReturnType<typeof mapStateToProps>
type ConnectedActions = typeof mapDispatchToProps

interface ComponentProps {
	showingEditModal: boolean
	onTableRowClick: (item: core.Calendar.Model) => any
	onEditModalClose: () => any
}

// State Key Constants
type ModalStateKey = typeof ShowingLocalEditModal | typeof ShowingDeletionModal
const ShowingLocalEditModal = 'showingLocalEditModal'
const ShowingDeletionModal = 'showingDeletionModal'

const initialState = {
	loading: false,
	error: false,
	pageIndex: 0,
	[ShowingLocalEditModal]: false,
	[ShowingDeletionModal]: false,
	calendarToEdit: null as string | null,
	calendarToDelete: null as string | null,
}

type Props = ComponentProps & ConnectedState & ConnectedActions & RouteComponentProps
type State = typeof initialState

class ClubCalendarsComponent extends React.Component<Props, State> {
	private pageSize: number

	constructor(props: Props) {
		super(props)
		this.pageSize = 20
		this.state = { ...initialState }
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers
	// ----------------------------------------------------------------------------------

	// ----------------------------------------------------------------------------------
	// Event Handlers - Modal Event Handlers
	// ----------------------------------------------------------------------------------

	executeCalendarSave = async (name: string, color: string, groupID: string) => {
		const { onEditModalClose, createCalendar, updateCalendar, fireFlashMessage } = this.props
		const { calendarToEdit } = this.state

		await setStateAsync(this, { loading: true })

		const updatingCalendar = !isNullOrUndefined(calendarToEdit)
		const payload: core.Calendar.Model = (updatingCalendar) ?
			({ _id: calendarToEdit as any, name, color, groupID: groupID as any }) :
			({ name, color, groupID: groupID as any })

		const saveFunction = (updatingCalendar) ?
			updateCalendar(calendarToEdit, payload) :
			createCalendar(payload)

		const [err] = await to(saveFunction as any)
		if (err) {
			fireFlashMessage(`Problem trying to save Calendar. ${err.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true, calendarToEdit: null })
			return
		}

		fireFlashMessage(`Successfully saved Calendar.`, Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, calendarToEdit: null })

		// Close the modal locally (if the modal was triggered in this component)
		await this.handleCloseModal(ShowingLocalEditModal)

		// Close the modal through props (if the modal was triggered from a parent component)
		onEditModalClose()
	}

	/**
	 * Make the API call to delete the Calendar, when the
	 * user confirms the deletion via the modal
	 */
	executeCalendarDeletion = async () => {
		const { deleteCalendar, fireFlashMessage } = this.props
		const { calendarToDelete } = this.state

		await setStateAsync(this, { loading: true })
		const calendarID = calendarToDelete

		const [deleteErr] = await to(deleteCalendar(calendarID) as any)
		if (deleteErr) {
			fireFlashMessage(`Problem trying to delete Calendar. ${deleteErr.message}`, Constants.FlashType.DANGER)
			await setStateAsync(this, { loading: false, error: true, calendarToDelete: null })
			return
		}

		fireFlashMessage('Successfully deleted Calendar.', Constants.FlashType.SUCCESS)
		await setStateAsync(this, { loading: false, calendarToDelete: null })
		await this.handleCloseModal(ShowingDeletionModal)
	}

	handleOpenModal = async (modalStateKey: ModalStateKey) => {
		await setStateAsync(this, () => ({ [modalStateKey]: true }))
	}

	handleCloseModal = async (modalStateKey: ModalStateKey) => {
		const newState: Partial<State> = {
			[modalStateKey]: false,
			calendarToEdit: null,
			calendarToDelete: null,
		}
		await setStateAsync(this, () => newState)
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Dropdown Actions
	// ----------------------------------------------------------------------------------

	/**
	 * Determines which action to take, based on which dropdown item
	 * the user selected in the table's dropdown menu
	 */
	handleTableDropdownAction = (e: any) => {
		const [action, value] = e
		switch (action) {
			case ClubCalendarTableActions.View:
				return this.handleViewCalendar(value)
			case ClubCalendarTableActions.Edit:
				return this.handleEditCalendar(value)
			case ClubCalendarTableActions.Delete:
				return this.handleDeleteCalendar(value)
			default:
				break
		}
	}

	handleViewCalendar = async (calendarID: string) => {
		// Get the Group ID for the Calendar
		const calendar = this.props.calendarsByID[calendarID]

		// Redirect us to the Calendar view
		const queryParams = { groupID: calendar.groupID, calendarID: calendarID }
		const search = queryString.stringify(queryParams)
		const location = { pathname: Constants.CALENDAR_ROUTE, search: search }
		this.props.history.push(location)
	}

	handleEditCalendar = async (calendarID: string) => {
		await setStateAsync(this, { calendarToEdit: calendarID })
		await this.handleOpenModal(ShowingLocalEditModal)
	}

	handleDeleteCalendar = async (calendarID: string) => {
		await setStateAsync(this, { calendarToDelete: calendarID })
		await this.handleOpenModal(ShowingDeletionModal)
	}

	// ----------------------------------------------------------------------------------
	// Event Handlers - Table Event Handlers
	// ----------------------------------------------------------------------------------

	/**
	 * Handle table pagination
	 */
	handlePage = async (data: any) => {
		const { pageIndex } = this.state

		// Check if the new page is different that the current one
		if (data.selected !== pageIndex) {
			await setStateAsync(this, { pageIndex: data.selected })
		}
	}

	/**
	 * Handle a column header of the table being selected
	 */
	handleTableHeaderAction = (e: any) => {
		// TODO: Add this functionality
	}

	// ----------------------------------------------------------------------------------
	// Content Builders
	// ----------------------------------------------------------------------------------

	buildTable = () => {
		const { loggedInClub, calendars } = this.props
		const { pageIndex, loading } = this.state

		const clubCalendarGroup = oc(loggedInClub).calendarGroups([]).find((cg) => {
			return cg.type === core.Calendar.GroupType.Club
		})
		const calendarsForGroup = oc(calendars)([]).filter((cal) => {
			return cal.groupID === clubCalendarGroup._id
		})
		const total = calendarsForGroup.length

		const startIdx = pageIndex * this.pageSize
		const endIdx = startIdx + this.pageSize
		const calendarsToShow = calendarsForGroup.slice(startIdx, endIdx).map((value) => {
			return {
				...value,
				colorBadge: { title: value.name, color: value.color },
			}
		})

		return (
			<div className='clubCalendars-table-container'>
				<TableComponent<core.Calendar.Model>
					columns={ClubCalendarTableColumns}
					rowItems={calendarsToShow}
					showPaging={true}
					currentPage={pageIndex}
					pageHandler={this.handlePage}
					totalResults={total}
					pageSize={this.pageSize}
					actionHandler={this.handleTableHeaderAction}
					dropdownHandler={this.handleTableDropdownAction}
					isLoading={loading}
					onTableRowClick={this.props.onTableRowClick}
				/>
			</div>
		)
	}

	buildEditModal = () => {
		const { loggedInClub, calendarsByID, showingEditModal, onEditModalClose } = this.props
		const { showingLocalEditModal, calendarToEdit } = this.state

		if (!showingEditModal && !showingLocalEditModal) { return null }
		const clubCalendarGroup = oc(loggedInClub).calendarGroups([]).find((cg) => {
			return cg.type === core.Calendar.GroupType.Club
		})
		const existingCalendar = calendarsByID[calendarToEdit]
		const existingResource = (existingCalendar) ?
			({
				name: oc(existingCalendar).name(''),
				color: oc(existingCalendar).color(''),
			}) : null
		const modalTitle = (existingResource) ? 'Edit Calendar' : 'New Calendar'

		return (
			<FormModal<core.Calendar.Model, ClubCalendarForm>
				modalTitle={modalTitle}
				formSpec={ClubCalendarFormInputs(`${clubCalendarGroup._id}`)}
				formResource={existingResource}
				submitButtonHandler={(values) => this.executeCalendarSave(values.name, values.color, values.groupID)}
				cancelButtonHandler={async () => {
					await this.handleCloseModal(ShowingLocalEditModal)
					onEditModalClose()
				}}
			/>
		)
	}

	buildDeletionModal = () => {
		if (!this.state.showingDeletionModal) { return null }
		return (
			<ModalComponent
				modalTitle={'Delete Calendar'}
				primaryMessage={'Are you sure you want to delete this Calendar?'}
				cancelButtonName={'Cancel'}
				cancelButtonHandler={() => this.handleCloseModal(ShowingDeletionModal)}
				submitButtonName={'Confirm'}
				submitButtonHandler={this.executeCalendarDeletion}
			/>
		)
	}

	render() {
		return (
			<>
				{this.buildTable()}
				{this.buildEditModal()}
				{this.buildDeletionModal()}
			</>
		)
	}
}

const mapStateToProps = (state: RootReducerState) => ({
	loggedInClub: state.club.loggedInClub,
	calendars: state.calendar.calendars,
	calendarsByID: calendarsByIDSelector(state),
})

const mapDispatchToProps = {
	...AlertActions,
	...CalendarActions,
}

const enhance = compose<React.ComponentType<ComponentProps>>(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(ClubCalendarsComponent)
